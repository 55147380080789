import {Record} from 'immutable';

// So far this is very highcharts specific but at least it makes configuration possible in the future
const tooltip = {
    shape: 'callout',
    backgroundColor: 'rgba(55, 55, 55, 0.9)',
    borderColor: '#606060',
    borderRadius: 6,
    shadow: false,
    style: {
        color: '#FFFFFF',
        fontSize: '13px',
        padding: '8px 10px',
        marginLeft: '-1px',
    },
};

interface IChartsConfiguration {
    readonly tooltip: any;
}

export class ChartsConfiguration extends Record<IChartsConfiguration>({
    tooltip,
}) {}
