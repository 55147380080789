import {type QueryHookOptions} from '@apollo/client';

import {FetchInquiriesPermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-inquiries-permissions.query';
import {
    type FetchInquiriesPermissionsQuery,
    type FetchInquiriesPermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-inquiries-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchInquiriesPermissions(
    options: QueryHookOptions<FetchInquiriesPermissionsQuery, FetchInquiriesPermissionsQueryVariables>,
): ImmerWrappedQueryResult<FetchInquiriesPermissionsQuery, FetchInquiriesPermissionsQueryVariables> {
    return useStatQuery<FetchInquiriesPermissionsQuery, FetchInquiriesPermissionsQueryVariables>(
        FetchInquiriesPermissions,
        options,
    );
}
