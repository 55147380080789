import {createAction} from 'signin-app/redux/helpers';

const domain = 'EMPLOYEE_CHECKIN_IN';

export const {action: selectLeaveHours} = createAction(`${domain}/SELECT_LEAVE_HOURS`, (hours: number) => ({hours}));

export const {action: selectLeaveMinutes} = createAction(`${domain}/SELECT_LEAVE_MINUTES`, (minutes: number) => ({
    minutes,
}));

export const {action: selectGroup} = createAction(`${domain}/SELECT_GROUP`, (groupId: string) => ({groupId}));

export const {action: resetLeave} = createAction(`${domain}/RESET_LEAVE`, () => null);

export const {
    action: checkInEmployee,
    success: checkInEmployeeSuccess,
    failed: checkInEmployeeFailed,
} = createAction(
    `${domain}/CHECKIN`,
    (
        accessToken: string,
        employeeId: string,
        selectedHour: number,
        selectedMinute: number,
        groupId: string,
        successMessage: string,
    ) => ({
        accessToken,
        employeeId,
        selectedHour,
        selectedMinute,
        groupId,
        successMessage,
    }),
    response => ({response}),
    (error: Error) => ({error}),
);
