import {Record} from 'immutable';

import {makeReducer, match} from 'web-app/util/redux';

import * as Actions from './actions';

export class EmployeeCheckInState extends Record<{
    readonly isCheckingIn: boolean;
    readonly selectedGroupId: string;
    readonly selectedLeaveHours?: number;
    readonly selectedLeaveMinutes?: number;
}>({
    isCheckingIn: false,
    selectedLeaveHours: undefined,
    selectedLeaveMinutes: undefined,
    selectedGroupId: '',
}) {}

export default makeReducer<EmployeeCheckInState>(
    [
        match(Actions.selectLeaveHours, (state, {payload}) =>
            state.merge({
                selectedLeaveHours: payload.hours,
            }),
        ),
        match(Actions.selectLeaveMinutes, (state, {payload}) =>
            state.merge({
                selectedLeaveMinutes: payload.minutes,
            }),
        ),
        match(Actions.selectGroup, (state, {payload}) =>
            state.merge({
                selectedGroupId: payload.groupId,
            }),
        ),
        match(Actions.checkInEmployee, state =>
            state.merge({
                isCheckingIn: true,
            }),
        ),
        match(Actions.checkInEmployeeSuccess, state =>
            state.merge({
                isCheckingIn: false,
            }),
        ),
        match(Actions.checkInEmployeeFailed, state =>
            state.merge({
                isCheckingIn: false,
            }),
        ),
        match(Actions.resetLeave, () => new EmployeeCheckInState()),
    ],
    new EmployeeCheckInState(),
);
