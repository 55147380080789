import {Record} from 'immutable';

import {makeReducer, match} from 'web-app/util/redux';
import BehaviorsCollection from 'web-app/behaviors/behaviors-collection';

import * as Actions from './actions';

export class AccountState extends Record<{
    loginId: string | undefined;
    behaviors: BehaviorsCollection;
}>({
    loginId: undefined,
    behaviors: new BehaviorsCollection([]),
}) {}

export default makeReducer<AccountState>(
    [
        match(Actions.fetchAccountSuccess, (state, {payload}) => {
            return state.merge({
                loginId: payload.account.loginId,
                behaviors: payload.account.behaviors,
            });
        }),
    ],
    new AccountState(),
);
