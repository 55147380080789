import React from 'react';

import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useStatFeatures} from '../../features/use-stat-features';

export function useKinderConnectPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['childAttendance']['header']['export']['kinderConnect'] & {loading: boolean} {
    const {institutionSetId} = payload;
    const [features, loading] = useStatFeatures({
        institutionId: institutionSetId,
    });

    return React.useMemo(
        () => ({
            loading,
            canCreate: features.kinderConnect.isEnabled,
            canRead: features.kinderConnect.isEnabled,
            canUpdate: false,
            canDelete: false,
        }),
        [features.kinderConnect.isEnabled, loading],
    );
}
