import React from 'react';
import {ZonedDateTime, type ZoneId} from 'js-joda';
import {Text, Box, Stack} from 'modern-famly';

import {compose} from 'web-app/util/typescript';
import {connectState} from 'signin-app/helpers/connect-state';
import translate from 'signin-app/helpers/translate';
import * as GroupsSelectors from 'signin-app/groups/selectors';
import {type RootState} from 'signin-app/redux/main-reducer';
import lifecycle from 'web-app/react/components/higher-order/lifecycle';
import {getFormattedTime} from 'signin-app/helpers/time';
import {institutionLocale} from 'signin-app/groups/selectors';
import {ListTimes} from 'signin-app/components/list-times/list-times';

import * as Selectors from './selectors';
import * as Actions from './actions';

interface StateProps {
    canShowPickupTime: boolean;
    pickupTimeOptions: ReturnType<typeof Selectors.pickupTimeOptions>;
    timezoneId: ZoneId | null;
    locale: string;
    selectedHours?: number;
    selectedMinutes?: number;
}

interface PickupTimeProps {
    selectedPickupTime: string | null;
    checkedIn: boolean;
}

type Props = StateProps & PickupTimeProps;

const PickupTime: React.FC<Props> = props => {
    const {canShowPickupTime, pickupTimeOptions, selectedHours, selectedMinutes} = props;

    if (!canShowPickupTime) {
        return null;
    }

    return (
        <Box mt={4}>
            <Stack alignItems="baseline">
                <Text variant="h6" emphasized color="n0">
                    {translate('pickupTime')}
                </Text>
            </Stack>
            <ListTimes
                times={pickupTimeOptions}
                selectedHours={selectedHours}
                selectedMinutes={selectedMinutes}
                displayMinutes={getFormattedTime}
                onHoursChange={Actions.selectPickupHours.dispatch}
                onMinutesChange={Actions.selectPickupMinutes.dispatch}
                e2eHourUnit="child-pickup-hour-unit"
            />
        </Box>
    );
};

const mapStateToProps = (state: RootState): StateProps => ({
    canShowPickupTime: Selectors.canShowPickupTime(state),
    pickupTimeOptions: Selectors.pickupTimeOptions(state),
    selectedHours: Selectors.selectedHours(state),
    selectedMinutes: Selectors.selectedMinutes(state),
    timezoneId: GroupsSelectors.timezoneId(state),
    locale: institutionLocale(state),
});

const initializeFromProps = (props: Props) => {
    const {selectedPickupTime, checkedIn, timezoneId} = props;

    if (selectedPickupTime && checkedIn && timezoneId) {
        const pickupTime = ZonedDateTime.parse(selectedPickupTime).withZoneSameInstant(timezoneId);
        Actions.initPickupTime.dispatch(pickupTime.hour(), pickupTime.minute());
    }
};

export default compose(
    connectState<PickupTimeProps, StateProps, React.ComponentType<PickupTimeProps & StateProps>>(mapStateToProps),
)
    .with(lifecycle(initializeFromProps))
    .apply(PickupTime);
