import React from 'react';
import {type RouteComponentProps} from 'react-router-dom';
import {ZonedDateTime} from 'js-joda';
import {Box, Text} from 'modern-famly';
import {useDispatch} from 'react-redux';

import {fetchChild} from 'signin-app/child/actions';
import translate from 'signin-app/helpers/translate';
import Header from 'signin-app/components/header';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import PersonSelect from 'signin-app/components/person-select';
import {PinHeader} from 'signin-app/child/pin-header';
import {PageBodyContainer} from 'signin-app/components/action-buttons';
import * as WhitelabelSelectors from 'signin-app/whitelabel/selectors';
import * as LoginSelectors from 'signin-app/login/selectors';
import * as GroupsSelectors from 'signin-app/groups/selectors';
import * as ChildSelectors from 'signin-app/child/selectors';
import * as ChildCheckinActions from 'signin-app/child/check-in/actions';
import {Card, CardsContainer} from 'signin-app/groups/group-item';
import {useTypedSelector} from 'signin-app/components/hooks';
import {ChildOverview} from 'signin-app/child/check-out/child-overview';
import {PageContainer} from 'signin-app/components/containers';
import * as GlobalEventActions from 'signin-app/global-event/actions';

import {CheckOutSubmit} from './checkout-submit';
import * as Selectors from './selectors';

type CheckOutRouteProps = RouteComponentProps<{childId: string}>;

export const ChildCheckOut: React.FC<CheckOutRouteProps> = props => {
    const {match} = props;
    const {childId} = match.params;
    const dispatch = useDispatch();

    const child = useTypedSelector(state => Selectors.getChild(state, props));
    const isFetching = useTypedSelector(ChildSelectors.isFetching);
    const canSwitchRoom = useTypedSelector(Selectors.canSwitchRoom);
    const groups = useTypedSelector(GroupsSelectors.groups);
    const timezoneId = useTypedSelector(GroupsSelectors.timezoneId);
    const limitedData = useTypedSelector(Selectors.limitedData);
    const currentChildGroupId = useTypedSelector(state =>
        ChildSelectors.currentChildGroupId(state, {id: props.match.params.childId}),
    );
    const isPinApp = useTypedSelector(LoginSelectors.isPinApp);
    const whitelabelConfiguration = useTypedSelector(WhitelabelSelectors.whiteLabelConfiguration);
    const accessToken = useTypedSelector(LoginSelectors.accessToken);
    const pickupTime = useTypedSelector(state => Selectors.pickupTime(state, {child}));

    React.useEffect(() => {
        if (!isPinApp) {
            fetchChild.dispatch(accessToken, match.params.childId);
        }
    }, [accessToken, match.params.childId, isPinApp]);

    const handleChildSwitchRoom = (roomId: string) => {
        if (child?.lastCheckIn?.pickupTime && timezoneId) {
            const pickupTime = ZonedDateTime.parse(child.lastCheckIn.pickupTime);
            const currentTime = ZonedDateTime.now();
            if (pickupTime < currentTime) {
                dispatch(GlobalEventActions.updateError.action(translate('pickupBeforeNowError'), false, 6000));
                return;
            }
        }

        if (child && child.lastCheckIn && timezoneId) {
            const successMessage = translate('hasMovedRoom', {personName: child.name.fullName});
            const parsedPickupTime = child.lastCheckIn.pickupTime
                ? ZonedDateTime.parse(child.lastCheckIn.pickupTime).withZoneSameInstant(timezoneId)
                : null;
            ChildCheckinActions.checkInChild.dispatch({
                accessToken,
                childId: child.id,
                pickupRelationId: child.lastCheckIn.pickupRelationId,
                selectedHours: parsedPickupTime ? parsedPickupTime.hour() : null,
                selectedMinutes: parsedPickupTime ? parsedPickupTime.minute() : null,
                successMessage,
                groupId: roomId,
            });
        }
    };

    const roomName = React.useMemo(() => {
        return groups.find(g => g.groupId === currentChildGroupId)?.title;
    }, [groups, currentChildGroupId]);

    return (
        <PageContainer>
            {isPinApp ? <PinHeader whitelabelConfiguration={whitelabelConfiguration} /> : <Header />}
            {!isFetching && child ? (
                <>
                    <PageBodyContainer mt={16}>
                        <PersonSelect activeChildId={match.params.childId} />
                        <ChildOverview child={child} limitedData={limitedData} />
                        {pickupTime && roomName ? (
                            <Text variant="h6" color="n0" textAlign="center" mt={6}>
                                {translate('leavingTimeRoom', {
                                    time: pickupTime,
                                    room: roomName,
                                })}
                            </Text>
                        ) : null}
                        {canSwitchRoom && (
                            <Box mx={4} my={3}>
                                <Text variant="h6" color="n0" emphasized>
                                    {translate('switchRoom')}
                                </Text>
                                <CardsContainer mt={2}>
                                    {groups.map(group => (
                                        <Card
                                            key={group.groupId}
                                            image={group.image.large}
                                            displayTitle={group.title}
                                            onClick={() => handleChildSwitchRoom(group.groupId)}
                                            selected={group.groupId === currentChildGroupId}
                                        />
                                    ))}
                                </CardsContainer>
                            </Box>
                        )}
                    </PageBodyContainer>
                    <CheckOutSubmit childId={childId} childName={child.name.firstName} />
                </>
            ) : (
                <Spinner color="white" centered />
            )}
        </PageContainer>
    );
};
