import {ofType} from 'redux-observable';
import {of, merge} from 'rxjs';
import {mergeMap, catchError, flatMap} from 'rxjs/operators';
import i18next from 'i18next';

import {combineEpics} from 'web-app/util/redux-observable';
import EmployeeApi from 'signin-app/api/employee-api';
import * as GlobalEventActions from 'signin-app/global-event/actions';
import translate from 'signin-app/helpers/translate';
import {type Action} from 'web-app/util/redux/action-types';
import {type RootState} from 'signin-app/redux/main-reducer';
import {makeEmployeeName} from 'signin-app/employee/selectors';
import {type SignInEpic} from 'signin-app/redux/types';
import {TrackingEvents} from 'web-app/util/famlytics/events';
import {track} from 'signin-app/util/famlytics';
import {isPinApp} from 'signin-app/login/selectors';

import * as Actions from './actions';

const checkOutEmployee: SignInEpic = (action$, state$) =>
    action$.pipe(
        ofType(Actions.checkOutEmployee.type),
        mergeMap(({payload}: ReturnType<typeof Actions.checkOutEmployee.action>) => {
            const {accessToken, employeeId} = payload;

            return EmployeeApi.checkOut(accessToken, employeeId).pipe(
                flatMap(response => {
                    const state = state$.value;
                    const getEmployeeName = makeEmployeeName();
                    const employeeName = getEmployeeName(state, {employeeId});

                    track(TrackingEvents.SIGNIN_SCREEN_STAFF_MEMBER_CHECKED_OUT, {
                        openedFrom: 'Sign out screen',
                        usedPinCode: isPinApp(state),
                    });

                    return [
                        Actions.checkOutEmployeeSuccess.action(response),
                        GlobalEventActions.updateSuccess.action(
                            translate('signOutSuccess', {personName: employeeName}),
                        ),
                    ];
                }),
                catchError(e => {
                    const errroMessage = e.error ? e.error : i18next.t('somethingWentWrong');
                    return merge(
                        of(GlobalEventActions.updateError.action(errroMessage)),
                        of(Actions.checkOutEmployeeFailed.action(e)),
                    );
                }),
            );
        }),
    );

export default combineEpics<Action<any>, Action<any>, RootState>(checkOutEmployee);
