import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select-v1';
import mergeClassNames from 'classnames';
import classNames from 'classnames';
import i18next from 'i18next';
import styled, {css} from 'styled-components';

import hexToRGB from 'web-app/util/hex-to-rgb';
import PlatformHelper from 'web-app/helpers/platform-helper';
import Highlighter from 'web-app/react/components/highlighter/highlighter';
import ProfileImage from 'web-app/react/components/profile-image/profile-image';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import IconSwitcher from 'web-app/react/components/icons/legacy/ember-icon-switcher';
import NewLookTheme from 'web-app/styleguide/themes/new-look';
import Arrow from 'web-app/react/components/icons/legacy/arrows';
import {Flex} from 'web-app/react/components/layout/layout';
import {getSpacing, s2} from 'web-app/styleguide/spacing';
import {hasValue} from 'web-app/util/typescript';
import {CLASSNAME_PREFIX} from 'web-app/react/global-styles/select-style';
import {uniquenessFilter} from 'web-app/util/array';

import MenuRenderer from './menu-renderer';
import extendedFilterOptions from './extended-filter-options';
import SelectCreatable from './select-creatable';

export const SelectAppearance = {
    SIMPLE: 'simple',
    DEFAULT: 'default',
};

const StyledOption = styled(Flex)`
    div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    ${props =>
        props.clickable
            ? css`
                  background-color: rgba(${Object.values(hexToRGB(props.theme.analogue3)).join(',')}, 0.05);

                  .${`${CLASSNAME_PREFIX}selectOption__title`} {
                      color: ${props.theme.analogue2};
                  }
              `
            : ''}
`;

const height = '34px';

const SharedStyles = css`
    &.${`${CLASSNAME_PREFIX}Select`} {
        font-size: ${props => props.theme.fontConfiguration.sizes.Caption};

        .Select-placeholder,
        &.Select--single > .Select-control .Select-value,
        &.Select--single .Select-input > inppu {
            line-height: ${height};
        }

        .Select-input {
            height: ${height};
            line-height: ${height};
        }

        .Select-control {
            transition: border-color 0.15s;
            border-color: ${props => props.theme.borderConfiguration.defaultColor};
            color: ${props => props.theme.text};
            border-radius: 4px;
        }

        &:hover {
            .Select-control {
                border-color: ${props => props.theme.borderConfiguration.hover};
            }
        }

        &.is-focused {
            &:not(.is-open) > .Select-control {
                box-shadow: none;
            }
            .Select-control {
                border-color: ${props => props.theme.borderConfiguration.focus};
            }
        }

        .Select-placeholder {
            color: ${props => props.theme.textDisabled};
            // padding is set to "0 10px" by default from ReactSelect.
            padding: 0 ${getSpacing(s2)};
        }
        .Select-input {
            // margin-left is set to 10px by default from ReactSelect.
            // This is just to align it with the gutter we have in other input components.
            margin-left: ${getSpacing(s2)};
            padding-left: 0;
        }

        &.has-value.Select--single > .Select-control .Select-value .Select-value-label,
        &.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
            color: ${props => props.theme.text};
        }

        &.Select--multi.has-value .Select-control {
            .Select-multi-value-wrapper {
                margin-bottom: 0 !important;
                padding-top: 0;
                overflow: hidden;
                width: 100%;
                display: block;
                padding-bottom: 6px;
            }

            .Select-input {
                width: 0;
                height: 24px;
                line-height: 24px;

                > input {
                    padding: 0 4px 0 4px;
                }
            }
        }

        &.is-disabled {
            .Select-control {
                border-color: transparent;
                background-color: ${props => props.theme.borderConfiguration.disabled};
                color: ${props => props.theme.textDisabled};
            }

            &.has-value.Select--single > .Select-control .Select-value .Select-value-label,
            &.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
                color: ${props => props.theme.textDisabled};
            }

            .Select-input {
                visibility: hidden;
            }
        }

        ${props =>
            props.ispassive
                ? css`
                      && {
                          .Select-control {
                              padding-left: 0;
                              border-color: transparent;
                              background: transparent;

                              .Select-placeholder,
                              .Select-value {
                                  padding-left: 0;
                              }

                              .Select-arrow-zone {
                                  display: none;
                              }
                          }

                          &.has-value.Select--single > .Select-control .Select-value .Select-value-label,
                          &.has-value.is-pseudo-focused.Select--single
                              > .Select-control
                              .Select-value
                              .Select-value-label {
                              color: ${props => props.theme.text};
                          }
                      }
                  `
                : ''}
    }
`;

const StyledSelectCreatable = styled(SelectCreatable)`
    ${SharedStyles}

    /**
    There seems to be a general issue with all our createable Selects where
    long inputs would make the Select input grow regardless of the container's style.

    This attempts to fix that by limiting the width of the value wrapper by wrapping it in a flex
    layout over hiding any overflow.
     */
    .Select-control {
        display: flex;
        align-items: center;
    }
    .Select-multi-value-wrapper,
    .Select-value-wrapper {
        overflow: hidden;
        flex-grow: 1;
    }
`;

const StyledReactSelect = styled(ReactSelect)`
    ${SharedStyles}
`;

const SelectedValueContainer = styled.div`
    display: inline-flex;
    align-items: center;
    border-radius: 23px;
    background-color: ${props => props.theme.secondary};
    color: white;
    margin-left: 6px;
    margin-top: 6px;
    max-width: 100%;

    ${props =>
        props.disabled
            ? css`
                  opacity: 0.5;
              `
            : ''}
`;

const SelectedValueLabel = styled.span`
    display: inline-block;
    color: ${props => props.theme.invertedText};
    padding: 4px 8px;
    font-size: ${props => props.theme.fontConfiguration.sizes.Small};
    font-family: ${props => `Inter, ${props.theme.fontConfiguration.family}`};
    font-weight: 500;
    line-height: 1.33;
    word-break: break-word;
`;

const StyledCloseButton = styled.span`
    cursor: pointer;
    padding: 5px 8px;
    border-radius: 0 100px 100px 0;

    &:hover {
        background-color: ${props => props.theme.active};
    }
`;

/**
 * Inspired by https://github.com/JedWatson/react-select/blob/v1.x/src/Value.js#L64
 */
const MultiValueComponent = ({option}) => {
    const isDragging = React.useRef(false);
    const handleRemove = React.useCallback(
        e => {
            if (isDragging.current) {
                return;
            }
            e.preventDefault();
            e.stopPropagation();
            option.onRemove(option.value);
        },
        [option],
    );
    const handleTouchStart = React.useCallback(() => {
        isDragging.current = false;
    }, []);
    const handleTouchMove = React.useCallback(() => {
        isDragging.current = true;
    }, []);

    return (
        <SelectedValueContainer disabled={option.disabled} data-e2e-class="Multi-select-container">
            <SelectedValueLabel>{option.value.label}</SelectedValueLabel>
            {!option.disabled && (
                <StyledCloseButton
                    onTouchMove={handleTouchMove}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleRemove}
                    onClick={handleRemove}
                    data-e2e-class="Multi-select-close-button"
                >
                    ×
                </StyledCloseButton>
            )}
        </SelectedValueContainer>
    );
};
MultiValueComponent.propTypes = {
    option: PropTypes.shape({
        disabled: PropTypes.bool,
        value: PropTypes.object,
        onRemove: PropTypes.func,
    }).isRequired,
};
const MultiValueComponentFunction = option => <MultiValueComponent option={option} />;

const StyleNativeSelect = styled.select`
    border-radius: 4px;
    font-size: ${props => props.theme.fontConfiguration.sizes.Caption};
    border-color: ${props => props.theme.borderConfiguration.defaultColor};
    line-height: 36px;
    height: 36px;
    color: ${props => props.theme.text};

    &:hover {
        border-color: ${props => props.theme.borderConfiguration.hover};
    }

    &:focus,
    &:active {
        border-color: ${props => props.theme.borderConfiguration.focus};
    }

    &:disabled {
        border-color: transparent;
        background-color: ${props => props.theme.borderConfiguration.disabled};
        color: ${props => props.theme.textDisabled};
    }

    ${props =>
        props.ispassive
            ? css`
                  && {
                      color: ${props => props.theme.text};
                  }
              `
            : ''}

    ${props =>
        props.placeholderActive
            ? css`
                  && {
                      color: ${props => props.theme.textDisabled};
                  }
              `
            : ''}
`;

export const StyledTitleContainer = styled.div``;
export const StyledSubtitleContainer = styled.div``;

class Select extends React.Component {
    state = {options: this.props.options};

    constructor(props) {
        super(props);
        this.promptTextCreator = this.promptTextCreator.bind(this);
        this.menuRenderer = this.menuRenderer.bind(this);
        this.arrowRenderer = this.arrowRenderer.bind(this);
        this.optionRenderer = this.optionRenderer.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (state.options !== props.options) {
            if (props.replaceCurrentOptions) {
                return {options: props.options.filter(uniquenessFilter(option => option.value))};
            }
            return {options: [...state.options, ...props.options].filter(uniquenessFilter(option => option.value))};
        }

        return null;
    }

    optionRenderer(option, index, inputValue) {
        const extended = this.props.extendedOptionRender;

        const subTitleNode = () => {
            if (extended && option.subtitle) {
                return (
                    <StyledSubtitleContainer className={`${CLASSNAME_PREFIX}selectOption__subtitle`}>
                        <Highlighter
                            searchWords={[inputValue]}
                            textToHighlight={option.subtitle}
                            highlightTag="strong"
                        />
                    </StyledSubtitleContainer>
                );
            } else if (extended && option.subtitleNode) {
                return <div>{option.subtitleNode}</div>;
            } else {
                return null;
            }
        };

        const secondSubtitleNode = () => {
            if (extended && option.secondSubtitle) {
                return (
                    <div className={`${CLASSNAME_PREFIX}selectOption__subtitle`}>
                        <Highlighter
                            searchWords={[inputValue]}
                            textToHighlight={option.secondSubtitle}
                            highlightTag="strong"
                        />
                    </div>
                );
            } else if (extended && option.secondSubtitleNode) {
                return <div>{option.secondSubtitleNode}</div>;
            } else {
                return null;
            }
        };

        const imageNode = () => {
            if (!extended) {
                return null;
            } else if (option.icon) {
                return (
                    <div className={`${CLASSNAME_PREFIX}selectOption__avatar`}>
                        <IconSwitcher icon={option.icon.icon} size={30} fill={option.icon.fill} />
                    </div>
                );
            } else if (option.image) {
                return (
                    <div className={`${CLASSNAME_PREFIX}selectOption__avatar`}>
                        <ProfileImage src={option.image} />
                    </div>
                );
            } else {
                return null;
            }
        };

        return (
            <StyledOption
                className={classNames('clearfix', `${CLASSNAME_PREFIX}selectOption`)}
                clickable={option.clickable}
                direction="row"
                align="center"
            >
                {imageNode()}
                <div>
                    <StyledTitleContainer
                        className={`${CLASSNAME_PREFIX}selectOption__title`}
                        data-e2e-class="select-option-title"
                    >
                        <Highlighter
                            searchWords={[inputValue]}
                            textToHighlight={this.props.labelKey ? option[this.props.labelKey] : option.label}
                            highlightTag="strong"
                        />
                    </StyledTitleContainer>
                    {subTitleNode()}
                    {secondSubtitleNode()}
                </div>
            </StyledOption>
        );
    }

    arrowRenderer() {
        return <Arrow size={16} direction="south" className={this.props.arrowClass} fill={NewLookTheme.text} />;
    }

    menuRenderer(passthroughProps) {
        return (
            <MenuRenderer
                optimized={this.props.optimized}
                internalised={this.props.internalised}
                hideMoreLabel={this.props.hideMoreLabel}
                {...passthroughProps}
            />
        );
    }

    promptTextCreator(option) {
        return i18next.t('createOption', {option});
    }

    get placeholder() {
        return this.props.placeholder || i18next.t('select');
    }

    get hasValue() {
        return hasValue(this.props.value) && this.props.value !== '';
    }

    render() {
        const filterOptions =
            this.props.filterOptions || this.props.extendedOptionRender ? extendedFilterOptions : undefined;
        const options = this.state.options;

        const props = {
            /**
             * In the mobile app, the input is reset by default when you scroll the view containing the element
             * By setting onBlurResetsInput and onCloseResetsInput to false we can circumvent this behavior
             */
            onBlurResetsInput: !PlatformHelper.isMobileApp(),
            onCloseResetsInput: !PlatformHelper.isMobileApp(),

            arrowRenderer: this.arrowRenderer,
            optionRenderer: this.optionRenderer,
            scrollMenuIntoView: false,
            openAfterFocus: true,
            noResultsText: i18next.t('account.noResults'),
            valueRenderer: this.props.extendedValueRender ? this.optionRenderer : undefined,
            valueComponent: this.props.multi ? MultiValueComponentFunction : undefined,
            menuRenderer: this.menuRenderer,
            promptTextCreator: this.promptTextCreator,

            ...this.props,
            options,
            placeholder: this.placeholder,
            filterOptions,

            className: mergeClassNames(
                `${CLASSNAME_PREFIX}Select`,
                this.props.className,
                {[`${CLASSNAME_PREFIX}Select--tall`]: this.props.extendedValueRender && !this.props.valueRenderer},
                {[`${CLASSNAME_PREFIX}Select--expand`]: this.props.expand},
                this.props.extendedOptionRender ? `${CLASSNAME_PREFIX}Select--extended` : null,
                'ignore-hammer-pull-to-refresh',
                `${CLASSNAME_PREFIX}Select--appearance-${this.props.appearance}`,
            ),
            ref: 'select',

            // Enabled this when debugging the options menu
            // onClose: () => this.refs.select.setState({isOpen: true}),

            onChange: value => {
                this.props.onChange(value);
                if (this.props.multi) {
                    this.refs.select.setState({isOpen: false});
                }
            },
            onNewOptionClick: option => {
                const onOptionSelected = newValue => {
                    const newOptions = [...options, option];
                    this.props.onChange(newValue);
                    this.setState({
                        options: newOptions,
                    });

                    if (this.props.onNewOptionClick) {
                        this.props.onNewOptionClick(option);
                    }
                };

                if (this.props.multi) {
                    const {value} = this.props;
                    const newValue =
                        typeof value === 'string'
                            ? value.concat(value ? this.props.delimiter : '', option.value)
                            : option;
                    onOptionSelected(this.props.newValueTransform ? this.props.newValueTransform(newValue) : newValue);
                    this.refs.select.setState({isOpen: false});
                } else {
                    onOptionSelected(
                        this.props.newValueTransform ? this.props.newValueTransform(option.value) : option.value,
                    );
                }

                if (this.props.closeOnNew) {
                    this.refs.select.setState({isOpen: false});
                }
            },
        };

        if (PlatformHelper.shouldUseNativeInputFields() && !props.ignorePlatform && !props.multi) {
            // ignore props not needed for select element
            const {options, className, value, autoFocus, disabled, form, multiple, name, required, clearable, size} =
                props;

            const placeholderActive = !this.hasValue;
            const mobileAppProps = {
                className,
                autoFocus,
                disabled,
                form,
                multiple,
                name,
                required,
                size,
                placeholderActive,
            };

            // Special handling of the HTML <select> element when the field is uncontrolled and
            // unclearable. In this case we want to ensure that "placeholder element" is selected.
            // If this isn't done, the native HTML <select> element will set the selected option
            // to the first non-disabled option. When trying to select that same first non-disabled
            // option, the `onChange` handler will not be called  (as the option is not being changed)
            if (!mobileAppProps.clearable) {
                mobileAppProps.value = value ?? '';
            }

            mobileAppProps.onChange = event => {
                const value = event.target.value;
                const option = options.find(opt => {
                    // On iOS integer values are converted to strings by Safari. This mitigates that problem
                    if (Number.isInteger(opt.value)) {
                        return opt.value === Number.parseInt(value, 10);
                    } else {
                        return opt.value === value;
                    }
                });

                this.props.onChange(option);
            };

            return (
                <StyleNativeSelect
                    {...mobileAppProps}
                    data-e2e-id={this.props['data-e2e-id']}
                    data-intercom-target={this.props['data-intercom-target']}
                >
                    <option value="" disabled={!clearable} hidden={this.hasValue && !clearable}>
                        {this.placeholder}
                    </option>
                    {options &&
                        options.map(opt => (
                            <option key={opt.value} value={opt.value}>
                                {opt.label}
                            </option>
                        ))}
                </StyleNativeSelect>
            );
        }

        const spinnerSize = 25;
        const spinnerContainerStyle = {
            position: 'absolute',
            right: '6px',
            marginTop: `-${spinnerSize / 2}px`,
            top: '50%',
        };

        return (
            <div
                data-e2e-id={this.props['data-e2e-id']}
                className={classNames(`${CLASSNAME_PREFIX}container`, {
                    [props.containerClassName]: props.containerClassName,
                })}
                data-intercom-target={this.props['data-intercom-target']}
            >
                {this.props.allowCreate ? <StyledSelectCreatable {...props} /> : <StyledReactSelect {...props} />}
                {this.props.isLoading ? (
                    <div style={spinnerContainerStyle}>
                        <Spinner size={spinnerSize} />
                    </div>
                ) : null}
            </div>
        );
    }
}

Select.propTypes = {
    theme: PropTypes.object,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    filterOptions: PropTypes.func,
    arrowRenderer: PropTypes.func,
    value: PropTypes.any,
    options: PropTypes.array,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    arrowClass: PropTypes.string,
    labelKey: PropTypes.string,
    multi: PropTypes.bool,
    clearable: PropTypes.bool,
    extendedOptionRender: PropTypes.bool,
    extendedValueRender: PropTypes.bool,
    valueRenderer: PropTypes.any,
    ignorePlatform: PropTypes.bool,
    optimized: PropTypes.bool,
    expand: PropTypes.bool,
    ispassive: PropTypes.string,
    isLoading: PropTypes.bool,
    allowCreate: PropTypes.bool,
    simpleValue: PropTypes.bool,
    internalised: PropTypes.bool,
    onNewOptionClick: PropTypes.func,
    closeOnNew: PropTypes.bool,
    delimiter: PropTypes.string,
    replaceCurrentOptions: PropTypes.bool,
    appearance: PropTypes.oneOf([SelectAppearance.SIMPLE, SelectAppearance.DEFAULT]),
    newValueTransform: PropTypes.func,
    hideMoreLabel: PropTypes.bool,
    ['data-e2e-id']: PropTypes.string,
    ['data-intercom-target']: PropTypes.string,
};

Select.defaultProps = {
    ignorePlatform: false,
    clearable: true,
    optimized: false,
    delimiter: ',',
    appearance: SelectAppearance.DEFAULT,
    hideMoreLabel: false,
    replaceCurrentOptions: false,
};

export default Select;
