import gql from 'graphql-tag';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchStaffHoursExactAttendancePermissions = gql`
    query FetchStaffHoursExactAttendancePermissions($siteSetId: InstitutionSetId!) {
        staffhours {
            featureSettings {
                exactAttendance(siteSetId: $siteSetId) {
                    canOptIn
                }
            }
        }
    }
`;
