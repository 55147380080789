import {type QueryHookOptions} from '@apollo/client';

import {FetchEmployeePermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-employee-permissions.query';
import {
    type FetchEmployeePermissionsQuery,
    type FetchEmployeePermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-employee-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchEmployeePermissions(
    options: QueryHookOptions<FetchEmployeePermissionsQuery, FetchEmployeePermissionsQueryVariables>,
): ImmerWrappedQueryResult<FetchEmployeePermissionsQuery, FetchEmployeePermissionsQueryVariables> {
    return useStatQuery<FetchEmployeePermissionsQuery, FetchEmployeePermissionsQueryVariables>(
        FetchEmployeePermissions,
        options,
    );
}
