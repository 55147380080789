import {type Breakpoint} from '@mui/material/styles';

import {hasValue} from 'modern-famly/util';

export type RawValue<T> = T;
export type BreakpointsValue<T> = {[B in Breakpoint]?: T};
export type ResponsiveValue<T> = BreakpointsValue<T> | RawValue<T> | undefined;

export const map = <T, U>(responsiveValue: ResponsiveValue<T>, mapFn: (val: T) => U): ResponsiveValue<U> => {
    if (!hasValue(responsiveValue)) {
        return undefined;
    }

    if (hasBreakpointsValue(responsiveValue)) {
        const [base, mobileLandscape, tabletPortrait, tabletLandscape, laptop] = toArray(responsiveValue).map(v =>
            !hasValue(v) ? v : mapFn(v),
        );

        return {base, mobileLandscape, tabletPortrait, tabletLandscape, laptop};
    }

    return mapFn(responsiveValue);
};

const toArray = <T>(bpv: BreakpointsValue<T>) => {
    const {base, mobileLandscape, tabletPortrait, tabletLandscape, laptop} = bpv;

    return [base, mobileLandscape, tabletPortrait, tabletLandscape, laptop];
};

const stringIsBreakpoint = (str: string): str is Breakpoint => {
    switch (str) {
        case 'base':
        case 'mobileLandscape':
        case 'tabletPortrait':
        case 'tabletLandscape':
        case 'laptop':
            return true;
        default:
            return false;
    }
};

const hasBreakpointsValue = (prop: ResponsiveValue<any>): prop is BreakpointsValue<any> => {
    return typeof prop === 'object' && Object.keys(prop).some(stringIsBreakpoint);
};
