import gql from 'graphql-tag';

import {StatPermissionCustomFormsInterest} from '../fragment/stat-permission-custom-forms-interest.fragment';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchCustomFormsInterestPermissions = gql`
    query FetchCustomFormsInterestPermissions($institutionSetId: SiteSetId!) {
        attendance {
            management {
                customRegistrationForm {
                    interest(siteSetId: $institutionSetId) {
                        ...StatPermissionCustomFormsInterest
                    }
                }
            }
        }
    }
    ${StatPermissionCustomFormsInterest}
`;
