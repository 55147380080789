import React from 'react';

import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useStatFeatures} from '../../features/use-stat-features';

/**
 * Do not use this query directly!
 */
export function useMilkReportPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['childAttendance']['header']['export']['milkReport'] & {loading: boolean} {
    const {institutionSetId} = payload;
    const [features, loading] = useStatFeatures({
        institutionId: institutionSetId,
    });
    return React.useMemo(
        () => ({
            loading,
            canCreate: false,
            canRead: features.milkReport.isEnabled,
            canUpdate: false,
            canDelete: false,
        }),
        [features.milkReport.isEnabled, loading],
    );
}
