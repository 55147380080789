import {Record} from 'immutable';

export enum ButtonType {
    Primary = 'primary',
    Confirm = 'confirm',
}

interface IButton {
    background: string;
    color: string;
}

export class ButtonTypeConfiguration extends Record<IButton>({
    background: '',
    color: '',
}) {}

type ButtonConfigurationOptions = {[K in ButtonType]: ButtonTypeConfiguration} & {
    radius: number;
};

export class ButtonConfiguration extends Record<ButtonConfigurationOptions>({
    radius: 0,
    [ButtonType.Primary]: new ButtonTypeConfiguration(),
    [ButtonType.Confirm]: new ButtonTypeConfiguration(),
}) {}
