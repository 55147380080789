import {type QueryHookOptions} from '@apollo/client';

import {FetchWorkTagsPermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-work-tags-permissions.query';
import {
    type FetchWorkTagsPermissionsQuery,
    type FetchWorkTagsPermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-work-tags-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchWorkTagsPermissions(
    options: QueryHookOptions<FetchWorkTagsPermissionsQuery, FetchWorkTagsPermissionsQueryVariables>,
): ImmerWrappedQueryResult<FetchWorkTagsPermissionsQuery, FetchWorkTagsPermissionsQueryVariables> {
    return useStatQuery<FetchWorkTagsPermissionsQuery, FetchWorkTagsPermissionsQueryVariables>(
        FetchWorkTagsPermissions,
        options,
    );
}
