import React from 'react';

import {NewLookTheme} from 'web-app/styleguide/themes';

import {type IconProps} from '../icon-props';
import {G, SVG} from '../svg';
import {defaultPropTypes} from './default-prop-types';
import {type IconComponent} from './icon-component';

/**
 * @deprecated use MF icons
 */
export const Info: IconComponent<IconProps> = ({fill, ...rest}) => (
    <SVG viewBox="0 0 24 24" {...rest}>
        <G fill={fill}>
            <path d="M12,4 C7.6,4 4,7.6 4,12 C4,16.4 7.6,20 12,20 C16.4,20 20,16.4 20,12 C20,7.6 16.4,4 12,4 Z M13,16 L11,16 L11,11 L13,11 L13,16 Z M12,10 C11.4,10 11,9.6 11,9 C11,8.4 11.4,8 12,8 C12.6,8 13,8.4 13,9 C13,9.6 12.6,10 12,10 Z" />
        </G>
    </SVG>
);
Info.propTypes = defaultPropTypes;
Info.defaultProps = {
    fill: NewLookTheme.text,
};
Info.isPrivate = true;
export default Info;
