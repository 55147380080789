import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import DateTimePicker from 'web-app/react/components/form-elements/datetimepicker/datetimepicker';

const FormDateTimePicker = props => {
    const {onChange, input, ...rest} = props;

    return (
        <DateTimePicker
            {...input}
            {...rest}
            onChange={newValue => {
                const isValidMoment = moment.isMoment(newValue) && newValue.isValid();
                const validValue = isValidMoment ? newValue : '';

                if (onChange) {
                    onChange(validValue);
                }
                return input.onChange(validValue);
            }}
        />
    );
};
FormDateTimePicker.propTypes = {
    onChange: PropTypes.func,
    input: PropTypes.object,
};

export default FormDateTimePicker;
