import {type StatCrudPermission} from './internal/stat-crud-permission';
import {type StatPermissions} from './internal/stat-permissions';
import {type StatPermissionsPath} from './stat-permissions-path';

/**
 * Accesses the given permissions object by the given permissionPath in the form of e.g.
 * `customRegistrationForm.enrollment` and returns the associated Crud permission
 *
 * @param permissions the stat permission object
 * @param permissionPath the path leading to the crud permissions to be resolved
 * @returns the crud permissions in the given path
 */
export function resolvePermissionPath(
    permissions: StatPermissions,
    permissionPath: StatPermissionsPath,
): StatCrudPermission {
    return permissionPath.split('.').reduce((prev, curr) => prev?.[curr], permissions) as any;
}
