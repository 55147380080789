import React from 'react';

import {getBehaviorsCollectionFromGraphQL} from 'web-app/behaviors/behaviors-collection';
import employeeBehaviorIds from 'web-app/behaviors/employee-behavior-ids';

import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';
import {useFetchStaffProfileScheduleWidgetPermissions} from './queries/use-fetch-staff-profile-schedule-widget-permissions';

/**
 * Do not use this query directly!
 */
export function useStaffProfileScheduleWidgetPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['staffProfile']['scheduleWidget'] & {loading: boolean} {
    const {data, loading} = useFetchStaffProfileScheduleWidgetPermissions({
        variables: {
            siteSetId: payload.institutionSetId,
            employeeId: payload.employeeId as string,
            institutionId: payload.institutionSetId,
        },
        skip: !payload.shouldQuery || !payload.employeeId || !payload.institutionSetId,
    });

    const scheduleWidgetPermissions = useCrudPermissionAdapter(data?.staffhours.management.schedules);
    const staffRotasPermissions = React.useMemo(() => {
        const hasStaffRotasBehavior = getBehaviorsCollectionFromGraphQL(data?.staffhours.behaviors ?? []).includes(
            employeeBehaviorIds.CanSeeDetailsFromProfile,
        );
        return {
            canRead: hasStaffRotasBehavior,
            canUpdate: hasStaffRotasBehavior,
            canDelete: false,
            canCreate: false,
        };
    }, [data?.staffhours.behaviors]);
    const attendancePermissions = useCrudPermissionAdapter(data?.staffhours.management.attendance);
    const staffHoursPermissions = useCrudPermissionAdapter(data?.staffhours.management.totals);

    return React.useMemo(
        () => ({
            loading,
            ...scheduleWidgetPermissions,
            staffRotasButton: staffRotasPermissions,
            weeklyDetailsButton: attendancePermissions,
            staffHoursButton: staffHoursPermissions,
        }),
        [attendancePermissions, loading, scheduleWidgetPermissions, staffHoursPermissions, staffRotasPermissions],
    );
}
