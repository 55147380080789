import React from 'react';

const formPrepare =
    <TOriginalProps, TAdditionalProps>(transformProps: (props: TOriginalProps) => TAdditionalProps) =>
    (Component: React.ComponentType<React.PropsWithChildren<TOriginalProps & TAdditionalProps>>) => {
        const InnerComponent: React.FC<React.PropsWithChildren<TOriginalProps>> = props => {
            const transformedProps = transformProps(props);
            if (typeof transformedProps !== 'object') {
                throw new Error('The function passed to formPrepare did not return an object');
            }
            return <Component {...props} {...transformedProps} />;
        };

        return InnerComponent;
    };

export default formPrepare;
