import React from 'react';
import Box from '@mui/material/Box';
import {checkboxClasses} from '@mui/material/Checkbox';

import {StandaloneCheckbox, type StandaloneCheckboxProps} from 'modern-famly/components/input';
import {Text} from 'modern-famly/components/data-display/text';
import {type DataProps, useDataProps} from 'modern-famly/components/util';

export type MobileSelectAllProps = {
    label: string;
} & Omit<StandaloneCheckboxProps, 'size'> &
    DataProps;

export const MobileSelectAll = ({label, ...rest}: MobileSelectAllProps) => {
    const dataProps = useDataProps(rest);

    return (
        <Box
            component="label"
            sx={({modernFamlyTheme}) => ({
                display: 'inline-flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: rest.checked ? modernFamlyTheme.colorPalette.p75 : undefined,
                borderRadius: 2,
                padding: 3,

                // Override the padding of the checkbox - otherwise it'd require a lof of manual spacing adjustments
                // The touch target is still large enough as we're wrapping the checkbox in a label
                [`.${checkboxClasses.root}`]: {
                    padding: 0,
                },
            })}
            {...dataProps}
        >
            <StandaloneCheckbox {...rest} size="compact" />
            <Text variant="body-small" color="n400">
                {label}
            </Text>
        </Box>
    );
};
