import gql from 'graphql-tag';

import {StatPermissionStaffAttendanceByEmployeeManagement} from '../fragment/stat-permission-staffattendance-by-employee.fragment';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchStaffAttendanceByEmployeePermissions = gql`
    query FetchStaffAttendanceByEmployeePermissions($institutionSetId: SiteSetId!, $employeeId: EmployeeId!) {
        staffhours {
            management {
                staffAttendance {
                    employee(siteSetId: $institutionSetId, employeeId: $employeeId) {
                        ...StatPermissionStaffAttendanceByEmployeeManagement
                    }
                }
            }
        }
    }
    ${StatPermissionStaffAttendanceByEmployeeManagement}
`;
