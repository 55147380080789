import {useFormControl as useMuiFormControl} from '@mui/material/FormControl';

/**
 * Gets the state of the FormControl that the component is being rendered inside
 */
export const useFormControl = () => {
    const {disabled, error} = useMuiFormControl() || {};

    return {disabled, error};
};
