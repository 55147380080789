import React from 'react';
import MuiFormControl, {type FormControlProps as MuiFormControlProps} from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';

import {FormLabel, type FormLabelProps as MuiFormLabelProps} from 'modern-famly/components/input/form-label';
import {Text} from 'modern-famly/components/data-display';
import {FormErrorText, FormHelperText} from 'modern-famly/components/input/form-helper-text/form-helper-text';
import {useDataProps} from 'modern-famly/components/util';
import {useTranslation} from 'modern-famly/system/use-translation';

import {FormControlTestAttributes} from './form-control-test-attributes';

export type FormControlProps = {
    /**
     * The label to be displayed describing the input.
     */
    label?: string;

    /**
     * The props to be forwarded to the MUI FormLabel describing the input.
     */
    labelProps?: Pick<MuiFormLabelProps, 'htmlFor'>;

    /**
     * Adds the required * (asterix) to the field, if true.
     *
     * Note: this does not validate, that the field is actually present on the form data!
     */
    required?: MuiFormControlProps['required'];

    /**
     * Helper text displayed below the input,
     */
    helperText?: string;

    /**
     * Message that describes the input field's error.
     */
    errorMessage?: string;

    /**
     * Callback that's invoked when user indicates they want to clear the field,
     *
     * When provided, a clear button is displayed.
     */
    onClear?: () => void;
} & Pick<MuiFormControlProps, 'disabled' | 'fullWidth' | 'children'>;

const ClearButton = styled('button')`
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
`;

export const FormControl = (props: FormControlProps) => {
    const {label, labelProps, helperText, errorMessage, children, onClear, disabled, ...rest} = props;
    const clearButtonText = useTranslation('FormControl.clear');

    const dataProps = useDataProps(rest);

    return (
        <MuiFormControl disabled={disabled} {...rest} error={Boolean(errorMessage)} {...dataProps}>
            {props.label || props.onClear ? (
                <Stack direction="row" justifyContent="space-between" marginBottom={1} gap={2}>
                    <FormLabel htmlFor={labelProps?.htmlFor}>{label}</FormLabel>
                    {onClear ? (
                        <ClearButton
                            onClick={onClear}
                            disabled={disabled}
                            data-e2e-class="form-control-clear"
                            type="button"
                        >
                            <Text variant="micro" emphasized color={disabled ? 'n200' : 'p400'}>
                                {clearButtonText}
                            </Text>
                        </ClearButton>
                    ) : null}
                </Stack>
            ) : null}
            {children}
            {errorMessage || helperText ? (
                <Box mt={1}>
                    {errorMessage ? (
                        <FormErrorText data-e2e-class={FormControlTestAttributes.errorText}>
                            {errorMessage}
                        </FormErrorText>
                    ) : null}
                    {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
                </Box>
            ) : null}
        </MuiFormControl>
    );
};
