import React from 'react';
import {useMutation} from '@apollo/client';

import {createQRCodeLink} from 'web-app/react/modals-container/signin-modal/signin-app-helpers';
import {useTypedSelector} from 'signin-app/components/hooks';
import * as WhitelabelSelectors from 'signin-app/whitelabel/selectors';

import * as Queries from './queries';
import {type QRSecretMutation, type QRSecretMutationVariables} from './__generated__/queries.api-types';

export const NEW_SECRET_FETCHING_INTERVAL = 900000; // 15 minutes
export const NEW_QR_GENERATING_INTERVAL = 5000;

export const useGenerateQrValue = (
    institutionId: string | undefined,
    setQrValue: (value: string) => void,
    secret: string,
) => {
    const whitelabelConfiguration = useTypedSelector(WhitelabelSelectors.whiteLabelConfiguration);
    const mobileAppUrlScheme = whitelabelConfiguration.MobileAppUrl;

    const generateQrValue = React.useCallback(async () => {
        if (!institutionId || !secret) {
            return;
        }

        const deepLink = await createQRCodeLink(institutionId, secret, mobileAppUrlScheme);

        setQrValue(deepLink);
    }, [institutionId, mobileAppUrlScheme, secret, setQrValue]);

    React.useEffect(() => {
        generateQrValue();
        const newQrValue = setInterval(() => generateQrValue(), NEW_QR_GENERATING_INTERVAL);
        return () => clearInterval(newQrValue);
    }, [generateQrValue]);
};

export const useGenerateSecret = (institutionId: string | undefined, setSecret: (secret: string) => void) => {
    const [getSecret] = useMutation<QRSecretMutation, QRSecretMutationVariables>(Queries.GetQrSecret);
    const generateSecret = React.useCallback(async () => {
        if (!institutionId) {
            return;
        }

        const secretData = await getSecret({
            variables: {
                siteId: institutionId,
            },
        });

        if (secretData && secretData.data?.qrSignin?.getSecret?.secret) {
            setSecret(secretData.data?.qrSignin?.getSecret?.secret);
        }
    }, [getSecret, institutionId, setSecret]);

    React.useEffect(() => {
        generateSecret();
        const newSecret = setInterval(() => generateSecret(), NEW_SECRET_FETCHING_INTERVAL);
        return () => clearInterval(newSecret);
    }, [generateSecret]);
};
