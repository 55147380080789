import React from 'react';

import {Select, type SelectOption, type SelectProps} from 'modern-famly/components/input';
import {Box} from 'modern-famly/components/layout';

import {ListItem, type ListItemProps} from './list-item';

export type ListItemSelectProps<T extends SelectOption> = Omit<SelectProps<T>, 'size' | 'fullWidth'> & {
    displayFrom?: ListItemProps['displayFrom'];
};

export const ListItemSelect = <T extends SelectOption>({displayFrom, ...selectProps}: ListItemSelectProps<T>) => {
    return (
        <ListItem
            displayFrom={displayFrom}
            onClick={e => {
                e.stopPropagation();
                e.preventDefault();
            }}
        >
            <Box width="100%">
                <Select {...selectProps} fullWidth size="compact" />
            </Box>
        </ListItem>
    );
};
