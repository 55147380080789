import gql from 'graphql-tag';

import {StatPermissionCustomFormsManagement} from '../fragment/stat-permission-custom-forms-management.fragment';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchCustomFormsManagementPermissions = gql`
    query FetchCustomFormsManagementPermissions($institutionSetId: SiteSetId!, $formId: CustomRegistrationFormId!) {
        attendance {
            management {
                customRegistrationForm {
                    byFormId(siteSetId: $institutionSetId, customRegistrationFormId: $formId) {
                        ...StatPermissionCustomFormsManagement
                    }
                }
            }
        }
    }
    ${StatPermissionCustomFormsManagement}
`;
