import PropTypes from 'prop-types';
import React from 'react';

import {NewLookTheme} from 'web-app/styleguide/themes';

import {G, SVG} from '../svg';
import {type IconComponent} from './icon-component';

/**
 * @deprecated use MF icons
 */
export const Trashcan: IconComponent<any> = ({fill, ...rest}) => (
    <SVG viewBox="0 0 24 24" radius={4} {...rest}>
        <G fill={fill}>
            <path d="M6,10 L18,10 L18,18 C18,19.1 17.1,20 16,20 L8,20 C6.9,20 6,19.1 6,18 L6,10 Z M16,7 L20,7 L20,9 L4,9 L4,7 L8,7 L8,5 C8,4.4 8.4,4 9,4 L15,4 C15.6,4 16,4.4 16,5 L16,7 Z M14,7 L14,6 L10,6 L10,7 L14,7 Z" />
        </G>
    </SVG>
);
Trashcan.propTypes = {
    fill: PropTypes.string,
};
Trashcan.defaultProps = {
    fill: NewLookTheme.textSecondary,
};
Trashcan.isPrivate = true;
export default Trashcan;
