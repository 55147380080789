import {Map} from 'immutable';
import {type Reducer, type ReducersMapObject} from 'redux';

import {type Action, type ActionDescription} from './action-types';

export type PayloadReducer<State, Payload> = (state: State, action: Action<Payload>) => State;

export interface ReducerDescription<State, Payload = any> {
    actionDescription: ActionDescription<Payload>;
    reducer: PayloadReducer<State, Payload>;
}

export const match = <State, Payload>(
    actionDescription: ActionDescription<Payload>,
    reducer: PayloadReducer<State, Payload>,
): ReducerDescription<State, Payload> => {
    return {actionDescription, reducer};
};

export const makeReducer = <State>(
    reducerDescriptions: Array<ReducerDescription<State>>,
    initialState: State,
): Reducer<State, Action<any>> => {
    const reducerMap = Map<string, PayloadReducer<State, any>>().withMutations(map => {
        reducerDescriptions.forEach(reducerDescription => {
            map.set(reducerDescription.actionDescription.type, reducerDescription.reducer);
        });
    });

    return (state: State = initialState, action) => {
        const reducer = reducerMap.get(action.type);

        if (reducer) {
            return reducer(state, action as Action<any>);
        } else {
            return state;
        }
    };
};

export type InferReducerType<C> = C extends Reducer<infer P> ? P : never;
export type InferStateType<T> = T extends ReducersMapObject<infer S, any> ? S : never;
