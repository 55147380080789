import {Record, List} from 'immutable';

import Name from 'web-app/react/entities/common/name';
import Image from 'web-app/react/entities/common/image';
import {type EntityModel} from 'web-app/react/entities/factory';

export interface IEmployee {
    readonly id: string;
    readonly employeeId: string;
    readonly famlyId: string;
    readonly institutionId: string;
    readonly name: typeof Name.default;
    readonly groupTitle: string;
    readonly groupId: string; // a room/group (staff) to which the employee belongs
    readonly image: typeof Image.default;
    readonly birthday: string | null;
    readonly checkedIn: boolean;
    readonly checkinTime: string | null;
    readonly checkoutTime: string | null;
    readonly estimatedCheckoutTime: string | null;
    readonly tempGroupId: string | null; // a room/group in which the employee is currently signed in
}

export interface IStaffGroup {
    readonly employees: List<IEmployee>;
    readonly groupId: string;
    readonly title: string;
}

export const StaffGroup = Record<IStaffGroup>({
    employees: List(),
    groupId: '',
    title: '',
});

const fromAPI = (data: any) => {
    return new StaffGroup({
        ...data,
        id: data.groupId,
        employees: List(
            data.employees.map((employee: IEmployee) => ({
                ...employee,
                id: employee.employeeId,
                name: Name.fromAPI(employee.name),
                image: Image.fromAPI(employee.image),
            })),
        ),
    });
};

const StaffGroupModel: EntityModel<IStaffGroup> = {
    record: StaffGroup,
    default: new StaffGroup(),
    fromAPI,
};

export default StaffGroupModel;
