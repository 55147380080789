import {ofType} from 'redux-observable';
import {of, merge} from 'rxjs';
import i18next from 'i18next';
import {mergeMap, map, catchError} from 'rxjs/operators';

import {combineEpics} from 'web-app/util/redux-observable';
import DayCareApi from 'signin-app/api/daycare-api';
import * as GlobalEventActions from 'signin-app/global-event/actions';
import {type Action} from 'web-app/util/redux/action-types';
import {type RootState} from 'signin-app/redux/main-reducer';
import {type SignInEpic} from 'signin-app/redux/types';

import childCheckIn from './check-in/epics';
import childCheckOut from './check-out/epics';
import * as Actions from './actions';

const fetchChild: SignInEpic = action$ =>
    action$.pipe(
        ofType(Actions.fetchChild.type),
        mergeMap(({payload}: ReturnType<typeof Actions.fetchChild.action>) =>
            DayCareApi.child(payload.accessToken, payload.childId).pipe(
                map(response => {
                    return Actions.fetchChildSuccess.action(response);
                }),
                catchError(e =>
                    merge(
                        of(GlobalEventActions.updateError.action(i18next.t('somethingWentWrong'))),
                        of(Actions.fetchChildFailed.action(e)),
                    ),
                ),
            ),
        ),
    );

export default combineEpics<Action<any>, Action<any>, RootState>(fetchChild, childCheckIn, childCheckOut);
