import {useState, useEffect, useRef} from 'react';

import {type InferPromiseType} from 'web-app/util/typescript';

import {useIsMounted} from './use-is-mounted';

/**
 * Use dynamic imports directly in your react code!
 *
 * Usage:
 * ```
 * const Foo = useImport(import('bar'));
 * ```
 */
export const useImport = <T>(importPromise: Promise<T>) => {
    // We're using a useRef here to ensure a stable reference to the Promise that (eventually) resolves to the dependency.
    // In the Ember build (which uses ember-auto-import), importPromise was stable - and didn't cause the useEffect
    // below to re-evaluate.
    // But in our Webpack build, this was not the case, which caused the useEffect to constantly
    // re-evaluate and thus causing an infinite render-loop.
    const importPromiseRef = useRef(importPromise);
    const [imported, setImported] = useState<InferPromiseType<Promise<T>>>();
    const isMounted = useIsMounted();

    useEffect(() => {
        importPromiseRef.current.then(importResult => {
            if (isMounted()) {
                setImported(importResult);
            }
        });
    }, [isMounted]);
    return imported;
};
