import React from 'react';
import {createBox} from 'modern-famly';
import styled from 'styled-components';

import {useCustomWhitelabelColor} from 'signin-app/components/hooks/use-custom-whitelabel-color';

const StyledBox = styled(createBox({}))<{customBackgroundColor?: string}>`
    background-color: ${props => props.customBackgroundColor};
    min-height: 100svh;
`;

export const AppContainer = ({children}) => {
    const p500 = useCustomWhitelabelColor('p500');

    return <StyledBox customBackgroundColor={p500}>{children}</StyledBox>;
};
