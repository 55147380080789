import {waitForFrames} from 'web-app/util/animation';
import PlatformHelper from 'web-app/helpers/platform-helper';

/**
 * This forces the browser to recalculate layout
 * after iOS keyboard is closed,
 * otherwise the page has shrinked height
 */
export const repaintView = () => {
    waitForFrames(() => {
        if (document.body.scrollTo) {
            document.body.scrollTo(document.body.scrollLeft, document.body.scrollTop);
        }
    });
};

export const iOSKeyboardFix = () => {
    if (PlatformHelper.isIos()) {
        repaintView();
    }
};

/**
 * Returns the position of a node is relation to the viewport along with its size.
 * Can be used to obtain position for an element with `position: fixed` that should be next to a flow-positioned element
 * e.g. this is used to position the day-picker popup next to the input.
 */
export const getElementGeometry = (node: HTMLElement) => {
    const {top, left, width, height} = node.getBoundingClientRect();
    return {left: left + document.body.scrollLeft, top: top + document.body.scrollTop, width, height};
};
