import {Record} from 'immutable';

interface IBorderConfiguration {
    defaultColor: string;
    hover: string;
    focus: string;
    error: string;
    disabled: string;
}

export class BorderConfiguration extends Record<IBorderConfiguration>({
    defaultColor: 'none',
    hover: 'none',
    focus: 'none',
    error: 'none',
    disabled: 'none',
}) {}
