import {type ColorPalette} from 'modern-famly';

import type LegacyColors from 'web-app/styleguide/colors';

/**
 * Standard icon sizes defined by UX Power Tools
 */
export enum IconSize {
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
}

/**
 * All streamline icons are available in Light, Regular, and Bold weights.
 * The main difference is their stroke width on a 24x24 pixel grid:
 *
 * Light: 1px
 * Regular: 1.5px
 * Bold: 2px
 */
export enum IconWeight {
    Light,
    Regular,
    Bold,
}

/**
 * Use SelfScaling when the icon needs to scale to the containing component
 */
export const SelfScaling = 'SelfScaling';

/**
 * Description of an icon's square size in pixels.
 * Support for rectangle icons could be considered in the future
 * Support for other units than pixels could be considered added in the future
 */
export type SizeDescription = IconSize | number | typeof SelfScaling;

export type IconFill =
    | LegacyColors
    | {
          type: 'mf';
          color: keyof ColorPalette;
      };
export interface IconProps {
    fill?: IconFill;
    size: SizeDescription;
    className?: string; // Required to be able to style the icon using SC
}

/*
 * The actual type of Icons. Every Icon must implement IconProps.
 * Custom icons can also take additional props (e.g. a second fill variable).
 */
export type IconType<P = {}> = React.FunctionComponent<React.PropsWithChildren<IconProps & P>>;
