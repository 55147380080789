import React from 'react';
import {type WrappedFieldProps} from 'redux-form';

import {getErrorLabel} from 'web-app/react/components/form-elements/validators';
import {Colors} from 'web-app/styleguide';
import {Body} from 'web-app/react/components/text/text';

type ReactOnMetaProps = {
    validateIgnoreTouch?: boolean;
} & WrappedFieldProps;

const EMPTY_OBJ = {};
const ReactOnMeta: React.FC<React.PropsWithChildren<ReactOnMetaProps>> = props => {
    const {meta, children, validateIgnoreTouch} = props;

    const {touched, error} = meta || EMPTY_OBJ;

    const showErrors = validateIgnoreTouch ? Boolean(error) : touched && Boolean(error);

    return (
        <>
            {children}
            {showErrors ? (
                <Body color={Colors.Accent3} key="error" className="form-field-error">
                    {getErrorLabel(error, props)}
                </Body>
            ) : null}
        </>
    );
};

export default ReactOnMeta;
