import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button, {ButtonSize} from 'web-app/react/components/form-elements/button/button';
import Arrow from 'web-app/react/components/icons/legacy/lazy-icons/arrow';
import {Flex} from 'web-app/react/components/layout/layout';
import {media} from 'web-app/styleguide/utils';

const ArrowBase = styled(Arrow).attrs(() => ({
    size: 24,
}))``;

const NextArrow = styled(ArrowBase)`
    transform: rotate(180deg);
`;

const ArrowButton = styled(Button)`
    padding: 0 !important;
`;

class Navbar extends React.Component {
    static propTypes = {
        classNames: PropTypes.shape({
            navBar: PropTypes.string.isRequired,
            navButtonPrev: PropTypes.string.isRequired,
            navButtonNext: PropTypes.string.isRequired,
        }),
        className: PropTypes.string,
        showPreviousButton: PropTypes.bool,
        showNextButton: PropTypes.bool,
        onPreviousClick: PropTypes.func,
        onNextClick: PropTypes.func,
        dir: PropTypes.string,
        labels: PropTypes.shape({
            previousMonth: PropTypes.string.isRequired,
            nextMonth: PropTypes.string.isRequired,
        }),
        noTab: PropTypes.bool,
    };

    shouldComponentUpdate(nextProps) {
        return (
            nextProps.labels !== this.props.labels ||
            nextProps.dir !== this.props.dir ||
            this.props.showPreviousButton !== nextProps.showPreviousButton ||
            this.props.showNextButton !== nextProps.showNextButton
        );
    }

    handleNextClick = () => {
        if (this.props.onNextClick) {
            this.props.onNextClick();
        }
    };

    handlePreviousClick = () => {
        if (this.props.onPreviousClick) {
            this.props.onPreviousClick();
        }
    };

    render() {
        const {classNames, className, showPreviousButton, showNextButton, labels, noTab} = this.props;

        return (
            <StyledNavBar align="middle" justify="space-between" className={className || classNames.navBar}>
                <ArrowButton
                    appearance="icon"
                    buttonSize={ButtonSize.mini}
                    tabIndex={noTab ? -1 : 0}
                    aria-label={labels.previousMonth}
                    key="previous"
                    isDisabled={!showPreviousButton}
                    onClick={showPreviousButton ? this.handlePreviousClick : undefined}
                    data-e2e-id="daypicker-previous-month"
                >
                    <ArrowBase />
                </ArrowButton>
                <ArrowButton
                    appearance="icon"
                    buttonSize={ButtonSize.mini}
                    tabIndex={noTab ? -1 : 0}
                    aria-label={labels.nextMonth}
                    key="right"
                    isDisabled={!showNextButton}
                    onClick={showNextButton ? this.handleNextClick : undefined}
                    data-e2e-id="daypicker-next-month"
                >
                    <NextArrow />
                </ArrowButton>
            </StyledNavBar>
        );
    }
}

export default Navbar;

const StyledNavBar = styled(Flex)`
    margin: 0 1rem;
    ${media.mobile`
        margin: 0;
    `}
`;
