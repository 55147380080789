import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

import {Body, Caption} from 'web-app/react/components/text/text';
import ReactOnMeta from 'web-app/react/components/form-elements/containers/react-on-meta';
import {s2} from 'web-app/styleguide/spacing';
import {getProperty} from 'web-app/util/typescript';

import {SIZE_MAP, makeInputSize, makeMarginSize} from '../helpers';

const isEven = n => n % 2 === 0;

const RadioInput = styled.div`
    height: ${props => makeInputSize(props.size)}px;
    width: ${props => makeInputSize(props.size)}px;
    &:after {
        height: ${props =>
            isEven(makeInputSize(props.size))
                ? Math.floor(makeInputSize(props.size) / 2)
                : Math.floor(makeInputSize(props.size) / 2) - 1}px;
        width: ${props =>
            isEven(makeInputSize(props.size))
                ? Math.floor(makeInputSize(props.size) / 2)
                : Math.floor(makeInputSize(props.size) / 2) - 1}px;
    }
    border-radius: 50%;
    border: 2px solid ${props => props.theme.text};
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    margin: ${props => makeMarginSize(props.size)}px;
    margin-right: 8px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
        content: '';
        display: block;
        transform: scale(0);
        transition: transform 0.2s ${props => props.theme.timings.functions.default};
        border-radius: 50%;
        background-color: ${props => props.theme.analogue2};
    }

    input:checked + & {
        border-color: ${props => props.theme.analogue2};
        &:after {
            transform: scale(1);
        }
    }

    ${props =>
        props.noLabel
            ? css`
                  margin: ${props => makeMarginSize(props.size)}px;
              `
            : ''}

    ${props =>
        props.disabled
            ? css`
                  opacity: 0.5;
              `
            : ''}
`;

const HiddenRadioInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 1px;
    pointer-events: none;
    opacity: 0;
    margin: 0;
    &:focus + ${RadioInput} {
        border-color: ${props => props.theme.analogue3};
    }
`;

const RadioWrapper = styled.label`
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    position: relative;

    & > * {
        pointer-events: none;
    }

    ${props =>
        props.disabled
            ? css`
                  cursor: auto;
              `
            : ''}
`;

class RadioButton extends React.PureComponent {
    render() {
        return (
            <ReactOnMeta {...this.props}>
                <RadioWrapper
                    disabled={this.props.disabled}
                    className={this.props.className}
                    data-e2e-class={getProperty(this.props, 'data-e2e-class')}
                >
                    <HiddenRadioInput
                        id={this.props.inputId}
                        {...this.props.input}
                        required={this.props.required}
                        disabled={this.props.disabled}
                        type="radio"
                        onChange={this.handleChange}
                        // data-e2e-class property prop above cannot be used with React testing library due to it specifically checking for data-e2e-id (it usually checks for data-testid but we changed that in order to use the existing infrastructure)
                        data-e2e-id={getProperty(this.props, 'data-e2e-id')}
                    />
                    <RadioInput size={this.props.size} disabled={this.props.disabled} noLabel={!this.props.label} />
                    {this.props.label ? (
                        <div>
                            <Body as="span" disabled={this.props.disabled} className={this.props.radioTextClassName}>
                                {this.props.label}
                            </Body>
                            {this.props.description ? (
                                <Caption
                                    secondary={!this.props.disabled}
                                    disabled={this.props.disabled}
                                    marginBottom={this.props.descriptionMarginBottom}
                                >
                                    {this.props.description}
                                </Caption>
                            ) : null}
                        </div>
                    ) : null}
                </RadioWrapper>
            </ReactOnMeta>
        );
    }

    handleChange = newInputValue => {
        if (this.props.onChange) {
            this.props.onChange(newInputValue.target.value);
        }
        return this.props.input.onChange(newInputValue);
    };
}

RadioButton.propTypes = {
    input: PropTypes.any,
    onChange: PropTypes.func,
    className: PropTypes.string,
    radioBtnClassName: PropTypes.string,
    radioTextClassName: PropTypes.string,
    size: PropTypes.oneOf(Object.keys(SIZE_MAP)),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.node,
    description: PropTypes.string,
    inputId: PropTypes.string,
    'data-e2e-class': PropTypes.string,
    'data-e2e-id': PropTypes.string,
    descriptionMarginBottom: PropTypes.any,
};
RadioButton.defaultProps = {
    size: 'small',
    descriptionMarginBottom: s2,
};

export default RadioButton;
