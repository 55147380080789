import {hasValue} from 'web-app/util/typescript';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useFetchStaffAttendancePermissions} from './queries/use-fetch-staff-attendance-permissions';
import {type StatPermissions} from './stat-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';

export function useStaffAttendancePermissions(payload: UseStatPermissionPayload): StatPermissions['staffAttendance'] {
    const {institutionSetId, shouldQuery} = payload;
    return useCrudPermissionAdapter(
        useFetchStaffAttendancePermissions({
            variables: {
                institutionSetId,
            },
            skip: !hasValue(institutionSetId) || !shouldQuery,
        }).data?.staffhours.management.staffAttendance.siteSet,
    );
}
