import React from 'react';
import styled from 'styled-components';
import {Text, Box, Stack, createStack} from 'modern-famly';

import {useCustomWhitelabelColor} from 'signin-app/components/hooks/use-custom-whitelabel-color';

const TimeBoxContainer = styled(
    createStack({
        alignItems: 'center',
        justifyContent: 'center',
        px: '16px',
        py: '24px',
        width: '88px',
        maxHeight: '72px',
        minHeight: '72px',
        borderRadius: '8px',
    }),
)<{customBorderColor?: string; customBackgroundColor?: string}>`
    box-sizing: border-box;
    border: 1px solid ${props => props.customBorderColor};
    background-color: ${props => props.customBackgroundColor};
    cursor: pointer;
    color: ${props => props.theme.mf.colorPalette.n0};
`;

interface TimeBoxProps {
    value: number;
    selected: boolean;
    handleOnClick: (value: number) => void;
    displayValue?: string | number;
    className?: string;
    subText?: string;
    dataE2eTimeHour?: string;
}

const TimeBox: React.FC<TimeBoxProps> = ({
    value,
    selected,
    displayValue,
    className,
    subText,
    dataE2eTimeHour,
    handleOnClick,
}) => {
    const p300 = useCustomWhitelabelColor('p300');
    const p400 = useCustomWhitelabelColor('p400');

    const selectTime = React.useCallback(() => {
        handleOnClick(value);
    }, [handleOnClick, value]);

    const timeValue = displayValue === undefined ? value : displayValue;

    return (
        <TimeBoxContainer
            onClick={selectTime}
            className={className}
            data-e2e-class={dataE2eTimeHour}
            customBorderColor={selected ? p400 : p300}
            customBackgroundColor={selected ? p400 : 'transparent'}
        >
            <Stack flexDirection="column" justifyContent="center" alignItems="center">
                <Text variant="h6" color="n0">
                    {timeValue}
                </Text>
                <Box>{subText}</Box>
            </Stack>
        </TimeBoxContainer>
    );
};

export default TimeBox;
