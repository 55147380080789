import {Record, List} from 'immutable';

export class ChildCheckout extends Record<{
    autoCheckedOut: boolean;
    checkinLoginId: string;
    checkinTime: string;
    checkoutLoginId: string;
    checkoutTime: string;
    childCheckinId: string;
    childId: string;
    deletedAt: string | null;
    goHomeWithChildId: string;
    groupId: string;
    hours?: number;
    institutionId: string;
    pickupRelationId: string;
    pickupTime: string;
}>({
    autoCheckedOut: false,
    checkinLoginId: '',
    checkinTime: '',
    checkoutLoginId: '',
    checkoutTime: '',
    childCheckinId: '',
    childId: '',
    deletedAt: '',
    goHomeWithChildId: '',
    groupId: '',
    hours: undefined,
    institutionId: '',
    pickupRelationId: '',
    pickupTime: '',
}) {}

export interface IStatusConfig {
    color: string;
    createdAt: string;
    forChildren: boolean;
    forEmployees: boolean;
    icon: string;
    institutionId: string;
    statusConfigId: string;
    title: string;
}

export class StatusConfig extends Record<IStatusConfig>({
    color: '',
    createdAt: '',
    forChildren: false,
    forEmployees: false,
    icon: '',
    institutionId: '',
    statusConfigId: '',
    title: '',
}) {}

export interface IStatusRegistration {
    childId: string;
    employeeId: string | null;
    endedAt: string | null;
    endedBy: string | null;
    institutionId: string;
    startedAt: string;
    startedBy: {
        image: string;
        loginId: string;
        name: string;
        status: string;
    };
    statusConfigId: string;
    statusRegistrationId: string;
}

export class StatusRegistration extends Record<IStatusRegistration>({
    childId: '',
    employeeId: '',
    endedAt: '',
    endedBy: '',
    institutionId: '',
    startedAt: '',
    startedBy: {
        image: '',
        loginId: '',
        name: '',
        status: '',
    },
    statusConfigId: '',
    statusRegistrationId: '',
}) {}

export interface IGoHomeWithChildrenGroup {
    children: List<any>;
    color: string;
    groupId: string;
    image: string;
    title: string;
}

interface GroupBase {
    color: string;
    groupId: string;
    image: string;
    title: string;
}

interface ChildBase {
    childId: string;
}

interface RawChild extends ChildBase {
    image: string;
    name: string;
}

export interface Child extends ChildBase {
    id: string;
    image: {
        small: string;
    };
    name: {
        firstName: string;
        lastName: string;
        fullName: string;
    };
}

export interface RawGroup extends GroupBase {
    children: RawChild[];
}

export const transformGroups = (groups: RawGroup[]): GoHomeWithChildrenGroup[] => {
    return groups.map(
        group =>
            new GoHomeWithChildrenGroup({
                ...group,
                children: List(
                    group.children.map(child => {
                        const name = child.name.split(' ');

                        return {
                            ...child,
                            id: child.childId,
                            image: {
                                small: child.image,
                            },
                            name: {
                                firstName: name[0],
                                lastName: name[1],
                                fullName: child.name,
                            },
                        };
                    }),
                ),
            }),
    );
};

export class GoHomeWithChildrenGroup extends Record<IGoHomeWithChildrenGroup>({
    children: List(),
    color: '',
    groupId: '',
    image: '',
    title: '',
}) {}
