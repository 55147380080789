import {type QueryHookOptions} from '@apollo/client';

import {FetchCustomFormsManagementPermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-custom-forms-management-permissions.query';
import {
    type FetchCustomFormsManagementPermissionsQuery,
    type FetchCustomFormsManagementPermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-custom-forms-management-permissions.query.api-types';
import {useSubscribableSaveCustomRegistrationFormMutationResult} from 'web-app/react/staffing-attendance/hooks/mutations/use-save-custom-registration-form-mutation';
import {useSubscribableUpdateCustomRegistrationFormPublishedStateMutationResult} from 'web-app/react/staffing-attendance/hooks/mutations/use-update-custom-registration-form-published-state-mutation';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchCustomFormsManagementPermissions(
    options: QueryHookOptions<
        FetchCustomFormsManagementPermissionsQuery,
        FetchCustomFormsManagementPermissionsQueryVariables
    >,
): ImmerWrappedQueryResult<
    FetchCustomFormsManagementPermissionsQuery,
    FetchCustomFormsManagementPermissionsQueryVariables
> {
    const queryResult = useStatQuery<
        FetchCustomFormsManagementPermissionsQuery,
        FetchCustomFormsManagementPermissionsQueryVariables
    >(FetchCustomFormsManagementPermissions, options);

    useSubscribableUpdateCustomRegistrationFormPublishedStateMutationResult(result => {
        // Only refetch the query for the appropriate formId
        if (result?.attendance.customRegistrationForm.setPublished.formId === options.variables?.formId) {
            queryResult.refetch();
        }
    });

    useSubscribableSaveCustomRegistrationFormMutationResult(() => {
        queryResult.refetch();
    });

    return queryResult;
}
