import React from 'react';
import styled, {css} from 'styled-components';

import Link, {type LinkProps} from 'web-app/react/components/link/link';
import {type BaseProps, Base} from 'web-app/react/components/layout/layout';
import {media} from 'web-app/styleguide/utils';

interface BorderProps {
    top?: boolean;
    indented?: boolean;
}

const Border = styled(Base)<BorderProps>`
    ${props => {
        const verticalPositioning = props.top
            ? css`
                  top: 0;
              `
            : css`
                  bottom: 0;
              `;

        const indentation = props.indented ? '15' : 0;

        return css`
            border-bottom: 1px solid;
            position: absolute;
            right: 0;
            left: ${indentation}px;
            ${verticalPositioning}
            border-color: ${props => props.theme.delimiter};
        `;
    }}
`;

const Item = styled(Base)<Props & BaseProps>`
    position: relative;

    ${props =>
        props.clickable
            ? css`
                  cursor: pointer;
                  &:hover,
                  &:active:not([disabled]) {
                      background: ${props => props.theme.backgroundHover};

                      ${media.mobile`
                          background: inherit;
                      `}
                  }
              `
            : ''}

    ${props =>
        props.active
            ? css`
                  background: ${props => props.theme.backgroundHover};
              `
            : ''}

    ${props =>
        props.padded
            ? css`
                  padding: ${props.compact ? '12px' : '12px 16px'};
              `
            : ''}

    ${props =>
        props.borderPosition === 'bottom'
            ? css`
                  &:last-child {
                      ${Border} {
                          display: none;
                      }
                  }
              `
            : ''}
`;

type ItemType = 'default' | 'link';

type BorderPosition = 'bottom' | 'top';

interface StyleProps {
    active?: boolean;
    padded?: boolean;
    compact?: boolean;
    clickable?: boolean;
    borderPosition?: BorderPosition;
}

interface ListItemProps {
    className?: string;
    onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
    href?: string;
    type?: ItemType;
    indentedBorder?: boolean;
    noBorder?: boolean;
}

type Props = ListItemProps & StyleProps & Partial<LinkProps>;

const ListItem: React.FC<React.PropsWithChildren<Props>> = props => {
    const {
        children,
        className,
        onClick,
        padded,
        compact,
        href,
        type,
        borderPosition = 'bottom',
        indentedBorder,
        noBorder,
        clickable,
        active,
        ...rest
    } = props;

    const itemProps = {
        className,
        clickable: clickable || Boolean(onClick) || Boolean(href),
        padded,
        compact,
        active,
        borderPosition,
    };

    const topBorder = !noBorder && borderPosition === 'top' ? <Border top={true} indented={indentedBorder} /> : null;

    const bottomBorder =
        !noBorder && borderPosition === 'bottom' ? <Border top={false} indented={indentedBorder} /> : null;

    if (type === 'link') {
        return (
            <Item as={Link} href={href} onClick={onClick} {...itemProps} {...rest}>
                {topBorder}
                {children}
                {bottomBorder}
            </Item>
        );
    }

    return (
        <Item onClick={onClick} {...itemProps}>
            {topBorder}
            {children}
            {bottomBorder}
        </Item>
    );
};

export default ListItem;
