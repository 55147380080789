import React from 'react';
import {useDispatch} from 'react-redux';
import {type InjectedFormProps, destroy} from 'redux-form';
import styled, {css} from 'styled-components';
import {Text, createStack} from 'modern-famly';

import {reduxForm} from 'web-app/react/deprecation/redux-form/redux-form';
import {compose} from 'web-app/util/typescript';
import {Form} from 'web-app/react/components/form-elements/containers/form/form';
import FormField from 'web-app/react/components/form-elements/containers/form-field/form-field';
import FormInput from 'web-app/react/components/form-elements/containers/form-input';
import {useTypedSelector} from 'signin-app/components/hooks';
import {useOnClickOutside} from 'web-app/react/hooks/use-on-click-outside';
import translate from 'signin-app/helpers/translate';
import {useCustomWhitelabelColor} from 'signin-app/components/hooks/use-custom-whitelabel-color';

import * as Selectors from './selectors';
import * as Actions from './actions';
import * as Constants from './constants';

const ENTER_KEY_CODE = 13;

const StyledFormField = styled(FormField)`
    width: 206px;
`;
const preventSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === ENTER_KEY_CODE) {
        e.preventDefault();
        Actions.addNewPickupRelation.dispatch(false);
    }
};

interface FormData {
    pickupPersonName: string;
}

type NewPickupPersonFormProps = InjectedFormProps<FormData>;

const NewPickupPersonForm: React.FC<NewPickupPersonFormProps> = () => {
    return (
        <Form id="newPickupPersonForm">
            <StyledFormField
                name="pickupPersonName"
                id="pickupPersonName"
                type="text"
                component={FormInput}
                onKeyDown={preventSubmit}
                autoFocus
            />
        </Form>
    );
};

const NewPickupPerson = compose(
    reduxForm<FormData>({
        form: Constants.NEW_PICKUP_PERSON_FORM,
        destroyOnUnmount: false,
    }),
).apply(NewPickupPersonForm);

const AddOptionWrapper = styled(
    createStack({
        minWidth: '254px',
        height: '80px',
        px: '24px',
        py: '16px',
        alignItems: 'center',
    }),
)<{selected?: boolean; customColor?: string}>`
    box-sizing: border-box;
    border: 1px dashed ${props => props.customColor};
    border-radius: ${props => props.theme.mf.spacing(4)};
    cursor: pointer;
    ${props =>
        props.selected
            ? css`
                  background-color: ${props.customColor};
              `
            : ''}
`;

const useAddPickupPerson = () => {
    const adding = useTypedSelector(Selectors.addingNewRelation);
    const pickupName = useTypedSelector(Selectors.formPickupPersonName);
    const hasSelectedPickupRelation = Boolean(useTypedSelector(Selectors.selectedPickupRelationId));

    const dispatch = useDispatch();

    const destroyNewPickupForm = React.useCallback(() => {
        dispatch(destroy(Constants.NEW_PICKUP_PERSON_FORM));
    }, [dispatch]);

    const handleAddPickupPersonClick = React.useCallback(() => {
        dispatch(Actions.addNewPickupRelation.action(true));
    }, [dispatch]);

    React.useEffect(() => {
        return () => destroyNewPickupForm();
    }, [destroyNewPickupForm]);

    return {
        adding,
        pickupName,
        hasSelectedPickupRelation,
        handleAddPickupPersonClick,
    };
};

export const AddPickupPerson: React.FC = () => {
    const {adding, pickupName, hasSelectedPickupRelation, handleAddPickupPersonClick} = useAddPickupPerson();
    const componentRef = React.useRef(null);
    const dispatch = useDispatch();
    const p300 = useCustomWhitelabelColor('p300');

    const onClickOutside = () => {
        if (adding) {
            dispatch(Actions.addNewPickupRelation.action(false));
        }
    };

    useOnClickOutside(componentRef, onClickOutside);

    const optionCardTitle = adding ? (
        <NewPickupPerson />
    ) : (
        <Text variant="h5" color="n0" emphasized>
            {pickupName}
        </Text>
    );

    return (
        <div ref={componentRef}>
            <AddOptionWrapper
                onClick={handleAddPickupPersonClick}
                selected={adding || (pickupName && !hasSelectedPickupRelation)}
                customColor={p300}
            >
                {!adding && !pickupName ? (
                    <Text variant="body" color="n0">
                        {translate('addPickupPerson')}
                    </Text>
                ) : (
                    optionCardTitle
                )}
            </AddOptionWrapper>
        </div>
    );
};
