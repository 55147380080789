import i18next from 'i18next';

import EntityFactory from 'signin-app/entities/factory';

import StaffGroup from './model';
import reducerExtension from './reducer-extension';

export default EntityFactory.createEntity({
    domain: 'ENTITIES/EMPLOYEE',
    entityName: i18next.t('entities.names.employee'),
    model: StaffGroup,
    groupByKeys: ['groupId'],
    entityIdentifier: 'groupId',
    reducerExtension,
    apiDescription: {
        [EntityFactory.RequestType.FetchAll]: {
            url: 'daycare/tablet/employees',
            requestPayload: accessToken => ({accessToken}),
            locateResponse: response => response.groups,
        },
    },
});
