/* eslint-disable default-case */
/* eslint-disable consistent-return */
import React from 'react';
import i18next from 'i18next';
import moment from 'moment-timezone';
import styled from 'styled-components';

import RadioButton from 'web-app/react/components/form-elements/containers/radio-button/radio-button';
import Select from 'web-app/react/components/form-elements/select/select';
import {getMonthOptions, getMonthRange} from 'web-app/util/moment';
import DayPickerInput from 'web-app/react/components/form-elements/daypicker/day-picker-input';
import {Flex, Base} from 'web-app/react/components/layout/layout';
import {s1, s2} from 'web-app/styleguide/spacing';
import {Caption} from 'web-app/react/components/text/text';
import {hasValue} from 'web-app/util/typescript';

export const isSelectOption = (option: SelectOption | OnChangeValue): option is SelectOption => {
    return (option as SelectOption).value !== undefined;
};

export enum PERIOD_TYPE {
    MONTH = 'MONTH',
    PERIOD = 'PERIOD',
}

export interface SelectOption {
    value: string;
    label: string;
}
export interface OnChangeValue {
    from?: string;
    to?: string;
}

export interface InputProps {
    type?: PERIOD_TYPE;
    emptyMonthOption?: SelectOption;
    onChange: (value: OnChangeValue | SelectOption | null) => void;
    monthsBack: number;
    monthsTotal: number;
    from?: string;
    to?: string;
    placeholder?: string;
}

interface State {
    type: PERIOD_TYPE;
}
class PeriodPicker extends React.Component<InputProps, State> {
    private static periodTypes = Object.keys(PERIOD_TYPE);
    private static inputTypeToTitle = (type: PERIOD_TYPE) => {
        switch (type) {
            case PERIOD_TYPE.MONTH:
                return i18next.t('month');
            case PERIOD_TYPE.PERIOD:
                return i18next.t('period');
        }
    };
    private baseMonthOptions = getMonthOptions(this.props.monthsBack, this.props.monthsTotal).reverse();
    private monthOptions = this.props.emptyMonthOption
        ? [this.props.emptyMonthOption, ...this.baseMonthOptions]
        : this.baseMonthOptions;
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type || PERIOD_TYPE.MONTH,
        };

        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleOnMonthChange = this.handleOnMonthChange.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
    }
    public componentDidUpdate(prevProps) {
        if (prevProps.type !== this.props.type && this.props.type !== undefined) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({type: this.props.type});
        }
    }
    get monthValue() {
        if (!this.props.from) {
            return this.props.emptyMonthOption ? this.props.emptyMonthOption.value : undefined;
        }
        return moment(this.props.from).startOf('month').format('YYYY-MM');
    }
    public render() {
        return (
            <Base fullWidth>
                <Flex marginBottom={s2}>
                    {PeriodPicker.periodTypes.map(type => (
                        <Base key={type} marginRight={s2}>
                            <RadioButton
                                label={PeriodPicker.inputTypeToTitle(type as PERIOD_TYPE)}
                                input={{
                                    value: type,
                                    onChange: this.handleTypeChange,
                                    checked: this.state.type === type,
                                }}
                            />
                        </Base>
                    ))}
                </Flex>
                {this.state.type === PERIOD_TYPE.PERIOD && (
                    <Flex align="center">
                        <DayPickerInput
                            value={this.props.from}
                            onChange={this.handleFromChange}
                            placeholder={i18next.t('from')}
                        />
                        <Base marginLeft={s1} marginRight={s1}>
                            <Caption>-</Caption>
                        </Base>
                        <DayPickerInput
                            value={this.props.to}
                            onChange={this.handleToChange}
                            placeholder={i18next.t('to')}
                        />
                    </Flex>
                )}
                {this.state.type === PERIOD_TYPE.MONTH && (
                    <StyledSelect
                        options={this.monthOptions}
                        onChange={this.handleOnMonthChange}
                        value={this.monthValue}
                        clearable={this.props.emptyMonthOption === undefined}
                        placeholder={this.props.placeholder}
                    />
                )}
            </Base>
        );
    }

    private handleTypeChange(e: React.ChangeEvent<TypeRadioButtonElement>) {
        this.setState({type: e.target.value});

        if (e.target.value === PERIOD_TYPE.MONTH && this.monthValue) {
            const currentFrom = this.props.from;
            const currentTo = this.props.to;

            if (
                this.props.emptyMonthOption &&
                this.props.emptyMonthOption.value === this.monthValue &&
                (hasValue(currentFrom) || hasValue(currentTo))
            ) {
                this.props.onChange(this.props.emptyMonthOption);
            } else if (this.props.emptyMonthOption && this.props.emptyMonthOption.value !== this.monthValue) {
                const {from, to} = getMonthRange(this.monthValue);

                const momentFrom = moment(from);
                const momentTo = moment(to);

                if (
                    currentFrom &&
                    moment(currentFrom).isSame(momentFrom) &&
                    currentTo &&
                    moment(currentTo).isSame(momentTo)
                ) {
                    return;
                }

                this.props.onChange({
                    from,
                    to,
                });
            }
        }
    }
    private handleFromChange(from?: string) {
        this.props.onChange({
            from,
            to: this.props.to,
        });
    }
    private handleToChange(to?: string) {
        this.props.onChange({
            from: this.props.from,
            to,
        });
    }
    private handleOnMonthChange(option: SelectOption | null) {
        if (option === null) {
            this.props.onChange(option);
        } else if (this.props.emptyMonthOption && this.props.emptyMonthOption.value === option.value) {
            this.props.onChange(option);
        } else {
            const {from, to} = getMonthRange(option.value);
            this.props.onChange({
                from,
                to,
            });
        }
    }
}

export default PeriodPicker;

interface TypeRadioButtonElement extends HTMLInputElement {
    value: PERIOD_TYPE;
}

const StyledSelect = styled(Select)`
    width: 100%;
`;
