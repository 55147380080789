import {type Components, type Theme} from '@mui/material/styles';
import {dateRangePickerDayClasses} from '@mui/x-date-pickers-pro';
import {pickersDayClasses} from '@mui/x-date-pickers';

import {type ColorKey} from 'modern-famly/theming';

export const themeColor = (color: ColorKey, theme: Theme) => {
    return theme.modernFamlyTheme.colorPalette[color];
};

export const DateRangePickerThemeConfiguration: Components<Theme> = {
    MuiDateRangePickerDay: {
        styleOverrides: {
            root: ({theme, ownerState}) => {
                const borderRadius = theme.shape.borderRadius * 3;

                return {
                    // :first-of-type styles the first day of each week
                    '&:first-of-type': {
                        // Ensures that the highlight at the beginning of the week has the correct border radius
                        '&:before': {
                            borderTopLeftRadius: borderRadius,
                            borderBottomLeftRadius: borderRadius,
                        },

                        // Ensures that the dashed preview at the beginning of the week has the correct border radius and border color
                        [`.${dateRangePickerDayClasses.rangeIntervalDayPreview}`]: {
                            borderTopLeftRadius: borderRadius,
                            borderBottomLeftRadius: borderRadius,
                            borderLeftColor:
                                ownerState.isPreviewing && !ownerState.outsideCurrentMonth
                                    ? themeColor('p200', theme)
                                    : undefined,
                        },
                    },
                    // :last-of-type styles the last day of each week
                    '&:last-of-type': {
                        '&:before': {
                            // We don't want to show a highlight background color for the day if:
                            // That day is the last day of the week _and_ that day marks the start of the highlight
                            ...(ownerState.isStartOfHighlighting ? {backgroundColor: 'transparent'} : {}),
                            borderTopRightRadius: borderRadius,
                            borderBottomRightRadius: borderRadius,
                        },

                        // Ensures that the dashed preview at the end of the week has the correct border radius and border color
                        [`.${dateRangePickerDayClasses.rangeIntervalDayPreview}`]: {
                            borderTopRightRadius: borderRadius,
                            borderBottomRightRadius: borderRadius,
                            borderRightColor:
                                ownerState.isPreviewing && !ownerState.outsideCurrentMonth
                                    ? themeColor('p200', theme)
                                    : undefined,
                        },
                    },

                    // We use pseudo elements to style the selected date range
                    position: 'relative',
                    '&:before':
                        ownerState.isHighlighting && !ownerState.outsideCurrentMonth
                            ? {
                                  content: '""',
                                  display: 'block',
                                  position: 'absolute',
                                  top: '4px',
                                  width: '106%',
                                  height: '28px',
                                  backgroundColor: themeColor('p75', theme),

                                  // Ensure that the start and the of the highlight are "tucked" behind the
                                  // start or end of the selected range
                                  right:
                                      ownerState.isHighlighting && ownerState.isEndOfHighlighting ? '4px' : undefined,
                                  left:
                                      ownerState.isHighlighting && ownerState.isStartOfHighlighting ? '4px' : undefined,
                              }
                            : undefined,

                    // Ensure that the highlight at the start and end of the months has the right border color
                    [`&.${dateRangePickerDayClasses.startOfMonth}:before`]: {
                        borderTopLeftRadius: borderRadius,
                        borderBottomLeftRadius: borderRadius,
                    },
                    [`&.${dateRangePickerDayClasses.endOfMonth}:before`]: {
                        borderTopRightRadius: borderRadius,
                        borderBottomRightRadius: borderRadius,
                    },

                    backgroundColor: 'transparent',
                };
            },

            // The innermost elements of the range calendar: the buttons that are pressed when clicking the dates.
            day: ({theme, ownerState}) => ({
                borderRadius: theme.shape.borderRadius * 3,
                borderColor: themeColor('p300', theme),

                ...theme.typography['body-small'],
                width: '32px',
                height: '32px',

                // A scale of 1.1 is applied out of the box. This resets it.
                transform: 'scale(1)',

                '&:hover, &:focus': {
                    backgroundColor: themeColor('p100', theme),
                },

                // Adds the right border color to "today"
                [`&:not(.${pickersDayClasses.selected})`]: {
                    borderColor: ownerState.outsideCurrentMonth ? 'transparent' : themeColor('p400', theme),
                },

                [`&.${pickersDayClasses.selected}, &.${pickersDayClasses.selected}:hover, &.${pickersDayClasses.selected}:focus`]:
                    {
                        backgroundColor: themeColor('p400', theme),
                    },
            }),
            // Sets the border color of the "previewed" date range, e.g. when dates are hovered, but before they're selected.
            rangeIntervalPreview: ({theme, ownerState}) => {
                const borderColor = themeColor('p200', theme);

                return {
                    borderTopColor:
                        ownerState.isPreviewing && !ownerState.outsideCurrentMonth ? borderColor : undefined,
                    borderBottomColor:
                        ownerState.isPreviewing && !ownerState.outsideCurrentMonth ? borderColor : undefined,
                    borderLeftColor:
                        ownerState.isStartOfPreviewing && !ownerState.outsideCurrentMonth ? borderColor : undefined,
                    borderRightColor:
                        ownerState.isEndOfPreviewing && !ownerState.outsideCurrentMonth ? borderColor : undefined,
                };
            },
            // Ensures that the start and end of the dashed preview have the right border radii
            rangeIntervalDayPreviewStart: ({theme}) => ({
                borderTopLeftRadius: theme.shape.borderRadius * 3,
                borderBottomLeftRadius: theme.shape.borderRadius * 3,
            }),
            rangeIntervalDayPreviewEnd: ({theme}) => ({
                borderTopRightRadius: theme.shape.borderRadius * 3,
                borderBottomRightRadius: theme.shape.borderRadius * 3,
            }),

            // Disables the border on a hovered day
            dayOutsideRangeInterval: {
                '&:hover': {
                    border: 'none !important',
                },
            },
        },
    },
    MuiDateRangeCalendar: {
        styleOverrides: {
            // Styles the divider between the calendars when the dialog is open
            monthContainer: ({theme}) => ({
                '&:not(:last-of-type)': {
                    borderRight: `2px solid ${themeColor('n75', theme)}`,
                },
            }),
        },
    },
};
