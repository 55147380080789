import PropTypes from 'prop-types';
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

import {TextFieldStyling} from 'web-app/react/components/form-elements/text-field/text-field';

// eslint-disable-next-line no-unused-vars
const StyledTextArea = styled(TextareaAutosize)`
    ${() => TextFieldStyling}

    &[disabled] {
        cursor: inherit;
    }
`;

class Textarea extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    render() {
        const {rows, autoSize, inputRef, ...otherProps} = this.props;

        if (!autoSize) {
            return (
                <StyledTextArea
                    as="textarea"
                    {...otherProps}
                    rows={`${rows}`}
                    ref={inputRef}
                    onChange={this.handleChange}
                />
            );
        }

        return (
            <StyledTextArea
                {...otherProps}
                minRows={parseInt(rows, 10) || 2}
                ref={inputRef}
                onChange={this.handleChange}
            />
        );
    }
    handleChange(e) {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }
}

Textarea.propTypes = {
    rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ispassive: PropTypes.string,
    onChangeAction: PropTypes.string,
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    autoSize: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
};
Textarea.defaultProps = {
    autoSize: true,
};

export default Textarea;
