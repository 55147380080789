import i18next from 'i18next';

import EntityFactory from 'signin-app/entities/factory';

import Child from './model';
import {GROUP_BY_KEYS} from './constants';
import reducerExtension from './reducer-extension';

export default EntityFactory.createEntity({
    domain: 'ENTITIES/CHILDREN',
    entityName: i18next.t('entities.names.child'),
    model: Child,
    groupByKeys: GROUP_BY_KEYS,
    reducerExtension,
    apiDescription: {
        [EntityFactory.RequestType.FetchAll]: {
            url: 'daycare/tablet/group',
            requestPayload: ({accessToken, groupId, institutionId, includeRelations}) => ({
                accessToken,
                groupId,
                institutionId,
                includeRelations,
            }),
            locateResponse: response => response.children,
        },
    },
});
