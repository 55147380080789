import {Record} from 'immutable';

interface IInformationArchitectureConfiguration {
    tabBarHeightMobile: number;
    tabBarHeightTablet: number;
    tabBarHeightDesktop: number;
    navigationBarHeightDesktop: number;
}

export class InformationArchitectureConfiguration extends Record<IInformationArchitectureConfiguration>({
    tabBarHeightMobile: 0,
    tabBarHeightTablet: 0,
    tabBarHeightDesktop: 0,
    navigationBarHeightDesktop: 0,
}) {}
