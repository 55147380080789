import {type Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import RESTClient from 'signin-app/api/clients/rest-client';
import {preparePinLoginResponse} from 'signin-app/pin/models';
import {StatusConfig, type IStatusConfig} from 'signin-app/child/models';
import BehaviorsCollection from 'web-app/behaviors/behaviors-collection';
import {setLocale} from 'web-app/util/locale';

export default class DayCareApi {
    public static login(password: string): Observable<any> {
        return RESTClient.postObservable('daycare/tablet/login', {password});
    }

    public static pinLogin(pin: string) {
        return RESTClient.getAuthenticatedObservable('v2/checkin', {
            pin,
        }).pipe(
            map(response => {
                return preparePinLoginResponse(response);
            }),
        );
    }

    public static groups(accessToken: string) {
        return RESTClient.getObservable('daycare/tablet/overview', {accessToken}).pipe(
            tap(response => {
                if (response?.institution?.locale) {
                    setLocale(response.institution.locale);
                }
            }),
            map(response => {
                return {
                    ...response,
                    // Currently the backend renders response.behaviors without being clamped in an array if there is only one present.
                    // This is just a quick work around for that which we should remove once they resolve it.
                    behaviors: new BehaviorsCollection(response.behaviors ? [response.behaviors].flatMap(_ => _) : []),
                };
            }),
        );
    }

    public static employees(accessToken: string) {
        return RESTClient.getObservable('daycare/tablet/employees', {
            accessToken,
        });
    }

    public static group(accessToken: string, groupId: string, institutionId: string, includeRelations: boolean) {
        return RESTClient.getObservable('daycare/tablet/group', {
            accessToken,
            groupId,
            institutionId,
            includeRelations,
        });
    }

    public static child(accessToken: string, childId: string) {
        return RESTClient.getObservable('daycare/tablet/child', {
            accessToken,
            childId,
        });
    }

    public static configs(institutionId: string) {
        return RESTClient.getAuthenticatedObservable('v2/status/configs', {
            institutionId,
        }).pipe(
            map((response: {statusConfigs: IStatusConfig[]}) => {
                return response.statusConfigs.map(r => new StatusConfig(r));
            }),
        );
    }
}
