import {type QueryHookOptions} from '@apollo/client';

import {FetchContractedHoursPermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-contracted-hours-permissions.query';
import {
    type FetchContractedHoursPermissionsQuery,
    type FetchContractedHoursPermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-contracted-hours-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchContractedHoursPermissions(
    options: QueryHookOptions<FetchContractedHoursPermissionsQuery, FetchContractedHoursPermissionsQueryVariables>,
): ImmerWrappedQueryResult<FetchContractedHoursPermissionsQuery, FetchContractedHoursPermissionsQueryVariables> {
    return useStatQuery<FetchContractedHoursPermissionsQuery, FetchContractedHoursPermissionsQueryVariables>(
        FetchContractedHoursPermissions,
        options,
    );
}
