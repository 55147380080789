import React from 'react';
import {type RouteComponentProps, Route} from 'react-router-dom';
import {Box, Text} from 'modern-famly';
import {useDispatch} from 'react-redux';

import * as ChildSelectors from 'signin-app/child/selectors';
import translate from 'signin-app/helpers/translate';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import Header from 'signin-app/components/header';
import {fetchGoHomeWithChildren} from 'signin-app/go-home-with/actions';
import Groups from 'signin-app/go-home-with/groups';
import Children from 'signin-app/go-home-with/children';
import {useTypedSelector} from 'signin-app/components/hooks';
import {PageContainer} from 'signin-app/components/containers';

import * as Selectors from './selectors';

type GoHomeWithRouteProps = RouteComponentProps<{childId: string}>;

const GoHomeWith: React.FC<GoHomeWithRouteProps> = props => {
    const {match} = props;
    const isFetching = useTypedSelector(Selectors.isFetching);
    const childName = useTypedSelector(state =>
        ChildSelectors.childName(state, {id: match.params.childId, childId: match.params.childId}),
    );
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchGoHomeWithChildren.action(props.match.params.childId));
    }, [dispatch, props.match.params.childId]);

    return (
        <PageContainer>
            <Header />
            {!isFetching ? (
                <Box>
                    <Text variant="h6" emphasized color="n0" textAlign="center" my={6}>
                        {translate('withWhomIsGoingHome', {childName})}
                    </Text>
                    <Route path={match.url} exact component={Groups} />
                    <Route
                        path={`${match.url}/:groupId`}
                        render={props => <Children {...props} childId={match.params.childId} />}
                    />
                </Box>
            ) : (
                <Spinner color="white" centered />
            )}
        </PageContainer>
    );
};

export default GoHomeWith;
