import gql from 'graphql-tag';

import {CustomRegistrationFormChildField} from '../fragment/custom-registration-form-child-field.fragment';
import {CustomRegistrationFormDetails} from '../fragment/custom-registration-form-details.fragment';
import {CustomRegistrationFormField} from '../fragment/custom-registration-form-field.fragment';
import {CustomRegistrationFormSaveResult} from '../fragment/custom-registration-form-save-result.fragment';
import {CustomRegistrationFormSection} from '../fragment/custom-registration-form-section.fragment';

export const SaveCustomRegistrationForm = gql`
    mutation SaveCustomRegistrationForm($siteSetId: SiteSetId!, $form: CustomRegistrationFormDetailsInput!) {
        attendance {
            customRegistrationForm {
                save(siteSetId: $siteSetId, form: $form) {
                    ...CustomRegistrationFormSaveResult
                }
            }
        }
    }
    ${CustomRegistrationFormSaveResult}
    ${CustomRegistrationFormDetails}
    ${CustomRegistrationFormSection}
    ${CustomRegistrationFormField}
    ${CustomRegistrationFormChildField}
`;
