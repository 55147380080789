import {ENV as ENVIRONMENT_TYPES, type EnvValues} from 'web-app/shared/constants';
import {getAPIConfigs} from 'web-app/shared/environment-helpers';
import SHARED_ENVIRONMENT_CONFIG from 'web-app/shared/environment';

const CURRENT_ENVIRONMENT = (process.env.NODE_ENV as EnvValues) || ENVIRONMENT_TYPES.LOCAL;
const SIGNIN_V2_PRODUCTION_SENTRY_DSN = 'https://4dd01948ea33453c9be9f42d9214de4a@sentry.famly.de/36';
const REMOTE_DEV_HOST = process.env.REMOTE_DEV_HOST;
const SENTRY_TARGET = process.env.SENTRY_TARGET;

const parseSentryTarget = (target?: string) => {
    switch (target) {
        case 'production':
            return target;
        case undefined:
        case '':
            return undefined;
        default:
            console.warn(`Invalid value of "${target}" passed as Sentry Target`);
            return undefined;
    }
};

const apiConfigs = getAPIConfigs(
    CURRENT_ENVIRONMENT,
    SHARED_ENVIRONMENT_CONFIG.CURRENT_PLATFORM,
    SHARED_ENVIRONMENT_CONFIG.IS_DEVELOPMENT,
    undefined,
    REMOTE_DEV_HOST,
);

const version = process.env.APP_VERSION || 'dev';

const ENV = {
    environment: CURRENT_ENVIRONMENT,
    isDevelopment: SHARED_ENVIRONMENT_CONFIG.IS_DEVELOPMENT,

    APP: {
        platform: SHARED_ENVIRONMENT_CONFIG.CURRENT_PLATFORM,
        apiConfigs,
        version,
        sentryVersion: version,
        // Version is based on the commit, so it seems safe to use it as build number as well.
        // This is used for Sentry's `config.dist`
        buildNumber: version,
    },

    sentry: {
        target: parseSentryTarget(SENTRY_TARGET),
        production: {
            debug: false,
            development: false,
            dsn: SIGNIN_V2_PRODUCTION_SENTRY_DSN,
            environment: 'production',
        },
    },
};

export default ENV;
