import {useEffect, type RefObject} from 'react';
import {fromEvent} from 'rxjs';
import {type FromEventTarget} from 'rxjs/internal/observable/fromEvent';
import {debounceTime} from 'rxjs/operators';

import {hasValue} from 'web-app/util/typescript';

interface Props<K extends keyof GlobalEventHandlersEventMap> {
    eventName: K;
    target: RefObject<FromEventTarget<GlobalEventHandlersEventMap[K]>>;
    skip?: boolean;
    debounce?: number;
    handler: (e: GlobalEventHandlersEventMap[K]) => void;
}
/**
 * Use this hook to handle DOM events of any kind.
 *
 * @example
 * useEventHandler({
 *   target: useRef(document),
 *   eventName: 'scroll'
 *   handler: useCallback(e => {
 *     // Do something
 *   }, [])
 * });
 *
 */

export const useEventHandler = <K extends keyof GlobalEventHandlersEventMap>({
    target,
    skip,
    debounce = 100,
    eventName,
    handler,
}: Props<K>) => {
    useEffect(() => {
        if (!hasValue(target.current) || skip) {
            return () => {
                /* */
            };
        }
        const listener = fromEvent(target.current, eventName).pipe(debounceTime(debounce)).subscribe(handler);
        return () => {
            listener.unsubscribe();
        };
    }, [target, skip, debounce, handler, eventName]);
};
