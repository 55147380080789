import React, {useContext} from 'react';
import MuiAvatar, {type AvatarProps as MuiAvatarProps} from '@mui/material/Avatar';

import {Icon} from 'modern-famly/components/data-display';
import {useDataProps} from 'modern-famly/components/util';
import {AvatarGroupContext} from 'modern-famly/components/data-display/avatar-group/avatar-group';
import {hasValue} from 'modern-famly/util';
import {type ColorKey} from 'modern-famly/theming';

export type AvatarProps = {
    /**
     * Used in combination with `src` or to provide an alt attribute for the rendered `img` element.
     */
    alt?: MuiAvatarProps['alt'];

    /**
     * The initials to show in place of the image in case it fails to load.
     */
    initials?: string;

    /**
     * The colors of the avatar component when the image is not shown
     */
    color?: AvatarColor;

    /**
     * The size in pixels of the avatar. The font size will be adapted accordingly.
     */
    size?: number;

    /**
     * The `src` attribute for the `img` element.
     */
    src?: MuiAvatarProps['src'];

    /**
     * Whether the avatar component appears disabled
     */
    disabled?: boolean;
};

/**
 * The color variations that the avatar's "initials state" can be
 */
export type AvatarColor =
    | 'light-blue'
    | 'blue'
    | 'light-green'
    | 'green'
    | 'light-yellow'
    | 'yellow'
    | 'light-orange'
    | 'orange';

const DEFAULT_SIZE = 32;

export const Avatar = ({alt, initials, size: sizeFromProps, src, color, disabled, ...props}: AvatarProps) => {
    const dataProps = useDataProps(props);
    const {size: sizeFromAvatarGroup} = useContext(AvatarGroupContext);

    const size = sizeFromProps ?? sizeFromAvatarGroup ?? DEFAULT_SIZE;

    const {background, foreground} = getAvatarColors({color, disabled});

    return (
        <MuiAvatar
            alt={alt}
            src={src}
            sx={theme => ({
                background: theme.modernFamlyTheme.colorPalette[background],
                color: theme.modernFamlyTheme.colorPalette[foreground],
                width: `${size}px`,
                height: `${size}px`,
                fontSize: `${getFontSize(size)}px`,
                lineHeight: 'unset',
                textTransform: 'uppercase',
                ['.MuiAvatar-img']: {
                    // Grayscale the profile image if the avatar is disabled
                    filter: hasValue(src) && disabled === true ? 'grayscale(1)' : undefined,
                    opacity: hasValue(src) && disabled === true ? '0.5' : undefined,
                },
            })}
            {...dataProps}
        >
            {getFallback({initials, size})}
        </MuiAvatar>
    );
};

const getAvatarColors = (config: {
    color?: AvatarColor;
    disabled?: boolean;
}): {foreground: ColorKey; background: ColorKey} => {
    if (config.disabled === true) {
        return {background: 'n50', foreground: 'n200'};
    }

    switch (config.color) {
        case 'light-blue':
            return {background: 'b100', foreground: 'b400'};
        case 'blue':
            return {background: 'b200', foreground: 'b500'};
        case 'light-green':
            return {background: 'g100', foreground: 'g400'};
        case 'green':
            return {background: 'g200', foreground: 'g500'};
        case 'light-orange':
            return {background: 'o100', foreground: 'o400'};
        case 'orange':
            return {background: 'o200', foreground: 'o500'};
        case 'light-yellow':
            return {background: 'y100', foreground: 'y400'};
        case 'yellow':
            return {background: 'y200', foreground: 'o500'};
        default:
            return {background: 'n100', foreground: 'n400'};
    }
};

/**
 * Gets the font size based on the size of the avatar
 *
 * @param size number
 * @returns number
 */
export const getFontSize = (size: number) => {
    /**
     * Based on linear regression over these samples:
     * size: 100 -> fontSize: 36
     * size: 60  -> fontSize: 24
     * size: 40  -> fontSize: 16
     * size: 20  -> fontSize: 8
     */
    const fontSize = 0.349 * size + 1.83;

    return Math.round(fontSize);
};

/**
 * Gets the fallback of the Avatar if image cannot be loaded. Fallback order:
 *
 * - `initials` (if provided)
 * - Generic profile image icon
 *
 * @param config
 * @returns
 */
const getFallback = (config: {initials?: string; size: number}): React.ReactNode => {
    if (config.initials) {
        return config.initials;
    }

    return <Icon name="person" filled={config.size <= 32} size={config.size - 10} />;
};
