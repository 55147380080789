import RESTClient from 'signin-app/api/clients/rest-client';

export default class EmployeeApi {
    public static checkIn(accessToken: string, employeeId: string, groupId: string, estimatedCheckoutTime?: string) {
        return RESTClient.postObservable(`v2/employees/${employeeId}/checkins`, {
            accessToken,
            estimatedCheckoutTime,
            groupId,
        });
    }

    public static checkOut(accessToken: string, employeeId: string) {
        return RESTClient.postObservable(`v2/employees/${employeeId}/checkout`, {
            accessToken,
        });
    }
}
