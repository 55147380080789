import React from 'react';

import {G, SVG} from '../svg';
import {defaultPropTypes} from './default-prop-types';
import {type IconComponent} from './icon-component';

export const ArrowIcon: IconComponent<any> = props => {
    const {appearance, fill, ...rest} = props;
    return (
        <SVG {...rest} viewBox="0 0 24 24">
            <G appearance={appearance} color={fill}>
                <polygon points="13.9776119 7.32238825 10.0223881 11.2776121 8.97761194 12.3223883 10.0223881 13.3671644 13.9776119 17.3223883 15.0223881 16.2776121 11.0671642 12.3223883 15.0223881 8.36716437" />
            </G>
        </SVG>
    );
};
ArrowIcon.propTypes = defaultPropTypes;
ArrowIcon.isPrivate = true;

export default ArrowIcon;
