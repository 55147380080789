import React from 'react';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {type StatPermissions} from './stat-permissions';
import {useContractBankHoursPermissions} from './use-contract-bank-hours-permissions';
import {useContractChangeLogPermissions} from './use-contract-change-log-permissions';
import {useStaffHoursExportCSVPermissions} from './use-staff-hours-export-csv-permissions';
import {useStaffHoursFiltersPermissions} from './use-staff-hours-filters-permissions';

export function useStaffHoursPagePermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['staffHoursPage'] & {loading: boolean} {
    const {loading: loadingChangeLog, ...seeChangeLog} = useContractChangeLogPermissions(payload);
    const {loading: loadingBankHours, ...bankHours} = useContractBankHoursPermissions(payload);
    const {loading: loadingExport, ...exportCsv} = useStaffHoursExportCSVPermissions(payload);
    const {loading: loadingStaffHours, ...staffHours} = useStaffHoursFiltersPermissions(payload);

    return React.useMemo(
        () => ({
            loading: loadingChangeLog || loadingBankHours || loadingExport,
            header: {
                seeChangeLog,
                export: exportCsv,
                filters: staffHours,
            },
            content: {
                bankHours,
            },
        }),
        [bankHours, exportCsv, loadingBankHours, loadingChangeLog, loadingExport, seeChangeLog, staffHours],
    );
}
