import {type Theme} from '../theme';
import {createBaseThemeVariation} from './base';

export const CpcTreehouseTheme: Theme = createBaseThemeVariation({
    name: 'cpc-treehouse',
    brandColor: 'hsla(120, 50%, 40%, 1)',
    primaryColors: {
        p50: 'hsla(120, 50%, 98%, 1)',
        p75: 'hsla(120, 50%, 97%, 1)',
        p100: 'hsla(120, 50%, 96%, 1)',
        p200: 'hsla(120, 50%, 90%, 1)',
        p300: 'hsla(120, 50%, 70%, 1)',
        p400: 'hsla(120, 50%, 40%, 1)',
        p500: 'hsla(120, 50%, 10%, 1)',
    },
});
