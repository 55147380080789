import {useTransformQuery} from 'web-app/react/hooks/use-transform-query';

import {GetChildNameFromId} from './queries';
import {type GetChildNameFromIdQuery, type GetChildNameFromIdQueryVariables} from './__generated__/queries.api-types';

export const useGoingHomeWith = ({goHomeWithChildId}) => {
    const [childName] = useTransformQuery<GetChildNameFromIdQuery, GetChildNameFromIdQueryVariables>()(
        GetChildNameFromId,
        {
            variables: {
                childIds: [goHomeWithChildId],
            },
            skip: !goHomeWithChildId,
        },
        ({data}) => {
            if (!data || !data.children || data.children.length === 0) {
                return null;
            }

            return data.children[0].name.firstName;
        },
    );
    return childName;
};
