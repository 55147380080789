import React from 'react';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {type StatPermissions} from './stat-permissions';
import {useMilkReportPermissions} from './use-milk-report-permissions';
import {useKinderConnectPermissions} from './use-kinder-connect-permissions';

export function useChildAttendancePermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['childAttendance'] & {loading: boolean} {
    const {loading: loadingMilkReport, ...milkReport} = useMilkReportPermissions(payload);
    const {loading: loadingKinderConnect, ...kinderConnect} = useKinderConnectPermissions(payload);

    return React.useMemo(
        () => ({
            loading: loadingMilkReport || loadingKinderConnect,
            header: {
                export: {
                    milkReport,
                    kinderConnect,
                },
            },
            content: {},
        }),
        [kinderConnect, loadingKinderConnect, loadingMilkReport, milkReport],
    );
}
