import type i18next from 'i18next';
import React from 'react';

import {getGroupName} from './get-group-name';

export function useGetCurrentAndBaseGroupName(
    allGroups?: {groupId: string; title?: string}[],
    currentGroupId?: string,
    baseGroupId?: string,
    unknownRoomTranslationKey: Parameters<typeof i18next.t>[0] = 'unknown',
): {currentGroupName: string; baseGroupName: string} {
    return React.useMemo(() => {
        return getCurrentAndBaseGroupName(allGroups, currentGroupId, baseGroupId, unknownRoomTranslationKey);
    }, [allGroups, currentGroupId, baseGroupId, unknownRoomTranslationKey]);
}

export function getCurrentAndBaseGroupName(
    allGroups?: {groupId: string; title?: string}[],
    currentGroupId?: string,
    baseGroupId?: string,
    unknownRoomTranslationKey: Parameters<typeof i18next.t>[0] = 'unknown',
): {currentGroupName: string; baseGroupName: string} {
    return {
        currentGroupName: getGroupName(allGroups, currentGroupId, unknownRoomTranslationKey),
        baseGroupName: getGroupName(allGroups, baseGroupId, unknownRoomTranslationKey),
    };
}
