import {ofType} from 'redux-observable';
import {merge, of, from} from 'rxjs';
import {mergeMap, map, catchError, tap, concatMap} from 'rxjs/operators';

import {combineEpics} from 'web-app/util/redux-observable';
import DayCareApi from 'signin-app/api/daycare-api';
import * as GlobalEventActions from 'signin-app/global-event/actions';
import translate from 'signin-app/helpers/translate';
import {saveLoginData, saveAccessToken, removeLoginData} from 'signin-app/api/helpers/local-storage';
import {type Action} from 'web-app/util/redux/action-types';
import {type RootState} from 'signin-app/redux/main-reducer';
import {type SignInEpic} from 'signin-app/redux/types';
import {client} from 'signin-app/api/clients/apollo-client';

import * as Actions from './actions';
import * as Queries from './queries';
import {type GetQRSignInSettingsQuery, type GetSiteIdQuery} from './__generated__/queries.api-types';

const getSiteId = () => {
    return from(
        client.query<GetSiteIdQuery>({
            query: Queries.GetSiteId,
            fetchPolicy: 'network-only',
        }),
    ).pipe(
        mergeMap(({data}) => {
            return of(data?.institutions[0].institutionId);
        }),
        catchError(() => {
            return of('');
        }),
    );
};

const isQrSigninAvailable = (siteId: string) => {
    return from(
        client.query<GetQRSignInSettingsQuery>({
            query: Queries.GetQRSignInSettings,
            variables: {
                siteIds: [siteId],
            },
            fetchPolicy: 'network-only',
        }),
    ).pipe(
        mergeMap(({data}) => {
            return of(data?.qrSignin.siteSettings.siteSettings[0].isEnabled); // The sign in user has one institution id only
        }),
        catchError(() => {
            return of(false);
        }),
    );
};

const login: SignInEpic = action$ =>
    action$.pipe(
        ofType(Actions.login.type),
        mergeMap(({payload}: ReturnType<typeof Actions.login.action>) =>
            DayCareApi.login(payload.password).pipe(
                tap(response => saveAccessToken(response.accessToken)),
                mergeMap(response => {
                    return getSiteId().pipe(
                        mergeMap(siteId => {
                            return isQrSigninAvailable(siteId as string).pipe(
                                tap(isQrSigninEnabled =>
                                    saveLoginData(response.pinSigninAvailable, isQrSigninEnabled, siteId),
                                ),
                                map(isQrSigninEnabled => {
                                    return Actions.loginSuccess.action(
                                        response.accessToken,
                                        response.pinSigninAvailable,
                                        isQrSigninEnabled,
                                        siteId,
                                    );
                                }),
                            );
                        }),
                    );
                }),
                catchError(e =>
                    merge(
                        of(GlobalEventActions.updateError.action(translate('invalidPassword'))),
                        of(Actions.loginFailed.action(e)),
                    ),
                ),
            ),
        ),
    );

const logout: SignInEpic = action$ =>
    action$.pipe(
        ofType(Actions.logOut.type),
        concatMap(() => {
            return of(Actions.logOutSuccess.action());
        }),
        concatMap(logOutSuccessAction => {
            // After Actions.logOutSuccess.action() completes, remove login data
            removeLoginData();
            return of(logOutSuccessAction);
        }),
    );

export default combineEpics<Action<any>, Action<any>, RootState>(login, logout);
