import createSelector from 'web-app/util/custom-create-selector';
import * as GroupsSelectors from 'signin-app/groups/selectors';

export const groupsForFormData = createSelector(
    GroupsSelectors.groups,
    GroupsSelectors.staffGroups,
    GroupsSelectors.hiddenGroupIds,
    (groups, staffGroups, hiddenGroupIds) =>
        groups
            .filter(group => group.noChildren > 0)
            .concat(staffGroups.filter(group => group.employees.size > 0))
            .sortBy(group => group.title)
            .map(group => ({
                id: group.groupId,
                // Make all groups checked if none are selected
                value: !hiddenGroupIds.includes(group.groupId),
                title: group.title,
            }))
            .toArray(),
);
