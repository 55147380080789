import i18next from 'i18next';
import React from 'react';

import {getPersonCurrentRoomId} from './get-person-current-room';
import {getCurrentAndBaseGroupName} from './use-get-current-and-base-group-name';

interface isInAnotherRoomPayload {
    groupId: string;
    checkins?: ImmutableListOrArray<{groupId: string}>;
    allGroups?: {groupId: string; title?: string}[];
    unknownRoomTranslationKey?: Parameters<typeof i18next.t>[0];
}
export function useIsInAnotherRoom(payload: isInAnotherRoomPayload): boolean {
    return React.useMemo(() => isInAnotherRoom(payload), [payload]);
}

export function isInAnotherRoom({
    groupId,
    checkins,
    allGroups,
    unknownRoomTranslationKey = 'unknown',
}: isInAnotherRoomPayload): boolean {
    // this is the id the user is currently checked in to
    const currentRoom = getPersonCurrentRoomId(checkins);

    const {currentGroupName, baseGroupName} = getCurrentAndBaseGroupName(
        allGroups,
        currentRoom,
        groupId,
        unknownRoomTranslationKey,
    );

    return checkRoomsDiffering(currentGroupName, baseGroupName, unknownRoomTranslationKey);
}

function checkRoomsDiffering(
    currentGroupName: string,
    baseGroupName: string,
    unknownRoomTranslationKey: Parameters<typeof i18next.t>[0] = 'unknown',
): boolean {
    return (
        ![currentGroupName, baseGroupName].includes(i18next.t(unknownRoomTranslationKey)) &&
        currentGroupName !== baseGroupName
    );
}
