import {map} from 'rxjs/operators';

import RESTClient from 'signin-app/api/clients/rest-client';
import BehaviorsCollection from 'web-app/behaviors/behaviors-collection';

export const fetchAccount = () => {
    return RESTClient.getAuthenticatedObservable('me/me/me').pipe(
        map(response => {
            return {
                loginId: response.loginId,
                behaviors: new BehaviorsCollection(response.behaviors),
            };
        }),
    );
};
