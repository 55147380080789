import React from 'react';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {Text, Stack, Box, createStack, Icon} from 'modern-famly';
import moment from 'moment-timezone';
import {ZonedDateTime} from 'js-joda';

import {type IEmployee} from 'signin-app/entities/staff/model';
import type ChildModel from 'signin-app/entities/children/model';
import {type PinChild, type PinEmployee} from 'signin-app/pin/models';
import {limitedData} from 'signin-app/child/check-out/selectors';
import {useTypedSelector} from 'signin-app/components/hooks';
import {LeaveType} from 'web-app/react/leave-types/leave-type';
import {getLeaveTypeIcon, LeaveTypeIconSize} from 'web-app/react/leave-types/leave-type-icon';
import {showSignedIn} from 'signin-app/group/selectors';
import * as GroupsSelectors from 'signin-app/groups/selectors';
import translate from 'signin-app/helpers/translate';
import {PersonImage} from 'signin-app/components/person-image';
import {getWentHome} from 'web-app/react/components/person-button/person-button-base';
import {useCustomWhitelabelColor} from 'signin-app/components/hooks/use-custom-whitelabel-color';

interface PersonButtonProps {
    person: PersonType;
    onClick?: (personId: string) => void;
    linkTo?: string;
    className?: string;
    selected?: boolean;
    hide?: boolean;
    compact?: boolean;
}

type PersonType = typeof ChildModel.default | IEmployee | PinChild | PinEmployee;

const StyledPersonContainer = styled(
    createStack({
        p: '20px',
        borderRadius: '12px',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
    }),
)<{selected?: boolean; hide?: boolean; compact?: boolean; customBackgroundColor?: string; customBorderColor?: string}>`
    ${props =>
        props.compact
            ? css`
                  width: 120px;
              `
            : ''}
    overflow: hidden;

    ${props =>
        props.compact
            ? ''
            : css`
                  border: 1px solid ${props.customBorderColor};
              `}

    ${props =>
        props.selected
            ? css`
                  background-color: ${props.customBackgroundColor};
              `
            : ''}

    ${props =>
        props.hide
            ? css`
                  display: none !important;
              `
            : ''}
          
    box-sizing: border-box;
`;

enum StatusState {
    SignedIn = 'SignedIn',
    SignedOut = 'SignedOut',
    None = 'None',
}

const hasProperty = (obj: any, property): obj is typeof ChildModel.default => {
    return property in obj;
};

const employeeHasProperty = (obj: any, property): obj is IEmployee => {
    return property in obj;
};

const StyledIconContainer = styled(
    createStack({
        position: 'absolute',
        width: '36px',
        height: '44px',
        top: '0',
        right: '0',
        alignItems: 'center',
        justifyContent: 'center',
    }),
)<{customBackgroundColor?: string}>`
    border-radius: 0 12px 0 12px;
    background-color: ${props => props.customBackgroundColor};
`;

const StyledBirthdayIcon = ({customBackgroundColor}) => {
    return (
        <StyledIconContainer customBackgroundColor={customBackgroundColor}>
            <span>🎂</span>
        </StyledIconContainer>
    );
};

const AbsenceImage = styled(
    createStack({
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        position: 'absolute',
        top: '50%',
        left: '50%',
    }),
)`
    transform: translate(-50%, -50%);
`;

const StyledTextContainer = styled(createStack({}))`
    max-width: 90%;
    * {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;

const PersonButton: React.FC<PersonButtonProps> = props => {
    const {linkTo, person, hide, selected, compact, onClick} = props;
    const isDataLimited = useTypedSelector(state => limitedData(state));
    const shouldShowSignedIn = useTypedSelector(state => showSignedIn(state));
    const timezoneId = useTypedSelector(GroupsSelectors.timezoneId);
    const p300 = useCustomWhitelabelColor('p300');
    const p400 = useCustomWhitelabelColor('p400');

    const hasBirthdayToday = React.useMemo(() => {
        if (hasProperty(person, 'birthday')) {
            const birthday = person.birthday;
            const today = moment();
            const birthdate = moment(birthday);
            const hasBirthday = today.month() === birthdate.month() && today.date() === birthdate.date();
            return hasBirthday;
        }
        return false;
    }, [person]);

    const handleButtonClick = React.useCallback(() => {
        if (onClick) {
            onClick(person.id);
        }
    }, [person, onClick]);

    const displayName = React.useMemo(() => {
        if (compact) {
            return person.name.firstName;
        }
        return `${person.name.firstName} ${person.name.lastName.charAt(0)}.`;
    }, [person, compact]);

    const pickupDetails = React.useMemo(() => {
        if (
            hasProperty(person, 'pickupTime') &&
            hasProperty(person, 'pickupName') &&
            person.pickupTime &&
            person.pickupName &&
            timezoneId
        ) {
            /** Following the timezone logic from signin-app/src/child/check-in/pickup-time.tsx */
            const pickupTime = ZonedDateTime.parse(person.pickupTime).withZoneSameInstant(timezoneId);
            const hours = pickupTime.hour().toString().padStart(2, '0');
            const minutes = pickupTime.minute().toString().padStart(2, '0');
            const formattedTime = `${hours}:${minutes}`;
            return translate('leavingTimePerson', {
                time: formattedTime,
                name: person.pickupName,
            });
        }
        return null;
    }, [person, timezoneId]);

    const personButtonInner = React.useMemo(
        () => (
            <StyledPersonContainer
                hide={hide}
                selected={selected}
                compact={compact}
                customBackgroundColor={p400}
                customBorderColor={p300}
            >
                <PersonAvatar person={person} isDataLimited={isDataLimited} />
                <StyledTextContainer mt={compact ? '12px' : ''}>
                    <Text variant={compact ? 'body' : 'h6'} emphasized color="n0">
                        {displayName}
                    </Text>
                </StyledTextContainer>
                {hasBirthdayToday ? <StyledBirthdayIcon customBackgroundColor={p300} /> : null}
                {shouldShowSignedIn ? (
                    <Text variant="micro" color="n0">
                        {pickupDetails}
                    </Text>
                ) : null}
            </StyledPersonContainer>
        ),
        [
            person,
            displayName,
            hide,
            selected,
            hasBirthdayToday,
            isDataLimited,
            shouldShowSignedIn,
            pickupDetails,
            compact,
            p300,
            p400,
        ],
    );

    return (
        <>
            {linkTo ? (
                <Link to={linkTo} onClick={handleButtonClick}>
                    <Box>{personButtonInner}</Box>
                </Link>
            ) : (
                <Box onClick={handleButtonClick}>{personButtonInner}</Box>
            )}
        </>
    );
};

export const PersonAvatar: React.FC<{person: PersonType; isDataLimited: boolean}> = ({person, isDataLimited}) => {
    const wentHome = React.useMemo(() => {
        if (hasProperty(person, 'checkins')) {
            return getWentHome(person.checkins);
        }
        if (employeeHasProperty(person, 'checkoutTime')) {
            return !person.checkedIn && person.checkoutTime;
        }
        return false;
    }, [person]);

    const absenceStatus = React.useMemo(() => {
        if (hasProperty(person, 'hasVacation') && person.hasVacation) {
            return getLeaveTypeIcon(LeaveType.Vacation, LeaveTypeIconSize.huge);
        }
        if (hasProperty(person, 'isSick') && person.isSick) {
            return getLeaveTypeIcon(LeaveType.Sick, LeaveTypeIconSize.huge);
        }
        if (hasProperty(person, 'isAbsent') && person.isAbsent) {
            return getLeaveTypeIcon(LeaveType.Absent, LeaveTypeIconSize.huge);
        }
        return null;
    }, [person]);

    const initials = React.useMemo(() => {
        return `${person.name.firstName.charAt(0)}${person.name.lastName.charAt(0)}`;
    }, [person]);

    const status = React.useMemo(() => {
        if (wentHome) {
            return StatusState.SignedOut;
        }
        if (person.checkedIn) {
            return StatusState.SignedIn;
        }
        return StatusState.None;
    }, [person.checkedIn, wentHome]);

    return (
        <Stack position="relative">
            <PersonImage
                image={person.image.small}
                initials={initials}
                size={86}
                isAbsent={Boolean(absenceStatus)}
                isNotSignedIn={status !== StatusState.SignedIn}
            />
            {!isDataLimited ? <Status status={status} /> : null}
            {absenceStatus ? <AbsenceImage>{absenceStatus}</AbsenceImage> : null}
        </Stack>
    );
};

const StyledStatusBox = styled(
    createStack({
        width: '22px',
        height: '22px',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
    }),
)`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 100;
`;

const Status: React.FC<{status: StatusState}> = ({status}) => {
    const bgColor = React.useMemo(() => {
        if (status === StatusState.SignedIn) {
            return 'g300';
        }
        if (status === StatusState.SignedOut) {
            return 'n300';
        }
        return 'n0';
    }, [status]);
    return (
        <StyledStatusBox backgroundColor={bgColor}>
            {status === StatusState.SignedIn ? <Icon name="check" size={16} color="n0" /> : null}
            {status === StatusState.SignedOut ? <Icon name="exit_to_app" size={16} color="n0" /> : null}
        </StyledStatusBox>
    );
};

export default PersonButton;
