import React from 'react';
import {ZonedDateTime, DateTimeFormatter} from 'js-joda';
import {type RouteComponentProps} from 'react-router-dom';
import {Box, Text} from 'modern-famly';
import {useDispatch} from 'react-redux';

import * as LoginSelectors from 'signin-app/login/selectors';
import translate from 'signin-app/helpers/translate';
import {isPrefetching} from 'signin-app/components/private-route/selectors';
import * as GroupSelectors from 'signin-app/groups/selectors';
import {checkInEmployee} from 'signin-app/employee/check-in/actions';
import {limitedData} from 'signin-app/child/check-out/selectors';
import Header from 'signin-app/components/header';
import {PinHeader} from 'signin-app/child/pin-header';
import {
    ConfirmButton,
    ActionButton,
    BottomBarContainer,
    PageBodyContainer,
    ConfirmButtonTypes,
} from 'signin-app/components/action-buttons';
import {UserOverview} from 'signin-app/components/user-overview';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import {getFormattedTime} from 'signin-app/helpers/time';
import {timeFormatAgnosticSplitTimeString} from 'web-app/react/components/form-elements/time/time';
import {Card, CardsContainer} from 'signin-app/groups/group-item';
import {useTypedSelector} from 'signin-app/components/hooks';
import PersonSelect from 'signin-app/components/person-select';
import * as WhitelabelSelectors from 'signin-app/whitelabel/selectors';
import {isPinApp} from 'signin-app/login/selectors';
import {PageContainer} from 'signin-app/components/containers';

import {checkOutEmployee} from './actions';
import * as Selectors from './selectors';

type CheckOutRouteProps = RouteComponentProps<{employeeId: string}>;

export const EmployeeCheckOut: React.FC<CheckOutRouteProps> = props => {
    const accessToken = useTypedSelector(LoginSelectors.accessToken);
    const isFetching = useTypedSelector(isPrefetching);
    const employee = useTypedSelector(state => Selectors.employee(state, props));
    const isCheckingOut = useTypedSelector(Selectors.isCheckingOut);
    const groups = useTypedSelector(GroupSelectors.groups);
    const timezoneId = useTypedSelector(GroupSelectors.timezoneId);
    const isDataLimited = useTypedSelector(limitedData);
    const employeeEstimatedCheckoutTimeEnabled = useTypedSelector(GroupSelectors.employeeEstimatedCheckoutTimeEnabled);
    const locale = useTypedSelector(GroupSelectors.institutionLocale);
    const canSwitchRoom = useTypedSelector(GroupSelectors.canSwitchRoom);
    const isPin = useTypedSelector(isPinApp);
    const whitelabelConfiguration = useTypedSelector(WhitelabelSelectors.whiteLabelConfiguration);

    const dispatch = useDispatch();

    const estimatedCheckoutTime = React.useMemo(() => {
        if (employee && employeeEstimatedCheckoutTimeEnabled && timezoneId) {
            const formatter = DateTimeFormatter.ofPattern('HH:mm');
            return employee.estimatedCheckoutTime
                ? ZonedDateTime.parse(employee.estimatedCheckoutTime).withZoneSameInstant(timezoneId).format(formatter)
                : '';
        }
        return undefined;
    }, [employee, employeeEstimatedCheckoutTimeEnabled, timezoneId]);

    const [hour, minute] = estimatedCheckoutTime ? estimatedCheckoutTime.split(timeFormatAgnosticSplitTimeString) : '';
    const formattedEstimatedCheckoutTime = getFormattedTime(parseInt(hour, 10), parseInt(minute, 10), locale, false);

    const handleRoomSwitch = React.useCallback(
        (roomId: string) => {
            if (!employee || !timezoneId) {
                return;
            }
            const successMessage = translate('hasMovedRoom', {personName: employee.name.fullName});

            const parsedPickupTime =
                employeeEstimatedCheckoutTimeEnabled && employee.estimatedCheckoutTime
                    ? ZonedDateTime.parse(employee.estimatedCheckoutTime as string).withZoneSameInstant(timezoneId)
                    : undefined;

            dispatch(
                checkInEmployee.action(
                    accessToken,
                    employee.id,
                    parsedPickupTime?.hour(),
                    parsedPickupTime?.minute(),
                    roomId,
                    successMessage,
                ),
            );
        },
        [employee, timezoneId, accessToken, dispatch, employeeEstimatedCheckoutTimeEnabled],
    );

    return (
        <PageContainer data-e2e-id="employee-checkout">
            {isPin ? <PinHeader whitelabelConfiguration={whitelabelConfiguration} /> : <Header />}
            {employee && !isFetching ? (
                <>
                    <PageBodyContainer mt={16}>
                        <PersonSelect activeEmployeeId={employee.id} />
                        <UserOverview
                            image={employee.image.large}
                            name={isDataLimited ? employee.name.firstName : employee.name.fullName}
                            subText={employeeEstimatedCheckoutTimeEnabled ? translate('leavingToday') : ''}
                            subTextBold={formattedEstimatedCheckoutTime.fullTime}
                        />
                        {canSwitchRoom && (
                            <Box my={3}>
                                <Text variant="h6" color="n0" emphasized>
                                    {translate('switchRoom')}
                                </Text>
                                <CardsContainer mt={2}>
                                    {groups.map(group => (
                                        <Card
                                            key={group.groupId}
                                            image={group.image.large}
                                            displayTitle={group.title}
                                            onClick={() => handleRoomSwitch(group.groupId)}
                                            selected={
                                                employee.tempGroupId
                                                    ? group.groupId === employee.tempGroupId
                                                    : group.groupId === employee.groupId
                                            }
                                            dataE2eCardContainerClass={`group-title-${group.title}`}
                                        />
                                    ))}
                                </CardsContainer>
                            </Box>
                        )}
                    </PageBodyContainer>
                    <BottomBarContainer>
                        <ActionButton
                            text={translate('changeLeavingTime')}
                            link={`/employee/${employee.id}/checkin?update`}
                        />
                        <ConfirmButton
                            type={ConfirmButtonTypes.SIGN_OUT}
                            text={translate('signOut')}
                            isLoading={isCheckingOut}
                            onClick={() => dispatch(checkOutEmployee.action(accessToken, employee.id))}
                            dataE2eCheckinConfirmButton="employee-check-out-confirm-button"
                        />
                    </BottomBarContainer>
                </>
            ) : (
                <Spinner color="white" centered />
            )}
        </PageContainer>
    );
};
