import React from 'react';
import MuiAvatarGroup, {type AvatarGroupProps as MuiAvatarGroupProps} from '@mui/material/AvatarGroup';
import {avatarClasses} from '@mui/material/Avatar';
import {type Components, type Theme} from '@mui/material/styles';

import {getFontSize} from 'modern-famly/components/data-display/avatar/avatar';
import {useDataProps, type DataProps} from 'modern-famly/components/util';

export type AvatarGroupProps = {
    /**
     * The Avatars to display. Use the Avatar component from Modern Famly.
     *
     * Example:
     * ```tsx
     * return (
     *    <AvatarGroup>
     *        <Avatar initials="AB" />
     *        <Avatar initials="CD" />
     *        <Avatar initials="EF" />
     *        <Avatar initials="GH" />
     *    </AvatarGroup>
     * )
     * ```
     */
    children?: MuiAvatarGroupProps['children'];

    /**
     * The maximum number of Avatars to display before showing an overflow avatar.
     *
     * Defaults to 4.
     */
    max?: MuiAvatarGroupProps['max'];

    /**
     * The size in pixels of the avatar. The font size will be adapted accordingly.
     *
     * Defaults to 32.
     */
    size?: number;
} & DataProps;

export const AvatarGroupContext = React.createContext<{size?: number}>({});

const OVERFLOW_CLASSNAME = 'MFAvatarGroup-overflow';
const DEFAULT_MAX = 4;
const DEFAULT_SIZE = 32;

export const AvatarGroup = ({children, max = DEFAULT_MAX, size = DEFAULT_SIZE, ...props}: AvatarGroupProps) => {
    const dataProps = useDataProps(props);

    return (
        <AvatarGroupContext.Provider value={React.useMemo(() => ({size}), [size])}>
            <MuiAvatarGroup
                max={max}
                slotProps={{
                    additionalAvatar: {className: OVERFLOW_CLASSNAME},
                }}
                sx={theme => ({
                    [`& .${OVERFLOW_CLASSNAME}`]: {
                        backgroundColor: theme.modernFamlyTheme.colorPalette.b400,
                        width: `${size}px`,
                        height: `${size}px`,
                        fontSize: `${getFontSize(size)}px`,
                        lineHeight: 'unset',
                    },
                    // Ensures that we start a new stacking context for the z-index applied to reverse the stacking order.
                    position: 'relative',
                })}
                {...dataProps}
            >
                {children}
            </MuiAvatarGroup>
        </AvatarGroupContext.Provider>
    );
};

export const AvatarGroupThemeConfiguration: Components<Theme>['MuiAvatarGroup'] = {
    styleOverrides: {
        root: ({ownerState: {max = DEFAULT_MAX}}) => ({
            // We want to stack the visible avatars from left to right, but MUI's default is
            // to stack from right to left. So we need to reverse the order of the visible items.
            // From: https://github.com/mui/material-ui/issues/30789#issuecomment-1054061085
            //
            // MUI also has this open issue suggesting to open this up to the user as a prop:
            // https://github.com/mui/material-ui/issues/30789
            ...[...Array(max)].reduce(
                (result, curr, index) => ({
                    ...result,
                    [`& > .${avatarClasses.root}:nth-of-type(${index + 1})`]: {
                        zIndex: max - index,
                    },
                }),
                {},
            ),
        }),
    },
};
