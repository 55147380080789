import {gql} from '@apollo/client';

// unfortunately, graphql does not allow creating fragments out of nowhere, so we have to go
// with the leave management as base :/
export const StatPermissionLeaves = gql`
    fragment StatPermissionLeaves on LeaveManagementQuery {
        sick(siteSetId: $institutionSetId, employeeId: $employeeId) {
            ...StatPermissionLeave
        }
        childSick(siteSetId: $institutionSetId, employeeId: $employeeId) {
            ...StatPermissionLeave
        }
        holiday(siteSetId: $institutionSetId, employeeId: $employeeId) {
            ...StatPermissionLeave
        }
        absence(siteSetId: $institutionSetId, employeeId: $employeeId) {
            ...StatPermissionLeave
        }
    }
`;
