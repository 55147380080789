import React from 'react';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {type StatPermissions} from './stat-permissions';
import {useNewFormPermissions} from './use-new-form-permissions';

export function useFormsPermissions(payload: UseStatPermissionPayload): StatPermissions['forms'] & {loading: boolean} {
    const {loading: loadingNewForm, ...newForm} = useNewFormPermissions(payload);

    return React.useMemo(
        () => ({
            loading: loadingNewForm,
            header: {
                newForm,
            },
            content: {},
        }),
        [loadingNewForm, newForm],
    );
}
