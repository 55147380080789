import {useMemo} from 'react';
import {type TypographyProps} from '@mui/material/Typography';

type DataProp = `data-${string}`;
export type DataProps = {[key: DataProp]: string};
type Props = {[key: string]: any};

export const extractDataProps = (props: Props) => {
    const dataKeys = Object.keys(props).filter(key => key.startsWith('data-')) as DataProp[];

    return dataKeys.reduce((acc, key) => {
        acc[key] = props[key];
        return acc;
    }, {} as DataProps);
};

/**
 * Utility hook to extract all data-attributes that are added to components
 */
export const useDataProps = (props: Props): DataProps => {
    return useMemo(() => {
        return extractDataProps(props);
    }, [props]);
};

export const getEmphasizedFontWeight = (variant: TypographyProps['variant']) => {
    return variant === 'micro' ? '700' : '600';
};
