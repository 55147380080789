import React from 'react';

import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useDailyTotalsPermissions} from './use-daily-totals-permissions';
import {useDetailedBreakdownPermissions} from './use-detailed-breakdown-permissions';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export function useAttendancePermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['attendance'] & {loading: boolean} {
    const {loading: loadingDailyTotals, ...dailyTotals} = useDailyTotalsPermissions(payload);
    const {loading: loadingDetailedBreakdown, ...detailedBreakdown} = useDetailedBreakdownPermissions(payload);

    return React.useMemo(
        () => ({
            loading: loadingDailyTotals || loadingDetailedBreakdown,
            header: {
                export: {
                    dailyTotals,
                    detailedBreakdown,
                },
            },
            content: {},
        }),
        [loadingDailyTotals, dailyTotals, loadingDetailedBreakdown, detailedBreakdown],
    );
}
