import {hasValue} from 'web-app/util/typescript';

import {type StatPermissions} from './stat-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useFetchSchedulePermissions} from './queries/use-fetch-schedule-permissions';

/**
 * Do not use this query directly!
 */
export function useSchedulePermissions(payload: UseStatPermissionPayload): StatPermissions['schedules'] {
    const {institutionSetId, employeeId, shouldQuery} = payload;
    return useCrudPermissionAdapter(
        useFetchSchedulePermissions({
            variables: {
                siteSetId: institutionSetId,
                employeeId: employeeId as string,
            },
            skip: !hasValue(institutionSetId) || !hasValue(employeeId) || !shouldQuery,
        }).data?.staffhours.management.schedules,
    );
}
