import React from 'react';

import {type Option} from './types';
import Select from './select';

const groupRegex = /CHILDREN|EMPLOYEES|TAG/;

const defaultSortOptions = (options: Option<string>[]) => {
    const groupOptions = options.filter(option => {
        return option.value.match(groupRegex);
    });

    const personOptions = options.filter(option => {
        return !option.value.match(groupRegex);
    });

    return groupOptions.concat(personOptions);
};

interface InputProps {
    options: Option<string>[];
    onBlur?: React.FocusEventHandler;
    isLoading?: boolean;
    placeholder?: string;
    className?: string;
    sortOptions?: (options: this['options']) => this['options'];
    multi?: boolean;
    simpleValue?: boolean;
}

type Props = InputProps;

const RecipientSelect: React.FC<React.PropsWithChildren<Props>> = props => {
    const {options, sortOptions = defaultSortOptions, multi = true, simpleValue = true} = props;

    const sortedOptions = React.useMemo(() => {
        return sortOptions(options);
    }, [sortOptions, options]);

    return (
        <Select
            {...props}
            simpleValue={simpleValue}
            options={sortedOptions}
            multi={multi}
            tabSelectsValue={false}
            extendedOptionRender={true}
            ignorePlatform={true}
            optimized={true}
        />
    );
};

export default RecipientSelect;
