import {type QueryHookOptions} from '@apollo/client';

import {FetchLeaveRequestPermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-leave-request-permissions.query';
import {
    type FetchLeaveRequestPermissionsQuery,
    type FetchLeaveRequestPermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-leave-request-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchLeaveRequestPermissions(
    options: QueryHookOptions<FetchLeaveRequestPermissionsQuery, FetchLeaveRequestPermissionsQueryVariables>,
): ImmerWrappedQueryResult<FetchLeaveRequestPermissionsQuery, FetchLeaveRequestPermissionsQueryVariables> {
    return useStatQuery<FetchLeaveRequestPermissionsQuery, FetchLeaveRequestPermissionsQueryVariables>(
        FetchLeaveRequestPermissions,
        options,
    );
}
