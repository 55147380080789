import {createStore, applyMiddleware, compose} from 'redux';
import {createEpicMiddleware} from 'redux-observable';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import {List} from 'immutable';

import {getLoginData} from 'signin-app/api/helpers/local-storage';
import {type Action} from 'web-app/util/redux/action-types';
import {InstitutionOverviewState} from 'signin-app/groups/reducer';
import * as LocalStorage from 'signin-app/api/helpers/local-storage';

import {LoginState} from '../login/reducer';
import createRootReducer, {type RootState} from './main-reducer';
import rootEpic from '../root-epic';

// create the middleware
const epicMiddleware = createEpicMiddleware<Action<any>, Action<any>, RootState>();

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: (...args: any[]) => any;
        __cypressToastDuration?: number;
    }
}

const composeEnhancers =
    (process.env.NODE_ENV !== 'production' && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const loginData = getLoginData();

const getHiddenGroupIds = (): List<string> => {
    const hiddenGroupIds = LocalStorage.getHiddenGroupIds();
    return List(hiddenGroupIds);
};

/**
 * The redux types of createStore now seem to requires a complete RootState to be passed down.
 * This is inconsistent with redux itself which accepts a partial RootState as preloadState
 */
const preloadedState = {
    login: new LoginState({
        accessToken: loginData.accessToken,
        pinSigninAvailable: loginData.pinSigninAvailable,
        qrSigninAvailable: loginData.qrSigninAvailable,
        siteId: loginData.siteId,
    }),
    institutionOverview: new InstitutionOverviewState({
        hiddenGroupIds: getHiddenGroupIds(),
    }),
} as RootState;

export const history = createBrowserHistory({
    // basename: '/v2/',
});

const store = createStore<RootState, Action<any>, {}, {}>(
    createRootReducer(history),
    preloadedState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), epicMiddleware)),
);

epicMiddleware.run(rootEpic);

export const getStore = () => store;

export default store;
