import React from 'react';
import {ViewportList} from 'react-viewport-list';

import {MobileList} from './mobile-list';

export type MobileVirtualListProps<ItemType> = {
    data: ItemType[];
    renderRow: (item: ItemType, index: number) => React.ReactNode;
    scrollContainerRef?: React.RefObject<HTMLElement>;
};

export function MobileVirtualList<ItemType>({data, renderRow, scrollContainerRef}: MobileVirtualListProps<ItemType>) {
    return (
        <MobileList>
            <ViewportList viewportRef={scrollContainerRef} items={data}>
                {renderRow}
            </ViewportList>
        </MobileList>
    );
}
