import {combineEpics} from 'web-app/util/redux-observable';
import {type RootState} from 'signin-app/redux/main-reducer';
import {type Action} from 'web-app/util/redux/action-types';
import {updateOnActionsEpic} from 'web-app/react/components/higher-order/update-on-actions';
import {entityEpics} from 'signin-app/setup-entities';
import prefetch from 'signin-app/components/private-route/epics';

import login from './login/epics';
import child from './child/epics';
import employee from './employee/epics';
import pinLogin from './pin/epics';
import goHomeWith from './go-home-with/epics';

const rootEpic = combineEpics<Action<any>, Action<any>, RootState>(
    login,
    child,
    employee,
    prefetch,
    updateOnActionsEpic,
    pinLogin,
    goHomeWith,
    ...entityEpics,
);

export default rootEpic;
