import moment, {type Moment} from 'moment-timezone';
import numeral from 'numeral';
import i18next from 'i18next';

import {hasValue} from 'web-app/util/typescript';

export const MINUTES_IN_DAY = 24 * 60 - 1;

export interface Time {
    hours: Hours;
    minutes: Minutes;
}

export type Hours = number;
export type Minutes = number;

/**
 * Formats a time in the user's locale
 *
 * @param time
 */
export const format = (time: Time, locale: string): string => {
    return moment().locale(locale).hour(time.hours).minute(time.minutes).format('LT');
};

/**
 * Returns true if `candidateTime` is before `testTime`
 *
 * @param testTime
 * @param candidateTime
 */
export const isBefore = (testTime: Time, candidateTime: Time) => {
    if (candidateTime.hours > testTime.hours) {
        return false;
    }

    if (candidateTime.hours < testTime.hours) {
        return true;
    }

    if (candidateTime.hours === testTime.hours && candidateTime.minutes < testTime.minutes) {
        return true;
    }

    return false;
};

/**
 * Returns true if `candidateTime` is after `testTime`
 *
 * @param testTime
 * @param candidateTime
 */
export const isAfter = (testTime: Time, candidateTime: Time) => {
    if (candidateTime.hours < testTime.hours) {
        return false;
    }

    if (candidateTime.hours > testTime.hours) {
        return true;
    }

    if (candidateTime.hours === testTime.hours && candidateTime.minutes > testTime.minutes) {
        return true;
    }

    return false;
};

/**
 * Creates a Time instance from the given minutes.
 *
 * @example
 * // returns {hours: 10, minutes: 37}
 * Time.fromMinutes(637)
 *
 * @param _minutes
 */
export const fromMinutes = (minutes: Minutes): Time => {
    const hours = Math.floor(minutes / 60);
    const _minutes = minutes % 60;

    return {hours, minutes: _minutes};
};

/**
 * Creates a `Time` instance from the given string. Uses the given locale and timeFormat
 * to figure out if the given string is valid. Returns `null` if the time could not be parsed.
 *
 * @param userInput The input string to parse
 * @param locale The user's locale
 * @param timeFormat The `LT` time format of the user's locale
 */
const parse = (userInput: string, locale: string, timeFormat: string): Time | null => {
    // We need to do some US specific handling, as there can be ambiguity when
    // trying to parse times starting with either 1 or 2.
    const isUS = locale === 'en_US';
    const isThreeDigits = /^[1|2][0-9][0-9]$/g.test(userInput);
    const isThreeDigitsAndANonDigit = /^[1|2][0-9][0-9][^0-9]/g.test(userInput);

    if (isUS && (isThreeDigits || isThreeDigitsAndANonDigit)) {
        return fromMoment(moment(`0${userInput}`, timeFormat, locale));
    }

    const parsedTime = fromMoment(moment(userInput, timeFormat, locale));

    if (hasValue(parsedTime)) {
        return parsedTime;
    }

    // If the moment is not valid, try adding a leading zero and parse again
    return fromMoment(moment(`0${userInput}`, timeFormat, locale));
};

/**
 * Creates a `parse` function with the given locale and timeFormat already
 * in scope. This makes the `parse` function easier to test, and also avoids
 * looking up the users locale information up every time.
 *
 * @param locale The user's locale
 * @param timeFormat The `LT` time format of the user's locale
 */
export const makeParse = (locale: string, timeFormat: string): ((userInput: string) => Time | null) => {
    return (userInput: string) => {
        return parse(userInput, locale, timeFormat);
    };
};

/**
 * Converts a `Time` instance to a number of minutes
 *
 * @example
 * // returns 637
 * Time.toMinutes({hours: 10, minutes: 37});
 *
 * @param time
 */
export const toMinutes = (time: Time): Minutes => {
    return time.hours * 60 + time.minutes;
};

/**
 * Formats a `Time` instance in HH:mm format
 *
 * This is necessary for HTML5's <input type="time" /> field
 *
 * @param time
 */
export const formatForNativeInputField = (time: Time): string => {
    const hours = time.hours < 10 ? `0${time.hours}` : `${time.hours}`;
    const minutes = time.minutes < 10 ? `0${time.minutes}` : `${time.minutes}`;

    return `${hours}:${minutes}`;
};

/**
 * Formats a `Time` instance according to the ISO 8601-1 standard: HH:mm:ss
 *
 * @param time
 */
export const formatForIso8601 = (time: Time): string => {
    const hours = `${time.hours}`.padStart(2, '0');
    const minutes = `${time.minutes}`.padStart(2, '0');

    return `${hours}:${minutes}:00`;
};

/**
 * Create a Time instance from a Moment object. Returns null if the passed
 * Moment object is invalid
 *
 * @param momentDate Moment
 */
export const fromMoment = (
    momentDate: Moment,
    {withSeconds}: {withSeconds?: boolean} = {},
): Time | TimeWithSeconds | null => {
    if (!momentDate.isValid()) {
        return null;
    }

    const value = {hours: momentDate.hours(), minutes: momentDate.minutes()};

    if (withSeconds) {
        return {...value, seconds: momentDate.seconds()};
    }

    return value;
};

/**
 * Adds `minutes` to the passed `time`. If the new calculated time exceeds
 * 23:59, 23:59 will be returned.
 *
 * @param minutes
 * @param time
 */
export const addMinutes = (minutes: Minutes, time: Time): Time => {
    const newMinutes = toMinutes(time) + minutes;

    return newMinutes > MINUTES_IN_DAY ? {hours: 23, minutes: 59} : fromMinutes(newMinutes);
};

/**
 * Subtracts `minutes` from the passed `time`. If the new calculated time is before
 * 00:00, 00:00 will be returned.
 *
 * @param minutes
 * @param time
 */
export const subtractMinutes = (minutes: Minutes, time: Time): Time => {
    const newMinutes = toMinutes(time) - minutes;

    return newMinutes < 0 ? {hours: 0, minutes: 0} : fromMinutes(newMinutes);
};

/**
 * Formats a number of minutes nicely.
 *
 * @example
 * Time.formatMinutes(30) // output: 30m
 * Time.formatMinutes(185) // output: 3h 5m
 *
 * @param minutes
 */
export const formatMinutes = (minutes: Minutes): string => {
    const time = fromMinutes(minutes);

    if (time.hours === 0) {
        return `${time.minutes}${i18next.t('time.minutesAbbr')}`;
    } else {
        return `${time.hours}${i18next.t('time.hoursAbbr')} ${time.minutes}${i18next.t('time.minutesAbbr')}`;
    }
};

/**
 * Use this as an alternative to Moment's `fromNow`.
 * This will never round to shorthands like "an hour ago",
 * but will always write out exact hours and minutes.
 */
export type TimeWithSeconds = Time & {seconds?: number};
export const getFromNowString = (time: TimeWithSeconds) => {
    const deltaTime = getTimeFromNow(time);

    const deltaTimeString = buildTimeString({
        hours: Math.abs(deltaTime.hours),
        minutes: Math.abs(deltaTime.minutes),
        seconds: hasValue(deltaTime.seconds) ? Math.abs(deltaTime.seconds) : undefined,
    });

    if (deltaTime.minutes < 0 || deltaTime.hours < 0) {
        return i18next.t('timeUntilTemplate', {delta: deltaTimeString});
    }
    return i18next.t('timeAgoTemplate', {delta: deltaTimeString});
};
const getTimeFromNow = (time: TimeWithSeconds) => {
    const now = moment();
    return getTimeBetween(
        {
            hours: now.hours(),
            minutes: now.minutes(),
            seconds: now.seconds(),
        },
        time,
    );
};
export const getTimeBetween = (a: TimeWithSeconds, b: TimeWithSeconds) => {
    const duration = moment.duration(moment(a).diff(b));
    return {
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: hasValue(a.seconds) && hasValue(b.seconds) ? duration.seconds() : undefined,
    };
};
export const buildTimeString = (time: TimeWithSeconds) => {
    return `${getHoursString(time.hours)}${getMinutesString(time.minutes)}${getSecoundsString(time.seconds)}`.trim();
};

const getHoursString = (amount: number) => {
    if (amount === 0) {
        return '';
    }

    return `${i18next.t('hours', {count: amount, formatted: `${numeral(amount).format('0[.]0')}`})} `;
};

const getMinutesString = (amount: number) => {
    if (amount === 0) {
        return '';
    }

    return `${i18next.t('minutes', {count: amount})} `;
};

const getSecoundsString = (amount?: number) => {
    if (!hasValue(amount) || amount === 0) {
        return '';
    }

    return i18next.t('seconds', {count: amount});
};

export const timeFormatAgnosticSplitTimeString = /:|am|pm/g;

/**
 * Splits the given time string from `HH:mm` into `[HH, mm]` while removing `am|pm` at the end.
 *
 * @see tests/jest/app/react/staff-rotas/time-format-agnostic-split-time-string.test.ts
 * @param timeString the time string to be parsed
 * @returns an array containing [HH, mm] and possibly an empty third string, if am or pm where
 * removed
 */
export function fromTimeString(timeString?: string): Time {
    const [hours, minutes] = (timeString?.split(timeFormatAgnosticSplitTimeString) ?? []).map(Number);
    return {
        hours,
        minutes,
    };
}
