import React from 'react';
import MuiBox, {type BoxProps as MuiBoxProps} from '@mui/material/Box';

import {extractSystemStyles, type WithSystemProps} from 'modern-famly/system';
import {type DataProps, useDataProps} from 'modern-famly/components/util';

import {type ComponentProps} from '../component-prop';

type Props = {
    children?: MuiBoxProps['children'];
};

export type BoxProps<T extends keyof JSX.IntrinsicElements = 'div'> = WithSystemProps<Props & DataProps> &
    ComponentProps<T>;

export const Box = React.forwardRef(
    <T extends keyof JSX.IntrinsicElements = 'div'>(props: BoxProps<T>, ref: React.Ref<React.ElementRef<T>>) => {
        const {component, children, ...rest} = props;

        const dataProps = useDataProps(rest);
        const {sx, nonSystemProps} = extractSystemStyles(rest);

        const _props = {...dataProps, sx, component, ...nonSystemProps};

        return (
            <MuiBox {...(_props as any)} ref={ref}>
                {children}
            </MuiBox>
        );
    },
);

export const createBox = <T extends keyof JSX.IntrinsicElements = 'div'>(outerProps: BoxProps<T>) => {
    return React.forwardRef((innerProps: BoxProps<T>, ref: React.Ref<React.ElementRef<T>>) => {
        const props = {...outerProps, ...innerProps};

        return <Box {...(props as any)} ref={ref} />;
    });
};
