import {gql} from '@apollo/client';

import {StatPermissionWorkTag} from '../fragment/stat-permission-work-tags.fragment';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchWorkTagsPermissions = gql`
    query FetchWorkTagsPermissions($siteSetId: SiteSetId!) {
        staffhours {
            management {
                workTags(siteSetId: $siteSetId) {
                    ...StatPermissionWorkTag
                }
            }
        }
    }
    ${StatPermissionWorkTag}
`;
