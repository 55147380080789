import React from 'react';

import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useFetchInquiriesPermissions} from './queries/use-fetch-inquiries-permissions';

/**
 * Do not use this query directly!
 */
export function useNewInquiriesPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['inquiries']['header']['newInquiry'] {
    const {data: permissionData} = useFetchInquiriesPermissions({
        variables: {siteSetId: payload.institutionSetId},
        skip: !payload.shouldQuery,
    });
    return React.useMemo(
        () => ({
            canCreate: permissionData?.attendance.management.orgInquiries[0].canCreate ?? false,
            canRead: permissionData?.attendance.management.orgInquiries[0].canCreate ?? false,
            canUpdate: false,
            canDelete: false,
        }),
        [permissionData?.attendance.management.orgInquiries],
    );
}
