import React, {useState, useEffect, useRef} from 'react';

interface InputProps {
    debounced?: boolean;
    inputRef?: any;
}
type Props = InputProps & React.InputHTMLAttributes<HTMLInputElement>;

const DebouncableInput: React.FC<React.PropsWithChildren<Props>> = ({debounced, inputRef, capture, ...rest}) => {
    const [value, setValue] = useState(rest.value);
    const [timeout, setTimeoutState] = useState<ReturnType<typeof setTimeout> | null>(null);
    const prevRestValue = useRef(rest.value);

    const debouncedOnChange = (e: React.ChangeEvent<HTMLInputElement>, ...args: any) => {
        e.persist();
        setValue(e.target.value);

        const later = () => {
            setTimeoutState(null);
            if (rest.onChange) {
                // Could not figure out how make TS happy with the args parameter
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                rest.onChange(e, ...args);
            }
        };
        if (timeout) {
            clearTimeout(timeout);
        }
        setTimeoutState(setTimeout(later, 200));
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>, ...args: any) => {
        if (timeout) {
            clearTimeout(timeout);
            setTimeoutState(null);
        }
        if (rest.onChange) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            rest.onChange(e, ...args);
        }
        if (rest.onBlur) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            rest.onBlur(e, ...args);
        }
    };

    useEffect(() => {
        if (debounced && prevRestValue.current !== rest.value) {
            if (timeout) {
                clearTimeout(timeout);
            }
            setValue(rest.value);
            setTimeoutState(null);
        }
        prevRestValue.current = rest.value;

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [rest.value, debounced, timeout, setValue, setTimeoutState]);

    if (debounced) {
        return (
            <input
                {...rest}
                onBlur={handleBlur}
                onChange={debouncedOnChange}
                value={value}
                ref={inputRef}
                capture={Boolean(capture)}
            />
        );
    }
    return <input {...rest} ref={inputRef} capture={Boolean(capture)} />;
};

export default DebouncableInput;
