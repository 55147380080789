import React from 'react';
import InputAdornment, {type InputAdornmentProps} from '@mui/material/InputAdornment';

import {Icon, type IconName} from 'modern-famly/components/data-display';
import {type ColorKey} from 'modern-famly/theming';

import {type TextFieldProps} from './text-field';

export type IconAdornmentProps = {
    /**
     * The position this adornment should appear relative to the `Input`.
     */
    position: InputAdornmentProps['position'];

    /**
     * The icon to display
     */
    icon: IconName;

    /**
     * The size of the text field that the adornment sits in
     */
    size: TextFieldProps['size'];

    /**
     * The color of the icon
     */
    color?: ColorKey;

    /**
     * Whether the icon should be filled or not
     */
    filled?: boolean;
};

export const IconAdornment = ({position, icon, size, color, filled}: IconAdornmentProps) => {
    return (
        <InputAdornment position={position}>
            <Icon size={size === 'regular' ? 24 : 20} name={icon} color={color} filled={filled} />
        </InputAdornment>
    );
};
