import React from 'react';
import i18next from 'i18next';
import styled from 'styled-components';

import {Flex} from 'web-app/react/components/layout/layout';
import TextField from 'web-app/react/components/form-elements/text-field/text-field';
import {s2} from 'web-app/styleguide/spacing';
import {Body} from 'web-app/react/components/text/text';

export interface RangeValue {
    lower: string;
    upper: string;
}

export interface InputProps {
    value: RangeValue;
    onChange: (value?: RangeValue) => void;
}

const StyledTextField = styled(TextField)`
    min-width: 0;
`;

class RangePicker extends React.Component<InputProps> {
    constructor(props) {
        super(props);

        this.handleLowerChange = this.handleLowerChange.bind(this);
        this.handleUpperChange = this.handleUpperChange.bind(this);
    }

    public render() {
        return (
            <Flex childSpacing={s2} align="baseline">
                <StyledTextField type="number" onChange={this.handleLowerChange} placeholder={i18next.t('from')} />
                <Body secondary>-</Body>
                <StyledTextField type="number" onChange={this.handleUpperChange} placeholder={i18next.t('to')} />
            </Flex>
        );
    }

    private handleLowerChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.onChange({
            lower: e.target.value,
            upper: this.props.value.upper,
        });
    }

    private handleUpperChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.onChange({
            lower: this.props.value.lower,
            upper: e.target.value,
        });
    }
}

export default RangePicker;
