import React from 'react';

import {hasValue} from 'web-app/util/typescript';

import {type StatCrudPermission} from './stat-crud-permission';

/**
 * Adapts the given backend crud structure to the correct frontend crud structure.
 *
 * Any property not explicitely set to 'true' on the backend side, will be treated as 'false' on the frontend side.
 *
 * @example
 * ```
 * const institutionId = 'some-id';
 * const backendCrud = useFetchSubtypesPermissions({institutionId});
 * const permissions = useCrudPermissionAdapter(backendCrud);
 *
 * // backendCrud = {canCreate: true, canView: true};
 * // permissions = {canCreate: true, canRead: true, canUpdate: false, canDelete:false}
 * ```
 * @param backendCrud the permissions coming from the backend
 * @returns the adapted backend crud as the frontend Crud structure
 */
export function useCrudPermissionAdapter(
    backendCrud?: Partial<{
        canCreate: boolean;
        canView: boolean;
        canDelete: boolean;
        canEdit: boolean;
    }>,
): StatCrudPermission {
    return React.useMemo(() => {
        if (hasValue(backendCrud)) {
            return {
                canCreate: backendCrud.canCreate ?? false,
                canRead: backendCrud.canView ?? false,
                canDelete: backendCrud.canDelete ?? false,
                canUpdate: backendCrud.canEdit ?? false,
            };
        }
        return {
            canCreate: false,
            canRead: false,
            canUpdate: false,
            canDelete: false,
        };
    }, [backendCrud]);
}
