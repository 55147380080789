import moment from 'moment-timezone';

import {
    QR_SIGNIN_MODAL_QUERY_PARAM_HMAC,
    QR_SIGNIN_MODAL_QUERY_PARAM_SITEID,
    QR_SIGNIN_MODAL_QUERY_PARAM_KEY,
    QR_SIGNIN_MODAL_QUERY_PARAM_NAME,
} from './constants';

const generateHmac = async (secret: string, body: string) => {
    const enc = new TextEncoder();
    const algorithm = {name: 'HMAC', hash: 'SHA-256'};
    const key = await crypto.subtle.importKey('raw', enc.encode(secret), algorithm, false, ['sign', 'verify']);
    const signature = await crypto.subtle.sign(algorithm.name, key, enc.encode(body));
    const hashArray = Array.from(new Uint8Array(signature));
    const digest = hashArray
        .map(b => b.toString(16).padStart(2, '0'))
        .join('')
        .toString();

    return digest;
};

export const createQRCodeLink = async (
    institutionId: string,
    secret: string,
    mobileAppUrlScheme: string,
    validFrom?: string,
) => {
    const secondsSinceEpoch = moment().utc().unix();
    /** If validFrom is present, then it should be used to generate a "static" key, which will be always the same for a given secret */
    const key = `${institutionId}${validFrom ? validFrom : secondsSinceEpoch}`;
    const hmac = await generateHmac(secret, key);
    const deepLink = `${mobileAppUrlScheme}://account/feed/me?${QR_SIGNIN_MODAL_QUERY_PARAM_NAME}&${QR_SIGNIN_MODAL_QUERY_PARAM_HMAC}=${hmac}&${QR_SIGNIN_MODAL_QUERY_PARAM_SITEID}=${institutionId}&${QR_SIGNIN_MODAL_QUERY_PARAM_KEY}=${key}`;

    return deepLink;
};
