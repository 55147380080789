import {Record} from 'immutable';

import BehaviorsCollection from 'web-app/behaviors/behaviors-collection';

import Image, {type Image as ImageRecord} from '../common/image';

class InstitutionSetting extends Record<{
    setting: string;
    value: boolean;
}>({
    setting: '',
    value: false,
}) {}

export class StatusStats extends Record<{
    SICK: number;
    VACATION: number;
    ABSENT: number;
    SLEEPING: number;
    ON_BUS: number;
    ON_TRIP: number;
    CHILD_SICK: number;
}>({
    SICK: 0,
    VACATION: 0,
    ABSENT: 0,
    SLEEPING: 0,
    ON_BUS: 0,
    ON_TRIP: 0,
    CHILD_SICK: 0,
}) {}

export class PeopleStats extends Record<{
    checkedIn: number;
    total: number;
    expected: number;
    status: StatusStats;
}>({
    checkedIn: 0,
    total: 0,
    expected: 0,
    status: new StatusStats(),
}) {}
export const peopleStatsFromAPI = stats =>
    new PeopleStats({
        ...stats,
        status: new StatusStats(stats.status),
    });

class CustomStatusConfig extends Record<{
    statusConfigId: string;
    color: string;
    createdAt: string;
    forChildren: boolean;
    forEmployees: boolean;
    icon: string;
    institutionId: string;
    title: string;
}>({
    statusConfigId: '',
    color: '',
    createdAt: '',
    forChildren: false,
    forEmployees: false,
    icon: '',
    institutionId: '',
    title: '',
}) {}
export class CustomStatusStats extends Record<{
    count: number;
    statusConfig: CustomStatusConfig;
}>({
    count: 0,
    statusConfig: new CustomStatusConfig(),
}) {}
export const customStatusStatsFromAPI = stats =>
    new CustomStatusStats({
        count: stats.count,
        statusConfig: new CustomStatusConfig(stats.statusConfig),
    });

export interface GroupStats {
    children?: PeopleStats;
    employees?: PeopleStats;
}

export class Group extends Record<{
    departmentId: string;
    groupId: string;
    title: string;
    image: ImageRecord;
    behaviors: BehaviorsCollection;
    capacity?: number;
    color?: string;
    deletedAt?: string;
    famlyId: string;
    info: string;
    institutionId: string;
    phone?: string;
    requirePickupInfo: boolean;
    starred: boolean;
    starring: boolean;
    staffRatio?: number;
    settings?: InstitutionSetting[];
    childCount?: number;
    staffCount?: number;
    stats?: GroupStats;
    statusRegistrationStats?: {
        children?: CustomStatusStats[];
        employees?: CustomStatusStats[];
    };
}>({
    departmentId: '',
    groupId: '',
    title: '',
    image: Image.default,
    behaviors: new BehaviorsCollection([]),
    childCount: undefined,
    staffCount: undefined,
    capacity: undefined,
    color: undefined,
    deletedAt: undefined,
    famlyId: '',
    info: '',
    institutionId: '',
    phone: '',
    requirePickupInfo: false,
    staffRatio: undefined,
    starred: false,
    starring: false,
    settings: undefined,
    stats: undefined,
    statusRegistrationStats: undefined,
}) {}

export function fromAPI(data) {
    return new Group({
        ...data,
        behaviors: new BehaviorsCollection(data.behaviors),
        childCount: data.stats && data.stats.children ? data.stats.children.total : undefined,
        staffCount: data.stats && data.stats.employees ? data.stats.employees.total : undefined,
        stats: data.stats
            ? {
                  children: data.stats.children ? peopleStatsFromAPI(data.stats.children) : undefined,
                  employees: data.stats.employees ? peopleStatsFromAPI(data.stats.employees) : undefined,
              }
            : undefined,
        statusRegistrationStats: data.statusRegistrationStats
            ? {
                  children: data.statusRegistrationStats.children
                      ? data.statusRegistrationStats.children.map(customStatusStatsFromAPI)
                      : undefined,
                  employees: data.statusRegistrationStats.employees
                      ? data.statusRegistrationStats.employees.map(customStatusStatsFromAPI)
                      : undefined,
              }
            : undefined,
        image: Image.fromAPI(data.image),
        settings: data.settings ? data.settings.map(setting => new InstitutionSetting(setting)) : undefined,
    });
}

export default {
    fromAPI,
    Group,
};
