import gql from 'graphql-tag';

import {InstitutionWithTimezone} from '../fragment/institution-timezone.fragment';

export const InstitutionsTimezone = gql`
    query InstitutionsTimezone($institutionId: InstitutionId!) {
        institutions(institutionIds: [$institutionId]) {
            ...InstitutionWithTimezone
        }
    }
    ${InstitutionWithTimezone}
`;
