import {css} from 'styled-components';

import {hasValue, getProperty} from 'web-app/util/typescript';

export type ElevationConfiguration = {
    one: string;
    two: string;
    three: string;
    four: string;
    five: string;
    six: string;
};

export const defaultElevationConfiguration: ElevationConfiguration = {
    one: 'none',
    two: 'none',
    three: 'none',
    four: 'none',
    five: 'none',
    six: 'none',
};

export interface ElevationProps {
    elevation?: keyof ElevationConfiguration;
}

export const elevationCSS = css<ElevationProps>`
    ${props =>
        hasValue(props.elevation) && hasValue(getProperty(props.theme.elevation, props.elevation))
            ? css`
                  box-shadow: ${props.theme.elevation[props.elevation]};
              `
            : ''}
`;
