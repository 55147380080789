import React from 'react';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {type StatPermissions} from './stat-permissions';
import {useStaffHoursHistoricalDownloadPermissions} from './use-staff-hours-historical-download-permissions';
import {useStaffHoursExactAttendancePermission} from './use-staff-hours-exact-attendance-permission';

export function useStaffHoursSettingsPagePermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['staffHoursSettingsPage'] & {loading: boolean} {
    const {loading: loadingHistoricalDownloadPermissions, ...historicalDownload} =
        useStaffHoursHistoricalDownloadPermissions(payload);

    const {loading: loadingStaffHoursExactAttendancePermissions, ...exactAttendance} =
        useStaffHoursExactAttendancePermission(payload);

    return React.useMemo(
        () => ({
            loading: loadingHistoricalDownloadPermissions || loadingStaffHoursExactAttendancePermissions,
            header: {
                exportHistoricalData: historicalDownload,
                exactAttendanceOptIn: exactAttendance,
            },
            content: {},
        }),
        [
            exactAttendance,
            historicalDownload,
            loadingHistoricalDownloadPermissions,
            loadingStaffHoursExactAttendancePermissions,
        ],
    );
}
