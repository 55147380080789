import React, {useCallback, useMemo} from 'react';
import {type ValueType, type OptionsType} from 'react-select';
import {type WrappedFieldProps} from 'redux-form';

import SelectV2, {
    type BaseOptionType,
    type BaseProps,
    type SingleProps,
} from 'web-app/react/components/form-elements/new-select/select';
import ReactOnMeta from 'web-app/react/components/form-elements/containers/react-on-meta';
import {type DistributiveOmit} from 'web-app/util/typescript';

// extended the input props to have 3 extra fields based on the original props of the select component
// distributive does not break Unions while Omitting things from props

// Extended the input props of our Select component with the extra props provided by ReduxForm
// Using DistributiveOmit to persist union types while omitting unsupported functionality from props.

type InputProps<T extends BaseOptionType> = DistributiveOmit<BaseProps<T> & SingleProps<T>, 'options'> & {
    input: WrappedFieldProps['input'];
    meta: WrappedFieldProps['meta'];
    options: OptionsType<T>;
    validateIgnoreTouch?: boolean;
    useOptionAsValue?: boolean;
};

const emptyBlur = () => ({});

function FormSelectV2<T extends BaseOptionType>({
    input,
    meta,
    validateIgnoreTouch,
    useOptionAsValue,
    ...otherProps
}: InputProps<T>) {
    // this will probably be needed in order to detect errors and do something about it
    // const showErrors = validateIgnoreTouch ? Boolean(meta.error) : meta.touched && Boolean(meta.error);

    const {onChange: otherPropsOnChange} = otherProps;
    const {onChange: inputOnChange} = input;

    const handleChange = useCallback(
        (newValue: ValueType<any>) => {
            if (otherPropsOnChange) {
                otherPropsOnChange(newValue);
            }

            if (useOptionAsValue) {
                inputOnChange(newValue);
            } else {
                inputOnChange(newValue ? newValue.value : null);
            }
        },
        [otherPropsOnChange, useOptionAsValue, inputOnChange],
    );

    // when redux global state is changed, this will be triggered
    const currentSelection = useMemo(() => {
        if (useOptionAsValue) {
            return otherProps.options?.find(option => option.value === input.value.value);
        }
        return otherProps.options?.find(option => option.value === input.value);
    }, [useOptionAsValue, otherProps.options, input.value]);

    return (
        <ReactOnMeta input={input} meta={meta} validateIgnoreTouch={validateIgnoreTouch} {...otherProps}>
            <SelectV2
                {...input}
                {...otherProps}
                onBlur={emptyBlur}
                onChange={handleChange}
                value={currentSelection ?? null}
            />
        </ReactOnMeta>
    );
}

export default FormSelectV2;
