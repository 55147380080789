import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useSubtypePermissions} from './use-subtype-permissions';

/**
 * Do not use this query directly!
 */
export function useLeaveSettingsSubtypePaidPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['staffLeaveSettings']['leave']['subtypes']['paid'] & {loading: boolean} {
    return useSubtypePermissions(payload);
}
