/* eslint-disable consistent-return */

import {type MealTypeFragment} from 'web-app/react/meals/meal-registration/__generated__/queries.api-types';

import {MealTypeColorMap} from './constants';

export const mealTypeColorToColorKey = (color: number) => MealTypeColorMap[color];

export const mealTypeColorFromId = (mealTypes: MealTypeFragment[], mealTypeId: string) => {
    const mealType = mealTypes.find(mealType => mealType.id === mealTypeId);
    if (mealType) {
        return mealType.color;
    }
    return 0;
};
