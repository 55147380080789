import gql from 'graphql-tag';

import {StatPermissionSchedule} from '../fragment/stat-permission-schedules.fragment';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchSchedulePermissions = gql`
    query FetchSchedulePermissions($siteSetId: SiteSetId!, $employeeId: EmployeeId!) {
        staffhours {
            management {
                schedules(siteSetId: $siteSetId, employeeId: $employeeId) {
                    ...StatPermissionSchedule
                }
            }
        }
    }
    ${StatPermissionSchedule}
`;
