import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'web-app/react/components/form-elements/text-field/text-field';
import DecoratedInput from 'web-app/react/components/form-elements/containers/decorated-input/decorated-input';
import ReactOnMeta from 'web-app/react/components/form-elements/containers/react-on-meta';

class FormInput extends React.PureComponent {
    static propTypes = {
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        input: PropTypes.object,
        meta: PropTypes.any,
        placeholder: PropTypes.string,
        decoration: PropTypes.node,
    };
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }
    render() {
        const {
            meta, // eslint-disable-line no-unused-vars
            input,
            decoration,
            ...rest
        } = this.props;

        return (
            <ReactOnMeta {...this.props}>
                {decoration ? (
                    <DecoratedInput
                        decoration={decoration}
                        {...rest}
                        {...input}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                    />
                ) : (
                    <TextField {...rest} {...input} onChange={this.handleChange} onBlur={this.handleBlur} />
                )}
            </ReactOnMeta>
        );
    }
    handleChange(e, ...args) {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
        this.props.input.onChange(e, ...args);
    }
    handleBlur(e) {
        if (this.props.onBlur) {
            this.props.onBlur(e);
        }

        if (this.props.input?.onBlur) {
            this.props.input.onBlur(e);
        }
    }
}

export default FormInput;
