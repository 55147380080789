import {gql} from '@apollo/client';

export const FetchAttendanceDetailsPermissions = gql`
    query FetchAttendanceDetailsPermissions($siteSetId: SiteSetId!, $employeeId: EmployeeId) {
        staffhours {
            management {
                shiftplanner(siteSetId: $siteSetId) {
                    shift {
                        published: publishedV2(siteSetId: $siteSetId, employeeId: $employeeId) {
                            canView
                        }
                    }
                    canView
                    canEdit
                }
                attendance: attendanceV2(siteSetId: $siteSetId, employeeId: $employeeId) {
                    canView
                    canEdit
                    siteSet {
                        canView
                        canEdit
                    }
                }
                leaves {
                    basic(siteSetId: $siteSetId, employeeId: $employeeId) {
                        canView
                        canEdit
                    }
                }
                contractedHours: contractedHoursV2(siteSetId: $siteSetId, employeeId: $employeeId) {
                    bankHours {
                        canView
                    }
                    contractedHours {
                        canView
                    }
                }
            }
        }
    }
`;
