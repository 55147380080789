import moment from 'moment-timezone';
import {createSelectorCreator, defaultMemoize, createSelector} from 'reselect';
import {Range} from 'immutable';
import {type Modifiers} from 'react-day-picker';

import {shallowCompare} from 'web-app/util/shallow-diff';
import {type IDayRendererInputProps} from 'web-app/react/components/form-elements/daypicker/daypicker';
import {
    type DayPickerInputProps,
    type InnerProps,
} from 'web-app/react/components/form-elements/daypicker/day-picker-input';
import {formatDateToISO} from 'web-app/util/date';

const createDaySelector = createSelectorCreator(defaultMemoize, (d1: any, d2: any) => d1.getTime() === d2.getTime());

export const makeMemoizedDaySelector = () =>
    createDaySelector(
        (props: Pick<IDayRendererInputProps, 'day'>) => props.day,
        day => day,
    );

const createModifierSelector = createSelectorCreator(
    defaultMemoize,
    (modifier1: any, modifier2: any) => !shallowCompare(modifier1, modifier2),
);
export const makeMemoizedModifiersSelector = () =>
    createModifierSelector(
        (props: Pick<IDayRendererInputProps, 'modifiers'>) => props.modifiers,
        modifiers => modifiers,
    );

export const makeMemoizedFormattedDateSelector = () =>
    createSelector(
        (props: Pick<IDayRendererInputProps, 'day'>) => props.day,
        day => formatDateToISO(day),
    );

export const makeMemoizedFormattedValue = () =>
    createSelector(
        (props: DayPickerInputProps & InnerProps) => props.value,
        (props: DayPickerInputProps & InnerProps) => props.valueFormat,
        (value, valueFormat) =>
            value ? moment(value, valueFormat).format(moment.localeData().longDateFormat('L')) : undefined,
    );

const MIN_YEAR = 1900;
const MAX_YEAR = 2050;
const yearOptions = Range(MIN_YEAR, MAX_YEAR + 1)
    .map(i => ({
        value: i,
        label: i,
    }))
    .toArray();
export const makeYearPickerOptionsSelector = () =>
    createSelector(
        // todo remove any with DayPicker/CaptionElement (which uses this selector) is converted to TS
        (props: any) => props.withYearPicker,
        (props: any) => props.date,
        (withYearPicker, date) => {
            if (!withYearPicker) {
                return undefined;
            } else if (!date) {
                return yearOptions;
            }
            const yearOfDate = Number(moment(date).format('YYYY'));
            if (yearOfDate < MIN_YEAR) {
                return [
                    {
                        value: yearOfDate,
                        label: yearOfDate,
                    },
                    ...yearOptions,
                ];
            } else if (yearOfDate > MAX_YEAR) {
                return [
                    ...yearOptions,
                    {
                        value: yearOfDate,
                        label: yearOfDate,
                    },
                ];
            } else {
                return yearOptions;
            }
        },
    );

export const makeModifiersSelector = () =>
    createSelector(
        (props: DayPickerInputProps & InnerProps) => props.modifiers,
        (modifiers): Partial<Modifiers> => {
            const standardModifiers = {
                weekend: {
                    daysOfWeek: [0, 6],
                },
            };
            if (modifiers) {
                return {
                    ...standardModifiers,
                    ...modifiers,
                };
            }
            return standardModifiers;
        },
    );
