import {type DocumentNode} from 'graphql';
import {type OperationVariables, type QueryHookOptions} from '@apollo/client';

import {type StatPermissionsPath} from '../adaptation/permission/stat-permissions-path';
import {type ImmerWrappedQueryResult} from '../use-immer-query-wrapper';
import {useStatQuery} from './use-stat-query';
import {resolvePermissionPath} from '../adaptation/permission/resolve-stat-permission-path';
import {useStatContext} from '../adaptation/context/stat-context';

type GuardedQueryOperationVariables = OperationVariables & {
    institutionId?: string;
    siteId?: string;
    siteSetId?: string;
    institutionSetId?: string;
    formId?: string;
    siteIds?: string[];
};

/**
 * Combines the stat query with the stat permissions
 *
 * @see useStatQuery
 */
export function useStatGuardedQuery<
    TData = any,
    TVariables extends GuardedQueryOperationVariables = GuardedQueryOperationVariables,
>(
    permissionPath: StatPermissionsPath,
    query: DocumentNode,
    options?: QueryHookOptions<TData, TVariables>,
): ImmerWrappedQueryResult<TData, TVariables> {
    const {permissions} = useStatContext();

    return useStatQuery(query, {
        ...options,
        skip: options?.skip || !resolvePermissionPath(permissions, permissionPath).canRead,
    });
}
