// Originator types
export const ORIGINATOR_TYPES = {
    MEAL: 'Famly.Daycare:Meal' as const,
    MEAL_REGISTRATION: 'Famly.Daycare:MealRegistration' as const,
    ACTION: 'Famly.Daycare:Action' as const,
    LEAVE: 'Famly.Daycare:ChildLeave' as const,
    EMPLOYEE_LEAVE: 'Famly.Daycare:EmployeeLeave' as const,
    NAP: 'Famly.Daycare:Nap' as const,
    CHECK_IN: 'Famly.Daycare:ChildCheckin' as const,
    EMPLOYEE_CHECK_IN: 'Famly.Daycare:EmployeeCheckin' as const,
    CLOSING_DAY: 'Famly.Daycare:ClosingDay' as const,
    PLANNED_CHECKOUT: 'Famly.Daycare:PlannedCheckout' as const,
    EVENT: 'Famly.Daycare:Event' as const,
    NOTE: 'Famly.Daycare:ChildNote' as const,
    STATUS_REGISTRATION: 'Famly.Daycare:StatusRegistration' as const,
    ADMINISTERED_MEDICATION: 'Famly.Daycare:AdministeredMedication' as const,
};

// Action types
export const ACTION_TYPES = {
    DIAPER_TOILET_ACCIDENT: 'DIAPER_TOILET_ACCIDENT',
    DIAPERCHANGE: 'DIAPERCHANGE',
    SLEEP_CHECK: 'SLEEP_CHECK',
    TOILETVISIT: 'TOILETVISIT',
    CREAM_APPLICATION: 'CREAM_APPLICATION',
    WASHED_HANDS: 'WASHED_HANDS',
    TEMPERATURE_TAKEN: 'TEMPERATURE_TAKEN',
    MEAL: 'MEAL',
    BIKE: 'BIKE',
    OVERFLOW: 'OVERFLOW',
};

// Leave types
export const LEAVE_TYPES = {
    SICK: 'SICK',
    VACATION: 'VACATION',
    ABSENT: 'ABSENT',
};

export const TRANSITIONS = {
    EVENT_SLIDE: 0.1,
    EVENT_FADE: 0.075,
    EVENT_DELAY: 0.025,
    DAY_FADE: 0.5,
};

export const MAX_STEP = 4;
export const DAYS_TO_CRAWL = 28;

export const TEMPORAL_GROUPS = [
    [ACTION_TYPES.DIAPER_TOILET_ACCIDENT],
    [ACTION_TYPES.DIAPERCHANGE],
    [ACTION_TYPES.TOILETVISIT],
    [ACTION_TYPES.CREAM_APPLICATION],
    [ACTION_TYPES.WASHED_HANDS],
    [ORIGINATOR_TYPES.MEAL_REGISTRATION],
];

export const RANGE_GROUPS = [
    {
        parent: ORIGINATOR_TYPES.NAP,
        members: [ACTION_TYPES.SLEEP_CHECK],
    },
];
