import {createAction} from 'signin-app/redux/helpers';
import {type ChildCheckout, type StatusConfig, type StatusRegistration} from 'signin-app/child/models';

const domain = 'CHILD_CHECK_OUT';

export const {
    action: checkOutChild,
    success: checkOutChildSuccess,
    failed: checkOutChildFailed,
} = createAction(
    `${domain}/CHECKOUT`,
    (childId: string, pin?: string) => ({childId, pin}),
    (response: ChildCheckout) => ({response}),
    (error: Error) => ({error}),
);

export const {
    action: fetchStatusConfigs,
    success: fetchStatusConfigsSuccess,
    failed: fetchStatusConfigsFailed,
} = createAction(
    `${domain}/FETCH_STATUSES`,
    (institutionId: string) => ({institutionId}),
    (response: StatusConfig[]) => ({response}),
    (error: Error) => ({error}),
);

interface ApplyStatusArgs {
    childId: string;
    status: any;
}

export const {
    action: applyChildStatus,
    success: applyChildStatusSuccess,
    failed: applyChildStatusFailed,
} = createAction(
    `${domain}/APPLY_STATUS`,
    (args: ApplyStatusArgs) => args,
    (response: StatusRegistration[], status) => ({response, status}),
    (error: Error) => ({error}),
);

export const {
    action: removeChildStatus,
    success: removeChildStatusSuccess,
    failed: removeChildStatusFailed,
} = createAction(
    `${domain}/REMOVE_STATUS`,
    (args: ApplyStatusArgs) => args,
    (response: StatusRegistration[], status) => ({response, status}),
    (error: Error) => ({error}),
);
