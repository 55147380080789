import styled from 'styled-components';
import {Icon, createBox} from 'modern-famly';
import React from 'react';

import {exhaustiveCheck} from 'web-app/util/typescript';

import {LeaveType} from './leave-type';

export enum LeaveTypeIconSize {
    tiny = 16,
    small = 24,
    medium = 32,
    large = 40,
    huge = 64,
}

interface LeaveTypeIconColorOptions {
    disabled?: boolean;
    isDisplayedOnDarkBackground?: boolean;
}

interface LeaveTypeBackgroundInverted extends LeaveTypeIconColorOptions {
    invertedBackground: true;
    padding: number;
}

interface LeaveTypeBackgroundNormal extends LeaveTypeIconColorOptions {
    invertedBackground: false;
}

type LeaveTypeIconOptions = LeaveTypeBackgroundInverted | LeaveTypeBackgroundNormal;

const IconBackgroundWrapper = styled(createBox({}))`
    border-radius: 4px;

    > svg {
        /* This is needed for the icon to be centered */
        display: block;
    }

    span.material-icons {
        vertical-align: middle;
    }
`;

export function getLeaveTypeIcon(
    leaveType: LeaveType,
    size?: LeaveTypeIconSize,
    options?: LeaveTypeIconOptions,
): JSX.Element | null {
    const iconSize = size ?? defaultLeaveTypeIconSize[leaveType];
    switch (leaveType) {
        case LeaveType.Sick:
            return getSickIcon(iconSize, options);
        case LeaveType.Absent:
            return getAbsentIcon(iconSize, options);
        case LeaveType.Vacation:
            return getVacationIcon(iconSize, options);
        case LeaveType.ChildSick:
            return getChildSickIcon(iconSize, options);
        default:
            exhaustiveCheck(leaveType);
            return null;
    }
}

const defaultLeaveTypeIconSize: {[key in LeaveType]: LeaveTypeIconSize} = {
    [LeaveType.ChildSick]: LeaveTypeIconSize.small,
    [LeaveType.Sick]: LeaveTypeIconSize.small,
    [LeaveType.Vacation]: LeaveTypeIconSize.small,
    [LeaveType.Absent]: LeaveTypeIconSize.small,
};

function getSickIcon(size: LeaveTypeIconSize, options?: LeaveTypeIconOptions): JSX.Element {
    if (options?.invertedBackground) {
        return (
            <IconBackgroundWrapper
                padding={`${options.padding}px`}
                backgroundColor={options?.disabled ? 'r200' : 'r400'}
            >
                <Icon name="device_thermostat" size={size - 2 * options.padding} color="n0" />
            </IconBackgroundWrapper>
        );
    }
    return <Icon name="device_thermostat" size={size} color={options?.disabled ? 'r200' : 'r400'} />;
}

function getChildSickIcon(size: LeaveTypeIconSize, options?: LeaveTypeIconOptions): JSX.Element {
    if (options?.invertedBackground) {
        return (
            <IconBackgroundWrapper
                padding={`${options.padding}px`}
                backgroundColor={options?.disabled ? 'r200' : 'r400'}
            >
                <Icon name="stethoscope" size={size - 2 * options.padding} color={'n0'} />
            </IconBackgroundWrapper>
        );
    }
    return <Icon name="stethoscope" size={size} color={options?.disabled ? 'r100' : 'r300'} />;
}

function getVacationIcon(size: LeaveTypeIconSize, options?: LeaveTypeIconOptions): JSX.Element {
    if (options?.invertedBackground) {
        return (
            <IconBackgroundWrapper
                padding={`${options.padding}px`}
                backgroundColor={options?.disabled ? 'y200' : 'y400'}
            >
                <Icon name="clear_day" size={size - 2 * options.padding} color={'n0'} />
            </IconBackgroundWrapper>
        );
    }
    return <Icon name="clear_day" size={size} color={options?.disabled ? 'y200' : 'y400'} />;
}

function getAbsentIcon(size: LeaveTypeIconSize, options?: LeaveTypeIconOptions): JSX.Element {
    if (options?.invertedBackground) {
        return (
            <IconBackgroundWrapper
                padding={`${options.padding}px`}
                backgroundColor={options?.disabled ? 'n100' : 'n300'}
            >
                <Icon name="event_busy" size={size} color={'n0'} />
            </IconBackgroundWrapper>
        );
    }
    return <Icon name="event_busy" size={size} color={options?.disabled ? 'n100' : 'n300'} />;
}
