import React from 'react';

import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useFetchStaffHoursExactAttendancePermissions} from './queries/use-fetch-staff-hours-exact-attendance-permissions';

/**
 * Do not use this query directly!
 */
export function useStaffHoursExactAttendancePermission(
    payload: UseStatPermissionPayload,
): StatPermissions['staffHoursSettingsPage']['header']['exactAttendanceOptIn'] & {loading: boolean} {
    const {data, loading} = useFetchStaffHoursExactAttendancePermissions({
        variables: {siteSetId: payload.institutionSetId},
        skip: !payload.institutionSetId || !payload.shouldQuery,
    });

    return React.useMemo(() => {
        const canOptIn = data?.staffhours.featureSettings.exactAttendance.canOptIn ?? false;

        // Since backend does not follow CRUD pattern - we need to manually map the permissions
        return {loading, canRead: canOptIn, canUpdate: canOptIn, canCreate: false, canDelete: false};
    }, [data?.staffhours.featureSettings.exactAttendance.canOptIn, loading]);
}
