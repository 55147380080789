import {type List} from 'immutable';

import {createAction} from 'signin-app/redux/helpers';

const domain = 'DAYCARE';

export const {
    action: fetchOverview,
    success: fetchOverviewSuccess,
    failed: fetchOverviewFailed,
} = createAction(
    `${domain}/FETCH_OVERVIEW`,
    accessToken => ({accessToken}),
    response => ({institutionOverview: response}),
    (error: Error) => ({error}),
);

export const {action: updateHiddenGroups} = createAction(
    `${domain}/UPDATE_HIDDEN_GROUPS`,
    (hiddenGroupIds: List<string>) => ({hiddenGroupIds}),
);
