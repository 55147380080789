import {CpcTreehouseTheme as CpcTreehouseMFTheme} from 'modern-famly';

import {hexToRGBA} from 'web-app/util/hex-to-rgb';

import BaseTheme from './base';

const CpcTrehouseChromeColor = '#339933';
const CpcTrehousePrimary = CpcTrehouseChromeColor;
const CpcTrehouseActive = hexToRGBA(CpcTrehouseChromeColor, 0.8);
const CpcTrehouseBackgroundHover = hexToRGBA(CpcTrehouseChromeColor, 0.04);

const CpcTrehouseTheme = BaseTheme.merge({
    title: 'CPC Treehouse',
    chromeColor: CpcTrehouseChromeColor,
    primary: CpcTrehousePrimary,
    active: CpcTrehouseActive,
    backgroundHover: CpcTrehouseBackgroundHover,
    mf: CpcTreehouseMFTheme,
});

export default CpcTrehouseTheme;
