import React from 'react';
import PropTypes from 'prop-types';

import DurationPicker from 'web-app/react/components/form-elements/timepicker/duration-picker';

/**
 * Simple wrapper around DurationPicker.
 */
const FormDurationPicker = props => {
    const {time, onChange, input, ...otherProps} = props;

    if (time) {
        input.value = time;
    }

    return (
        <DurationPicker
            {...input}
            {...otherProps}
            onChange={newValue => {
                if (onChange) {
                    onChange(newValue);
                }
                return input.onChange(newValue);
            }}
        />
    );
};
FormDurationPicker.propTypes = {
    interval: PropTypes.number,
    fromMinutes: PropTypes.number,
    toMinutes: PropTypes.number,
    time: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    input: PropTypes.object, // redux-form.
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func,
};

export default FormDurationPicker;
