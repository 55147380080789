import gql from 'graphql-tag';

export const CustomRegistrationFormSaveResult = gql`
    fragment CustomRegistrationFormSaveResult on FormSaveResult {
        mostRecentVersion
        savedForm {
            ...CustomRegistrationFormDetails
        }
        status
    }
`;
