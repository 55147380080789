import React from 'react';

import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';
import {useFetchDetailedBreakdownPermissions} from './queries/use-fetch-detailed-breakdown-permissions';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export function useDetailedBreakdownPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['attendance']['header']['export']['detailedBreakdown'] & {loading: boolean} {
    const {data, loading} = useFetchDetailedBreakdownPermissions({
        variables: {siteSetId: payload.institutionSetId},
        skip: !payload.shouldQuery,
    });
    const crud = useCrudPermissionAdapter(data?.staffhours.management.attendance.detailedCsvExportURL);
    return React.useMemo(() => ({loading, ...crud}), [crud, loading]);
}
