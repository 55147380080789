import React from 'react';
import {Link} from 'react-router-dom';
import {Stack} from 'modern-famly';

import PersonButton from 'signin-app/components/person-button';
import {pinChildren} from 'signin-app/child/selectors';
import {pinEmployees} from 'signin-app/employee/selectors';

import {useTypedSelector} from './hooks';

interface ChildSelectProps {
    activeChildId?: string;
    activeEmployeeId?: string;
    className?: string;
}

const PersonSelect: React.FC<ChildSelectProps> = ({className, activeChildId, activeEmployeeId}) => {
    const children = useTypedSelector(pinChildren);
    const employees = useTypedSelector(pinEmployees);
    const allPinPersons = children?.concat(employees);

    if (!children || (allPinPersons && allPinPersons.size < 2)) {
        return null;
    }

    return (
        <Stack className={className} mb={12} justifyContent="space-between" flexWrap="wrap">
            <Stack gap={4} flexWrap="wrap">
                {children.map(child => {
                    const childUrl = `/child/${child.id}/${child.checkedIn ? 'checkout' : 'checkin'}`;

                    return (
                        <Link to={childUrl} key={child.id}>
                            <PersonButton
                                key={child.id}
                                person={child}
                                selected={child.id === activeChildId}
                                compact={true}
                            />
                        </Link>
                    );
                })}
            </Stack>
            {employees && employees.size > 0 ? (
                <Stack gap={4} flexWrap="wrap">
                    {employees.map(employee => {
                        const employeeUrl = `/employee/${employee.id}/${employee.checkedIn ? 'checkout' : 'checkin'}`;

                        return (
                            <Link to={employeeUrl} key={employee.id}>
                                <PersonButton
                                    key={employee.id}
                                    person={employee}
                                    selected={employee.id === activeEmployeeId}
                                    compact={true}
                                />
                            </Link>
                        );
                    })}
                </Stack>
            ) : null}
        </Stack>
    );
};

export default PersonSelect;
