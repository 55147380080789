import {STATUS_TYPE} from 'web-app/react/components/notification-bar/types';
import {type DispatchableActionDescription} from 'web-app/util/redux/action-types';
import {type CreateAction} from 'web-app/util/redux/action-helpers';
import {hasValue} from 'web-app/util/typescript';

import {defaultDuration, defaultErrorDuration, defaultErrorExtendedDuration, defaultSuccessDuration} from './constants';

const domain = 'GLOBAL';

interface GlobalEventPayload {
    message: string;
    sticky: boolean;
    statusType: STATUS_TYPE;
    duration: number;
}

interface GlobalEventActions {
    updateError: DispatchableActionDescription<GlobalEventPayload>;
    updateSuccess: DispatchableActionDescription<GlobalEventPayload>;
    updateInfo: DispatchableActionDescription<GlobalEventPayload>;
    updateStatus: DispatchableActionDescription<GlobalEventPayload>;
    clearStatus: DispatchableActionDescription<{eventId: string}>;
}

const getDefaultDurationForUpdateStatusEvent = (
    statusType: GlobalEventPayload['statusType'],
    hasExtendedErrorDuration: boolean,
) => {
    switch (statusType) {
        case STATUS_TYPE.Success:
            return defaultSuccessDuration;
        case STATUS_TYPE.Error:
            return hasExtendedErrorDuration ? defaultErrorExtendedDuration : defaultErrorDuration;
        default:
            return defaultDuration;
    }
};

const MAX_CHARACTER_LENGTH_BEFORE_EXTENDED_ERROR_DURATION = 80;

const shouldHaveExtendedErrorDuration = (message?: string) => {
    if (!hasValue(message)) {
        return false;
    }

    return message.length >= MAX_CHARACTER_LENGTH_BEFORE_EXTENDED_ERROR_DURATION;
};

const createGlobalEventActions = (createAction: CreateAction): GlobalEventActions => {
    const {action: updateError} = createAction(
        `${domain}/UPDATE_GLOBAL_STATUS`,
        (
            message: string,
            sticky = false,
            duration = shouldHaveExtendedErrorDuration(message) ? defaultErrorExtendedDuration : defaultErrorDuration,
            e2eId = '',
        ) => ({
            message,
            statusType: STATUS_TYPE.Error,
            e2eId,
            sticky,
            duration,
        }),
    );

    const {action: updateSuccess} = createAction(
        `${domain}/UPDATE_GLOBAL_STATUS`,
        (message: string, sticky = false, duration = defaultSuccessDuration, e2eId = '') => ({
            message,
            statusType: STATUS_TYPE.Success,
            e2eId,
            sticky,
            duration,
        }),
    );

    const {action: updateInfo} = createAction(
        `${domain}/UPDATE_GLOBAL_STATUS`,
        (message: string, sticky = false, duration = defaultDuration, e2eId = '') => ({
            message,
            statusType: STATUS_TYPE.Info,
            e2eId,
            sticky,
            duration,
        }),
    );

    const {action: updateStatus} = createAction(
        `${domain}/UPDATE_GLOBAL_STATUS`,
        (
            message: string,
            statusType: STATUS_TYPE,
            sticky = false,
            duration = getDefaultDurationForUpdateStatusEvent(statusType, shouldHaveExtendedErrorDuration(message)),
            e2eId = '',
        ) => ({
            message,
            statusType,
            e2eId,
            sticky,
            duration,
        }),
    );

    const {action: clearStatus} = createAction(`${domain}/CLEAR_STATUS`, (eventId: string) => ({eventId}));

    return {
        updateError,
        updateSuccess,
        updateInfo,
        updateStatus,
        clearStatus,
    };
};

export default createGlobalEventActions;
