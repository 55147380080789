import React from 'react';
import PropTypes from 'prop-types';

import Incrementer from 'web-app/react/components/increment-decrement/incrementer';
import ReactOnMeta from 'web-app/react/components/form-elements/containers/react-on-meta';

const FormIncrementer = props => {
    const {
        meta, // eslint-disable-line no-unused-vars
        input,
        onChange,
        ...rest
    } = props;

    const value = input.value || 0;

    const handleChange = newValue => {
        input.onChange(newValue);

        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <ReactOnMeta {...props}>
            <Incrementer
                {...rest}
                count={value}
                onIncrement={() => handleChange(value + 1)}
                onDecrement={() => handleChange(value - 1)}
            />
        </ReactOnMeta>
    );
};
FormIncrementer.propTypes = {
    onChange: PropTypes.func,
    input: PropTypes.object,
    meta: PropTypes.any,
    placeholder: PropTypes.string,
};

export default FormIncrementer;
