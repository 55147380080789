import React from 'react';

export default function withContext<TContext, TOriginalProps = {}>(Consumer: React.Consumer<TContext>) {
    return (
        Component: React.ComponentType<React.PropsWithChildren<TOriginalProps & {context: TContext}>>,
    ): React.FC<React.PropsWithChildren<TOriginalProps>> => {
        const InnerComponent: React.FC<React.PropsWithChildren<TOriginalProps>> = props => {
            return <Consumer>{context => <Component {...props} context={context} />}</Consumer>;
        };

        return InnerComponent;
    };
}
