import {useStatFeatures} from '../../features/use-stat-features';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {type StatPermissions} from './stat-permissions';

/**
 * Do not use this query directly!
 */
export function useStaffAttendanceFeatureTogglePermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['staffAttendanceFeatureToggle'] {
    const {institutionSetId} = payload;
    const [
        {
            attendancePage: {canEnable},
        },
    ] = useStatFeatures({
        institutionId: institutionSetId,
    });
    return {
        canCreate: false,
        canRead: canEnable,
        canUpdate: canEnable,
        canDelete: false,
    };
}
