import React from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {Stack, createBox, Text, Avatar, AvatarGroup, createStack} from 'modern-famly';
import moment from 'moment-timezone';

import * as PrivateRouteSelectors from 'signin-app/components/private-route/selectors';
import * as DisplayedGroupsModalActions from 'signin-app/modals-container/displayed-groups-modal/actions';
import translate from 'signin-app/helpers/translate';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import DisplayedGroupsSettings from 'signin-app/modals-container/displayed-groups-modal/displayed-groups-modal';
import {useTypedSelector} from 'signin-app/components/hooks';
import {SettingsDropdown} from 'signin-app/components/settings-dropdown';
import {isOpen} from 'web-app/react/modals-container/types';
import {type RootState} from 'signin-app/redux/main-reducer';
import {usePull} from 'signin-app/helpers/use-pull';
import {accessToken} from 'signin-app/login/selectors';
import * as Actions from 'signin-app/components/private-route/actions';
import * as WhitelabelSelectors from 'signin-app/whitelabel/selectors';
import {type IStaffGroup, type IEmployee} from 'signin-app/entities/staff/model';
import {HeaderLogoContainer} from 'signin-app/components/containers';
import {useCustomWhitelabelColor} from 'signin-app/components/hooks/use-custom-whitelabel-color';

import * as Selectors from './selectors';
import {GroupItem, GroupType, CardsContainer} from './group-item';

const RefreshContainer = styled(createBox({}))<{mt: number}>`
    margin-top: ${props => props.mt}px;
`;

const StyledCheckedInContainer = styled(createStack({}))<{customBackgroundColor?: string}>`
    background-color: ${props => props.customBackgroundColor};
`;

export const Groups: React.FC<{}> = () => {
    const institution = useTypedSelector(Selectors.institution);
    const displayedChildGroups = useTypedSelector(Selectors.displayedChildGroups);
    const displayedStaffGroups = useTypedSelector(Selectors.displayedStaffGroups);
    const checkedInCounts = useTypedSelector(Selectors.checkedInCounts);
    const isFetching = useTypedSelector(PrivateRouteSelectors.isPrefetching);
    const didPrefetch = useTypedSelector(PrivateRouteSelectors.didPrefetch);
    const lastPrefetch = useTypedSelector(PrivateRouteSelectors.lastPrefetch);
    const isSettingsModalOpen = useTypedSelector((state: RootState) => isOpen(state.modals.displayedGroupsSettings));
    const token = useTypedSelector(accessToken);
    const whitelabelConfiguration = useTypedSelector(state => WhitelabelSelectors.whiteLabelConfiguration(state));
    const p400 = useCustomWhitelabelColor('p400');
    const enableEmployeeCheckin = useTypedSelector(Selectors.enableEmployeeCheckin);

    const dispatch = useDispatch();

    const [isHeaderInView, setIsHeaderInView] = React.useState(true);

    const headerRef = React.useRef<HTMLDivElement>(null);

    const handlePullDown = React.useCallback(() => dispatch(Actions.prefetch.action(token)), [dispatch, token]);
    const {onTouchStart, onTouchMove, onTouchEnd, marginTop} = usePull({
        minPullDistance: 100,
        onPullDown: handlePullDown,
        preventFromRefreshing: !isHeaderInView,
    });

    React.useEffect(() => {
        const handleScroll = () => {
            if (headerRef.current) {
                const headerRect = headerRef.current.getBoundingClientRect();
                setIsHeaderInView(headerRect.bottom > 0);
            }
        };
        const root = document.getElementById('root');
        root?.addEventListener('scroll', handleScroll);

        return () => {
            root?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const totalCheckedInCount = React.useMemo(() => {
        return displayedStaffGroups.reduce((total, group) => total + checkedInCounts[group.groupId], 0);
    }, [checkedInCounts, displayedStaffGroups]);

    const checkedInStaff = React.useMemo(() => {
        const checkedIn = Array.from(displayedStaffGroups)
            .reduce((acc: IEmployee[], item: IStaffGroup) => [...acc, ...item.employees], [])
            .filter((staff: IEmployee) => staff.checkedIn);
        return checkedIn;
    }, [displayedStaffGroups]);

    const lastUpdated = React.useMemo(() => {
        if (!lastPrefetch) {
            return null;
        }
        return (
            <Text variant="body-small" color="n200" textAlign="center" mt={2}>
                {`${translate('lastUpdated')} ${moment(lastPrefetch).format('HH:mm')}`}
            </Text>
        );
    }, [lastPrefetch]);

    return isFetching || !didPrefetch ? (
        <Spinner color="white" centered />
    ) : (
        <>
            {marginTop ? <Spinner color="white" centered /> : null}
            <RefreshContainer
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
                mt={marginTop}
            >
                <Stack flexDirection="column" px={14} pt={10} pb={30}>
                    <Stack alignItems="center" justifyContent="space-between" ref={headerRef}>
                        <HeaderLogoContainer>
                            <whitelabelConfiguration.PinLogo />
                        </HeaderLogoContainer>
                        <SettingsDropdown
                            settings={[
                                {
                                    label: translate('settings'),
                                    onClick: () => dispatch(DisplayedGroupsModalActions.show.action()),
                                },
                            ]}
                            siteName={institution?.title}
                            showWarning
                        />
                    </Stack>
                    <Text variant="h4" color="n0" textAlign="center" mt={2} data-e2e-id="sign-in-title">
                        {translate('signInTitle')}
                    </Text>
                    {lastUpdated}
                    <Stack direction="column" mt={6} gap={18} data-e2e-id="signin-overview">
                        <Stack gap={2} flexDirection="column">
                            <Text variant="h5" color="n0" emphasized>
                                {translate('childrenRooms')}
                            </Text>
                            <CardsContainer>
                                {displayedChildGroups.map(group => (
                                    <GroupItem
                                        key={group.groupId}
                                        title={group.title}
                                        image={group.image.large}
                                        href={`/groups/${group.groupId}`}
                                        checkedInCount={checkedInCounts[group.groupId]}
                                        groupType={GroupType.Children}
                                        groupId={group.groupId}
                                    />
                                ))}
                            </CardsContainer>
                        </Stack>

                        {enableEmployeeCheckin && displayedStaffGroups.size > 0 ? (
                            <Stack gap={2} flexDirection="column">
                                <Text variant="h5" color="n0" emphasized>
                                    {translate('staffRooms')}
                                </Text>
                                {totalCheckedInCount ? (
                                    <StyledCheckedInContainer
                                        color="n0"
                                        px={3}
                                        py={1}
                                        borderRadius={32}
                                        flex="0"
                                        minHeight="48px"
                                        width="fit-content"
                                        alignItems="center"
                                        customBackgroundColor={p400}
                                    >
                                        <AvatarGroup>
                                            {checkedInStaff.map(staff => (
                                                <Avatar
                                                    initials={`${staff.name.firstName.charAt(
                                                        0,
                                                    )}${staff.name.lastName.charAt(0)}`}
                                                    src={staff.image.small}
                                                    alt={staff.name.firstName}
                                                    size={24}
                                                />
                                            ))}
                                        </AvatarGroup>
                                        <Text variant="body-small" ml={2}>
                                            {translate('staffCheckedInCount', {
                                                number: totalCheckedInCount,
                                            })}
                                        </Text>
                                    </StyledCheckedInContainer>
                                ) : null}
                                <CardsContainer>
                                    {displayedStaffGroups.map(group => (
                                        <GroupItem
                                            key={group.groupId}
                                            title={group.title}
                                            href={`/staff/${group.groupId}`}
                                            checkedInCount={checkedInCounts[group.groupId]}
                                            groupType={GroupType.Staff}
                                            groupId={group.groupId}
                                        />
                                    ))}
                                </CardsContainer>
                            </Stack>
                        ) : null}
                    </Stack>
                </Stack>
                {isSettingsModalOpen ? <DisplayedGroupsSettings /> : null}
            </RefreshContainer>
        </>
    );
};

export default Groups;
