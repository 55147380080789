import {produce} from 'immer';
import React from 'react';

import {hasValue} from 'web-app/util/typescript';

type NestedBoolean<T> = T extends object
    ? {[key in keyof T]: T[key] extends object ? NestedBoolean<T[key]> : boolean}
    : never;

/**
 * Allows creating a proxy version of a potential god-object consisting of multiple queries and makes sure, that
 * the queries in the god-object-like are only queried, once they are first accessed.
 *
 * This is done by keeping a record of properties, that were already accessed in the returned result
 * (the god-object-like) and putting a boolean flag in this hook's state (shouldQuery) to true.
 *
 * This hook's state (shouldQuery) is the second returned object and has the same structure as the object reached
 * into the createProxy (first returned element) and assigns the boolean value `true`, if a query should be run.
 *
 * ```
 * function myQueryGodObject(params): {a: QueryResultA, b: QueryResultB} {
 *   const [createProxy, shouldQuery] = useQueriesOnFirstCall<{a: QueryResultA, b: QueryResultB}>();
 *   const result = {
 *     a: useQueryA({...params, skip: params.skip || !shouldQuery.a}),
 *     b: useQueryB({...params, skip: params.skip || !shouldQuery.b}),
 *   }
 *   return createProxy(result);
 * }
 * ```
 * @returns a tuple containing the creation method for the proxy and the current should query state
 */
export function useQueriesOnFirstCall<T extends object>(): [
    createProxy: (data: T) => T,
    shouldQuery: Partial<NestedBoolean<T>>,
] {
    const [shouldQuery, setShouldQuery] = React.useState<Partial<NestedBoolean<T>>>({});
    const proxyHandler = (prefix?: string) => ({
        get(target, p) {
            if (prefix ? !shouldQuery[prefix]?.[p] : !shouldQuery[p]) {
                setShouldQuery(currentState =>
                    produce(currentState, draft => {
                        if (prefix) {
                            // if a boolean value has been set for a nested object type
                            // we need to delete that entry first
                            if (hasValue(draft[prefix]) && typeof draft[prefix] === 'boolean') {
                                delete draft[prefix];
                            }

                            // if there is no nested object already, create it
                            if (!draft[prefix]) {
                                draft[prefix] = {
                                    [p]: true,
                                };
                            } else {
                                // or add the key otherwise
                                draft[prefix][p] = true;
                            }
                        } else if (!hasValue(draft[p])) {
                            draft[p] = true;
                        }
                        return draft;
                    }),
                );
            }
            if (typeof target[p] === 'object' && !Array.isArray(target[p]) && target[p] !== null) {
                return new Proxy(target[p], proxyHandler(prefix ? `${prefix}.${p}` : p));
            }
            return target[p];
        },
    });
    return [(data: T) => new Proxy(data, proxyHandler()) as T, shouldQuery];
}
