import React from 'react';

import GlobalEventContent from 'web-app/react/global-event/global-event-content';
import {isOpen} from 'web-app/react/modals-container/types';
import {EventSize} from 'web-app/react/global-event/types';
import {useTypedSelector} from 'signin-app/components/hooks';
import {useDispatchCallback} from 'web-app/react/hooks/use-bind-dispatch';

import * as Actions from './actions';

export default () => {
    const events = useTypedSelector(state => state.globalEvent.events);
    const isShowingModals = useTypedSelector(state => isOpen(state.modals.displayedGroupsSettings)); // Currently we're using only 1 modal

    const clearStatus = useDispatchCallback(
        dispatch => (eventId: string) => {
            dispatch(Actions.clearStatus.action(eventId));
        },
        [],
    );

    return (
        <GlobalEventContent
            events={events}
            isShowingModals={isShowingModals}
            clearStatus={clearStatus}
            eventSize={EventSize.Large}
        />
    );
};
