import React from 'react';

import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';
import {useFetchContractedHoursPermissions} from './queries/use-fetch-contracted-hours-permissions';

/**
 * Do not use this query directly!
 */
export function useContractChangeLogPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['staffProfile']['contract']['changeLog'] & {loading: boolean} {
    const {data, loading} = useFetchContractedHoursPermissions({
        variables: {siteSetId: payload.institutionSetId},
        skip: !payload.shouldQuery,
    });
    const crud = useCrudPermissionAdapter(data?.staffhours.management.contractedHours.changeLog);
    return React.useMemo(() => ({loading, ...crud}), [crud, loading]);
}
