declare const navigator: any;
import {StatusBar, Style} from '@capacitor/status-bar';

import PlatformHelper, {Store} from 'web-app/helpers/platform-helper';
import {currentTheme} from 'web-app/styleguide';
import {hslStringToRgb, isHSLA} from 'web-app/util/hsl-helpers';

type VideoFile = {
    fullPath: string;
    localURL: string;
};
export const androidVideoHandler = () => {
    // `navigator.device` is defined by cordova-plugin-media-capture
    return new Promise<VideoFile[]>((resolve, reject) => {
        navigator.device.capture.captureVideo(resolve, reject, {
            limit: 1,
            // The video capture on Amazon devices crashes if the duration is not set.
            duration: PlatformHelper.store() === Store.AMAZONAPPSTORE ? 3600 : undefined,
        });
    });
};

/*
 * Status bar operations
 */
export const setStatusBar = (style: Style) => {
    if (!PlatformHelper.hasDynamicStatusBar()) {
        return new Promise<void>(resolve => resolve());
    }
    return Promise.all([
        StatusBar.setStyle({style}),
        PlatformHelper.isAndroid()
            ? StatusBar.setBackgroundColor({color: styleToBackgroundColor(style)})
            : Promise.resolve(),
    ]);
};

const styleToBackgroundColor = (style: Style) => {
    const chromeColor = currentTheme().chromeColor;
    const hexChromeColor = isHSLA(chromeColor) ? hslStringToRgb(chromeColor) : chromeColor;

    switch (style) {
        case Style.Light:
            return currentTheme().invertedText;
        case Style.Dark:
            return hexChromeColor;
        default:
            return hexChromeColor;
    }
};
