const employeeBehaviorIds = {
    CanManageKibigWeb: 'CanManageKibigWeb',
    CanAccessEmployeeProfile: 'CanAccessEmployeeProfile',
    ShowEmployeeAddress: 'ShowEmployeeAddress',
    CanSendPostTo: 'CanSendPostTo',
    CanSendMessageTo: 'CanSendMessageTo',
    ShowEmployeePhoneNumber: 'ShowEmployeePhoneNumber',
    CanChangeEmail: 'CanChangeEmail',
    CanManageEmployeeRole: 'CanManageEmployeeRole',
    CanChangeGroup: 'CanChangeGroup',
    CanChangeStartEnddate: 'CanChangeStartEnddate',
    CanCheckinEmployee: 'CanCheckinEmployee',
    CanCheckoutEmployee: 'CanCheckoutEmployee',
    ShowSensitiveInformation: 'ShowSensitiveInformation',
    CanSetStatusChildSick: 'CanSetStatusChildSick',
    CanNotChangeAddress: 'CanNotChangeAddress',
    CanManageTags: 'CanManageTags',
    IsActive: 'IsActive',
    ShowStaffQualifications: 'ShowStaffQualifications',
    CanCreateQualificationAssignment: 'CanCreateQualificationAssignment',
    CanUpdateQualificationAssignment: 'CanUpdateQualificationAssignment',
    CanRemoveQualificationAssignment: 'CanRemoveQualificationAssignment',
    ShowLeaveWidget: 'ShowLeaveWidget',
    CanAddEmployeeLeave: 'CanAddEmployeeLeave',
    CanSwitchRoom: 'CanSwitchRoom',
    ShowEmployeeCalendar: 'ShowEmployeeCalendar',
    UsePredefinedEmployeeStatus: 'UsePredefinedEmployeeStatus',
    CanSeeKeyWorker: 'CanSeeKeyWorker',
    CanAssignKeyWorker: 'CanAssignKeyWorker',
    CanSeeDetailsFromProfile: 'CanSeeDetailsFromProfile',
    ShowEmployeeContacts: 'ShowEmployeeContacts',
    CanEditEmployeeBasicInfo: 'CanEditEmployeeBasicInfo',
    CanRequestVacation: 'CanRequestVacation',
    CanViewContractSection: 'CanViewContractSection',
    CanEditContractSection: 'CanEditContractSection',
    CanViewAndEditEmployeeContactInfo: 'CanViewAndEditEmployeeContactInfo',
    CanGetOneTimePassword: 'CanGetOneTimePassword',
};

export default employeeBehaviorIds;
