import {createAction} from 'signin-app/redux/helpers';

const domain = 'DAYCARE';

export const {
    action: prefetch,
    success: prefetchSuccess,
    failed: prefetchFailed,
} = createAction(
    `${domain}/PREFETCH`,
    accessToken => ({accessToken}),
    response => ({institutionOverview: response}),
    (error: Error) => ({error}),
);
