import {convertToAmPmHours, type AmPmHours} from 'signin-app/helpers/time';

interface HourDisplayValue {
    hour: number;
    amPm: 'am' | 'pm';
}

export const getDisplayHourValue = (locale: string, hour: number = 0): HourDisplayValue => {
    let amPmHours = {} as AmPmHours;
    if (locale === 'en_US') {
        amPmHours = convertToAmPmHours(hour);
    }

    const formattedHour = locale === 'en_US' ? amPmHours.hours : hour;

    return {
        hour: formattedHour,
        amPm: amPmHours.amPm,
    };
};
