import {gql} from '@apollo/client';

import {StatPermissionLeave} from '../fragment/stat-permission-leave.fragment';
import {StatPermissionLeaves} from '../fragment/stat-permission-leaves.fragment';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchLeavePermissions = gql`
    query FetchLeavePermissions($institutionSetId: InstitutionSetId!, $employeeId: EmployeeId!) {
        staffhours {
            management {
                leaves {
                    ...StatPermissionLeaves
                }
            }
        }
    }
    ${StatPermissionLeaves}
    ${StatPermissionLeave}
`;
