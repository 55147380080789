export const Headers = {
    XFamlyAccesstoken: 'X-Famly-Accesstoken',
    XFamlyPlatform: 'X-Famly-Platform',
    XFamlyVersion: 'X-Famly-Version',
    XFamlyRequestUuid: 'X-Famly-Request-Uuid',
    XFamlyInstallationId: 'X-Famly-InstallationId',
    XFamlyRoute: 'X-Famly-Route',
};

export const REST_GRAPHQL_CLIENTNAME = 'restGraphQl';
