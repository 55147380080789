import React from 'react';
import moment from 'moment-timezone';
import DayPicker, {
    type Modifier,
    type Modifiers,
    type WeekdayElementProps,
    type DayPickerProps,
} from 'react-day-picker';
import styled, {css} from 'styled-components';

import formPrepare from 'web-app/react/components/higher-order/form-prepare';
import {compose} from 'web-app/util/typescript';
import deriveProps from 'web-app/react/components/higher-order/derive-props';
import {Flex, Base} from 'web-app/react/components/layout/layout';
import {PureRenderIgnoreFunctions} from 'web-app/react/components/higher-order/pure-render';
import {Body, Caption} from 'web-app/react/components/text/text';
import {hexToRGBString} from 'web-app/util/hex-to-rgb';
import {media} from 'web-app/styleguide/utils';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import {Colors} from 'web-app/styleguide';
import PlatformHelper from 'web-app/helpers/platform-helper';

import LocaleUtils from './locale-utils';
import CaptionElement from './components/caption-element';
import NavbarElement from './components/navbar-element';
import * as Selectors from './selectors';

class Weekday extends React.PureComponent<WeekdayElementProps> {
    public render() {
        const {weekday, className, localeUtils, locale} = this.props;
        const weekdayName = localeUtils.formatWeekdayShort(weekday, locale);
        return (
            <StyledWeekDay inline color={Colors.TextDisabled} className={className} title={weekdayName}>
                {weekdayName}
            </StyledWeekDay>
        );
    }
}

const StyledWeekDay = styled(Caption)`
    display: table-cell;
`;

export const Day = styled(Flex)`
    height: 36px;
    width: 36px;
    padding: 2px;
    box-sizing: border-box;
    position: relative;
    z-index: 3;

    ${Body} {
        z-index: 2;
    }
`;

const Dot = styled(Base)<{selected?: boolean}>`
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: ${props => (props.selected ? props.theme.invertedText : props.theme.active)};
    box-sizing: border-box;
    & + & {
        margin-left: 3px;
    }
`;

const DotContainer = styled(Flex)`
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
`;

const getBackground = (theme, colorProp, opacity) => {
    const themeColor = theme[colorProp];
    if (opacity) {
        const color = hexToRGBString(themeColor);

        return `rgba(${color}, ${opacity})`;
    }

    return themeColor;
};

interface IDayWrapperModifiers {
    backgroundAccent3?: boolean;
    disabled?: boolean;
    outside?: boolean;
    backgroundOpacity?: boolean;
    selected?: boolean;
    today?: boolean;
}

interface IDayRendererModifiers extends IDayWrapperModifiers {
    oneDot?: boolean;
    twoDots?: boolean;
    threeDots?: boolean;
}

interface IDayWrapperProps {
    modifiers: IDayWrapperModifiers;
}

const DayWrapper = styled(Base)<IDayWrapperProps>`
    background: transparent;
    transition: background-color 0.5s linear;
    ${props =>
        props.modifiers.backgroundAccent3 && !props.modifiers.disabled && !props.modifiers.outside
            ? css`
        background: ${getBackground(props.theme, 'accent3', props.modifiers.backgroundOpacity ? '0.5' : undefined)};
        ${Day} ${Body} {
            color: ${props => props.theme.invertedText}; !important;
        }

        ${Dot} {
            background: ${props => props.theme.invertedText};
        }

        ${
            !props.modifiers.selected && props.modifiers.today && props.modifiers.backgroundOpacity
                ? css`
            ${Day} ${Body} {
                color: ${props => props.theme.accent3}; !important;
            }
        `
                : ''
        }
    `
            : ''}
    ${props =>
        props.modifiers.outside
            ? css`
                  ${Day} ${Body} {
                      color: ${props.theme.textDisabled};
                  }
              `
            : ''}
`;

export interface IDayRendererInputProps {
    day: Date;
    modifiers: IDayRendererModifiers;
}

interface IDayRendererDerivedProps {
    memoizedDay: ReturnType<typeof Selectors.makeMemoizedDaySelector>;
    memoizedModifiers: ReturnType<typeof Selectors.makeMemoizedModifiersSelector>;
    memoizedFormattedDate: ReturnType<typeof Selectors.makeMemoizedFormattedDateSelector>;
}

interface IDayRendererFormPrepareProps {
    day: Date;
    modifiers: IDayRendererModifiers;
    formattedDate: string;
}

class DayRenderer extends React.PureComponent<
    IDayRendererInputProps & IDayRendererDerivedProps & IDayRendererFormPrepareProps
> {
    constructor(props) {
        super(props);
    }

    public render() {
        const {day, modifiers, formattedDate} = this.props;
        return (
            <DayWrapper modifiers={modifiers} data-e2e-id={`daypicker-day_${formattedDate}`}>
                <Day align="center" justify="center">
                    <Body emphasized>{day.getDate()}</Body>
                    {modifiers.oneDot || modifiers.twoDots || modifiers.threeDots ? (
                        <DotContainer align="center" justify="center">
                            <Dot selected={modifiers.selected} />
                            {modifiers.twoDots || modifiers.threeDots ? <Dot selected={modifiers.selected} /> : null}
                            {modifiers.threeDots ? <Dot selected={modifiers.selected} /> : null}
                        </DotContainer>
                    ) : null}
                </Day>
            </DayWrapper>
        );
    }
}

const ComposedDayRenderer = compose(
    deriveProps<IDayRendererInputProps, IDayRendererDerivedProps>(() => ({
        memoizedDay: Selectors.makeMemoizedDaySelector(),
        memoizedModifiers: Selectors.makeMemoizedModifiersSelector(),
        memoizedFormattedDate: Selectors.makeMemoizedFormattedDateSelector(),
    })),
)
    .with(
        formPrepare<IDayRendererInputProps & IDayRendererDerivedProps, IDayRendererFormPrepareProps>(props => ({
            day: props.memoizedDay(props),
            modifiers: props.memoizedModifiers(props),
            formattedDate: props.memoizedFormattedDate({day: props.day}),
        })),
    )
    .apply(DayRenderer);

const defaultDayRenderer = (day, modifiers) => <ComposedDayRenderer day={day} modifiers={modifiers} />;

/**
 * This is a nasty fix to a browser bug in Safari
 * where table elements with `display: table-caption;`
 * will jump from the top to the bottom of a table if
 * a child element in the table creates a new stacking
 * context.
 *
 * See the Webkit bug report here:
 * https://bugs.webkit.org/show_bug.cgi?id=187903
 */
const safariCaptionFix = css`
    ${() => {
        if (PlatformHelper.isSafariDesktop()) {
            return css`
                .DayPicker-Caption {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 0;
                    position: absolute;
                    top: -26px;
                    left: 0;
                    align-items: center;
                }

                .DayPicker-NavBar {
                    top: -43px;
                    z-index: 1;
                }

                .DayPicker-Months {
                    /* Start: SAFARI FIX */
                    margin-top: 40px;
                    outline: none;
                }
            `;
        } else {
            return css``;
        }
    }}
`;

const WithMonthChangeStyle = css`
    .DayPicker-Caption {
        color: ${props => props.theme.text};
        text-align: center;
        font-size: ${props => props.theme.fontConfiguration.sizes.Caption};
        height: auto;
        margin: 0;
    }
    .DayPicker-wrapper {
        padding: 0;
    }
    .DayPicker-NavButton {
        height: 16px;
        width: 16px;
    }
    .DayPicker-NavBar {
        top: -3px;
        position: absolute;
        left: 0;
        right: 0;
    }

    ${safariCaptionFix};
`;

const DayPickerWrapper = styled.div<{canChangeMonth: boolean; hoverEffectOnWeek?: boolean}>`
    .DayPicker-wrapper {
        padding: 8px 0;
    }

    .DayPicker:not(.DayPicker--interactionDisabled) {
        ${props =>
            props.hoverEffectOnWeek
                ? css`
                      .DayPicker-Week:hover {
                          .DayPicker-Day:not(.DayPicker-Day--disabled) {
                              &:after {
                                  content: '';
                                  top: 0;
                                  left: 0;
                                  right: 0;
                                  bottom: 0;
                                  position: absolute;
                                  background-color: ${props => props.theme.backgroundHover};
                                  z-index: 1;
                                  border-radius: 0 !important;
                              }

                              &:first-child:after {
                                  border-top-left-radius: 50% !important;
                                  border-bottom-left-radius: 50% !important;
                              }

                              &:last-child:after {
                                  border-top-right-radius: 50% !important;
                                  border-bottom-right-radius: 50% !important;
                              }
                          }

                          .DayPicker-Day--isWeekendDisabled {
                              &.DayPicker-Day--isMonday:after {
                                  border-top-left-radius: 50% !important;
                                  border-bottom-left-radius: 50% !important;
                              }

                              &.DayPicker-Day--isFriday:after {
                                  border-top-right-radius: 50% !important;
                                  border-bottom-right-radius: 50% !important;
                              }
                          }
                      }
                  `
                : ''}

        .DayPicker-Day {
            padding: 0;
            cursor: pointer;
            border: none;
            position: relative;
            border-radius: 0 !important;

            &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--weekend) {
                &,
                &:hover {
                    background: transparent;
                }
            }
            &--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--backgroundAccent3):not(
                    .DayPicker-Day--outside
                ):not(.DayPicker-Day--selected):not(.DayPicker-Day--primary) {
                ${Day} {
                    ${Body} {
                        color: ${props => props.theme.accent3} !important;
                    }
                }
            }
            &:focus {
                outline: none;
            }

            &:not(.DayPicker-Day--beingSelected):not(.DayPicker-Day--disabled):hover,
            &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover,
            &--primary:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
                &:after {
                    content: '';
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    position: absolute;
                    background-color: ${props => props.theme.backgroundHover};
                    z-index: 1;
                    border-radius: 50%;
                }
            }

            &--disabled:not(.DayPicker-Day--outside),
            &--weekend {
                border-radius: 0;
                &,
                &:hover {
                    background-color: ${props => props.theme.backgroundHover} !important;
                }
                ${Day} {
                    ${Body} {
                        color: ${props => props.theme.textDisabled};
                    }
                }
            }

            &--disabled:not(.DayPicker-Day--outside) {
                background-color: ${props => props.theme.backgroundHover} !important;
                ${Day} {
                    cursor: default;
                    // Using important here because no matter what disabled dates should keep this styling
                    background-color: ${props => props.theme.backgroundHover} !important;
                    ${Body} {
                        color: ${props => props.theme.textDisabled} !important;
                    }
                }
                &:before,
                &:after {
                    content: none !important;
                }
            }

            &--volatile {
                ${Day} {
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;

                        background-image: ${props => `repeating-linear-gradient(
                        -45deg,
                        ${props.theme.delimiter},
                        ${props.theme.delimiter} 5px,
                        transparent 5px,
                        transparent 12.3px
                    )`};
                        z-index: 1;
                    }
                }
            }

            &--primary:not(.DayPicker-Day--disabled) {
                ${Day} {
                    border-radius: 50% !important;
                    background-color: ${props => props.theme.primary};
                }
            }

            &--selected:not(.DayPicker-Day--disabled) {
                ${Day} {
                    background-color: ${props => props.theme.active};
                }
            }

            &--selected:not(.DayPicker-Day--disabled),
            &--primary:not(.DayPicker-Day--disabled) {
                ${Day} {
                    ${Body} {
                        color: ${props => props.theme.invertedText};
                    }
                }

                &.DayPicker-Day--isFirstInRange {
                    ${Day} {
                        border-top-left-radius: 50%;
                        border-bottom-left-radius: 50%;
                    }
                }

                &.DayPicker-Day--isLastInRange {
                    ${Day} {
                        border-top-right-radius: 50%;
                        border-bottom-right-radius: 50%;
                    }
                }
                &:hover {
                    ${Day} {
                        background-color: ${props => props.theme.secondary};
                    }
                }
            }

            &--beingSelected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected),
            &--beingUnselected.DayPicker-Day--selected {
                ${Day} {
                    background-color: rgba(${props => hexToRGBString(props.theme.active)}, 0.5);
                    ${Body} {
                        color: ${props => props.theme.invertedText};
                    }
                }
            }

            &--firstOfSelection:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
                    .DayPicker-Day--primary
                ),
            &--lastOfSelection:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
                    .DayPicker-Day--primary
                ):not(.DayPicker-Day--beingUnselected) {
                ${Day} {
                    border-radius: 50%;
                    background-color: ${props => props.theme.active};
                }
            }
            &--firstOfSelection:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
                    .DayPicker-Day--primary
                ) {
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 50%;
                    background-color: rgba(${props => hexToRGBString(props.theme.active)}, 0.5);
                    z-index: 1;
                }
            }
            &--lastOfSelection:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
                    .DayPicker-Day--primary
                ):not(.DayPicker-Day--beingUnselected) {
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 50%;
                    left: 0;
                    background-color: rgba(${props => hexToRGBString(props.theme.active)}, 0.5);
                    z-index: 1;
                }
            }

            &--selected.DayPicker-Day--beingSelected {
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    background-color: rgba(${props => hexToRGBString(props.theme.active)}, 0.5);
                    z-index: 1;
                }
            }

            &--firstOfSelection.DayPicker-Day--lastOfSelection:not(.DayPicker-Day--selected):not(
                    .DayPicker-Day--primary
                ) {
                &:before {
                    content: none;
                }
            }

            &--outside {
                background-color: transparent;
                color: transparent;
            }

            &--secondary:not(.DayPicker-Day--selected) {
                ${Day} {
                    border-radius: 50%;
                    background: rgba(${props => hexToRGBString(props.theme.active)}, 0.5);
                    opacity: 50%;
                    ${Body} {
                        color: ${props => props.theme.invertedText};
                    }
                }
            }
        }
    }

    .DayPicker-Months {
        display: flex;
        flex-wrap: wrap;
    }

    ${props =>
        props.canChangeMonth
            ? WithMonthChangeStyle
            : css`
                  .DayPicker-Caption {
                      font-size: ${props => props.theme.fontConfiguration.sizes.Subheader};
                      color: ${props => props.theme.text};
                      text-align: left;
                      margin-bottom: 5px;
                      margin-top: 10px;
                      margin-left: 2px;
                  }
              `}

    .DayPicker-Month {
        margin-bottom: 20px;
    }

    ${media.mobile`
        ${() => WithMonthChangeStyle}
    `}

    ${media.tablet`
        .DayPicker-Month {
            margin-left: 5px;
            margin-right: 5px;
        }
    `}
`;

interface IDaypickerProps {
    className?: string;
    loading?: boolean;
    canChangeMonth?: boolean;
    handleDayClick: (date: Date, modifiers: IDayRendererModifiers) => void;
    dayRenderer?: (date: Date, modifiers: IDayRendererModifiers) => void;
    selectedDays: DayPickerProps['selectedDays'];
    month: Date | undefined;
    handleDayMouseOver?: (date: Date) => void;
    numberOfMonths: number;
    disabledDates?: Modifier | Modifier[];
    tabIndex?: number;
    modifiers: Partial<Modifiers> | undefined;
    withYearPicker?: boolean;
    onMonthChange: (date: Date) => void;
    noTab?: boolean;
    fixedWeeks?: boolean;
    hoverEffectOnWeek?: boolean;
    locale?: string;
}

class Daypicker extends React.Component<IDaypickerProps> {
    private locale = moment.locale();

    constructor(props) {
        super(props);

        this.renderNavBar = this.renderNavBar.bind(this);
        this.renderCaption = this.renderCaption.bind(this);
        this.renderDay = this.renderDay.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);
    }

    public render() {
        const {
            className,
            handleDayClick,
            handleDayMouseOver,
            numberOfMonths,
            selectedDays,
            month,
            disabledDates,
            modifiers,
            canChangeMonth = true,
            loading,
            hoverEffectOnWeek,
            locale,
            ...rest
        } = this.props;

        return (
            <DayPickerWrapper
                canChangeMonth={canChangeMonth}
                className={className}
                hoverEffectOnWeek={hoverEffectOnWeek}
            >
                <div>
                    <DayPicker
                        {...rest}
                        canChangeMonth={canChangeMonth}
                        month={month}
                        numberOfMonths={numberOfMonths || 12}
                        selectedDays={selectedDays}
                        disabledDays={disabledDates}
                        onDayClick={handleDayClick}
                        onDayMouseEnter={handleDayMouseOver}
                        weekdayElement={Weekday}
                        captionElement={this.renderCaption}
                        navbarElement={this.renderNavBar}
                        renderDay={this.renderDay}
                        localeUtils={LocaleUtils}
                        locale={locale || this.locale}
                        modifiers={modifiers}
                    />
                    {loading ? <Spinner /> : null}
                </div>
            </DayPickerWrapper>
        );
    }

    private renderDay(day, modifiers) {
        let renderedDay;
        if (this.props.dayRenderer) {
            renderedDay = this.props.dayRenderer(day, modifiers);
        }

        return renderedDay ? renderedDay : defaultDayRenderer(day, modifiers);
    }
    private renderCaption(dayPickerProps) {
        return (
            <CaptionElement
                {...dayPickerProps}
                withYearPicker={this.props.withYearPicker}
                handleYearChange={this.handleYearChange}
                noTab={this.props.noTab}
            />
        );
    }

    private renderNavBar(navBarProps) {
        return <NavbarElement {...navBarProps} noTab={this.props.noTab} />;
    }

    private handleYearChange(e) {
        if (!this.props.onMonthChange) {
            return;
        }
        this.props.onMonthChange(moment(this.props.month).year(e.target.value).toDate());
    }
}

export default PureRenderIgnoreFunctions(Daypicker);
