import React from 'react';

import {type Dictionary} from 'web-app/util/typescript';
import LocalStorageService from 'web-app/services/local-storage';

export type StatLocalStorageKey<T extends string> = Dictionary<T, string>;

/**
 * A hook that returns the current query params with keys matching the passed array.
 *
 * @param {Array.<string>} statLocalStorageKey - List of localStorage names who's values to get.
 * @returns {\{statLocalStorageKey: string | null\}} - Map of the provided keys with their
 * corresponding values. Null if empty.
 *
 * Usage:
 * ```js
 * const queryVariables = useStatLocalStorage(['foo'])
 * console.log(queryVariables.foo) // Outputs "bar"
 * ```
 *
 * You can also assign new values and it will be stored in the search params:
 *
 * Usage:
 * ```js
 * const queryVariables = useStatLocalStorage(['hello'])
 * queryVariables.hello = 'world'
 * console.log(new URLSearchParams(window.location.search).hello) // Outputs "world"
 * ```
 *
 */

type Options = {
    useUserContext?: boolean;
};

export function useStatLocalStorage<T extends string>(statLocalStorageKey: T[], options?: Options) {
    const computedLocalStorage = useComputeLocalStorage(statLocalStorageKey, options);
    return React.useMemo(
        () =>
            new Proxy(computedLocalStorage, {
                /**
                 * This proxy handler overwrites the default setting of a value in the returned value here.
                 *
                 * Instead of setting the value in the localStorage, leading to no change, we are taking the
                 * assigned value and instead save it in the search params. This in turn will lead to a
                 * rerun this hook, which in turns rerenders the components using this hook.
                 *
                 * If you want to delete an existing query param, just pass 'undefined'
                 *
                 * @param _ current query parameters object. unused.
                 * @param prop the property the user assigned a new value to
                 * @param newValue the new value
                 * @returns true, which means the assignment was successful
                 */
                set(_: StatLocalStorageKey<T>, prop: string, newValue: string) {
                    if (options?.useUserContext) {
                        LocalStorageService.saveForUserContextEncrypted(prop, newValue);
                    } else {
                        LocalStorageService.saveForUser(prop, newValue);
                    }
                    return true;
                },
            }),
        [computedLocalStorage, options],
    );
}

const useComputeLocalStorage = <T extends string>(
    statLocalStorageKey: T[],
    options?: Options,
): StatLocalStorageKey<T> => {
    return React.useMemo(() => {
        return statLocalStorageKey.reduce((reduction, localStorageKey) => {
            reduction[localStorageKey] = options?.useUserContext
                ? LocalStorageService.getForUserContextEncrypted(localStorageKey)
                : LocalStorageService.getForUser(localStorageKey);
            return reduction;
        }, {} as StatLocalStorageKey<T>);
    }, [statLocalStorageKey, options]);
};
