import React from 'react';

export type ListContextType = {
    /**
     * When `true` forces the `display: contents` CSS property on child ListRow components.
     */
    forceDisplayContents: boolean;

    /**
     * Indicates whether the list is displayed on a mobile device (< tabletPortrait)
     */
    device: 'mobile' | 'non-mobile';
};

export const ListContext = React.createContext<ListContextType>({
    forceDisplayContents: false,
    device: 'non-mobile',
});

export const useListContext = () => {
    return React.useContext(ListContext);
};
