import React from 'react';
import {type Components, type Theme} from '@mui/material/styles';
import MuiFormLabel, {type FormLabelProps as MuiFormLabelProps, formLabelClasses} from '@mui/material/FormLabel';

import {getEmphasizedFontWeight} from 'modern-famly/components/util';

export type FormLabelProps = Pick<MuiFormLabelProps, 'children' | 'disabled' | 'required' | 'htmlFor'>;

export const FormLabel: React.FC<FormLabelProps> = props => <MuiFormLabel {...props} error={false} focused={false} />;

/**
 * MUI Theming
 */
const fontVariant = 'body';
export const LabelThemeConfiguration: Components<Theme>['MuiFormLabel'] = {
    styleOverrides: {
        root: ({theme}) => ({
            fontWeight: getEmphasizedFontWeight(fontVariant),
            color: theme.modernFamlyTheme.colorPalette.n400,
            fontSize: theme.typography[fontVariant].fontSize,
            lineHeight: theme.typography[fontVariant].lineHeight,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',

            [`&.${formLabelClasses.disabled}`]: {
                color: theme.modernFamlyTheme.colorPalette.n200,

                [`.${formLabelClasses.asterisk}`]: {
                    color: theme.modernFamlyTheme.colorPalette.n200,
                },
            },

            [`.${formLabelClasses.asterisk}`]: {
                color: theme.modernFamlyTheme.colorPalette.r400,
            },
        }),
    },
};
