import React from 'react';
import PropTypes from 'prop-types';

import {G, SVG} from '../svg';
import {type IconComponent} from './icon-component';

export const SuccessIcon: IconComponent<any> = ({appearance, fill, ...rest}) => (
    <SVG {...rest} viewBox="0 0 24 24">
        <G appearance={appearance} fill={fill}>
            <path d="M8.926 18.652L4 13.725l1.767-1.768 3.074 3.074L17.959 5l1.849 1.682z" />
        </G>
    </SVG>
);

SuccessIcon.propTypes = {
    size: PropTypes.number,
    appearance: PropTypes.string,
    fill: PropTypes.string,
};

export default SuccessIcon;
