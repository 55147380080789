import {createMatchSelector} from 'connected-react-router';
import {Map, type List} from 'immutable';

import createSelector from 'web-app/util/custom-create-selector';
import StaffEntity from 'signin-app/entities/staff';
import {type RootState} from 'signin-app/redux/main-reducer';
import {type IEmployee, type IStaffGroup} from 'signin-app/entities/staff/model';
import {type EntityMap} from 'web-app/react/entities/factory/selectors-factory';
import {type PinEmployee, type PinLoginResponse, type EmployeeCheckinOverview} from 'signin-app/pin/models';
import {isPinApp} from 'signin-app/login/selectors';
import {pinLoginResponse} from 'signin-app/pin/selectors';

export interface EmployeeIdProps {
    match?: {
        params: {
            employeeId: string;
        };
    };
    employeeId?: string;
}

export const getEmployeeIdFromProps = (_: RootState, props: EmployeeIdProps) => {
    const {match, employeeId} = props;

    if (match) {
        return match.params?.employeeId;
    } else if (employeeId) {
        return employeeId;
    }

    return '';
};

// Pin Employee selectors

export const pinEmployees = createSelector<RootState, PinLoginResponse | undefined, List<PinEmployee> | undefined>(
    pinLoginResponse,
    pinPeople => pinPeople && pinPeople.employees && pinPeople.employees.map(e => e.employee),
);

const employeeOverview = createSelector<
    RootState,
    EmployeeIdProps,
    PinLoginResponse | undefined,
    string,
    EmployeeCheckinOverview | undefined
>(
    (state: RootState) => state.pin.people,
    getEmployeeIdFromProps,
    (pinPeople, employeeId) => {
        const employeeCheckinOverview =
            pinPeople && pinPeople.employees.find(employeeCheckin => employeeCheckin.employee.id === employeeId);
        return employeeCheckinOverview;
    },
);

export const getPinEmployee = createSelector<
    RootState,
    EmployeeIdProps,
    EmployeeCheckinOverview | undefined,
    EmployeeCheckinOverview['employee'] | undefined
>(employeeOverview, overview => {
    return overview && overview.employee;
});

// Employee entity + common selectors

export interface ExtendedEmployee extends IEmployee {
    staffGroupId: string;
}

export const makeGetAllEmployees = () => (staffEntityMap: EntityMap<IStaffGroup>) => {
    return staffEntityMap.reduce<Map<string, ExtendedEmployee>>((acc, mapItem) => {
        for (const employee of mapItem.employees) {
            const extendedEmployee = {
                ...employee,
                staffGroupId: mapItem.groupId,
            };
            acc = acc.set(employee.id, extendedEmployee);
        }
        return acc;
    }, Map());
};

const getAllEmployees = makeGetAllEmployees();

export const makeEmployeeName = () =>
    createSelector<
        RootState,
        EmployeeIdProps,
        boolean,
        EntityMap<IStaffGroup>,
        PinEmployee | undefined,
        string,
        string
    >(
        isPinApp,
        StaffEntity.selectors.entityMap,
        getPinEmployee,
        getEmployeeIdFromProps,
        (pinApp, staffEntityMap, pinEmployee, employeeId) => {
            if (pinApp) {
                return pinEmployee ? pinEmployee.name.fullName : '';
            }

            const allEmployees = getAllEmployees(staffEntityMap);
            const employee = allEmployees.find(employee => employee.id === employeeId);
            return employee ? employee.name.fullName : '';
        },
    );

export const makeStaffSelector = (routePath: string) =>
    createSelector(
        StaffEntity.selectors.entityMap,
        createMatchSelector<RootState, {employeeId?: string}>(routePath),
        (staffEntityMap, match) => {
            if (!match) {
                return null;
            }

            const {employeeId} = match.params;
            let employee;

            for (const staffGroup of staffEntityMap) {
                employee = staffGroup[1].employees.find((employee: IEmployee) => employee.id === employeeId);
                if (employee) {
                    break;
                }
            }

            return employee;
        },
    );
