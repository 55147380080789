import {type RangeValue} from 'web-app/react/components/form-elements/containers/range-picker/range-picker';

export const rangeToString = (value?: RangeValue) => {
    return value ? `${value.lower}|${value.upper}` : undefined;
};

export const stringToRange = (value: string) => {
    const splitValue = typeof value === 'string' && value.includes('|') ? value.split('|') : [undefined, undefined];
    return {
        lower: splitValue[0] || '',
        upper: splitValue[1] || '',
    };
};
