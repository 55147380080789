import React from 'react';
import {List} from 'immutable';
import i18next from 'i18next';
import styled, {css} from 'styled-components';

import {PortalModal} from 'web-app/react/components/modals/modals';
import {waitForFrames} from 'web-app/util/animation';
import {getSpacing, s2, s3, s6} from 'web-app/styleguide/spacing';
import {Base, Flex} from 'web-app/react/components/layout/layout';
import Icon from 'web-app/react/components/icons/legacy/icon';
import GroupButtonCSS, {
    type GroupButtonStyleProps,
} from 'web-app/react/components/form-elements/button-group/button-group-style';
import Button from 'web-app/react/components/form-elements/button/button';
import {Title} from 'web-app/react/components/text/text';
import PlatformHelper from 'web-app/helpers/platform-helper';
import {hasValue} from 'web-app/util/typescript';
import {useBreakpoints} from 'web-app/react/hooks/use-breakpoints';
import {PortalRootId} from 'web-app/react/components/portals/constants';

export const shouldUseActionSheet = () => PlatformHelper.isMobileApp() || PlatformHelper.isLandscapeSize();
export const useActionSheet = () => {
    const {customAtLeastOneLargerThanLandscape} = useBreakpoints();
    return !customAtLeastOneLargerThanLandscape || PlatformHelper.isMobileApp();
};

export interface ActionSheetItem<T extends string> {
    id: T;
    title: string;
    listTitle?: string;
    disabled?: boolean;
    // Either onClick or href should be defined
    onClick?: () => void;
    href?: string;
    type?: string;
    icon?: string | React.ReactNode;
    category?: string;
    roundIcon?: boolean;
}

export interface ActionSheetProps<T extends string> {
    items: List<ActionSheetItem<T>>;
    handleClose?: () => void;
    title?: string;
    onItemClick?: (item: ActionSheetItem<T>) => void;
    customRootId?: string;
    className?: string;
}

type GroupItem<T extends string> = ActionSheetItem<T>['category'] | ActionSheetItem<T>;
function groupItemIsCategory<T extends string>(item: GroupItem<T>): item is ActionSheetItem<T>['category'] {
    return typeof item === 'string';
}

function ActionSheet<T extends string = string>(props: ActionSheetProps<T>) {
    const {customRootId, items, handleClose, onItemClick, className} = props;

    const [shouldRender, setShouldRender] = React.useState(false);

    React.useEffect(() => {
        waitForFrames(() => setShouldRender(true));
    }, []);

    const onClose = React.useCallback(() => {
        setShouldRender(false);

        if (handleClose) {
            handleClose();
        }
    }, [handleClose]);

    const groupedItems = React.useMemo(() => {
        return items
            .groupBy(item => item.category)
            .reduce((reduction, items, category) => {
                return reduction.push(category).concat(items.toList()).filter(hasValue);
            }, List<GroupItem<T>>());
    }, [items]);

    return (
        <ActionSheetPortalModal
            rootId={customRootId || PortalRootId.portalRoot}
            fullScreenOnMobile={false}
            onCloseModal={onClose}
            active={shouldRender}
            className={`${className} ignore-react-onclickoutside`}
            noBackdropOverflow
        >
            <StyledActionSheet>
                {groupedItems.map((item, i) =>
                    groupItemIsCategory(item) ? (
                        <ActionSheetCategoryWrapper padding={s2} key={item || 'default'}>
                            <Title emphasized>{item}</Title>
                        </ActionSheetCategoryWrapper>
                    ) : (
                        <ActionSheetButton
                            key={item.id}
                            button={item}
                            isFirst={i === 0}
                            isLast={i === groupedItems.size - 1}
                            css={ActionButtonStyle}
                            onClose={onClose}
                            onItemClick={onItemClick}
                        />
                    ),
                )}
            </StyledActionSheet>
            <Base marginTop={s2}>
                <ActionSheetButton
                    button={{
                        id: 'cancel',
                        title: i18next.t('cancel'),
                    }}
                    onClose={onClose}
                    isFirst={true}
                    isLast={true}
                    css={CancelButtonStyle}
                />
            </Base>
        </ActionSheetPortalModal>
    );
}

export default ActionSheet;

const ActionSheetCategoryWrapper = styled(Base)`
    background: ${props => props.theme.delimiter};
`;

const ActionSheetPortalModal = styled(PortalModal)<any>`
    && {
        background: transparent;
        margin: 0 auto;
        width: 95%;
        max-width: 360px;
        position: absolute;
        max-height: calc(100% - ${getSpacing(s6)});
        bottom: 12px;
        bottom: calc(12px + env(safe-area-inset-bottom));
        left: 50%;
        transition: transform 0.3s ${props => props.theme.timings.functions.default}, opacity 0.2s linear;
        display: flex;
        flex-direction: column;
        ${props =>
            props.active
                ? css`
                      transform: translate(-50%, 0);
                      opacity: 1;
                  `
                : css`
                      transform: translate(-50%, ${getSpacing(s6)});
                      opacity: 0;
                  `}
    }
`;
const StyledActionSheet = styled.div`
    overflow: auto;
    border-radius: 14px;
    background: ${props => props.theme.invertedText};
    position: relative;
`;
const ActionButtonStyle = css`
    && {
        * {
            font-weight: normal;
        }
    }
`;
const CancelButtonStyle = css`
    &&& {
        border-radius: 14px;
        justify-content: center;
        background: ${props => props.theme.invertedText};
        * {
            color: ${props => props.theme.analogue2};
        }
    }
`;

interface GroupButtonComponentProps<T extends string> {
    button: ActionSheetItem<T>;
    isFirst: boolean;
    isLast: boolean;
    css?: any;
    onClose: () => void;
    onItemClick?: ActionSheetProps<T>['onItemClick'];
}

class ActionSheetButton<T extends string> extends React.PureComponent<GroupButtonComponentProps<T>> {
    public render() {
        const {button, isFirst, isLast, css} = this.props;

        return (
            <StyledActionSheetButton
                data-e2e-id={`action-sheet-button-${button.id}`}
                count={1}
                first={isFirst}
                last={isLast}
                onClick={this.handleClick}
                href={button.href}
                type={button.href ? 'link' : undefined}
                css={css}
                disabled={button.disabled}
            >
                <Flex fullWidth align="center" justify={button.icon ? 'flex-start' : 'center'}>
                    {button.icon ? (
                        <IconContainer round={Boolean(button.roundIcon)} marginRight={s3}>
                            <Icon icon={button.icon} />
                        </IconContainer>
                    ) : null}
                    <Title emphasized ellipsis>
                        {button.title}
                    </Title>
                </Flex>
            </StyledActionSheetButton>
        );
    }

    private handleClick = () => {
        if (this.props.onItemClick) {
            this.props.onItemClick(this.props.button);
        }
        if (this.props.button.onClick) {
            this.props.button.onClick();
        }
        this.props.onClose();
    };
}

const StyledActionSheetButton = styled(Button)<GroupButtonStyleProps>`
    ${GroupButtonCSS};

    &&& {
        &:not(:last-child) {
            border-bottom: 1px solid ${props => props.theme.delimiter};
        }
        background: transparent;
    }
    ${props => props.css};
    height: 62px;
`;

const IconContainer = styled(Flex)<{round: boolean}>`
    ${props =>
        props.round
            ? css`
                  border-radius: 20px;
                  overflow: hidden;
              `
            : ''}
    > * {
        height: 32px;
        width: 32px;
    }
`;
