import React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Stack from '@mui/material/Stack';

import {Box} from 'modern-famly/components/layout/box';
import {Button} from 'modern-famly/components/input';
import {type SystemProps} from 'modern-famly/system';
import {useTranslation} from 'modern-famly/system/use-translation';

export interface ListDrawerProps {
    /**
     * The padding top of the drawer
     */
    paddingTop?: SystemProps['paddingTop'];

    /**
     * The padding x of the drawer
     * Default is 6
     */
    paddingX?: SystemProps['paddingX'];

    /**
     * The padding left of the drawer
     * Default is 6
     */
    paddingLeft?: SystemProps['paddingLeft'];

    /**
     * The padding right of the drawer
     * Default is 6
     */
    paddingRight?: SystemProps['paddingRight'];

    /**
     * If the drawer is open or not
     */
    isOpen: boolean;

    /**
     * The text to show on the close button
     */
    closeButtonText?: string;

    /**
     * Callback when the drawer is closed
     */
    closeDrawer: () => void;

    /**
     * The content of the drawer
     */
    children?: React.ReactNode;
}

export const ListDrawer: React.FC<ListDrawerProps> = props => {
    const defaultCloseButtonText = useTranslation('ListDrawer.defaultCloseButtonText');
    const defaultPaddingX = props?.paddingX ?? 6;
    const closeButtonText = props?.closeButtonText ?? defaultCloseButtonText;

    return (
        <SwipeableDrawer
            anchor={'right'}
            open={props.isOpen}
            onClose={props.closeDrawer}
            onOpen={() => {}}
            PaperProps={{
                sx: {
                    paddingBottom: 'env(safe-area-inset-bottom)',
                },
            }}
        >
            <Stack
                direction="column"
                paddingTop={props?.paddingTop ?? 8}
                paddingLeft={props?.paddingLeft ?? defaultPaddingX}
                paddingRight={props?.paddingRight ?? defaultPaddingX}
                boxSizing="border-box"
                minWidth="375px"
                height="100vh"
                justifyContent="space-between"
            >
                <Box>{props.children}</Box>
                <Box paddingBottom={10}>
                    <Button variant="tertiary" onClick={props.closeDrawer} fullWidth text={closeButtonText} />
                </Box>
            </Stack>
        </SwipeableDrawer>
    );
};
