import {NuffieldHealthTheme as NuffieldHealthMFTheme} from 'modern-famly';

import BaseTheme from './base';

const NuffieldHealthChromeColor = NuffieldHealthMFTheme.colorPalette.brandColor;

const NuffieldHealthTheme = BaseTheme.merge({
    title: 'Nuffield Health Nursery',
    chromeColor: NuffieldHealthChromeColor,
    mf: NuffieldHealthMFTheme,
});

export default NuffieldHealthTheme;
