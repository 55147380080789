import React from 'react';
import ReactDOM from 'react-dom';
import {fromEvent, Observable, type Subscription, type Observer} from 'rxjs';
import equals from 'is-equal-shallow';
import {share, debounceTime} from 'rxjs/operators';

import PlatformHelper from 'web-app/helpers/platform-helper';
import * as Breakpoints from 'web-app/styleguide/breakpoint-helpers';

const resizeEvent = fromEvent(window, 'resize').pipe(share(), debounceTime(250));
const mediaQueries = window.matchMedia('print');
const mediaQueryEvent = Observable.create((observer: Observer<any>) => {
    mediaQueries.addListener(e => observer.next(e));
}).pipe(share());

export interface AvailableStates {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    isLandscape: boolean;
    isPortrait: boolean;
    isPrint: boolean;
    isAtLeastLandscape: boolean;
    isMobileLandscapeAndLarger: boolean;
    isTabletPortraitAndLarger: boolean;
    isTabletLandscapeAndLarger: boolean;
    isLaptopAndLarger: boolean;
    customAtLeastOneLargerThanLandscape: boolean;
}

interface ResponsiveStateFunctions {
    [x: string]: () => boolean;
}

const responsiveStateFunctions: ResponsiveStateFunctions = {
    isMobile: () => PlatformHelper.isMobileSize(),
    isTablet: () => PlatformHelper.isTabletSize(),
    isDesktop: () => PlatformHelper.isDesktopSize(),
    isLandscape: () => PlatformHelper.isLandscapeSize(),
    isPortrait: () => PlatformHelper.isPortraitSize(),
    isAtLeastLandscape: () => PlatformHelper.atLeastLandscape(),
    isMobileLandscapeAndLarger: () => Breakpoints.isMobileLandscapeAndLarger(),
    isTabletPortraitAndLarger: () => Breakpoints.isTabletPortraitAndLarger(),
    isTabletLandscapeAndLarger: () => Breakpoints.isTabletLandscapeAndLarger(),
    isLaptopAndLarger: () => Breakpoints.isLaptopAndLarger(),
    // Custom breakpoints
    customAtLeastOneLargerThanLandscape: () => PlatformHelper.customAtLeastOneLargerThanLandscape(),
};

export type ResponsiveStateValues = {[K in keyof AvailableStates]: boolean};

const fromRequestedStateToValues = (requestedState: Array<keyof AvailableStates>) =>
    requestedState.reduce<ResponsiveStateValues>(
        (reduction, state) =>
            responsiveStateFunctions[state]
                ? {
                      ...reduction,
                      [state]: responsiveStateFunctions[state](),
                  }
                : reduction,
        {} as ResponsiveStateValues,
    );

/**
 * @deprecated Use the `useBreakpoints` hook instead if possible
 */
export default function <TProps>(requestedState: Array<keyof AvailableStates>) {
    // Let's wait for a better type system - Partial is good for now.
    return (Component: React.ComponentType<React.PropsWithChildren<TProps & Partial<AvailableStates>>>) => {
        class ResponsiveComponent extends React.Component<TProps, Partial<AvailableStates>> {
            private mediaSubscription: Subscription | undefined;
            private resizeSubscription: Subscription | undefined;

            constructor(props: TProps) {
                super(props);

                this.state = fromRequestedStateToValues(requestedState);
            }

            public componentDidMount() {
                this.mediaSubscription = mediaQueryEvent.subscribe(this.mediaUpdate);
                this.resizeSubscription = resizeEvent.subscribe(this.resizeUpdate);
            }

            public componentWillUnmount() {
                if (this.mediaSubscription) {
                    this.mediaSubscription.unsubscribe();
                }
                if (this.resizeSubscription) {
                    this.resizeSubscription.unsubscribe();
                }
            }

            public render() {
                const responsiveProps = requestedState.includes('isPrint')
                    ? {
                          isPrint: window.matchMedia('print').matches,
                          ...this.state,
                      }
                    : this.state;

                return <Component {...this.props} {...responsiveProps} />;
            }

            private resizeUpdate = () => {
                const newState = fromRequestedStateToValues(requestedState);
                if (!equals(newState, this.state)) {
                    this.setState(newState);
                }
            };

            private mediaUpdate = () => {
                (ReactDOM as any).flushSync(() => {
                    this.forceUpdate();
                });
            };
        }

        return ResponsiveComponent;
    };
}
