/* eslint-disable consistent-return */
import React from 'react';
import styled from 'styled-components';

import dynamicImport from 'web-app/react/components/higher-order/dynamic-import';
import Arrow from 'web-app/react/components/icons/legacy/arrows';
import PlatformHelper from 'web-app/helpers/platform-helper';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import {imageFragmentToSrcString} from 'web-app/util/typed-image-util';
import {type ImageFragment} from 'web-app/api/shared-fragments/__generated__/image.api-types';

const checkIfFileUploadCarouselImage = img => typeof img === 'object' && typeof img.url === 'string';
export interface FileUploadCarouselImage {
    id: string;
    url: string;
    loading?: boolean;
}

interface CarouselProps {
    renderer: (item: CarouselItem) => React.ReactNode;
    items: CarouselItem[];
    initial?: number | ComplexCarouselItem['id'];
    onChange: (item: number | ComplexCarouselItem['id']) => void;
    arrowFill?: string;
    arrowSize?: number;
    defaultIndexOrId?: string | number;
    settings?: object;
}

interface ImportProps {
    imports: any;
}

export type CarouselImage = string | ImageFragment;
export interface ComplexCarouselItem {
    image?: CarouselImage;
    id: string;
    title: string;
    subtitle?: string;
    secret: undefined;
    link?: string;
    isActionTypeLink?: boolean;
}
const checkIfComplexCarouselItem = img => typeof img === 'object' && typeof img.title === 'string';

interface CarouselVideo {
    videoSrc: string;
}

export const isVideoCarouselItem = (carouselItem: CarouselItem): carouselItem is CarouselVideo => {
    // eslint-disable-next-line no-prototype-builtins
    return typeof carouselItem === 'object' && carouselItem.hasOwnProperty('videoSrc');
};

export type CarouselItem = ComplexCarouselItem | CarouselImage | FileUploadCarouselImage | CarouselVideo;

export const SliderWrapper = styled.div`
    *:focus {
        outline: none;
    }
    img {
        width: 100%;
    }
    .slick-arrow {
        z-index: 1;
    }
    .slick-next {
        right: 8px;
    }
    .slick-prev {
        left: 8px;
    }
`;

const propsToInitialIndex = (props: CarouselProps) => {
    if (props.initial === undefined) {
        return undefined;
    } else if (typeof props.initial === 'number') {
        return props.initial;
    } else if (typeof props.initial === 'string') {
        return props.items.findIndex(item => isComplexCarouselItem(item) && item.id === props.initial);
    }
};
const itemToKey = (item: CarouselItem, i: number) => {
    if (isComplexCarouselItem(item) || isFileUploadCarouselImage(item)) {
        return item.id;
    } else {
        return i;
    }
};

class Carousel extends React.Component<CarouselProps & ImportProps> {
    public state = {initial: propsToInitialIndex(this.props) || 0};

    private settings;
    private carouselRef;

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleBeforeChange = this.handleBeforeChange.bind(this);
        this.handleCarouselRef = this.handleCarouselRef.bind(this);
        const commonSettings = {
            nextArrow: <Arrow direction="east" size={this.props.arrowSize} fill={this.props.arrowFill || 'white'} />,
            prevArrow: <Arrow direction="west" size={this.props.arrowSize} fill={this.props.arrowFill || 'white'} />,
        };
        this.settings = {
            ...commonSettings,
            ...(this.props.settings || {
                infinite: true,
                speed: 200,
                slidesToShow: 1,
                slidesToScroll: 1,
            }),
        };
    }

    public componentDidUpdate(prevProps) {
        const {items, defaultIndexOrId} = this.props;

        // Handle when items change and we need to change the selected index
        // At the moment it just reverts to index 0 or default index/id whenever an item or more are removed
        if (
            (prevProps.items.length > items.length || prevProps.defaultIndexOrId !== defaultIndexOrId) &&
            this.carouselRef
        ) {
            if (defaultIndexOrId && typeof defaultIndexOrId === 'number' && defaultIndexOrId <= items.length - 1) {
                this.carouselRef.slickGoTo(defaultIndexOrId);
                this.handleChange(defaultIndexOrId);
                return;
            } else if (defaultIndexOrId && typeof defaultIndexOrId === 'string') {
                const indexToGoTo = this.props.items.findIndex((item, i) => {
                    if (isComplexCarouselItem(item) || isFileUploadCarouselImage(item)) {
                        return item.id === defaultIndexOrId;
                    } else {
                        return i === 0;
                    }
                });
                if (indexToGoTo > -1) {
                    this.carouselRef.slickGoTo(indexToGoTo);
                    this.handleChange(indexToGoTo);
                    return;
                }
            }
            // Default case if no defaultIndexOrId is passed or if unable to find matching item
            this.carouselRef.slickGoTo(0);
            this.handleChange(0);
        } else if (items.length > prevProps.items.length && this.carouselRef) {
            const lastIndex = items.length - 1;
            this.carouselRef.slickGoTo(lastIndex, true);
            this.handleChange(lastIndex);
        }
    }

    public render() {
        const {items, renderer, imports} = this.props;

        const {Slider} = imports;

        return (
            <SliderWrapper>
                <Slider
                    ref={this.handleCarouselRef}
                    afterChange={PlatformHelper.isMobileApp() ? this.handleChange : undefined}
                    beforeChange={!PlatformHelper.isMobileApp() ? this.handleBeforeChange : undefined}
                    onSwipe={this.handleChange}
                    initialSlide={this.state.initial}
                    {...this.settings}
                >
                    {items.map((item, i) => (
                        <div key={itemToKey(item, i)}>{renderer ? renderer(item) : defaultRenderer(item)}</div>
                    ))}
                </Slider>
            </SliderWrapper>
        );
    }

    private handleCarouselRef(ref) {
        this.carouselRef = ref;
    }

    private handleChange(i: number) {
        const item = this.props.items[i];

        if (!item) {
            return;
        } else if (isComplexCarouselItem(item)) {
            this.props.onChange(item.id);
        } else {
            this.props.onChange(i);
        }
    }

    private handleBeforeChange(oldIndex: number, newIndex: number) {
        this.handleChange(newIndex);
    }
}

export default dynamicImport<CarouselProps>(() => [
    {
        name: 'Slider',
        module: import('react-slick'),
    },
])(Carousel);

export const isStringImage = (item: CarouselItem): item is string => typeof item === 'string';
export const isFileUploadCarouselImage = (item: CarouselItem): item is FileUploadCarouselImage =>
    checkIfFileUploadCarouselImage(item);
export const isComplexCarouselItem = (item: CarouselItem): item is ComplexCarouselItem =>
    checkIfComplexCarouselItem(item);

const defaultRenderer = (item: CarouselItem) => {
    if (isStringImage(item)) {
        return stringRenderer(item);
    } else if (isComplexCarouselItem(item)) {
        return null; // Implement this if you need it
    } else if (isFileUploadCarouselImage(item)) {
        return fileUplaodImageRenderer(item);
    } else if (isVideoCarouselItem(item)) {
        return null; // Implement this if you need it
    } else {
        return imageRenderer(item);
    }
};

const imageRenderer = (image: ImageFragment) => <img src={imageFragmentToSrcString(image)} />;

/**
 * The spinner isn't vertically centered as of implementation on 2020-03-23
 * This is a known shortcoming as we need to ship fast
 */
const fileUplaodImageRenderer = (image: FileUploadCarouselImage) =>
    // eslint-disable-next-line no-extra-boolean-cast
    Boolean(image.loading) ? <Spinner centered /> : <img src={image.url} />;

const stringRenderer = (image: string) => <img src={image} />;
