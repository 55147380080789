import {map} from 'rxjs/operators';

import RESTClient from 'signin-app/api/clients/rest-client';
import {
    ChildCheckout,
    StatusRegistration,
    type IStatusRegistration,
    transformGroups,
    type RawGroup,
} from 'signin-app/child/models';

interface ChildCheckInOptions {
    childId: string;
    pickupRelationId: string;
    pickupTime: string;
    newPickupRelationName?: string;
    groupId?: string;
    pin?: string;
    goHomeWithChildId?: string;
}

interface StatusRegistrationResponse {
    statusRegistrations: IStatusRegistration[];
}

export default class ChildApi {
    public static checkIn(options: ChildCheckInOptions) {
        const {childId, ...params} = options;

        return RESTClient.postAuthenticatedObservable(`v2/children/${childId}/checkins`, params);
    }

    public static checkOut(childId: string, pin?: string) {
        return RESTClient.postAuthenticatedObservable(`v2/children/${childId}/checkout`, {
            pin,
        }).pipe(
            map(response => {
                return new ChildCheckout(response[0]);
            }),
        );
    }

    public static applyStatus(childId: string, statusConfigId: string) {
        return RESTClient.postAuthenticatedObservable(`v2/status/configs/${statusConfigId}/registrations`, {
            childIds: [childId],
            employeeIds: [],
        }).pipe(
            map((response: StatusRegistrationResponse) => {
                return response.statusRegistrations.map(r => new StatusRegistration(r));
            }),
        );
    }

    public static endStatus(childId: string, statusConfigId: string) {
        return RESTClient.postAuthenticatedObservable(`v2/status/configs/${statusConfigId}/registrations/end`, {
            childIds: [childId],
            employeeIds: [],
        }).pipe(
            map((response: StatusRegistrationResponse) => {
                return response.statusRegistrations.map(r => new StatusRegistration(r));
            }),
        );
    }

    public static goHomeWithChildren(childId: string) {
        return RESTClient.getAuthenticatedObservable(`v2/childcheckins/gohomewith`, {
            childId,
        }).pipe(
            map((response: RawGroup[]) => {
                return transformGroups(response);
            }),
        );
    }
}
