import {type QueryHookOptions} from '@apollo/client';

import {FetchDailyTotalsPermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-daily-totals.query';
import {
    type FetchDailyTotalsPermissionsQuery,
    type FetchDailyTotalsPermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-daily-totals.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchDailyTotalsPermissions(
    options: QueryHookOptions<FetchDailyTotalsPermissionsQuery, FetchDailyTotalsPermissionsQueryVariables>,
): ImmerWrappedQueryResult<FetchDailyTotalsPermissionsQuery, FetchDailyTotalsPermissionsQueryVariables> {
    return useStatQuery<FetchDailyTotalsPermissionsQuery, FetchDailyTotalsPermissionsQueryVariables>(
        FetchDailyTotalsPermissions,
        options,
    );
}
