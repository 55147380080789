import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import DayPicker from 'web-app/react/components/form-elements/daypicker/daypicker';

const FormDayPicker = props => {
    const {input, valueFormat, onChange, disabled, modifiers, ...otherProps} = props;

    const {value} = input;

    const momentValue = value ? moment(value, valueFormat) : null;

    const dateValue = momentValue && momentValue.isValid() ? momentValue.toDate() : null;

    const primary = dateValue ? [dateValue] : [];
    const overrideModifiers = {
        ...modifiers,
        primary,
        isFirstInRange: primary,
        isLastInRange: primary,
        disabled: disabled ? () => true : modifiers.disabled,
    };

    return (
        <DayPicker
            {...otherProps}
            month={dateValue}
            modifiers={overrideModifiers}
            numberOfMonths={1}
            handleDayClick={(day, {disabled}) => {
                if (disabled) {
                    return null;
                }

                const dayMoment = day ? moment(day) : null;

                const newValue = dayMoment ? dayMoment.format(valueFormat) : dayMoment;

                if (onChange) {
                    onChange(newValue);
                }
                return input.onChange(newValue);
            }}
        />
    );
};

const dayPickerPropTypes = {
    input: PropTypes.object, // redux-form.
    legendProps: PropTypes.object,
    modifiers: PropTypes.object,
    onChange: PropTypes.func,
    valueFormat: PropTypes.string,
    placeholder: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    hoverEffectOnWeek: PropTypes.bool,
};

const dayPickerDefaultProps = {
    valueFormat: 'YYYY-MM-DD',
};

FormDayPicker.propTypes = dayPickerPropTypes;
FormDayPicker.defaultProps = dayPickerDefaultProps;

export default FormDayPicker;
