import React from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import {Range} from 'immutable';
import styled, {css} from 'styled-components';

import Select from 'web-app/react/components/form-elements/select/select';
import PlatformHelper from 'web-app/helpers/platform-helper';
import TextField from 'web-app/react/components/form-elements/text-field/text-field';
import {getShow12HourClock} from 'web-app/util/moment';
import {type Omit, hasValue} from 'web-app/util/typescript';
import {s1} from 'web-app/styleguide/spacing';
import {Body} from 'web-app/react/components/text/text';
import {timeFormatAgnosticSplitTimeString} from 'web-app/react/components/form-elements/time/time';

const PLACEHOLDER = '--';
const PLACEHOLDER_SEPARATOR = ':';
const MAX_MINUTE_VALUE = 59;
export const CLASSNAME_PREFIX = 'TIMEPICKER';

export const Container = styled.div<{focused?: boolean; disabled?: boolean; passive?: boolean}>`
    background: white;
    display: flex;
    border: 1px solid ${props => props.theme.borderConfiguration.defaultColor};
    align-items: center;
    border-radius: 4px;
    font-size: ${props => props.theme.fontConfiguration.sizes.Caption};
    padding: 4px 4px 4px 2px;
    transition: box-shadow 0.5s, border-color 0.15s ease-out;

    &:hover {
        border-color: ${props => props.theme.borderConfiguration.hover};
    }

    ${props =>
        props.disabled
            ? css`
                  border-color: transparent;
                  background-color: ${props => props.theme.borderConfiguration.disabled};
                  color: ${props => props.theme.textDisabled};

                  .Select.is-disabled {
                      .Select-control {
                          background-color: transparent;
                      }

                      .Select-input {
                          display: none;
                      }
                  }
              `
            : ''}

    ${props =>
        props.focused
            ? css`
                  border-color: ${props => props.theme.borderConfiguration.focus};
              `
            : null}

    ${props =>
        props.passive
            ? css`
                  border-color: transparent;
                  background: transparent;

                  :hover {
                      border-color: transparent;
                  }
              `
            : ''}

    &::placeholder {
        color: ${props => props.theme.textDisabled};
    }

    .${CLASSNAME_PREFIX}_nativeInput {
        padding-right: 0;
        width: auto;
    }

    .${CLASSNAME_PREFIX}_input, .${CLASSNAME_PREFIX}_inputContainer {
        background: transparent;
        border: none;
        width: 26px;
        height: 26px;
        color: ${props => props.theme.text};

        &:focus {
            border: none;
        }

        .Select-arrow-zone {
            display: none;
        }

        &:disabled {
            background: transparent;
            color: inherit;
        }
    }

    .${CLASSNAME_PREFIX}_textInput {
        width: initial;
    }

    .${CLASSNAME_PREFIX}_input {
        padding-right: 0;
    }

    .${CLASSNAME_PREFIX}_spacer {
        flex: 1 1 0%;

        &.${CLASSNAME_PREFIX}_lastSpacer {
            min-width: 5px;
            flex-shrink: 0;
        }
    }

    .${CLASSNAME_PREFIX}_select {
        font-size: ${props => props.theme.fontConfiguration.sizes.Caption};

        .Select-control {
            background: transparent;
            border: none;

            .Select-arrow-zone {
                display: none;
            }

            .Select-value {
                padding: 0 !important;
                min-width: 26px;
                text-align: center;
            }
        }

        .Select-menu-outer {
            width: auto;
            min-width: 60px;
        }
    }

    .${CLASSNAME_PREFIX}_timeOffset {
        .Select-control {
            padding-right: 10px;
        }
        .Select-value {
            width: 25px;
        }
    }

    .${CLASSNAME_PREFIX}_splitter {
        line-height: 25px;
        margin-top: -2px;
    }

    .${CLASSNAME_PREFIX}_arrow {
        height: 6px;
        width: 10px;
    }

    .${CLASSNAME_PREFIX}_unit {
        font-weight: 500;
    }

    .Select {
        .Select-placeholder,
        &.Select--single > .Select-control .Select-value,
        &.Select--single .Select-input > inppu {
            line-height: 26px;
        }

        .Select-input {
            height: 26px;
            line-height: 26px;
        }
    }
`;

export interface LocalTimeValue {
    hours: number;
    minutes: number;
}

export type TimePickerInputValue = LocalTimeValue | null;

export interface TimePickerInputProps {
    value?: LocalTimeValue | null;
    onChange?: (newValue: TimePickerInputValue) => any;
    validMinutes?: number[];
    placeholder?: string;
    id?: string;
    className?: string;
    offsetClassName?: string;
    timePickerClassName?: string;
    disabled?: boolean;
    required?: boolean;
    validHours?: number[]; // hours from 0 to 23
    maxTime?: LocalTimeValue;
    passive?: boolean;
    passivePlaceholder?: string;
}

type HourOrMinute = null | string;
type HourOffset = '0' | '12';

interface Option {
    label: string;
    value: HourOrMinute;
}

interface OffsetOption extends Omit<Option, 'value'> {
    value: HourOffset;
}

interface TimeUnitOption extends Option {
    numberValue: number;
}

interface ComponentState {
    focused: boolean;
    show12HourClock: boolean;
    hourOffsetOptions: OffsetOption[];
    hourOptions: TimeUnitOption[];
    minuteOptions: TimeUnitOption[];
}

const localTimeToMinutes = (time: LocalTimeValue): number => time.hours * 60 + time.minutes;

class TimePicker extends React.PureComponent<TimePickerInputProps, ComponentState> {
    private allHourOptions: ComponentState['hourOptions'];
    private maxTimeInMinutes: number | null;

    constructor(props: TimePickerInputProps) {
        super(props);

        const {value, validMinutes} = props;

        this.onChange = this.onChange.bind(this);
        this.onHoursChange = this.onHoursChange.bind(this);
        this.onMinutesChange = this.onMinutesChange.bind(this);
        this.onHourOffsetChange = this.onHourOffsetChange.bind(this);
        this.onNativeChange = this.onNativeChange.bind(this);
        this.calculateValues = this.calculateValues.bind(this);
        this.formatValue = this.formatValue.bind(this);
        this.getSelect = this.getSelect.bind(this);
        this.getTimeInput = this.getTimeInput.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

        const mutableMoment = moment();

        const firstOffsetOption = mutableMoment.startOf('day').format('A');
        const secondOffsetOption = mutableMoment.endOf('day').format('A');
        const hourOffsetOptions: ComponentState['hourOffsetOptions'] = [
            {
                label: firstOffsetOption,
                value: '0',
            },
            {
                label: secondOffsetOption,
                value: '12',
            },
        ];

        const show12HourClock = getShow12HourClock();
        const hourOffset = value && value.hours > 12 ? '12' : '0';

        const hourLength = show12HourClock ? 12 : 24;
        const hours = Range(0, hourLength).toArray();
        this.allHourOptions = [{label: PLACEHOLDER, value: null} as TimeUnitOption].concat(
            hours.map((_, i) => {
                // We want it to show 12:00 AM and not 00:00 AM
                const labelValue = show12HourClock && i === 0 ? 12 : i;

                return {
                    label: labelValue < 10 ? `0${labelValue}` : `${labelValue}`,
                    value: `${i}`,
                    numberValue: i,
                };
            }),
        );

        this.maxTimeInMinutes = props.maxTime ? localTimeToMinutes(props.maxTime) : null;

        this.state = {
            focused: false,
            hourOffsetOptions,
            hourOptions: this.getHourOptions(show12HourClock ? hourOffset : undefined),
            minuteOptions: this.getMinuteOptions(validMinutes),
            show12HourClock,
        };
    }

    public render() {
        const {hours, minutes, hourOffset} = this.calculateValues(this.props.value);

        if (PlatformHelper.shouldUseNativeInputFields()) {
            return this.getTimeInput(hours, minutes);
        }

        return this.getSelect(hours, minutes, hourOffset);
    }

    private calculateValues(value: TimePickerInputProps['value']) {
        let hours: HourOrMinute = null;
        let minutes: HourOrMinute = null;
        let hourOffset: HourOffset | null = null;

        const {show12HourClock} = this.state;

        if (value) {
            if (PlatformHelper.shouldUseNativeInputFields()) {
                hours = this.formatValue(value.hours);
                minutes = this.formatValue(value.minutes);
            } else {
                hourOffset = (() => {
                    if (show12HourClock) {
                        return value.hours >= 12 ? '12' : '0';
                    } else {
                        return null;
                    }
                })();
                hours = `${hourOffset ? value.hours - Number(hourOffset) : value.hours}`;
                minutes = `${value.minutes}`;
            }
        } else {
            hourOffset = show12HourClock ? '0' : null;
        }

        return {
            hours,
            minutes,
            hourOffset,
        };
    }

    private onChange(
        hours: HourOrMinute,
        minutes: HourOrMinute,
        hourOffset: HourOffset | null = null,
        oldHours?: HourOrMinute,
        oldMinutes?: HourOrMinute,
    ) {
        const {onChange, validMinutes} = this.props;

        const hoursIsEmpty = hours === '';
        const minutesIsEmpty = minutes === '';

        const hoursNumber = Number(hours);
        const minutesNumber = Number(minutes);

        if (onChange) {
            // Don't allow to select a time (HH:mm) that would be over `maxTimeInMinutes`
            if (hasValue(this.maxTimeInMinutes)) {
                const selectedValueInMinutes = (Number(hourOffset) + hoursNumber) * 60 + minutesNumber;
                const maxMinuteOptionValue = validMinutes ? Math.max(...validMinutes) : MAX_MINUTE_VALUE;

                // Reset minute options if all of them are below `maxTimeInMinutes`
                if (selectedValueInMinutes + maxMinuteOptionValue < this.maxTimeInMinutes) {
                    this.setState({minuteOptions: this.getMinuteOptions(validMinutes)});
                } else {
                    const maxHourInLimit = Number(hourOffset) + hoursNumber;
                    const minutesInLimit = this.maxTimeInMinutes - maxHourInLimit * 60;
                    const minuteOptionsInLimit = this.state.minuteOptions.filter(
                        option => option.numberValue <= minutesInLimit,
                    );

                    // If the selected value is over `maxTimeInMinutes`,
                    // change it to the maximum allowed time
                    onChange({
                        hours: maxHourInLimit,
                        minutes:
                            selectedValueInMinutes > this.maxTimeInMinutes
                                ? Math.max(...minuteOptionsInLimit.map(option => option.numberValue))
                                : minutesNumber,
                    });

                    // Show only those minute options that are below `maxTimeInMinute`
                    this.setState({
                        minuteOptions: minuteOptionsInLimit,
                    });

                    return;
                }
            }

            if (
                (hours === null && oldHours !== null) ||
                (minutes === null && oldMinutes !== null) ||
                (hours === '' && minutes === undefined)
            ) {
                onChange(null);
                return;
            } else if (
                (hoursIsEmpty && minutesIsEmpty) ||
                (hoursIsEmpty && minutesNumber === 0) ||
                (minutesIsEmpty && hoursNumber === 0)
            ) {
                onChange({
                    hours: 0,
                    minutes: 0,
                });
                return;
            } else if (hourOffset && Number(hourOffset)) {
                onChange({
                    hours: hoursNumber + Number(hourOffset),
                    minutes: minutesNumber,
                });
            } else {
                onChange({
                    hours: hoursNumber,
                    minutes: minutesNumber,
                });
            }
        }
    }

    private formatValue(value: number) {
        const returnVal = value || 0;

        if (returnVal < 10) {
            return `0${returnVal}`;
        }

        return `${returnVal}`;
    }

    private getSelect(hours, minutes, hourOffset) {
        const {id, className, required, offsetClassName, timePickerClassName, disabled, passive, passivePlaceholder} =
            this.props;

        const {hourOffsetOptions, hourOptions, minuteOptions, focused} = this.state;

        if (passive) {
            return (
                <Container passive>
                    {!hasValue(hours) && !hasValue(minutes) && hasValue(passivePlaceholder) ? (
                        <Body>{passivePlaceholder}</Body>
                    ) : (
                        <React.Fragment>
                            <Body paddingRight={s1}>{this.formatValue(hours)}</Body>
                            <div className={`${CLASSNAME_PREFIX}_splitter`}>{PLACEHOLDER_SEPARATOR}</div>
                            <Body paddingLeft={s1}>{this.formatValue(minutes)}</Body>
                            {hourOffset ? (
                                <Body paddingLeft={s1}>
                                    {hourOffsetOptions.find(option => option.value === hourOffset)?.label ?? null}
                                </Body>
                            ) : null}
                        </React.Fragment>
                    )}
                </Container>
            );
        }

        return (
            <Container id={id} disabled={disabled} focused={focused} className={className}>
                <Select
                    required={required}
                    disabled={disabled}
                    value={hours}
                    clearable={false}
                    onChange={this.onHoursChange}
                    options={hourOptions}
                    scrollMenuIntoView={true}
                    containerClassName={`${CLASSNAME_PREFIX}_inputContainer`}
                    className={classNames(
                        `${CLASSNAME_PREFIX}_input`,
                        `${CLASSNAME_PREFIX}_select`,
                        timePickerClassName,
                    )}
                    placeholder={PLACEHOLDER}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    hideMoreLabel={true}
                />
                <div className={`${CLASSNAME_PREFIX}_splitter`}>{PLACEHOLDER_SEPARATOR}</div>
                <Select
                    required={required}
                    disabled={disabled}
                    value={minutes}
                    clearable={false}
                    onChange={this.onMinutesChange}
                    options={minuteOptions}
                    scrollMenuIntoView={true}
                    containerClassName={`${CLASSNAME_PREFIX}_inputContainer`}
                    className={classNames(
                        `${CLASSNAME_PREFIX}_input`,
                        `${CLASSNAME_PREFIX}_select`,
                        timePickerClassName,
                    )}
                    placeholder={PLACEHOLDER}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    hideMoreLabel={true}
                />
                <div className={`${CLASSNAME_PREFIX}_spacer`} />
                {hourOffset ? (
                    <Select
                        required={required}
                        disabled={disabled}
                        value={hourOffset}
                        clearable={false}
                        onChange={this.onHourOffsetChange}
                        options={hourOffsetOptions}
                        scrollMenuIntoView={true}
                        className={classNames(
                            `${CLASSNAME_PREFIX}_select`,
                            `${CLASSNAME_PREFIX}_timeOffset`,
                            offsetClassName,
                        )}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        hideMoreLabel={true}
                    />
                ) : null}
            </Container>
        );
    }

    private onHoursChange(option) {
        const {hours, minutes, hourOffset} = this.calculateValues(this.props.value);

        this.onChange(option ? option.value : '', minutes, hourOffset, hours, minutes);
    }

    private onMinutesChange(option) {
        const {hours, minutes, hourOffset} = this.calculateValues(this.props.value);

        this.onChange(hours, option ? option.value : '', hourOffset, hours, minutes);
    }

    private onHourOffsetChange(option) {
        const {value, validHours} = this.props;

        const {hours, minutes} = this.calculateValues(value);

        let newHours = hours;

        if (validHours) {
            this.setState({
                hourOptions: this.getHourOptions(option.value),
            });

            // AM
            if (option.value === '0' && !validHours.includes(Number(hours))) {
                const validAMHour = validHours.find(h => h < 12);
                newHours = validAMHour ? String(validAMHour) : null;
            }
            // PM
            else if (option.value === '12' && !validHours.includes(Number(hours) + 12)) {
                const validPMHour = validHours.find(h => h >= 12);
                if (!validPMHour) {
                    newHours = null;
                } else {
                    newHours = validPMHour === 12 ? String(validPMHour) : String(validPMHour - 12);
                    newHours = newHours === '12' ? '0' : newHours;
                }
            }
        }

        this.onChange(newHours, minutes, option ? option.value : '', hours, minutes);
    }

    private onNativeChange(e: React.ChangeEvent<HTMLInputElement>) {
        const [newHours, newMinutes] = e.target.value.split(timeFormatAgnosticSplitTimeString);
        this.onChange(newHours, newMinutes);
    }

    private getTimeInput(hours, minutes) {
        const {className, required, disabled} = this.props;
        const value = hours && minutes ? `${hours}:${minutes}` : '';

        return (
            <TextField
                type="time"
                value={value}
                className={classNames(`${CLASSNAME_PREFIX}_nativeInput`, className)}
                required={required}
                disabled={disabled}
                placeholder={`${PLACEHOLDER}${PLACEHOLDER_SEPARATOR}${PLACEHOLDER}`}
                onChange={this.onNativeChange}
            />
        );
    }

    private handleFocus() {
        this.setState({
            focused: true,
        });
    }

    private handleBlur() {
        this.setState({
            focused: false,
        });
    }

    private getHourOptions(hourOffset?: HourOffset) {
        const {validHours} = this.props;

        if (!validHours || !validHours.length) {
            return this.allHourOptions;
        }
        // get valid AM hours
        if (hourOffset === '0') {
            const validHoursAM = validHours.filter(h => h < 12);
            return this.allHourOptions.filter(opt => {
                const optionValue = opt.numberValue;
                return optionValue !== undefined && validHoursAM.includes(optionValue);
            });
        }
        // get valid PM hours
        else if (hourOffset === '12') {
            const validHoursPM = validHours.filter(h => h >= 12);

            return this.allHourOptions.filter(opt => {
                const optionValue = opt.numberValue;
                return (
                    optionValue !== undefined &&
                    validHoursPM.includes(optionValue === 12 ? 12 : optionValue + Number(hourOffset))
                );
            });
        }
        // timepicker uses 24 hour military format
        else {
            return this.allHourOptions.filter(
                opt => opt.numberValue !== undefined && validHours.includes(opt.numberValue),
            );
        }
    }

    private getMinuteOptions(validMinutes: TimePickerInputProps['validMinutes']): TimeUnitOption[] {
        const minutes = Range(0, 60).toArray();
        const labelOption = {label: PLACEHOLDER, value: null} as TimeUnitOption;
        const allMinuteOptions: ComponentState['minuteOptions'] = minutes.map((_, minute) => ({
            label: minute < 10 ? `0${minute}` : `${minute}`,
            value: `${minute}`,
            numberValue: minute,
        }));

        const validMinuteOptions = validMinutes
            ? allMinuteOptions.filter(opt => validMinutes.includes(opt.numberValue))
            : allMinuteOptions;

        return [labelOption, ...validMinuteOptions];
    }
}

export default TimePicker;
