import LocalStorageService from 'web-app/services/local-storage';
import generateUUID from 'web-app/util/uuid';

const getInstallationId = () => {
    const installationIdKey = 'installationId';
    let installationId = LocalStorageService.getFromLocalStorage(installationIdKey);

    if (!installationId) {
        installationId = generateUUID();
        LocalStorageService.saveToLocalStorage(installationIdKey, installationId);
    }

    return installationId;
};

export default getInstallationId;
