import React from 'react';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {type StatPermissions} from './stat-permissions';
import {useEmployeePermissions} from './use-employee-permissions';

export function useInstitutionPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['institution'] & {loading: boolean} {
    const {loading, ...employees} = useEmployeePermissions(payload);
    return React.useMemo(
        () => ({
            loading,
            employees,
        }),
        [employees, loading],
    );
}
