import {createSelector, type ParametricSelector} from 'reselect';
import {type List} from 'immutable';
import {createMatchSelector} from 'connected-react-router';

import ChildrenEntity from 'signin-app/entities/children';
import {type RootState} from 'signin-app/redux/main-reducer';
import {isPrefetching} from 'signin-app/components/private-route/selectors';
import {groups} from 'signin-app/groups/selectors';
import {isPinApp} from 'signin-app/login/selectors';
import {type PinLoginResponse, type ChildCheckinOverview, type PinChild, type PinEmployee} from 'signin-app/pin/models';
import {pinLoginResponse} from 'signin-app/pin/selectors';
import ChildPickupBehaviorIds from 'web-app/behaviors/child-pickup-behavior-ids';
import RoutesMap from 'signin-app/routes/routes-map';
import {pinEmployees} from 'signin-app/employee/selectors';

import {type Relation} from './reducer';

const getChildIdFromProps = (_: RootState, props: ChildIdProps) => {
    const {childId, child, match} = props;

    if (childId) {
        return childId;
    } else if (child) {
        return child.id;
    } else if (match) {
        return match.params.childId;
    }

    return '';
};

// PIN Child selectors

const childOverview = createSelector(pinLoginResponse, getChildIdFromProps, (pinPeople, childId) => {
    const childCheckin = pinPeople
        ? pinPeople.children.find(childCheckin => childCheckin.child.id === childId)
        : undefined;
    return childCheckin;
});

const childBehaviors = createSelector(childOverview, overview => overview && overview.behaviors);

export const canSelectOtherChildsParent = createSelector(childBehaviors, behaviors =>
    Boolean(
        behaviors && behaviors.find(behavior => behavior.id === ChildPickupBehaviorIds.ShowOtherParentsPickupOption),
    ),
);

export const requiresPickupInfo = createSelector(childBehaviors, behaviors =>
    Boolean(behaviors && behaviors.find(behavior => behavior.id === ChildPickupBehaviorIds.RequiresPickupInfo)),
);

export const pinChildren = createSelector<RootState, PinLoginResponse | undefined, List<PinChild> | undefined>(
    pinLoginResponse,
    pinPeople => pinPeople && pinPeople.children && pinPeople.children.map(childCheckin => childCheckin.child),
);

export const getPinChild = createSelector(childOverview, overview => {
    return overview && overview.child;
});

export const isChildCheckedIn = createSelector(childOverview, overview => {
    return Boolean(overview && overview.child.checkedIn);
});

export const childPinRelations = createSelector<RootState, ChildIdProps, ChildCheckinOverview | undefined | null, any>(
    childOverview,
    overview => {
        return overview && overview.relations;
    },
);

export const getPinChildren = createSelector<RootState, PinLoginResponse | undefined, List<PinChild> | undefined>(
    pinLoginResponse,
    pinPeople => {
        return pinPeople && pinPeople.children.map(childOverview => childOverview.child);
    },
);

export const getChildIndex = createSelector<
    RootState,
    ChildIdProps,
    List<PinChild> | undefined,
    string,
    number | undefined
>(getPinChildren, getChildIdFromProps, (pinChildren, childId) => {
    if (pinChildren) {
        return pinChildren.findIndex(child => child.id === childId);
    }
    return undefined;
});

const getNextPerson = createSelector<
    RootState,
    ChildIdProps,
    List<PinChild> | undefined,
    number | undefined,
    List<PinEmployee> | undefined,
    PinChild | PinEmployee | undefined
>(getPinChildren, getChildIndex, pinEmployees, (pinChildren, childIndex, pinEmployees) => {
    const employeesArray = pinEmployees?.toArray();
    const employee = employeesArray ? employeesArray[0] : null;
    if (childIndex && childIndex < 0) {
        return undefined;
    }
    if (pinChildren && childIndex !== undefined && !pinChildren.get(childIndex + 1) && employee) {
        return employee;
    }
    if (pinChildren && childIndex !== undefined) {
        return pinChildren.get(childIndex + 1);
    }
    return undefined;
});

const hasProperty = (obj: any, property): obj is typeof PinChild | PinEmployee => {
    return property in obj;
};

export const nextPersonUrl = createSelector<RootState, ChildIdProps, PinChild | PinEmployee | undefined, string>(
    getNextPerson,
    nextPerson => {
        const isEmployee = nextPerson ? hasProperty(nextPerson, 'employeeId') : false;
        if (nextPerson && isEmployee) {
            return `/employee/${nextPerson.id}/${nextPerson.checkedIn ? 'checkout' : 'checkin'}`;
        }
        if (nextPerson) {
            return `/child/${nextPerson.id}/${nextPerson.checkedIn ? 'checkout' : 'checkin'}`;
        }
        return RoutesMap.pin;
    },
);

export const isFetchingChild = (state: RootState): boolean => state.child.isFetching;

export const childRelations = (state: RootState): List<Relation> => state.child.relations;

export interface ChildIdProps {
    childId?: string;
    match?: {
        params: {
            childId: string;
        };
    };
    child?: {
        id: string;
        pickupRelationId: string | null;
    };
}

// ENTITIY child + common
export const relations = createSelector(childRelations, childPinRelations, (relations, pinRelations) => {
    return pinRelations ? pinRelations : relations;
});

export const childName = createSelector<RootState, {id: string; childId: string}, boolean, any, any, string>(
    isPinApp,
    ChildrenEntity.selectors.getById,
    getPinChild,
    (pinApp, entityChild, pinChild) => {
        const child = pinApp ? pinChild : entityChild;
        return child ? child.name.firstName : '';
    },
);

export const isFetching = createSelector(
    isFetchingChild,
    isPrefetching,
    (fetchingChild, prefetching) => fetchingChild || prefetching,
);

export const makeChildSelector = (routePath: string) => {
    const matchSelector = createMatchSelector<RootState, {childId?: string}>(routePath);

    return createSelector(ChildrenEntity.selectors.entityMap, matchSelector, (childrenEntityMap, match) => {
        if (!match) {
            return null;
        }

        const {childId} = match.params;
        const child = childrenEntityMap.find(child => child.id === childId);
        return child;
    });
};

export const childsGroupId = createSelector(
    ChildrenEntity.selectors.entityMap,
    getChildIdFromProps,
    (childrenEntityMap, childId) => {
        const child = childrenEntityMap.find(child => child.id === childId);
        return child ? child.groupId : '';
    },
);

export const requirePickupInfo = createSelector(
    childsGroupId,
    groups,
    requiresPickupInfo,
    (groupId, allGroups, pickupInfoRequired) => {
        const group = allGroups.find(group => group.groupId === groupId);
        return pickupInfoRequired || Boolean(group && group.requirePickupInfo);
    },
);
export const currentChildGroupId: ParametricSelector<RootState, {id: string}, string> = createSelector(
    ChildrenEntity.selectors.getById,
    child => {
        if (child) {
            return child.lastCheckIn ? child.lastCheckIn.groupId : child.groupId;
        }
        return '';
    },
);
