import {gql} from '@apollo/client';

export const GetInstitutions = gql`
    query GetInstitutions {
        institutions {
            institutionId
            managedFeatures {
                featureId
                enabled
            }
            title
            behaviors {
                behaviorId
            }
            privacyPolicyUrl
            profileImage {
                url
            }
            settings {
                settingGroups {
                    groups {
                        __typename
                    }
                }
            }
            timeZone
            identificationNumber
            details {
                behaviors {
                    behaviorId
                }
                address {
                    __typename
                    city
                    street
                    zip
                    country
                    state
                }
                contactPerson {
                    __typename
                    fullName
                }
                phone
                ean
                email
                vat
                socialMedia {
                    __typename
                    facebook
                    instagram
                    twitter
                }
                description
            }
            addressFormatting {
                format
            }
        }
    }
`;
export const GetFoodItems = gql`
    query GetFoodItems($institutionId: InstitutionId!) {
        meals {
            foodItems(institutionId: $institutionId) {
                id
                title
            }
        }
    }
`;

const ApiAccessTokenSettings = gql`
    fragment ApiAccessTokenSettings on ApiAccessTokenSettings {
        canCreate
        canRead
        canUpdate
        canDelete
    }
`;

export const GetApiAccessTokenSettings = gql`
    query GetApiAccessTokenSettings($siteSetId: SiteSetId!) {
        roles {
            apiAccessTokens {
                settings(siteSetId: $siteSetId) {
                    ...ApiAccessTokenSettings
                }
            }
        }
    }
    ${ApiAccessTokenSettings}
`;

export const GetFinanceBehaviors = gql`
    query GetFinanceBehaviors($institutionSetId: InstitutionSetId!) {
        finance {
            integration {
                info(institutionSetId: $institutionSetId) {
                    behaviors {
                        behaviorId
                        __typename
                        ... on BehaviorXeroIntegration {
                            tenant {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const ToggleFeatures = gql`
    mutation ToggleFeatures($institutionId: InstitutionId!, $features: [FeatureState!]!) {
        institutions {
            managedFeatures {
                setFeatures(institutionId: $institutionId, features: $features) {
                    enabled
                    featureId
                }
            }
        }
    }
`;

export const ManagedUserFeatures = gql`
    query ManagedUserFeatures {
        me {
            features {
                list {
                    features {
                        enabled
                        feature
                    }
                }
            }
        }
    }
`;

export const EnableUserFeatures = gql`
    mutation EnableUserFeatures($features: [UserFeatureType!]!) {
        me {
            features {
                enable(features: $features) {
                    features {
                        enabled
                        feature
                    }
                }
            }
        }
    }
`;
export const DisableUserFeatures = gql`
    mutation DisableUserFeatures($features: [UserFeatureType!]!) {
        me {
            features {
                disable(features: $features) {
                    features {
                        enabled
                        feature
                    }
                }
            }
        }
    }
`;

export const UpdateSocialLinksAndDescription = gql`
    mutation UpdateSocialLinksAndDescription(
        $institutionId: InstitutionId!
        $facebookLink: URI
        $instagramLink: URI
        $twitterLink: URI
        $description: String
    ) {
        institutions {
            socialMedia {
                updateFacebook(institutionId: $institutionId, link: $facebookLink)
                updateInstagram(institutionId: $institutionId, link: $instagramLink)
                updateTwitter(institutionId: $institutionId, link: $twitterLink)
            }

            description {
                updateDescription(institutionId: $institutionId, description: $description)
            }
        }
    }
`;

const SettingScopedFragment = gql`
    fragment SettingScopedFragment on SettingsScoped {
        key
        scope
        type
        options
        value {
            ... on SettingValueBoolean {
                __typename
                booleanValue
            }
            ... on SettingValueString {
                stringValue
                __typename
            }
            ... on SettingValueFloat {
                floatValue
                __typename
            }
            ... on SettingValueJson {
                jsonValue
                __typename
            }
        }
    }
`;

export const GetSettingsForInstitution = gql`
    query GetSettingsForInstitution($institutionId: InstitutionId!) {
        institutions(institutionId: $institutionId) {
            settingsScoped {
                ...SettingScopedFragment
            }
        }
    }
    ${SettingScopedFragment}
`;

export const UpdateInstitutionSettings = gql`
    mutation UpdateInstitutionSettings($institutionId: UniqueId!, $settings: [SettingInput!]!) {
        settings {
            setSettings(scope: INSTITUTION, targetId: $institutionId, settings: $settings) {
                ...SettingScopedFragment
            }
        }
    }
    ${SettingScopedFragment}
`;

export const GetCurriculums = gql`
    query GetCurriculums(
        $institutionSetIds: [InstitutionSetId!]!
        $curriculumType: CurriculumType!
        $observationType: ObservationType
    ) {
        childDevelopment {
            curriculums {
                listToggles(
                    institutionSetIds: $institutionSetIds
                    curriculumType: $curriculumType
                    observationType: $observationType
                ) {
                    curriculumType
                    observationType
                    curriculum {
                        title
                        id
                        abbr
                    }
                    enabled
                    order
                }
            }
        }
    }
`;

export const ToggleCurriculums = gql`
    mutation ToggleCurriculums($institutionSetId: InstitutionSetId!, $toggles: [CurriculumToggleUpdate!]!) {
        childDevelopment {
            curriculums {
                updateToggles(institutionSetId: $institutionSetId, toggles: $toggles) {
                    curriculum {
                        id
                        title
                    }
                    enabled
                    observationType
                    curriculumType
                }
            }
        }
    }
`;

export const GetAssessmentLimitation = gql`
    query GetAssessmentLimitation {
        limitationSettings {
            packageLimitationSettings {
                childProfile {
                    assessmentLimitation
                }
            }
        }
    }
`;

export const GetSignInBehaviours = gql`
    query GetSignInBehaviours($siteIds: [SiteId!]) {
        qrSignin {
            siteSettings(siteIds: $siteIds) {
                siteSettings {
                    siteId
                    isEnabled
                    canManage
                }
            }
        }
    }
`;

export const GetWebhooksStatus = gql`
    query GetWebhooksStatus($siteSetId: SiteSetId!) {
        webhooks {
            status(siteSetId: $siteSetId) {
                isEnabled
            }
        }
    }
`;

export const GetDataTransferStatus = gql`
    query GetDataTransferStatus($siteSetId: SiteSetId!) {
        dataTransfer {
            v1 {
                config {
                    isEnabled(siteSetId: $siteSetId)
                }
            }
        }
    }
`;
