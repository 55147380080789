import React from 'react';
import {type OperationVariables, type UpdateQueryOptions, type QueryResult} from '@apollo/client';
import {produce, type Draft} from 'immer';

// Override `updateQuery` to match the fact that it will now be called with `Draft<TData>`
export type ImmerWrappedQueryResult<TData, TVariables> = Omit<QueryResult<TData, TVariables>, 'updateQuery'> & {
    updateQuery: (mapFn: (cache: Draft<TData>, options: UpdateQueryOptions<TVariables>) => TData) => void;
};

/**
 * Wrapper for the (resilient) query hook, which replaces the native updateQuery function with
 * immer, so we can use immutable data without all the spreading of the current cache values.
 *
 * The current, mutable cache value gets wrapped in the immer.produce function and the mapping
 * function normally passed to the `updateQuery` function gets forwarded the wrapped,
 * now immutable datastructure.
 *
 * This allows changing values in the mapping function by directly assigning values to the
 * `cache` object properties instead of having to spread the (possibly deep nested) cache objects with the
 * sole purpose of creating new objects (aka. new shallow), so apollo notices the new values
 * by its shallow comparision
 *
 * @param queryResult return value of the `useResilientQuery`
 * @returns the same queryResult, just that we can now use immutable data in the `updateQuery`.
 * @example
 *
 * ```
 * const {updateQuery} = useImmerQueryWrapper(useResilientQuery(SomeQuery));
 * ```
 *
 * @example
 *
 * this is without the immer wrapper:
 *
 * ```ts
 * const {updateQuery} = useResilientQuery(SomeQuery);
 *
 * subscribeToMutationResult(mutationResult => {
 *   updateQuery(mutableCache => {
 *     return {
 *       ...mutableCache,
 *       deep: {
 *         ...mutableCache.deep,
 *         nested: {
 *           ...mutableCache.deep.nested,
 *           object: {
 *             ...mutationResult.object
 *           }
 *         }
 *       }
 *     }
 *   })
 * })
 * ```
 *
 * and this is the same update with the immer wrapper:
 *
 * ```ts
 * const {updateQuery} = useImmerQueryWrapper(useResilientQuery(SomeQuery));
 *
 * subscribeToMutationResult(mutationResult => {
 *   updateQuery(immerCache => {
 *     immerCache.deep.nested.object = mutationResult.object;
 *     return immerCache;
 *   })
 * })
 * ```
 */
export function useImmerQueryWrapper<TData = any, TVariables = OperationVariables>(
    queryResult: QueryResult<TData, TVariables>,
): ImmerWrappedQueryResult<TData, TVariables> {
    const {updateQuery} = queryResult;
    const updateQueryMemo = React.useCallback(
        mapFn => {
            updateQuery((cache, options) => {
                return produce(cache, draft => {
                    // this cast needs to happen due to typings being off.
                    // it is safe to do so, details: https://github.com/famly/app/pull/13468#issuecomment-1198020349
                    mapFn(draft, options);
                });
            });
        },
        [updateQuery],
    );
    return React.useMemo(
        () => ({
            ...queryResult,
            updateQuery: updateQueryMemo,
        }),
        [queryResult, updateQueryMemo],
    );
}
