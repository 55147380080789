import {type QueryHookOptions} from '@apollo/client';

import {FetchStaffHoursExactAttendancePermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-staff-hours-exact-attendance-permissions.query';
import type {
    FetchStaffHoursExactAttendancePermissionsQuery,
    FetchStaffHoursExactAttendancePermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-staff-hours-exact-attendance-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchStaffHoursExactAttendancePermissions(
    options: QueryHookOptions<
        FetchStaffHoursExactAttendancePermissionsQuery,
        FetchStaffHoursExactAttendancePermissionsQueryVariables
    >,
): ImmerWrappedQueryResult<
    FetchStaffHoursExactAttendancePermissionsQuery,
    FetchStaffHoursExactAttendancePermissionsQueryVariables
> {
    return useStatQuery<
        FetchStaffHoursExactAttendancePermissionsQuery,
        FetchStaffHoursExactAttendancePermissionsQueryVariables
    >(FetchStaffHoursExactAttendancePermissions, options);
}
