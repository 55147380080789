import gql from 'graphql-tag';

export const CustomRegistrationFormDetails = gql`
    fragment CustomRegistrationFormDetails on CustomRegistrationFormDetails {
        formType
        formName
        id
        locale
        versionId
        sections {
            ...CustomRegistrationFormSection
        }
    }
`;
