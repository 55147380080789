import React from 'react';
import shallowCompare from 'react-addons-shallow-compare';

export function PureRender<TProps = {}>(ComposedComponent: React.ComponentType<React.PropsWithChildren<TProps>>) {
    class InnerComponent extends React.PureComponent<TProps> {
        public render() {
            return <ComposedComponent {...this.props} />;
        }
    }

    return InnerComponent;
}

export function PureRenderIgnoreFunctions<TProps = {}>(
    ComposedComponent: React.ComponentType<React.PropsWithChildren<TProps>>,
) {
    class InnerComponent extends React.Component<TProps> {
        public shouldComponentUpdate(nextProps: Readonly<TProps>, nextState: Readonly<any>) {
            const instance = {
                props: excludeFunctions(this.props),
                state: excludeFunctions(this.state),
            };
            return shallowCompare(instance, excludeFunctions(nextProps), excludeFunctions(nextState));
        }

        public render() {
            return <ComposedComponent {...this.props} />;
        }
    }

    return InnerComponent;
}

/*
 * Helpers
 */

const excludeFunctions = obj => {
    const result = {};

    for (const prop in obj) {
        if (typeof obj[prop] !== 'function') {
            result[prop] = obj[prop];
        }
    }

    return result;
};
