import {ApolloProvider} from '@apollo/client';
import React from 'react';
import {Provider} from 'react-redux';
import {ThemeProvider} from 'styled-components';

export const StyledComponentsThemeWrapper = (InnerComponent, theme) => props =>
    (
        <ThemeProvider theme={theme}>
            <InnerComponent {...props} />
        </ThemeProvider>
    );

export const ReduxContextWrapper = (InnerComponent, store) => props => {
    return (
        <Provider store={store}>
            <InnerComponent {...props} />
        </Provider>
    );
};

export const ApolloContextWrapper = (InnerComponent, apolloClient) => props =>
    (
        <ApolloProvider client={apolloClient}>
            <InnerComponent {...props} />
        </ApolloProvider>
    );
