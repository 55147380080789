import gql from 'graphql-tag';

import {StatPermissionCustomFormsEnrollment} from '../fragment/stat-permission-custom-forms-enrollment.fragment';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchCustomFormsEnrollmentPermissions = gql`
    query FetchCustomFormsEnrollmentPermissions($institutionSetId: SiteSetId!) {
        attendance {
            management {
                customRegistrationForm {
                    enrollment(siteSetId: $institutionSetId) {
                        ...StatPermissionCustomFormsEnrollment
                    }
                }
            }
        }
    }
    ${StatPermissionCustomFormsEnrollment}
`;
