import {type QueryHookOptions} from '@apollo/client';
import React from 'react';

import {InstitutionsTimezone} from '../../graphql/queries/institutitons-timezone.query';
import {
    type InstitutionsTimezoneQuery,
    type InstitutionsTimezoneQueryVariables,
} from '../../graphql/queries/__generated__/institutitons-timezone.query.api-types';
import {useStatLocalStorage} from '../../utils/use-stat-local-storage';
import {type ImmerWrappedQueryResult} from '../use-immer-query-wrapper';
import {useStatGuardedQuery} from './use-stat-guarded-query';

const institutionTzLocalStorageKey = id => `timezone-info-${id}`;

/**
 * Fetches the institution's timezone and locale data and stores it in the LocalStorage.
 *
 * The fetched information should not update during the lifetime of an institution, so this
 * is safe to do.
 *
 * @param options the query options for the institution timezone query
 * @returns the localeData with institution timezone and locale for the given institution
 */
export function useInstitutionsTimezoneQuery(
    options: QueryHookOptions<InstitutionsTimezoneQuery, InstitutionsTimezoneQueryVariables>,
): ImmerWrappedQueryResult<InstitutionsTimezoneQuery, InstitutionsTimezoneQueryVariables> {
    const key = institutionTzLocalStorageKey(options.variables?.institutionId);
    const localStorageValues = useStatLocalStorage([key]);

    const tzData = React.useMemo(
        () => (localStorageValues[key] ? JSON.parse(localStorageValues[key] as string) : undefined),
        [key, localStorageValues],
    );

    const queryResult = useStatGuardedQuery<InstitutionsTimezoneQuery, InstitutionsTimezoneQueryVariables>(
        'unrestricted',
        InstitutionsTimezone,
        {...options, skip: options.skip ?? Boolean(tzData)},
    );

    React.useEffect(() => {
        if (!queryResult.loading && queryResult.data) {
            localStorageValues[key] = JSON.stringify(queryResult.data.institutions[0]);
        }
    });

    const tzDataMemoed = React.useMemo(
        () => ({
            ...queryResult,
            data: {
                institutions: [{institutionId: options.variables?.institutionId as string, ...tzData}],
            },
        }),
        [options.variables?.institutionId, queryResult, tzData],
    );

    if (tzData) {
        return tzDataMemoed;
    }

    return queryResult;
}
