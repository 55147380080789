import {type ActionCreator, type Dispatch} from 'redux';

import {type Action, type ActionDescription, type DispatchableActionDescription} from 'web-app/util/redux/action-types';

type PayloadCreator<Payload> = (...args: any[]) => Payload;

const createActionCreator = <Payload>(
    type: string,
    payloadCreator?: (...args: any[]) => Payload,
    metaCreator?: (...args: any[]) => any,
): ActionCreator<Action<Payload>> => {
    const actionCreator = (...args: any[]) => {
        // Not the prettiest way of handling undefined payloadCreator but necessary for backwards compatibility
        const fallback: PayloadCreator<Payload> = payload => payload;
        const payload = payloadCreator ? payloadCreator(...args) : fallback(...args);

        let meta;
        if (metaCreator) {
            meta = metaCreator(...args);
        }

        const action: Action<Payload> = {
            type,
            payload,
            meta,
            error: payload instanceof Error,
        };
        return action;
    };

    actionCreator.toString = () => {
        return type.toString();
    };

    return actionCreator;
};

const internalLogError = (args: any[], isDevelopment: boolean) => {
    if (isDevelopment) {
        const errorObject = {
            message: args[0]?.message,
            stack: args[0]?.stack,
        };
        // tslint:disable-next-line:no-console
        console.error('The failed action-creator was invoked with\n', ...args);
        // tslint:disable-next-line:no-console
        console.error('The following action was created\n', errorObject);
    }
};

const createFailedActionCreator = <Payload>(
    isDevelopment: boolean,
    type: string,
    payloadCreator?: PayloadCreator<Payload>,
    metaCreator?: (...args: any[]) => any,
) => {
    return (...args: any[]) => {
        internalLogError(args, isDevelopment);
        return createActionCreator<Payload>(type, payloadCreator, metaCreator)(...args);
    };
};

const createActionDescription = <Payload>(
    dispatch: (args: any) => any,
    type: string,
    payloadCreator?: PayloadCreator<Payload>,
    metaCreator?: (...args: any[]) => any,
) => {
    const actionCreator = createActionCreator<Payload>(type, payloadCreator, metaCreator);

    return {
        type,
        action: actionCreator,
        dispatch: (...args: any[]) => dispatch(actionCreator(...args)),
    };
};

export interface APIActions<ActionCreatorPayload, SuccessCreatorPayload, FailedCreatorPayload, AbortedCreatorPayload> {
    action: DispatchableActionDescription<ActionCreatorPayload>;
    success: DispatchableActionDescription<SuccessCreatorPayload>;
    failed: ActionDescription<FailedCreatorPayload>;
    aborted: DispatchableActionDescription<AbortedCreatorPayload>;
}

export type CreateAction = <ActionCreatorPayload, SuccessCreatorPayload, FailedCreatorPayload, AbortedCreatorPayload>(
    type: string,
    action: PayloadCreator<ActionCreatorPayload>,
    success?: PayloadCreator<SuccessCreatorPayload>,
    failed?: PayloadCreator<FailedCreatorPayload>,
    aborted?: PayloadCreator<AbortedCreatorPayload>,
) => APIActions<ActionCreatorPayload, SuccessCreatorPayload, FailedCreatorPayload, AbortedCreatorPayload>;

/* Takes dispatch argument so actions for any store
 * created by createAction can be dispatched directly
 */
export const makeCreateAction =
    (dispatch: (args: any) => any, isDevelopment: boolean) =>
    <ActionCreatorPayload, SuccessCreatorPayload, FailedCreatorPayload, AbortedCreatorPayload>(
        type: string,
        action: PayloadCreator<ActionCreatorPayload>,
        success?: PayloadCreator<SuccessCreatorPayload>,
        failed?: PayloadCreator<FailedCreatorPayload>,
        aborted?: PayloadCreator<AbortedCreatorPayload>,
    ): APIActions<ActionCreatorPayload, SuccessCreatorPayload, FailedCreatorPayload, AbortedCreatorPayload> => {
        const failedType = `${type}_FAILED`;

        return {
            action: createActionDescription(dispatch, type, action),
            success: createActionDescription(dispatch, `${type}_SUCCESS`, success),
            failed: {
                type: failedType,
                action: createFailedActionCreator<FailedCreatorPayload>(isDevelopment, failedType, failed),
            },
            aborted: createActionDescription(dispatch, `${type}_ABORTED`, aborted),
        };
    };

export interface ActionDescriptionTree {
    [name: string]: ActionDescription<any> | ActionDescriptionTree;
}
export interface ActionCreatorTree {
    [name: string]: ActionCreator<any> | ActionCreatorTree;
}

export const isActionDescription = <A>(object: any): object is ActionDescription<A> => {
    return 'type' in object;
};

export const bindActionCreators = (actions: ActionDescriptionTree, dispatch: Dispatch<Action<any>>) => {
    return Object.keys(actions).reduce((actionCreators, key) => {
        const actionObj = actions[key];

        if (isActionDescription(actionObj)) {
            actionCreators[key] = (...args: any[]) => dispatch(actionObj.action(...args));
        } else if (typeof actionObj === 'object' && key !== 'default') {
            const actionDescriptionTree = actionObj as ActionDescriptionTree;
            actionCreators[key] = bindActionCreators(actionDescriptionTree, dispatch);
        }

        return actionCreators;
    }, {} as ActionCreatorTree);
};
