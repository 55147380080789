import React from 'react';

type PropsFunc<TProps> = (props: TProps) => void;

export default function lifecycle<TProps = {}>(
    initializeFromProps?: PropsFunc<TProps>,
    cleanUpFromProps?: PropsFunc<TProps>,
) {
    return (Component: React.ComponentType<React.PropsWithChildren<TProps>>): React.ComponentClass<TProps> => {
        class InnerComponent extends React.Component<TProps> {
            public componentDidMount() {
                if (initializeFromProps) {
                    initializeFromProps(this.props);
                }
            }

            public componentWillUnmount() {
                if (cleanUpFromProps) {
                    cleanUpFromProps(this.props);
                }
            }

            public render() {
                return <Component {...this.props} />;
            }
        }

        return InnerComponent;
    };
}
