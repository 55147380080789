import React from 'react';
import styled, {css} from 'styled-components';
import {createStack, Text} from 'modern-famly';

import {useCustomWhitelabelColor} from 'signin-app/components/hooks/use-custom-whitelabel-color';

const ImageContainer = styled(createStack({}))<{size?: number; hasLightBg?: boolean}>`
    width: ${props => (props.size ? `${props.size}px` : '86px')};
    height: ${props => (props.size ? `${props.size}px` : '86px')};
    border-radius: 50%;
    flex-shrink: 0;

    ${props =>
        props.hasLightBg
            ? css`
                  background-color: ${props => props.theme.mf.colorPalette.n0};
              `
            : ''}
`;

const StyledImage = styled.img<{isAbsent?: boolean; isGreyedOut?: boolean}>`
    width: 100%;
    border-radius: 50%;
    ${props =>
        props.isAbsent
            ? css`
                  opacity: 0.15;
                  filter: grayscale(100%);
              `
            : ''}
    ${props =>
        props.isGreyedOut
            ? css`
                  filter: brightness(60%) grayscale(100%);
              `
            : ''}
`;

const TextWrapper = styled(createStack({}))<{hasOpacity?: boolean; customBackgroundColor?: string}>`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    ${props =>
        props.hasOpacity
            ? css`
                  background-color: ${props.customBackgroundColor};
                  opacity: 0.15;
              `
            : ''}
`;

interface PersonImageProps {
    image: string | undefined;
    initials: string;
    size?: number;
    isAbsent?: boolean;
    isNotSignedIn?: boolean;
}

export const PersonImage: React.FC<PersonImageProps> = ({image, initials, size, isAbsent, isNotSignedIn}) => {
    const p500 = useCustomWhitelabelColor('p500');
    return (
        <ImageContainer size={size} hasLightBg={isAbsent}>
            {image ? (
                <StyledImage src={image} isAbsent={isAbsent} isGreyedOut={isNotSignedIn} />
            ) : (
                <TextWrapper hasOpacity={isAbsent} customBackgroundColor={p500}>
                    <Text variant="h5" color="n0">
                        {initials}
                    </Text>
                </TextWrapper>
            )}
        </ImageContainer>
    );
};
