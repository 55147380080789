import React from 'react';

import {G, SVG} from '../svg';
import {defaultPropTypes} from './default-prop-types';
import {type IconComponent} from './icon-component';

export const AddIcon: IconComponent<any> = ({appearance, fill, ...rest}) => (
    <SVG {...rest} viewBox="0 0 24 24">
        <G appearance={appearance} color={fill}>
            <path d="M10.8,10.8 L7.2,10.8 L7.2,13.2 L10.8,13.2 L10.8,16.8 L13.2,16.8 L13.2,13.2 L16.8,13.2 L16.8,10.8 L13.2,10.8 L13.2,7.2 L10.8,7.2 L10.8,10.8 Z M12,20 C7.581722,20 4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 Z" />
        </G>
    </SVG>
);
AddIcon.propTypes = defaultPropTypes;
AddIcon.isPrivate = true;

export default AddIcon;
