import {hasValue} from 'web-app/util/typescript';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useFetchFtePermissions} from './queries/use-fetch-fte-permissions';
import {type StatPermissions} from './stat-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';

export function useFtePermissions(payload: UseStatPermissionPayload): StatPermissions['fte'] {
    const {institutionSetId, shouldQuery} = payload;
    return useCrudPermissionAdapter(
        useFetchFtePermissions({
            variables: {
                institutionSetId,
            },
            skip: !hasValue(institutionSetId) || !shouldQuery,
        }).data?.attendance.management.customFte,
    );
}
