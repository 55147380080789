import React from 'react';

import {StandaloneCheckbox, type StandaloneCheckboxProps} from 'modern-famly/components/input';

import {ListItem} from './list-item';

export type ListItemCheckboxProps = {
    isSelected: boolean;
    onChange: StandaloneCheckboxProps['onChange'];
};

export const ListItemCheckbox = (props: ListItemCheckboxProps) => {
    return (
        <ListItem px={3} onClick={e => e.stopPropagation()}>
            <StandaloneCheckbox checked={props.isSelected} onChange={props.onChange} size="compact" />
        </ListItem>
    );
};
