import {gql} from '@apollo/client';

// unfortunately, graphql does not allow creating fragments out of nowhere, so we have to go
// with the leave management as base :/
export const StatPermissionFte = gql`
    fragment StatPermissionFte on CustomFteManagementType {
        canEdit
        canView
    }
`;
