import {Record} from 'immutable';

import {makeReducer, match} from 'web-app/util/redux';
import {type GoHomeWithChildrenGroup} from 'signin-app/child/models';

import * as Actions from './actions';

export class GoHomeWithState extends Record<{
    readonly isFetching: boolean;
    readonly selectedGoHomeWithChildId: string;
    readonly groups: GoHomeWithChildrenGroup[];
}>({
    isFetching: false,
    selectedGoHomeWithChildId: '',
    groups: [],
}) {}

export default makeReducer<GoHomeWithState>(
    [
        match(Actions.fetchGoHomeWithChildren, state => {
            return state.merge({
                isFetching: true,
            });
        }),
        match(Actions.fetchGoHomeWithChildrenSuccess, (state, {payload}) => {
            return state.merge({
                isFetching: false,
                groups: payload.response,
            });
        }),
        match(Actions.fetchGoHomeWithChildrenFailed, state => {
            return state.merge({
                isFetching: false,
            });
        }),
        match(Actions.selectGoHomeWithChild, (state, {payload}) => {
            return state.merge({
                selectedGoHomeWithChildId: payload.childId,
            });
        }),
    ],
    new GoHomeWithState(),
);
