import {gql} from '@apollo/client';

// unfortunately, graphql does not allow creating fragments out of nowhere, so we have to go
// with the leave management as base :/
export const StatPermissionLeaveRequest = gql`
    fragment StatPermissionLeaveRequest on LeaveRequestManagementType {
        canApprove
        canView
        canCreate
        canEdit
        canDelete
        canEditSubtype
        managementNotes {
            canCreate
            canView
            canEdit
            canDelete
        }
    }
`;
