import React from 'react';
import {type OperationVariables, type UpdateQueryOptions} from '@apollo/client';
import {produce, type Draft} from 'immer';

import {hasValue} from 'web-app/util/typescript';

import {type ImmerWrappedQueryResult} from './use-immer-query-wrapper';

// Override `updateQuery` to match the fact that it will now be called with `Draft<TData>`
export type ImmerWrappedQueryDelayedUpdateResult<TData, TVariables> = Omit<
    ImmerWrappedQueryResult<TData, TVariables>,
    'updateQuery'
> & {
    updateQuery: (mapFn: (cache: Draft<TData>, options: UpdateQueryOptions<TVariables>) => TData) => void;
};

/**
 * Wraps the result query of the immer wrapped query with a way to "stash" and bulk apply cache updates, if the
 * query to be updated has not returned from the backend, yet.
 *
 * This usually only happens in e2e tests, as the user cannot be quick enough in doing changes on a page before the
 * queries have returned, but on slow internet, it might still happen.
 *
 * This is kind of a race condition, so to say.
 *
 * The way it is solved is by adding all cache updates to a stable referenced array and applying the updates one
 * after the other, once the data from the backend has arrived.
 *
 * This <i>might</i> cause stale states, if multiple cache updates land in the batch update array, but it is
 * very unlikely and can be ignored for now.
 *
 * @param queryResult the query result when wrapping it with immer
 * @returns the same query result, just that it should not be possible to happen that cache updates are applied
 * before the query returns.
 * @author Domi <dr@famly.co>
 */
export function useImmerQueryDelayedUpdateWrapper<TData = any, TVariables = OperationVariables>(
    queryResult: ImmerWrappedQueryResult<TData, TVariables>,
): ImmerWrappedQueryDelayedUpdateResult<TData, TVariables> {
    const {data, updateQuery} = queryResult;
    const batchUpdates = React.useRef<((cache: Draft<TData>, options: UpdateQueryOptions<TVariables>) => TData)[]>([]);

    React.useEffect(() => {
        if (batchUpdates.current.length > 0 && hasValue(data)) {
            updateQuery((cache, options) => {
                // apply the first update manually to have the right type
                let result: TData = batchUpdates.current[0](cache, options);

                // and apply all the following updates "by hand", using another call to the immer functions
                if (batchUpdates.current.length > 1) {
                    batchUpdates.current.slice(1).forEach(update => {
                        result = produce(result, draft => {
                            update(draft, options);
                        });
                    });
                }
                return result;
            });
            // clear the array the easy way
            batchUpdates.current.length = 0;
        }
    }, [data, updateQuery]);

    const updateQueryMemo = React.useCallback(
        mapFn => {
            updateQuery((cache, options) => {
                if (hasValue(cache)) {
                    return mapFn(cache, options);
                } else {
                    batchUpdates.current.push(mapFn);
                    return cache;
                }
            });
        },
        [updateQuery],
    );

    return React.useMemo(
        () => ({
            ...queryResult,
            updateQuery: updateQueryMemo,
        }),
        [queryResult, updateQueryMemo],
    );
}
