import React from 'react';
import MuiStack, {type StackProps as MuiStackProps} from '@mui/material/Stack';

import {createStack, Stack} from 'modern-famly/components/layout';
import {Icon, type ListItemButtonProps} from 'modern-famly/components/data-display';
import {exhaustiveCheck} from 'modern-famly/util';
import {type DataProps, useDataProps} from 'modern-famly/components/util';
import {DropdownButton, StandaloneCheckbox, type StandaloneCheckboxProps} from 'modern-famly/components/input';
import {useTranslation} from 'modern-famly/system/use-translation';

import {getAnchorProps, type AnchorProps} from './anchor-props';

export type MobileListRowProps = React.PropsWithChildren<
    {
        layout: '1' | '1 / 1' | '1 / 2' | '2 / 1' | '2 / 2';
        onClick?: MuiStackProps['onClick'];
        onExpand?: MuiStackProps['onClick'];
        actionButtons?: ListItemButtonProps[];
        checkbox?: Pick<StandaloneCheckboxProps, 'checked' | 'onChange' | 'disabled'> & DataProps;
    } & AnchorProps &
        DataProps
>;

export const MobileListRow = (props: MobileListRowProps) => {
    // When the onExpand prop is present it takes priority over the href prop
    const anchorProps = props.onExpand ? null : getAnchorProps(props);

    const dataProps = useDataProps(props);

    const moreText = useTranslation('MobileListRow.more');

    return (
        <MuiStack
            {...anchorProps}
            direction="row"
            justifyContent="space-between"
            borderRadius="12px"
            sx={theme => ({
                border: `1px solid ${theme.modernFamlyTheme.colorPalette.n75}`,

                // Not exactly sure why, but when adding an href to the row, the text of all the row's list items
                // gets the underline styling by default. This is a workaround to remove it.
                textDecoration: 'none',
            })}
            overflow="hidden"
            alignItems="center"
            onClick={props.onExpand ?? props.onClick}
            {...dataProps}
            padding={3}
            gap={3}
        >
            {props.checkbox ? (
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    ml={-3}
                    mr={-2}
                    // We stop propagation to prevent the row from being clicked when the checkbox is clicked
                    onClick={(e: React.MouseEvent) => e.stopPropagation()}
                >
                    <StandaloneCheckbox {...props.checkbox} size="compact" />
                </Stack>
            ) : null}
            <MuiStack
                gap={2}
                // flexGrow ensures that the content container takes up as much space as possible
                flexGrow={1}
                // minWidth ensures that the contents can ellipsis properly
                minWidth={0}
                // We ignore `onClick` if `onExpand` is present
                sx={{
                    // Not exactly sure why, but when adding an href to the row, the text of all the row's list items
                    // gets the underline styling by default. This is a workaround to remove it.
                    textDecoration: 'none',
                }}
                alignSelf="center"
            >
                <MobileListRowContent layout={props.layout}>{props.children}</MobileListRowContent>
            </MuiStack>
            {/* We only show the action buttons if `onExpand` is not present. Potential action buttons should be shown in the drawer opened by `onExpand`. */}
            {props.actionButtons && !props.onExpand ? (
                <Stack
                    borderLeft="n75"
                    alignSelf="stretch"
                    my={-3}
                    mr={-3}
                    alignItems="center"
                    px={3}
                    onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <DropdownButton
                        data-e2e-class="mobile-list-row-actions-button"
                        variant="transparent"
                        size="compact"
                        icon="more_horiz"
                        options={props.actionButtons}
                        aria-label={moreText}
                    />
                </Stack>
            ) : null}
            {props.onExpand ? <Icon name="arrow_forward_ios" color="n200" size={12} /> : null}
        </MuiStack>
    );
};

const MobileListRowContent = (props: React.PropsWithChildren<Pick<MobileListRowProps, 'layout'>>) => {
    const [first, second, third, fourth] = React.Children.toArray(props.children).filter(React.isValidElement);

    switch (props.layout) {
        case '1':
            return <>{first}</>;
        case '1 / 1':
            return (
                <>
                    {first}
                    {second}
                </>
            );
        case '1 / 2':
            return (
                <>
                    <SubRow>{first}</SubRow>
                    <SubRow>
                        {second}
                        {third}
                    </SubRow>
                </>
            );
        case '2 / 1':
            return (
                <>
                    <SubRow>
                        {first}
                        {second}
                    </SubRow>
                    <SubRow>{third}</SubRow>o
                </>
            );
        case '2 / 2':
            return (
                <>
                    <SubRow>
                        {first}
                        {second}
                    </SubRow>
                    <SubRow>
                        {third}
                        {fourth}
                    </SubRow>
                </>
            );
        default:
            exhaustiveCheck(props.layout);
            return null;
    }
};

const SubRow = createStack({
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 4,
});
