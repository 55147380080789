import {createGlobalStyle} from 'styled-components';

import {media} from 'web-app/styleguide/utils';

export const CLASSNAME_PREFIX = 'LEGACY_MODAL_';

/**
 * In an effort to convert our modals to styled components
 * we decided to throw the towel in the ring and just do a
 * simple-stupid port.
 *
 * We're currently planning on creating new modals. Our hope is to make
 * them from scratch and eventually get rid of this 🚮
 */
export default createGlobalStyle`
    .${CLASSNAME_PREFIX}groupActionModalBackdrop {
        padding: 20px 20px 20px 20px;
        overflow: auto;
    }

    .${CLASSNAME_PREFIX}outsideCloseButton {
        padding: 0;
        position: absolute;
        right: -12px;
        top: -10px;
        z-index: 99;
        background: #332732;
        border-radius: 50%;
        border: 2px solid white;
        box-shadow: 0 0 14px rgba(0, 0, 0, 0.32);
        cursor: pointer;
        display: flex;
        align-items: center;

        .${CLASSNAME_PREFIX}outsideCloseIcon {
            width: 12px;
            padding: 12px;
        }
    }

    .${CLASSNAME_PREFIX}modalBody {
        padding: 16px;
        &.${CLASSNAME_PREFIX}noPadding {
            padding: 0;
        }
    }

    .${CLASSNAME_PREFIX}groupActionModal {
        margin: 60px auto 40px auto;
        max-width: 600px;
        border-radius: 7px;
        position: relative;

        &.${CLASSNAME_PREFIX}fullScreenOnMobile {
            @media screen and (max-width: 49em) {
                margin: 0;
            }
        }

        &.${CLASSNAME_PREFIX}tiny {
            max-width: 400px;
        }

        &.${CLASSNAME_PREFIX}medium {
            max-width: 600px;
        }

        &.${CLASSNAME_PREFIX}big {
            max-width: 800px;
        }

        &.${CLASSNAME_PREFIX}navModal {
            max-width: 780px;
        }

        &.${CLASSNAME_PREFIX}fullscreen {
            width: 100%;
            max-width: 1100px;
            position: fixed;
            overflow: auto;
            top: 40px;
            left: 0;
            padding-bottom: 0;
            bottom: 0;
            margin: 0 auto;
            border-radius: 0;

            @media screen and (min-width: 49em) {
                position: relative;
                min-height: 750px;
                overflow: hidden;
                border-radius: 7px;
            }
        }

        &.${CLASSNAME_PREFIX}custom {
            padding: 0;
            min-height: inherit;
        }

        .${CLASSNAME_PREFIX}modalTopBar {
            position: relative;
            max-width: 100%;
            box-sizing: border-box;
            min-height: 60px;
            background: #332732;
            color: white;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            z-index: 99;

            display: flex;
            align-items: center;
            padding: 0 16px;

            &_children {
                min-width: 0;
                flex-grow: 1;
                display: flex;
                align-items: center;
            }

            .${CLASSNAME_PREFIX}headline {
                font-size: ${props => props.theme.fontConfiguration.sizes.Subheader};
                line-height: 1.2;
                font-weight: 600;
            }
            .${CLASSNAME_PREFIX}subheader {
                font-size: ${props => props.theme.fontConfiguration.sizes.Caption};
                line-height: 1.2;
                color: #999999;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
            }

            &.${CLASSNAME_PREFIX}white {
                background: white;
                color: #333333;
            }

            .${CLASSNAME_PREFIX}closeButtonContainer {
                padding-right: 0;
                flex-shrink: 0;
                margin-right: -16px;
            }
        }

        .${CLASSNAME_PREFIX}closeButton {
            padding: 16px;
            display: flex;
            align-items: center;
        }

        .${CLASSNAME_PREFIX}closeIcon {
            height: 18px;
            width: 18px;
        }

        .${CLASSNAME_PREFIX}closeModal {
            position: absolute;
            top: 0;
            right: 0;
            height: 50px;
            width: 60px;
            background-color: #f7f7f7;
            font-size: ${props => props.theme.fontConfiguration.sizes.Title};
            text-align: center;
            line-height: 50px;
            color: #888;
            border-left: 1px solid #ededed;
            cursor: pointer;
            &:hover {
                background-color: #ededed;
            }
        }
    }

    .${CLASSNAME_PREFIX}cancelButtonContainer {
        display: none;
    }

    .${CLASSNAME_PREFIX}leftMobileElement,
    .${CLASSNAME_PREFIX}rightMobileElement {
        display: none;
    }

    .${CLASSNAME_PREFIX}modalHeaderImage {
        width: 40px;
        height: 40px;
    }

    @media all and (max-width: 800px) {
        .${CLASSNAME_PREFIX}groupActionModalBackdrop {
            padding-left: 20px;
        }
    }

    @media all and (max-width: 49em) {
        .${CLASSNAME_PREFIX}outsideCloseButton {
            right: -4px;
        }

        .${CLASSNAME_PREFIX}groupActionModalBackdrop {
            padding: 5px;
            top: 0;
            bottom: 0;
        }

        .${CLASSNAME_PREFIX}cancelButtonContainer {
            display: block;
        }

        .${CLASSNAME_PREFIX}leftMobileElement,
        .${CLASSNAME_PREFIX}rightMobileElement {
            display: block;
            align-self: center;
        }

        .${CLASSNAME_PREFIX}groupActionModalBackdrop .${CLASSNAME_PREFIX}fullScreenOnMobile {
            max-width: none;
            width: 100%;
            position: fixed;
            overflow: auto;
            top: 0;
            left: 0;
            padding-top: env(safe-area-inset-top);
            padding-bottom: env(safe-area-inset-bottom);
            bottom: 0;
            margin: 0 auto;
            border-radius: 0;
        }

        .${CLASSNAME_PREFIX}groupActionModal {
            .${CLASSNAME_PREFIX}modalTopBar {
                border-radius: 0;
                position: relative;
                min-height: 44px;

                &.${CLASSNAME_PREFIX}showCancelButton,
                &.${CLASSNAME_PREFIX}hasRightMobileElement {
                    .${CLASSNAME_PREFIX}modalTopBar_image {
                        display: none;
                    }
                    .${CLASSNAME_PREFIX}closeButtonContainer {
                        display: none;
                    }
                }
                &.${CLASSNAME_PREFIX}hasRightMobileElement.${CLASSNAME_PREFIX}hasLeftMobileElement {
                    .${CLASSNAME_PREFIX}modalTopBar_children {
                        text-align: center;
                    }
                }

                &.${CLASSNAME_PREFIX}showCancelButton {
                    padding: 0 75px;

                    .${CLASSNAME_PREFIX}cancelButtonContainer,
                    .${CLASSNAME_PREFIX}leftMobileElement,
                    .${CLASSNAME_PREFIX}rightMobileElement {
                        position: absolute;
                        padding: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    .${CLASSNAME_PREFIX}cancelButtonContainer,
                    .${CLASSNAME_PREFIX}leftMobileElement {
                        left: 0;
                    }

                    .${CLASSNAME_PREFIX}rightMobileElement {
                        right: 0;
                    }

                    .${CLASSNAME_PREFIX}modalTopBar_children {
                        justify-content: center;
                        width: 100%;
                        & > div {
                            width: 100%;
                        }
                    }

                    .${CLASSNAME_PREFIX}subheader,
                    .${CLASSNAME_PREFIX}headline {
                        text-align: center;
                    }
                }

                .${CLASSNAME_PREFIX}subheader,
                .${CLASSNAME_PREFIX}headline {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:not(.${CLASSNAME_PREFIX}showCancelButton) {
                    .${CLASSNAME_PREFIX}subheader,
                    .${CLASSNAME_PREFIX}headline {
                        max-width: 360px;
                    }
                }
            }

            &.${CLASSNAME_PREFIX}fixHeaderOnMobile {
                .${CLASSNAME_PREFIX}modalTopBar {
                    position: sticky;
                    top: 0;
                }
            }
        }
    }

    .${CLASSNAME_PREFIX}fitToViewport {
        max-height: 85%;
        display: flex;
        flex-direction: column;

        .${CLASSNAME_PREFIX}modalTopBar {
            width: 100%;
            flex-shrink: 0;
        }

        .${CLASSNAME_PREFIX}modalBody {
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
        }
    }

    ${media.notMobile`
        @-moz-document url-prefix() {
            .${CLASSNAME_PREFIX}fitToViewport {
                height: 85%;
            }
        }
    `}

    ${media.mobile`
        .${CLASSNAME_PREFIX}fitToViewport {
            max-height: none;
            height: 100%;
        }
    `}

    ${media.tablet`
        .${CLASSNAME_PREFIX}groupActionModal.${CLASSNAME_PREFIX}fullscreen {
            max-width: none;
            max-height: none;
            width: 100%;
            position: fixed;
            overflow: auto;
            top: 0;
            left: 0;
            padding-bottom: 0;
            bottom: 0;
            margin: 0 auto;
            border-radius: 0;
        }
    `}

    @media all and (max-width: 500px) {
        .${CLASSNAME_PREFIX}groupActionModal .${CLASSNAME_PREFIX}modalTopBar {
            .${CLASSNAME_PREFIX}subheader,
            .${CLASSNAME_PREFIX}headline {
                max-width: 265px;
            }
        }
    }

    @media all and (max-width: 400px) {
        .${CLASSNAME_PREFIX}groupActionModal .${CLASSNAME_PREFIX}modalTopBar:not(.${CLASSNAME_PREFIX}showCancelButton) {
            .${CLASSNAME_PREFIX}subheader,
            .${CLASSNAME_PREFIX}headline {
                max-width: 240px;
            }
        }
    }

    @media all and (max-width: 350px) {
        .${CLASSNAME_PREFIX}groupActionModal .${CLASSNAME_PREFIX}modalTopBar:not(.${CLASSNAME_PREFIX}showCancelButton) {
            .${CLASSNAME_PREFIX}subheader,
            .${CLASSNAME_PREFIX}headline {
                max-width: 180px;
            }
        }
    }

    @media all and (max-width: 319px) {
        .${CLASSNAME_PREFIX}groupActionModal .${CLASSNAME_PREFIX}modalTopBar:not(.${CLASSNAME_PREFIX}showCancelButton) {
            .${CLASSNAME_PREFIX}subheader,
            .${CLASSNAME_PREFIX}headline {
                max-width: 150px;
            }
        }
    }

    @media all and (max-width: 289px) {
        .${CLASSNAME_PREFIX}groupActionModal .${CLASSNAME_PREFIX}modalTopBar:not(.${CLASSNAME_PREFIX}showCancelButton) {
            .${CLASSNAME_PREFIX}subheader,
            .${CLASSNAME_PREFIX}headline {
                max-width: 100px;
            }
        }
    }
`;
