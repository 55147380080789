import styled, {css} from 'styled-components';

import {media} from 'web-app/styleguide/utils';
import {Base} from 'web-app/react/components/layout/layout';
import {getSpacing, s3, s4, s6} from 'web-app/styleguide/spacing';

interface ContainerProps {
    extraPadding?: boolean;
    square?: boolean;
    noPadding?: boolean;
    noMargin?: boolean;
}

/**
 * @deprecated Any of the additional props on this container component, including the `elevation`
 * are also available on the Box and Stack components
 */
const Container = styled(Base)<ContainerProps>`
    background-color: ${props => props.theme.invertedText};
    box-sizing: border-box;

    ${props =>
        props.square
            ? ''
            : css`
                  border-radius: ${props => props.theme.containerConfiguration.borderRadius};
              `}

    ${props =>
        props.noMargin
            ? css`
                  margin: 0;
              `
            : css`
                  margin-bottom: ${getSpacing(s6)};

                  ${media.mobile`
            margin-bottom: ${getSpacing(s3)};
        `}
              `}

    ${props =>
        props.noPadding
            ? ''
            : css`
                  padding: ${props.extraPadding ? getSpacing(s6) : getSpacing(s4)};
              `}
`;

export default Container;
