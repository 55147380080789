import gql from 'graphql-tag';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchStaffHoursPermissions = gql`
    query FetchStaffHoursPermissions($siteSetId: SiteSetId!, $employeeId: EmployeeId) {
        staffhours {
            management {
                totals: totalsV2(siteSetId: $siteSetId, employeeId: $employeeId) {
                    canView
                    csvExport {
                        canView
                    }
                }
            }
        }
    }
`;
