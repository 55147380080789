import React from 'react';
import {type List} from 'immutable';

import {relations} from 'signin-app/child/selectors';
import {type RootState} from 'signin-app/redux/main-reducer';
import {type Relation} from 'signin-app/child/reducer';
import {selectedPickupRelationId} from 'signin-app/child/check-in/selectors';
import lifecycle from 'web-app/react/components/higher-order/lifecycle';
import {selectPickupRelation} from 'signin-app/child/check-in/actions';
import {compose} from 'web-app/util/typescript';
import {connectState} from 'signin-app/helpers/connect-state';
import {Card} from 'signin-app/groups/group-item';

interface StateProps {
    childRelations: List<Relation>;
    selectedPickupRelationId: string;
}

interface ChildRelationsProps {
    checkedIn: boolean;
    childId: string;
    selectedRelationId?: string | null;
    handleRelationClick: VoidFunction;
}

type Props = StateProps & ChildRelationsProps;

const ChildRelations: React.FC<Props> = props => {
    const {childRelations, selectedPickupRelationId, handleRelationClick} = props;

    const handleClick = async (relationId: string | null) => {
        await selectPickupRelation.dispatch(relationId);
        handleRelationClick();
    };

    return (
        <>
            {childRelations.map(relation => (
                <Card
                    key={relation.relationId}
                    image={relation.image.large}
                    displayTitle={relation.name.firstName}
                    initials={`${relation.name.firstName.charAt(0)}${relation.name.lastName.charAt(0)}`}
                    onClick={() => handleClick(relation.relationId)}
                    selected={selectedPickupRelationId === relation.relationId}
                    dataE2eCardContainerClass="child-person-card-title"
                />
            ))}
        </>
    );
};

const mapStateToProps = (state: RootState, props: ChildRelationsProps) => ({
    childRelations: relations(state, props),
    selectedPickupRelationId: selectedPickupRelationId(state),
});

const initializeFromProps = (props: Props) => {
    const {selectedRelationId, checkedIn} = props;

    if (selectedRelationId && checkedIn) {
        selectPickupRelation.dispatch(selectedRelationId);
    }
};

export default compose(
    connectState<ChildRelationsProps, StateProps, React.ComponentType<ChildRelationsProps & StateProps>>(
        mapStateToProps,
    ),
)
    .with(lifecycle(initializeFromProps))
    .apply(ChildRelations);
