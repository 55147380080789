import React from 'react';
import InputAdornment, {type InputAdornmentProps} from '@mui/material/InputAdornment';
import {styled} from '@mui/material/styles';

import {type TextFieldProps} from './text-field';

export type ImageAdornmentProps = {
    /**
     * The position this adornment should appear relative to the `Input`.
     */
    position: InputAdornmentProps['position'];

    /**
     * The icon to display
     */
    imageUrl: string;

    /**
     * The size of the text field that the adornment sits in
     */
    size: TextFieldProps['size'];
};

export const ImageAdornment = ({position, imageUrl, size}: ImageAdornmentProps) => {
    return (
        <InputAdornment position={position}>
            <AdornmentImage size={size} src={imageUrl} />
        </InputAdornment>
    );
};

const AdornmentImage = styled('img')<{size: ImageAdornmentProps['size']}>`
    width: ${props => (props.size === 'compact' ? '20px' : '24px')};
    height: ${props => (props.size === 'compact' ? '20px' : '24px')};
    border-radius: 50%;
`;
