import React from 'react';

import {Text} from '../text';
import {ListItem} from './list-item';
import {useListContext} from './list-context';

export type ListItemShortTextProps = {
    text: string;
};

export const ListItemShortText = (props: ListItemShortTextProps) => {
    const {device} = useListContext();

    return (
        <ListItem minWidth={0}>
            <Text variant={device === 'mobile' ? 'body-small' : 'body'} color="n400" ellipsis>
                {props.text}
            </Text>
        </ListItem>
    );
};
