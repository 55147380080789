import {type Breakpoint} from '@mui/material/styles';

import {useMediaQuery} from '../../../theming/breakpoints';

export type ListBreakpoint = Exclude<Breakpoint, 'base' | 'mobileLandscape'>;

export const useListMediaQuery = (displayFrom: ListBreakpoint) => {
    const isVisible = useMediaQuery(breakpoints => breakpoints.up(displayFrom));

    return isVisible;
};
