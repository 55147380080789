import React from 'react';

import Spinner from 'web-app/react/components/loading/spinner/spinner';
import {waitForFrames} from 'web-app/util/animation';

interface InternalState {
    mounting: boolean;
}

export default function deferRender<TOriginalProps>(
    Component: React.ComponentType<React.PropsWithChildren<TOriginalProps>>,
    showSpinnerWhileMounting?: boolean,
) {
    return class extends React.PureComponent<TOriginalProps, InternalState> {
        public state = {mounting: true};
        private _isMounted = true;

        constructor(props) {
            super(props);

            this.handleNotMounting = this.handleNotMounting.bind(this);
        }

        public componentWillUnmount() {
            // We do this to make sure we don't update state on an unmounted component
            this._isMounted = false;
        }

        public componentDidMount() {
            waitForFrames(this.handleNotMounting);
        }

        public render() {
            if (this.state.mounting) {
                if (showSpinnerWhileMounting) {
                    return <Spinner margined centered />;
                } else {
                    return null;
                }
            }
            return <Component {...this.props} />;
        }

        private handleNotMounting() {
            if (this._isMounted) {
                this.setState({mounting: false});
            }
        }
    };
}

export function deferRenderWithSpinner<TOriginalProps>(
    Component: React.ComponentType<React.PropsWithChildren<TOriginalProps>>,
) {
    return deferRender(Component, true);
}
