import {type QueryHookOptions} from '@apollo/client';

import {FetchStaffHoursHistoricalDownloadPermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-staff-hours-historical-download-permissions.query';
import {
    type FetchStaffHoursHistoricalDownloadPermissionsQuery,
    type FetchStaffHoursHistoricalDownloadPermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-staff-hours-historical-download-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchStaffHoursHistoricalDownloadPermissions(
    options: QueryHookOptions<
        FetchStaffHoursHistoricalDownloadPermissionsQuery,
        FetchStaffHoursHistoricalDownloadPermissionsQueryVariables
    >,
): ImmerWrappedQueryResult<
    FetchStaffHoursHistoricalDownloadPermissionsQuery,
    FetchStaffHoursHistoricalDownloadPermissionsQueryVariables
> {
    return useStatQuery<
        FetchStaffHoursHistoricalDownloadPermissionsQuery,
        FetchStaffHoursHistoricalDownloadPermissionsQueryVariables
    >(FetchStaffHoursHistoricalDownloadPermissions, options);
}
