import gql from 'graphql-tag';

import {StatPermissionCustomFormsManagement} from '../fragment/stat-permission-custom-forms-management.fragment';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchCustomFormsManagementPermissionsBySiteSetId = gql`
    query FetchCustomFormsManagementPermissionsBySiteSetId($siteSetId: SiteSetId!) {
        attendance {
            management {
                customRegistrationForm {
                    bySiteSetId(siteSetId: $siteSetId) {
                        ...StatPermissionCustomFormsManagement
                    }
                }
            }
        }
    }
    ${StatPermissionCustomFormsManagement}
`;
