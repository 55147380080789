import React from 'react';

import {hasValue} from 'web-app/util/typescript';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useFetchStaffHoursPermissions} from './queries/use-fetch-staff-hours-permissions';
import {type StatPermissions} from './stat-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';

export function useStaffHoursPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['staffHours'] & {loading: boolean} {
    const {institutionSetId, employeeId, shouldQuery} = payload;
    const {loading, data} = useFetchStaffHoursPermissions({
        variables: {
            siteSetId: institutionSetId,
            employeeId,
        },
        skip: !shouldQuery || !hasValue(institutionSetId),
    });
    const crud = useCrudPermissionAdapter(data?.staffhours.management.totals);

    return React.useMemo(
        () => ({
            loading,
            ...crud,
            canUpdate: crud.canRead,
        }),
        [crud, loading],
    );
}
