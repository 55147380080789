import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useStatFeatures} from '../../features/use-stat-features';

/**
 * Do not use this query directly!
 */
export function useStaffingCSVPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['staffSchedule']['header']['export']['staffingCSV'] {
    const {institutionSetId} = payload;
    const [features] = useStatFeatures({
        institutionId: institutionSetId,
    });
    return {
        canCreate: false,
        canRead: features.shiftPlanner.isEnabled,
        canUpdate: features.shiftPlanner.isEnabled,
        canDelete: false,
    };
}
