import React from 'react';
import Box from '@mui/material/Box';
import MuiCheckbox, {type CheckboxProps as MuiCheckboxProps} from '@mui/material/Checkbox';
import MuiFormControlLabel, {type FormControlLabelProps as MuiFormLabelProps} from '@mui/material/FormControlLabel';
import {type Theme, styled} from '@mui/material/styles';
import {useFormControl} from '@mui/material/FormControl';

import {useTheme} from 'modern-famly/theming';
import {FormErrorText, FormHelperText} from 'modern-famly/components/input/form-helper-text/form-helper-text';
import {type ColorKey} from 'modern-famly/theming/colors';
import {type DataProps, useDataProps} from 'modern-famly/components/util';

type Size = 'compact' | 'regular';

export type CheckboxProps = {
    /**
     * ID attribute of the input element.
     */
    id?: string;

    /**
     * The label of the checkbox
     */
    label: React.ReactNode;

    /**
     * The props to be forwarded to the MUI FormLabel describing the input.
     */
    labelProps?: Pick<MuiFormLabelProps, 'htmlFor'>;

    /**
     * If `true`, the component is checked.
     */
    checked?: MuiCheckboxProps['checked'];

    /**
     * Callback fired when the state is changed.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event The event source of the callback.
     * You can pull out the new checked state by accessing `event.target.checked` (boolean).
     */
    onChange?: MuiCheckboxProps['onChange'];

    /**
     * Callback fired when the checkbox is clicked.
     */
    onClick?: MuiCheckboxProps['onClick'];

    /**
     * The checkbox size. Possible values are 'regular' and 'compact'
     */
    size?: Size;

    /**
     * Whether the checkbox is disabled
     */
    disabled?: boolean;

    /**
     * Whether the checkbox should show its error state
     */
    error?: boolean;

    /**
     * Helper text displayed below the label
     */
    helperText?: string;

    /**
     * Message that describes the checkbox' error.
     */
    errorMessage?: string;
} & DataProps;

const getSize = (size: Size) => {
    switch (size) {
        case 'compact':
            return '16px';
        case 'regular':
        default:
            return '24px';
    }
};

const getOutlineSize = (size: Size) => {
    switch (size) {
        case 'compact':
            return '1px';
        case 'regular':
        default:
            return '2px';
    }
};

const color =
    (color: ColorKey) =>
    <T extends {theme: Theme}>(props: T) =>
        props.theme.modernFamlyTheme.colorPalette[color];

const Unchecked = styled('span', {shouldForwardProp: prop => prop !== 'size' && prop !== 'error'})<{
    size: Size;
    error?: CheckboxProps['error'];
}>`
    border-radius: 4px;
    background-color: ${color('n0')};
    border: 1px solid ${color('n200')};

    width: ${props => getSize(props.size)};
    height: ${props => getSize(props.size)};

    .Mui-focusVisible & {
        outline: ${props => getOutlineSize(props.size)} solid ${color('p100')};
        border: 1px solid ${color('p400')};
    }

    .Mui-disabled & {
        background-color: ${color('n75')};
    }

    /* MUI doesn't seem to expose a class representing the error state for checkboxes */
    ${props =>
        props.error
            ? `
                  outline: ${getOutlineSize(props.size)} solid ${props.theme.modernFamlyTheme.colorPalette.r200};
                  border: 1px solid ${props.theme.modernFamlyTheme.colorPalette.r400};
              `
            : ''}
`;

const Checked = styled(Unchecked)`
    background-color: ${color('p400')};
`;

const Checkmark = ({fill}: {fill: string}) => {
    return (
        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.29683 4.44483C1.06809 4.20506 0.688299 4.19612 0.448535 4.42486C0.20877 4.65359 0.19983 5.03339 0.428565 5.27315L2.81028 7.7697C3.05552 8.02677 3.46919 8.01562 3.70023 7.74571L9.42764 1.05463C9.64312 0.802892 9.61373 0.424132 9.36199 0.208648C9.11025 -0.00683625 8.73149 0.0225553 8.51601 0.274296L3.5081 6.12481C3.35407 6.30475 3.07829 6.31219 2.9148 6.14081L1.29683 4.44483Z"
                fill={fill}
            />
        </svg>
    );
};

export type StandaloneCheckboxProps = Pick<
    CheckboxProps,
    'checked' | 'disabled' | 'error' | 'size' | 'onChange' | 'id' | 'onClick'
> &
    DataProps;

export const StandaloneCheckbox: React.FC<StandaloneCheckboxProps> = props => {
    const {id, size = 'regular', error: errorFromProps, disabled: disabledFromProps} = props;

    const {colorPalette} = useTheme();

    const {error: errorFromFormControl, disabled: disabledFromFormControl} = useFormControl() ?? {};

    const error = errorFromProps ?? errorFromFormControl;
    const disabled = disabledFromProps ?? disabledFromFormControl;
    const dataProps = useDataProps(props);

    return (
        <MuiCheckbox
            id={id}
            disableRipple
            checked={props.checked}
            icon={<Unchecked size={size} error={error} />}
            onChange={props.onChange}
            onClick={props.onClick}
            checkedIcon={
                <Checked size={size} error={error}>
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%" width="100%">
                        <Checkmark fill={disabled ? colorPalette.n300 : colorPalette.n0} />
                    </Box>
                </Checked>
            }
            disabled={disabled}
            {...dataProps}
        />
    );
};

export const Checkbox = (props: CheckboxProps) => {
    const {id, labelProps, size = 'regular'} = props;

    const dataProps = useDataProps(props);

    return (
        <MuiFormControlLabel
            htmlFor={labelProps?.htmlFor}
            control={
                <StandaloneCheckbox
                    id={id}
                    size={props.size}
                    disabled={props.disabled}
                    error={props.error}
                    onChange={props.onChange}
                    checked={props.checked}
                    onClick={props.onClick}
                />
            }
            label={
                <>
                    {typeof props.label === 'string' ? <div>{props.label}</div> : props.label}
                    {props.errorMessage ? <FormErrorText error>{props.errorMessage}</FormErrorText> : null}
                    {props.helperText ? <FormHelperText>{props.helperText}</FormHelperText> : null}
                </>
            }
            sx={theme => ({
                alignItems: 'flex-start',
                // By default, MUI sets a marginLeft to 11px,
                // but that leads to the checkbox element getting "pulled out" of its container.
                marginLeft: '-9px',
                // By default, MUI sets a marginRight on the checkbox to 16px, which is not necessary
                marginRight: '0',
                '& .MuiFormControlLabel-label': {
                    fontSize: theme.typography[size === 'compact' ? 'body-small' : 'body'].fontSize,
                    lineHeight: theme.typography[size === 'compact' ? 'body-small' : 'body'].lineHeight,
                    color: theme.modernFamlyTheme.colorPalette.n400,

                    // Pushes down the label such that the main label text is horizontally aligned with the checkbox
                    marginTop: size === 'compact' ? '10px' : '12px',

                    // Removes MUI's label color choice when checkbox is disabled.
                    // In our designs, the label has the same color regardless of disabled state.
                    '&.Mui-disabled': {
                        color: theme.modernFamlyTheme.colorPalette.n300,
                    },
                },
            })}
            {...dataProps}
        />
    );
};
