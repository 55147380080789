import React from 'react';
import PropTypes from 'prop-types';

const BeforeChangeComponent = props => {
    const {InputComponent, beforeChange, input, ...rest} = props;

    const {onChange, ...inputValues} = input;

    const newInput = {
        onChange: value => {
            if (beforeChange) {
                beforeChange(() => onChange(value), value);
            } else {
                onChange(value);
            }
        },
        ...inputValues,
    };
    return <InputComponent input={newInput} {...rest} />;
};
BeforeChangeComponent.propTypes = {
    InputComponent: PropTypes.func,
    beforeChange: PropTypes.func,
    input: PropTypes.object,
};

export default BeforeChangeComponent;
