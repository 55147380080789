import {NFamilyClubTheme as NFamilyClubMFTheme} from 'modern-famly';

import {hexToRGBA} from 'web-app/util/hex-to-rgb';

import BaseTheme from './base';

const NFamilyClubChromeColor = '#0B1A3B';
const NFamilyClubPrimary = NFamilyClubChromeColor;
const NFamilyClubActive = hexToRGBA(NFamilyClubChromeColor, 0.8);
const NFamilyClubBackgroundHover = hexToRGBA(NFamilyClubChromeColor, 0.04);

const NFamilyClubTheme = BaseTheme.merge({
    title: 'NFamilyClub',
    chromeColor: NFamilyClubChromeColor,
    primary: NFamilyClubPrimary,
    active: NFamilyClubActive,
    backgroundHover: NFamilyClubBackgroundHover,
    mf: NFamilyClubMFTheme,
});

export default NFamilyClubTheme;
