import React from 'react';

import {type ColorKey} from 'modern-famly/theming';
import {Text} from 'modern-famly/components/data-display/text';
import {type DataProps, useDataProps} from 'modern-famly/components/util';
import {Stack} from 'modern-famly/components/layout';

const maxUpperBound = 999;

export type NumericPillProps = {
    /**
     * Count to display in the pill
     */
    count: number;

    /**
     * Max number from a range 1–999 to display before showing a plus sign
     * @default 999
     */
    max?: number;

    /**
     * Controls the color of the text in the pill
     * @defalut n400
     */
    color?: ColorKey;

    /**
     * Controls the background color of the pill
     * @default n100
     */
    backgroundColor?: ColorKey;
} & DataProps;

const minimumSize = '24px';

export const NumericPill = (props: NumericPillProps) => {
    const {count, color = 'n400', backgroundColor = 'n100', max = 999} = props;

    const dataProps = useDataProps(props);

    const clampedMax = max < 1 ? maxUpperBound : Math.min(max, maxUpperBound);

    const displayCount = count > clampedMax ? `${clampedMax}+` : count;

    return (
        <Stack
            display="inline-flex"
            direction="row"
            alignItems="center"
            justifyContent="center"
            height={minimumSize}
            minWidth={minimumSize}
            boxSizing="border-box"
            paddingX={2}
            borderRadius={6}
            color={color}
            backgroundColor={backgroundColor}
            {...dataProps}
        >
            <Text variant="body-small">{displayCount}</Text>
        </Stack>
    );
};
