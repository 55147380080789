import {css} from 'styled-components';

import {getSpacing, s3} from 'web-app/styleguide/spacing';

export interface GroupButtonStyleProps {
    first: boolean;
    last: boolean;
    count: number;
    css?: any;
    bordered?: boolean;
    disabled?: boolean;
}

export default css<GroupButtonStyleProps>`
    ${props => css`
        display: flex;
        align-items: center;
        flex-shrink: 0;
        border-radius: 0;
        padding-top: ${getSpacing(s3)};
        padding-bottom: ${getSpacing(s3)};
        width: ${100 / props.count}%;
        position: relative;
        z-index: 2;
        border: none;
        ${props.first
            ? css`
                  &:first-child {
                      border-top-left-radius: ${props.theme.buttonConfiguration.radius}px;
                  }
                  &:last-child {
                      border-top-right-radius: ${props.theme.buttonConfiguration.radius}px;
                  }
              `
            : ''}
        ${props.last
            ? css`
                  &:first-child {
                      border-bottom-left-radius: ${props.theme.buttonConfiguration.radius}px;
                  }
                  &:last-child {
                      border-bottom-right-radius: ${props.theme.buttonConfiguration.radius}px;
                  }
              `
            : ''}
        &:hover, &:focus {
            z-index: 2;
        }
        &&&,
        &&&:focus,
        &&&:hover,
        &&&:active {
            background: ${props.theme.invertedText};
        }

        ${props.bordered
            ? css`
                  border: 1px solid ${props.theme.delimiter};
                  &&:hover {
                      z-index: 3;
                  }
                  /**
                   * Normally we should use "\${StyledComponent} + &&" to target siblings with specific props,
                   * but since this is just a css template we can't do this. I've checked the places where this
                   * is used, and all usages are still OK.
                   */
                  & + && {
                      margin-left: -1px;
                      width: calc(${100 / props.count}% + 1px);
                  }
              `
            : css`
                  &,
                  &:hover,
                  &:focus {
                      border: 0;
                  }
                  /**
                   * Normally we should use "\${StyledComponent} + &&" to target siblings with specific props,
                   * but since this is just a css template we can't do this. I've checked the places where this
                   * is used, and all usages are still OK.
                   */
                  & + && {
                      margin-left: 1px;
                      width: calc(${100 / props.count}% - 1px);
                  }
              `}
    `}
`;
