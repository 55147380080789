import {type Theme} from '../theme';
import {createBaseThemeVariation} from './base';

export const NFamilyClubTheme: Theme = createBaseThemeVariation({
    name: 'nfamilyclub',
    brandColor: 'hsla(215, 75%, 20%, 1)',
    primaryColors: {
        p50: 'hsla(215, 75%, 98%, 1)',
        p75: 'hsla(215, 75%, 97%, 1)',
        p100: 'hsla(215, 75%, 96%, 1)',
        p200: 'hsla(215, 75%, 90%, 1)',
        p300: 'hsla(215, 75%, 70%, 1)',
        p400: 'hsla(215, 75%, 20%, 1)',
        p500: 'hsla(215, 75%, 10%, 1)',
    },
});
