const domain = 'MODALS_CONTAINER';

export interface ModalActionDescription {
    type: string;
}

export const openedType = `${domain}/MODAL_OPENED`;
export const closedType = `${domain}/MODAL_CLOSED`;

export const modalOpened = () => ({
    type: openedType,
});

export const modalClosed = () => ({
    type: closedType,
});
