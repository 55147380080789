import {Record} from 'immutable';

import {makeReducer, match} from 'web-app/util/redux';

import * as Actions from './actions';

export class LoginState extends Record<{
    readonly accessToken: string;
    readonly pinSigninAvailable: boolean;
    readonly qrSigninAvailable: boolean;
    readonly isLoggingIn: boolean;
    readonly siteId: string;
    readonly [x: string]: any;
}>({
    accessToken: '',
    isLoggingIn: false,
    pinSigninAvailable: false,
    qrSigninAvailable: false,
    siteId: '',
}) {}

export default makeReducer<LoginState>(
    [
        match(Actions.login, state => {
            return state.merge({
                isLoggingIn: true,
            });
        }),
        match(Actions.loginSuccess, (state, {payload}) => {
            return state.merge({
                isLoggingIn: false,
                accessToken: payload.accessToken,
                pinSigninAvailable: payload.pinSigninAvailable,
                qrSigninAvailable: payload.qrSigninAvailable,
                siteId: payload.siteId,
            });
        }),
        match(Actions.loginFailed, state => {
            return state.merge({
                isLoggingIn: false,
            });
        }),
        match(Actions.logOutSuccess, () => new LoginState()),
        match(Actions.qrSigninChange, (state, {payload}) => {
            return state.merge({
                qrSigninAvailable: payload.qrSigninEnabled,
            });
        }),
    ],
    new LoginState(),
);
