import React from 'react';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useFetchShiftPlannerPermissions} from './queries/use-fetch-shift-planner-permissions';
import {type StatPermissions} from './stat-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';

/**
 * Do not use this query directly!
 */
export function useShiftPlannerPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['shiftPlanner'] & {loading: boolean} {
    const {institutionSetId, shouldQuery} = payload;
    const {data, loading} = useFetchShiftPlannerPermissions({
        variables: {
            siteSetId: institutionSetId,
        },
        skip: !shouldQuery || !institutionSetId,
    });

    const general = useCrudPermissionAdapter(data?.staffhours.management.shiftplanner);
    const draft = useCrudPermissionAdapter(data?.staffhours.management.shiftplanner?.shift.draft);
    const published = useCrudPermissionAdapter(data?.staffhours.management.shiftplanner?.shift.published);

    return React.useMemo(
        () => ({
            loading,
            general,
            shift: {
                draft,
                published,
            },
        }),
        [draft, general, loading, published],
    );
}
