import latinize from 'latinize';

const extendedFilterOptions = (options, filterValue, excludeOptions, props) => {
    if (props.ignoreAccents) {
        filterValue = latinize(filterValue);
    }

    if (props.ignoreCase) {
        filterValue = filterValue.toLowerCase();
    }

    if (excludeOptions) {
        excludeOptions = excludeOptions.map(i => i[props.valueKey]);
    }

    return options.filter(option => {
        if (option.skipFilter) {
            return true;
        }
        if (excludeOptions && excludeOptions.indexOf(option[props.valueKey]) > -1) {
            return false;
        }
        if (props.filterOption) {
            return props.filterOption.call(this, option, filterValue);
        }
        if (!filterValue) {
            return true;
        }
        let valueTest = String(option[props.valueKey]);
        let labelTest = String(option[props.labelKey]);
        let subtitleTest = String(option.subtitle);
        if (props.ignoreAccents) {
            if (props.matchProp !== 'label') {
                valueTest = latinize(valueTest);
            }
            if (props.matchProp !== 'value') {
                labelTest = latinize(labelTest);
            }
            if (props.matchProp === 'any' && subtitleTest) {
                subtitleTest = latinize(subtitleTest);
            }
        }
        if (props.ignoreCase) {
            if (props.matchProp !== 'label') {
                valueTest = valueTest.toLowerCase();
            }
            if (props.matchProp !== 'value') {
                labelTest = labelTest.toLowerCase();
            }
            if (props.matchProp === 'any' && subtitleTest) {
                subtitleTest = subtitleTest.toLowerCase();
            }
        }

        return props.matchPos === 'start'
            ? (props.matchProp !== 'label' && valueTest.substr(0, filterValue.length) === filterValue) ||
                  (props.matchProp !== 'value' && labelTest.substr(0, filterValue.length) === filterValue) ||
                  (props.matchProp === 'any' &&
                      subtitleTest &&
                      subtitleTest.substr(0, filterValue.length) === filterValue)
            : (props.matchProp !== 'label' && valueTest.indexOf(filterValue) >= 0) ||
                  (props.matchProp !== 'value' && labelTest.indexOf(filterValue) >= 0) ||
                  (props.matchProp === 'any' && subtitleTest && subtitleTest.indexOf(filterValue) >= 0);
    });
};

export default extendedFilterOptions;
