import React from 'react';
import ReactSelect from 'react-select-v1';

/*
 * ReactSelect.Creatable has a problem with ref propety
 * it is solved by wrapping it in a class-based Component
 * https://github.com/JedWatson/react-select/issues/2181
 * solved in react-select v2
 */

class Creatable extends React.Component {
    render() {
        return <ReactSelect.Creatable {...this.props} />;
    }
}

export default Creatable;
