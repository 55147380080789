import React from 'react';

import {LeaveType} from 'web-app/react/leave-types/leave-type';
import {hasValue} from 'web-app/util/typescript';
import {type StatPermissionLeaveRequestFragment} from 'web-app/react/staffing-attendance/graphql/fragment/__generated__/stat-permission-leave-request.fragment.api-types';
import {type StatPermissionLeaveRequestsFragment} from 'web-app/react/staffing-attendance/graphql/fragment/__generated__/stat-permission-leave-requests.fragment.api-types';

import {type ExtendedStatCrudPermissions} from './extended-stat-crud-permissions';
import {type StatPermissions} from './stat-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useFetchLeaveRequestPermissions} from './queries/use-fetch-leave-request-permissions';

/**
 * Do not use this query directly!
 */
export function useLeaveRequestPermissions(payload: UseStatPermissionPayload): StatPermissions['leaveRequests'] {
    const {institutionSetId, employeeId, shouldQuery} = payload;
    const {data: leavePermissionData} = useFetchLeaveRequestPermissions({
        variables: {
            institutionSetId,
            employeeId: employeeId ?? '',
        },
        skip: !hasValue(employeeId) || !hasValue(institutionSetId) || !shouldQuery,
    });
    const permissionFragment: StatPermissionLeaveRequestsFragment | undefined =
        leavePermissionData?.staffhours.management.leaveRequests;
    const sickLeaveCrudPermissions = useCrudPermissionAdapter(permissionFragment?.sick);
    const childSickLeaveCrudPermissions = useCrudPermissionAdapter(permissionFragment?.childSick);
    const vacationLeaveCrudPermissions = useCrudPermissionAdapter(permissionFragment?.holiday);
    const absentLeaveCrudPermissions = useCrudPermissionAdapter(permissionFragment?.absence);

    const absentManagementNotes = useCrudPermissionAdapter(permissionFragment?.absence.managementNotes);
    const sickManagementNotes = useCrudPermissionAdapter(permissionFragment?.sick.managementNotes);
    const vacationManagementNotes = useCrudPermissionAdapter(permissionFragment?.holiday.managementNotes);
    const childSickManagementNotes = useCrudPermissionAdapter(permissionFragment?.childSick.managementNotes);

    const sickLeaveAdditionalPermissions = useAdditionalLeaveRequestPermissionsAdapter(permissionFragment?.sick);
    const childSickLeaveAdditionalPermissions = useAdditionalLeaveRequestPermissionsAdapter(
        permissionFragment?.childSick,
    );
    const vacationLeaveAdditionalPermissions = useAdditionalLeaveRequestPermissionsAdapter(permissionFragment?.holiday);
    const absentLeaveAdditionalPermissions = useAdditionalLeaveRequestPermissionsAdapter(permissionFragment?.absence);

    return React.useMemo(() => {
        return {
            [LeaveType.Sick]: {
                managementNotes: sickManagementNotes,
                ...sickLeaveCrudPermissions,
                ...sickLeaveAdditionalPermissions,
            },
            [LeaveType.ChildSick]: {
                managementNotes: childSickManagementNotes,
                ...childSickLeaveCrudPermissions,
                ...childSickLeaveAdditionalPermissions,
            },
            [LeaveType.Vacation]: {
                managementNotes: vacationManagementNotes,

                ...vacationLeaveCrudPermissions,
                ...vacationLeaveAdditionalPermissions,
            },
            [LeaveType.Absent]: {
                managementNotes: absentManagementNotes,
                ...absentLeaveCrudPermissions,
                ...absentLeaveAdditionalPermissions,
            },
        };
    }, [
        sickManagementNotes,
        sickLeaveCrudPermissions,
        sickLeaveAdditionalPermissions,
        childSickManagementNotes,
        childSickLeaveCrudPermissions,
        childSickLeaveAdditionalPermissions,
        vacationManagementNotes,
        vacationLeaveCrudPermissions,
        vacationLeaveAdditionalPermissions,
        absentManagementNotes,
        absentLeaveCrudPermissions,
        absentLeaveAdditionalPermissions,
    ]);
}

function useAdditionalLeaveRequestPermissionsAdapter(
    request?: StatPermissionLeaveRequestFragment,
): Pick<ExtendedStatCrudPermissions<StatPermissionLeaveRequestFragment>, 'canApprove' | 'canEditSubtype'> {
    return React.useMemo(() => {
        const {canApprove, canEditSubtype} = request ?? {};
        return {
            canApprove: canApprove ?? false,
            canEditSubtype: canEditSubtype ?? false,
        };
    }, [request]);
}
