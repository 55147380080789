import React from 'react';
import styled, {keyframes, css} from 'styled-components';

import {getSpacing, s6, type SpacingIdentity} from 'web-app/styleguide/spacing';
import {hasValue} from 'web-app/util/typescript';

const opacityOff = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

export const SpinnerElement = styled.div`
    animation: ${opacityOff} 1s linear infinite;
    border-radius: 10px;
    position: absolute;
    top: 40%;
    left: 46.5%;
    width: 6%;
    height: 20%;
`;

interface StyledSpinnerProps {
    centered?: boolean;
    margined?: boolean;
    absolute?: boolean;
    inline?: boolean;
    id?: string;
    size?: number | SpacingIdentity;
}

const StyledSpinner = styled('div')<StyledSpinnerProps>`
    position: relative;
    z-index: 1; /* Workaround for https://bugs.chromium.org/p/chromium/issues/detail?id=62363 */

    ${({size}) => {
        if (!hasValue(size)) {
            return '';
        }
        if (typeof size === 'number') {
            return css`
                height: ${size}px;
                width: ${size}px;
            `;
        }
        return css`
            height: ${getSpacing(size)};
            width: ${getSpacing(size)};
        `;
    }}

    ${props =>
        props.centered &&
        css`
            margin: auto;
        `}

    ${props =>
        props.margined &&
        css`
            margin-top: ${getSpacing(s6)};
            margin-bottom: ${getSpacing(s6)};
        `}

    ${props =>
        props.inline &&
        css`
            display: inline-block;
            vertical-align: top;
        `}

    ${props =>
        props.absolute &&
        css`
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -16px;
            margin-left: -16px;
        `}
`;

type SpinnerProps = {
    className?: string;
    color?: string;
    size?: number | SpacingIdentity;
    ['data-e2e-id']?: string;
} & StyledSpinnerProps;

const Spinner: React.FC<React.PropsWithChildren<SpinnerProps>> = ({
    className,
    color,
    size,
    centered,
    margined,
    absolute,
    inline,
    id,
    'data-e2e-id': dataE2eId,
}) => {
    const spinnerElements = new Array(10).fill(0).map((_, i) => {
        const deg = (360 / 10) * i;
        const transform = `rotate(${deg}deg) translate(0,-130%)`;
        const startDelay = `${-0.5 + i * (1 / 10)}s`;
        const style = {
            background: color,
            WebkitTransform: transform,
            transform,
            WebkitAnimationDelay: startDelay,
            animationDelay: startDelay,
        };
        return <SpinnerElement key={i} style={style} />;
    });

    return (
        <StyledSpinner
            className={className}
            size={size}
            centered={centered}
            margined={margined}
            absolute={absolute}
            inline={inline}
            id={id}
            data-e2e-id={dataE2eId}
        >
            {spinnerElements}
        </StyledSpinner>
    );
};
Spinner.defaultProps = {
    color: 'black',
    size: 32,
    centered: false,
    margined: false,
    absolute: false,
    inline: false,
};

export default Spinner;
