import gql from 'graphql-tag';

export const StatPermissionShiftPlannerManagementType = gql`
    fragment StatPermissionShiftPlannerManagementType on ShiftPlannerManagementType {
        canView
        canCreate
        canEdit
        canDelete
    }
`;
