import React from 'react';
import {createPortal} from 'react-dom';

import {captureMessage} from 'web-app/services/sentry';
import {hasValue} from 'web-app/util/typescript';

import {PortalRootId} from './constants';

const Portal: React.FC<
    React.PropsWithChildren<{
        dynamicId?: string;
        rootId?: PortalRootId;
    }>
> = props => {
    const {dynamicId, rootId, children} = props;

    const id = dynamicId || rootId || PortalRootId.portalRoot;

    const [element, setElement] = React.useState<HTMLElement | null>(null);

    /**
     * To ensure that we can reliably select the targetted DOM element we need to defer the query
     * until after current layout operations. Otherwise it is not possible to target elements rendered
     * in the same component.
     */
    React.useLayoutEffect(() => {
        setElement(() => {
            const element = document.getElementById(id);
            if (!hasValue(element)) {
                captureMessage(`Attempted to render React Portal in non-existing DOM element with id "${id}"`);
            }
            return element;
        });
    }, [id]);

    return hasValue(element) ? createPortal(children, element) : null;
};

export default Portal;
