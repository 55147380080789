import gql from 'graphql-tag';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchStaffProfileScheduleWidgetPermissions = gql`
    query FetchStaffProfileScheduleWidgetPermissions(
        $siteSetId: SiteSetId!
        $institutionId: InstitutionId!
        $employeeId: EmployeeId!
    ) {
        staffhours {
            management {
                schedules(siteSetId: $siteSetId, employeeId: $employeeId) {
                    canView
                }
                attendance: attendanceV2(siteSetId: $siteSetId, employeeId: $employeeId) {
                    canView
                }
                totals: totalsV2(siteSetId: $siteSetId, employeeId: $employeeId) {
                    canView
                }
            }
            behaviors(employeeId: $employeeId, institutionId: $institutionId) {
                behaviorId
                __typename
            }
        }
    }
`;
