import React from 'react';
import {type WrappedFieldProps} from 'redux-form';

import RangePicker, {
    type RangeValue,
} from 'web-app/react/components/form-elements/containers/range-picker/range-picker';
import ReactOnMeta from 'web-app/react/components/form-elements/containers/react-on-meta';
import {stringToRange, rangeToString} from 'web-app/react/components/form-elements/containers/range-picker/helpers';

type Props = WrappedFieldProps & {onChange: (value: string | undefined) => void};

class FormDayPickerInput extends React.Component<Props> {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    public render() {
        const {
            input,
            meta, // eslint-disable-line no-unused-vars
            onChange, // eslint-disable-line no-unused-vars
            ...rest
        } = this.props;

        const {value, ...restInput} = input;

        return (
            <ReactOnMeta {...this.props}>
                <RangePicker {...restInput} value={stringToRange(value)} onChange={this.handleChange} {...rest} />
            </ReactOnMeta>
        );
    }
    private handleChange(value?: RangeValue) {
        const stringValue = rangeToString(value);
        if (this.props.onChange) {
            this.props.onChange(stringValue);
        }
        this.props.input.onChange(stringValue);
    }
}

export default FormDayPickerInput;
