import {gql} from '@apollo/client';

import {StatPermissionFte} from '../fragment/stat-permission-fte.fragment';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchFtePermissions = gql`
    query FetchFtePermissions($institutionSetId: InstitutionSetId!) {
        attendance {
            management {
                customFte(siteSetId: $institutionSetId) {
                    ...StatPermissionFte
                }
            }
        }
    }
    ${StatPermissionFte}
`;
