import {Record} from 'immutable';

import TextTypes from './text-types';

type TextTypeMapping = {[K in TextTypes]: string | null};

class FontSizes extends Record<TextTypeMapping>({
    [TextTypes.Display]: null,
    [TextTypes.Headline]: null,
    [TextTypes.Title]: null,
    [TextTypes.Subheader]: null,
    [TextTypes.Body]: null,
    [TextTypes.Caption]: null,
    [TextTypes.Small]: null,
}) {}

class FontLineHeights extends Record<TextTypeMapping>({
    [TextTypes.Display]: null,
    [TextTypes.Headline]: null,
    [TextTypes.Title]: null,
    [TextTypes.Subheader]: null,
    [TextTypes.Body]: null,
    [TextTypes.Caption]: null,
    [TextTypes.Small]: null,
}) {}

class FontCircularPadding extends Record<TextTypeMapping>({
    [TextTypes.Display]: null,
    [TextTypes.Headline]: null,
    [TextTypes.Title]: null,
    [TextTypes.Subheader]: null,
    [TextTypes.Body]: null,
    [TextTypes.Caption]: null,
    [TextTypes.Small]: null,
}) {}

interface IFontConfiguration {
    family: string;
    sizes: FontSizes;
    lineHeights: FontLineHeights;
    fontWeight: string;
    italic: string;
    emphasized: string;
    circularPadding: FontCircularPadding;
}

export class FontConfiguration extends Record<IFontConfiguration>({
    family: '',
    sizes: new FontSizes(),
    lineHeights: new FontLineHeights(),
    fontWeight: '',
    italic: '',
    emphasized: '',
    circularPadding: new FontCircularPadding(),
}) {}

// New look

const BaseFontSizes = new FontSizes({
    [TextTypes.Display]: '40px',
    [TextTypes.Headline]: '32px',
    [TextTypes.Title]: '24px',
    [TextTypes.Subheader]: '20px',
    [TextTypes.Body]: '16px',
    [TextTypes.Caption]: '14px',
    [TextTypes.Small]: '12px',
});

const BaseFontLineHeight = new FontLineHeights({
    [TextTypes.Display]: '48px',
    [TextTypes.Headline]: '40px',
    [TextTypes.Title]: '32px',
    [TextTypes.Subheader]: '28px',
    [TextTypes.Body]: '24px',
    [TextTypes.Caption]: '22px',
    [TextTypes.Small]: '22px',
});

const BaseFontCircularPadding = new FontCircularPadding({
    [TextTypes.Display]: '1px 12px 0',
    [TextTypes.Headline]: '1px 12px 0',
    [TextTypes.Title]: '1px 11px 0',
    [TextTypes.Subheader]: '1px 8px 0px',
    [TextTypes.Body]: '2px 7px 0',
    [TextTypes.Caption]: '1px 5px 0',
    [TextTypes.Small]: '1px 6px 0',
});

export const BaseFontConfiguration = new FontConfiguration({
    family: 'Matter, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
    sizes: BaseFontSizes,
    lineHeights: BaseFontLineHeight,
    fontWeight: 'normal',
    italic: 'font-style: italic',
    emphasized: 'font-weight: 500',
    circularPadding: BaseFontCircularPadding,
});
