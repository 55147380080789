import LocalStorage from 'web-app/services/local-storage';

import {type Error} from './types';
import {MAX_LENGTH, MAX_LIFETIME} from './constants';

const LOCAL_STORAGE_KEY = 'debuggerErrorLog';

export const getErrorLog = () => {
    const errors = (LocalStorage.getFromLocalStorage(LOCAL_STORAGE_KEY) as Error[]) ?? [];

    return errors;
};

export const saveErrorLog = (errors: Error[]) => {
    LocalStorage.saveToLocalStorage(LOCAL_STORAGE_KEY, errors.slice(0, MAX_LENGTH));
};

export const addErrorToErrorLog = (newError: Omit<Error, 'timestamp' | 'route'>) => {
    const errors = getErrorLog();

    errors.unshift({
        ...newError,
        timestamp: new Date().toISOString(),
        route: window.location.href,
    });

    saveErrorLog(errors);
};

/**
 * Purge errors older than MAX_LIFETIME to avoid storing potentially sensitive data for too long
 */
export const purgeErrorLog = () => {
    const errors = getErrorLog();

    const purgedErrors = errors.filter(error => {
        return Date.now() - new Date(error.timestamp).getTime() < MAX_LIFETIME;
    });

    saveErrorLog(purgedErrors);
};
