import {createTheme, type Theme as MuiTheme, useTheme as useMuiTheme} from '@mui/material/styles';
import {type Localization} from '@mui/material/locale';
import {type LinkProps} from '@mui/material/Link';

/**
 * The following makes sure that MUI's theme gets augmented with components from @mui/x-date-pickers-pro
 */
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';

import {TypographyThemeConfiguration, TypographyThemeOptions} from 'modern-famly/components/data-display/text';
import {DrawerThemeConfiguration} from 'modern-famly/components/navigation/drawer/drawer';
import {ButtonThemeConfiguration} from 'modern-famly/components/input/button/button-base';
import {LabelThemeConfiguration} from 'modern-famly/components/input/form-label/form-label';
import {IconThemeConfiguration} from 'modern-famly/components/data-display/icon';
import {OutlinedInputThemeConfiguration} from 'modern-famly/components/input/text-field/text-field';
import {FormHelperTextThemeConfiguration} from 'modern-famly/components/input/form-helper-text/form-helper-text';
import {SelectThemeConfiguration} from 'modern-famly/components/input/select/base';
import {ChipThemeConfiguration} from 'modern-famly/components/data-display/pill/pill';
import {AvatarGroupThemeConfiguration} from 'modern-famly/components/data-display/avatar-group/avatar-group';
import {BadgeThemeConfiguration} from 'modern-famly/components/data-display/badge/badge';
import {DatePickerThemeConfiguration} from 'modern-famly/components/input/date-and-time/date-picker/date-picker-theme-configuration';
import {DateRangePickerThemeConfiguration} from 'modern-famly/components/input/date-and-time/date-range-picker/date-range-picker-theme-configuration';
import {SwitchThemeConfiguration} from 'modern-famly/components/input/toggle/toggle';

import {type ColorPalette} from './colors';
import {type ElevationConfiguration} from './elevations';

/**
 * Modern Famly theme
 */
export interface Theme {
    /**
     * The name of the theme
     */
    name: string;

    /**
     * The theme's color palette
     */
    colorPalette: ColorPalette;

    /**
     * Elevations
     */
    elevation: ElevationConfiguration;

    /**
     * Theme helper for spacing. Forwarded from Material.
     *
     * @see https://mui.com/material-ui/customization/spacing/
     */
    spacing: MuiTheme['spacing'];

    /**
     * Theme helper for breakpoints.
     *
     * @see https://mui.com/material-ui/customization/breakpoints/
     */
    breakpoints: MuiTheme['breakpoints'];

    /**
     * Theme helper for border radius.
     */
    shape: MuiTheme['shape'];
}

/**
 * Converts a Modern Famly Theme to a theme that's compatible with MUI
 *
 * @param theme A Modern Famly theme
 * @returns A MUI compatible theme
 */
export const toMuiTheme = (
    theme: Theme,
    localization: Localization,
    config?: {LinkBehavior?: React.ElementType},
): MuiTheme => {
    return createTheme(
        {
            modernFamlyTheme: theme,
            breakpoints: theme.breakpoints,
            spacing: theme.spacing,
            shape: theme.shape,
            typography: TypographyThemeOptions,
            components: {
                MuiCssBaseline: {
                    styleOverrides: `
                    -webkit-font-smoothing: antialiased;
                `,
                },
                MuiButtonBase: {
                    defaultProps: {
                        LinkComponent: config?.LinkBehavior,
                    },
                },
                MuiLink: {
                    // The `as LinkProps` cast is necessary. This is what MUI does in their own documentation: https://mui.com/material-ui/guides/routing/#global-theme-link
                    defaultProps: {
                        component: config?.LinkBehavior,
                    } as LinkProps,
                },
                MuiButton: ButtonThemeConfiguration,
                MuiChip: ChipThemeConfiguration,
                MuiDrawer: DrawerThemeConfiguration,
                MuiFormLabel: LabelThemeConfiguration,
                MuiIcon: IconThemeConfiguration,
                MuiFormHelperText: FormHelperTextThemeConfiguration,
                MuiTypography: TypographyThemeConfiguration,
                MuiOutlinedInput: OutlinedInputThemeConfiguration,
                MuiAutocomplete: SelectThemeConfiguration,
                MuiUseMediaQuery: {
                    defaultProps: {
                        /**
                         * Tells MUI that we're not doing server-side rendering and as such
                         * don't need to cater for it when using the useMediaQuery hook.
                         *
                         * For more details, see https://mui.com/material-ui/react-use-media-query/#client-side-only-rendering
                         */
                        noSsr: true,
                    },
                },
                MuiAvatarGroup: AvatarGroupThemeConfiguration,
                MuiBadge: BadgeThemeConfiguration,
                MuiSwitch: SwitchThemeConfiguration,
                ...DatePickerThemeConfiguration,
                ...DateRangePickerThemeConfiguration,
            },
        },
        localization,
    );
};

/**
 * Get the current Modern Famly Theme
 *
 * @returns The current theme
 */
export const useTheme = (): Theme => {
    const muiTheme = useMuiTheme();

    return muiTheme.modernFamlyTheme;
};

/*
|------------------------------------------------------------------------------
| Module augmentation
|------------------------------------------------------------------------------
|
| Extends MUI's theme definition to include Modern Famly theme
|
*/
type ModernFamlyTheme = Theme;

declare module '@mui/material/styles' {
    interface Theme {
        modernFamlyTheme: ModernFamlyTheme;
    }

    interface ThemeOptions {
        modernFamlyTheme: ModernFamlyTheme;
    }

    interface BreakpointOverrides {
        xs: false;
        sm: false;
        md: false;
        lg: false;
        xl: false;

        base: true;
        mobileLandscape: true;
        tabletPortrait: true;
        tabletLandscape: true;
        laptop: true;
    }
}
