import {findAll} from 'highlight-words-core';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Highlights all occurrences of search terms (searchText) within a string (textToHighlight).
 * This function returns an array of strings and <span>s (wrapping highlighted words).
 */

// Taken from https://github.com/sindresorhus/escape-string-regexp
const escapeRegExp = str => {
    return str.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
};

const Highlighter = props => {
    const {autoEscape, searchWords, textToHighlight, sanitize = escapeRegExp} = props;

    let {activeClassName, highlightTag, activeIndex, highlightClassName, highlightStyle} = props;

    activeClassName = activeClassName || '';
    activeIndex = activeIndex || -1;
    highlightTag = highlightTag || 'mark';
    highlightClassName = highlightClassName || '';
    highlightStyle = highlightStyle || {};

    const chunks = findAll({
        autoEscape,
        sanitize,
        searchWords,
        textToHighlight,
    });
    const HighlightTag = highlightTag;
    let highlightCount = -1;
    let highlightClassNames = '';

    return (
        <span>
            {chunks.map((chunk, index) => {
                const text = textToHighlight.substr(chunk.start, chunk.end - chunk.start);

                if (chunk.highlight) {
                    highlightCount++;
                    highlightClassNames = `${highlightClassName} ${
                        highlightCount === Number(activeIndex) ? activeClassName : ''
                    }`;

                    return (
                        <HighlightTag className={highlightClassNames} key={index} style={highlightStyle}>
                            {text}
                        </HighlightTag>
                    );
                } else {
                    return <span key={index}>{text}</span>;
                }
            })}
        </span>
    );
};
Highlighter.propTypes = {
    activeClassName: PropTypes.string,
    activeIndex: PropTypes.string,
    autoEscape: PropTypes.bool,
    highlightClassName: PropTypes.string,
    highlightTag: PropTypes.string,
    highlightStyle: PropTypes.object,
    searchWords: PropTypes.arrayOf(PropTypes.string).isRequired,
    textToHighlight: PropTypes.string.isRequired,
    sanitize: PropTypes.func,
};

export default Highlighter;
