import {createAction} from 'signin-app/redux/helpers';

const domain = 'CHILD';

export const {
    action: fetchChild,
    success: fetchChildSuccess,
    failed: fetchChildFailed,
} = createAction(
    `${domain}/FETCH_CHILD`,
    (accessToken: string, childId: string) => ({accessToken, childId}),
    (response: {[x: string]: any}) => ({response}),
    (error: Error) => ({error}),
);
