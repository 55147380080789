import {type Subscription} from 'rxjs';

import {createRestClient} from 'web-app/api/clients/rest-client';
import {APIErrorCode} from 'web-app/api/types';
import {logOut} from 'signin-app/login/actions';
import APIHelper from 'signin-app/api/helpers/api-helper';

const onError = (errorMessage: string) => {
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(errorMessage);
    }
};

const senryService = {
    // eslint-disable-next-line no-console
    captureMessage: (message: string) => console.log(message),
    // eslint-disable-next-line no-console
    captureException: (e: Error) => console.log(e),
};

const SignInAppRestClient = createRestClient(APIHelper, onError, senryService);

let errorSubscription: Subscription | undefined;

// Handles expired login session
if (!errorSubscription) {
    errorSubscription = SignInAppRestClient.ErrorSubject.subscribe(error => {
        if (error.responseData && error.responseData.errorCode === APIErrorCode.SESSION_EXPIRED) {
            logOut.dispatch(false);
        }
    });
}

export default SignInAppRestClient;
