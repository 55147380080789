import React from 'react';
import MuiSwitch from '@mui/material/Switch';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import {type Theme, type Components} from '@mui/material/styles';
import {useFormControl} from '@mui/material/FormControl';

import {adjustAlpha} from 'modern-famly/theming/colors';
import {type DataProps, useDataProps} from 'modern-famly/components/util';

export type ToggleProps = {
    /**
     * The value for the toggle
     */
    checked: boolean;

    /**
     * Callback for when the toggle is toggled
     */
    onChange: (value: boolean) => void;

    /**
     * The label for the toggle
     */
    label: string;

    /**
     * The position of the label
     *
     * @default 'start'
     */
    labelPlacement?: 'start' | 'end';

    // /**
    //  * The size of the toggle
    //  *
    //  * @default 'regular'
    //  */
    // size?: 'regular' | 'compact';

    /**
     * Whether the toggle is disabled
     */
    disabled?: boolean;

    /**
     * ID attribute of the underlying input element.
     */
    id?: string;
} & DataProps;

const trackColor = (theme: Theme, checked: boolean | undefined, disabled: boolean | undefined) => {
    if (!checked) {
        return theme.modernFamlyTheme.colorPalette.n100;
    }

    if (disabled) {
        return theme.modernFamlyTheme.colorPalette.n200;
    }

    return theme.modernFamlyTheme.colorPalette.p400;
};

const thumbColor = (theme: Theme, checked: boolean | undefined, disabled: boolean | undefined) => {
    if (checked) {
        return theme.modernFamlyTheme.colorPalette.n0;
    }

    if (disabled) {
        return theme.modernFamlyTheme.colorPalette.n200;
    }

    return theme.modernFamlyTheme.colorPalette.n300;
};

export type StandaloneToggleProps = Omit<ToggleProps, 'label' | 'labelPlacement'> & {ariaLabel: string};

export const StandaloneToggle = (props: StandaloneToggleProps) => {
    const dataProps = useDataProps(props);

    const {disabled: disabledFromFormControl} = useFormControl() ?? {};
    const disabled = props.disabled ?? disabledFromFormControl;

    return (
        <MuiSwitch
            checked={props.checked}
            onChange={(_, checked) => props.onChange(checked)}
            disabled={disabled}
            id={props.id}
            inputProps={{
                'aria-label': props.ariaLabel,
            }}
            {...dataProps}
        />
    );
};

export const Toggle = (props: ToggleProps) => {
    const dataProps = useDataProps(props);
    return (
        <MuiFormControlLabel
            control={
                <MuiSwitch
                    checked={props.checked}
                    onChange={(_, checked) => props.onChange(checked)}
                    disabled={props.disabled}
                    id={props.id}
                />
            }
            label={props.label}
            labelPlacement={props.labelPlacement ?? 'start'}
            sx={() => ({
                // By default, MUI sets this to 16px
                marginLeft: '0',
            })}
            {...dataProps}
        />
    );
};

declare module '@mui/material/Switch' {
    export interface SwitchPropsSizeOverrides {
        small: false;
        medium: false;
        large: false;

        regular: true;
        compact: true;
    }
}

export const SwitchThemeConfiguration: Components<Theme>['MuiSwitch'] = {
    styleOverrides: {
        root: ({theme, ownerState: {checked, disabled}}) => ({
            padding: '8px',

            // Increases the size of the "moving" part of the switch on mousedown
            'input[type="checkbox"]:active + .MuiSwitch-thumb:not(.MuiSwitch-disabled)': !disabled
                ? {
                      width: 20,
                      height: 20,
                      margin: 0,
                      padding: 2,
                  }
                : undefined,

            // Changes color of the ripple effect
            '& .MuiTouchRipple-root': {
                color: theme.modernFamlyTheme.colorPalette[checked ? 'p300' : 'n300'],
            },
        }),

        switchBase: ({theme}) => ({
            ':hover': {
                backgroundColor: adjustAlpha(0.1, theme.modernFamlyTheme.colorPalette.n300),
            },
            '&.Mui-checked:hover': {
                backgroundColor: adjustAlpha(0.1, theme.modernFamlyTheme.colorPalette.p300),
            },
        }),

        track: ({theme, ownerState: {checked, disabled}}) => ({
            backgroundColor: `${trackColor(theme, checked, disabled)} !important`,
            opacity: `1 !important`,
            borderRadius: 22 / 2,
            border: checked ? undefined : `2px solid ${theme.modernFamlyTheme.colorPalette.n200}`,
            boxSizing: 'border-box',
        }),

        // The "moving" part of the switch
        thumb: ({theme, ownerState: {checked, disabled}}) => ({
            boxShadow: 'none',
            width: 16,
            height: 16,
            margin: 2,
            background: thumbColor(theme, checked, disabled),

            // Sets background image (the checkmark or the "dash")
            backgroundImage: checked
                ? `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                      trackColor(theme, checked, disabled),
                  )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`
                : `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                      theme.modernFamlyTheme.colorPalette.n50,
                  )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            boxSizing: 'border-box',
        }),
    },
};
