import React from 'react';

export default function deriveProps<TOriginalProps, TAdditionalProps>(
    deriveFromProps: (props: TOriginalProps) => TAdditionalProps,
) {
    return (Component: React.ComponentType<React.PropsWithChildren<TOriginalProps & TAdditionalProps>>) =>
        class extends React.PureComponent<TOriginalProps> {
            private derivedProps;

            public constructor(props: TOriginalProps) {
                super(props);
                this.derivedProps = deriveFromProps(props);
                if (typeof this.derivedProps !== 'object') {
                    throw new Error('The function passed to deriveProps did not return an object');
                }
            }

            public render() {
                return <Component {...this.props} {...this.derivedProps} />;
            }
        };
}
