import React from 'react';
import {type RouteComponentProps, withRouter} from 'react-router-dom';

class ScrollToTop extends React.Component<React.PropsWithChildren<RouteComponentProps>> {
    public componentDidUpdate(prevProps) {
        // When a route is changed, the loaded page
        // should be displayed at the top of the screen.
        // Normally `window.scrollTo` would be used but only
        // the main body(#root) of the app is scrollable because
        // we prevent overscroll/bounce in iOS Safari:
        // https://www.bram.us/2016/05/02/prevent-overscroll-bounce-in-ios-mobilesafari-pure-css/
        if (this.props.location !== prevProps.location) {
            document.getElementById('root')?.scrollTo(0, 0);
        }
    }

    public render() {
        return this.props.children;
    }
}

const ScrollToTopWithRouter = withRouter(ScrollToTop);

const ScrollToTopOnRouteChange = InnerComponent => props => {
    return (
        <ScrollToTopWithRouter>
            <InnerComponent {...props} />
        </ScrollToTopWithRouter>
    );
};

export default ScrollToTopOnRouteChange;
