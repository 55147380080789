import {type OperationVariables, type QueryHookOptions} from '@apollo/client';
import {type DocumentNode} from 'graphql';

// eslint-disable-next-line no-restricted-imports
import {useResilientQuery} from 'web-app/react/hooks/use-resilient-query';

import {useImmerQueryDelayedUpdateWrapper} from '../use-immer-query-delayed-update-wrapper';
import {useImmerQueryWrapper, type ImmerWrappedQueryResult} from '../use-immer-query-wrapper';

/**
 * Simply combines the `useResilientQuery` with the `useImmerQueryWrapper`.
 *
 * @see useResilientQuery
 * @see useImmerQueryWrapper
 */
export function useStatQuery<TData = any, TVariables = OperationVariables>(
    query: DocumentNode,
    options?: QueryHookOptions<TData, TVariables>,
): ImmerWrappedQueryResult<TData, TVariables> {
    return useImmerQueryDelayedUpdateWrapper(useImmerQueryWrapper(useResilientQuery(query, options)));
}
