import React from 'react';
import Box from '@mui/material/Box';

import {ClassNames} from './shared';

export const ListHeaderRow = (props: {children?: React.ReactNode}) => {
    return (
        <Box
            display="contents"
            sx={{
                [`.${ClassNames.ListHeader}`]: {
                    height: '48px',
                    alignItems: 'center',
                },
            }}
        >
            {props.children}
        </Box>
    );
};
