import {CpcOrchardsTheme as CpcOrchardsMFTheme} from 'modern-famly';

import {hexToRGBA} from 'web-app/util/hex-to-rgb';

import BaseTheme from './base';

const CpcOrchardsChromeColor = '#339966';
const CpcOrchardsPrimary = CpcOrchardsChromeColor;
const CpcOrchardsActive = hexToRGBA(CpcOrchardsChromeColor, 0.8);
const CpcOrchardsBackgroundHover = hexToRGBA(CpcOrchardsChromeColor, 0.04);

const CpcOrchardsTheme = BaseTheme.merge({
    title: 'CPC Orchards',
    chromeColor: CpcOrchardsChromeColor,
    primary: CpcOrchardsPrimary,
    active: CpcOrchardsActive,
    backgroundHover: CpcOrchardsBackgroundHover,
    mf: CpcOrchardsMFTheme,
});

export default CpcOrchardsTheme;
