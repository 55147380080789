import React from 'react';
import PropTypes from 'prop-types';

import Carousel, {isStringImage} from 'web-app/react/components/carousel/carousel';

const FormCarousel = props => {
    const onChange = value => {
        if (props.onChange) {
            props.onChange(value);
        }
        props.input.onChange(value);
    };

    return (
        <Carousel
            onChange={onChange}
            initial={
                props.input.value
                    ? props.items.findIndex(item =>
                          isStringImage(item) ? item === props.input.value : item.id === props.input.value,
                      )
                    : undefined
            }
            renderer={props.renderer}
            items={props.items}
        />
    );
};
FormCarousel.propTypes = {
    onChange: PropTypes.func,
    input: PropTypes.object,
    meta: PropTypes.any,
    items: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                id: PropTypes.string.isRequired,
            }),
            PropTypes.string,
        ]),
    ).isRequired,
    renderer: PropTypes.func,
};

export default FormCarousel;
