import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useStatFeatures} from '../../features/use-stat-features';

/**
 * Do not use this query directly!
 */
export function useLeaveSettingsRangePermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['staffLeaveSettings']['leave']['ranges'] & {loading: boolean} {
    const {institutionSetId} = payload;
    const [features, loading] = useStatFeatures({
        institutionId: institutionSetId,
    });
    return {
        // for now, we do only have a single "permission", aka flag for the
        // read and update permissions
        loading,
        canRead: features.leaveRange.isEnabled,
        canUpdate: features.leaveRange.isEnabled,
        canCreate: false,
        canDelete: false,
    };
}
