import gql from 'graphql-tag';

export const GetChildNameFromId = gql`
    query GetChildNameFromId($childIds: [ChildId!]!) {
        children(childIds: $childIds) {
            name {
                firstName
            }
        }
    }
`;
