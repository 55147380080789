import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

import DebouncableInput from 'web-app/react/components/form-elements/debouncable-input';
import {authStateContext} from 'web-app/react/contexts/auth-state';

export const TextFieldStyling = css`
    text-align: ${props => props.textAlign};
    border-radius: 4px;
    font-size: ${props => props.theme.fontConfiguration.sizes.Caption};
    padding: 8px;
    border-color: ${props => props.theme.borderConfiguration.defaultColor};
    transition: box-shadow 0.5s, border-color 0.15s ease-out;
    color: ${props => props.theme.text};

    &:hover {
        border-color: ${props => props.theme.borderConfiguration.hover};
    }

    &:focus,
    &:active {
        border-color: ${props => props.theme.borderConfiguration.focus};
    }

    &::placeholder {
        color: ${props => props.theme.textDisabled};
    }

    &:disabled {
        border-color: transparent;
        background-color: ${props => props.theme.borderConfiguration.disabled};
        color: ${props => props.theme.textDisabled};
    }

    ${props =>
        props.ispassive
            ? css`
                  && {
                      border-color: transparent;
                      background: transparent;
                      padding-left: 0;
                      color: ${props => props.theme.text};
                      -webkit-text-fill-color: ${props => props.theme.text};
                      opacity: 1;
                  }
              `
            : ''}
`;

const InputCSS = css`
    border-radius: ${props => props.cornerStyle === 'rounded' && '3px'};
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin: 0 0 0.625rem;
    padding: 0.3125rem;
    border: 1px solid ${props => props.theme.borderConfiguration.defaultColor};
    background-color: ${props => props.theme.invertedText};
    box-shadow: inset 0 0 0 rgba(51, 51, 51, 0.1);
    font-family: inherit;
    font-size: 0.8125rem;
    font-weight: normal;
    color: ${props => props.theme.text};
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    appearance: none;

    &:focus {
        outline: none;
        border: 1px solid ${props => props.theme.borderConfiguration.focus};
        box-shadow: 0 0 0 ${props => props.theme.textDisabled};
    }

    ${props =>
        props.invalid
            ? css`
                  outline: none;

                  &&:not(:focus):not(:active) {
                      box-shadow: 0 0 0 2px ${props => props.theme.accent3};
                  }
              `
            : ''}

    height: 36px;

    ${TextFieldStyling}
`;

// eslint-disable-next-line no-unused-vars
const Input = styled(({ispassive, invalid, textAlign, cornerStyle, ...rest}) => <DebouncableInput {...rest} />)`
    ${InputCSS}
`;

const TextField = props => {
    const {
        id,
        type,
        placeholder,
        value,
        cornerStyle,
        textAlign,
        onChange,
        onFocus,
        onBlur,
        required,
        disabled,
        autoFocus,
        className,
        step,
        inputRef,
        ispassive,
        passive,
        // eslint-disable-next-line no-unused-vars
        mismatchString,
        ...rest
    } = props;

    const extraAttrs = rest;

    if (step) {
        extraAttrs.step = step;
    }

    if (inputRef) {
        extraAttrs.inputRef = input => inputRef(input);
    }

    const {isLoggedIn} = React.useContext(authStateContext);

    const shouldDisallowAutoComplete = !isLoggedIn || type === 'email';

    return (
        <Input
            id={id}
            type={type}
            value={value}
            className={className}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            required={required}
            autoFocus={autoFocus}
            onFocus={onFocus}
            onBlur={onBlur}
            ispassive={passive || ispassive}
            textAlign={textAlign}
            cornerStyle={cornerStyle}
            autoComplete={shouldDisallowAutoComplete ? 'off' : undefined}
            {...extraAttrs}
        />
    );
};
TextField.propTypes = {
    id: PropTypes.string,
    type: PropTypes.oneOf([
        'text',
        'number',
        'email',
        'password',
        'search',
        'tel',
        'url',
        'range',
        'date',
        'hidden',
        'time',
    ]),
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cornerStyle: PropTypes.string,
    textAlign: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    inputRef: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    ispassive: PropTypes.string,
    passive: PropTypes.bool,
    autoFocus: PropTypes.bool,
    invalid: PropTypes.bool,
    step: PropTypes.string,
    className: PropTypes.string,
    debounced: PropTypes.bool,
    theme: PropTypes.object,
    mismatchString: PropTypes.string,
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
TextField.defaultProps = {
    type: 'text',
    required: false,
    disabled: false,
    autoFocus: false,
    debounced: false,
};

export default TextField;
