import React from 'react';
import {type WrappedFieldProps} from 'redux-form';

import ReactOnMeta from 'web-app/react/components/form-elements/containers/react-on-meta';
import PeriodPicker, {type InputProps} from 'web-app/react/components/form-elements/period-picker/period-picker';
import {type Optional} from 'web-app/util/typescript';

type Props = WrappedFieldProps &
    Optional<InputProps, 'onChange'> & {
        dateFormat: string;
    };

class FormDayPickerInput extends React.Component<Props> {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    public render() {
        const {
            input,
            meta, // eslint-disable-line no-unused-vars
            onChange, // eslint-disable-line no-unused-vars
            dateFormat,
            ...rest
        } = this.props;

        return (
            <ReactOnMeta {...this.props}>
                <PeriodPicker from={input.value.from} to={input.value.to} onChange={this.handleChange} {...rest} />
            </ReactOnMeta>
        );
    }
    private handleChange(value: Parameters<InputProps['onChange']>[0]): void {
        if (this.props.onChange) {
            this.props.onChange(value);
        }

        this.props.input.onChange(value);
    }
}

export default FormDayPickerInput;
