import React from 'react';
import Stack from '@mui/material/Stack';

import {ListContext} from './list-context';

export const MobileList = (props: React.PropsWithChildren<{}>) => {
    const ListContextValue = React.useMemo(() => ({forceDisplayContents: false, device: 'mobile' as const}), []);

    return (
        <ListContext.Provider value={ListContextValue}>
            <Stack gap="2px">{props.children}</Stack>
        </ListContext.Provider>
    );
};
