import {Record, List} from 'immutable';

import {makeReducer, match} from 'web-app/util/redux';
import type Name from 'web-app/react/entities/common/name';
import type Image from 'web-app/react/entities/common/image';
import type Address from 'web-app/react/entities/common/address';

import * as Actions from './actions';

export interface Relation {
    address: typeof Address.default;
    childCustody: boolean;
    childId: string;
    childLivesAt: boolean;
    email: string;
    extraInfo: string;
    hideFromPhoneList: boolean;
    homePhone?: object;
    image: typeof Image.default;
    loginId: string;
    name: typeof Name.default;
    phone?: object;
    placeOfWork: string;
    priority: number;
    protectedAddress: boolean;
    relationId: string;
    status: string;
    workPhone?: object;
}

export class ChildState extends Record<{
    readonly naps: List<any>;
    readonly relations: List<Relation>;
    readonly isFetching: boolean;
}>({
    naps: List(),
    relations: List(),
    isFetching: false,
}) {}

export default makeReducer<ChildState>(
    [
        match(Actions.fetchChild, state => {
            return state.merge({
                isFetching: true,
            });
        }),
        match(Actions.fetchChildSuccess, (state, {payload}) => {
            return state.merge({
                isFetching: false,
                naps: List(payload.response.naps),
                relations: List(payload.response.relations),
            });
        }),
        match(Actions.fetchChildFailed, state => {
            return state.merge({
                isFetching: false,
            });
        }),
    ],
    new ChildState(),
);
