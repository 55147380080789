import {type Theme} from '../theme';
import {createBaseThemeVariation} from './base';

export const MonkeyPuzzleTheme: Theme = createBaseThemeVariation({
    name: 'monkeypuzzle',
    brandColor: 'hsla(190, 80%, 30%, 1)',
    primaryColors: {
        p50: 'hsla(190, 80%, 98%, 1)',
        p75: 'hsla(190, 80%, 97%, 1)',
        p100: 'hsla(190, 80%, 90%, 1)',
        p200: 'hsla(190, 80%, 80%, 1)',
        p300: 'hsla(190, 80%, 70%, 1)',
        p400: 'hsla(190, 80%, 30%, 1)',
        p500: 'hsla(190, 80%, 15%, 1)',
    },
});
