import {createAction} from 'signin-app/redux/helpers';
import {type GoHomeWithChildrenGroup} from 'signin-app/child/models';

const domain = 'OTHER_CHILDREN';

export const {
    action: fetchGoHomeWithChildren,
    success: fetchGoHomeWithChildrenSuccess,
    failed: fetchGoHomeWithChildrenFailed,
} = createAction(
    `${domain}/FETCH`,
    (childId: string) => ({childId}),
    (response: GoHomeWithChildrenGroup[]) => ({response}),
);

export const {action: selectGoHomeWithChild} = createAction(
    `${domain}/SELECT_GO_HOME_WITH_CHILD`,
    (childId: string) => ({childId}),
);
