import React, {type ReactNode, type FC} from 'react';
import classNames from 'classnames';
import styled, {type css} from 'styled-components';

import {media} from 'web-app/styleguide/utils';
import {getSpacing, s6} from 'web-app/styleguide/spacing';
import Button, {ButtonAppearance, type ButtonSize} from 'web-app/react/components/form-elements/button/button';
import {getProperty} from 'web-app/util/typescript';

const StyledForm = styled.form`
    & input {
        &[type='text'],
        &[type='password'],
        &[type='date'],
        &[type='datetime'],
        &[type='datetime-local'],
        &[type='month'],
        &[type='week'],
        &[type='email'],
        &[type='number'],
        &[type='search'],
        &[type='tel'],
        &[type='time'],
        &[type='url'],
        &[type='color'],
        textarea {
            margin-bottom: 0;
        }
    }
`;

const ButtonContainer = styled('div')<{
    hideFooter: boolean | undefined;
    hideFooterOnMobile: any;
    css?: ReturnType<typeof css>;
}>`
    margin-top: ${getSpacing(s6)};

    display: ${props => (props.hideFooter ? 'none' : 'block')};

    ${media.mobile`
        display: ${props => (props.hideFooterOnMobile || props.hideFooter ? 'none' : 'block')};
    `}
    ${props => props.css || ''}
`;

interface FormProps {
    id?: string;
    buttonContainerExtraElements?: ReactNode;
    deleteAppearance?: string;
    buttonClassName?: string;
    hideFooter?: boolean;
    hideFooterOnMobile?: boolean;
    onSubmit?: any;
    buttonSize?: ButtonSize;
    cancelForm?: (...args: any[]) => void;
    deleteForm?: any;
    submitText?: string;
    cancelText?: string;
    className?: string;
    buttonContainerClassName?: string;
    deleteText?: string;
    loading?: boolean;
    deleting?: boolean;
    disableConfirm?: boolean;
    disableCancel?: boolean;
    disableDelete?: boolean;
    disabled?: boolean;
    pristine?: boolean;
    formRef?: (ref) => void;
    submitButtonPosition?: string;
    anyTouched?: boolean;
    initialValues?: object;
    buttonContainerCSS?: ReturnType<typeof css>;
    submitButtonId?: string;
    dataE2eSubmitButtonId?: string;
    dataE2eCancelButtonId?: string;
    ['data-intercom-target']?: string;
    ['data-e2e-id']?: string;
    dataIntercomTargetSubmitButton?: string;
}

type Props = FormProps;

export const Form: FC<React.PropsWithChildren<Props>> = props => {
    const {
        buttonContainerExtraElements,
        buttonContainerClassName,
        buttonClassName,
        id,
        buttonSize,
        deleteAppearance,
        hideFooter,
        hideFooterOnMobile,
        children,
        onSubmit,
        cancelForm,
        submitText,
        cancelText,
        loading,
        deleteForm,
        deleteText,
        deleting,
        disableConfirm,
        disableCancel,
        disableDelete,
        disabled,
        pristine,
        className,
        formRef,
        submitButtonPosition,
        buttonContainerCSS,
        submitButtonId,
        dataE2eSubmitButtonId,
        dataE2eCancelButtonId,
        dataIntercomTargetSubmitButton,
    } = props;

    const deleteAppearanceToUse = deleteText ? deleteAppearance : ButtonAppearance.deleteNoText;

    return (
        <StyledForm
            className={className}
            onSubmit={onSubmit}
            id={id}
            ref={formRef}
            data-intercom-target={getProperty(props, 'data-intercom-target')}
            data-e2e-id={getProperty(props, 'data-e2e-id')}
        >
            {children}

            {submitText || cancelText || buttonContainerExtraElements || deleteForm ? (
                <ButtonContainer
                    css={buttonContainerCSS}
                    className={classNames(buttonContainerClassName, 'clearfix')}
                    hideFooter={hideFooter}
                    hideFooterOnMobile={hideFooterOnMobile}
                >
                    {submitText ? (
                        <Button
                            promiseOnClick
                            id={submitButtonId}
                            buttonSize={buttonSize}
                            appearance="confirm"
                            type="submit"
                            position={submitButtonPosition || 'right'}
                            spinner={loading}
                            isDisabled={loading}
                            disabled={disabled || pristine || disableConfirm}
                            data-e2e-id={dataE2eSubmitButtonId}
                            data-intercom-target={dataIntercomTargetSubmitButton}
                        >
                            {submitText}
                        </Button>
                    ) : null}
                    {cancelForm ? (
                        <Button
                            buttonSize={buttonSize}
                            position="right"
                            onClick={cancelForm}
                            disabled={disabled || disableCancel}
                            data-e2e-id={dataE2eCancelButtonId}
                        >
                            {cancelText}
                        </Button>
                    ) : null}
                    {deleteForm && !loading ? (
                        <Button
                            className={buttonClassName}
                            buttonSize={buttonSize}
                            appearance={deleteAppearanceToUse}
                            type="button"
                            position="left"
                            spinner={deleting}
                            onClick={deleteForm}
                            disabled={disabled || disableDelete}
                        >
                            {deleteText ? <span>{deleteText}</span> : null}
                        </Button>
                    ) : null}
                    {buttonContainerExtraElements}
                </ButtonContainer>
            ) : null}
        </StyledForm>
    );
};

Form.defaultProps = {
    deleteAppearance: 'delete',
};
