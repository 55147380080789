enum RoutesMap {
    pin = '/pin',
    overview = '/overview',
    nurseryGroup = '/groups/:groupId',
    childCheckIn = '/child/:childId/checkin',
    childCheckOut = '/child/:childId/checkout',
    staffGroup = '/staff/:staffGroupId',
    employeeCheckIn = '/employee/:employeeId/checkin',
    employeeCheckOut = '/employee/:employeeId/checkout',
    goHomeWith = '/go-home-with/:childId',
    qr = '/qr',
}

export default RoutesMap;
