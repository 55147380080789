import gql from 'graphql-tag';

export const GetQRSignInSettings = gql`
    query GetQRSignInSettings($siteIds: [SiteId!]) {
        qrSignin {
            siteSettings(siteIds: $siteIds) {
                siteSettings {
                    siteId
                    isEnabled
                    canManage
                }
            }
        }
    }
`;

export const GetSiteId = gql`
    query GetSiteId {
        institutions {
            institutionId
        }
    }
`;
