import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

export interface ProviderContext {
    translate: (key: string, hash?: object) => string;
}

class ReactContextProvider extends React.Component<React.PropsWithChildren<{}>> {
    public static childContextTypes = {
        translate: PropTypes.func,
    };

    public getChildContext(): ProviderContext {
        return {
            translate(key, hash) {
                return i18next.t(key, hash);
            },
        };
    }

    public render() {
        return this.props.children;
    }
}

export default ReactContextProvider;
