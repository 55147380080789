import {handleActions} from 'redux-actions';

import {type EntityState, entityStateFactory} from 'web-app/react/entities/factory/reducer-factory';
import {checkInEmployeeSuccess} from 'signin-app/employee/check-in/actions';
import {type IStaffGroup} from 'signin-app/entities/staff/model';
import {makeGetAllEmployees, type ExtendedEmployee} from 'signin-app/employee/selectors';
import {checkOutEmployeeSuccess} from 'signin-app/employee/check-out/actions';

interface CheckInPayload {
    response: {[x: string]: any};
    status: any;
}

const EntityStateFactory = entityStateFactory<IStaffGroup>();
const initialState = new EntityStateFactory();

const getAllEmployees = makeGetAllEmployees();

export default handleActions<EntityState<IStaffGroup>, CheckInPayload>(
    {
        [checkInEmployeeSuccess.type]: (state, {payload}) => {
            // entityMap is empty if an employee logs in with a pin
            if (!payload || state.entityMap.isEmpty()) {
                return state;
            }

            const employee = getAllEmployees(state.entityMap).get(payload.response.employeeId);
            const checkedInEmployee = {
                ...(employee as ExtendedEmployee),
                checkedIn: true,
                ...payload.response,
                tempGroupId: payload.response.groupId,
            };

            const entityMapItem = state.entityMap.get(employee!.staffGroupId);

            const updatedEntityMapItem = entityMapItem!.update('employees', employees => {
                const index = employees.findIndex(employee => employee.id === checkedInEmployee.employeeId);
                return employees.update(index, () => checkedInEmployee);
            });

            const updatedEntityMap = state.entityMap.set(employee!.staffGroupId, updatedEntityMapItem);

            return state.merge({
                entityMap: updatedEntityMap,
            });
        },
        [checkOutEmployeeSuccess.type]: (state, {payload}) => {
            if (!payload || !payload.response || payload.response.length === 0 || state.entityMap.isEmpty()) {
                return state;
            }

            const employee = getAllEmployees(state.entityMap).get(payload.response[0].employeeId);
            const checkedInEmployee = {
                ...(employee as ExtendedEmployee),
                checkedIn: false,
                ...payload.response[0],
            };

            const entityMapItem = state.entityMap.get(employee!.staffGroupId);

            const updatedEntityMapItem = entityMapItem!.update('employees', employees => {
                const index = employees.findIndex(employee => employee.id === checkedInEmployee.employeeId);
                return employees.update(index, () => checkedInEmployee);
            });

            const updatedEntityMap = state.entityMap.set(employee!.staffGroupId, updatedEntityMapItem);

            return state.merge({
                entityMap: updatedEntityMap,
            });
        },
    },
    initialState,
);
