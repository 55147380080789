import gql from 'graphql-tag';

export const GetQrSecret = gql`
    mutation QRSecret($siteId: SiteId!) {
        qrSignin {
            getSecret(siteId: $siteId) {
                secret
            }
        }
    }
`;

export const GetInstitutionData = gql`
    query GetInstitutionData {
        institutions {
            institutionId
            title
            profileImage {
                url
            }
        }
    }
`;
