import {Record} from 'immutable';

export interface IOpeningHours {
    day: number;
    fromHour: number;
    fromMinute: number;
    open: boolean;
    toHour: number;
    toMinute: number;
}

export class OpeningHours extends Record<IOpeningHours>({
    day: 0,
    fromHour: 0,
    fromMinute: 0,
    open: false,
    toHour: 0,
    toMinute: 0,
}) {}

export class Institution extends Record<{
    image: string;
    institutionId: string;
    showPickupUntilExactClosingTime: boolean;
    timezone: string;
    title: string;
    usePincodeCheckin: boolean;
}>({
    image: '',
    institutionId: '',
    showPickupUntilExactClosingTime: false,
    timezone: '',
    title: '',
    usePincodeCheckin: false,
}) {}

export interface IChildRelation {
    image: string;
    name: string;
    relationId: string;
    fullName?: string;
}

export class ChildRelation extends Record<IChildRelation>({
    image: '',
    name: '',
    relationId: '',
}) {}
