export const exhaustiveCheck = (x: never) => {
    console.warn('Exhaustive check caught following value:', x);
};

export function hasValue<T>(candidate: T | undefined | null): candidate is T {
    return candidate !== undefined && candidate !== null;
}

export type NonEmptyArray<T> = [T, ...T[]];

export function isNonEmpty<T>(arr: T[] | NonEmptyArray<T>): arr is NonEmptyArray<T> {
    return arr.length > 0;
}
