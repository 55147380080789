import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {SVG, G} from './svg';

const ArrowBase = ({appearance, fill, ...rest}) => (
    <SVG {...rest} viewBox="0 0 24 24">
        <G color={fill} appearance={appearance}>
            <polygon points="14.7686567 5 9.23134328 10.5373134 7.76865672 12 9.23134328 13.4626866 14.7686567 19 16.2313433 17.5373134 10.6940299 12 16.2313433 6.46268657" />
        </G>
    </SVG>
);
ArrowBase.propTypes = {
    fill: PropTypes.string,
    appearance: PropTypes.string,
};

const ArrowDown = styled(ArrowBase)`
    transform: rotate(-90deg);
`;

const ArrowUp = styled(ArrowBase)`
    transform: rotate(90deg);
`;

const ArrowLeft = ArrowBase;

const ArrowRight = styled(ArrowBase)`
    transform: rotate(180deg);
`;

const Arrow = props => {
    const {direction, ...rest} = props;

    switch (direction) {
        case 'north':
            return <ArrowUp {...rest} />;
        case 'south':
            return <ArrowDown {...rest} />;
        case 'east':
            return <ArrowRight {...rest} />;
        case 'west':
            return <ArrowLeft {...rest} />;
        default:
            return null;
    }
};
Arrow.options = {
    direction: ['north', 'south', 'east', 'west'],
};
Arrow.propTypes = {
    fill: PropTypes.string,
    direction: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.number,
};
Arrow.defaultProps = {
    appearance: 'default',
    size: 20,
};

export default styled(Arrow)``;
