import React from 'react';
import {daDK, deDE, esES, enUS, nbNO, type Localization as CoreLocalization} from '@mui/material/locale';
import {LicenseInfo} from '@mui/x-license-pro';

import {type Theme} from 'modern-famly/theming/theme';
import {ThemeProvider} from 'modern-famly/theming/theme-provider';
import {exhaustiveCheck} from 'modern-famly/util';

/**
 * The public key for mui-x pro. This key is meant to be public.
 * See https://mui.com/x/introduction/licensing/#security for more info.
 */
LicenseInfo.setLicenseKey(
    '5b4a472c481709aaf76e8ba5ffaa143dTz05MTc5MixFPTE3NDkwMjgyNjAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

export interface Props {
    theme: Theme;
    children?: React.ReactNode;
}

export const useModernFamlyContext = () => {
    return React.useContext(ModernFamlyContext);
};

export type ModernFamlyContextType = {
    isMobileApp: boolean;
    locale: SupportedLanguage;
    isDisplayingDatePicker: boolean;
    setIsDisplayingDatePicker: (value: boolean) => void;
};

export const ModernFamlyContext = React.createContext<ModernFamlyContextType>({
    isMobileApp: false,
    locale: 'enUS',
    isDisplayingDatePicker: false,
    setIsDisplayingDatePicker: () => {
        console.warn('ModernFamlyContext not initialized');
    },
});

export type SupportedLanguage = 'daDK' | 'enUS' | 'deDE' | 'deCH' | 'esES' | 'esUS' | 'enGB' | 'nbNO';

type MuiLocalizations = {
    core: CoreLocalization;
};

const languageToLocalization = (supportedLanguage: SupportedLanguage): MuiLocalizations => {
    switch (supportedLanguage) {
        case 'daDK': {
            return {core: daDK};
        }
        case 'deCH':
        case 'deDE': {
            return {core: deDE};
        }
        case 'esUS':
        case 'esES': {
            return {core: esES};
        }
        case 'enGB':
        case 'enUS': {
            return {core: enUS};
        }
        case 'nbNO': {
            return {core: nbNO};
        }
        default:
            exhaustiveCheck(supportedLanguage);
            return {core: enUS};
    }
};

export const ModernFamlyProvider = (props: {
    theme: Theme;
    isMobileApp: boolean;
    language?: SupportedLanguage;
    children?: React.ReactNode;
    LinkBehavior?: React.ElementType;
}) => {
    const locale: SupportedLanguage = props.language ?? 'enUS';
    const localizations = languageToLocalization(locale);

    const [isDisplayingDatePicker, setIsDisplayingDatePicker] = React.useState(false);

    return (
        <ThemeProvider theme={props.theme} localization={localizations.core} LinkBehavior={props.LinkBehavior}>
            <ModernFamlyContext.Provider
                value={{isMobileApp: props.isMobileApp, locale, isDisplayingDatePicker, setIsDisplayingDatePicker}}
            >
                {props.children}
            </ModernFamlyContext.Provider>
        </ThemeProvider>
    );
};
