import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

import Select from 'web-app/react/components/form-elements/select/select';
import ReactOnMeta from 'web-app/react/components/form-elements/containers/react-on-meta';
import deferRender from 'web-app/react/components/higher-order/defer-render';

const emptyBlur = () => ({});

const FormSelect = ({input, meta, validateIgnoreTouch, ...otherProps}) => {
    const showErrors = validateIgnoreTouch ? Boolean(meta.error) : meta.touched && Boolean(meta.error);
    return (
        <ReactOnMeta input={input} meta={meta} validateIgnoreTouch={validateIgnoreTouch} {...otherProps}>
            <StyledSelect
                {...input}
                {...otherProps}
                autoBlur={true}
                hasError={showErrors}
                className={otherProps.className}
                onBlur={emptyBlur}
                onChange={newValue => {
                    if (otherProps.onChange) {
                        otherProps.onChange(newValue);
                    }
                    if (otherProps.multi) {
                        return input.onChange(newValue);
                    }
                    return input.onChange(newValue ? newValue.value : null);
                }}
            />
        </ReactOnMeta>
    );
};

const StyledSelect = styled(Select)`
    width: 100%;
    display: block;
    ${props =>
        props.hasError
            ? css`
                  &,
                  &:focus,
                  .Select-control {
                      border-color: color-red;
                  }
              `
            : ''}
`;

FormSelect.propTypes = {
    input: PropTypes.any,
    meta: PropTypes.any,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    required: PropTypes.bool,
    validateIgnoreTouch: PropTypes.bool,
    clearable: PropTypes.bool,
    extendedOptionRender: PropTypes.bool,
    disabled: PropTypes.bool,
    options: PropTypes.array.isRequired,
    onInputChange: PropTypes.func,
    onChange: PropTypes.func,
};

export const DeferredFormSelect = deferRender(FormSelect);

export default FormSelect;
