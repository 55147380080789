import React from 'react';
import styled from 'styled-components';

import {reduxForm} from 'web-app/react/deprecation/redux-form/redux-form';
import {Form} from 'web-app/react/components/form-elements/containers/form/form';
import FormField from 'web-app/react/components/form-elements/containers/form-field/form-field';
import {LoginFormTextField} from 'web-app/react/login/shared-components';
import {required} from 'web-app/react/components/form-elements/validators';
import translate from 'signin-app/helpers/translate';
import {compose} from 'web-app/util/typescript';
import {Colors} from 'web-app/styleguide';
import * as WhitelabelSelectors from 'signin-app/whitelabel/selectors';
import {useTypedSelector} from 'signin-app/components/hooks';

import * as Constants from './constants';
import * as Selectors from '../selectors';

const StyledForm = styled(Form)<{primaryColor: string}>`
    &&& {
        width: 264px;

        input {
            font-family: ${props => props.theme.fontConfiguration.family} !important;
        }
        button[type='submit'] {
            width: 100%;
            font-size: 20px;
            padding: 8px 16px;
            border-radius: 7px;
            font-family: ${props => props.theme.fontConfiguration.family} !important;
            &,
            &:hover,
            &&&:focus:not([disabled]) {
                background-color: ${props => props.theme.invertedText};
            }
            &,
            &:hover {
                border-color: ${props => props.theme.invertedText};
            }
            color: ${props => props.primaryColor};
        }
    }
`;

const StyledLoginFormTextField = styled(LoginFormTextField)`
    color: ${props => props.theme.invertedText};
    height: 43px;
    &&&::placeholder {
        color: ${props => props.theme.invertedTextSecondary};
    }
`;

interface InputProps {
    onSubmit: (data: FormData) => void;
    className?: string;
}

export interface FormData {
    password: string;
}

export default compose(
    reduxForm<FormData, InputProps>({
        form: Constants.FORM_NAME,
    }),
).apply(props => {
    const {handleSubmit, className} = props;

    const whitelabelConfiguration = useTypedSelector(state => WhitelabelSelectors.whiteLabelConfiguration(state));
    const isLoggingIn = useTypedSelector(state => Selectors.isLoggingIn(state));

    return (
        <StyledForm
            id="loginForm"
            onSubmit={handleSubmit}
            submitText={translate('logIn')}
            loading={isLoggingIn}
            className={className}
            primaryColor={whitelabelConfiguration.PrimaryColor}
            dataE2eSubmitButtonId="signin-login-button"
        >
            <FormField
                name="password"
                id="password"
                type="password"
                placeholder={translate('passwordPlaceholder')}
                component={StyledLoginFormTextField}
                required
                validate={[required]}
                props={{
                    autoComplete: 'signin-password',
                    activeColor: Colors.InvertedText,
                    passiveColor: Colors.InvertedTextDisabled,
                }}
            />
        </StyledForm>
    );
});
