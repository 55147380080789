import {
    type Theme as ModernFamlyTheme,
    FamlyTheme as FamlyMFTheme,
    EspiraTheme as EspiraMFTheme,
    KidoTheme as KidoMFTheme,
    BrightHorizonsTheme as BrightHorizonsMFTheme,
    MonkeyPuzzleTheme as MonkeyPuzzleMFTheme,
    NFamilyClubTheme as NFamilyClubMFTheme,
    GrandirUKTheme as GrandirUKMFTheme,
    NuffieldHealthTheme as NuffieldHealthMFTheme,
    KindredTheme as KindredMFTheme,
    KinderzimmerUKTheme as KinderzimmerUKMFTheme,
    CpcTreehouseTheme as CpcTreehouseMFTheme,
    CpcOrchardsTheme as CpcOrchardsMFTheme,
} from 'modern-famly';

import {Whitelabel} from 'web-app/shared/white-labeling-constants';
import {getWhitelabel} from 'web-app/shared/white-labeling-helpers';
import {exhaustiveCheck, hasValue} from 'web-app/util/typescript';

import NewLookTheme from './new-look';
import EspiraTheme from './espira';
import KidoTheme from './kido';
import GrandirUKTheme from './grandiruk';
import NuffieldHealthTheme from './nuffieldhealth';
import BrightHorizonsTheme from './bright-horizons';
import KindredTheme from './kindred';
import MonkeyPuzzleTheme from './monkeypuzzle';
import NFamilyClubTheme from './nfamilyclub';
import KinderzimmerUKTheme from './kinderzimmeruk';
import CpcTreehouseTheme from './cpc-treehouse';
import CpcOrchardsTheme from './cpc-orchards';

/**
 * @deprecated Use color enum
 */

export const colorKeys = [
    'primary',
    'secondary',
    'active',
    'accent1',
    'accent2',
    'accent3',
    'analogue1',
    'analogue2',
    'analogue3',
    'text',
    'blackScrim',
    'textSecondary',
    'textDisabled',
    'invertedText',
    'invertedTextSecondary',
    'invertedTextDisabled',
    'whiteSecondary',
    'whiteDisabled',
    'delimiter',
    'backgroundHover',
];

export const currentTheme = () => {
    const whitelabel = getWhitelabel();

    switch (whitelabel) {
        case Whitelabel.espira:
            return EspiraTheme;
        case Whitelabel.kido:
            return KidoTheme;
        case Whitelabel.brighthorizons:
            return BrightHorizonsTheme;
        case Whitelabel.kindred:
            return KindredTheme;
        case Whitelabel.monkeypuzzle:
            return MonkeyPuzzleTheme;
        case Whitelabel.nfamilyclub:
            return NFamilyClubTheme;
        case Whitelabel.grandiruk:
            return GrandirUKTheme;
        case Whitelabel.nuffieldhealth:
            return NuffieldHealthTheme;
        case Whitelabel.kinderzimmeruk:
            return KinderzimmerUKTheme;
        case Whitelabel.cpctreehouse:
            return CpcTreehouseTheme;
        case Whitelabel.cpcorchards:
            return CpcOrchardsTheme;
        case null:
            return NewLookTheme;
        default:
            exhaustiveCheck(whitelabel);
            return NewLookTheme;
    }
};

export const getCurrentModernFamlyTheme = (): ModernFamlyTheme => {
    const whitelabel = getWhitelabel();

    if (!hasValue(whitelabel)) {
        return FamlyMFTheme;
    }

    switch (whitelabel) {
        case Whitelabel.espira:
            return EspiraMFTheme;
        case Whitelabel.kido:
            return KidoMFTheme;
        case Whitelabel.brighthorizons:
            return BrightHorizonsMFTheme;
        case Whitelabel.kindred:
            return KindredMFTheme;
        case Whitelabel.monkeypuzzle:
            return MonkeyPuzzleMFTheme;
        case Whitelabel.nfamilyclub:
            return NFamilyClubMFTheme;
        case Whitelabel.grandiruk:
            return GrandirUKMFTheme;
        case Whitelabel.nuffieldhealth:
            return NuffieldHealthMFTheme;
        case Whitelabel.kinderzimmeruk:
            return KinderzimmerUKMFTheme;
        case Whitelabel.cpctreehouse:
            return CpcTreehouseMFTheme;
        case Whitelabel.cpcorchards:
            return CpcOrchardsMFTheme;
        default:
            exhaustiveCheck(whitelabel);
            return FamlyMFTheme;
    }
};

export default currentTheme;

export {NewLookTheme};
