import {type QueryHookOptions} from '@apollo/client';

import {FetchFtePermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-fte-permissions.query';
import {
    type FetchFtePermissionsQuery,
    type FetchFtePermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-fte-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchFtePermissions(
    options: QueryHookOptions<FetchFtePermissionsQuery, FetchFtePermissionsQueryVariables>,
): ImmerWrappedQueryResult<FetchFtePermissionsQuery, FetchFtePermissionsQueryVariables> {
    return useStatQuery<FetchFtePermissionsQuery, FetchFtePermissionsQueryVariables>(FetchFtePermissions, options);
}
