import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

import {Flex, Base} from 'web-app/react/components/layout/layout';
import {s2} from 'web-app/styleguide/spacing';
import {Caption, Subheader, Title} from 'web-app/react/components/text/text';

import Container from './container';

const TitleContainer = styled.div`
    max-width: 100%;
    flex-grow: 1;
`;

const getHeaderPadding = props => {
    if (props.withPadding) {
        return css`
            padding: ${props => (props.extraPadding ? '24px' : '16px')};
            ${props =>
                props.compact
                    ? css`
                          padding-bottom: 0;
                      `
                    : ''}
        `;
    } else if (props.compact) {
        return '';
    }

    return css`
        padding-bottom: ${props => (props.extraPadding ? '24px' : '16px')};
    `;
};

const Header = styled(Flex)`
    ${props =>
        props.noMargin
            ? ''
            : css`
                  margin-bottom: 16px;
              `}

    ${getHeaderPadding}
`;

const RightWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
`;

const TitleStyles = css`
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const TitleElement = styled(Title)`
    ${TitleStyles};
`;

const TitleSubheader = styled(Subheader)`
    ${TitleStyles};
`;

// Exported from this file such that we're able to style it from outside instead of directly passing in css.
export const InnerContainer = styled(Flex)``;

const Component = props => {
    const {
        id,
        leftTitleBarElement,
        rightTitleBarElement,
        title,
        hideHeader,
        description,
        noHeaderMargin,
        noMargin,
        noPadding,
        extraPadding,
        className,
        children,
        emphasizedTitle,
        headerCSS,
        compact,
        compactDescription,
        afterElement,
        noHeaderWrap,
        flexContainerProps,
        ...rest
    } = props;

    const TitleComponent = compact ? TitleSubheader : TitleElement;
    const DescriptionComponent = compact || compactDescription ? Caption : Subheader;

    return (
        <Container
            id={id}
            noMargin={noMargin}
            extraPadding={extraPadding}
            noPadding={noPadding}
            className={className}
            {...rest}
        >
            <InnerContainer
                direction="column"
                justify="space-between"
                fullWidth={!afterElement}
                {...flexContainerProps}
            >
                {hideHeader ? null : (
                    <Header
                        css={headerCSS}
                        compact={compact}
                        noMargin={noHeaderMargin}
                        withPadding={noPadding}
                        extraPadding={extraPadding}
                        align="center"
                        wrap={noHeaderWrap ? undefined : 'wrap'}
                    >
                        {leftTitleBarElement}
                        <TitleContainer>
                            <TitleComponent emphasized={emphasizedTitle}>{title}</TitleComponent>
                            {description ? (
                                <Base marginTop={s2}>
                                    <DescriptionComponent disabled>{description}</DescriptionComponent>
                                </Base>
                            ) : null}
                        </TitleContainer>
                        {rightTitleBarElement ? <RightWrapper>{rightTitleBarElement}</RightWrapper> : null}
                    </Header>
                )}
                {children}
            </InnerContainer>
            {afterElement}
        </Container>
    );
};

Component.propTypes = {
    flexContainerProps: PropTypes.object,
    compact: PropTypes.bool,
    compactDescription: PropTypes.bool,
    extraPadding: PropTypes.bool,
    hideHeader: PropTypes.bool,
    noHeaderMargin: PropTypes.bool,
    children: PropTypes.any,
    id: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.node,
    description: PropTypes.node,
    rightTitleBarElement: PropTypes.any,
    leftTitleBarElement: PropTypes.any,
    noMargin: PropTypes.bool,
    noPadding: PropTypes.bool,
    emphasizedTitle: PropTypes.bool,
    headerCSS: PropTypes.array,
    afterElement: PropTypes.node,
    noHeaderWrap: PropTypes.bool,
    elevation: PropTypes.oneOf(['one', 'two', 'three', 'four', 'five', 'six']),
};

Component.defaultProps = {
    emphasizedTitle: true,
};

export default Component;
