import {combineReducers, type Reducer} from 'redux';
import {reducer as reduxFormReducer, type FormStateMap} from 'redux-form';
import {connectRouter, type RouterState, type LocationChangeAction} from 'connected-react-router';

import {StoreKey as EntityStoreKey} from 'web-app/react/entities/factory/constants';
import {type EntityState} from 'web-app/react/entities/factory/reducer-factory';
import {type Action} from 'web-app/util/redux/action-types';
import {type GlobalEventState} from 'web-app/react/global-event/helpers/create-reducer';
import prefetch, {type PrefetchState} from 'signin-app/components/private-route/reducer';

import {entityReducer} from '../setup-entities';
import loginReducer, {type LoginState} from '../login/reducer';
import * as LoginActions from '../login/actions';
import globalEvent from '../global-event/reducer';
import institutionOverview, {type InstitutionOverviewState} from '../groups/reducer';
import child, {type ChildState} from '../child/reducer';
import childCheckIn, {type ChildCheckInState} from '../child/check-in/reducer';
import childCheckOut, {type ChildCheckOutState} from '../child/check-out/reducer';
import employeeCheckIn, {type EmployeeCheckInState} from '../employee/check-in/reducer';
import employeeCheckOut, {type EmployeeCheckOutState} from '../employee/check-out/reducer';
import childrenGroup, {type ChildrenGroupState} from '../group/reducer';
import pin, {type PinState} from '../pin/reducer';
import goHomeWith, {type GoHomeWithState} from '../go-home-with/reducer';
import modals, {type ModalsState} from '../modals-container/reducer';
import account, {type AccountState} from '../account/reducer';

const reducersObj = {
    [EntityStoreKey]: entityReducer,
    // redux-form uses older Reducer type which conflicts
    // with Reducer used by combineReducers
    form: reduxFormReducer as Reducer<any>,
    globalEvent,
    institutionOverview,
    login: loginReducer,
    child,
    childCheckIn,
    childCheckOut,
    prefetch,
    employeeCheckIn,
    employeeCheckOut,
    childrenGroup,
    pin,
    goHomeWith,
    modals,
    account,
};

export interface RootState {
    [EntityStoreKey]: {[x: string]: EntityState<any>};
    form: FormStateMap;
    globalEvent: GlobalEventState;
    institutionOverview: InstitutionOverviewState;
    login: LoginState;
    child: ChildState;
    childCheckIn: ChildCheckInState;
    childCheckOut: ChildCheckOutState;
    prefetch: PrefetchState;
    employeeCheckIn: EmployeeCheckInState;
    employeeCheckOut: EmployeeCheckOutState;
    childrenGroup: ChildrenGroupState;
    router: RouterState<any>;
    pin: PinState;
    goHomeWith: GoHomeWithState;
    modals: ModalsState;
    account: AccountState;
}

export const createRootReducer = history => {
    const appReducer = combineReducers<RootState, Action<any> | LocationChangeAction>({
        ...reducersObj,
        router: connectRouter(history),
    });

    const rootReducer = (state, action) => {
        // Resets entire Redux state on logOut
        if (action.type === LoginActions.logOutSuccess.type) {
            // Don't reset the router state,
            // otherwise some issues with browser history may appear
            const {router} = state;
            state = {router};
        }

        return appReducer(state, action);
    };

    return rootReducer;
};

export default createRootReducer;
