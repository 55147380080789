import {createSubscribableMutator} from 'web-app/react/hooks/create-subscribable-mutator';

import * as Queries from '../queries';
import {type ToggleFeaturesMutation, type ToggleFeaturesMutationVariables} from '../__generated__/queries.api-types';

export const {
    useSubscribableMutation: useSubscribableToggleFeatureMutation,
    useUpdateCache: useSubscribableToggleFeatureMutationUpdateCache,
    useSubscribeMutationResult: useSubscribableToggleFeatureMutationResult,
} = createSubscribableMutator<ToggleFeaturesMutation, ToggleFeaturesMutationVariables>(Queries.ToggleFeatures);
