import React from 'react';

import {hasValue} from 'web-app/util/typescript';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useFetchStaffHoursPermissions} from './queries/use-fetch-staff-hours-permissions';
import {type StatPermissions} from './stat-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';

export function useStaffHoursExportCSVPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['staffHoursPage']['header']['export'] & {loading: boolean} {
    const {institutionSetId, employeeId, shouldQuery} = payload;
    const {data, loading} = useFetchStaffHoursPermissions({
        variables: {
            siteSetId: institutionSetId,
            employeeId,
        },
        skip: !shouldQuery || !hasValue(institutionSetId),
    });
    const crud = useCrudPermissionAdapter(data?.staffhours.management.totals.csvExport);

    return React.useMemo(() => ({loading, ...crud}), [crud, loading]);
}
