import {combineReducers} from 'redux';

import displayedGroupsSettings, {type DisplayedGroupsSettingsModalState} from './displayed-groups-modal/reducer';

export interface ModalsState {
    displayedGroupsSettings: DisplayedGroupsSettingsModalState;
}

export default combineReducers<ModalsState>({
    displayedGroupsSettings,
});
