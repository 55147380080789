import {Record} from 'immutable';

import hexToRGB from 'web-app/util/hex-to-rgb';

export enum Shadow {
    zero = 'zero',
    half = 'half',
    one = 'one',
    two = 'two',
    three = 'three',
    four = 'four',
    five = 'five',
}

export enum ShadowDirection {
    up = 'up',
    down = 'down',
    left = 'left',
    right = 'right',
}

export interface IShadowConfiguration {
    readonly zero: string;
    readonly half: string;
    readonly one: string;
    readonly two: string;
    readonly three: string;
    readonly four: string;
    readonly five: string;
    readonly getShadow: (shadow: Shadow, direction: ShadowDirection) => string;
    readonly color: string;
}

export class ShadowConfiguration extends Record<IShadowConfiguration>({
    zero: '',
    half: '',
    one: '',
    two: '',
    three: '',
    four: '',
    five: '',
    getShadow: () => '',
    color: '',
}) {}

const getShadowSize = (shadow: Shadow) => {
    switch (shadow) {
        case Shadow.half:
            return 1;
        case Shadow.two:
            return 4;
        case Shadow.three:
            return 8;
        case Shadow.four:
            return 16;
        case Shadow.five:
            return 24;
        case Shadow.one:
        default:
            return 2;
    }
};

const getShadowPosition = (shadow: Shadow, direction: ShadowDirection) => {
    const size = getShadowSize(shadow);

    const blur = size * 2;

    const directionalSize = (direction === ShadowDirection.right || direction === ShadowDirection.up ? -1 : 1) * size;

    if (direction === ShadowDirection.right || direction === ShadowDirection.left) {
        return `${directionalSize}px 0 ${blur}px 0`;
    } else {
        return `0 ${directionalSize}px ${blur}px 0`;
    }
};

const getShadow = (shadow: Shadow, direction: ShadowDirection, shadowBlack: string) => {
    const position = getShadowPosition(shadow, direction);

    return `${position} ${shadowBlack}`;
};

const getShadows = (black: string) => {
    const rgbBlack = hexToRGB(black);

    if (!rgbBlack) {
        return new ShadowConfiguration();
    }

    const shadowBlack = `rgba(${rgbBlack.r}, ${rgbBlack.g}, ${rgbBlack.b}, 0.1)`;

    return new ShadowConfiguration({
        zero: 'none',
        half: getShadow(Shadow.half, ShadowDirection.down, shadowBlack),
        one: getShadow(Shadow.one, ShadowDirection.down, shadowBlack),
        two: getShadow(Shadow.two, ShadowDirection.down, shadowBlack),
        three: getShadow(Shadow.three, ShadowDirection.down, shadowBlack),
        four: getShadow(Shadow.four, ShadowDirection.down, shadowBlack),
        five: getShadow(Shadow.five, ShadowDirection.down, shadowBlack),
        getShadow: (shadow: Shadow, direction: ShadowDirection) => getShadow(shadow, direction, shadowBlack),
        color: shadowBlack,
    });
};

export default getShadows;
