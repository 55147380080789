import PropTypes from 'prop-types';
import i18next from 'i18next';
import React from 'react';
import classNames from 'classnames';
import {compose} from 'redux';

import Select from 'web-app/react/components/form-elements/select/select';
import {PureRenderIgnoreFunctions} from 'web-app/react/components/higher-order/pure-render';

import {Container, CLASSNAME_PREFIX} from './timepicker';

const PLACEHOLDER = '--';

class DurationPicker extends React.Component {
    constructor(props) {
        super(props);

        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

        const minuteOptions = [{label: PLACEHOLDER, value: null}].concat(
            Array.from({length: 60}).map((_, i) => ({
                label: i < 10 ? `0${i}` : `${i}`,
                value: i,
            })),
        );

        this.state = {
            focused: false,
            minuteOptions,
        };
    }

    onChange(hours, minutes, oldHours, oldMinutes) {
        const {onChange} = this.props;

        const hoursIsEmpty = hours === '';
        const minutesIsEmpty = minutes === '';

        if (onChange) {
            if ((hours === null && oldHours !== null) || (minutes === null && oldMinutes !== null)) {
                onChange(null);
                return;
            }

            if (
                (hoursIsEmpty && minutesIsEmpty) ||
                (hoursIsEmpty && minutes === 0) ||
                (minutesIsEmpty && hours === 0)
            ) {
                onChange(0);
                return;
            }

            const newValue = Number(hours) * 60 + Number(minutes);

            onChange(newValue);
        }
    }

    getSelect(hours, minutes) {
        const {className, required, timePickerClassName, disabled} = this.props;

        const {focused, minuteOptions} = this.state;

        const amountPattern = '[0-9]+';

        const hoursString = `${hours}`;
        const hoursSize = hoursString.length > 2 ? hoursString.length : 2;

        return (
            <Container focused={focused} disabled={disabled} className={className}>
                <input
                    type="text"
                    size={hoursSize}
                    value={hours}
                    disabled={disabled}
                    pattern={amountPattern}
                    className={classNames(
                        `${CLASSNAME_PREFIX}_textInput`,
                        `${CLASSNAME_PREFIX}_input`,
                        `${CLASSNAME_PREFIX}_select`,
                        {
                            [timePickerClassName]: Boolean(timePickerClassName),
                        },
                    )}
                    placeholder={PLACEHOLDER}
                    onChange={e => this.onChange(e.target.value.replace(/[^0-9]/g, ''), minutes, hours, minutes)}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                />
                <div className={`${CLASSNAME_PREFIX}_unit`}>{i18next.t('time.hoursAbbr')}</div>
                <Select
                    required={required}
                    disabled={disabled}
                    value={minutes}
                    clearable={false}
                    onChange={opt => this.onChange(hours, opt ? opt.value : '', hours, minutes)}
                    options={minuteOptions}
                    scrollMenuIntoView={true}
                    onOpen={this.scrollIntoView}
                    containerClassName={`${CLASSNAME_PREFIX}_inputContainer`}
                    className={classNames(`${CLASSNAME_PREFIX}_input`, `${CLASSNAME_PREFIX}_select`, {
                        [timePickerClassName]: Boolean(timePickerClassName),
                    })}
                    arrowClass={`${CLASSNAME_PREFIX}_arrow`}
                    placeholder={PLACEHOLDER}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                />
                <div className={`${CLASSNAME_PREFIX}_unit`}>{i18next.t('time.minutesAbbr')}</div>
                <div className={classNames(`${CLASSNAME_PREFIX}_spacer`, `${CLASSNAME_PREFIX}_lastSpacer`)} />
            </Container>
        );
    }

    render() {
        let hours = '';
        let minutes = null;

        const value = this.props.value;

        if ((value || value === 0) && value !== '') {
            hours = Math.floor(value / 60);
            minutes = value - hours * 60;
        }

        return this.getSelect(hours, minutes);
    }

    handleFocus() {
        this.setState({
            focused: true,
        });
    }

    handleBlur() {
        this.setState({
            focused: false,
        });
    }
}
DurationPicker.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    offsetClassName: PropTypes.string,
    timePickerClassName: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
};

export default compose(PureRenderIgnoreFunctions)(DurationPicker);
