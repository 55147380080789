import {KinderzimmerUKTheme as KinderzimmerUKMFTheme} from 'modern-famly';

import {hexToRGBA} from 'web-app/util/hex-to-rgb';

import BaseTheme from './base';

const KinderzimmerUKChromeColor = '#114232';
const KinderzimmerUKPrimary = KinderzimmerUKChromeColor;
const KinderzimmerUKActive = hexToRGBA(KinderzimmerUKChromeColor, 0.8);
const KinderzimmerUKBackgroundHover = hexToRGBA(KinderzimmerUKChromeColor, 0.04);

const KinderzimmerUKTheme = BaseTheme.merge({
    title: 'Kinderzimmer UK',
    chromeColor: KinderzimmerUKChromeColor,
    primary: KinderzimmerUKPrimary,
    active: KinderzimmerUKActive,
    backgroundHover: KinderzimmerUKBackgroundHover,
    mf: KinderzimmerUKMFTheme,
});

export default KinderzimmerUKTheme;
