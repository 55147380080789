import React from 'react';
import moment, {type Moment} from 'moment-timezone';

import {type DayPickerInputProps} from 'web-app/react/components/form-elements/daypicker/day-picker-input';

const useHighlightWeekConfig = ({
    startOfweek,
    weekendSupport,
    timezone,
}: {
    startOfweek: Moment;
    weekendSupport: boolean;
    timezone: string;
}): {modifiers: DayPickerInputProps['modifiers']; disabledDays: DayPickerInputProps['disabledDays']} => {
    const modifiers = React.useMemo(() => {
        const selectedDayFrom =
            !weekendSupport && startOfweek.isoWeekday() > 5
                ? startOfweek.clone().add(1, 'week').isoWeekday(1) // next Monday
                : startOfweek.clone();
        const selectedDayTo = selectedDayFrom.clone().add(weekendSupport ? 6 : 4, 'days');

        return {
            selected: {
                from: selectedDayFrom.toDate(),
                to: selectedDayTo.toDate(),
            },
            isFirstInRange: day => moment(day).isSame(selectedDayFrom, 'day'),
            isLastInRange: day => moment(day).isSame(selectedDayTo, 'day'),
            isInRange: day => moment(day).isBetween(selectedDayFrom, selectedDayTo, 'days', '[]'),
            isMonday: day => moment(day).isoWeekday() === 1,
            isFriday: day => moment(day).isoWeekday() === 5,
            isWeekendDisabled: () => !weekendSupport,
        };
    }, [startOfweek, weekendSupport]);

    const disabledDays = React.useCallback(
        day => !weekendSupport && moment.tz(day, timezone).isoWeekday() > 5,
        [timezone, weekendSupport],
    );

    return {modifiers, disabledDays};
};

export default useHighlightWeekConfig;
