import React from 'react';
import {ThemeProvider as MuiThemeProvider} from '@mui/material/styles';
import {type Localization} from '@mui/material/locale';

import {type Theme, toMuiTheme} from './theme';

export interface Props {
    theme: Theme;
    localization: Localization;
    children?: React.ReactNode;
    LinkBehavior?: React.ElementType;
}

export const ThemeProvider = ({theme, children, localization, LinkBehavior}: Props): JSX.Element => {
    const muiTheme = React.useMemo(() => {
        return toMuiTheme(theme, localization, {
            LinkBehavior,
        });
    }, [theme, localization, LinkBehavior]);

    return <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>;
};
