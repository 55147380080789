import React from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';

import {compose} from 'web-app/util/typescript';
import formPrepare from 'web-app/react/components/higher-order/form-prepare';
import {Flex} from 'web-app/react/components/layout/layout';
import DayPickerInput, {
    type DayPickerInputProps,
} from 'web-app/react/components/form-elements/daypicker/day-picker-input';
import TimePicker, {type TimePickerInputProps} from 'web-app/react/components/form-elements/timepicker/timepicker';

import * as Helpers from './helpers';
import type * as Types from './types';

const StyledDayPickerInput = styled(DayPickerInput)`
    flex-grow: 2;
`;

const StyledTimePicker = styled(TimePicker)`
    flex-grow: 1;
    margin-left: 8px;
`;

const Container = styled(Flex)`
    input {
        margin-bottom: 0;
    }
`;

interface InputProps {
    value: Types.DateTimePickerValue;
    onChange: (value: this['value']) => void;
    dayPickerInputProps?: Omit<DayPickerInputProps, 'value' | 'onChange'>;
    timePickerInputProps?: Omit<TimePickerInputProps, 'value' | 'onChange'>;
    required?: boolean;
}

interface PrepareProps {
    timeValue: ReturnType<typeof Helpers.timeValue>;
    dateValue: ReturnType<typeof Helpers.dateValue>;
}

type Props = InputProps & PrepareProps;

class DateTimePicker extends React.Component<Props> {
    constructor(props) {
        super(props);
        this.onDateChange = this.onDateChange.bind(this);
        this.onTimeChange = this.onTimeChange.bind(this);
    }

    public render() {
        const {
            dateValue,
            timeValue,
            dayPickerInputProps = {},
            timePickerInputProps = {},
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ...rest
        } = this.props;

        return (
            <Container align="center" justify="space-between">
                <StyledDayPickerInput
                    {...dayPickerInputProps}
                    required={this.props.required}
                    value={dateValue}
                    onChange={this.onDateChange}
                />
                <StyledTimePicker
                    {...timePickerInputProps}
                    required={this.props.required}
                    value={timeValue}
                    onChange={this.onTimeChange}
                />
            </Container>
        );
    }

    private onDateChange(newValue?: string) {
        const {onChange, timeValue} = this.props;
        if (!newValue) {
            onChange('');
        } else {
            onChange(
                moment(newValue)
                    .startOf('day')
                    .add(timeValue ? timeValue.hours * 60 + timeValue.minutes : 0, 'minutes'),
            );
        }
    }

    private onTimeChange(newValue) {
        const {onChange, dateValue} = this.props;
        onChange(moment(dateValue).clone().startOf('day').add(newValue, 'minutes'));
    }
}

export default compose(
    formPrepare<InputProps, PrepareProps>(({value}) => ({
        timeValue: Helpers.timeValue(value),
        dateValue: Helpers.dateValue(value),
    })),
).apply(DateTimePicker);
