import {type QueryHookOptions} from '@apollo/client';

import {FetchSubtypesPermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-subtypes-permissions.query';
import {
    type FetchSubtypesPermissionsQuery,
    type FetchSubtypesPermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-subtypes-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchSubtypesPermissions(
    options: QueryHookOptions<FetchSubtypesPermissionsQuery, FetchSubtypesPermissionsQueryVariables>,
): ImmerWrappedQueryResult<FetchSubtypesPermissionsQuery, FetchSubtypesPermissionsQueryVariables> {
    return useStatQuery<FetchSubtypesPermissionsQuery, FetchSubtypesPermissionsQueryVariables>(
        FetchSubtypesPermissions,
        options,
    );
}
