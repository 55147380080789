import gql from 'graphql-tag';

import {StatPermissionDraftShiftManagementType} from '../fragment/stat-permission-draft-shift-management-type.fragment';
import {StatPermissionPublishedShiftManagementType} from '../fragment/stat-permission-published-shift-management-type.fragment';
import {StatPermissionShiftPlannerManagementType} from '../fragment/stat-permission-shift-planner-management-type.fragment';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchShiftPlannerPermissions = gql`
    query FetchShiftPlannerPermissions($siteSetId: SiteSetId!) {
        staffhours {
            management {
                shiftplanner(siteSetId: $siteSetId) {
                    ...StatPermissionShiftPlannerManagementType
                    shift {
                        draft(siteSetId: $siteSetId) {
                            ...StatPermissionDraftShiftManagementType
                        }
                        published(siteSetId: $siteSetId) {
                            ...StatPermissionPublishedShiftManagementType
                        }
                    }
                }
            }
        }
    }
    ${StatPermissionShiftPlannerManagementType}
    ${StatPermissionDraftShiftManagementType}
    ${StatPermissionPublishedShiftManagementType}
`;
