import {type QueryHookOptions} from '@apollo/client';

import {FetchShiftPlannerPermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-shift-planner-permissions.query';
import {
    type FetchShiftPlannerPermissionsQuery,
    type FetchShiftPlannerPermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-shift-planner-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchShiftPlannerPermissions(
    options: QueryHookOptions<FetchShiftPlannerPermissionsQuery, FetchShiftPlannerPermissionsQueryVariables>,
): ImmerWrappedQueryResult<FetchShiftPlannerPermissionsQuery, FetchShiftPlannerPermissionsQueryVariables> {
    return useStatQuery<FetchShiftPlannerPermissionsQuery, FetchShiftPlannerPermissionsQueryVariables>(
        FetchShiftPlannerPermissions,
        options,
    );
}
