import {type QueryHookOptions} from '@apollo/client';

import {FetchStaffAttendancePermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-staff-attendance-permissions.query';
import {
    type FetchStaffAttendancePermissionsQuery,
    type FetchStaffAttendancePermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-staff-attendance-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchStaffAttendancePermissions(
    options: QueryHookOptions<FetchStaffAttendancePermissionsQuery, FetchStaffAttendancePermissionsQueryVariables>,
): ImmerWrappedQueryResult<FetchStaffAttendancePermissionsQuery, FetchStaffAttendancePermissionsQueryVariables> {
    return useStatQuery<FetchStaffAttendancePermissionsQuery, FetchStaffAttendancePermissionsQueryVariables>(
        FetchStaffAttendancePermissions,
        options,
    );
}
