// eslint-disable-next-line no-restricted-imports
import {useSelector, type TypedUseSelectorHook} from 'react-redux';

import {type RootState} from 'signin-app/redux/main-reducer';

/**
 * A hook to access the redux store's state. This hook takes a selector function
 * as an argument. The selector is called with the store state.
 *
 * This hook takes an optional equality comparison function as the second parameter
 * that allows you to customize the way the selected state is compared to determine
 * whether the component needs to be re-rendered.
 *
 * @param selector the selector function
 * @param equalityFn the function that will be used to determine equality
 *
 * @returns the selected state
 *
 * @example
 *
 * import useTypedSelector from 'web-app/react/hooks/use-typed-selector';
 *
 * export const CounterComponent = () => {
 *   const counter = useTypedSelector(state => state.counter)
 *   return <div>{counter}</div>
 * }
 */

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default useTypedSelector;
