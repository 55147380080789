import {type TrackingEvent} from '../types';
import {FinanceEvents} from './finance';
import {FoundationEvents} from './foundation';
import {GrowthEvents} from './growth';
import {PtpEvents} from './ptp';
import {StatEvents} from './stat';

export const TrackingEvents = {
    ...FinanceEvents,
    ...FoundationEvents,
    ...PtpEvents,
    ...GrowthEvents,
    ...StatEvents,
} satisfies {[key in string]: TrackingEvent};

export {DynamicTrackingEvents} from './ptp';
