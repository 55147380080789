import type React from 'react';
import {css} from 'styled-components';

import renderStyle from 'web-app/styleguide/render-style';

/** Matter Bold */
const MatterBold = require('root/public/fonts/Matter/Matter-Bold.eot');
const MatterBoldWoff2 = require('root/public/fonts/Matter/Matter-Bold.woff2');
const MatterBoldWoff = require('root/public/fonts/Matter/Matter-Bold.woff');
const MatterBoldTTF = require('root/public/fonts/Matter/Matter-Bold.ttf');
/** Matter Bold Italic */
const MatterBoldItalic = require('root/public/fonts/Matter/Matter-BoldItalic.eot');
const MatterBoldItalicWoff2 = require('root/public/fonts/Matter/Matter-BoldItalic.woff2');
const MatterBoldItalicWoff = require('root/public/fonts/Matter/Matter-BoldItalic.woff');
const MatterBoldItalicTTF = require('root/public/fonts/Matter/Matter-BoldItalic.ttf');
/** Matter Semi Bold */
const MatterSemiBold = require('root/public/fonts/Matter/Matter-SemiBold.eot');
const MatterSemiBoldWoff2 = require('root/public/fonts/Matter/Matter-SemiBold.woff2');
const MatterSemiBoldWoff = require('root/public/fonts/Matter/Matter-SemiBold.woff');
const MatterSemiBoldTTF = require('root/public/fonts/Matter/Matter-SemiBold.ttf');
/** Matter Semi Bold */
const MatterMediumItalic = require('root/public/fonts/Matter/Matter-MediumItalic.eot');
const MatterMediumItalicWoff2 = require('root/public/fonts/Matter/Matter-MediumItalic.woff2');
const MatterMediumItalicWoff = require('root/public/fonts/Matter/Matter-MediumItalic.woff');
const MatterMediumItalicTTF = require('root/public/fonts/Matter/Matter-MediumItalic.ttf');
/** Matter Heavy */
const MatterHeavy = require('root/public/fonts/Matter/Matter-Heavy.eot');
const MatterHeavyWoff2 = require('root/public/fonts/Matter/Matter-Heavy.woff2');
const MatterHeavyWoff = require('root/public/fonts/Matter/Matter-Heavy.woff');
const MatterHeavyTTF = require('root/public/fonts/Matter/Matter-Heavy.ttf');
/** Matter Light Italic */
const MatterLightItalic = require('root/public/fonts/Matter/Matter-LightItalic.eot');
const MatterLightItalicWoff2 = require('root/public/fonts/Matter/Matter-LightItalic.woff2');
const MatterLightItalicWoff = require('root/public/fonts/Matter/Matter-LightItalic.woff');
const MatterLightItalicTTF = require('root/public/fonts/Matter/Matter-LightItalic.ttf');
/** Matter Medium */
const MatterMedium = require('root/public/fonts/Matter/Matter-Medium.eot');
const MatterMediumWoff2 = require('root/public/fonts/Matter/Matter-Medium.woff2');
const MatterMediumWoff = require('root/public/fonts/Matter/Matter-Medium.woff');
const MatterMediumTTF = require('root/public/fonts/Matter/Matter-Medium.ttf');
/** Matter Semi Bold Italic */
const MatterSemiBoldItalic = require('root/public/fonts/Matter/Matter-SemiBoldItalic.eot');
const MatterSemiBoldItalicWoff2 = require('root/public/fonts/Matter/Matter-SemiBoldItalic.woff2');
const MatterSemiBoldItalicWoff = require('root/public/fonts/Matter/Matter-SemiBoldItalic.woff');
const MatterSemiBoldItalicTTF = require('root/public/fonts/Matter/Matter-SemiBoldItalic.ttf');
/** Matter Heavy Italic */
const MatterHeavyItalic = require('root/public/fonts/Matter/Matter-HeavyItalic.eot');
const MatterHeavyItalicWoff2 = require('root/public/fonts/Matter/Matter-HeavyItalic.woff2');
const MatterHeavyItalicWoff = require('root/public/fonts/Matter/Matter-HeavyItalic.woff');
const MatterHeavyItalicTTF = require('root/public/fonts/Matter/Matter-HeavyItalic.ttf');
/** Matter Heavy Italic */
const MatterLight = require('root/public/fonts/Matter/Matter-Light.eot');
const MatterLightWoff2 = require('root/public/fonts/Matter/Matter-Light.woff2');
const MatterLightWoff = require('root/public/fonts/Matter/Matter-Light.woff');
const MatterLightTTF = require('root/public/fonts/Matter/Matter-Light.ttf');
/** Matter Regular */
const MatterRegular = require('root/public/fonts/Matter/Matter-Regular.eot');
const MatterRegularWoff2 = require('root/public/fonts/Matter/Matter-Regular.woff2');
const MatterRegularWoff = require('root/public/fonts/Matter/Matter-Regular.woff');
const MatterRegularTTF = require('root/public/fonts/Matter/Matter-Regular.ttf');
/** Matter Regular Italic */
const MatterRegularItalic = require('root/public/fonts/Matter/Matter-RegularItalic.eot');
const MatterRegularItalicWoff2 = require('root/public/fonts/Matter/Matter-RegularItalic.woff2');
const MatterRegularItalicWoff = require('root/public/fonts/Matter/Matter-RegularItalic.woff');
const MatterRegularItalicTTF = require('root/public/fonts/Matter/Matter-RegularItalic.ttf');
/** Inter */
const InterRegular2 = require('root/public/fonts/Inter/Inter-Regular.woff2');
const InterRegular = require('root/public/fonts/Inter/Inter-Regular.woff');
const InterItalic2 = require('root/public/fonts/Inter/Inter-Italic.woff2');
const InterItalic = require('root/public/fonts/Inter/Inter-Italic.woff');
const InterMedium2 = require('root/public/fonts/Inter/Inter-Medium.woff2');
const InterMedium = require('root/public/fonts/Inter/Inter-Medium.woff');
const InterMediumItalic2 = require('root/public/fonts/Inter/Inter-MediumItalic.woff2');
const InterMediumItalic = require('root/public/fonts/Inter/Inter-MediumItalic.woff');
const InterSemiBold2 = require('root/public/fonts/Inter/Inter-SemiBold.woff2');
const InterSemiBold = require('root/public/fonts/Inter/Inter-SemiBold.woff');
const InterSemiBoldItalic2 = require('root/public/fonts/Inter/Inter-SemiBoldItalic.woff2');
const InterSemiBoldItalic = require('root/public/fonts/Inter/Inter-SemiBoldItalic.woff');
const InterBold2 = require('root/public/fonts/Inter/Inter-Bold.woff2');
const InterBold = require('root/public/fonts/Inter/Inter-Bold.woff');
const InterBoldItalic2 = require('root/public/fonts/Inter/Inter-BoldItalic.woff2');
const InterBoldItalic = require('root/public/fonts/Inter/Inter-BoldItalic.woff');
/** Material Symbols
 *
 * update the `v` parameter for it in the `font-face` declaration below
 */
const MaterialSymbols = require('root/public/fonts/Material-Symbols/Material-Symbols-Rounded-Wght-300.woff2');

export const MFFonts: React.FC = renderStyle(css`
    @font-face {
        font-family: 'Matter';
        src: url('${MatterBold}');
        src: local('Matter Bold'), local('Matter-Bold'), url('${MatterBoldWoff2}') format('woff2'),
            url('${MatterBoldWoff}') format('woff'), url('${MatterBoldTTF}') format('truetype');
        font-weight: bold;
        font-style: normal;
    }
    @font-face {
        font-family: 'Matter';
        src: url('${MatterBoldItalic}');
        src: local('Matter Bold Italic'), local('Matter-BoldItalic'), url('${MatterBoldItalicWoff2}') format('woff2'),
            url('${MatterBoldItalicWoff}') format('woff'), url('${MatterBoldItalicTTF}') format('truetype');
        font-weight: bold;
        font-style: italic;
    }

    @font-face {
        font-family: 'Matter';
        src: url('${MatterSemiBold}');
        src: local('Matter SemiBold'), local('Matter-SemiBold'), url('${MatterSemiBoldWoff2}') format('woff2'),
            url('${MatterSemiBoldWoff}') format('woff'), url('${MatterSemiBoldTTF}') format('truetype');
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: 'Matter';
        src: url('${MatterMediumItalic}');
        src: local('Matter Medium Italic'), local('Matter-MediumItalic'),
            url('${MatterMediumItalicWoff2}') format('woff2'), url('${MatterMediumItalicWoff}') format('woff'),
            url('${MatterMediumItalicTTF}') format('truetype');
        font-weight: 500;
        font-style: italic;
    }

    @font-face {
        font-family: 'Matter';
        src: url('${MatterHeavy}');
        src: local('Matter Heavy'), local('Matter-Heavy'), url('${MatterHeavyWoff2}') format('woff2'),
            url('${MatterHeavyWoff}') format('woff'), url('${MatterHeavyTTF}') format('truetype');
        font-weight: 900;
        font-style: normal;
    }

    @font-face {
        font-family: 'Matter';
        src: url('${MatterLightItalic}');
        src: local('Matter Light Italic'), local('Matter-LightItalic'), url('${MatterLightItalicWoff2}') format('woff2'),
            url('${MatterLightItalicWoff}') format('woff'), url('${MatterLightItalicTTF}') format('truetype');
        font-weight: 300;
        font-style: italic;
    }

    @font-face {
        font-family: 'Matter';
        src: url('${MatterMedium}');
        src: local('Matter Medium'), local('Matter-Medium'), url('${MatterMediumWoff2}') format('woff2'),
            url('${MatterMediumWoff}') format('woff'), url('${MatterMediumTTF}') format('truetype');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'Matter';
        src: url('${MatterSemiBoldItalic}');
        src: local('Matter SemiBold Italic'), local('Matter-SemiBoldItalic'),
            url('${MatterSemiBoldItalicWoff2}') format('woff2'), url('${MatterSemiBoldItalicWoff}') format('woff'),
            url('${MatterSemiBoldItalicTTF}') format('truetype');
        font-weight: 600;
        font-style: italic;
    }

    @font-face {
        font-family: 'Matter';
        src: url('${MatterHeavyItalic}');
        src: local('Matter Heavy Italic'), local('Matter-HeavyItalic'), url('${MatterHeavyItalicWoff2}') format('woff2'),
            url('${MatterHeavyItalicWoff}') format('woff'), url('${MatterHeavyItalicTTF}') format('truetype');
        font-weight: 900;
        font-style: italic;
    }

    @font-face {
        font-family: 'Matter';
        src: url('${MatterLight}');
        src: local('Matter Light'), local('Matter-Light'), url('${MatterLightWoff2}') format('woff2'),
            url('${MatterLightWoff}') format('woff'), url('${MatterLightTTF}') format('truetype');
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: 'Matter';
        src: url('${MatterRegular}');
        src: local('Matter Regular'), local('Matter-Regular'), url('${MatterRegularWoff2}') format('woff2'),
            url('${MatterRegularWoff}') format('woff'), url('${MatterRegularTTF}') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Matter';
        src: url('${MatterRegularItalic}');
        src: local('Matter Regular Italic'), local('Matter-RegularItalic'),
            url('${MatterRegularItalicWoff2}') format('woff2'), url('${MatterRegularItalicWoff}') format('woff'),
            url('${MatterRegularItalicTTF}') format('truetype');
        font-weight: normal;
        font-style: italic;
    }

    @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('${InterRegular2}?v=3.19') format('woff2'), url('${InterRegular}?v=3.19') format('woff');
    }

    @font-face {
        font-family: 'Inter';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url('${InterItalic2}?v=3.19') format('woff2'), url('${InterItalic}?v=3.19') format('woff');
    }

    @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('${InterMedium2}?v=3.19') format('woff2'), url('${InterMedium}?v=3.19') format('woff');
    }
    @font-face {
        font-family: 'Inter';
        font-style: italic;
        font-weight: 500;
        font-display: swap;
        src: url('${InterMediumItalic2}?v=3.19') format('woff2'), url('${InterMediumItalic}?v=3.19') format('woff');
    }

    @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url('${InterSemiBold2}?v=3.19') format('woff2'), url('${InterSemiBold}?v=3.19') format('woff');
    }
    @font-face {
        font-family: 'Inter';
        font-style: italic;
        font-weight: 600;
        font-display: swap;
        src: url('${InterSemiBoldItalic2}?v=3.19') format('woff2'), url('${InterSemiBoldItalic}?v=3.19') format('woff');
    }
    @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('${InterBold2}?v=3.19') format('woff2'), url('${InterBold}?v=3.19') format('woff');
    }

    @font-face {
        font-family: 'Inter';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url('${InterBoldItalic2}?v=3.19') format('woff2'), url('${InterBoldItalic}?v=3.19') format('woff');
    }
    /* Material Symbols Rounded, weight 300, optical size 24, grade 0, fill 0..1 */
    @font-face {
        font-family: 'Material Symbols Rounded';
        font-style: normal;
        src: url('${MaterialSymbols}?v=3') format('woff2');
    }
`);
