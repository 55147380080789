import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import moment from 'moment-timezone';
import styled from 'styled-components';

import {s6} from 'web-app/styleguide/spacing';
import Button, {ButtonSize} from 'web-app/react/components/form-elements/button/button';
import Close from 'web-app/react/components/icons/legacy/lazy-icons/close';
import {Body} from 'web-app/react/components/text/text';
import Legend from 'web-app/react/components/legend/legend';
import {PortalModal} from 'web-app/react/components/modals/modals';
import TitledContainer from 'web-app/react/components/container/titled-container';
import {Base} from 'web-app/react/components/layout/layout';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import FormDayPicker from 'web-app/react/components/form-elements/containers/form-day-picker';

const DayPickerModal = styled(({className, ...props}) => <PortalModal {...props} containerClassName={className} />)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DayPickerButton = styled(Button)`
    &:active,
    &:focus {
        outline: none;
    }
`;

const DayPickerContainer = styled(TitledContainer)`
    position: relative;
`;

const SpinnerContainer = styled(Base)`
    position: absolute;
    bottom: 0;
    right: 12px;
`;

class FormDayPickerModal extends React.Component {
    state = {
        showDayPickerModal: false,
    };

    render() {
        const {showDayPickerModal} = this.state;

        const {loading, ...dayPickerProps} = this.props;

        const {input, valueFormat, placeholder, onChange, disabled, legendProps} = dayPickerProps;

        const {value} = input;

        const momentValue = value ? moment(value, valueFormat) : null;

        const formattedValue = momentValue && momentValue.isValid() ? momentValue.format('LL') : null;
        const dateValue = formattedValue || placeholder;
        const headerValue = formattedValue || i18next.t('selectADate');

        const closeModal = () =>
            this.setState({
                showDayPickerModal: false,
            });

        const closeButton = showDayPickerModal ? (
            <Button
                buttonSize={ButtonSize.mini}
                appearance="icon"
                box="inline"
                isDisabled={disabled}
                onClick={closeModal}
            >
                <Close />
            </Button>
        ) : null;

        return (
            <React.Fragment>
                <DayPickerButton
                    buttonSize={ButtonSize.mini}
                    appearance="none"
                    box="inline"
                    onClick={() =>
                        this.setState({
                            showDayPickerModal: true,
                        })
                    }
                >
                    <Body disabled={!formattedValue}>{dateValue}</Body>
                </DayPickerButton>
                {showDayPickerModal ? (
                    <DayPickerModal size="tiny" fullScreenOnMobile={false} onCloseModal={closeModal}>
                        <DayPickerContainer
                            title={headerValue}
                            rightTitleBarElement={closeButton}
                            extraPadding
                            noHeaderMargin
                        >
                            <FormDayPicker
                                {...dayPickerProps}
                                onChange={value => {
                                    if (onChange) {
                                        onChange(value);
                                    }
                                    closeModal();
                                }}
                            />
                            {legendProps ? <Legend marginTop={s6} {...legendProps} /> : null}
                            {loading ? (
                                <SpinnerContainer>
                                    <Spinner />
                                </SpinnerContainer>
                            ) : null}
                        </DayPickerContainer>
                    </DayPickerModal>
                ) : null}
            </React.Fragment>
        );
    }
}

const dayPickerPropTypes = {
    input: PropTypes.object, // redux-form.
    legendProps: PropTypes.object,
    modifiers: PropTypes.object,
    onChange: PropTypes.func,
    valueFormat: PropTypes.string,
    placeholder: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
};

const dayPickerDefaultProps = {
    valueFormat: 'YYYY-MM-DD',
};

FormDayPickerModal.propTypes = dayPickerPropTypes;
FormDayPickerModal.defaultProps = dayPickerDefaultProps;

export default FormDayPickerModal;
