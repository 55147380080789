import gql from 'graphql-tag';

import {StatFeatureCustomFte} from 'web-app/react/staffing-attendance/graphql/fragment/stat-feature-custom-fte.fragment';
import {StatFeatureCustomRegistrationForms} from 'web-app/react/staffing-attendance/graphql/fragment/stat-feature-custom-registration-forms.fragment';
import {StatFeatureHideNewEnquiryButtonFeature} from 'web-app/react/staffing-attendance/graphql/fragment/stat-feature-hide-new-enquiry-button.fragment';
import {StatFeatureHolidayLeaveAllowanceFeature} from 'web-app/react/staffing-attendance/graphql/fragment/stat-feature-holiday-leave-allowance.fragment';
import {StatFeatureKinderConnect} from 'web-app/react/staffing-attendance/graphql/fragment/stat-feature-kinder-connect.fragment';
import {StatFeatureLeaveRanges} from 'web-app/react/staffing-attendance/graphql/fragment/stat-feature-leave-range.fragment';
import {StatFeatureLeaveRequests} from 'web-app/react/staffing-attendance/graphql/fragment/stat-feature-leave-requests.fragment';
import {StatFeatureLeave} from 'web-app/react/staffing-attendance/graphql/fragment/stat-feature-leave.fragment';
import {StatFeatureMilkReport} from 'web-app/react/staffing-attendance/graphql/fragment/stat-feature-milk-report.fragment';
import {StatFeaturePartDayLeave} from 'web-app/react/staffing-attendance/graphql/fragment/stat-feature-part-day-leave.fragment';
import {StatFeatureShiftPlanner} from 'web-app/react/staffing-attendance/graphql/fragment/stat-feature-shift-planner.fragment';
import {StatFeatureStaffAttendancePage} from 'web-app/react/staffing-attendance/graphql/fragment/stat-feature-staff-attendance-page.fragment';
import {StatFeatureStaffHours} from 'web-app/react/staffing-attendance/graphql/fragment/stat-feature-staff-hours.fragment';
import {StatFeatureStaffLeaveApi} from 'web-app/react/staffing-attendance/graphql/fragment/stat-feature-staff-leave-api.fragment';
import {StatFeatureStaffRotas} from 'web-app/react/staffing-attendance/graphql/fragment/stat-feature-staff-rotas.fragment';
import {StatFeatureStaffSignInOut} from 'web-app/react/staffing-attendance/graphql/fragment/stat-feature-staff-sign-in-out.fragment';
import {StatFeatureSubType} from 'web-app/react/staffing-attendance/graphql/fragment/stat-feature-subtype.fragment';

export const StatFeatures = gql`
    query StatFeatures($institutionSetId: InstitutionSetId!, $siteSetId: SiteSetId!) {
        staffhours {
            featureSettings {
                leave(siteSetId: $institutionSetId) {
                    ...StatFeatureLeave
                }
                leaveRequest(siteSetId: $institutionSetId) {
                    ...StatFeatureLeaveRequests
                }
                partDayLeave(siteSetId: $institutionSetId) {
                    ...StatFeaturePartDayLeave
                }
                staffHours(siteSetId: $institutionSetId) {
                    ...StatFeatureStaffHours
                }
                holidayLeaveAllowance(siteSetId: $institutionSetId) {
                    ...StatFeatureHolidayLeaveAllowanceFeature
                }
                staffAttendancePage(siteSetId: $siteSetId) {
                    ...StatFeatureStaffAttendancePage
                }
                staffRotas(siteSetId: $institutionSetId) {
                    ...StatFeatureStaffRotas
                }
                subtype(siteSetId: $institutionSetId) {
                    ...StatFeatureSubType
                }
                staffLeaveAPI(siteSetId: $institutionSetId) {
                    ...StatFeatureStaffLeaveApi
                }
                leaveRangeSettings(siteSetId: $institutionSetId) {
                    ...StatFeatureLeaveRanges
                }
                employeeCheckIn(siteSetId: $institutionSetId) {
                    ...StatFeatureStaffSignInOut
                }
                shiftplanner(siteSetId: $institutionSetId) {
                    ...StatFeatureShiftPlanner
                }
            }
        }
        attendance {
            featureSettings {
                customFte(siteSetId: $institutionSetId) {
                    ...StatFeatureCustomFte
                }
                inquiry(siteSetId: $siteSetId) {
                    ...StatFeatureHideNewEnquiryButtonFeature
                    hideEnquiryCSVDownload
                }
                milkReport(siteSetId: $institutionSetId) {
                    ...StatFeatureMilkReport
                }
                kinderConnect(siteSetId: $institutionSetId) {
                    ...StatFeatureKinderConnect
                }
                customRegistrationForms(siteSetId: $institutionSetId) {
                    ...StatFeatureCustomRegistrationForms
                }
            }
        }
    }
    ${StatFeatureLeave}
    ${StatFeatureLeaveRequests}
    ${StatFeaturePartDayLeave}
    ${StatFeatureStaffHours}
    ${StatFeatureHolidayLeaveAllowanceFeature}
    ${StatFeatureStaffRotas}
    ${StatFeatureCustomFte}
    ${StatFeatureHideNewEnquiryButtonFeature}
    ${StatFeatureSubType}
    ${StatFeatureMilkReport}
    ${StatFeatureKinderConnect}
    ${StatFeatureStaffLeaveApi}
    ${StatFeatureCustomRegistrationForms}
    ${StatFeatureLeaveRanges}
    ${StatFeatureStaffSignInOut}
    ${StatFeatureShiftPlanner}
    ${StatFeatureStaffAttendancePage}
`;
