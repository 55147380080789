import {Record} from 'immutable';

import {makeReducer, match} from 'web-app/util/redux';

import * as Actions from './actions';

export class EmployeeCheckOutState extends Record<{
    readonly isCheckingOut: boolean;
}>({
    isCheckingOut: false,
}) {}

export default makeReducer<EmployeeCheckOutState>(
    [
        match(Actions.checkOutEmployee, state =>
            state.merge({
                isCheckingOut: true,
            }),
        ),
        match(Actions.checkOutEmployeeSuccess, state =>
            state.merge({
                isCheckingOut: false,
            }),
        ),
        match(Actions.checkOutEmployeeFailed, state =>
            state.merge({
                isCheckingOut: false,
            }),
        ),
    ],
    new EmployeeCheckOutState(),
);
