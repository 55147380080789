import {type QueryHookOptions} from '@apollo/client';

import {FetchStaffHoursPermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-staff-hours-permissions.query';
import {
    type FetchStaffHoursPermissionsQuery,
    type FetchStaffHoursPermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-staff-hours-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchStaffHoursPermissions(
    options: QueryHookOptions<FetchStaffHoursPermissionsQuery, FetchStaffHoursPermissionsQueryVariables>,
): ImmerWrappedQueryResult<FetchStaffHoursPermissionsQuery, FetchStaffHoursPermissionsQueryVariables> {
    return useStatQuery<FetchStaffHoursPermissionsQuery, FetchStaffHoursPermissionsQueryVariables>(
        FetchStaffHoursPermissions,
        options,
    );
}
