import {createSubscribableMutator} from 'web-app/react/hooks/create-subscribable-mutator';

import {SaveCustomRegistrationForm} from '../../graphql/mutations/save-custom-registration-form.query';
import {
    type SaveCustomRegistrationFormMutation,
    type SaveCustomRegistrationFormMutationVariables,
} from '../../graphql/mutations/__generated__/save-custom-registration-form.query.api-types';

export const {
    useSubscribableMutation: useSubscribableSaveCustomRegistrationFormMutation,
    useSubscribeMutationResult: useSubscribableSaveCustomRegistrationFormMutationResult,
} = createSubscribableMutator<SaveCustomRegistrationFormMutation, SaveCustomRegistrationFormMutationVariables>(
    SaveCustomRegistrationForm,
);
