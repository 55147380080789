import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import {List} from 'immutable';
import {compose} from 'redux';
import {connect} from 'react-redux';
import styled, {css} from 'styled-components';

import ProfileImage from 'web-app/react/components/profile-image/profile-image';
import {Caption, Body} from 'web-app/react/components/text/text';
import ActionSheet, {shouldUseActionSheet} from 'web-app/react/components/action-sheet/action-sheet';
import Arrow from 'web-app/react/components/icons/legacy/arrows';
import NewLookTheme from 'web-app/styleguide/themes/new-look';
import {CLASSNAME_PREFIX} from 'web-app/react/global-styles/select-style';

import * as Selectors from './selectors';

export const OptionsRenderer = props => {
    const {showSubtitle, option} = props;

    return (
        <div className={classNames('clearfix', `${CLASSNAME_PREFIX}dropdownOption`)}>
            {option.image || option.roundImage ? (
                <div className={`${CLASSNAME_PREFIX}dropdownOption__avatar`}>
                    {option.roundImage ? (
                        <ProfileImage src={option.image} />
                    ) : (
                        <img className={`${CLASSNAME_PREFIX}image`} src={option.image} />
                    )}
                </div>
            ) : null}
            <div className={`${CLASSNAME_PREFIX}dropdownOption__content`}>
                <div className={`${CLASSNAME_PREFIX}dropdownOption__title`}>
                    <div>{option.label}</div>
                </div>
                {showSubtitle && option.subtitle ? (
                    <div className={`${CLASSNAME_PREFIX}dropdownOption__subtitle`}>{option.subtitle}</div>
                ) : null}
            </div>
        </div>
    );
};

OptionsRenderer.propTypes = {
    option: PropTypes.any,
    showSubtitle: PropTypes.any,
};

OptionsRenderer.defaultProps = {
    showSubtitle: true,
};

const Category = styled.div`
    background-color: ${props => props.theme.invertedText};
    padding: 8px 16px 4px 10px;
    position: sticky;
    top: 0;
    z-index: 2;
`;

export const StyledDropdownItems = styled.div``;

const Container = styled.div`
    ${props =>
        props.highlight
            ? css`
                  position: relative;

                  &:before {
                      pointer-events: none;
                      content: '';
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      left: -4px;
                      right: -4px;
                      border: 1px solid ${props => props.theme[props.highlightColor]};
                  }
              `
            : ''}
`;

class DropdownItem extends React.PureComponent {
    static propTypes = {
        renderer: PropTypes.func.isRequired,
        handleClick: PropTypes.func.isRequired,
        option: PropTypes.object.isRequired,
    };
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        this.props.handleClick(this.props.option);
    }
    render() {
        return (
            <div
                className={classNames('dropdownItem', `${CLASSNAME_PREFIX}dropdownValue`)}
                onClick={this.handleClick}
                data-e2e-class="dropdown-option"
            >
                {this.props.renderer(this.props.option)}
            </div>
        );
    }
}

class Dropdown extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.renderOption = this.renderOption.bind(this);
        this.getDisplayValue = this.getDisplayValue.bind(this);
    }

    handleClose() {
        this.setState({
            open: false,
        });
    }

    handleClickOutside() {
        if (!shouldUseActionSheet()) {
            this.handleClose();
        }
    }

    handleChange(option) {
        if (this.props.onChange) {
            this.props.onChange({
                value: option.id,
                ...option,
            });
        }
        this.handleClose();
    }

    renderOption(option, showSubtitle = true) {
        if (this.props.optionRenderer || this.props.extendedOptionRender) {
            const Renderer = this.props.optionRenderer || OptionsRenderer;

            return <Renderer option={option} showSubtitle={showSubtitle} />;
        }

        return <Body>{option.label}</Body>;
    }

    getDisplayValue() {
        const displayValue = this.props.options.find(opt => opt.value === this.props.value);
        if (!displayValue) {
            return this.props.placeholder ? (
                <div className={`${CLASSNAME_PREFIX}placeholder`}>{this.props.placeholder}</div>
            ) : null;
        }

        if (this.props.displayRenderer) {
            return this.props.displayRenderer(displayValue);
        }

        return this.renderOption(displayValue, false);
    }

    handleToggle() {
        if (!this.props.disabled) {
            this.setState({open: !this.state.open});
        }
    }

    render() {
        const {
            highlight,
            highlightColor,
            disabled,
            className,
            options,
            hideArrow,
            alignRight,
            slim,
            optionsClassName,
            itemsForActionSheet,
            customRootId,
            e2eId,
        } = this.props;

        const {open} = this.state;

        return (
            <Container
                highlight={highlight}
                highlightColor={highlightColor}
                data-e2e-id={e2eId}
                className={classNames(
                    `${CLASSNAME_PREFIX}dropdown`,
                    'ignore-hammer-pull-to-refresh',
                    {[`${CLASSNAME_PREFIX}disabled`]: disabled},
                    {[className]: className},
                )}
            >
                <div
                    className={`${CLASSNAME_PREFIX}dropdownSelectedValue`}
                    data-e2e-class="dropdown-selected-value"
                    onClick={this.handleToggle}
                >
                    {this.getDisplayValue()}
                    {disabled || hideArrow ? null : (
                        <div className={`${CLASSNAME_PREFIX}arrowContainer`}>
                            <Arrow direction="south" size={14} fill={NewLookTheme.text} />
                        </div>
                    )}
                </div>
                {open && shouldUseActionSheet() ? (
                    <ActionSheet
                        handleClose={this.handleClose}
                        onItemClick={this.handleChange}
                        items={itemsForActionSheet}
                        customRootId={customRootId}
                    />
                ) : null}
                {open && !shouldUseActionSheet() ? (
                    <StyledDropdownItems
                        className={classNames(`${CLASSNAME_PREFIX}dropdownItems`, optionsClassName, {
                            [`${CLASSNAME_PREFIX}alignRight`]: alignRight,
                            [`${CLASSNAME_PREFIX}slim`]: slim,
                        })}
                    >
                        {List(options)
                            .groupBy(opt => opt.category)
                            .map((opts, cat) => {
                                return (
                                    <React.Fragment key={cat || 'default'}>
                                        {cat ? (
                                            <Category key={`category-${cat}`}>
                                                <Caption emphasized>{cat}</Caption>
                                            </Category>
                                        ) : null}
                                        {opts.map(opt => (
                                            <DropdownItem
                                                key={opt.value}
                                                option={opt}
                                                renderer={this.renderOption}
                                                handleClick={this.handleChange}
                                            />
                                        ))}
                                    </React.Fragment>
                                );
                            })
                            .toList()
                            .toArray()}
                    </StyledDropdownItems>
                ) : null}
            </Container>
        );
    }
}

Dropdown.propTypes = {
    value: PropTypes.any,
    highlight: PropTypes.bool,
    highlightColor: PropTypes.string,
    extendedOptionRender: PropTypes.bool,
    optionRenderer: PropTypes.func,
    displayRenderer: PropTypes.func,
    placeholder: PropTypes.node,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    options: PropTypes.any,
    onChange: PropTypes.func,
    hideArrow: PropTypes.bool,
    alignRight: PropTypes.bool,
    slim: PropTypes.bool,
    optionsClassName: PropTypes.string,
    itemsForActionSheet: PropTypes.instanceOf(List),
    customRootId: PropTypes.string,
    e2eId: PropTypes.string,
};

Dropdown.defaultProps = {
    highlightColor: 'accent3',
};

export default compose(
    connect(() => {
        const itemsForActionSheet = Selectors.makeItemsForActionSheet();
        return (_, {options}) => ({
            itemsForActionSheet: itemsForActionSheet(_, {options}),
        });
    }),
    onClickOutside,
)(Dropdown);
