import {ofType, type Epic, type StateObservable, type ActionsObservable} from 'redux-observable';
import {merge, of} from 'rxjs';
import {mergeMap, map, catchError, filter, flatMap} from 'rxjs/operators';
import {LOCATION_CHANGE, replace, type LocationChangeAction} from 'connected-react-router';

import {combineEpics} from 'web-app/util/redux-observable';
import DayCareApi from 'signin-app/api/daycare-api';
import * as GlobalEventActions from 'signin-app/global-event/actions';
import {type Action} from 'web-app/util/redux/action-types';
import {type RootState} from 'signin-app/redux/main-reducer';
import {type SignInEpic} from 'signin-app/redux/types';
import RoutesMap from 'signin-app/routes/routes-map';
import {fetchOverview, fetchOverviewSuccess, fetchOverviewFailed} from 'signin-app/groups/actions';
import {checkInChildSuccess, resetPickup} from 'signin-app/child/check-in/actions';
import {checkOutChildSuccess} from 'signin-app/child/check-out/actions';
import {checkInEmployeeSuccess} from 'signin-app/employee/check-in/actions';
import {checkOutEmployeeSuccess} from 'signin-app/employee/check-out/actions';
import * as LoginSelectors from 'signin-app/login/selectors';
import {isCompletelyLoggedIn} from 'signin-app/pin/selectors';
import {nextPersonUrl} from 'signin-app/child/selectors';

import * as Actions from './actions';

const pinLogin: SignInEpic = action$ =>
    action$.pipe(
        ofType(Actions.pinLogin.type),
        mergeMap(({payload}: ReturnType<typeof Actions.pinLogin.action>) =>
            DayCareApi.pinLogin(payload.pin).pipe(
                map(response => {
                    return Actions.pinLoginSuccess.action(response);
                }),
                catchError(e =>
                    merge(of(GlobalEventActions.updateError.action(e.error)), of(Actions.pinLoginFailed.action(e))),
                ),
            ),
        ),
    );

const institutionOverview: SignInEpic = action$ =>
    action$.pipe(
        ofType(fetchOverview.type),
        mergeMap(({payload}: ReturnType<typeof fetchOverview.action>) =>
            DayCareApi.groups(payload.accessToken).pipe(
                map(overview => fetchOverviewSuccess.action(overview)),
                catchError(e =>
                    merge(of(fetchOverviewFailed.action()), of(GlobalEventActions.updateError.action(e.error))),
                ),
            ),
        ),
    );

const clearPin = (action$: ActionsObservable<LocationChangeAction>, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(LOCATION_CHANGE),
        filter(({payload}) => {
            const isLoggedIn = isCompletelyLoggedIn(state$.value);
            return payload.location.pathname === RoutesMap.pin && isLoggedIn;
        }),
        map(() => {
            return Actions.pinLogout.action();
        }),
    );

const redirectOnSuccess: Epic<LocationChangeAction | Action<any>, any, RootState> = (action$, state$) =>
    action$.pipe(
        ofType(
            checkInChildSuccess.type,
            checkOutChildSuccess.type,
            checkInEmployeeSuccess.type,
            checkOutEmployeeSuccess.type,
        ),
        flatMap(
            (
                action: ReturnType<
                    | typeof checkInChildSuccess.action
                    | typeof checkOutChildSuccess.action
                    | typeof checkInEmployeeSuccess.action
                    | typeof checkOutEmployeeSuccess.action
                >,
            ) => {
                const pinApp = LoginSelectors.isPinApp(state$.value);
                const pinRedirectUrl = nextPersonUrl(state$.value, action.payload.response);
                const redirectToUrl = pinApp ? pinRedirectUrl : RoutesMap.overview;
                const actions = [replace(redirectToUrl)];
                return actions;
            },
        ),
    );

const updateChildOnSuccess: SignInEpic = (action$, state$) =>
    action$.pipe(
        ofType(checkInChildSuccess.type, checkOutChildSuccess.type),
        filter(() => LoginSelectors.isPinApp(state$.value)),
        flatMap(({payload}: ReturnType<typeof checkInChildSuccess.action | typeof checkOutChildSuccess.action>) => {
            // child-checkout endpoint returns an array
            const childPayload = Array.isArray(payload) ? payload[0] : payload;
            return [resetPickup.action(), Actions.updateChild.action(childPayload)];
        }),
    );

const updateEmployeeOnSuccess: SignInEpic = (action$, state$) =>
    action$.pipe(
        ofType(checkInEmployeeSuccess.type, checkOutEmployeeSuccess.type),
        filter(() => LoginSelectors.isPinApp(state$.value)),
        flatMap(
            ({payload}: ReturnType<typeof checkInEmployeeSuccess.action | typeof checkOutEmployeeSuccess.action>) => {
                return [Actions.updateEmployee.action(payload)];
            },
        ),
    );

export default combineEpics<Action<any>, Action<any>, RootState>(
    pinLogin,
    institutionOverview,
    clearPin,
    redirectOnSuccess,
    updateChildOnSuccess,
    updateEmployeeOnSuccess,
);
