import {type DependencyList, useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {type ActionDescription} from 'web-app/util/redux/action-types';

/**
 * Use this to easily bind your actions to react-redux dispatch, all while
 * preserving type interface!
 *
 * Instead of doing this:
 * ```
 * const dispatch = useDispatch();
 * const foo = React.useCallback(() => dispatch(MyAction.action), [dispatch]);
 * ```
 *
 * You can do this:
 * ```
 * const foo = useBindDispatch(Actions.bar);
 * ```
 */
export const useBindDispatch = <Action extends ActionDescription<any>>(action: Action) => {
    return useDispatchCallback(
        dispatch => {
            return (...args: Parameters<typeof action['action']>) => {
                dispatch(action.action(...args));
            };
        },
        [action],
    );
};

type MakeCallback<T extends (...args: any[]) => any> = (dispatch: ReturnType<typeof useDispatch>) => T;

/**
 * Use this to create custom callbacks that involve dispatch.
 *
 * ```
 * const foo = useMakeDispatch(dispatch => (fizz: string, buzz: string) => {
 *   // do something with fizz and buzz
 *   dispatch(Actions.bar);
 *   return {fizz, buzz};
 * })
 * const foo: (fizz: string, buzz: string) => {fizz: string; buzz: string}
 * ```
 */
export const useDispatchCallback = <Callback extends (...args: any[]) => any>(
    makeCallback: MakeCallback<Callback>,
    dependencyList: DependencyList,
) => {
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(makeCallback(dispatch), [dispatch, ...dependencyList]);
};
