import gql from 'graphql-tag';

import {CustomRegistrationFormDescription} from '../fragment/generics/custom-registration-form-description.fragment';

export const GetCustomFormDescription = gql`
    query GetCustomFormDescription($siteSetId: SiteSetId!, $formId: CustomRegistrationFormId!) {
        attendance {
            customRegistrationForm {
                description(siteSetId: $siteSetId, formId: $formId) {
                    ...CustomRegistrationFormDescription
                }
            }
        }
    }
    ${CustomRegistrationFormDescription}
`;
