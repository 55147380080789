import {type MutationUpdaterFn, type MutationHookOptions, useMutation} from '@apollo/client';
import {type DocumentNode} from 'graphql';
import React from 'react';

import {REST_GRAPHQL_CLIENTNAME} from 'web-app/api/clients/constants';
import {
    isDiscriminatedProps,
    toDiscriminatedProps,
    updateApolloCache,
    type UpdateApolloCache,
} from 'web-app/util/graphql';
import {type NonEmptyArray} from 'web-app/util/non-empty-array';
import {hasValue} from 'web-app/util/typescript';
import generateUUID from 'web-app/util/uuid';

export interface SubscribableMutatorOptions<TData, TVariables> {
    withRest?: boolean;
    useCustomMutationHook?: typeof useMutation<TData, TVariables>;
}

/**
 * Create a mutation callback that other components can "subscribe" to to update their part
 * of the cache.
 *
 * @example
 * const {useSubscribableMutation, useUpdateCache} = createSubscribableMutator<CreateFooMutation, CreateFooMutationVariables>();
 *
 * MutatorComponent() {
 *  const [mutate, {loading}] = useSubscribableMutation(CreateFoo);
 *  return (
 *    <button onClick={mutate} />
 *  )
 * }
 *
 * OtherComponent() {
 *   useUpdateCache<ListFooQuery, ListFooQueryVariables>({
 *     cacheQuery: ListFoo,
 *     cacheVariables: {...},
 *     updater: (cachedData, result) => [
 *       result,
 *       ...cachedData,
 *     ]
 *   })
 *  ...
 * }
 */
export const createSubscribableMutator = <TData, TVariables>(
    mutation: DocumentNode,
    subscribableMutatorOptions?: SubscribableMutatorOptions<TData, TVariables>,
) => {
    const updaters: {[id: string]: MutationUpdaterFn<TData>} = {};
    const resultSubscriptions: ((
        result: TData | null | undefined,
        options?: MutationHookOptions<TData, TVariables>,
    ) => void)[] = [];

    const useSubscribableMutation = (options?: MutationHookOptions<TData, TVariables>) => {
        return (subscribableMutatorOptions?.useCustomMutationHook ?? useMutation)(mutation, {
            ...(options || {}),
            context: {
                ...(options || {}),
                clientName: subscribableMutatorOptions?.withRest ? REST_GRAPHQL_CLIENTNAME : undefined,
            },
            update: (proxy, result, callOptions) => {
                if (hasValue(options) && hasValue(options.update)) {
                    options.update(proxy, result, {});
                }
                Object.values(updaters).forEach(updater => updater(proxy, result));
                resultSubscriptions.forEach(subscription => subscription(result.data, callOptions));
            },
        });
    };

    type UpdateCacheArg<CacheQuery, CacheVariables> = UpdateApolloCache<CacheQuery, CacheVariables, TData>;
    const useUpdateCache = <CacheQuery, CacheVariables>(
        ...args: NonEmptyArray<UpdateCacheArg<CacheQuery, CacheVariables>>
    ) => {
        React.useEffect(() => {
            const updateTuples = args.map(arg => {
                /**
                 * For some reason Typescript cannot handle the union type in `UpdateApolloCache`
                 * but we can convert the union to the type DiscriminatedProps without any downsides
                 * and have everything compile safely.
                 */
                const discriminatedProps = isDiscriminatedProps(arg) ? arg : toDiscriminatedProps(arg);
                return [generateUUID(), updateApolloCache<CacheQuery, CacheVariables>()(discriminatedProps)] as const;
            });
            updateTuples.forEach(([id, updater]) => {
                updaters[id] = updater;
            });
            return () => {
                updateTuples.forEach(([id]) => {
                    delete updaters[id];
                });
            };
        }, [args]);
    };

    const useSubscribeMutationResult = (
        cb: (result: TData | null | undefined, options?: MutationHookOptions<TData, TVariables>) => void,
    ) => {
        const cancelCallback = React.useCallback(() => {
            resultSubscriptions.splice(
                resultSubscriptions.findIndex(subscription => subscription === cb),
                1,
            );
        }, [cb]);

        React.useEffect(() => {
            resultSubscriptions.push(cb);
            return cancelCallback;
        }, [cancelCallback, cb]);
    };

    return {useSubscribableMutation, useUpdateCache, useSubscribeMutationResult};
};

/**
 * Simple wrapper for the creation for subscribable mutators for rest calls via graphql.
 *
 * @see createSubscribableMutator
 */
export const createSubscribableRestMutator = <TData, TVariables>(
    mutation: DocumentNode,
    subscribableMutatorOptions?: Pick<SubscribableMutatorOptions<TData, TVariables>, 'useCustomMutationHook'>,
) =>
    createSubscribableMutator<TData, TVariables>(mutation, {
        withRest: true,
        useCustomMutationHook: subscribableMutatorOptions?.useCustomMutationHook,
    });
