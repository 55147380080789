import {Record} from 'immutable';

import {makeReducer, match} from 'web-app/util/redux';

import * as Actions from './actions';

export class PrefetchState extends Record<{
    readonly isPrefetching: boolean;
    readonly didPrefetch: boolean;
    readonly lastPrefetch: Date | null;
}>({
    isPrefetching: false,
    didPrefetch: false,
    lastPrefetch: null,
}) {}

export default makeReducer<PrefetchState>(
    [
        match(Actions.prefetch, state => {
            return state.merge({
                isPrefetching: true,
            });
        }),
        match(Actions.prefetchSuccess, state => {
            const date = new Date();
            return state.merge({
                isPrefetching: false,
                didPrefetch: true,
                lastPrefetch: date,
            });
        }),
        match(Actions.prefetchFailed, state => {
            return state.merge({
                isPrefetching: false,
            });
        }),
    ],
    new PrefetchState(),
);
