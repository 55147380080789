import gql from 'graphql-tag';

export const StatPermissionDraftShiftManagementType = gql`
    fragment StatPermissionDraftShiftManagementType on DraftShiftManagementType {
        canView
        canCreate
        canEdit
        canDelete
        canBulkDelete
    }
`;
