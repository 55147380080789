import React from 'react';
import Button from '@mui/material/Button';

export type Props = {
    text: string;
    onClick?: VoidFunction;
};

export const TestComponent: React.FC<Props> = ({text, onClick}) => {
    return <Button onClick={onClick}>{text}</Button>;
};
