import {List} from 'immutable';

import createSelector from 'web-app/util/custom-create-selector';

export const makeItemsForActionSheet = () =>
    createSelector(
        (_, props) => props.options,
        options =>
            List(options).map((opt: any) => ({
                ...opt,
                title: opt.label,
                id: opt.value,
                icon: opt.image,
                roundIcon: opt.roundImage,
            })),
    );
