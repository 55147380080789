import moment from 'moment-timezone';

import {isNonEmpty, head, map, sort} from 'web-app/util/non-empty-array';
import {exhaustiveCheck, hasValue} from 'web-app/util/typescript';
import * as APIConstants from 'web-app/api/constants';

import type * as Types from './types';

export const hasAdministrationSchedule = (
    medicationForm: Types.MedicationFormForHelpers,
): medicationForm is Types.MedicationFormWithSchedule => {
    return hasValue(medicationForm.time) && isNonEmpty(medicationForm.time);
};

const hasUpdatedAdministrationSchedule = (
    medicationForm: Types.MedicationFormForHelpers,
): medicationForm is Types.MedicationFormWithUpdatedSchedule => {
    return hasValue(medicationForm.dailyUpdateForToday);
};

/**
 * Helper to return all times that a medicine should be given today but still needs to be administered
 */
export const getAvailableAdministrationSchedule = (medicationForm: Types.MedicationFormForHelpers) => {
    const administrationSchedule = getDailyAdministrationSchedule(medicationForm);
    switch (administrationSchedule.type) {
        case 'no-schedule':
        case 'missing-daily-update':
            return administrationSchedule;
        case 'has-schedule': {
            const administrationsForToday = getAdministrationsForToday(medicationForm);
            const availableAdministrations = administrationSchedule.times.filter(time => {
                return !administrationsForToday.some(administration => administration.scheduledAt === time);
            });
            if (!isNonEmpty(availableAdministrations)) {
                return {
                    type: 'schedule-completed' as const,
                    times: null,
                };
            }
            return {
                type: 'has-schedule' as const,
                times: availableAdministrations,
            };
        }
        default:
            exhaustiveCheck(administrationSchedule);
            return administrationSchedule;
    }
};

/**
 * Helper to return all times that a medicine should be given today
 */
export const getDailyAdministrationSchedule = (medicationForm: Types.MedicationFormForHelpers) => {
    if (medicationForm.dailyNotifications) {
        if (!hasUpdatedAdministrationSchedule(medicationForm)) {
            return {
                type: 'missing-daily-update' as const,
                times: medicationForm.time,
            };
        }
        if (!isNonEmpty(medicationForm.dailyUpdateForToday.times)) {
            return {
                type: 'no-schedule' as const,
                times: null,
            };
        }
        return {
            type: 'has-schedule' as const,
            times: sort(medicationForm.dailyUpdateForToday.times),
        };
    }
    if (!hasAdministrationSchedule(medicationForm)) {
        return {
            type: 'no-schedule' as const,
            times: null,
        };
    }
    return {
        type: 'has-schedule' as const,
        times: sort(medicationForm.time),
    };
};

export const getDailyAdministrationStatus = (medicationForm: Types.MedicationFormForHelpers, timezone: string) => {
    const dailyAdministrationSchedule = getDailyAdministrationSchedule(medicationForm);
    switch (dailyAdministrationSchedule.type) {
        case 'has-schedule': {
            const dailyAdministrations = getAdministrationsForToday(medicationForm);
            return {
                type: dailyAdministrationSchedule.type,
                times: map(dailyAdministrationSchedule.times, time => {
                    return {
                        time,
                        formattedTime: moment.tz(time, APIConstants.API_LOCAL_TIME_FORMAT, timezone).format('LT'),
                        administered:
                            dailyAdministrations.findIndex(administration => administration.scheduledAt === time) > -1,
                    };
                }),
            };
        }
        case 'missing-daily-update':
        case 'no-schedule':
            return dailyAdministrationSchedule;
        default:
            exhaustiveCheck(dailyAdministrationSchedule);
            return dailyAdministrationSchedule;
    }
};

export const getFirstAvailableAdministration = (medicationForm: Types.MedicationFormForHelpers) => {
    const availableAdministrationSchedule = getAvailableAdministrationSchedule(medicationForm);
    switch (availableAdministrationSchedule.type) {
        case 'has-schedule':
            return head(availableAdministrationSchedule.times);
        default:
            return null;
    }
};

export const getAdministrationsForToday = (medicationForm: Types.MedicationFormForHelpers) => {
    const today = moment();
    return medicationForm.administeredMedication.results.filter(administration => {
        return moment(administration.administeredAt).isSame(today, 'days');
    });
};

const getParentAdministration = (medicationForm: Types.MedicationFormForHelpers) => {
    return medicationForm.dailyUpdateForToday ?? null;
};

export const getLatestAdministrationForToday = (medicationForm: Types.MedicationFormForHelpers, timezone: string) => {
    /**
     * Be aware that there's a "flaw" in the backend implementation where `administrations`.administeredAt is UTC format
     * YYYY-MM-DDTHH:mm:ssZ where as `parentAdministration`.lastAdministeredAt contains _no_ zone information (YYYY-MM-DDTHH:mm:ss)
     * but is actually zoned to the nursery.
     *
     * This is handled in this helper by converting parentAdministration.lastAdministeredAt to UTC so they follow
     * the same format as normal administrations.
     */
    const administrations = getAdministrationsForToday(medicationForm);
    const parentAdministration = getParentAdministration(medicationForm)?.lastAdministeredAt;
    const UTCParentAdministration = hasValue(parentAdministration)
        ? moment
              .tz(parentAdministration, APIConstants.API_LOCAL_DATE_TIME_FORMAT, timezone)
              .utc()
              .format(APIConstants.API_LOCAL_DATE_TIME_FORMAT)
        : null;

    if (isNonEmpty(administrations) && hasValue(UTCParentAdministration)) {
        /**
         * If both administered by the nursery AND the parents
         * return the latest
         */
        const latestAdministration = head(administrations);
        if (moment(UTCParentAdministration).isBefore(latestAdministration.administeredAt)) {
            return latestAdministration.administeredAt;
        }
        return UTCParentAdministration;
    }
    if (isNonEmpty(administrations)) {
        return head(administrations).administeredAt;
    }
    if (hasValue(UTCParentAdministration)) {
        return UTCParentAdministration;
    }
    return null;
};
