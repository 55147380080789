import {gql} from '@apollo/client';

export const StatPermissionWorkTag = gql`
    fragment StatPermissionWorkTag on WorkTagsManagementFlagsType {
        canView
        canCreate
        canEdit
        canDelete
    }
`;
