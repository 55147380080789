import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useIsOrganizationPage} from '../../../use-is-organization-page';
import {useCustomFormsEnrollmentPermissions} from './use-custom-forms-enrollment-permissions';
import {useCustomFormsInterestPermissions} from './use-custom-forms-interest-permissions';
import {useCustomFormsRegistrationPermissions} from './use-custom-forms-registration-permissions';

/**
 * Do not use this query directly!
 */
export function useNewFormPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['forms']['header']['newForm'] & {loading: boolean} {
    const isOrganizationView = useIsOrganizationPage();

    const {loading: enrollmentLoading, ...enrollmentPermissions} = useCustomFormsEnrollmentPermissions(payload);
    const {loading: interestLoading, ...interestPermissions} = useCustomFormsInterestPermissions(payload);
    const {loading: registrationLoading, ...registrationPermissions} = useCustomFormsRegistrationPermissions(payload);

    return {
        loading: enrollmentLoading || interestLoading || registrationLoading,
        canCreate: false,
        canRead:
            // We currently don't allow creating a form in organization context, so false is returned here.
            !isOrganizationView &&
            // If the user has the permissions to create at least one (of the currently 3 available form types), we return true
            (enrollmentPermissions.canCreate || interestPermissions.canCreate || registrationPermissions.canCreate),
        canUpdate: false,
        canDelete: false,
    };
}
