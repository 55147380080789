import {type QueryHookOptions} from '@apollo/client';

import {FetchStaffProfileScheduleWidgetPermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-staff-profile-schedule-widget-permissions.query';
import {
    type FetchStaffProfileScheduleWidgetPermissionsQuery,
    type FetchStaffProfileScheduleWidgetPermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-staff-profile-schedule-widget-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchStaffProfileScheduleWidgetPermissions(
    options: QueryHookOptions<
        FetchStaffProfileScheduleWidgetPermissionsQuery,
        FetchStaffProfileScheduleWidgetPermissionsQueryVariables
    >,
): ImmerWrappedQueryResult<
    FetchStaffProfileScheduleWidgetPermissionsQuery,
    FetchStaffProfileScheduleWidgetPermissionsQueryVariables
> {
    return useStatQuery<
        FetchStaffProfileScheduleWidgetPermissionsQuery,
        FetchStaffProfileScheduleWidgetPermissionsQueryVariables
    >(FetchStaffProfileScheduleWidgetPermissions, options);
}
