import React from 'react';
// Disabling linting here is ok in this one instance where we build the custom Popper component
// eslint-disable-next-line no-restricted-imports
import MUIPopper, {type PopperProps as MUIPopperProps} from '@mui/material/Popper';

export type PopperProps = MUIPopperProps;

export const PopperClassName = 'MFPopper-root';

/**
 * Override of MUI's Popper with a custom class name to enable external styling
 */
export const Popper = React.forwardRef<HTMLDivElement, PopperProps>((props, ref) => {
    return <MUIPopper className={PopperClassName} {...props} ref={ref} />;
});
