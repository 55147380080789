import {type Epic} from 'redux-observable';
import {combineReducers} from 'redux';
import {type Record} from 'immutable';

import {type Action} from 'web-app/util/redux/action-types';
import {type RootState} from 'signin-app/redux/main-reducer';
import {type IEntityState} from 'web-app/react/entities/factory/reducer-factory';

import ChildrenEntity from './entities/children';
import StaffEntity from './entities/staff';

const entities = [ChildrenEntity, StaffEntity];

export const entityEpics: Array<Epic<Action<any>, Action<any>, RootState>> = entities.map(entity => entity.epics);

const entityReducersObj = entities.reduce((acc, entity) => {
    return {
        ...acc,
        [entity.domain]: entity.reducer,
    };
}, {});

interface IEntityRootState {
    [entityName: string]: InstanceType<Record.Factory<IEntityState<any>>>;
}

export const entityReducer = combineReducers<IEntityRootState>(entityReducersObj);
