import gql from 'graphql-tag';

import {CustomRegistrationFormDescription} from '../fragment/generics/custom-registration-form-description.fragment';

export const UpdateCustomRegistrationFormPublishedState = gql`
    mutation SetPublished($formId: CustomRegistrationFormId!, $siteSetId: SiteSetId!, $published: Boolean!) {
        attendance {
            customRegistrationForm {
                setPublished(formId: $formId, siteSetId: $siteSetId, published: $published) {
                    ...CustomRegistrationFormDescription
                }
            }
        }
    }
    ${CustomRegistrationFormDescription}
`;
