import {type List} from 'immutable';

import LocalStorageService from 'web-app/services/local-storage';

const ACCESS_TOKEN_KEY = 'signInAccessToken';
const ACCESS_TOKEN_KEY_SIGNIN_V1 = 'accessToken';
const PIN_SIGNIN_AVAILABLE_KEY = 'pinSigninAvailable';
const QR_SIGNIN_AVAILABLE_KEY = 'qrSigninAvailable';
const HIDDEN_GROUP_IDS_KEY = 'hiddenGroupIds';
const SITE_ID = 'siteId';

export const saveAccessToken = (accessToken: string) => {
    LocalStorageService.saveToLocalStorage(ACCESS_TOKEN_KEY, accessToken);
};

export const saveLoginData = (pinSigninAvailable: boolean, qrSigninAvailable: boolean, siteId: string) => {
    LocalStorageService.saveToLocalStorage(PIN_SIGNIN_AVAILABLE_KEY, pinSigninAvailable);
    saveIsQRSigninAvailable(qrSigninAvailable);
    LocalStorageService.saveToLocalStorage(SITE_ID, siteId);
};

export const saveSiteId = (siteId: string) => {
    LocalStorageService.saveToLocalStorage(SITE_ID, siteId);
};

export const saveIsQRSigninAvailable = (qrSigninAvailable: boolean) => {
    LocalStorageService.saveToLocalStorage(QR_SIGNIN_AVAILABLE_KEY, qrSigninAvailable);
};

export const getLoginData = () => {
    const accessToken = LocalStorageService.getFromLocalStorage(ACCESS_TOKEN_KEY);
    const pinSigninAvailable = LocalStorageService.getFromLocalStorage(PIN_SIGNIN_AVAILABLE_KEY);
    const qrSigninAvailable = LocalStorageService.getFromLocalStorage(QR_SIGNIN_AVAILABLE_KEY);
    const siteId = LocalStorageService.getFromLocalStorage(SITE_ID);

    return {
        accessToken,
        pinSigninAvailable,
        qrSigninAvailable,
        siteId,
    };
};

export const removeLoginData = () => {
    LocalStorageService.clearKey(ACCESS_TOKEN_KEY);
    LocalStorageService.clearKey(PIN_SIGNIN_AVAILABLE_KEY);
    LocalStorageService.clearKey(QR_SIGNIN_AVAILABLE_KEY);
    LocalStorageService.clearKey(SITE_ID);

    // This ensures that the user is also logged out of the old Sign-in v1
    // during the transition period from v1 to v2.
    // Both versions are hosted on the very same domain.
    LocalStorageService.clearKey(ACCESS_TOKEN_KEY_SIGNIN_V1, 'famlyCheckin');
};

export const saveHiddenGroupIds = (groupIds: List<string>) => {
    LocalStorageService.saveToLocalStorage(HIDDEN_GROUP_IDS_KEY, groupIds);
};

export const getHiddenGroupIds = () => LocalStorageService.getFromLocalStorage(HIDDEN_GROUP_IDS_KEY);
