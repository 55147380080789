import {type QueryHookOptions} from '@apollo/client';

import {FetchDetailedBreakdownPermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-detailed-breakdown.query';
import {
    type FetchDetailedBreakdownPermissionsQuery,
    type FetchDetailedBreakdownPermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-detailed-breakdown.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchDetailedBreakdownPermissions(
    options: QueryHookOptions<FetchDetailedBreakdownPermissionsQuery, FetchDetailedBreakdownPermissionsQueryVariables>,
): ImmerWrappedQueryResult<FetchDetailedBreakdownPermissionsQuery, FetchDetailedBreakdownPermissionsQueryVariables> {
    return useStatQuery<FetchDetailedBreakdownPermissionsQuery, FetchDetailedBreakdownPermissionsQueryVariables>(
        FetchDetailedBreakdownPermissions,
        options,
    );
}
