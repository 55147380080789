import React from 'react';

import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';
import {useFetchAttendanceDetailsPermissions} from './queries/use-fetch-attendance-details-permissions';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export function useAttendanceDetailsPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['attendanceDetails'] & {loading: boolean} {
    const {data, loading} = useFetchAttendanceDetailsPermissions({
        variables: {
            siteSetId: payload.institutionSetId,
            employeeId: payload.employeeId,
        },
        skip: !payload.shouldQuery,
    });
    const schedulePermissions = useCrudPermissionAdapter(data?.staffhours.management.shiftplanner);
    const publishedShiftsPermissions = useCrudPermissionAdapter(
        data?.staffhours.management.shiftplanner.shift.published,
    );
    const attendanceSiteSetPermissions = useCrudPermissionAdapter(data?.staffhours.management.attendance.siteSet);
    const attendancePermissions = useCrudPermissionAdapter(data?.staffhours.management.attendance);
    const leavePermissions = useCrudPermissionAdapter(data?.staffhours.management.leaves.basic);
    const contractedHoursPermissions = useCrudPermissionAdapter(
        data?.staffhours.management.contractedHours.contractedHours,
    );
    const bankHoursPermissions = useCrudPermissionAdapter(data?.staffhours.management.contractedHours.bankHours);

    return React.useMemo(
        () => ({
            loading,
            header: {
                staffSelector: attendanceSiteSetPermissions,
                goToSchedule: schedulePermissions,
            },
            content: {
                cell: {
                    leave: leavePermissions,
                    schedule: publishedShiftsPermissions,
                    attendance: attendancePermissions,
                    breaks: publishedShiftsPermissions,
                },
                actions: {
                    addAttendance: attendanceSiteSetPermissions,
                    addPersonalAttendance: attendancePermissions,
                    addLeave: leavePermissions,
                },
                totals: {
                    schedule: publishedShiftsPermissions,
                    contracted: contractedHoursPermissions,
                    bankHours: bankHoursPermissions,
                    attended: attendancePermissions,
                    breaks: publishedShiftsPermissions,
                },
            },
        }),
        [
            loading,
            attendanceSiteSetPermissions,
            schedulePermissions,
            leavePermissions,
            attendancePermissions,
            publishedShiftsPermissions,
            contractedHoursPermissions,
            bankHoursPermissions,
        ],
    );
}
