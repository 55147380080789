export const FORM_NAME = 'link-eyo-form';

export const FRAMEWORK = {
    EYO: 'EYO',
    CoEL: 'CoeL',
};

// Sorted alphabetically
export const FRAMEWORKIDS = {
    BtA: 'd05c239b-e038-11e9-9dbb-0242ac120002',
    CfE: 'e302526a-c55e-11e8-9835-0242ac120003',
    CoEL: '7b87fcce-4bf2-4e0a-ace1-bd2a9646364a',
    DRDP_CALIFORNIA: 'af8a794d-7888-49ef-96d4-8668447bdfb9',
    ECAT: 'b572ef66-692a-45bc-8a10-cd124dfeacab',
    ESPIRA2018: 'a7272720-ee9c-4a33-a0a9-50760ab3034d',
    EYFP: '4e9afe84-56c0-4fe9-bff4-296471451cec',
    EYFP17: '13a84822-fbd5-11e9-9229-0242ac120002',
    EYO: 'f369d7be-d5b3-4efb-9cab-3c4d9ed656aa',
    EYFS2021: '4a201aa4-44b6-45f5-a8bc-8740bb83bf0f',
    FLORIDA: '91612f24-2859-495a-a605-baaec7f1d8a0',
    GeorgiaGELDS: '8ef5caf3-1a25-4818-af13-0d1483902c2c',
    GERMAN_EYO: 'd3f6b708-12a2-4e31-8b3b-d8491901433e',
    GIRFEC: '154e4f3f-c61f-11e8-9835-0242ac120003',
    HEAD_START: '89c33031-f44f-45c1-a517-3dda9971e3e7',
    KAE: '38eb23fb-6ebd-46af-9305-8cfe4bb332b6',
    KAP: 'b7c9cdb6-4f3c-4522-aac7-fd663a290b5d',
    LEARNING: '3886e12f-4d67-41ce-9101-b23097fc1c04',
    NEW_YORK: '7262fe52-8847-43c1-83de-4688112f441e',
    NewJersey0to3: '34cbe94f-97fd-4ab3-946d-9e85eee0634e',
    NewJerseyPLS: '2ae46ca2-a420-43e8-99df-b5ae7ae3dec2',
    ORSI: 'dd249931-cbe5-4eca-881f-a38a5842237f', // OLD CfE
    PB3: 'b0520277-c61f-11e8-9835-0242ac120003',
    SEND: '5fec4092-c4a3-4f47-a3fc-897e2c0e3c36',
    TexasPKG: 'cb9fdda3-288e-43e6-bc95-6acb2143d88f',
    RtA: 'f2b1673b-0de4-4b39-bb06-4dfac962cabc',
};

// Frameworks with age-bands
export const TABBED_FRAMEWORKIDS = [
    FRAMEWORKIDS.DRDP_CALIFORNIA,
    FRAMEWORKIDS.EYFP,
    FRAMEWORKIDS.EYO,
    FRAMEWORKIDS.FLORIDA,
    FRAMEWORKIDS.GeorgiaGELDS,
    FRAMEWORKIDS.GERMAN_EYO,
    FRAMEWORKIDS.HEAD_START,
    FRAMEWORKIDS.NEW_YORK,
    FRAMEWORKIDS.NewJersey0to3,
    FRAMEWORKIDS.NewJerseyPLS,
    FRAMEWORKIDS.ORSI,
    FRAMEWORKIDS.TexasPKG,
];
export const DYNAMICALLY_TABBED_FRAMEWORKIDS = [FRAMEWORKIDS.CfE];

/**
 * Framework IDs in this list will be used allowed to render in
 * the `framework-area-picker` component.
 */
export const FRAMEWORKIDS_FOR_AREA_PICKER = [FRAMEWORKIDS.ESPIRA2018];
