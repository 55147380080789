import React from 'react';
import Typography from '@mui/material/Typography';

import {useDataProps, type DataProps} from 'modern-famly/components/util';

import {ListItem, type ListItemProps} from './list-item';
import {useListContext} from './list-context';

export type ListItemLongTextProps = {
    text: string;
} & Pick<ListItemProps, 'displayFrom'> &
    DataProps;

export const ListItemLongText = (props: ListItemLongTextProps) => {
    const dataProps = useDataProps(props);
    const {device} = useListContext();

    return (
        <ListItem displayFrom={props.displayFrom}>
            <Typography
                variant={device === 'mobile' ? 'body-small' : 'body'}
                sx={theme => ({
                    color: theme.modernFamlyTheme.colorPalette.n300,
                    display: '-webkit-box',
                    '-webkit-line-clamp': device === 'mobile' ? '1' : '2',
                    '-webkit-box-orient': 'vertical',
                    overflow: 'hidden',
                    maxWidth: '400px',
                })}
                {...dataProps}
            >
                {props.text}
            </Typography>
        </ListItem>
    );
};
