import React from 'react';
import {Redirect, type RouteComponentProps} from 'react-router-dom';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';

import {Headline, Body} from 'web-app/react/components/text/text';
import {Flex} from 'web-app/react/components/layout/layout';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import translate from 'signin-app/helpers/translate';
import {s1, s6, getSpacing, s12} from 'web-app/styleguide/spacing';
import {Colors} from 'web-app/styleguide';
import RoutesMap from 'signin-app/routes/routes-map';
import * as WhitelabelSelectors from 'signin-app/whitelabel/selectors';
import {useTypedSelector} from 'signin-app/components/hooks';

import * as Actions from './actions';
import * as Selectors from './selectors';
import Form, {type FormData} from './form/form';

const LoginContainer = styled.div`
    position: relative;
    height: 100vh;
`;

const ScreenCenter = styled(Flex).attrs(() => ({
    direction: 'column',
    align: 'center',
}))`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
`;

const StyledForm = styled(Form)`
    width: 100%;
    margin-top: ${getSpacing(s12)};

    & > input {
        padding: 20px 8px;

        &::placeholder {
            font-size: 20px !important;
        }
    }
`;

const Login: React.FC<RouteComponentProps> = () => {
    const whitelabelConfiguration = useTypedSelector(state => WhitelabelSelectors.whiteLabelConfiguration(state));
    const isLoggedIn = useTypedSelector(state => Selectors.isLoggedIn(state));
    const isLoggingIn = useTypedSelector(state => Selectors.isLoggingIn(state));
    const isPinApp = useTypedSelector(state => Selectors.isPinApp(state));
    const isQrSignin = useTypedSelector(state => Selectors.isQrSignin(state));

    const dispatch = useDispatch();

    const handleSubmit = React.useCallback(
        (formData: FormData) => {
            dispatch(Actions.login.action(formData.password));
        },
        [dispatch],
    );

    if (isLoggedIn) {
        if (isQrSignin && !isPinApp) {
            return <Redirect to={RoutesMap.qr} />;
        }
        if (isPinApp) {
            return <Redirect to={RoutesMap.pin} />;
        }
        return <Redirect to={RoutesMap.overview} />;
    }

    if (isLoggingIn) {
        return <Spinner color="white" centered />;
    }

    return (
        <>
            <LoginContainer>
                <ScreenCenter>
                    {React.createElement(whitelabelConfiguration.LoginLogo)}
                    <Headline emphasized marginTop={s6} color={Colors.InvertedText} textAlign="center">
                        {translate('logInHeadline', {whitelabelName: whitelabelConfiguration.ProductName})}
                    </Headline>
                    <Body marginTop={s1} color={Colors.InvertedText} textAlign="center">
                        {translate('loginDescription', {whitelabelName: whitelabelConfiguration.ProductName})}
                    </Body>
                    <StyledForm onSubmit={handleSubmit} />
                </ScreenCenter>
            </LoginContainer>
        </>
    );
};

export default Login;
