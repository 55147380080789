import {makeReducer, match} from 'web-app/util/redux';
import {type ModalState, open, close} from 'web-app/react/modals-container/types';

import * as Actions from './actions';

export type DisplayedGroupsSettingsModalState = ModalState<{}>;

export default makeReducer<DisplayedGroupsSettingsModalState>(
    [match(Actions.show, () => open({})), match(Actions.hide, () => close())],
    close(),
);
