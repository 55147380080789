import React from 'react';

import Spinner from 'web-app/react/components/loading/spinner/spinner';

import {type StatFeatures} from '../features/stat-features';
import {useStatFeatures} from '../features/use-stat-features';
// eslint-disable-next-line no-restricted-imports
import {type StatPermissions} from '../permission/internal/stat-permissions';
import {type StatPermissionScope, useScopedStatPermissions} from '../permission/use-scoped-stat-permissions';
import {useStatPermissions} from '../permission/use-stat-permissions';

export interface StatContext {
    features: StatFeatures;
    permissions: StatPermissions & {loading?: boolean};
}

/** only exposed for testing */
export const statContext = React.createContext<StatContext | undefined>(undefined);

export function useStatContext(): StatContext {
    return React.useContext(statContext) as StatContext;
}

export const StatContextProvider = ({
    institutionId,
    employeeId,
    formId,
    children,
    scopes,
}: React.PropsWithChildren<{
    institutionId: string;
    employeeId?: string;
    formId?: string;
    scopes?: StatPermissionScope[];
}>) => {
    const [features, loadingFeatures] = useStatFeatures({institutionId});
    const statPermissions = useStatPermissions({
        institutionSetId: institutionId,
        employeeId,
        formId,
    });
    const {loading: loadingPermissions, ...scopedPermissions} = useScopedStatPermissions(
        {
            institutionSetId: institutionId,
            employeeId,
            formId,
        },
        scopes ?? [],
    );
    const value = React.useMemo(
        () => ({
            features,
            permissions: scopes?.length ? scopedPermissions : statPermissions,
        }),
        [features, scopedPermissions, scopes?.length, statPermissions],
    );
    return (
        <statContext.Provider value={value as StatContext}>
            {loadingFeatures || loadingPermissions ? <Spinner centered margined /> : children}
        </statContext.Provider>
    );
};
