import {type QueryHookOptions} from '@apollo/client';

import {FetchCustomFormsInterestPermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-custom-forms-interest-permissions.query';
import {
    type FetchCustomFormsInterestPermissionsQuery,
    type FetchCustomFormsInterestPermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-custom-forms-interest-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchCustomFormsInterestPermissions(
    options: QueryHookOptions<
        FetchCustomFormsInterestPermissionsQuery,
        FetchCustomFormsInterestPermissionsQueryVariables
    >,
): ImmerWrappedQueryResult<
    FetchCustomFormsInterestPermissionsQuery,
    FetchCustomFormsInterestPermissionsQueryVariables
> {
    return useStatQuery<FetchCustomFormsInterestPermissionsQuery, FetchCustomFormsInterestPermissionsQueryVariables>(
        FetchCustomFormsInterestPermissions,
        options,
    );
}
