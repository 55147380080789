import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {type StatPermissions} from './stat-permissions';
import {useContractBankHoursPermissions} from './use-contract-bank-hours-permissions';
import {useContractWeeklyHoursPermissions} from './use-contract-weekly-hours-permissions';
import {useLeavePermissions} from './use-leave-permission';
import {useShiftPlannerPermissions} from './use-shift-planner-permissions';

export function useStaffOverviewPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['staffOverview'] & {loading: boolean} {
    const {loading: loadingBankHours, ...bankHoursCrud} = useContractBankHoursPermissions(payload);
    const {loading: loadingWeeklyHours, ...weeklyHoursCrud} = useContractWeeklyHoursPermissions(payload);
    const {loading: loadingShiftPlanner, ...shiftPlannerCrud} = useShiftPlannerPermissions(payload);
    const {loading: loadingLeave, ...leaveCrud} = useLeavePermissions(payload);
    return {
        loading: loadingBankHours || loadingWeeklyHours || loadingShiftPlanner,
        header: {},
        content: {
            cells: {
                employee: {
                    bankHours: bankHoursCrud,
                    contractedHours: weeklyHoursCrud,
                },
                day: {
                    scheduled: shiftPlannerCrud?.general ?? {
                        canRead: false,
                        canUpdate: false,
                        canCreate: false,
                        canDelete: false,
                    },
                    leave: leaveCrud,
                },
                total: {
                    scheduled: shiftPlannerCrud?.general ?? {
                        canRead: false,
                        canUpdate: false,
                        canCreate: false,
                        canDelete: false,
                    },
                },
            },
        },
    };
}
