import React from 'react';
import {ViewportList} from 'react-viewport-list';

import {List, type ListProps} from './list';
import {ListHeaderRow} from './list-header-row';

export type VirtualListProps<ItemType> = Omit<ListProps, 'children'> & {
    data: ItemType[];
    listHeaderRow?: React.ReactNode;
    renderRow: (item: ItemType, index: number) => React.ReactNode;
    scrollContainerRef?: React.RefObject<HTMLElement>;
};

export function VirtualList<ItemType>({
    data,
    renderRow,
    listHeaderRow,
    scrollContainerRef,
    ...listProps
}: VirtualListProps<ItemType>) {
    const header = React.isValidElement(listHeaderRow) && listHeaderRow.type === ListHeaderRow ? listHeaderRow : null;

    const supportsSubgrid = !listProps.forceDisplayContents && CSS.supports('grid-template-columns', 'subgrid');

    return (
        <List {...listProps}>
            {header}
            {supportsSubgrid ? (
                <ViewportList viewportRef={scrollContainerRef} items={data} itemSize={66}>
                    {renderRow}
                </ViewportList>
            ) : (
                data.map(renderRow)
            )}
        </List>
    );
}
