import moment from 'moment-timezone';

import * as FamlyticsAPI from 'signin-app/util/famlytics/api';
import FamlyticsBehaviors from 'web-app/behaviors/famlytics-behavior-ids';
import generateUUID from 'web-app/util/uuid';
import {getStore} from 'signin-app/redux/store';
import LocalStorage from 'web-app/services/local-storage';
import PlatformHelper from 'web-app/helpers/platform-helper';
import {type TrackingEvent} from 'web-app/util/famlytics/types';

export const track = (eventName: TrackingEvent, meta?: object) => {
    const state = getStore().getState();
    const account = state.account;
    const isTouchDevice = PlatformHelper.isTouch();

    const analyticsBehaviorPayload = account.behaviors.behaviorForId(FamlyticsBehaviors.EnableAnalytics)?.payload;

    if (!account.loginId || !analyticsBehaviorPayload) {
        return;
    }

    const event = {
        name: eventName.name,
        meta: {
            serverProvided: analyticsBehaviorPayload.meta,
            ...meta,
            isTouchDevice,
            screenWidth: window.screen.width,
            screenHeight: window.screen.height,
            windowWidth:
                window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth || undefined,
            windowHeight:
                window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || undefined,
        },
        eventId: generateUUID(),
        timestamp: moment().toISOString(),
        loginId: account.loginId,
        deviceId: LocalStorage.getFromLocalStorage('deviceId'),
    };

    const token = analyticsBehaviorPayload.token;
    const expires = analyticsBehaviorPayload.expires;

    FamlyticsAPI.track([event], token, expires);
};
