import React from 'react';
import {useDispatch} from 'react-redux';

import translate from 'signin-app/helpers/translate';
import {pinHolder} from 'signin-app/pin/selectors';
import {
    ConfirmButton,
    ActionButton,
    BottomBarContainer,
    ConfirmButtonTypes,
} from 'signin-app/components/action-buttons';
import {useTypedSelector} from 'signin-app/components/hooks';

import * as Selectors from './selectors';
import {checkOutChild} from './actions';

interface CheckOutSubmitProps {
    childId: string;
    childName: string;
}

export const CheckOutSubmit: React.FC<CheckOutSubmitProps> = props => {
    const {childId, childName} = props;
    const isCheckingOut = useTypedSelector(Selectors.isCheckingOut);
    const pin = useTypedSelector(pinHolder);
    const dispatch = useDispatch();

    const handleChildCheckOut = React.useCallback(() => {
        dispatch(checkOutChild.action(childId, pin));
    }, [childId, pin, dispatch]);

    return (
        <BottomBarContainer>
            <ActionButton text={translate('changePickUp')} link={`/child/${childId}/checkin?update`} />
            <ConfirmButton
                type={ConfirmButtonTypes.SIGN_OUT}
                text={translate('signOut', {personName: childName})}
                isLoading={isCheckingOut}
                onClick={handleChildCheckOut}
                dataE2eCheckinConfirmButton="child-check-out-confirm-button"
            />
        </BottomBarContainer>
    );
};
