import {type QueryHookOptions} from '@apollo/client';

import {FetchSchedulePermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-schedule-permissions.query';
import {
    type FetchSchedulePermissionsQuery,
    type FetchSchedulePermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-schedule-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchSchedulePermissions(
    options: QueryHookOptions<FetchSchedulePermissionsQuery, FetchSchedulePermissionsQueryVariables>,
): ImmerWrappedQueryResult<FetchSchedulePermissionsQuery, FetchSchedulePermissionsQueryVariables> {
    return useStatQuery<FetchSchedulePermissionsQuery, FetchSchedulePermissionsQueryVariables>(
        FetchSchedulePermissions,
        options,
    );
}
