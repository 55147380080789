import React from 'react';

import Spinner from 'web-app/react/components/loading/spinner/spinner';
import deferRender from 'web-app/react/components/higher-order/defer-render';

interface DynamicImportState {
    loaded: boolean;
    imports: any;
}

interface DynamicImport {
    name: string;
    bits?: string[];
    default?: boolean;
    module: Promise<any>;
}

interface Config<Props> {
    spinner?: boolean;
    onLoaded?: (props: Props) => void;
}

const getImports = (dynamicImport: DynamicImport, module: any) => {
    const bits = dynamicImport.bits || [];

    const objectWithDefault =
        bits.length === 0 || dynamicImport.default
            ? {
                  [dynamicImport.name]: module.default,
              }
            : {};

    return bits.reduce(
        (reduction, bit) => ({
            ...reduction,
            [bit]: module[bit],
        }),
        objectWithDefault,
    );
};

function dynamicImport<TOriginalProps>(makeImports: () => DynamicImport[], config?: Config<TOriginalProps>) {
    return (Component: React.ComponentType<React.PropsWithChildren<TOriginalProps & {imports: any}>>) => {
        return deferRender(
            class extends React.PureComponent<React.PropsWithChildren<TOriginalProps>, DynamicImportState> {
                public state = {loaded: false, imports: {}};

                public componentDidMount() {
                    const imports = makeImports();
                    Promise.all(imports.map(imp => imp.module)).then(loadedImports =>
                        this.setState(
                            {
                                imports: imports.reduce(
                                    (reduction, dynamicImport, i) => ({
                                        ...reduction,
                                        ...getImports(dynamicImport, loadedImports[i]),
                                    }),
                                    {},
                                ),
                                loaded: true,
                            },
                            () => {
                                if (config?.onLoaded) {
                                    config.onLoaded(this.props);
                                }
                            },
                        ),
                    );
                }

                public render() {
                    if (!this.state.loaded) {
                        if (config && config.spinner) {
                            return <Spinner centered margined />;
                        }
                        return null;
                    }
                    return <Component {...this.props} imports={this.state.imports} />;
                }
            },
        );
    };
}

export default dynamicImport;
