import {gql} from '@apollo/client';

import {StatPermissionLeaveRequest} from '../fragment/stat-permission-leave-request.fragment';
import {StatPermissionLeaveRequests} from '../fragment/stat-permission-leave-requests.fragment';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchLeaveRequestPermissions = gql`
    query FetchLeaveRequestPermissions($institutionSetId: InstitutionSetId!, $employeeId: EmployeeId!) {
        staffhours {
            management {
                leaveRequests {
                    ...StatPermissionLeaveRequests
                }
            }
        }
    }
    ${StatPermissionLeaveRequests}
    ${StatPermissionLeaveRequest}
`;
