import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

import Button, {ButtonSize} from 'web-app/react/components/form-elements/button/button';

// Naively converted from CSS module on the "Kill CSS modules" hack day
const StyledWrapper = styled.div`
    border: solid 1px #d4d4d4;
    border-top: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f6f6;
    border-radius: 3px;

    .button {
        padding: 5px 0;
        border: none;
        border-radius: 3px;
        font-weight: 500;

        &:focus,
        &:active {
            border: none;
        }

        &:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .count {
        text-align: center;
        font-weight: 500;
    }

    .button,
    .count {
        flex: 1 1 0%;
    }
`;

const Incrementer = props => {
    const {buttonClassName, className, count, onIncrement, onDecrement, disabled} = props;
    return (
        <StyledWrapper className={classNames(className, 'buttonContainer')}>
            <Button
                isDisabled={disabled}
                className={classNames(buttonClassName, 'button')}
                buttonSize={ButtonSize.mini}
                onClick={onDecrement}
            >
                -
            </Button>
            <div className={'count'}>{count}</div>
            <Button
                isDisabled={disabled}
                className={classNames(buttonClassName, 'button')}
                buttonSize={ButtonSize.mini}
                onClick={onIncrement}
            >
                +
            </Button>
        </StyledWrapper>
    );
};

Incrementer.propTypes = {
    disabled: PropTypes.bool,
    className: PropTypes.string,
    buttonClassName: PropTypes.string,
    count: PropTypes.number,
    onIncrement: PropTypes.func,
    onDecrement: PropTypes.func,
};

export default Incrementer;
