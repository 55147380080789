import gql from 'graphql-tag';

export const CustomRegistrationFormChildField = gql`
    fragment CustomRegistrationFormChildField on CustomRegistrationFormChildField {
        id
        required
        mandatory
        fieldType
        description
        userConfiguration
        fileAttachment {
            filename
            fileId
            objectUrl
        }
    }
`;
