import gql from 'graphql-tag';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchEmployeePermissions = gql`
    query FetchEmployeePermissions($institutionSetId: SiteSetId!) {
        management {
            employees(siteSetId: $institutionSetId) {
                canView
            }
        }
    }
`;
