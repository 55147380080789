import React from 'react';
import Stack from '@mui/material/Stack';

import {useDataProps, type DataProps} from 'modern-famly/components/util';

import {Avatar, type AvatarProps} from '../avatar';
import {Text} from '../text';
import {ListItem, type ListItemProps} from './list-item';
import {getAnchorProps, type AnchorProps} from './anchor-props';
import {useListContext} from './list-context';

export type ListItemAvatarProps = {
    /**
     * The avatar to display in the list item.
     *
     * If a string is provided, it will be interpreted as the `src` property of AvatarProps.
     */
    avatar: AvatarProps['src'] | AvatarProps;
    title: string;
    description?: string;

    /**
     * Function to call when the item is clicked.
     *
     * Ignored on mobile.
     */
    onClick?: VoidFunction;

    displayFrom?: ListItemProps['displayFrom'];
} & AnchorProps &
    DataProps;

export const ListItemAvatar = (props: ListItemAvatarProps) => {
    const {device} = useListContext();
    const anchorProps = device === 'non-mobile' ? getAnchorProps(props) : null;
    const onClick = device === 'non-mobile' ? props.onClick : undefined;
    const dataProps = useDataProps(props);
    const avatarProps = getAvatarProps(props.avatar);

    return (
        // The padding is added to the Stack component instead of the ListItem component
        // to make a potential hover effect not touch the edges of the ListItem component.
        <ListItem displayFrom={props.displayFrom} px={0} minWidth={0}>
            <Stack
                {...anchorProps}
                boxSizing="border-box"
                height="100%"
                direction="row"
                px={device === 'mobile' ? 0 : 4}
                gap={2}
                alignItems="center"
                onClick={props.onClick}
                width="100%"
                overflow="hidden"
                sx={theme => ({
                    textDecoration: 'unset',
                    ':hover':
                        anchorProps?.href || onClick
                            ? {
                                  cursor: 'pointer',
                                  backgroundColor: theme.modernFamlyTheme.colorPalette.p50,
                              }
                            : undefined,
                    ':focus-visible': {
                        outlineOffset: '-1px',
                        outlineColor: theme.modernFamlyTheme.colorPalette.p400,
                    },
                })}
                {...dataProps}
            >
                <Avatar size={32} {...avatarProps} />
                <Stack direction="column" minWidth="0">
                    <Text variant="body" textDecoration="none" color="n400" ellipsis>
                        {props.title}
                    </Text>
                    {props.description ? (
                        <Text variant="body-small" color="n300" textDecoration="none" ellipsis>
                            {props.description}
                        </Text>
                    ) : null}
                </Stack>
            </Stack>
        </ListItem>
    );
};

const getAvatarProps = (avatar: ListItemAvatarProps['avatar']): AvatarProps => {
    if (typeof avatar === 'string' || avatar === null || avatar === undefined) {
        return {src: avatar ?? undefined};
    }

    return avatar;
};
