import React from 'react';
import {type WrappedFieldProps} from 'redux-form';

import RecipientsSelect from 'web-app/react/components/form-elements/select/recipients-select';
import ReactOnMeta from 'web-app/react/components/form-elements/containers/react-on-meta';
import {type Option} from 'web-app/react/components/form-elements/select/types';

const emptyBlur = () => ({});

interface FormRecipientSelectProps {
    options: Option<string>[];
    isLoading?: boolean;
    className?: string;
    placeholder?: string;
    sortOptions?: (options: this['options']) => this['options'];
}

export type Props = FormRecipientSelectProps & WrappedFieldProps;

const FormRecipientSelect: React.FC<React.PropsWithChildren<Props>> = props => {
    const {input, options, isLoading, placeholder, className, sortOptions, ...rest} = props;

    return (
        <ReactOnMeta input={input} {...rest}>
            <RecipientsSelect
                {...input}
                onBlur={emptyBlur}
                options={options}
                sortOptions={sortOptions}
                isLoading={isLoading}
                placeholder={placeholder}
                className={className}
                {...rest}
            />
        </ReactOnMeta>
    );
};

export default FormRecipientSelect;
