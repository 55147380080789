import RESTClient from 'signin-app/api/clients/rest-client';
import ENV from 'signin-app/config/environment';
import {ENV as ENVIRONMENT_TYPES} from 'web-app/shared/constants';

import {type Event} from './types';

export const track = (events: Event[], token: string, expires: string) => {
    return RESTClient.post(
        'client-events',
        {
            events,
            token,
            expires,
        },
        {
            customApiUrl: getAnalyticsUrl(),
            suppressErrors: true,
        },
    );
};

const getAnalyticsUrl = () => {
    const currentEnvironment = ENV.environment;
    const remoteHost = process.env.REMOTE_DEV_HOST;

    if (remoteHost) {
        return `http://fl.${remoteHost}`;
    }

    switch (currentEnvironment) {
        case ENVIRONMENT_TYPES.LOCAL:
            return 'http://fl.famly.local';
        case ENVIRONMENT_TYPES.PRODUCTION:
            return 'https://fl.famly.de';
        default:
            throw new Error(`Unknown environment: ${currentEnvironment}`);
    }
};
