/* eslint-disable prefer-spread */
/* eslint-disable prefer-rest-params */
import {createSelectorCreator, createSelector} from 'reselect';

import SHARED_ENVIRONMENT_CONFIG from 'web-app/shared/environment';
import LocalStorageService from 'web-app/services/local-storage';

function defaultEqualityCheck(a, b) {
    return a === b;
}

function areArgumentsShallowlyEqual(equalityCheck, prev, next) {
    if (prev === null || next === null || prev.length !== next.length) {
        return false;
    }

    // Do this in a for loop (and not a `forEach` or an `every`) so we can determine equality as fast as possible.
    const length = prev.length;
    for (let i = 0; i < length; i++) {
        if (!equalityCheck(prev[i], next[i])) {
            return false;
        }
    }

    return true;
}

const USE_FUNCTION_CHECKING_SELECTOR = Boolean(LocalStorageService.getFromLocalStorage('useFunctionCheckingSelector'));

export function customMemoize(func, equalityCheck = defaultEqualityCheck) {
    let lastArgs: any = null;
    let lastResult: any = null;
    // we reference arguments instead of spreading them for performance reasons
    return function () {
        if (USE_FUNCTION_CHECKING_SELECTOR) {
            Array.from(arguments).forEach(arg => {
                if (typeof arg !== 'object' || arg === null) {
                    return;
                }

                Object.keys(arg).forEach(key => {
                    if (typeof arg[key] === 'function') {
                        // tslint:disable-next-line:no-console
                        console.error(`${key} is passed as a function to a selector`);
                    }
                });
            });
        }

        if (!areArgumentsShallowlyEqual(equalityCheck, lastArgs, arguments)) {
            // apply arguments instead of spreading for performance.
            lastResult = func.apply(null, arguments);
        }

        lastArgs = arguments;
        return lastResult;
    };
}

// This is an attempt to allow for better debugging of what we pass into our selectors.
// "defaultEqualityCheck", "areArgumentsShallowlyEqual" are an exact copy of the original implementation from the reselect library.
// "customMemoize" functionality-wise similar to the defaultMemoization function in reselect
// with the option of letting us know if we pass in an object with a function, which we've found to be an anti-pattern.
// See https://github.com/reduxjs/reselect/blob/master/src/index.js for said default implementations
export default SHARED_ENVIRONMENT_CONFIG.IS_DEVELOPMENT ? createSelectorCreator(customMemoize as any) : createSelector;
