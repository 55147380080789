import React from 'react';
import PropTypes from 'prop-types';

import {currentTheme} from 'web-app/styleguide';
import {useImport} from 'web-app/react/hooks/use-import';
import {hasValue} from 'web-app/util/typescript';

/**
 * Take the input CamelCase string and make it kebab-case to convert the Component naming
 * convention and make it into the file naming convention for the lazy import
 *
 * @param {*} str CamelCase string to make into kebab-case
 * @returns kebabized CamelCase string
 */
const kebabize = str => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase());

const SuspendedIconSwitcher = props => {
    return (
        <React.Suspense fallback={null}>
            <IconSwitcher {...props} />
        </React.Suspense>
    );
};

const IconSwitcher = props => {
    const {icon, color, fill, ...rest} = props;

    const LazyIcon = useImport(
        !hasValue(icon)
            ? new Promise(res => res(null))
            : import(`./lazy-icons/${kebabize(icon)}`).catch(() => {
                  // do nothing if the import fails, as we will fall back to null anyways.
              }),
    );
    const theme = currentTheme();
    if (LazyIcon) {
        return <LazyIcon.default {...rest} fill={color ? theme[color] : fill} />;
    }
    return null;
};

const propTypes = {
    color: PropTypes.string,
    fill: PropTypes.string,
    size: PropTypes.number,
    transparent: PropTypes.bool,
    radius: PropTypes.number,
    background: PropTypes.string,
    icon: PropTypes.string.isRequired,
};

SuspendedIconSwitcher.propTypes = propTypes;
IconSwitcher.propTypes = propTypes;

export default SuspendedIconSwitcher;
