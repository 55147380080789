import React from 'react';
import {type WrappedFieldProps} from 'redux-form';

import TogglableInputField, {
    type TogglableInputFieldProps,
} from 'web-app/react/components/form-elements/togglabe-input-field/togglable-input-field';

interface InputProps extends TogglableInputFieldProps {
    onChange: (e: any) => any;
}

type Props = InputProps & WrappedFieldProps;

class TogglableFormField extends React.Component<Props> {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        const {input, enabledComponent, meta, ...rest} = this.props;

        return (
            <TogglableInputField
                {...rest}
                enabledComponent={enabledComponent}
                value={input.value}
                onChange={this.handleChange}
            />
        );
    }

    private handleChange(e) {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
        this.props.input.onChange(e);
    }
}

export default TogglableFormField;
