import {createGlobalStyle} from 'styled-components';

export const GlobalTooltipClassname = 'GlobalTooltipClassname';

export default createGlobalStyle`
    .${GlobalTooltipClassname} .rc-tooltip-inner {
        background-color: ${props => props.theme.text};
    }

    .${GlobalTooltipClassname}.rc-tooltip-placement-top .rc-tooltip-arrow,
    .${GlobalTooltipClassname}.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
    .${GlobalTooltipClassname}.rc-tooltip-placement-topRight .rc-tooltip-arrow {
        border-top-color: ${props => props.theme.text};
    }

    .${GlobalTooltipClassname}.rc-tooltip-placement-right .rc-tooltip-arrow,
    .${GlobalTooltipClassname}.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
    .${GlobalTooltipClassname}.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
        border-right-color: ${props => props.theme.text};
    }

    .${GlobalTooltipClassname}.rc-tooltip-placement-left .rc-tooltip-arrow,
    .${GlobalTooltipClassname}.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
    .${GlobalTooltipClassname}.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
        border-left-color: ${props => props.theme.text};
    }

    .${GlobalTooltipClassname}.rc-tooltip-placement-bottom .rc-tooltip-arrow,
    .${GlobalTooltipClassname}.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
    .${GlobalTooltipClassname}.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
        border-bottom-color: ${props => props.theme.text};
    }
`;
