import createSelector from 'web-app/util/custom-create-selector';
import {type RootState} from 'signin-app/redux/main-reducer';
import StaffEntity from 'signin-app/entities/staff';

export const staffGroup = createSelector(
    StaffEntity.selectors.entityMap,
    (_: RootState, props: {staffGroupId: string}) => props.staffGroupId,
    (staffEntityMap, groupId) => {
        return staffEntityMap.find((_, staffGroupId) => staffGroupId === groupId);
    },
);
