import createSelector from 'web-app/util/custom-create-selector';
import {type RootState} from 'signin-app/redux/main-reducer';

export const isLoggingIn = (state: RootState) => state.login.isLoggingIn;
export const accessToken = (state: RootState) => state.login.accessToken;
export const isPinApp = (state: RootState) => state.login.pinSigninAvailable;
export const isQrSignin = (state: RootState) => state.login.qrSigninAvailable;
export const getSiteId = (state: RootState) => state.login.siteId;

export const isLoggedIn = createSelector(accessToken, token => Boolean(token));
