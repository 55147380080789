import React from 'react';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {type StatPermissions} from './stat-permissions';
import {useContractBankHoursPermissions} from './use-contract-bank-hours-permissions';
import {useContractChangeLogPermissions} from './use-contract-change-log-permissions';
import {useContractWeeklyHoursPermissions} from './use-contract-weekly-hours-permissions';
import {useContractWorkAvailabilityPermissions} from './use-contract-work-availability-permissions';
import {useStaffProfileScheduleWidgetPermissions} from './use-staff-profile-schedule-widget-permissions';

export function useStaffProfilePermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['staffProfile'] & {loading: boolean} {
    const {loading: loadingScheduleWidget, ...scheduleWidget} = useStaffProfileScheduleWidgetPermissions(payload);
    const {loading: loadingContractWeeklyHours, ...weeklyHours} = useContractWeeklyHoursPermissions(payload);
    const {loading: loadingWorkAvailability, ...workAvailability} = useContractWorkAvailabilityPermissions(payload);
    const {loading: loadingChangeLog, ...changeLog} = useContractChangeLogPermissions(payload);
    const {loading: loadingBankHours, ...bankHours} = useContractBankHoursPermissions(payload);

    return React.useMemo(
        () => ({
            loading: loadingScheduleWidget || loadingContractWeeklyHours || loadingWorkAvailability || loadingChangeLog,
            scheduleWidget,
            contract: {
                weeklyHours,
                workAvailability,
                bankHours,
                changeLog,
            },
        }),
        [
            bankHours,
            changeLog,
            loadingChangeLog,
            loadingContractWeeklyHours,
            loadingScheduleWidget,
            loadingWorkAvailability,
            scheduleWidget,
            weeklyHours,
            workAvailability,
        ],
    );
}
