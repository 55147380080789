import {type TrackingEvent} from '../types';

export const FinanceEvents = {
    // Manage plan
    CREATE_PLAN: {
        name: 'CREATE_PLAN',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Batch invoice
    BATCH_INVOICE_CREATE_AND_SEND_INVOICES_CONFIRM: {
        name: 'Batch invoice: Clicked on "Create and send invoices": Confirm' as const,
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    BATCH_INVOICE_CREATE_AND_SEND_INVOICES_COMPLETE: {
        name: 'Batch invoice: Clicked on "Create and send invoices": Complete' as const,
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    BATCH_INVOICE_CREATE_AND_SEND_INVOICES_BUTTON_CLICKED: {
        name: 'Batch invoice: Clicked on "Create and send invoices" button',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // All billing activity
    ALL_BILLING_ACTIVITY_GENERATE_SAGE200_BILLPAYERS_EXPORT: {
        name: 'All billing activity: Generate sage200 bill payers export clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ALL_BILLING_ACTIVITY_DOWNLOAD_SAGE200_BILLPAYERS_EXPORT: {
        name: 'All billing activity: Download sage200 bill payers export clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ALL_BILLING_ACTIVITY_GENERATE_DATEV_BILLPAYERS_EXPORT: {
        name: 'All billing activity: Generate DATEV bill payers export clicked',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    ALL_BILLING_ACTIVITY_DOWNLOAD_DATEV_BILLPAYERS_EXPORT: {
        name: 'All billing activity: Download DATEV bill payers export clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ALL_BILLING_ACTIVITY_GENERATE_SAGE200_PAYMENTS_EXPORT: {
        name: 'All billing activity: Generate sage200 payments export clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ALL_BILLING_ACTIVITY_DOWNLOAD_SAGE200_PAYMENTS_EXPORT: {
        name: 'All billing activity: Download sage200 payments export clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ALL_BILLING_ACTIVITY_DOWNLOAD_SAGE200_INVOICES_EXPORT: {
        name: 'All billing activity: Download sage200 invoices export clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Direct Debit Famly Pay banner
    DIRECT_DEBIT_FAMLY_PAY_BANNER_DISMISSED: {
        name: 'Famly Pay: Direct Debit Info banner dismissed',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Famly Pay
    FAMLY_PAY_GET_STARTED_CLICKED: {
        name: 'Famly Pay: Get started clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_PAY_SETUP_FORM_SUBMITTED: {
        name: 'Famly Pay: Setup form submitted',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_PAY_PAY_INVOICE: {
        name: 'Famly pay: pay invoice',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_PAY_INFO_REQUIRED_FLOW_DONE: {
        name: 'Famly Pay: information required flow done',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_PAY_PAYMENT_METHOD_SETUP_FLOW: {
        name: 'Famly pay payment method setup flow',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // New Booking pattern builder
    NEW_BOOKING_PATTERN_BUILDER_CTA_CLICKED: {
        name: 'Booking pattern: CTA button click',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    NEW_BOOKING_PATTERN_BUILDER_ITEM_ADDED: {
        name: 'Booking pattern: Item added',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    NEW_BOOKING_PATTERN_BUILDER_PASTE_DAY: {
        name: 'Booking pattern: Paste day',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Pricing
    PRICING_BUTTON_CLICKED: {
        name: 'Pricing: button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    PRICING_NEW_ITEM_CREATED: {
        name: 'Pricing: new item created',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Bulk pattern flow
    BULK_FLOW_CREATE_PLANS_BUTTON_CLICKED: {
        name: 'Bulk flow: Button on plans page',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    BULK_FLOW_INTRO_BUTTON_CLICKED: {
        name: 'Bulk flow: Create plans',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    BULK_FLOW_BOOKING_PATTERN_BUTTON_CLICKED: {
        name: 'Bulk flow: Booking patterns',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    BULK_FLOW_BOOKING_PATTERN_SAVED: {
        name: 'Bulk flow: Save booking pattern',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    BULK_FLOW_FUNDING_BUTTON_CLICKED: {
        name: 'Bulk flow: Funding',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    BULK_FLOW_CUSTOM_FUNDING_ADDED: {
        name: 'Bulk flow: Custom funding',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    BULK_FLOW_FUNDING_SAVED: {
        name: 'Bulk flow: Save funding',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    BULK_FLOW_DISCOUNTS_BUTTON_CLICKED: {
        name: 'Bulk flow: Discounts',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    BULK_FLOW_CUSTOM_DISCOUNT_ADDED: {
        name: 'Bulk flow: Custom discount',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    BULK_FLOW_DISCOUNTS_SAVED: {
        name: 'Bulk flow: Save discounts',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    BULK_FLOW_FINAL_SUCCESS_SCREEN_SHOWN: {
        name: 'Bulk flow: Final success screen',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Payment method setup flow
    PAYMENT_FLOW_PAYMENT_METHOD_SHOWN: {
        name: 'Payment flow: payment method shown',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    PAYMENT_FLOW_PAYMENT_METHOD_SELECTED: {
        name: 'Payment flow: payment method selected',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    PAYMENT_FLOW_ADD_CREDIT_CARD: {
        name: 'Payment flow: add credit card',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    PAYMENT_FLOW_DIRECT_DEBIT_FLOW: {
        name: 'Payment flow: Direct debit flow',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    PAYMENT_FLOW_PAYMENT_FLOW_COMPLETED: {
        name: 'Payment flow: payment flow completed',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    PAYMENT_FLOW_PAYMENT_FLOW_DROPOUT: {
        name: 'Payment flow: payment flow dropout',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    PAYMENTS_PAYMENT_IMPORT_CLICKED: {
        name: 'Payments: Payment import',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Debt collection
    DEBT_COLLECTION_SEND_PAYMENT_REMINDERS_CLICKED: {
        name: 'Debt collection: Send payment reminders',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Parent bookings
    PARENT_BOOKINGS_REQUEST_SESSION_FLOW: {
        name: 'Parent bookings: Request session',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Finance Power page
    FINANCE_POWER_PAGE_BULK_ACTION_CLICKED: {
        name: 'Finance Power page: Action panel bulk action clicked',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    FINANCE_POWER_PAGE_BULK_ACTION_SUCCESS: {
        name: 'Finance Power page:  Action panel bulk action success',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    FINANCE_POWER_PAGE_ROW_CLICKED: {
        name: 'Finance Power page: Table row clicked',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Payments
    PAYMENT_LOGGED: {
        name: 'Payment logged',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Invoices
    INVOICE_SENT: {
        name: 'Invoice sent',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Purchases
    SELECTED_PURCHASE_GROUP: {
        name: 'Purchase modal: Selected Purchase group',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
} satisfies Record<string, TrackingEvent>;
