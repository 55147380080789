import React from 'react';
import {type RouteProps, type RouteComponentProps} from 'react-router-dom';
import {Route, Redirect} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {hasValue} from 'web-app/util/typescript';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import {useTransformQuery} from 'web-app/react/hooks/use-transform-query';
import {accessToken, isPinApp, getSiteId, isQrSignin} from 'signin-app/login/selectors';
import {isAuthorized} from 'signin-app/pin/selectors';
import {didPrefetch} from 'signin-app/components/private-route/selectors';
import RoutesMap from 'signin-app/routes/routes-map';
import {
    type GetQRSignInSettingsQuery,
    type GetQRSignInSettingsQueryVariables,
} from 'signin-app/login/__generated__/queries.api-types';
import * as Queries from 'signin-app/login/queries';
import {saveIsQRSigninAvailable} from 'signin-app/api/helpers/local-storage';
import * as LoginActions from 'signin-app/login/actions';

import {useTypedSelector} from '../hooks';
import * as Actions from './actions';

interface PrivateRouteProps {
    redirectTo?: string;
    component: React.ComponentType<RouteComponentProps<any>>;
    path: RoutesMap;
    shouldPrefetch?: boolean;
}

type InputProps = PrivateRouteProps & RouteProps;

const PrivateRoute: React.FC<InputProps> = props => {
    const {redirectTo = '/', component: Component, path, shouldPrefetch, ...rest} = props;

    const dispatch = useDispatch();

    const siteId = useTypedSelector(getSiteId);
    const authorized = useTypedSelector(isAuthorized);
    const token = useTypedSelector(accessToken);
    const prefetched = useTypedSelector(didPrefetch);
    const pinSigninEnabled = useTypedSelector(isPinApp);
    const isQRSigninEnabled = useTypedSelector(isQrSignin);

    const [qrEnabledFromNetwork, {loading}] = useTransformQuery<
        GetQRSignInSettingsQuery,
        GetQRSignInSettingsQueryVariables
    >()(
        Queries.GetQRSignInSettings,
        siteId ? {variables: {siteIds: [siteId]}, fetchPolicy: 'network-only'} : {fetchPolicy: 'network-only'},
        ({data}) => {
            return data?.qrSignin.siteSettings.siteSettings[0]?.isEnabled;
        },
    );

    React.useEffect(() => {
        if (shouldPrefetch && !prefetched && authorized && !pinSigninEnabled) {
            dispatch(Actions.prefetch.action(token));
        }
    }, [token, prefetched, authorized, pinSigninEnabled, props, shouldPrefetch, dispatch]);

    React.useEffect(() => {
        if (!loading && authorized && hasValue(qrEnabledFromNetwork) && isQRSigninEnabled !== qrEnabledFromNetwork) {
            saveIsQRSigninAvailable(Boolean(qrEnabledFromNetwork));
            dispatch(LoginActions.qrSigninChange.action(Boolean(qrEnabledFromNetwork)));
        }
    }, [loading, qrEnabledFromNetwork, isQRSigninEnabled, dispatch, authorized]);

    // Disable access to routes other than /qr if QR sign in enabled and PIN sign in disabled
    // Disable access to /qr route if QR sign in is not enabled
    const canAccessRoute = React.useMemo(() => {
        if (
            (path !== RoutesMap.qr && isQRSigninEnabled && !pinSigninEnabled) ||
            (path === RoutesMap.qr && !isQRSigninEnabled)
        ) {
            return false;
        }
        return authorized;
    }, [authorized, pinSigninEnabled, isQRSigninEnabled, path]);

    if (loading) {
        <Spinner color="white" centered />;
    }
    return (
        <Route
            {...rest}
            path={path}
            render={props =>
                canAccessRoute ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: redirectTo,
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
