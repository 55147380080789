import Link from '@mui/material/Link';

export type AnchorProps = {href?: undefined} | {href: string; target?: HTMLAnchorElement['target']};

export const getAnchorProps = <T extends AnchorProps>(props: T) => {
    if (!props.href) {
        return null;
    }

    return {
        component: Link,
        href: props.href,
        target: props.target,
    };
};
