import {type ColorKey, useTheme} from 'modern-famly';

import {useTypedSelector} from 'signin-app/components/hooks';
import * as WhitelabelSelectors from 'signin-app/whitelabel/selectors';

export const useCustomWhitelabelColor = (colorKey: ColorKey): string => {
    const whitelabelConfiguration = useTypedSelector(state => WhitelabelSelectors.whiteLabelConfiguration(state));
    const theme = useTheme();

    return whitelabelConfiguration[colorKey] ?? theme.colorPalette[colorKey];
};
