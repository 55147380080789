import {EspiraTheme as EspiraMFTheme} from 'modern-famly';

import BaseTheme from './base';

export const EspiraChromeColor = '#A2282F';

const EspiraTheme = BaseTheme.merge({
    title: 'Espira',
    chromeColor: EspiraChromeColor,
    mf: EspiraMFTheme,
});

export default EspiraTheme;
