import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from 'web-app/react/components/form-elements/select/dropdown';

const emptyBlur = () => ({});

class FormDropdown extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(newValue) {
        if (this.props.onChange) {
            this.props.onChange(newValue);
        }
        return this.props.input.onChange(newValue ? newValue.value : null);
    }
    render() {
        const {
            meta, // eslint-disable-line no-unused-vars
            input,
            ...rest
        } = this.props;

        return <Dropdown {...input} {...rest} onBlur={emptyBlur} onChange={this.handleChange} />;
    }
}

FormDropdown.propTypes = {
    meta: PropTypes.any,
    input: PropTypes.any,
    placeholder: PropTypes.node,
    className: PropTypes.string,
    required: PropTypes.bool,
    clearable: PropTypes.bool,
    extendedOptionRender: PropTypes.bool,
    disabled: PropTypes.bool,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    displayRenderer: PropTypes.func,
    hideArrow: PropTypes.bool,
    alignRight: PropTypes.bool,
    slim: PropTypes.bool,
};

export default FormDropdown;
