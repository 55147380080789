import React from 'react';

import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useFetchEmployeePermissions} from './queries/use-fetch-employee-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';

/**
 * Do not use this query directly!
 */
export function useEmployeePermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['institution']['employees'] & {loading: boolean} {
    const {institutionSetId, shouldQuery} = payload;
    const {data, loading} = useFetchEmployeePermissions({
        variables: {
            institutionSetId,
        },
        skip: !shouldQuery,
    });
    const crud = useCrudPermissionAdapter(data?.management.employees);
    return React.useMemo(
        () => ({
            loading,
            ...crud,
        }),
        [crud, loading],
    );
}
