import {type TimePickerInputProps} from '../timepicker/timepicker';
import {type DayPickerInputProps} from '../daypicker/day-picker-input';
import type * as Types from './types';

export const timeValue = (value: Types.DateTimePickerValue): TimePickerInputProps['value'] => {
    if (!value) {
        return undefined;
    }

    return {
        hours: value.hours(),
        minutes: value.minutes(),
    };
};

export const dateValue = (value: Types.DateTimePickerValue): DayPickerInputProps['value'] => value || undefined;
