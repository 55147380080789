import {css} from 'styled-components';

import {media} from 'web-app/styleguide/utils';
import {getSpacing, s1, s6, divide} from 'web-app/styleguide/spacing';

/*
|---------------------------------------------------------------------------------
| PinDotsCSS
|---------------------------------------------------------------------------------
*/

interface PinDotsCSSConfig {
    color: string;
    makeTransform?: (dotSpacing: string) => string;
    makeHeight?: (dotSpacing: string) => string;
}

const dotSize = getSpacing(divide(s1, 2));
const dotSpacing = getSpacing(s6);

export const makePinDotsCSS = (config: PinDotsCSSConfig) => {
    return css`
        &:before {
            z-index: -1;
            pointer-events: none;
            content: '';
            height: ${props => config.makeHeight?.(dotSpacing(props)) ?? `calc(${getSpacing(s1)(props)} * 42)`};
            width: 168px;
            position: absolute;
            bottom: 0;
            right: 0;
            filter: blur(0.6px);
            transform: ${props =>
                config.makeTransform?.(dotSpacing(props)) ??
                `translate(calc(100% - (${dotSpacing(props)} * 3)), calc(${dotSpacing(props)} * 2))`};

            background-image: radial-gradient(${config.color} ${dotSize}, transparent ${dotSize});
            background-size: ${dotSpacing} ${dotSpacing};
        }

        ${media.nonStandardTablet`
            &:before {
                transform: translate(calc(100% - (${dotSpacing})), calc(${dotSpacing} * 2));
            }
        `}
    `;
};
