import hexToRGB from 'web-app/util/hex-to-rgb';

// https://en.wikipedia.org/wiki/Color_difference
type HEX = string;
const defaultColor = {r: 0, g: 0, b: 0};
export default (c1: HEX, c2: HEX) => {
    const {r: r1, g: g1, b: b1} = hexToRGB(c1) || defaultColor;
    const {r: r2, g: g2, b: b2} = hexToRGB(c2) || defaultColor;
    return Math.sqrt(Math.pow(r2 - r1, 2) + Math.pow(g2 - g1, 2) + Math.pow(b2 - b1, 2));
};
