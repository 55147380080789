import React from 'react';

import {useDataProps, type DataProps} from 'modern-famly/components/util';

import {Text} from '../text';
import {ListItem, type ListItemProps} from './list-item';
import {useListContext} from './list-context';

export type ListItemNumericProps = {
    text: string;
} & Pick<ListItemProps, 'displayFrom'> &
    DataProps;

export const ListItemNumeric = (props: ListItemNumericProps) => {
    const dataProps = useDataProps(props);
    const {device} = useListContext();

    return (
        <ListItem displayFrom={props.displayFrom}>
            <Text
                variant={device === 'mobile' ? 'body-small' : 'body'}
                textAlign={'right'}
                width="100%"
                numeric
                color="n400"
                {...dataProps}
            >
                {props.text}
            </Text>
        </ListItem>
    );
};
