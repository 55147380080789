import {hasValue} from 'web-app/util/typescript';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useFetchStaffAttendanceByEmployeePermissions} from './queries/use-fetch-staff-attendance-by-employee-permissions';
import {type StatPermissions} from './stat-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';

export function useStaffAttendanceByEmployeePermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['staffAttendance'] {
    const {institutionSetId, employeeId, shouldQuery} = payload;
    return useCrudPermissionAdapter(
        useFetchStaffAttendanceByEmployeePermissions({
            variables: {
                institutionSetId,
                employeeId: employeeId as string,
            },
            skip: !shouldQuery || !hasValue(institutionSetId) || !hasValue(employeeId),
        }).data?.staffhours.management.staffAttendance.employee,
    );
}
