import {createAction} from 'signin-app/redux/helpers';

const domain = 'AUTH';

export const {
    action: login,
    success: loginSuccess,
    failed: loginFailed,
} = createAction(
    `${domain}/LOGIN`,
    (password: string) => ({password}),
    (accessToken: string, pinSigninAvailable: boolean, qrSigninAvailable: boolean, siteId: string) => ({
        accessToken,
        pinSigninAvailable,
        qrSigninAvailable,
        siteId,
    }),
    (error: Error) => ({error}),
);

export const {action: logOut, success: logOutSuccess} = createAction(`${domain}/LOGOUT`, () => ({}));

export const {action: qrSigninChange} = createAction(`${domain}/QR_SIGNIN_CHANGE`, (qrSigninEnabled: boolean) => ({
    qrSigninEnabled,
}));
