import {type StatToggleableFeature} from './stat-togglable-feature';

export function toggleableFeatureAdapter<P extends Partial<{canOptIn: boolean; enabled: boolean; isEnabled: boolean}>>(
    backendFeature?: P,
): StatToggleableFeature {
    if (backendFeature) {
        return {
            canEnable: backendFeature.canOptIn ?? false,
            isEnabled: backendFeature.enabled ?? backendFeature.isEnabled ?? false,
        };
    }
    return {
        canEnable: false,
        isEnabled: false,
    };
}
