import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';

import Button, {ButtonSize} from 'web-app/react/components/form-elements/button/button';
import {Flex} from 'web-app/react/components/layout/layout';
import {Body} from 'web-app/react/components/text/text';

const ButtonGroupContainer = styled(Flex).attrs(() => ({
    align: 'center',
}))``;

const ButtonGroup = props => {
    const {
        options,
        input,
        disabled,
        placeholder,
        isEditable,
        labelClassName,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        meta,
    } = props;

    const {value} = input;

    if (isEditable) {
        return (
            <ButtonGroupContainer>
                {options.map(opt => (
                    <Button
                        buttonSize={ButtonSize.mini}
                        isDisabled={disabled}
                        appearance={value === opt.value ? 'confirm' : 'default'}
                        box="inline"
                        key={opt.value}
                        onClick={() => input.onChange(opt.value)}
                    >
                        {opt.label}
                    </Button>
                ))}
            </ButtonGroupContainer>
        );
    }

    const selectedOption = options.find(opt => opt.value === value);

    return (
        <ButtonGroupContainer className={labelClassName}>
            <Body>{selectedOption ? selectedOption.label : placeholder}</Body>
        </ButtonGroupContainer>
    );
};

ButtonGroup.propTypes = {
    options: PropTypes.array,
    meta: PropTypes.object,
    disabled: PropTypes.bool,
    isEditable: PropTypes.bool,
    placeholder: PropTypes.string,
    labelClassName: PropTypes.string,
    input: PropTypes.object,
};

ButtonGroup.defaultProps = {
    isEditable: true,
};

export default ButtonGroup;
