import {createAction} from 'signin-app/redux/helpers';

const domain = 'EMPLOYEE_CHECK_OUT';

export const {
    action: checkOutEmployee,
    success: checkOutEmployeeSuccess,
    failed: checkOutEmployeeFailed,
} = createAction(
    `${domain}/CHECKOUT`,
    (accessToken: string, employeeId: string) => ({accessToken, employeeId}),
    response => ({response}),
    (error: Error) => ({error}),
);

export const {
    action: switchRoom,
    success: switchRoomSuccess,
    failed: switchRoomFailed,
} = createAction(
    `${domain}/SWITCH_ROOM`,
    (accessToken: string, employeeId: string) => ({accessToken, employeeId}),
    response => ({response}),
    (error: Error) => ({error}),
);
