import React from 'react';

import Portal from 'web-app/react/components/portals/portal';
import {PortalRootId} from 'web-app/react/components/portals/constants';

export default css => () =>
    (
        <Portal rootId={PortalRootId.contractPortalRoot}>
            <style type="text/css">{css}</style>
        </Portal>
    );
