import {KidoTheme as KidoMFTheme} from 'modern-famly';

import {hexToRGBA} from 'web-app/util/hex-to-rgb';

import BaseTheme from './base';

const KidoChromeColor = '#00346A';
const KidoPrimary = KidoChromeColor;
const KidoActive = hexToRGBA(KidoChromeColor, 0.8);
const KidoBackgroundHover = hexToRGBA(KidoChromeColor, 0.04);

const KidoTheme = BaseTheme.merge({
    title: 'Kido',
    chromeColor: KidoChromeColor,
    primary: KidoPrimary,
    active: KidoActive,
    backgroundHover: KidoBackgroundHover,
    mf: KidoMFTheme,
});

export default KidoTheme;
