import {Record} from 'immutable';

import hexToRGB from 'web-app/util/hex-to-rgb';

const emergingColor = hexToRGB('#CCC')!;

export interface ChildDevelopment {
    colors: {
        refinement: {
            secure: string;
            developing: string;
            emerging: string;
        };
    };
}

// So far this is very highcharts specific but at least it makes configuration possible in the future
const colors = {
    refinement: {
        secure: '#217959',
        developing: '#82e881',
        emerging: `rgba(${emergingColor.r}, ${emergingColor.g}, ${emergingColor.b}, 0.8)`,
    },
};

class ChildDevelopmentRecord extends Record<ChildDevelopment>({
    colors,
}) {}

export default ChildDevelopmentRecord;
