import {Record} from 'immutable';

interface IContainerConfiguration {
    boxShadow: string;
    borderRadius: string;
    contentAreaBorder?: string;
    tableBorder?: string;
    popUpsAndLinksBorder?: string;
    popUpsAndLinksBoxShadow?: string;
    tabSeparatorBackground?: string;
    border?: string;
    boxShadow2?: string;
}

export class ContainerConfiguration extends Record<IContainerConfiguration>({
    boxShadow: 'none',
    borderRadius: '0',
    contentAreaBorder: undefined,
    tableBorder: undefined,
    popUpsAndLinksBorder: undefined,
    popUpsAndLinksBoxShadow: undefined,
    tabSeparatorBackground: undefined,
    border: undefined,
    boxShadow2: undefined,
}) {}
