import PropTypes from 'prop-types';
import React from 'react';

import {NewLookTheme} from 'web-app/styleguide/themes';

import {G, SVG} from '../svg';
import {type IconComponent} from './icon-component';

/**
 * @deprecated use MF icons
 */
export const More: IconComponent<any> = ({fill, ...rest}) => (
    <SVG viewBox="0 0 24 24" {...rest}>
        <G fill={fill}>
            <path d="M12,14 C10.8954305,14 10,13.1045695 10,12 C10,10.8954305 10.8954305,10 12,10 C13.1045695,10 14,10.8954305 14,12 C14,13.1045695 13.1045695,14 12,14 Z M19,14 C17.8954305,14 17,13.1045695 17,12 C17,10.8954305 17.8954305,10 19,10 C20.1045695,10 21,10.8954305 21,12 C21,13.1045695 20.1045695,14 19,14 Z M5,14 C3.8954305,14 3,13.1045695 3,12 C3,10.8954305 3.8954305,10 5,10 C6.1045695,10 7,10.8954305 7,12 C7,13.1045695 6.1045695,14 5,14 Z" />
        </G>
    </SVG>
);
More.propTypes = {
    bordered: PropTypes.bool,
    transparent: PropTypes.bool,
    fill: PropTypes.string,
};
More.defaultProps = {
    fill: NewLookTheme.primary,
};
More.isPrivate = true;
export default More;
