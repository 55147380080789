import gql from 'graphql-tag';

export const CustomRegistrationFormField = gql`
    fragment CustomRegistrationFormField on CustomRegistrationFormField {
        id
        required
        mandatory
        fieldType
        description
        userConfiguration
        children {
            ...CustomRegistrationFormChildField
        }
    }
`;
