import {Record, List} from 'immutable';

import Name from 'web-app/react/entities/common/name';
import Image from 'web-app/react/entities/common/image';
import {type CheckIn} from 'web-app/react/entities/common/checkin';

export interface IChild {
    readonly birthday: string;
    readonly birthplace: string;
    readonly checkedIn: boolean;
    readonly checkinTime: string | null;
    readonly checkins: List<CheckIn>;
    readonly id: string;
    readonly childId: string;
    readonly createdTime: string;
    readonly email: string | null;
    readonly endDate: string | null;
    readonly extraInfo: string;
    readonly groupId: string;
    readonly hasVacation: boolean;
    readonly homeAddress: string | null;
    readonly image: typeof Image.default;
    readonly institutionId: string;
    readonly isAbsent: boolean;
    readonly isSick: boolean;
    readonly isSleeping: boolean;
    readonly language: string;
    readonly leaves: List<string>;
    readonly leavingReason: string;
    readonly loginId: string;
    readonly name: typeof Name.default;
    readonly nationality: string;
    readonly pickupName: string | null;
    readonly pickupRelationId: string | null;
    readonly pickupTime: string | null;
    readonly statusRegistrations: List<any>;
    readonly startDate: string;
    readonly statuses: List<string>;
    readonly lastCheckIn: CheckIn;
    readonly goHomeWithChildId: string;
}

const Child = Record<IChild>({
    birthday: '',
    birthplace: '',
    checkedIn: false,
    checkinTime: null,
    checkins: List(),
    id: '',
    childId: '',
    createdTime: '',
    email: null,
    endDate: null,
    extraInfo: '',
    groupId: '',
    hasVacation: false,
    homeAddress: null,
    image: Image.default,
    institutionId: '',
    isAbsent: false,
    isSick: false,
    isSleeping: false,
    language: '',
    leaves: List(),
    leavingReason: '',
    loginId: '',
    name: Name.default,
    nationality: '',
    pickupName: null,
    pickupRelationId: null,
    pickupTime: null,
    statusRegistrations: List(),
    startDate: '',
    statuses: List(),
    lastCheckIn: {} as CheckIn,
    goHomeWithChildId: '',
});

function fromAPI(data: any) {
    return new Child({
        ...data,
        id: data.childId,
        name: Name.fromAPI(data.name),
        image: Image.fromAPI(data.image),
        statusRegistrations: List(data.statusRegistrations),
        lastCheckIn: data.checkins && data.checkins.length > 0 ? data.checkins[0] : null,
    });
}

const ChildModel = {
    record: Child,
    default: new Child(),
    fromAPI,
};

export default ChildModel;
