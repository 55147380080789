import React from 'react';
import {type RouteComponentProps, Link} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {Text, Box, Stack, createStack, Icon} from 'modern-famly';

import translate from 'signin-app/helpers/translate';
import {accessToken, isPinApp} from 'signin-app/login/selectors';
import {limitedData} from 'signin-app/child/check-out/selectors';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import {PinHeader} from 'signin-app/child/pin-header';
import {PageBodyContainer} from 'signin-app/components/action-buttons';
import PersonSelect from 'signin-app/components/person-select';
import Header from 'signin-app/components/header';
import {useTypedSelector} from 'signin-app/components/hooks';
import * as WhitelabelSelectors from 'signin-app/whitelabel/selectors';
import {HeaderPerson} from 'signin-app/components/header-person';
import * as EmployeeSelectors from 'signin-app/employee/selectors';
import {ChildOverview} from 'signin-app/child/check-out/child-overview';
import {hasValue} from 'web-app/util/typescript';
import {useQueryParams} from 'web-app/react/hooks/use-query-params';
import {PageContainer} from 'signin-app/components/containers';
import {useCustomWhitelabelColor} from 'signin-app/components/hooks/use-custom-whitelabel-color';
import {CardsContainer} from 'signin-app/groups/group-item';

import ChildRelations from './child-relations';
import PickupTime from './pickup-time';
import {CheckinSubmit} from './checkin-submit';
import {AddPickupPerson} from './add-pickup-person';
import {fetchChild} from '../actions';
import * as ChildSelectors from '../selectors';
import * as Selectors from './selectors';
import * as Actions from './actions';

const GoingHomeWith = styled(
    createStack({
        width: '100%',
        height: '80px',
        px: '24px',
        py: '16px',
        alignItems: 'center',
        justifyContent: 'space-between',
    }),
)<{selected?: boolean; customColor?: string}>`
    box-sizing: border-box;
    border: 1px dashed ${props => props.customColor};
    border-radius: ${props => props.theme.mf.spacing(4)};
    cursor: pointer;
    ${props =>
        props.selected
            ? css`
                  background-color: ${props.customColor};
              `
            : ''}
`;

const StyledLink = styled(Link)`
    width: 100%;
`;

type CheckInRouteProps = RouteComponentProps<{childId: string}>;

export const ChildCheckIn: React.FC<CheckInRouteProps> = props => {
    const {match} = props;
    const {update} = useQueryParams(['update']);
    const isUpdatePage = hasValue(update);
    const pickupTimeRef = React.useRef<HTMLDivElement | null>(null);
    const [shouldScrollDown, setShouldScrollDown] = React.useState(true);

    const isFetching = useTypedSelector(ChildSelectors.isFetching);
    const child = useTypedSelector(state => Selectors.child(state, props));
    const token = useTypedSelector(accessToken);
    const isDataLimited = useTypedSelector(limitedData);
    const canSelectOtherChildsParent = useTypedSelector(state =>
        ChildSelectors.canSelectOtherChildsParent(state, props),
    );
    const requirePickupInfo = useTypedSelector(state =>
        ChildSelectors.requirePickupInfo(state, {childId: props.match.params.childId}),
    );
    const showPickupInfoScreen = useTypedSelector(state => Selectors.showPickupInfoScreen(state, props));
    const pickupPersonId = useTypedSelector(Selectors.selectedPickupRelationId);
    const pinApp = useTypedSelector(isPinApp);
    const pickupText = !requirePickupInfo ? translate('optionalPickupText') : translate('whoPicksUp');
    const whitelabelConfiguration = useTypedSelector(state => WhitelabelSelectors.whiteLabelConfiguration(state));
    const pinChildren = useTypedSelector(ChildSelectors.pinChildren);
    const pinEmployees = useTypedSelector(EmployeeSelectors.pinEmployees);
    const p300 = useCustomWhitelabelColor('p300');

    React.useEffect(() => {
        if (!pinApp) {
            fetchChild.dispatch(token, match.params.childId);
        }
        return () => {
            Actions.resetPickup.dispatch();
        };
    }, [token, match.params.childId, pinApp]);

    const initials = React.useMemo(() => {
        return `${child?.name.firstName.charAt(0)}${child?.name.lastName.charAt(0)}`;
    }, [child]);

    const childInfo = React.useMemo(() => {
        if (!child) {
            return null;
        }
        return (
            <HeaderPerson
                image={child?.image.small}
                initials={initials}
                name={isDataLimited ? child?.name.firstName : child?.name.fullName}
            />
        );
    }, [child, isDataLimited, initials]);

    // Show child info header only if PersonSelect will not be shown
    const shouldShowChildInfoOnPin = React.useMemo(() => {
        if (!pinApp || (pinEmployees && pinEmployees.size > 0) || (pinChildren && pinChildren.size > 1)) {
            return false;
        }
        return true;
    }, [pinApp, pinChildren, pinEmployees]);

    const scrollDown = React.useCallback(() => {
        if (pickupTimeRef.current && shouldScrollDown) {
            pickupTimeRef.current.scrollIntoView({behavior: 'smooth'});
            setShouldScrollDown(false);
        }
    }, [shouldScrollDown]);

    return (
        <PageContainer>
            {pinApp ? (
                <PinHeader whitelabelConfiguration={whitelabelConfiguration} />
            ) : (
                <Header centerElement={childInfo} />
            )}
            {isFetching || !child ? (
                <Spinner color="white" centered />
            ) : (
                <Box>
                    {showPickupInfoScreen || isUpdatePage ? (
                        <>
                            <PageBodyContainer mt={16}>
                                {shouldShowChildInfoOnPin ? (
                                    <Stack justifyContent="center" mb={14}>
                                        {childInfo}
                                    </Stack>
                                ) : null}
                                <PersonSelect activeChildId={match.params.childId} />
                                <Box>
                                    <Text variant="h6" color="n0" emphasized>
                                        {pickupText}
                                    </Text>
                                    <CardsContainer mt={4}>
                                        <ChildRelations
                                            selectedRelationId={child.pickupRelationId}
                                            checkedIn={child.checkedIn}
                                            childId={child.id}
                                            handleRelationClick={scrollDown}
                                        />
                                        {canSelectOtherChildsParent ? (
                                            <Stack>
                                                <StyledLink to={`/go-home-with/${child.id}`}>
                                                    <GoingHomeWith
                                                        selected={Boolean(child.goHomeWithChildId && !pickupPersonId)}
                                                        customColor={p300}
                                                    >
                                                        <Text variant="body" color="n0" emphasized>
                                                            {translate('goingHomeWith')}
                                                        </Text>
                                                        <Icon name="chevron_right" color="n0" />
                                                    </GoingHomeWith>
                                                </StyledLink>
                                            </Stack>
                                        ) : null}
                                        {!pinApp ? <AddPickupPerson /> : null}
                                    </CardsContainer>
                                </Box>
                                <div ref={pickupTimeRef}>
                                    <PickupTime selectedPickupTime={child.pickupTime} checkedIn={child.checkedIn} />
                                </div>
                            </PageBodyContainer>
                            <CheckinSubmit accessToken={token} child={child} isUpdatePage={isUpdatePage} />
                        </>
                    ) : (
                        <ChildOverview child={child} limitedData={isDataLimited} />
                    )}
                </Box>
            )}
        </PageContainer>
    );
};
