import React from 'react';
import {type RouteComponentProps} from 'react-router-dom';
import {Stack, Box, Text} from 'modern-famly';

import translate from 'signin-app/helpers/translate';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import PersonButton from 'signin-app/components/person-button';
import Header from 'signin-app/components/header';
import {staffGroup} from 'signin-app/staff-group/selectors';
import * as PrivateRouteSelectors from 'signin-app/components/private-route/selectors';
import {useTypedSelector} from 'signin-app/components/hooks';
import {PageContainer} from 'signin-app/components/containers';
import {PersonCardsContainer} from 'signin-app/group/group';

type GroupRouteProps = RouteComponentProps<{staffGroupId: string}>;

export const StaffGroup: React.FC<GroupRouteProps> = props => {
    const group = useTypedSelector(state => staffGroup(state, {staffGroupId: props.match.params.staffGroupId}));
    const isFetching = useTypedSelector(PrivateRouteSelectors.isPrefetching);
    return (
        <PageContainer>
            <Header />
            {isFetching ? (
                <Spinner color="white" centered />
            ) : (
                <Stack flexDirection="column" gap={8}>
                    <Text variant="h5" color="n0" textAlign="center">
                        {group?.title}
                    </Text>
                    {!(group && group.employees.size) ? (
                        <Box textAlign="center" color="n0" mt={8}>
                            {translate('noEmployees')}
                        </Box>
                    ) : (
                        <PersonCardsContainer>
                            {group &&
                                group.employees.map(employee => {
                                    return (
                                        <PersonButton
                                            key={employee.id}
                                            linkTo={
                                                employee.checkedIn
                                                    ? `/employee/${employee.id}/checkout`
                                                    : `/employee/${employee.id}/checkin`
                                            }
                                            person={employee}
                                        />
                                    );
                                })}
                        </PersonCardsContainer>
                    )}
                </Stack>
            )}
        </PageContainer>
    );
};
