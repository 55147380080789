import React from 'react';
import {
    type AutocompleteChangeDetails,
    type AutocompleteChangeReason,
    type AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material/Autocomplete';
import MuiTextField from '@mui/material/TextField';
import {styled} from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import {useFormControl} from '@mui/material/FormControl';
import MuiNativeSelect, {type NativeSelectProps as MuiNativeSelectProps} from '@mui/material/NativeSelect';

import {useModernFamlyContext} from 'modern-famly/system/modern-famly-provider';
import {useDataProps} from 'modern-famly/components/util';
import {hasValue} from 'modern-famly/util';
import {useDebounce} from 'modern-famly/util/use-debounce';
import {extractSystemStyles} from 'modern-famly/system';
import {type AlignmentProps} from 'modern-famly/system/system-props';

import {IconAdornment, ImageAdornment} from '../text-field';
import {BaseSelect, LoadingAdornment, type BaseSelectProps, type SelectOption} from './base';

type Multiple = false;
type DisableClearable = false;
type FreeSolo = undefined;
type AutoselectProps<T extends SelectOption> = MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>;

export type SelectProps<T extends SelectOption> = BaseSelectProps<T> & {
    /**
     * If `true`, the `NativeSelect` component which is optimized for mobile devices is used.
     * Note that `NativeSelect` is always used on mobile devices.
     */
    native?: boolean;

    /**
     * The change handler called when the component updates
     */
    onChange?: (
        option: T | undefined,
        reason: AutocompleteChangeReason,
        details: AutocompleteChangeDetails<T> | undefined,
    ) => void;

    /**
     * The value of the component. If a string is given, the component will use the option whose `value` matches the string.
     */
    value?: T | string | null;
} & AlignmentProps;

export const Select = <T extends SelectOption>({
    id,
    autoFocus,
    disabled: disabledFromProps,
    error: errorFromProps,
    fullWidth,
    native,
    noOptionsText,
    onChange: onChangeFromProps,
    open,
    options,
    placeholder,
    size,
    value: valueFromProps,
    getOptionAdornment,
    onInputChange,
    onClose,
    onOpen,
    autoComplete,
    filterOptions,
    isOptionEqualToValue,
    filterSelectedOptions,
    includeInputInList,
    inputValue,
    loading,
    ...rest
}: SelectProps<T>) => {
    const {isMobileApp} = useModernFamlyContext();

    const dataProps = useDataProps(rest);

    // As MUI's Autoselect component uses a FormControl itself, we have to manually get the
    // relevant values from the outer FormControl and pass them on. We ensure that values from
    // props always take precedence.
    const {disabled: formControlDisabled, error: formControlError} = useFormControl() || {};

    const disabled = disabledFromProps ?? formControlDisabled;
    const error = errorFromProps ?? formControlError;

    // MUI Autoselect's onChange passes in the native HTML event as the first argument to
    // onChange. Until someone requests otherwise we're only interested in the selected option.
    const onChange = React.useCallback<Exclude<AutoselectProps<T>['onChange'], undefined>>(
        (_, value, reason, details) => {
            onChangeFromProps?.(value ?? undefined, reason, details);
        },
        [onChangeFromProps],
    );

    const value = React.useMemo(() => {
        if (typeof valueFromProps === 'string') {
            // We return `null` rather than undefined because if the `value` is
            // provided that means that the component is used in controlled mode.
            return options.find(opt => opt.value === valueFromProps) ?? null;
        }

        return valueFromProps;
    }, [options, valueFromProps]);

    const shouldShowNative = React.useMemo(() => {
        if (hasValue(native)) {
            return native;
        }

        return isMobileApp;
    }, [native, isMobileApp]);

    const {sx: systemSx} = extractSystemStyles(rest);

    if (shouldShowNative) {
        return (
            <NativeSelect
                id={id}
                autoFocus={autoFocus}
                options={options}
                error={error}
                disabled={disabled}
                fullWidth={fullWidth}
                onChange={onChangeFromProps}
                size={size}
                value={valueFromProps}
                placeholder={placeholder}
                sx={systemSx}
                {...dataProps}
            />
        );
    }

    return (
        <BaseSelect<T, Multiple>
            id={id}
            fullWidth={fullWidth}
            noOptionsText={noOptionsText}
            open={open}
            size={size}
            disabled={disabled}
            onChange={onChange}
            renderInput={params => (
                <MuiTextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: value ? getInputAdornment(value, size) : undefined,
                        endAdornment: loading ? (
                            <>
                                <LoadingAdornment size={size ?? 'regular'} />
                                {params.InputProps.endAdornment}
                            </>
                        ) : (
                            params.InputProps.endAdornment
                        ),
                    }}
                    error={error}
                    variant="outlined"
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                />
            )}
            options={options}
            // Prevents the error: "A component is changing the uncontrolled value state..."
            // The `onChange` handler will never recieve this value.
            value={value}
            getOptionAdornment={getOptionAdornment}
            onInputChange={onInputChange}
            onClose={onClose}
            onOpen={onOpen}
            autoComplete={autoComplete}
            filterOptions={filterOptions}
            isOptionEqualToValue={isOptionEqualToValue}
            filterSelectedOptions={filterSelectedOptions}
            includeInputInList={includeInputInList}
            inputValue={inputValue}
            loading={loading}
            sx={systemSx}
            {...dataProps}
        />
    );
};

/**
 * Gets the start adornment of the input field based on the passed option
 */
const getInputAdornment = <T extends SelectOption>(selectedOption: T, size: SelectProps<T>['size']) => {
    if (selectedOption.imageUrl) {
        return <ImageAdornment imageUrl={selectedOption.imageUrl} position="start" size={size} />;
    }

    if (selectedOption.icon) {
        const {name, color, filled} = selectedOption.icon;
        return <IconAdornment icon={name} size={size} position="start" color={color} filled={filled} />;
    }

    return undefined;
};

/*
|------------------------------------------------------------------------------
| NativeSelect
|------------------------------------------------------------------------------
|
| The native <select> component that will be used when the end user is on a
| mobile device
|
*/
type NativeSelectProps<T extends SelectOption> = Omit<SelectProps<T>, 'noOptionsText' | 'open'> & {
    sx: MuiNativeSelectProps['sx'];
};

const NativeSelect = <T extends SelectOption>({
    autoFocus,
    disabled,
    options,
    error,
    fullWidth,
    onChange: onChangeFromProps,
    size: sizeFromProps,
    value: valueFromProps,
    placeholder,
    id,
    sx,
    ...rest
}: NativeSelectProps<T>) => {
    const size = sizeFromProps ?? 'regular';
    const value = typeof valueFromProps === 'string' ? valueFromProps : valueFromProps?.value;

    const dataProps = useDataProps(rest);

    const onChange = React.useCallback<React.ChangeEventHandler<HTMLSelectElement>>(
        e => {
            if (!onChangeFromProps) {
                return;
            }

            onChangeFromProps(
                options.find(option => option.value === e.target.value),
                'selectOption',
                undefined,
            );
        },
        [onChangeFromProps, options],
    );

    return (
        <MuiNativeSelect
            autoFocus={autoFocus}
            input={<NativeInput id={id} size={size} disabled={disabled} error={error} placeholder={placeholder} />}
            disabled={disabled}
            value={value}
            fullWidth={fullWidth}
            onChange={onChange}
            {...dataProps}
            sx={sx}
        >
            {/* If an empty option is not provided the native select will automatically select the first option */}
            <option key="NATIVE_SELECT__NO_OPTION" value={undefined}></option>
            {options.map(option => {
                return (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                );
            })}
        </MuiNativeSelect>
    );
};

/**
 * The root element of the native select
 */
const NativeInput = styled(InputBase)<{size: 'compact' | 'regular'}>(({theme, size}) => ({
    // Base state - targets the `select` element
    '& .MuiInputBase-input': {
        height: size === 'compact' ? '36px' : '48px',
        boxSizing: 'border-box',
        borderRadius: '8px',
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.modernFamlyTheme.colorPalette.n200}`,
        fontSize: size === 'regular' ? theme.typography.body.fontSize : theme.typography['body-small'].fontSize,
        padding: '0 26px 0 12px',
        '&:hover:not(:disabled)': {
            borderColor: theme.modernFamlyTheme.colorPalette.p300,
        },
        '&:focus': {
            background: theme.modernFamlyTheme.colorPalette.n0,
            borderRadius: '8px',
            borderColor: theme.modernFamlyTheme.colorPalette.p400,
        },
    },
    // Error state
    '&.Mui-error .MuiInputBase-input': {
        borderColor: theme.modernFamlyTheme.colorPalette.r400,
        '&:hover:not(:disabled)': {
            borderColor: theme.modernFamlyTheme.colorPalette.r400,
        },
    },

    // Disabled state
    '&.Mui-disabled .MuiInputBase-input': {
        backgroundColor: theme.modernFamlyTheme.colorPalette.n75,
        borderColor: theme.modernFamlyTheme.colorPalette.n75,
        WebkitTextFillColor: theme.modernFamlyTheme.colorPalette.n300,
    },

    // Ensures that the placement of the chevron aligns with the
    // non-native counterpart
    '& .MuiNativeSelect-icon': {
        marginRight: '10px',
    },
}));

type AsyncOptionsProps<T extends SelectOption> = Pick<
    SelectProps<T>,
    | 'onChange'
    | 'onInputChange'
    | 'value'
    | 'options'
    | 'inputValue'
    | 'loading'
    | 'onOpen'
    | 'onClose'
    | 'filterSelectedOptions'
    | 'includeInputInList'
    | 'autoComplete'
    | 'filterOptions'
    | 'isOptionEqualToValue'
>;

/**
 * Utility function for the asynchronous Select component to determine if an option is equal to a value.
 *
 * This is necessary as MUI's Autoselect component default to compare by reference (strict equality) which
 * will never be true for options fetched asynchronously.
 */
const isOptionEqualToValue = <T extends SelectOption>(option: T, value: T) => option.value === value.value;

/**
 * A hook that provides the necessary props to use the Select component with asynchronously loaded options.
 * This hook is intended to be used in conjunction with the Select component.
 *
 * @param fetchOptions A function that fetches options based on a query
 */
export function useAsyncOptionsForSelect<T extends SelectOption>({
    fetchOptions,
    onChange: onChangeFromProps,
}: {
    fetchOptions: (query: string) => Promise<Array<T>>;
    onChange?: SelectProps<T>['onChange'];
}): AsyncOptionsProps<T> {
    const [selectedOption, setSelectedOption] = React.useState<T | null>(null);
    const [query, setQuery] = React.useState<string>('');
    const [state, setState] = React.useState<'clean' | 'dirty' | 'closed'>('closed');

    const debouncedValue = useDebounce(query, 500);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<T[]>([]);

    React.useEffect(() => {
        if (!debouncedValue) {
            return () => {};
        }

        // We only want to fetch options when user has entered text
        if (state !== 'dirty') {
            return () => {};
        }

        // Avoids results of previously fired network requests to be visible to the user
        let wasCancelled = false;

        setLoading(true);

        fetchOptions(debouncedValue)
            .then(result => {
                if (wasCancelled) {
                    return;
                }

                const newOptions = hasValue(selectedOption) ? [selectedOption, ...result] : result;

                setOptions(newOptions);
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            wasCancelled = true;
        };
    }, [debouncedValue, state, selectedOption, fetchOptions]);

    const onChange = React.useCallback<NonNullable<SelectProps<T>['onChange']>>(
        (selectedOption, reason, details) => {
            setSelectedOption(selectedOption ?? null);
            onChangeFromProps?.(selectedOption, reason, details);

            // Reset the query when an option is selected
            // The available options from the search are still available as we don't change the options
            if (reason === 'selectOption') {
                setQuery('');
                setState('clean');
            }
        },
        [onChangeFromProps],
    );

    const onInputChange = React.useCallback<NonNullable<SelectProps<SelectOption>['onInputChange']>>(
        (_, value, reason) => {
            if (reason === 'input') {
                setState('dirty');
                setQuery(value);
            }

            if (reason === 'reset') {
                setQuery(value);
            }
        },
        [],
    );

    const onOpen = React.useCallback(() => {
        setState('clean');
    }, []);

    const onClose = React.useCallback(() => {
        setState('closed');
    }, []);

    return {
        onChange,
        onInputChange,
        value: selectedOption,
        options,
        inputValue: query,
        loading,
        onOpen,
        onClose,
        filterSelectedOptions: false,
        includeInputInList: true,
        autoComplete: true,
        filterOptions: x => x,
        isOptionEqualToValue,
    };
}
