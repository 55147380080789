import React from 'react';
import ReactSelect, {type Props as ReactSelectProps, type ValueType, type OptionsType} from 'react-select';
import i18next from 'i18next';
import styled from 'styled-components';

import {hasValue} from 'web-app/util/typescript';
import PlatformHelper from 'web-app/helpers/platform-helper';

import {getDefaultComponents, getDefaultStyles} from './components';

/*
|------------------------------------------------------------------------------
| Select component
|------------------------------------------------------------------------------
|
| The <Select> component is exported with a class name prefix. This makes it
| easy for consumers of the component to style it using styled components.
|
| For more info see https://react-select.com/styles#using-classnames
|
*/
const CLASS_NAME_PREFIX = 'select-component';

export function isReadOnlyArray<T>(cand: any): cand is ReadonlyArray<T> {
    return Array.isArray(cand);
}

interface OwnProps {
    ['data-e2e-id']?: string;
    ['data-intercom-target']?: string;

    /**
     * If this value is true, Select will keep the same rendering path as on Web and will _not_ use native components.
     * Useful if you're rendering exotic Selects values with custom option renderers.
     */
    nativeOnMobileOptOut?: boolean;
}

/**
 * If you as a consumer of the Select component need additional props from react-select, please pick them out here
 * and make sure to pass them to the <ReactSelect> instance in the <CustomSelect> component.
 */
export type BaseProps<T extends {}> = OwnProps &
    Pick<
        ReactSelectProps<T>,
        | 'autoFocus'
        | 'className'
        | 'classNamePrefix'
        | 'closeMenuOnSelect'
        | 'components'
        | 'controlShouldRenderValue'
        | 'defaultMenuIsOpen'
        | 'hideSelectedOptions'
        | 'inputValue'
        | 'isClearable'
        | 'isDisabled'
        | 'isLoading'
        | 'menuIsOpen'
        | 'noOptionsMessage'
        | 'onBlur'
        | 'onFocus'
        | 'onInputChange'
        | 'onMenuClose'
        | 'options'
        | 'placeholder'
        | 'styles'
        | 'value'
        | 'isSearchable'
        | 'filterOption'
    >;

export type MaybeOption<T> = T | undefined | null;

export interface SingleProps<T> {
    onChange: (value: MaybeOption<T>) => void;
    isMulti?: false;
}

export interface MultiProps<T extends {}> {
    onChange: (value: MaybeOption<OptionsType<T>>) => void;
    isMulti: true;
}

/**
 * These are props that augments the Select component with custom functionality. If you need to add a prop that is
 * not part of the `ReactSelectProps`, please add them here
 */
type CustomProps = {
    menuPortalTargetId?: string;
    isInvalid?: boolean;
};

export type Props<T extends {}> = BaseProps<T> & (SingleProps<T> | MultiProps<T>) & CustomProps;

export interface BaseOptionType {
    label: string;
    value: string;
}

function CustomSelect<T extends BaseOptionType>(props: Props<T>) {
    const styles = React.useRef(props.styles ?? getDefaultStyles());

    const menuPortalTarget = React.useMemo(() => {
        return props.menuPortalTargetId ? document.getElementById(props.menuPortalTargetId) : null;
    }, [props.menuPortalTargetId]);

    const componentsFromProps = props.components;
    const e2eId = props['data-e2e-id'];
    const dataIntercomTarget = props['data-intercom-target'];

    const components = React.useMemo(() => {
        return componentsFromProps
            ? {...getDefaultComponents({e2eId, dataIntercomTarget}), ...componentsFromProps}
            : getDefaultComponents({e2eId, dataIntercomTarget});
    }, [componentsFromProps, e2eId, dataIntercomTarget]);

    /**
     * If we're on in the mobile app, we want to use a good ol' <select> component
     *
     * Right now we're doing some nasty any-casting here. This is because of the ValueType that react-select
     * is working with which has to cover the multi-select case. We should be safe here because of the props type
     * guarding, but optimally we should type guard the `onChange` and `value` props when we're in mobile app as well
     */
    if (
        PlatformHelper.shouldUseNativeInputFields() &&
        !props.isMulti &&
        optionsCanBeUsedInNativeSelect(props.options) &&
        !props.nativeOnMobileOptOut
    ) {
        return (
            <NativeSelect
                autoFocus={props.autoFocus}
                options={props.options}
                isClearable={props.isClearable}
                onChange={props.onChange as any}
                placeholder={typeof props.placeholder === 'string' ? props.placeholder : undefined}
                value={hasValue(props.value) ? (props.value as any).value : undefined}
            />
        );
    }

    // This is the magic that gives us typings on the CustomSelect's `onChange` prop.
    // If `props.isMulti` is `false` the value passed to `onChange` is guaranteed to
    // be `T | null | undefined`. If `props.isMulti` is `true` the value passed to
    // `onChange` is guaranteed to be `OptionsType<T> | null | undefined`.
    const onChange = (value: ValueType<T>): void => {
        if (!hasValue(value)) {
            props.onChange(null);
            return;
        }

        if (isReadOnlyArray(value)) {
            if (props.isMulti) {
                props.onChange(value);
                return;
            }
        }

        if (!isReadOnlyArray(value)) {
            if (!props.isMulti) {
                props.onChange(value);
                return;
            }
        }
        props.onChange(null);
    };

    return (
        <ReactSelect
            classNamePrefix={CLASS_NAME_PREFIX}
            styles={styles.current}
            noOptionsMessage={props.noOptionsMessage ?? (() => i18next.t('noResults'))}
            menuPortalTarget={menuPortalTarget}
            {...props}
            placeholder={props.placeholder || i18next.t('select')}
            components={components}
            onChange={onChange}
        />
    );
}

export default CustomSelect;

/*
|------------------------------------------------------------------------------
| Native select component
|------------------------------------------------------------------------------
|
| In the mobile app, we want to use an HTML5 select so iOS and Android can
| show their native select inputs
|
*/
const NATIVE_SELECT_DEFAULT_VALUE = '__NATIVE_SELECT__DEFAULT_VALUE';

interface NativeOption {
    value: string;
    label: string;
}

interface NativeProps {
    autoFocus?: boolean;
    options: NativeOption[];
    onChange: (value: NativeOption | null | undefined) => void;
    isClearable?: boolean;
    placeholder?: string;
    value?: string;
    isDisabled?: boolean;
}

function optionsCanBeUsedInNativeSelect(options: any): options is NativeOption[] {
    // Check that we're defaulting with an arary
    if (!Array.isArray(options)) {
        return false;
    }

    // Ensure that the array doesn't contain `null` or `undefined`
    const areSomeOptionsUndefined = options.some(option => {
        return !hasValue(option);
    });

    if (areSomeOptionsUndefined) {
        return false;
    }

    // Ensure that all options have label and value fields that are strings
    return options.every(option => {
        return typeof option.label === 'string' && typeof option.value === 'string';
    });
}

function NativeSelect(props: NativeProps) {
    // Special handling of the HTML <select> element when the field is uncontrolled and
    // unclearable. In this case we want to ensure that "placeholder element" is selected.
    // If this isn't done, the native HTML <select> element will set the selected option
    // to the first non-disabled option. When trying to select that same first non-disabled
    // option, the `onChange` handler will not be called  (as the option is not being changed)
    const value = !props.isClearable && !props.value ? NATIVE_SELECT_DEFAULT_VALUE : props.value;

    return (
        <StyledSelect
            autoFocus={props.autoFocus}
            disabled={props.isDisabled}
            onChange={e => {
                if (e.target.value === NATIVE_SELECT_DEFAULT_VALUE) {
                    props.onChange(null);
                    return;
                }

                const option = props.options.find(option => option.value === e.target.value);
                props.onChange(option ?? null);
            }}
            value={value}
        >
            <option
                value={NATIVE_SELECT_DEFAULT_VALUE}
                disabled={!props.isClearable}
                hidden={hasValue(props.value) && !props.isClearable}
            >
                {props.placeholder || i18next.t('select')}
            </option>
            {(props.options as any)?.map(option => {
                return (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                );
            })}
        </StyledSelect>
    );
}

const StyledSelect = styled.select`
    border-radius: 4px;
    font-size: ${props => props.theme.fontConfiguration.sizes.Caption};
    border-color: ${props => props.theme.borderConfiguration.defaultColor};
    line-height: 36px;
    height: 36px;
    color: ${props => (props.value === NATIVE_SELECT_DEFAULT_VALUE ? props.theme.textDisabled : props.theme.text)};

    &:focus,
    &:active {
        border-color: ${props => props.theme.borderConfiguration.focus};
    }

    &:disabled {
        border-color: transparent;
        background-color: ${props => props.theme.borderConfiguration.disabled};
        color: ${props => props.theme.textDisabled};
    }
`;
