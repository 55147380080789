import {type QueryHookOptions} from '@apollo/client';

import {FetchCustomFormsRegistrationPermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-custom-forms-registration-permissions.query';
import {
    type FetchCustomFormsRegistrationPermissionsQuery,
    type FetchCustomFormsRegistrationPermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-custom-forms-registration-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchCustomFormsRegistrationPermissions(
    options: QueryHookOptions<
        FetchCustomFormsRegistrationPermissionsQuery,
        FetchCustomFormsRegistrationPermissionsQueryVariables
    >,
): ImmerWrappedQueryResult<
    FetchCustomFormsRegistrationPermissionsQuery,
    FetchCustomFormsRegistrationPermissionsQueryVariables
> {
    return useStatQuery<
        FetchCustomFormsRegistrationPermissionsQuery,
        FetchCustomFormsRegistrationPermissionsQueryVariables
    >(FetchCustomFormsRegistrationPermissions, options);
}
