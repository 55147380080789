import {type QueryHookOptions} from '@apollo/client';

import {FetchAttendanceDetailsPermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-attendance-details-permissions.query';
import {
    type FetchAttendanceDetailsPermissionsQuery,
    type FetchAttendanceDetailsPermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-attendance-details-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchAttendanceDetailsPermissions(
    options: QueryHookOptions<FetchAttendanceDetailsPermissionsQuery, FetchAttendanceDetailsPermissionsQueryVariables>,
): ImmerWrappedQueryResult<FetchAttendanceDetailsPermissionsQuery, FetchAttendanceDetailsPermissionsQueryVariables> {
    return useStatQuery<FetchAttendanceDetailsPermissionsQuery, FetchAttendanceDetailsPermissionsQueryVariables>(
        FetchAttendanceDetailsPermissions,
        options,
    );
}
