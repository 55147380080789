import {useCallback, useState} from 'react';

export const useListDrawer = () => {
    const [isOpen, setIsOpen] = useState(false);

    const openDrawer = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);

    const closeDrawer = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return {
        openDrawer,
        drawerProps: {isOpen, closeDrawer},
    };
};
