import {Map, List} from 'immutable';

import Behavior from './behavior';

export default class BehaviorsCollection {
    public _behaviorMap: Map<string, Behavior>;

    constructor(behaviorObjs: any[]) {
        const behaviorList = List(behaviorObjs).map(obj => new Behavior(obj));

        this._behaviorMap = Map<string, Behavior>().withMutations(map => {
            behaviorList.reduce((m, behavior) => {
                if (behavior.id) {
                    return m.set(behavior.id, behavior);
                } else {
                    return m;
                }
            }, map);
        });
    }

    public behaviorForId(id: string) {
        return this._behaviorMap.get(id);
    }

    public includes(id: string) {
        return Boolean(this.behaviorForId(id));
    }
}

export const getBehaviorsCollectionFromGraphQL = (behaviors: any[]) =>
    new BehaviorsCollection(getMappedBehaviors(behaviors));

const getMappedBehaviors = (behaviors: any[]) => {
    if (behaviors) {
        return behaviors.map(({id, behaviorId, ...rest}) => {
            if (rest) {
                return {
                    id: behaviorId || id,
                    payload: {
                        ...rest,
                    },
                };
            } else {
                return {
                    id: behaviorId || id,
                };
            }
        });
    }
    return [];
};
