import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';
import styled from 'styled-components';

import {
    isPartDayLeave,
    type PersonStatus,
    type StatusDescription,
} from 'web-app/react/staffing-attendance/hooks/use-adapt-staff-hours-to-person-status-map';
import {timeFormatAgnosticSplitTimeString} from 'web-app/react/components/form-elements/time/time';

import {Base} from '../layout/layout';

// same config as the other status display elements
const StyledStatusBase = styled(Base)`
    font-size: ${props => props.theme.fontConfiguration.sizes.Small};
    font-family: ${props => `Inter, ${props.theme.fontConfiguration.family}`};
    color: #999;
`;

export const PersonButtonStatus: React.FC<React.PropsWithChildren<PersonStatus>> = ({
    isSick,
    hasVacation,
    hasChildSick,
    isAbsent,
}) => {
    return (
        <React.Fragment>
            <StyledStatus statusDescription={isSick} />
            <StyledStatus statusDescription={hasVacation} />
            <StyledStatus statusDescription={hasChildSick} />
            <StyledStatus statusDescription={isAbsent} />
        </React.Fragment>
    );
};

/**
 * uses the given moment object to format a given string in the format `HH:mm`
 *
 * @param mutableMomentObject an instance of a moment object, which will be mutated in the process
 * @param toFormat the `HH:mm` formatted string to be localized and reformatted
 * @returns the localized version of the formatted string
 */
function formatHourAndMinute(mutableMomentObject: moment.Moment, toFormat: string): string {
    const [hours, minutes] = toFormat.split(timeFormatAgnosticSplitTimeString).map(split => Number(split));
    // not cloning the object here, as 'set' is overwriting the necessary elements and
    return mutableMomentObject.set({hours, minutes}).format('LT');
}

const StyledStatus: React.FC<React.PropsWithChildren<{statusDescription: StatusDescription}>> = ({
    statusDescription,
}) => {
    if (!statusDescription || statusDescription.some(status => status === false)) {
        return null;
    } else if (statusDescription.some(status => status === true)) {
        return <StyledStatusBase>{i18next.t('leaveRegistration.subLeaves.fullDay')}</StyledStatusBase>;
    }
    const currentTime = moment();
    return (
        <React.Fragment>
            {statusDescription.filter(isPartDayLeave).map(status => {
                const formattedFrom = formatHourAndMinute(currentTime, status.from);
                const formattedTo = formatHourAndMinute(currentTime, status.to);
                return (
                    <StyledStatusBase key={`${status.from}-${status.to}`}>
                        {formattedFrom} - {formattedTo}
                    </StyledStatusBase>
                );
            })}
        </React.Fragment>
    );
};
