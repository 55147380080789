export default function hexToRGB(hex: string): {r: number; g: number; b: number} | null {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
}

export const hexToRGBList = (hex: string) => {
    const rgb = hexToRGB(hex);

    if (!rgb) {
        return undefined;
    }

    const {r, g, b} = rgb;
    return [r, g, b];
};

export const hexToRGBString = (hex: string) => (hexToRGBList(hex) || []).join(', ');

export const hexToRGBA = (hex: string, alpha: number) => `rgba(${hexToRGBString(hex)}, ${alpha})`;
