import {type QueryHookOptions} from '@apollo/client';

import {FetchStaffAttendanceByEmployeePermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-staff-attendance-by-employee-permissions.query';
import {
    type FetchStaffAttendanceByEmployeePermissionsQuery,
    type FetchStaffAttendanceByEmployeePermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-staff-attendance-by-employee-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchStaffAttendanceByEmployeePermissions(
    options: QueryHookOptions<
        FetchStaffAttendanceByEmployeePermissionsQuery,
        FetchStaffAttendanceByEmployeePermissionsQueryVariables
    >,
): ImmerWrappedQueryResult<
    FetchStaffAttendanceByEmployeePermissionsQuery,
    FetchStaffAttendanceByEmployeePermissionsQueryVariables
> {
    return useStatQuery<
        FetchStaffAttendanceByEmployeePermissionsQuery,
        FetchStaffAttendanceByEmployeePermissionsQueryVariables
    >(FetchStaffAttendanceByEmployeePermissions, options);
}
