import React from 'react';

/**
 * A hook to know if the consuming component is still mounted.
 *
 * It is sometimes necessary to update a component asynchronously. While a promise is still running,
 * it might happen that the component is already unmounted, resulting in a memory leak and a react error.
 *
 * @see https://usehooks-ts.com/react-hook/use-is-mounted
 * @returns a callback indicating if a component is still mounted, or not
 */
export function useIsMounted(): () => boolean {
    const isMounted = React.useRef(false);

    React.useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    return React.useCallback(() => isMounted.current, []);
}
