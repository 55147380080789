import React from 'react';
import styled from 'styled-components';
import {Icon, Box, createStack} from 'modern-famly';

import {history} from 'signin-app/redux/store';
import {useCustomWhitelabelColor} from 'signin-app/components/hooks/use-custom-whitelabel-color';

const StyledHeader = styled(
    createStack({
        flexDirection: {
            base: 'column',
            mobileLandscape: 'row',
        },
        alignItems: 'center',
        justifyContent: 'space-between',
    }),
)``;

const StyledIconContainer = styled(
    createStack({
        borderRadius: '50%',
        width: '48px',
        height: '48px',
        alignItems: 'center',
        justifyContent: 'center',
    }),
)<{customBackgroundColor?: string}>`
    background-color: ${props => props.customBackgroundColor};
`;

interface HeaderProps {
    rightElement?: React.ReactNode;
    centerElement?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({rightElement, centerElement}) => {
    const p400 = useCustomWhitelabelColor('p400');

    return (
        <StyledHeader alignItems="flex-start" gap={2}>
            <StyledIconContainer onClick={() => history.goBack()} customBackgroundColor={p400}>
                <Icon name="arrow_back" color="n0" />
            </StyledIconContainer>
            <Box>{centerElement}</Box>
            <Box width={14}>{rightElement}</Box>
        </StyledHeader>
    );
};

export default Header;
