import ENV from 'signin-app/config/environment';
import {Headers} from 'web-app/api/clients/constants';
import LocalStorageService from 'web-app/services/local-storage';
import generateUUID from 'web-app/util/uuid';
import getInstallationId from 'web-app/services/installation-id-manager';
import {type APIConfig} from 'web-app/shared/environment-helpers';
import {getLoginData} from 'signin-app/api/helpers/local-storage';

interface RequestHeaders {
    headers: {[x: string]: string};
    requestUUID: string;
}

class APIHelper {
    private savedAPIKey?: string;
    private apiConfigs: APIConfig[] = ENV.APP.apiConfigs;

    constructor() {
        this.initConfig(LocalStorageService.getFromLocalStorage('apiKey'));
    }

    public getApiUrlByKey(key: string): string {
        return this.getConfigByKey(key).api;
    }

    public getGraphQLUrlByKey(key: string): string {
        return this.getConfigByKey(key).graphql;
    }

    public getRequestHeaders(authenticated?: boolean): RequestHeaders {
        const headers: {[x: string]: string} = {};
        const installationId = getInstallationId();

        if (authenticated) {
            headers[Headers.XFamlyAccesstoken] = getLoginData().accessToken;
        }

        // Request identifier that the backend returns in the response, allowing the frontend to verify that the request
        // matches the response
        const requestUUID = generateUUID();

        headers[Headers.XFamlyRequestUuid] = requestUUID;
        headers[Headers.XFamlyPlatform] = 'signin/v2';

        if (installationId) {
            headers[Headers.XFamlyInstallationId] = installationId;
        }

        return {
            headers,
            requestUUID,
        };
    }

    get baseConfig(): APIConfig {
        const baseAPIConfig = this.apiConfigs.find(conf => conf.isBase);
        if (baseAPIConfig) {
            return baseAPIConfig;
        }
        throw new Error(`No baseConfig found in apiConfigs`);
    }

    get apiKeys(): string[] {
        return this.apiConfigs.map(conf => conf.key);
    }

    get apiConfig(): APIConfig {
        if (this.savedAPIKey && ENV.APP.apiConfigs.length > 1) {
            return this.getConfigByKey(this.savedAPIKey);
        }
        // If there is an accessToken but no savedAPIKey
        // return the baseConfig since they can't be logged in to a new tld
        else if (getLoginData().accessToken) {
            return this.baseConfig;
        } else {
            return this.apiConfigs[0];
        }
    }

    // apiConfig setter can't be used because setter's argument
    // must be of the same type as the return type of its getter
    public setApiConfig(key: string) {
        if (this.apiKeys.includes(key)) {
            LocalStorageService.saveToLocalStorage('apiKey', key);
            this.initConfig(key);
        } else {
            throw new Error(`Set apiConfig - API config not found: ${key}`);
        }
    }

    get baseUrl(): string {
        return `${window.location.origin}/`;
    }

    get apiKey(): string {
        return this.apiConfig.key;
    }

    get apiUrl(): string {
        return this.apiConfig.api;
    }

    get famlyapiUrl(): string {
        return this.apiConfig.famlyapi;
    }

    get graphqlUrl(): string {
        return this.apiConfig.graphql;
    }

    private initConfig(key: string | undefined) {
        this.savedAPIKey = key;
    }

    private getConfigByKey(key: string): APIConfig {
        const config = this.apiConfigs.find(conf => conf.key === key);
        if (config) {
            return config;
        }
        throw new Error(`getConfigByKey - API config not found: ${key}`);
    }
}

const apiHelper = new APIHelper();
export default apiHelper;
