import {Record} from 'immutable';
import {BaseTheme, type Theme as ModernFamlyTheme} from 'modern-famly';

import {type OneOffColor, ColorConfiguration} from 'web-app/styleguide/colors';
import {ContainerConfiguration} from 'web-app/styleguide/containers';
import {BorderConfiguration} from 'web-app/styleguide/borders';
import {InformationArchitectureConfiguration} from 'web-app/styleguide/information-architecture';
import {NavigationConfiguration} from 'web-app/styleguide/navigation-configuration';
import {ShadowConfiguration} from 'web-app/styleguide/shadows';
import {FontConfiguration} from 'web-app/styleguide/fonts';
import DefaultTimingConfiguration, {type TimingConfiguration} from 'web-app/styleguide/timings';

import ChildDevelopmentConfiguration from '../child-development';
import {type SpacingConfiguration, DefaultSpacingConfiguration} from '../spacing';
import {ChartsConfiguration} from '../charts';
import {ButtonConfiguration} from '../components/buttons';
import {type ElevationConfiguration, defaultElevationConfiguration} from '../elevations';

export interface ITheme {
    readonly title: string;
    readonly primary: string;
    readonly secondary: string;
    readonly chromeColor: string;
    readonly active: string;
    readonly accent1: string;
    readonly accent2: string;
    readonly accent3: string;
    readonly analogue1: string;
    readonly analogue2: string;
    readonly analogue3: string;
    readonly analogue4: string;
    readonly text: string;
    readonly blackScrim: string;
    readonly textSecondary: string;
    readonly textDisabled: string;
    readonly invertedText: string;
    readonly invertedTextSecondary: string;
    readonly invertedTextDisabled: string;
    readonly whiteSecondary: string;
    readonly whiteDisabled: string;
    readonly delimiter: string;
    readonly background: string;
    readonly backgroundHover: string;
    readonly applicationBackground: string;
    readonly convertOneOffColor: (oneOffColor: OneOffColor) => string;
    readonly shadow: ShadowConfiguration;
    readonly fontConfiguration: FontConfiguration;
    readonly buttonConfiguration: ButtonConfiguration;
    readonly containerConfiguration: ContainerConfiguration;
    readonly borderConfiguration: BorderConfiguration;
    readonly informationArchitectureConfiguration: InformationArchitectureConfiguration;
    readonly chartsConfiguration: ChartsConfiguration;
    readonly childDevelopment: ChildDevelopmentConfiguration;
    readonly colors: ColorConfiguration;
    readonly navigationConfiguration: NavigationConfiguration;

    /**
     * The Modern Famly theme associated with this theme
     */
    readonly mf: ModernFamlyTheme;

    /**
     * Temporary function that will allow us to easily search for new color usage
     * once the final implementation is ready.
     */
    readonly newColor: (newColor: string) => string;
    readonly spacing: SpacingConfiguration;
    readonly timings: TimingConfiguration;
    readonly elevation: ElevationConfiguration;
}

export class Theme extends Record<ITheme>({
    title: '',
    primary: '',
    secondary: '',
    chromeColor: '',
    active: '',
    accent1: '',
    accent2: '',
    accent3: '',
    analogue1: '',
    analogue2: '',
    analogue3: '',
    analogue4: '',
    text: '',
    blackScrim: '',
    textSecondary: '',
    textDisabled: '',
    invertedText: '',
    invertedTextSecondary: '',
    invertedTextDisabled: '',
    whiteSecondary: '',
    whiteDisabled: '',
    delimiter: '',
    background: '',
    backgroundHover: '',
    applicationBackground: '',
    convertOneOffColor: color => color,
    shadow: new ShadowConfiguration(),
    fontConfiguration: new FontConfiguration(),
    buttonConfiguration: new ButtonConfiguration(),
    containerConfiguration: new ContainerConfiguration(),
    borderConfiguration: new BorderConfiguration(),
    informationArchitectureConfiguration: new InformationArchitectureConfiguration(),
    chartsConfiguration: new ChartsConfiguration(),
    childDevelopment: new ChildDevelopmentConfiguration(),
    colors: new ColorConfiguration(),
    newColor: (hslColor: string) => hslColor,
    spacing: DefaultSpacingConfiguration,
    timings: DefaultTimingConfiguration,
    elevation: defaultElevationConfiguration,
    navigationConfiguration: new NavigationConfiguration(),
    mf: BaseTheme,
}) {}
