import {createAction as createTypesafeAction} from 'typesafe-actions';

const successFallback = () => ({});
const errorFallback = (error: Error) => ({error});
const abortedFallback = () => ({});

export const createAction = <T extends string, TActionCreatorPayload, TActionPayloadArgs extends any[]>(
    type: T,
    actionPayloadCreator: (...args: TActionPayloadArgs) => TActionCreatorPayload,
) => {
    return createAllActions(type, actionPayloadCreator, successFallback, errorFallback);
};

type ActionType = ReturnType<typeof createAction>['action'];
export type InferAction<T extends ActionType> = ReturnType<T['action']>;

export const createAllActions = <
    T extends string,
    TActionCreatorPayload,
    TActionPayloadArgs extends any[],
    TSuccessActionCreatorPayload,
    TSuccessActionPayloadArgs extends any[],
    TFailedActionCreatorPayload,
    TFailedActionPayloadArgs extends any[],
>(
    type: T,
    actionPayloadCreator: (...args: TActionPayloadArgs) => TActionCreatorPayload,
    successActionPayloadCreator: (...args: TSuccessActionPayloadArgs) => TSuccessActionCreatorPayload,
    failedActionPayloadCreator?: (...args: TFailedActionPayloadArgs) => TFailedActionCreatorPayload,
) => {
    return {
        action: {
            type,
            action: createTypesafeAction(type, actionPayloadCreator)(),
        },
        success: {
            type: `${type}_SUCCESS`,
            action: createTypesafeAction(`${type}_SUCCESS`, successActionPayloadCreator)(),
        },
        failed: {
            type: `${type}_FAILED`,
            action: createTypesafeAction(`${type}_FAILED`, failedActionPayloadCreator)(),
        },
        aborted: {
            type: `${type}_ABORTED`,
            action: createTypesafeAction(`${type}_ABORTED`, abortedFallback)(),
        },
    };
};
