import {Record} from 'immutable';

import {makeReducer, match} from 'web-app/util/redux';

import * as Actions from './actions';

interface IChildrenGroupState {
    showSignedIn: boolean;
}

export class ChildrenGroupState extends Record<IChildrenGroupState>({
    showSignedIn: false,
}) {}

export default makeReducer<ChildrenGroupState>(
    [
        match(Actions.toggleWhoPicksUp, state => {
            return state.merge({
                showSignedIn: !state.showSignedIn,
            });
        }),
    ],
    new ChildrenGroupState(),
);
