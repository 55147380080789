import React from 'react';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useFetchCustomFormsRegistrationPermissions} from './queries/use-fetch-custom-form-registration-permissions';
import {type StatPermissions} from './stat-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';

export function useCustomFormsRegistrationPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['customRegistrationForms']['registration'] & {loading: boolean} {
    const {institutionSetId, shouldQuery} = payload;
    const {loading, data} = useFetchCustomFormsRegistrationPermissions({
        variables: {
            institutionSetId,
        },
        skip: !shouldQuery || !institutionSetId,
    });

    const crud = useCrudPermissionAdapter(data?.attendance.management.customRegistrationForm.registration);

    return React.useMemo(
        () => ({
            loading,
            ...crud,
        }),
        [crud, loading],
    );
}
