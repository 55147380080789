import moment from 'moment-timezone';

import {type Times} from 'signin-app/components/list-times/types';

const makeIsMinutesLaterThanNow = (minutesNow: number) => (minutes: number) => minutes > minutesNow;

export const timesLaterThanNow = (times: Times[], timezone: string): Times[] => {
    const now = moment.tz(timezone);
    const hoursNow = now.hours();
    const minutesNow = now.minutes();
    const isMinutesLaterThanNow = makeIsMinutesLaterThanNow(minutesNow);

    return times
        .map(time =>
            // Use only minutes within the current hour from now forward
            time.hour !== hoursNow ? time : {...time, minutes: time.minutes.filter(isMinutesLaterThanNow)},
        )
        .filter(time => time.hour >= hoursNow && time.minutes.length > 0);
};
