import {type TouchEvent, useState, useCallback, useMemo} from 'react';

interface PullInput {
    onPullDown: () => void;
    minPullDistance?: number;
    preventFromRefreshing?: boolean;
}

const DEFAULT_MIN_PULL_DISTANCE = 60;

interface PullOutput {
    onTouchStart: (e: TouchEvent) => void;
    onTouchMove: (e: TouchEvent) => void;
    onTouchEnd: (e: TouchEvent) => void;
    marginTop: number;
}

/**
 * Use this hook for a pull down gesture, e.g. to refresh a page
 *
 * @example
 * const {marginTop, onTouchStart, onTouchMove, onTouchEnd} = usePull({onPullDown: someAction});
 * <Box mt={marginTop} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} />
 *
 * The "actual" pull distance (verticalLengthOfOngoingTouch) is transformed into the marginTop we want to apply to the pulled element
 */

export const usePull = ({
    onPullDown,
    minPullDistance = DEFAULT_MIN_PULL_DISTANCE,
    preventFromRefreshing,
}: PullInput): PullOutput => {
    const [touchStart, setTouchStart] = useState<number>(0);
    const [verticalLengthOfOngoingTouch, setVerticalLengthOfOngoingTouch] = useState<number>(0);

    const marginTop = useMemo(() => {
        const distanceVert = touchStart - verticalLengthOfOngoingTouch;
        const isDownPull = distanceVert < -minPullDistance;

        if (isDownPull) {
            return verticalLengthOfOngoingTouch / 12;
        } else {
            return 0;
        }
    }, [verticalLengthOfOngoingTouch, minPullDistance, touchStart]);

    const onTouchStart = useCallback(
        (e: TouchEvent) => {
            setVerticalLengthOfOngoingTouch(0);
            if (e.targetTouches && e.targetTouches[0]) {
                setTouchStart(e.targetTouches[0].clientY);
            }
        },
        [setVerticalLengthOfOngoingTouch, setTouchStart],
    );

    const onTouchMove = useCallback(
        (e: TouchEvent) => {
            if (e.targetTouches && e.targetTouches[0]) {
                setVerticalLengthOfOngoingTouch(e.targetTouches[0].clientY);
            }
        },
        [setVerticalLengthOfOngoingTouch],
    );

    const onTouchEnd = useCallback(() => {
        if (!touchStart || !verticalLengthOfOngoingTouch) {
            return;
        }

        const distanceVert = touchStart - verticalLengthOfOngoingTouch;
        const isDownPull = distanceVert < -minPullDistance;

        if (isDownPull) {
            onPullDown();
            setVerticalLengthOfOngoingTouch(0);
        }
    }, [touchStart, verticalLengthOfOngoingTouch, minPullDistance, setVerticalLengthOfOngoingTouch, onPullDown]);

    if (preventFromRefreshing) {
        return {
            onTouchStart: () => {
                // noop
            },
            onTouchMove: () => {
                // noop
            },
            onTouchEnd: () => {
                // noop
            },
            marginTop: 0,
        };
    }

    return {
        onTouchStart,
        onTouchMove,
        onTouchEnd,
        marginTop,
    };
};
