import gql from 'graphql-tag';

import {StatPermissionBankHours} from '../fragment/stat-permission-bank-hours.fragment';
import {StatPermissionChangeLog} from '../fragment/stat-permission-change-log.fragment';
import {StatPermissionContractedHours} from '../fragment/stat-permission-contracted-hours.fragment';
import {StatPermissionWorkAvailability} from '../fragment/stat-permission-work-availability.fragment';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchContractedHoursPermissions = gql`
    query FetchContractedHoursPermissions($siteSetId: SiteSetId!, $employeeId: EmployeeId) {
        staffhours {
            management {
                contractedHours: contractedHoursV2(siteSetId: $siteSetId, employeeId: $employeeId) {
                    contractedHours {
                        ...StatPermissionContractedHours
                    }
                    workAvailability {
                        ...StatPermissionWorkAvailability
                    }
                    bankHours {
                        ...StatPermissionBankHours
                    }
                    changeLog {
                        ...StatPermissionChangeLog
                    }
                }
            }
        }
    }
    ${StatPermissionContractedHours}
    ${StatPermissionWorkAvailability}
    ${StatPermissionBankHours}
    ${StatPermissionChangeLog}
`;
