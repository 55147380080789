import {isImmutable} from 'immutable';

import {type CheckinsType} from 'web-app/react/components/person-button/person-button-base';

export function getPersonLastCheckin(checkins: CheckinsType) {
    if (isImmutable(checkins)) {
        return checkins?.get(0) ?? undefined;
    }
    return checkins?.[0] ?? undefined;
}
