import gql from 'graphql-tag';

export const StatPermissionPublishedShiftManagementType = gql`
    fragment StatPermissionPublishedShiftManagementType on PublishedShiftManagementType {
        canView
        canCreate
        canEdit
        canDelete
        canBulkDelete
    }
`;
