import {KindredTheme as KindredMFTheme} from 'modern-famly';

import {hexToRGBA} from 'web-app/util/hex-to-rgb';

import BaseTheme from './base';

const KindredChromeColor = '#1aa5b4';
const KindredPrimary = KindredChromeColor;
const KindredActive = hexToRGBA(KindredChromeColor, 0.8);
const KindredBackgroundHover = hexToRGBA(KindredChromeColor, 0.04);

const KindredTheme = BaseTheme.merge({
    title: 'Kindred',
    chromeColor: KindredChromeColor,
    primary: KindredPrimary,
    active: KindredActive,
    backgroundHover: KindredBackgroundHover,
    mf: KindredMFTheme,
});

export default KindredTheme;
