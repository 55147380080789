import {type ComponentType} from 'react';
import {connect, type MapStateToPropsParam} from 'react-redux';

// Not used. We removed a type parameter as part of updating to react-redux 8.x.x, where the types of connect changed.
// Leaving it in for now to avoid having to update all the files that use it.
// The plan is to remove all usages of `makeConnectState` (and `connectState`) and replace them with `useTypedSelector`.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type UnusedType = any;

export const makeConnectState = <RootState>() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return <InputProps, StateProps, UnusedType>(
        mapStateToProps: MapStateToPropsParam<StateProps, InputProps, RootState>,
    ) => {
        return (component: ComponentType<InputProps & StateProps>) =>
            connect<StateProps, {}, InputProps, RootState>(mapStateToProps)(
                // `any` was added as part of updating to react-redux 8.x.x, where the types of `connect` changed,
                // where we had trouble with getting `connect` to accept the type of `component`.
                // It's worth noting that the typing when using `makeConnectState` is still correct externally.
                component satisfies ComponentType<InputProps & StateProps> as any,
            );
    };
};
