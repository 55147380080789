import React from 'react';
import i18next from 'i18next';
import {List} from 'immutable';
import styled, {css} from 'styled-components';

import {reduxForm} from 'web-app/react/deprecation/redux-form/redux-form';
import {Modal, ModalTopBar, ModalBody} from 'web-app/react/components/modals/modals';
import ModalHeader from 'web-app/react/components/modals/components/new-modal-header';
import {Flex, Base} from 'web-app/react/components/layout/layout';
import Button, {ButtonSize, ButtonAppearance} from 'web-app/react/components/button-v2';
import {Body} from 'web-app/react/components/text/text';
import {Element, Form, FormField, CheckBox} from 'web-app/react/components/form-elements/containers';
import translate from 'signin-app/helpers/translate';
import {s0, s4, getSpacing, s2, s3, s1} from 'web-app/styleguide/spacing';
import {ButtonPosition} from 'web-app/react/components/form-elements/button/button';
import * as GroupsActions from 'signin-app/groups/actions';
import * as LocalStorage from 'signin-app/api/helpers/local-storage';
import {TrackingEvents} from 'web-app/util/famlytics/events';
import * as Famlytics from 'signin-app/util/famlytics';
import * as WhitelabelSelectors from 'signin-app/whitelabel/selectors';
import {useTypedSelector} from 'signin-app/components/hooks';

import * as Actions from './actions';
import * as Selectors from './selectors';

/*
|---------------------------------------------------------------------------------
| Displayed-groups modal
|---------------------------------------------------------------------------------
*/

type FormData = {[groupId: string]: boolean};
const updateHiddenGroups = (formValues: FormData) => {
    const hiddenGroupIds = List(Object.keys(formValues).filter(groupId => !formValues[groupId]));
    LocalStorage.saveHiddenGroupIds(hiddenGroupIds);
    GroupsActions.updateHiddenGroups.dispatch(hiddenGroupIds);
    Actions.hide.dispatch();
};

const DisplayedGroupsSettingsModal: React.FC = () => {
    const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false);

    const groups = useTypedSelector(Selectors.groupsForFormData);

    const initialValues = React.useMemo(
        () =>
            groups.reduce((reduction, group) => {
                reduction[group.id] = group.value;
                return reduction;
            }, {}),
        [groups],
    );

    return (
        <>
            <StyledModal onCloseModal={Actions.hide.dispatch} type="custom" fitToViewport>
                <ModalTopBar onCloseModal={Actions.hide.dispatch}>
                    <ModalHeader header={translate('settings')} />
                </ModalTopBar>
                <StyledModalBody>
                    <Base padding={[s0, s4]}>
                        <Body marginBottom={s1} emphasized>
                            {translate('settingsExplanation')}
                        </Body>

                        <FormWrapper onSubmit={updateHiddenGroups} initialValues={initialValues} groups={groups} />
                        <Flex justify="center" marginBottom={s3} marginTop={s3}>
                            <SwitchButton onClick={() => setIsConfirmationOpen(true)}>
                                {translate('switchToOldSignIn')}
                            </SwitchButton>
                        </Flex>
                    </Base>
                </StyledModalBody>
            </StyledModal>
            {isConfirmationOpen ? <SwitchAppConfirmationModal onCancel={() => setIsConfirmationOpen(false)} /> : null}
        </>
    );
};

const FormWrapper = reduxForm<FormData, {groups: ReturnType<typeof Selectors.groupsForFormData>}>({
    form: 'DISPLAYED_GROUPS_SETTINGS',
})(({handleSubmit, groups}) => {
    return (
        <Form
            onSubmit={handleSubmit}
            submitText={i18next.t('save')}
            submitButtonPosition={ButtonPosition.fill}
            buttonContainerCSS={buttonContainerCSS}
        >
            {groups.map(group => (
                <Element key={group.id}>
                    <FormField component={CheckBox} name={group.id} label={group.title} />
                </Element>
            ))}
        </Form>
    );
});

/*
|---------------------------------------------------------------------------------
| Switch-app-confirmation modal
|---------------------------------------------------------------------------------
*/

const SwitchAppConfirmationModal: React.FC<{onCancel: () => void}> = ({onCancel}) => {
    const whitelabelConfiguration = useTypedSelector(state => WhitelabelSelectors.whiteLabelConfiguration(state));

    return (
        <Modal onCloseModal={onCancel} type="medium" fitToViewport>
            <ModalTopBar onCloseModal={onCancel}>
                <ModalHeader
                    header={translate('switchToOldSignInTitle')}
                    subHeader={translate('switchToOldSignInBody', {
                        whitelabelName: whitelabelConfiguration.ProductName,
                    })}
                />
            </ModalTopBar>
            <ModalBody>
                <Flex align="center" justify="flex-end">
                    <Button
                        text={translate('switchConfirm')}
                        appearance={ButtonAppearance.Default}
                        size={ButtonSize.Small}
                        onClick={() => Famlytics.track(TrackingEvents.SIGNIN_SCREEN_V2_SWITCHED_TO_OLD_SIGNIN_APP)}
                        href="/v1"
                    />
                    <Button
                        text={translate('switchCancel')}
                        appearance={ButtonAppearance.Primary}
                        size={ButtonSize.Small}
                        onClick={onCancel}
                        marginLeft={s2}
                    />
                </Flex>
            </ModalBody>
        </Modal>
    );
};

/*
|---------------------------------------------------------------------------------
| Components
|---------------------------------------------------------------------------------
*/

const SwitchButton = styled(Body).attrs(() => ({
    as: 'button',
}))`
    color: ${props => props.theme.analogue2};
    border: none;
    background: transparent;
    padding: ${getSpacing([s2, s4])};
`;

const StyledModal = styled(Modal)`
    width: 432px;

    && {
        height: auto;
    }
`;

const StyledModalBody = styled(ModalBody)`
    overflow-y: auto;
    padding-bottom: 0;
`;

const buttonContainerCSS = css`
    position: sticky;
    bottom: 0;
    z-index: 3;
    background: ${props => props.theme.invertedText};
    margin: 0;
    margin-top: ${getSpacing(s4)};
    font-size: ${props => props.theme.fontConfiguration.sizes.Body};
    font-weight: ${props => props.theme.fontConfiguration.emphasized};
`;

export default DisplayedGroupsSettingsModal;
