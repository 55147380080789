import moment from 'moment-timezone';
import {LocaleUtils} from 'react-day-picker';

import memoize from 'web-app/util/memoize';

export function formatDay(day: number, locale: string = 'en') {
    return moment(day).locale(locale).format('ddd ll');
}

export function formatMonthTitle(date: string, locale: string = 'en') {
    return moment(date).locale(locale).format('MMMM YYYY');
}

export function formatMonthShortTitle(date: string, locale: string = 'en') {
    return moment(date).locale(locale).format('MMMM');
}

export function formatWeekdayShort(day: number, locale: string = 'en') {
    const localeData = moment.localeData(locale);
    return localeData.weekdaysMin()[day];
}

export function formatWeekdayLong(day: number, locale: string = 'en') {
    const localeData = moment.localeData(locale);
    return localeData.weekdays[day];
}

export function getFirstDayOfWeek(locale: string = 'en') {
    const localeData = moment.localeData(locale);
    return localeData.firstDayOfWeek();
}

export function getMonths(locale: string = 'en') {
    const months: string[] = [];
    let i = 0;
    while (i < 12) {
        months.push(moment().locale(locale).month(i).format('MMMM'));
        i += 1;
    }
    return months;
}

export default {
    /*
      the LocaleUtils in the new version of react-day-picker exports a new function called parseDate
      instead of implementing our own, we are just exporting it here
    */

    ...LocaleUtils,
    formatDay: memoize(formatDay),
    formatMonthTitle: memoize(formatMonthTitle),
    formatMonthShortTitle: memoize(formatMonthShortTitle),
    formatWeekdayShort: memoize(formatWeekdayShort),
    formatWeekdayLong: memoize(formatWeekdayLong),
    getFirstDayOfWeek: memoize(getFirstDayOfWeek),
    getMonths: memoize(getMonths),
};
