import {formValueSelector} from 'redux-form';
import {type List} from 'immutable';

import createSelector from 'web-app/util/custom-create-selector';
import {type RootState} from 'signin-app/redux/main-reducer';
import RoutesMap from 'signin-app/routes/routes-map';
import {isPinApp} from 'signin-app/login/selectors';
import {type Relation} from 'signin-app/child/reducer';
import * as GroupsSelectors from 'signin-app/groups/selectors';
import * as GroupsHelpers from 'signin-app/groups/helpers';

import {relations, makeChildSelector, requirePickupInfo, getPinChild, isChildCheckedIn} from '../selectors';
import * as Constants from './constants';

const formSelector = formValueSelector(Constants.NEW_PICKUP_PERSON_FORM);

export const formPickupPersonName = (state: RootState) => formSelector(state, 'pickupPersonName');

export const selectedPickupRelationId = (state: RootState): string => state.childCheckIn.selectedPickupRelationId;
export const selectedHours = (state: RootState): number | undefined => state.childCheckIn.selectedPickupHours;
export const selectedMinutes = (state: RootState): number | undefined => state.childCheckIn.selectedPickupMinutes;
export const isCheckingIn = (state: RootState): boolean => state.childCheckIn.isCheckingIn;
export const addingNewRelation = (state: RootState): boolean => state.childCheckIn.addingNewRelation;
const forceShowPickupScreen = (state: RootState): boolean => state.childCheckIn.forceShowPickupScreen;

export const pickupPersonName = createSelector(
    relations,
    selectedPickupRelationId,
    formPickupPersonName,
    (childRelations, selectedPickupId, formPersonName) => {
        let pickupName;

        if (selectedPickupId) {
            const selectedPickupPerson = (childRelations as List<Relation>).find(
                relation => relation.relationId === selectedPickupId,
            );
            pickupName = selectedPickupPerson ? selectedPickupPerson.name.firstName : '';
        } else {
            pickupName = formPersonName;
        }

        return pickupName;
    },
);

const entityChildSelector = makeChildSelector(RoutesMap.childCheckIn);

export const child = createSelector(isPinApp, getPinChild, entityChildSelector, (pinApp, pinChild, entityChild) => {
    if (pinApp) {
        return pinChild;
    }
    return entityChild;
});

export const canShowPickupTime = createSelector(
    formPickupPersonName,
    selectedPickupRelationId,
    (formPersonName, selectedPickupId) => Boolean(formPersonName || selectedPickupId),
);

export const canCheckIn = createSelector(
    selectedPickupRelationId,
    selectedHours,
    selectedMinutes,
    requirePickupInfo,
    formPickupPersonName,
    (relationId, hours, minutes, pickupRequired, pickupName): boolean => {
        if (!pickupRequired) {
            return true;
        }

        return (
            ((pickupName && pickupName.length) || relationId.length > 0) && hours !== undefined && minutes !== undefined
        );
    },
);

export const showPickupInfoScreen = createSelector(
    forceShowPickupScreen,
    requirePickupInfo,
    isChildCheckedIn,
    (forceShowPickupScreen, requirePickupInfo, isChildCheckedIn): boolean =>
        forceShowPickupScreen || requirePickupInfo || !isChildCheckedIn,
);

export const pickupTimeOptions = createSelector(
    GroupsSelectors.openingHours,
    GroupsSelectors.timezone,
    (openingHours, timezone) => {
        return GroupsHelpers.timesLaterThanNow(openingHours, timezone);
    },
);
