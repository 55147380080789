import {Record} from 'immutable';

import {makeReducer, match} from 'web-app/util/redux';
import {type StatusConfig} from 'signin-app/child/models';

import * as Actions from './actions';

export class ChildCheckOutState extends Record<{
    readonly isCheckingOut: boolean;
    readonly statusConfigs: StatusConfig[];
}>({
    isCheckingOut: false,
    statusConfigs: [],
}) {}

export default makeReducer<ChildCheckOutState>(
    [
        match(Actions.checkOutChild, state =>
            state.merge({
                isCheckingOut: true,
            }),
        ),
        match(Actions.checkOutChildSuccess, state =>
            state.merge({
                isCheckingOut: false,
            }),
        ),
        match(Actions.checkOutChildFailed, state =>
            state.merge({
                isCheckingOut: false,
            }),
        ),
        match(Actions.fetchStatusConfigsSuccess, (state, {payload}) =>
            state.merge({
                statusConfigs: payload.response,
            }),
        ),
    ],
    new ChildCheckOutState(),
);
