import i18next from 'i18next';

export const ValidationConstants = {
    REQUIRED: 'REQUIRED',
    MUST_MATCH: 'MUST_MATCH',
};

export const required = value => {
    if (value === null || value === undefined || value === '' || value === false) {
        return ValidationConstants.REQUIRED;
    }

    return undefined;
};

export const mustMatch = fieldName => (value, allValues) => {
    return allValues[fieldName] === value ? undefined : ValidationConstants.MUST_MATCH;
};

export const getErrorLabel = (error, props) => {
    switch (error) {
        case ValidationConstants.REQUIRED:
            if (props.requiredString) {
                return props.requiredString;
            }
            return i18next.t('required');
        case ValidationConstants.MUST_MATCH:
            if (props.mismatchString) {
                return props.mismatchString;
            }
            return i18next.t('fieldsMustMatch');
        default:
            return error;
    }
};
