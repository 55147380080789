import {gql} from '@apollo/client';

// unfortunately, graphql does not allow creating fragments out of nowhere, so we have to go
// with the subtype management as base :/
export const StatPermissionSubTypeManagement = gql`
    fragment StatPermissionSubTypeManagement on SubTypeManagementType {
        canView
        canCreate
        canEdit
        canDelete
        paidStatus {
            ...StatPermissionSubTypePaidManagement
        }
    }
`;
