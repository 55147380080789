import React from 'react';

import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';
import {useFetchStaffHoursHistoricalDownloadPermissions} from './queries/use-fetch-staff-hours-historical-download-permissions';

/**
 * Do not use this query directly!
 */
export function useStaffHoursHistoricalDownloadPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['staffHoursSettingsPage']['header']['exportHistoricalData'] & {loading: boolean} {
    const {data, loading} = useFetchStaffHoursHistoricalDownloadPermissions({
        variables: {siteSetId: payload.institutionSetId},
        skip: !payload.institutionSetId || !payload.shouldQuery,
    });
    const crud = useCrudPermissionAdapter(data?.staffhours.management.attendance.historicalCsvExportURL);

    return React.useMemo(() => ({loading, ...crud}), [crud, loading]);
}
