import {FamlyTheme as FamlyMFTheme} from 'modern-famly';

import {NavigationConfiguration} from 'web-app/styleguide/navigation-configuration';
import {NewColors} from 'web-app/styleguide/colors';
import {hexToRGBA} from 'web-app/util/hex-to-rgb';

import BaseTheme from './base';

export const FamlyPrimary = '#5C34A3';
export const FamlyChromeColor = '#4E169C';
export const FamlyActive = '#906CD0';
export const FamlyBackgroundHover = hexToRGBA(FamlyPrimary, 0.04);

const FamlyTheme = BaseTheme.merge({
    applicationBackground: '#FDFDFE',

    title: 'NewLook',
    chromeColor: FamlyChromeColor,
    primary: FamlyPrimary,
    active: FamlyActive,
    backgroundHover: FamlyBackgroundHover,

    navigationConfiguration: new NavigationConfiguration({
        contextualSidebarBackground: '#faf9fa',
        contextualSidebarLinkBackground: NewColors.Primary.t90,
        contextualSidebarLinkColor: NewColors.Neutral.s80,
        contextualSidebarLinkColorActive: NewColors.Analogue1.base,
    }),

    mf: FamlyMFTheme,
});

export default FamlyTheme;
