import {handleActions} from 'redux-actions';
import {List} from 'immutable';

import {type EntityState, entityStateFactory} from 'web-app/react/entities/factory/reducer-factory';
import {type IChild} from 'signin-app/entities/children/model';
import {checkInChildSuccess} from 'signin-app/child/check-in/actions';
import {
    checkOutChildSuccess,
    applyChildStatusSuccess,
    removeChildStatusSuccess,
} from 'signin-app/child/check-out/actions';
import {type CheckIn} from 'web-app/react/entities/common/checkin';
import {type StatusRegistration} from 'signin-app/child/models';

interface CheckInPayload {
    response: CheckIn;
    pickupName: string;
}
type CheckInAction = {payload?: CheckInPayload};

interface UpdateStatusPayload {
    response: StatusRegistration[];
    status: any;
}
type UpdateStatusAction = {
    payload?: UpdateStatusPayload;
};

const EntityStateFactory = entityStateFactory<IChild>();
const initialState = new EntityStateFactory();

const updateChildEntityMap = (
    state: EntityState<IChild>,
    payload: CheckInPayload,
    childId: string,
    checkedIn: boolean,
) => {
    const child = state.entityMap.get(childId);

    if (!child) {
        return state;
    }

    const childWithUpdatedCheckins = child.update('checkins', checkins => List([payload.response, ...checkins]));
    const updatedChild = childWithUpdatedCheckins.merge({
        checkedIn,
        ...payload.response,
        groupId: child.groupId,
        lastCheckIn: payload.response,
        pickupName: payload.pickupName,
    });
    const updatedEntityMap = state.entityMap.merge({[updatedChild.id]: updatedChild});

    return state.merge({
        entityMap: updatedEntityMap,
    });
};

export default handleActions<EntityState<IChild>, CheckInPayload & UpdateStatusPayload>(
    {
        [checkInChildSuccess.type]: (state, {payload}: CheckInAction) => {
            if (!payload) {
                return state;
            }
            return updateChildEntityMap(state, payload, payload.response.childId, true);
        },
        [checkOutChildSuccess.type]: (state, {payload}: CheckInAction) => {
            if (!payload) {
                return state;
            }
            return updateChildEntityMap(state, payload, payload.response.childId, false);
        },
        [applyChildStatusSuccess.type]: (state, {payload}: UpdateStatusAction) => {
            if (!payload) {
                return state;
            }

            const {response, status} = payload;

            const statusRegistration = response[0];
            const {childId} = statusRegistration;
            const child = state.entityMap.get(childId);

            const updatedChild = child!.update('statusRegistrations', registrations => {
                return registrations.concat({
                    statusConfig: status,
                });
            });
            const newEntityMap = state.entityMap.merge({[updatedChild.id]: updatedChild});

            return state.merge({
                entityMap: newEntityMap,
            });
        },
        [removeChildStatusSuccess.type]: (state, {payload}: UpdateStatusAction) => {
            if (!payload) {
                return state;
            }

            const {response, status} = payload;
            const statusRegistration = response[0];
            const {childId} = statusRegistration;
            const child = state.entityMap.get(childId);

            const updatedChild = child!.update('statusRegistrations', registrations => {
                const removedStatusIndex = registrations.findIndex(
                    reg => reg.statusConfig.statusConfigId === status.statusConfigId,
                );
                return registrations.delete(removedStatusIndex);
            });
            const newEntityMap = state.entityMap.merge({[updatedChild.id]: updatedChild});

            return state.merge({
                entityMap: newEntityMap,
            });
        },
    },
    initialState,
);
