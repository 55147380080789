import React from 'react';
import {type RouteComponentProps} from 'react-router-dom';
import {Link} from 'react-router-dom';

import {type GoHomeWithState} from 'signin-app/go-home-with/reducer';
import {Card, CardsContainer} from 'signin-app/groups/group-item';
import {useTypedSelector} from 'signin-app/components/hooks';

import * as Selectors from './selectors';

interface StateProps {
    groups: GoHomeWithState['groups'];
}

const Groups: React.FC<RouteComponentProps & {groups: StateProps['groups']}> = props => {
    const {match} = props;
    const groups = useTypedSelector(Selectors.groups);

    return (
        <CardsContainer>
            {groups.map(group => (
                <Link to={`${match.url}/${group.groupId}`}>
                    <Card key={group.groupId} image={group.image} displayTitle={group.title} />
                </Link>
            ))}
        </CardsContainer>
    );
};

export default Groups;
