import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const Icon = props => {
    const {icon, alt, className, ...rest} = props;

    if (typeof icon === 'string') {
        return <img className={className} src={icon} alt={alt} {...rest} />;
    }

    return (
        <icon.type
            {...icon.props}
            className={classNames(icon.props ? icon.props.className : null, className)}
            {...rest}
        />
    );
};

export const IconPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.node]);

Icon.propTypes = {
    icon: IconPropType.isRequired,
    alt: PropTypes.string,
    className: PropTypes.string,
};

export default Icon;
