import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

import {getSpacing, s1, s2} from 'web-app/styleguide/spacing';
import FormField from 'web-app/react/components/form-elements/containers/form-field/form-field';
import {Caption} from 'web-app/react/components/text/text';
import {Colors} from 'web-app/styleguide';
import {Base} from 'web-app/react/components/layout/layout';

const ErrorMessage = ({message, errorClassName}) => (
    <Base marginTop={s1}>
        <Caption color={Colors.Accent3} lineHeight="1.1" marginTop="2px" className={errorClassName}>
            {message}
        </Caption>
    </Base>
);

ErrorMessage.propTypes = {
    message: PropTypes.string,
    errorClassName: PropTypes.string,
};

const StyledFormField = styled(({hasError, checkbox, ...rest}) => <FormField {...rest} />)`
    ${props =>
        props.hasError
            ? css`
                  &,
                  &:focus,
                  &:hover {
                      border-color: ${props => props.theme.accent3};
                  }
              `
            : ''}

    ${props =>
        props.checkbox
            ? css`
                  margin-top: ${getSpacing(s2)};
              `
            : ''}
`;

const ErrorableFormField = ({className, inputClassName, errors, errorClassName, ...props}) => {
    const {type} = props;

    return (
        <div className={className}>
            <StyledFormField
                hasError={Boolean(errors.length)}
                checkbox={type === 'checkbox'}
                key="field"
                {...props}
                className={inputClassName}
            />
            {errors.map((e, i) => (
                <ErrorMessage errorClassName={errorClassName} key={i} message={e} />
            ))}
        </div>
    );
};
ErrorableFormField.propTypes = {
    errors: PropTypes.array,
    errorClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
};
ErrorableFormField.defaultProps = {
    errors: [],
};

export default ErrorableFormField;
