import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styled from 'styled-components';

import {Subheader} from 'web-app/react/components/text/text';
import formPrepare from 'web-app/react/components/higher-order/form-prepare';
import {compose} from 'web-app/util/typescript';
import deriveProps from 'web-app/react/components/higher-order/derive-props';

import * as Selectors from '../selectors';

class CaptionElement extends React.Component {
    static propTypes = {
        date: PropTypes.instanceOf(Date),
        localeUtils: PropTypes.object,
        locale: PropTypes.string,
        onClick: PropTypes.func,
        classNames: PropTypes.shape({
            caption: PropTypes.string.isRequired,
        }).isRequired,
        withYearPicker: PropTypes.bool,
        handleYearChange: PropTypes.func,
        year: PropTypes.string,
        noTab: PropTypes.bool,
        yearOptions: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.number,
                label: PropTypes.number,
            }),
        ),
    };

    shouldComponentUpdate(nextProps) {
        return (
            nextProps.locale !== this.props.locale ||
            nextProps.classNames !== this.props.classNames ||
            nextProps.date.getMonth() !== this.props.date.getMonth() ||
            nextProps.date.getFullYear() !== this.props.date.getFullYear()
        );
    }

    render() {
        const {classNames, date, locale, localeUtils, onClick, withYearPicker, yearOptions} = this.props;

        const captionName = withYearPicker
            ? localeUtils.formatMonthShortTitle(date, locale)
            : localeUtils.formatMonthTitle(date, locale);

        return (
            <div className={classNames.caption} role="heading">
                <Subheader emphasized title={captionName} onClick={onClick} data-e2e-id="daypicker-caption-header">
                    {captionName}
                </Subheader>
                {withYearPicker ? (
                    <StyledYearSelect
                        tabIndex={-1}
                        onChange={this.props.handleYearChange}
                        name="year"
                        value={moment(date).format('YYYY')}
                    >
                        {yearOptions.map(opt => (
                            <option key={opt.value} value={opt.value}>
                                {opt.label}
                            </option>
                        ))}
                    </StyledYearSelect>
                ) : null}
            </div>
        );
    }
}

export default compose(
    deriveProps(() => ({
        yearPickerOptionsSelector: Selectors.makeYearPickerOptionsSelector(),
    })),
)
    .with(
        formPrepare(props => ({
            yearOptions: props.yearPickerOptionsSelector(props),
        })),
    )
    .apply(CaptionElement);

const StyledYearSelect = styled('select')`
    width: auto;
    cursor: pointer;
    &,
    &:active,
    &:focus {
        border: none;
    }
`;
