import {useApolloClient} from '@apollo/client';

import {createSubscribableMutator} from 'web-app/react/hooks/create-subscribable-mutator';

import {UpdateCustomRegistrationFormPublishedState} from '../../graphql/mutations/update-custom-registration-form-published-state.query';
import {
    type SetPublishedMutation,
    type SetPublishedMutationVariables,
} from '../../graphql/mutations/__generated__/update-custom-registration-form-published-state.query.api-types';
import {GetCustomFormDescription} from '../../graphql/queries/get-custom-form-description.query';
import {
    type GetCustomFormDescriptionQuery,
    type GetCustomFormDescriptionQueryVariables,
} from '../../graphql/queries/__generated__/get-custom-form-description.query.api-types';

export const {
    useSubscribableMutation: useSubscribableUpdateCustomRegistrationFormPublishedStateMutation,
    useSubscribeMutationResult: useSubscribableUpdateCustomRegistrationFormPublishedStateMutationResult,
} = createSubscribableMutator<SetPublishedMutation, SetPublishedMutationVariables>(
    UpdateCustomRegistrationFormPublishedState,
);

export const useUpdateCustomRegistrationFormPublishState = ({
    siteSetId,
    onClose,
}: {
    siteSetId: string;
    onClose: VoidFunction;
}) => {
    const apolloClient = useApolloClient();

    const [updateCustomRegistrationFormPublishedStateMutation] =
        useSubscribableUpdateCustomRegistrationFormPublishedStateMutation({
            onCompleted: data => {
                apolloClient.writeQuery<GetCustomFormDescriptionQuery, GetCustomFormDescriptionQueryVariables>({
                    query: GetCustomFormDescription,
                    variables: {
                        formId: data.attendance.customRegistrationForm.setPublished.formId,
                        siteSetId,
                    },
                    data: {
                        attendance: {
                            __typename: 'AttendanceQueries',
                            customRegistrationForm: {
                                __typename: 'CustomRegistrationFormQueries',
                                description: data.attendance.customRegistrationForm.setPublished,
                            },
                        },
                    },
                });
                onClose();
            },
        });

    return {
        updateCustomRegistrationFormPublishedStateMutation,
    };
};
