import React from 'react';

/**
 * Helper hook for managing bulk actions in a list.
 *
 * @param data Array of items that can be toggled. They must all be mappable to a unique id.
 * @param toRowId Function that maps an item to a unique id.
 *
 * @returns Object containing the following properties:
 * - `selectedRows`: Array of ids of selected rows.
 * - `toggleSelected`: Function that toggles the selection of a row.
 * - `toggleAll`: Function that toggles the selection of all rows.
 * - `isRowSelected`: Function that returns whether a row is selected.
 * - `handleSelectAll`: Function that handles the select all checkbox.
 * - `isAllSelected`: Boolean indicating whether all rows are selected.
 * - `handleDeselectAll`: Function that deselects all rows (convenient for the deselect all button in the bulk action bar).
 */
export function useBulkActions<T>({data, toRowId}: {data: T[]; toRowId: (t: T) => string}) {
    const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
    const isRowSelected = React.useCallback((id: string) => selectedRows.includes(id), [selectedRows]);

    const toggleSelected = React.useCallback((id: string, checked: boolean) => {
        if (checked) {
            setSelectedRows(prev => [...prev, id]);
        } else {
            setSelectedRows(prev => prev.filter(rowId => rowId !== id));
        }
    }, []);

    const toggleAll = React.useCallback(
        (checked: boolean) => {
            if (checked) {
                setSelectedRows(data.map(toRowId));
            } else {
                setSelectedRows([]);
            }
        },
        [data, toRowId],
    );

    const isAllSelected = data.length > 0 && selectedRows.length === data.length;

    const handleSelectAll = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            toggleAll(checked);
        },
        [toggleAll],
    );

    const handleDeselectAll = React.useCallback(() => {
        toggleAll(false);
    }, [toggleAll]);

    return {
        selectedRows,
        toggleSelected,
        toggleAll,
        isRowSelected,
        handleSelectAll,
        isAllSelected,
        handleDeselectAll,
    };
}
