import React from 'react';

/**
 * A hook that accepts a callback function and a delay time (in milliseconds), then repeats the
 * execution of the given function by the defined milliseconds.
 */
export function useInterval(cb: VoidFunction, delayMs: number) {
    const intervalRef = React.useRef<ReturnType<typeof setInterval>>();
    const savedCallback = React.useRef<VoidFunction>();

    React.useEffect(() => {
        savedCallback.current = cb;
    }, [cb]);

    React.useEffect(() => {
        if (!intervalRef.current && savedCallback.current) {
            intervalRef.current = setInterval(savedCallback.current, delayMs);
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [delayMs]);
}
