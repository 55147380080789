import PropTypes from 'prop-types';
import React from 'react';

import {NewLookTheme} from 'web-app/styleguide/themes';

import {G, SVG} from '../svg';
import {type IconComponent} from './icon-component';

/**
 * @deprecated use MF icons
 */
export const Backspace: IconComponent<any> = ({fill, ...rest}) => (
    <SVG viewBox="0 0 24 24" {...rest}>
        <G fill={fill}>
            <path d="M17.9596999,6.07957825 L9.99737683,6.07957825 C8.98934674,6.07957825 7.74085448,6.71661563 7.15482751,7.52968689 L5.07666119,10.413327 C4.56548006,11.1221988 4.13551461,11.7189773 4.12197866,11.7387121 C4.02563455,11.8768552 4.02483832,12.1270917 4.12436736,12.2628667 C4.1426807,12.2889166 4.56627629,12.8762224 5.07029134,13.576411 L7.15562374,16.4711025 C7.74085448,17.2833844 8.9885505,17.9204217 9.99737683,17.9204217 L17.9596999,17.9204217 C19.0577042,17.9204217 19.9502806,17.0347267 19.9502806,15.9469478 L19.9502806,8.05305217 C19.9502806,6.96527335 19.0577042,6.07957825 17.9596999,6.07957825 Z M16.1339392,13.8100703 C16.445266,14.1187216 16.445266,14.6176158 16.1339392,14.9262671 C15.9786739,15.0801981 15.7748384,15.1575583 15.571003,15.1575583 C15.3671675,15.1575583 15.163332,15.0801981 15.0080667,14.9262671 L13.182306,13.1161968 L11.3565454,14.9262671 C11.2012801,15.0801981 10.9974446,15.1575583 10.7936091,15.1575583 C10.5897737,15.1575583 10.3859382,15.0801981 10.2306729,14.9262671 C9.91934607,14.6176158 9.91934607,14.1187216 10.2306729,13.8100703 L12.0564336,12 L10.2306729,10.1899297 C9.91934607,9.8812784 9.91934607,9.3823842 10.2306729,9.07373288 C10.5419997,8.76508156 11.0452185,8.76508156 11.3565454,9.07373288 L13.182306,10.8838032 L15.0080667,9.07373288 C15.3193936,8.76508156 15.8226124,8.76508156 16.1339392,9.07373288 C16.445266,9.3823842 16.445266,9.8812784 16.1339392,10.1899297 L14.3081785,12 L16.1339392,13.8100703 Z" />
        </G>
    </SVG>
);
Backspace.propTypes = {
    fill: PropTypes.string,
};
Backspace.defaultProps = {
    fill: NewLookTheme.text,
};
Backspace.isPrivate = true;
export default Backspace;
