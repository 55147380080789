import React from 'react';
import InputAdornment, {type InputAdornmentProps} from '@mui/material/InputAdornment';

import {Text} from 'modern-famly/components/data-display';

import {type TextFieldProps} from './text-field';

export type TextAdornmentProps = {
    /**
     * The position this adornment should appear relative to the `Input`.
     */
    position: InputAdornmentProps['position'];

    /**
     * The text to display
     */
    text: string;

    /**
     * The size of the text field that the adornment sits in
     */
    size: TextFieldProps['size'];
};

export const TextAdornment = ({position, text, size}: TextAdornmentProps) => {
    return (
        <InputAdornment position={position}>
            <Text variant={size === 'regular' ? 'body' : 'body-small'}>{text}</Text>
        </InputAdornment>
    );
};
