import React from 'react';

import {G, SVG} from '../svg';
import {defaultPropTypes} from './default-prop-types';
import {type IconComponent} from './icon-component';

export const CloseIcon: IconComponent<any> = props => {
    const {appearance, fill, ...rest} = props;
    return (
        <SVG {...rest} viewBox="0 0 24 24">
            <G appearance={appearance} color={fill}>
                <path d="M20.749 4.707l-1.415-1.414-7.292 7.293-7.293-7.293-1.415 1.414L10.627 12l-7.293 7.293 1.415 1.414 7.293-7.293 7.292 7.293 1.415-1.414L13.456 12z" />
            </G>
        </SVG>
    );
};
CloseIcon.propTypes = defaultPropTypes;
CloseIcon.isPrivate = true;

export default CloseIcon;
