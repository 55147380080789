/**
 * If the requestUUID and responseUUID don't match, it means that we are receiving a cached response (and maybe someone
 * else's data).
 * @param requestUUID
 * @param responseUUID
 * @param identifier
 * @param onError
 * @returns {boolean} Whether or not the request is verified
 */
export const verifyRequestUUID = (requestUUID, responseUUID, identifier, onError) => {
    const match = !responseUUID || (requestUUID && responseUUID === requestUUID);

    if (!match) {
        onError(requestUUID, responseUUID, identifier);
    }

    return match;
};
