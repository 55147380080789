import React from 'react';
import OutlinedInput, {
    outlinedInputClasses,
    type OutlinedInputProps as MuiOutlinedInputProps,
} from '@mui/material/OutlinedInput';
import {inputAdornmentClasses} from '@mui/material/InputAdornment';
import {type Components, type Theme} from '@mui/material/styles';
import {buttonBaseClasses} from '@mui/material/ButtonBase';

import {useDataProps} from 'modern-famly/components/util';
import {extractSystemStyles, type AlignmentProps} from 'modern-famly/system/system-props';

export type TextFieldProps = {
    /**
     * If `true`, the `input` element is focused during the first mount.
     */
    autoFocus?: MuiOutlinedInputProps['autoFocus'];

    /**
     * If `true`, the component is disabled.
     * The prop defaults to the value (`false`) inherited from the parent FormControl component.
     */
    disabled?: MuiOutlinedInputProps['disabled'];

    /**
     * The short hint displayed in the `input` before the user enters a value.
     */
    placeholder?: MuiOutlinedInputProps['placeholder'];

    /**
     * The size of the component.
     */
    size?: MuiOutlinedInputProps['size'];

    /**
     * If `true`, the `input` will take up the full width of its container.
     * @default false
     */
    fullWidth?: MuiOutlinedInputProps['fullWidth'];

    /**
     * If `true`, a [TextareaAutosize](/material-ui/react-textarea-autosize/) element is rendered.
     * @default false
     */
    multiline?: MuiOutlinedInputProps['multiline'];

    /**
     * If `true`, the `input` will indicate an error.
     * The prop defaults to the value (`false`) inherited from the parent FormControl component.
     */
    error?: MuiOutlinedInputProps['error'];

    /**
     * Start `InputAdornment` for this component. Will be ignored if `multiline` is `true`.
     */
    startAdornment?: MuiOutlinedInputProps['startAdornment'];

    /**
     * End `InputAdornment` for this component. Will be ignored if `multiline` is `true`.
     */
    endAdornment?: MuiOutlinedInputProps['endAdornment'];

    /**
     * Minimum number of rows to display when multiline option is set to true. Minimum and default value is 3.
     */
    minRows?: Exclude<MuiOutlinedInputProps['minRows'], string>;

    /**
     * Maximum number of rows to display when multiline option is set to true. Minimum value is 3.
     */
    maxRows?: Exclude<MuiOutlinedInputProps['maxRows'], string>;

    /**
     * The change handler called when the component is updated.
     */
    onChange?: MuiOutlinedInputProps['onChange'];

    /**
     * The handler called when the component is focused.
     */
    onFocus?: MuiOutlinedInputProps['onFocus'];

    /**
     * Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types).
     * @default 'text'
     */
    type?: MuiOutlinedInputProps['type'];

    /**
     * The value that the input component holds
     */
    value?: string;

    /**
     * Name attribute of the input element.
     */
    name?: string;

    /**
     * ID attribute of the input element.
     */
    id?: string;

    /**
     * If true, the input element is required.
     * The prop defaults to the value (false) inherited from the parent FormControl component.
     */
    required?: boolean;

    /**
     * Callback fired when the input is blurred.
     * Notice that the first argument (event) might be undefined.
     */
    onBlur?: MuiOutlinedInputProps['onBlur'];

    /**
     * Callback fired when the field is focused and a key is pressed.
     */
    onKeyDown?: MuiOutlinedInputProps['onKeyDown'];

    /**
     * Callback fired when the field is focused and a key is let go.
     */
    onKeyUp?: MuiOutlinedInputProps['onKeyUp'];

    /**
     * HTML Attributes applied to the input element.
     */
    inputProps?: {minLength?: number; maxLength?: number};
} & AlignmentProps;

export const TextField = React.forwardRef<typeof OutlinedInput, TextFieldProps>(
    (
        {
            disabled,
            placeholder,
            size,
            multiline,
            fullWidth,
            error,
            startAdornment,
            endAdornment,
            onChange,
            onFocus,
            value,
            type,
            minRows,
            maxRows,
            autoFocus,
            name,
            id,
            required,
            onBlur,
            onKeyDown,
            onKeyUp,
            inputProps,
            ...props
        },
        ref,
    ) => {
        const _inputProps = React.useMemo(() => {
            return {...inputProps, 'data-e2e-class': 'text-field-input'};
        }, [inputProps]);

        const dataProps = useDataProps(props);

        const _minRows = Math.max(minRows ?? 0, 3);
        const _maxRows = maxRows ? Math.max(3, maxRows) : undefined;

        const {sx: systemSx} = extractSystemStyles(props);

        return (
            <OutlinedInput
                inputProps={_inputProps}
                placeholder={placeholder}
                size={size}
                disabled={disabled}
                multiline={multiline}
                minRows={_minRows}
                maxRows={_maxRows}
                fullWidth={fullWidth}
                error={error}
                startAdornment={multiline ? undefined : startAdornment}
                endAdornment={multiline ? undefined : endAdornment}
                onChange={onChange}
                onFocus={onFocus}
                value={value}
                inputRef={ref}
                type={type}
                autoFocus={autoFocus}
                name={name}
                id={id}
                required={required}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                sx={systemSx}
                {...dataProps}
            />
        );
    },
);

/*
|------------------------------------------------------------------------------
| MUI Theming
|------------------------------------------------------------------------------
*/
declare module '@mui/material/InputBase' {
    interface InputBasePropsSizeOverrides {
        medium: false;
        small: false;

        regular: true;
        compact: true;
    }
}

export const OutlinedInputThemeConfiguration: Components<Theme>['MuiOutlinedInput'] = {
    defaultProps: {
        size: 'regular',
    },
    styleOverrides: {
        root: ({theme, ownerState}) => ({
            // In MUI's internal "OutlinedInput" component the border is not added directly
            // to the root element, because they support labels that "overlap" the border:
            //
            //       --- Label here --------------------------------------
            //       |                                                   |
            //       |  Value of input here                              |
            //       |                                                   |
            //       -----------------------------------------------------
            //
            // This is called the "notched input". However we don't support notched input in Modern Famly
            // and thus it's easier to apply the border directly to the root element and avoid unnecessary
            // deeply nested styling.
            [`& .${outlinedInputClasses.notchedOutline}`]: {
                display: 'none',
            },

            borderRadius: '8px',
            border: `1px solid ${theme.modernFamlyTheme.colorPalette.n200}`,
            backgroundColor: theme.modernFamlyTheme.colorPalette.n0,
            overflow: 'hidden',

            '&:hover': {
                borderColor: theme.modernFamlyTheme.colorPalette.p300,
            },
            '&.Mui-focused': {
                borderColor: theme.modernFamlyTheme.colorPalette.p400,
            },
            '&.Mui-error': {
                borderColor: theme.modernFamlyTheme.colorPalette.r400,
            },
            '&.Mui-disabled': {
                backgroundColor: theme.modernFamlyTheme.colorPalette.n75,
                borderColor: theme.modernFamlyTheme.colorPalette.n75,
            },

            // Following MUI's own styling scheme we add padding to the root element
            // when `multiline` is truthy. Padding will be added to the input element
            // when falsy.
            ...(ownerState.multiline && {
                padding:
                    ownerState.size === 'regular'
                        ? theme.modernFamlyTheme.spacing('13px', 3)
                        : theme.modernFamlyTheme.spacing(2, 3),
            }),
        }),
        input: ({theme, ownerState}) => ({
            color: theme.modernFamlyTheme.colorPalette.n400,
            height: 'initial',

            border: 'none !important',
            backgroundColor: 'inherit !important',

            fontSize: theme.typography[ownerState.size === 'compact' ? 'body-small' : 'body'].fontSize,
            lineHeight: theme.typography[ownerState.size === 'compact' ? 'body-small' : 'body'].lineHeight,

            '&.Mui-disabled': {
                WebkitTextFillColor: theme.modernFamlyTheme.colorPalette.n300,
            },

            '::placeholder': {
                color: theme.modernFamlyTheme.colorPalette.n300,
            },

            // Following MUI's own styling scheme we add padding to the input element
            // when `multiline` is falsy. Padding will be added to the root element
            // when truthy.
            ...(!ownerState.multiline && {
                padding:
                    ownerState.size === 'regular'
                        ? theme.modernFamlyTheme.spacing('13px', 3)
                        : theme.modernFamlyTheme.spacing(2, 3),
            }),
        }),
        adornedStart: ({theme}) => ({
            paddingLeft: theme.modernFamlyTheme.spacing(2),

            [`&.Mui-disabled .${inputAdornmentClasses.positionStart}`]: {
                color: theme.modernFamlyTheme.colorPalette.n300,
            },

            // This makes sure that the buttons used as adornment have the correct text color when the field is disabled.
            // This is the case for the NumericInput component which has button adornments
            [`&.Mui-disabled .${buttonBaseClasses.root}`]: {
                color: theme.modernFamlyTheme.colorPalette.n300,
            },

            [`& .${inputAdornmentClasses.positionStart}`]: {
                marginRight: 0,
            },

            [`& .${outlinedInputClasses.input}`]: {
                paddingLeft: theme.modernFamlyTheme.spacing(1),
            },
        }),
        adornedEnd: ({theme}) => ({
            paddingRight: theme.modernFamlyTheme.spacing(2),

            [`&.Mui-disabled .${inputAdornmentClasses.positionEnd}`]: {
                color: theme.modernFamlyTheme.colorPalette.n300,
            },

            // This makes sure that the buttons used as adornment have the correct text color when the field is disabled.
            // This is the case for the NumericInput component which has button adornments
            [`&.Mui-disabled .${buttonBaseClasses.root}`]: {
                color: theme.modernFamlyTheme.colorPalette.n300,
            },

            [`& .${inputAdornmentClasses.positionEnd}`]: {
                marginLeft: 0,
            },

            [`& .${outlinedInputClasses.input}`]: {
                paddingRight: theme.modernFamlyTheme.spacing(1),
            },
        }),
    },
};
