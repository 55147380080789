import React from 'react';
import {Route, Switch} from 'react-router-dom';

import {compose} from 'web-app/util/typescript';
import {connectState} from 'signin-app/helpers/connect-state';
import PrivateRoute from 'signin-app/components/private-route/private-route';
import Pin from 'signin-app/pin/pin';
import {QrScreen} from 'signin-app/qr/qr-screen';
import GoHomeWith from 'signin-app/go-home-with/go-home-with';

import Login from '../login/login';
import Groups from '../groups/groups';
import {Group} from '../group/group';
import {ChildCheckIn} from '../child/check-in/check-in';
import {ChildCheckOut} from '../child/check-out/check-out';
import {StaffGroup} from '../staff-group/staff-group';
import {EmployeeCheckIn} from '../employee/check-in/check-in';
import {EmployeeCheckOut} from '../employee/check-out/check-out';
import RoutesMap from './routes-map';

interface StateProps {
    location: any;
}

const Routes: React.FC<StateProps> = () => {
    return (
        <Switch>
            <Route exact path="/" component={Login} />
            <Route path={RoutesMap.pin} component={Pin} />
            <PrivateRoute path={RoutesMap.qr} component={QrScreen} />
            <PrivateRoute shouldPrefetch path={RoutesMap.overview} component={Groups} />
            <PrivateRoute shouldPrefetch path={RoutesMap.nurseryGroup} component={Group} />
            <PrivateRoute shouldPrefetch path={RoutesMap.childCheckIn} component={ChildCheckIn} />
            <PrivateRoute shouldPrefetch path={RoutesMap.childCheckOut} component={ChildCheckOut} />
            <PrivateRoute shouldPrefetch path={RoutesMap.staffGroup} component={StaffGroup} />
            <PrivateRoute shouldPrefetch path={RoutesMap.employeeCheckIn} component={EmployeeCheckIn} />
            <PrivateRoute shouldPrefetch path={RoutesMap.employeeCheckOut} component={EmployeeCheckOut} />
            <PrivateRoute path={RoutesMap.goHomeWith} component={GoHomeWith} />
        </Switch>
    );
};

export default compose(
    connectState<{}, StateProps, React.ComponentType<StateProps>>(state => ({
        // used only to force render of component for
        // the current route when location changes
        location: state.router.location,
    })),
).apply(Routes);
