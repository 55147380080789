import {type QueryHookOptions} from '@apollo/client';

import {useSubscribableToggleFeatureMutationResult} from 'web-app/react/settings/hooks/use-subscribable-toggle-feature-mutation';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';
import {FeatureType} from 'web-app/util/graphql/api-types';

import {StatFeatures} from './stat-features.query';
import {type StatFeaturesQuery, type StatFeaturesQueryVariables} from './__generated__/stat-features.query.api-types';

export function useStatFeaturesQuery(
    options: QueryHookOptions<StatFeaturesQuery, StatFeaturesQueryVariables>,
): ImmerWrappedQueryResult<StatFeaturesQuery, StatFeaturesQueryVariables> {
    const queryResult = useStatQuery<StatFeaturesQuery, StatFeaturesQueryVariables>(StatFeatures, options);

    useSubscribableToggleFeatureMutationResult(result => {
        queryResult.updateQuery(cached => {
            result?.institutions.managedFeatures.setFeatures.forEach(feature => {
                switch (feature.featureId) {
                    case FeatureType.SETTINGS_ENABLE_EMPLOYEE_LEAVE_REQUEST:
                        cached.staffhours.featureSettings.leaveRequest.enabled = feature.enabled;
                        break;
                    case FeatureType.ENABLE_PART_DAY_LEAVE:
                        cached.staffhours.featureSettings.partDayLeave.enabled = feature.enabled;
                        break;
                    case FeatureType.ENABLE_LEAVE_SUBTYPES:
                        cached.staffhours.featureSettings.subtype.enabled = feature.enabled;
                        break;
                    case FeatureType.LEAVE_SUBTYPES_REQUIRED:
                        cached.staffhours.featureSettings.subtype.required = feature.enabled;
                        break;
                    default:
                    // nothing to be done
                }
            });

            return cached;
        });
    });

    return queryResult;
}
