import React from 'react';

import {getPersonLastCheckin} from './get-person-last-checkin';

export function useGetPersonCurrentRoomId(checkins?: ImmutableListOrArray<{groupId: string}>): string | undefined {
    return React.useMemo(() => getPersonCurrentRoomId(checkins), [checkins]);
}

export function getPersonCurrentRoomId(checkins?: ImmutableListOrArray<{groupId: string}>): string | undefined {
    const lastCheckin = getPersonLastCheckin(checkins);
    return lastCheckin?.groupId;
}
