import React from 'react';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {type StatPermissions} from './stat-permissions';
import {useLeaveSettingsRangePermissions} from './use-leave-settings-range-permissions';
import {useLeaveSettingsSubtypePaidPermissions} from './use-leave-settings-subtype-paid-permissions';

export function useStaffLeaveSettingsPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['staffLeaveSettings'] & {loading: boolean} {
    const {loading: loadingLeaveSettingsRange, ...leaveSettingsRange} = useLeaveSettingsRangePermissions(payload);
    const {loading: loadingSubtypes, ...leaveSettingsPaidSubtypes} = useLeaveSettingsSubtypePaidPermissions(payload);

    return React.useMemo(
        () => ({
            loading: loadingLeaveSettingsRange || loadingSubtypes,
            leave: {
                ranges: leaveSettingsRange,
                subtypes: {
                    paid: leaveSettingsPaidSubtypes,
                },
            },
        }),
        [loadingLeaveSettingsRange, loadingSubtypes, leaveSettingsRange, leaveSettingsPaidSubtypes],
    );
}
