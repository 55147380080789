import {
    type ActionsObservable,
    // eslint-disable-next-line no-restricted-imports
    combineEpics as combineEpicsReduxObservable,
    type Epic,
    type StateObservable,
} from 'redux-observable';
import {type Observable, type ObservableInput} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {type Action} from 'web-app/util/redux/action-types';
import * as SentryService from 'web-app/services/sentry';

export const combineEpics = <T extends Action<any>, O extends T = T, State = any, Dependencies = any>(
    ...epics: Epic<T, O, State, Dependencies>[]
) => {
    return (action$: ActionsObservable<T>, state$: StateObservable<State>, dependencies: Dependencies) => {
        const mappedEpics = epics.map(epic =>
            applyCatchErrorToEpic<T, O, State, Dependencies>(epic, (error, source$) => {
                SentryService.captureException(error, {extra: {epicName: getFunctionName(epic)}});
                return source$;
            }),
        );

        return combineEpicsReduxObservable<T, O, State, Dependencies>(...mappedEpics)(action$, state$, dependencies);
    };
};

// Gets the function name, or returns <anonymous> if it's unnamed.
const getFunctionName = (fn: Function) => fn?.name || `<anonymous>`;

// Type for error handler that should pass the failing observable straight through
type ErrorHandler<T, R> = (err: any, caught: Observable<T>) => ObservableInput<R>;

// Extends an epic with error handling that doesn't manipulate the failing source
const applyCatchErrorToEpic =
    <T extends Action<any>, O extends T, State, Dependencies>(
        epic: Epic<T, O, State, Dependencies>,
        handleError: ErrorHandler<O, O>,
    ) =>
    (action$: ActionsObservable<T>, state$: StateObservable<State>, dependencies: Dependencies) =>
        epic(action$, state$, dependencies).pipe(catchError(handleError));
