import React from 'react';
import IconButton from '@mui/material/IconButton';

import {type ButtonProps} from 'modern-famly/components/input/button/button';
import {Icon} from 'modern-famly/components/data-display';
import {useTranslation} from 'modern-famly/system/use-translation';
import {useDataProps, type DataProps} from 'modern-famly/components/util';

export type FavoriteButtonProps = {
    size?: ButtonProps['size'];
    toggled: boolean;
    onToggle: (newState: boolean, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
} & DataProps;

export const FavoriteButton = ({
    toggled,
    onToggle: onToggleFromProps,
    size = 'regular',
    ...rest
}: FavoriteButtonProps) => {
    const addFavoriteAriaLabel = useTranslation('FavoriteButton.addFavorite');
    const removeFavoriteAriaLabel = useTranslation('FavoriteButton.removeFavorite');
    const dataProps = useDataProps(rest);

    const onToggle = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
        e => {
            onToggleFromProps(!toggled, e);
        },
        [toggled, onToggleFromProps],
    );

    return (
        <IconButton
            aria-label={toggled ? removeFavoriteAriaLabel : addFavoriteAriaLabel}
            aria-checked={toggled}
            sx={theme => ({
                height: size === 'compact' ? '36px' : '48px',
                width: size === 'compact' ? '36px' : '48px',
                '.MuiIcon-root': {
                    transition: 'color 0.2s ease-in-out',
                },
                '&:hover': {
                    background: theme.modernFamlyTheme.colorPalette.y100,
                    '.MuiIcon-root': {
                        color: theme.modernFamlyTheme.colorPalette.y400,
                    },
                },
            })}
            onClick={onToggle}
            {...dataProps}
        >
            <Icon name="star" color={toggled ? 'y400' : 'n300'} size={22} filled={toggled} />
        </IconButton>
    );
};
