import {Record} from 'immutable';
import {type AvatarColor} from 'modern-famly';

export class Image extends Record<{
    large: string;
    small: string;
    empty: boolean;
    isEmpty: boolean;
    /**
     * A number between 0 and 7 that the frontend can use to assign
     * a consistent color to e.g. avatars
     */
    colorCode?: number;
}>({
    large: '',
    small: '',
    empty: false,
    isEmpty: false,
    colorCode: undefined,
}) {}

const fromAPI = data => {
    if (typeof data === 'string') {
        return new Image({
            small: data,
            large: data,
        });
    }
    return new Image({
        ...data,
    });
};

export default {
    record: Image,
    default: new Image(),
    fromAPI,
};

/**
 * Turns a color code returned by core into an Modern Famly AvatarColor
 *
 * @param colorCode
 * @returns An AvatarColor or undefined
 */
export const colorCodeToAvatarColor = (colorCode: number | undefined): AvatarColor | undefined => {
    if (colorCode === undefined) {
        return undefined;
    }

    switch (true) {
        case colorCode < 15:
            return 'blue';
        case colorCode < 31:
            return 'light-blue';
        case colorCode < 47:
            return 'green';
        case colorCode < 63:
            return 'light-green';
        case colorCode < 79:
            return 'yellow';
        case colorCode < 95:
            return 'light-yellow';
        case colorCode < 111:
            return 'orange';
        case colorCode < 127:
            return 'light-orange';
        default:
            return undefined;
    }
};
