import {BrightHorizonsTheme as BrightHorizonsMFTheme} from 'modern-famly';

import {hexToRGBA} from 'web-app/util/hex-to-rgb';

import BaseTheme from './base';

const BrightHorizonsChromeColor = '#1F3E4E';
const BrightHorizonsPrimary = BrightHorizonsChromeColor;
const BrightHorizonsActive = hexToRGBA(BrightHorizonsChromeColor, 0.8);
const BrightHorizonsBackgroundHover = hexToRGBA(BrightHorizonsChromeColor, 0.04);

const BrightHorizonsTheme = BaseTheme.merge({
    title: 'Bright Horizons',
    chromeColor: BrightHorizonsChromeColor,
    primary: BrightHorizonsPrimary,
    active: BrightHorizonsActive,
    backgroundHover: BrightHorizonsBackgroundHover,
    mf: BrightHorizonsMFTheme,
});

export default BrightHorizonsTheme;
