import React from 'react';
import styled from 'styled-components';
import {createStack, Text} from 'modern-famly';
import {Link} from 'react-router-dom';

import Spinner from 'web-app/react/components/loading/spinner/spinner';
import {useCustomWhitelabelColor} from 'signin-app/components/hooks/use-custom-whitelabel-color';

export const PageBodyContainer = styled(
    createStack({
        flexDirection: 'column',
        pb: '162px', // value from designs
    }),
)``;

export const BottomBarContainer = ({children}) => {
    const p500 = useCustomWhitelabelColor('p500');
    return <StyledBottomBarContainer customBackgroundColor={p500}>{children}</StyledBottomBarContainer>;
};

export const StyledBottomBarContainer = styled(
    createStack({
        position: 'fixed',
        left: '0',
        right: '0',
        bottom: '0',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: '40px',
        py: '24px',
        gap: '16px',
    }),
)<{customBackgroundColor?: string}>`
    z-index: 100;
    background-color: ${props => props.customBackgroundColor};
`;

const StyledButton = createStack({
    alignItems: 'center',
    justifyContent: 'center',
    height: '64px',
    borderRadius: '8px',
    cursor: 'pointer',
    overflow: 'hidden',
    flex: 1,
});

const StyledConfirmButton = styled(StyledButton)<{
    disabled?: boolean;
    isOnlyButton?: boolean;
}>`
    border: none;
    color: ${props => (props.disabled ? props.theme.mf.colorPalette.n200 : props.theme.mf.colorPalette.n0)};
`;

const StyledLink = styled(Link)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export enum ConfirmButtonTypes {
    SIGN_IN = 'signIn',
    SIGN_OUT = 'signOut',
}

interface ConfirmButtonProps {
    text: string;
    type: ConfirmButtonTypes;
    onClick?: VoidFunction;
    isLoading?: boolean;
    disabled?: boolean;
    dataE2eCheckinConfirmButton?: string;
    isOnlyButton?: boolean;
}

interface ButtonProps {
    text: string;
    link: string;
}

export const ConfirmButton: React.FC<ConfirmButtonProps> = props => {
    const {text, type, onClick, isLoading, disabled, dataE2eCheckinConfirmButton, isOnlyButton} = props;

    const handleOnClick = React.useCallback(() => {
        if (!disabled && onClick) {
            onClick();
        }
    }, [disabled, onClick]);

    const bgColour = React.useMemo(() => {
        if (disabled) {
            return 'n75';
        }
        if (type === ConfirmButtonTypes.SIGN_IN) {
            return 'g400';
        }
        return 'r400';
    }, [disabled, type]);

    return (
        <StyledConfirmButton
            onClick={handleOnClick}
            disabled={disabled}
            data-e2e-id={dataE2eCheckinConfirmButton}
            isOnlyButton={isOnlyButton}
            backgroundColor={bgColour}
        >
            {isLoading ? (
                <Spinner color="white" centered />
            ) : (
                <Text variant="h6" textAlign="center" color={disabled ? 'n300' : 'n0'}>
                    {text}
                </Text>
            )}
        </StyledConfirmButton>
    );
};

const StyledButtonText = styled(createStack({}))`
    font-size: 20px;
`;

const ButtonWithBackground = styled(StyledButton)<{customBackgroundColor?: string}>`
    background-color: ${props => props.customBackgroundColor};
`;

export const ActionButton: React.FC<ButtonProps> = props => {
    const {text, link} = props;

    return (
        <ButtonWithBackground backgroundColor="n0">
            <StyledLink to={link}>
                <StyledButtonText color="n400">{text}</StyledButtonText>
            </StyledLink>
        </ButtonWithBackground>
    );
};
