import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useStatFeatures} from '../../features/use-stat-features';

/**
 * Do not use this query directly!
 */
export function useEditStaffSchedulePermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['staffScheduleSettings']['editStaffSchedule'] {
    const {institutionSetId} = payload;
    const [features] = useStatFeatures({
        institutionId: institutionSetId,
    });
    return {
        // We can read/update staff schedules only when the "shiftPlanner" feature is not enabled
        canRead: !features.shiftPlanner.isEnabled,
        canUpdate: !features.shiftPlanner.isEnabled,
        canCreate: false,
        canDelete: false,
    };
}
