import {gql} from '@apollo/client';

export const StatFeatureSubType = gql`
    fragment StatFeatureSubType on SubTypeFeatureSettingsFlags {
        canOptIn
        enabled
        required
        canOptInToLeaveSubtypeRequired
    }
`;
