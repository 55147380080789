import {ofType} from 'redux-observable';
import {from, range, of} from 'rxjs';
import {mergeMap, flatMap, catchError, concat, tap, map, ignoreElements} from 'rxjs/operators';

import {combineEpics} from 'web-app/util/redux-observable';
import {type Action} from 'web-app/util/redux/action-types';
import {type RootState} from 'signin-app/redux/main-reducer';
import {fetchOverviewSuccess} from 'signin-app/groups/actions';
import {fetchStatusConfigsSuccess} from 'signin-app/child/check-out/actions';
import DayCareApi from 'signin-app/api/daycare-api';
import {type InstitutionOverview} from 'signin-app/groups/reducer';
import ChildrenEntity from 'signin-app/entities/children';
import StaffEntity from 'signin-app/entities/staff';
import {type SignInEpic} from 'signin-app/redux/types';
import * as AccountAPI from 'signin-app/account/api';
import * as AccountActions from 'signin-app/account/actions';
import {TrackingEvents} from 'web-app/util/famlytics/events';
import * as Famlytics from 'signin-app/util/famlytics';

import * as Actions from './actions';

const prefetch: SignInEpic = action$ =>
    action$.pipe(
        ofType(Actions.prefetch.type),
        mergeMap(({payload}: ReturnType<typeof Actions.prefetch.action>) =>
            DayCareApi.groups(payload.accessToken).pipe(
                flatMap((overview: InstitutionOverview) => {
                    const fetchAllGroupsObservable = range(0, overview.groups.length).pipe(
                        flatMap(i => {
                            return ChildrenEntity.observables.fetchAll!({
                                accessToken: payload.accessToken,
                                groupId: overview.groups[i].groupId,
                                institutionId: overview.institution && overview.institution.institutionId,
                                includeRelations: true,
                                id: overview.groups[i].groupId,
                            }).pipe(
                                flatMap((fetchChildrenSuccessActions: Array<Action<any>>) =>
                                    from(fetchChildrenSuccessActions),
                                ),
                            );
                        }),
                    );

                    return StaffEntity.observables.fetchAll!(payload.accessToken).pipe(
                        flatMap((fetchStaffSuccessActions: Array<Action<any>>) => {
                            return DayCareApi.configs(overview.institution!.institutionId).pipe(
                                flatMap(statusConfigs => {
                                    return fetchAllGroupsObservable.pipe(
                                        concat(
                                            from(fetchStaffSuccessActions),
                                            of(fetchOverviewSuccess.action(overview)),
                                            of(fetchStatusConfigsSuccess.action(statusConfigs)),
                                            of(Actions.prefetchSuccess.action()),
                                        ),
                                    );
                                }),
                            );
                        }),
                        catchError(e => of(Actions.prefetchFailed.action(e))),
                    );
                }),
            ),
        ),
    );

const fetchAccount: SignInEpic = action$ =>
    action$.pipe(
        ofType(Actions.prefetchSuccess.type),
        mergeMap(() => {
            return AccountAPI.fetchAccount().pipe(map(AccountActions.fetchAccountSuccess.action));
        }),
    );

const trackSigninLoad: SignInEpic = action$ =>
    action$.pipe(
        ofType(AccountActions.fetchAccountSuccess.type),
        tap(() => {
            Famlytics.track(TrackingEvents.SIGNIN_SCREEN_V2_APP_LOADED, {
                screenSize: `${window.screen.width}(W) x ${window.screen.height}(H)`,
            });
        }),
        ignoreElements(),
    );

export default combineEpics<Action<any>, Action<any>, RootState>(prefetch, fetchAccount, trackSigninLoad);
