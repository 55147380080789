import {ApolloClient, ApolloLink, HttpLink, InMemoryCache} from '@apollo/client';
import fetch from 'node-fetch';

import APIHelper from 'signin-app/api/helpers/api-helper';

const apiKey = APIHelper.apiKey;

const isTestingEnvironment = process.env.TEST_TYPE === 'jest';
// There's a type mismatch between `@types/node-fetch` and `HttpLink.Options['fetch']`
// Since we're only using `node-fetch` for react-testing-library we'll just cast the type.
const typedFetch = fetch as unknown as WindowOrWorkerGlobalScope['fetch'];

const httpLink = new HttpLink({
    uri: operation => {
        /**
         * This adds a NOOP query string to the graphql URL which solely consists of the
         * operation name. This is exclusively done to improve the development experience
         */
        return `${APIHelper.getGraphQLUrlByKey(apiKey)}?${operation.operationName}`;
    },
    // A fetcher function is required when React testing library tests are run, if the fetcher function is not found then that will result into a failing test.
    fetch: isTestingEnvironment ? typedFetch : undefined,
});

const authLink = () =>
    new ApolloLink((operation, forward) => {
        const context = operation.getContext();
        const originalHeaders = context.headers || {};
        const {headers} = APIHelper.getRequestHeaders(!context.skipAuthentication);

        operation.setContext({
            headers: {
                ...originalHeaders,
                ...headers,
            },
        });

        return forward(operation);
    });

const link = ApolloLink.from([authLink(), httpLink]);

export const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
});
