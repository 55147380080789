import React from 'react';
import {components} from 'react-select';
import {type StylesConfig} from 'react-select';
import styled from 'styled-components';
import {Icon, Text} from 'modern-famly';

import Arrow from 'web-app/react/components/icons/legacy/arrows';
import Success from 'web-app/react/components/icons/legacy/lazy-icons/success';
import Close from 'web-app/react/components/icons/legacy/lazy-icons/close';
import {type InferProps} from 'web-app/util/typescript';
import {Flex} from 'web-app/react/components/layout/layout';
import currentTheme from 'web-app/styleguide/themes';
import {hexToRGBList} from 'web-app/util/hex-to-rgb';
import {Body} from 'web-app/react/components/text/text';
import {ScrollbarStylesForReactSelect} from 'web-app/react/components/helpers';

/*
|------------------------------------------------------------------------------
| Custom react-select components
|------------------------------------------------------------------------------
|
| react-select provides a way to override some of the default components used to
| build the select. We need to override some of those to get the right look and
| feel. The components defined here are used both in the Select and
| CreatableSelect components. See more at https://react-select.com/components
|
*/

export const getSelectContainer = (options: ComponentOptions) => {
    return (props: InferProps<typeof components.SelectContainer>) => {
        const innerProps = {
            ...props.innerProps,
            'data-e2e-id': options.e2eId,
            'data-e2e-class': options.e2eClass,
            'data-intercom-target': options.dataIntercomTarget,
        };

        return <components.SelectContainer {...props} innerProps={{...innerProps}} />;
    };
};

/**
 * The dropdown arrow icon
 */
export const DropdownIndicator = (props: InferProps<typeof components.DropdownIndicator>) => {
    return (
        <components.DropdownIndicator {...props}>
            <StyledArrow size={16} direction="south" isDisabled={props.selectProps.isDisabled} />
        </components.DropdownIndicator>
    );
};

const StyledArrow = styled(Arrow)<{isDisabled?: boolean}>`
    g {
        fill: ${props => (props.isDisabled ? props.theme.textDisabled : props.theme.text)};
    }
`;

/**
 * The options rendered in the item list
 */
export const Option = (props: InferProps<typeof components.Option>) => {
    // The `onClick` is handled by `components.Option`. If left on,
    // the onChange handler for the Select component will fire twice
    const {onClick, ...innerProps} = props.innerProps;

    return (
        <components.Option {...props}>
            <Flex {...innerProps} justify="space-between" data-e2e-class="timepicker-option">
                {/* TODO: (tl) the compiler thinks that `props.label` is always a string,
                so in the "else" case of the ternary, `props.label` has type `never` */}
                {typeof props.label === 'string' ? <Body ellipsis>{props.label}</Body> : props.label}
                {props.isSelected ? <StyledSuccess /> : null}
            </Flex>
        </components.Option>
    );
};

export const ModernFamlyOption = (props: InferProps<typeof components.Option>) => {
    // The `onClick` is handled by `components.Option`. If left on,
    // the onChange handler for the Select component will fire twice
    const {onClick, ...innerProps} = props.innerProps;

    return (
        <components.Option {...props}>
            <Flex {...innerProps} justify="space-between" grow={1}>
                {/* TODO: (tl) the compiler thinks that `props.label` is always a string,
                so in the "else" case of the ternary, `props.label` has type `never` */}
                {typeof props.label === 'string' ? (
                    <Text variant="body-small" ellipsis>
                        {props.label}
                    </Text>
                ) : (
                    props.label
                )}
                {props.isSelected ? <Icon name="check" size={20} color="p400" /> : null}
            </Flex>
        </components.Option>
    );
};

export const StyledSuccess = styled(Success)`
    g {
        fill: ${props => props.theme.primary};
    }
`;

/**
 * The small cross that clears the values
 */
export const ClearIndicator = (props: InferProps<typeof components.ClearIndicator>) => {
    return (
        <components.ClearIndicator {...props}>
            <StyledCloseIcon size={12} />
        </components.ClearIndicator>
    );
};

const StyledCloseIcon = styled(Close)`
    g {
        fill: ${props => props.theme.text};
    }

    &:hover {
        cursor: pointer;

        g {
            fill: ${props => props.theme.accent3};
        }
    }
`;

interface ComponentOptions {
    e2eId?: string;
    e2eClass?: string;
    dataIntercomTarget?: string;
}

export const getDefaultComponents = (options: ComponentOptions = {}) => ({
    SelectContainer: getSelectContainer(options),
    IndicatorSeparator: null,
    DropdownIndicator,
    Option,
    ClearIndicator,
});

/*
|------------------------------------------------------------------------------
| Custom component styling
|------------------------------------------------------------------------------
|
| Apply some basic styling from our own theme. See more about custom styling at
| https://react-select.com/styles
|
*/
export const getDefaultStyles = (): StylesConfig => {
    const theme = currentTheme();
    const activeRGB = hexToRGBList(theme.active) || [0, 0, 0];

    return {
        container: base => ({
            ...base,
            width: '100%',
        }),
        control: (base, state) => {
            return {
                ...base,
                backgroundColor: (() => {
                    if (state.selectProps.passive) {
                        return 'transparent';
                    }
                    if (state.isDisabled) {
                        return theme.borderConfiguration.disabled;
                    }
                    return base.backgroundColor;
                })(),
                borderColor: (() => {
                    if (state.isDisabled) {
                        return 'transparent';
                    }

                    if (state.selectProps.isInvalid) {
                        return theme.accent3;
                    }

                    if (state.isFocused) {
                        return theme.borderConfiguration.focus;
                    }

                    return theme.borderConfiguration.defaultColor;
                })(),
                borderWidth: state.selectProps.passive ? 0 : '1px',
                boxShadow: 'none',
                minHeight: '36px',
                height: '36px',
                '&:hover': {
                    borderColor: state.isFocused ? theme.borderConfiguration.focus : theme.borderConfiguration.hover,
                    cursor: 'text',
                },
                fontFamily: `'Matter', ${theme.fontConfiguration.family}`,
                fontSize: 14,
            };
        },
        valueContainer: (base, state) => ({
            ...base,
            padding: state.selectProps.passive ? 0 : `0 8px`,
            height: '100%',
        }),
        input: (base, state) => ({
            ...base,
            color: state.isDisabled && !state.selectProps.passive ? theme.textDisabled : theme.text,
            fontFamily: `'Matter', ${theme.fontConfiguration.family}`,
            margin: '0',
            '& input': {
                marginBottom: 0,
                fontFamily: theme.fontConfiguration.family,
            },
        }),
        menu: base => ({
            ...base,
            fontFamily: `'Matter', ${theme.fontConfiguration.family}`,
            fontSize: 14,
            minWidth: '100%',
            width: 'initial',
            zIndex: 3,
        }),
        menuList: base => ({
            ...base,
            padding: '4px',
            boxShadow: theme.shadow.three,
            ...ScrollbarStylesForReactSelect,
        }),
        option: (base, state) => {
            // rgb values of the active color from our theme
            const [r, g, b] = activeRGB;

            const textStyles = (() => {
                if (state.isSelected) {
                    return {
                        backgroundColor: `rgba(${r},${g}, ${b}, 0.04)`,
                        color: theme.text,
                    };
                } else if (state.isFocused) {
                    return {
                        backgroundColor: theme.backgroundHover,
                        color: theme.text,
                    };
                } else {
                    return {
                        backgroundColor: 'transparent',
                        color: theme.text,
                    };
                }
            })();

            return {
                ...base,
                borderRadius: '2px',
                padding: '12px 8px',
                ...textStyles,
                '&:active': {
                    backgroundColor: `rgba(${r},${g}, ${b}, 0.08)`,
                },
                '&:hover': {
                    cursor: 'pointer',
                },
            };
        },
        clearIndicator: base => ({
            ...base,
            paddingRight: 0,
        }),
        placeholder: (base, state) => ({
            ...base,
            color: (() => {
                if (state.selectProps.passive) {
                    return theme.text;
                }

                return theme.textDisabled;
            })(),
            fontSize: '14px',
            marginLeft: 0,
            marginRight: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
        }),
        singleValue: (base, state) => ({
            ...base,
            margin: 0,
            color: state.selectProps.passive ? theme.text : base.color,
        }),
        dropdownIndicator: base => ({
            ...base,
            cursor: 'pointer',
        }),
    };
};
