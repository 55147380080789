import React from 'react';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useFetchWorkTagsPermissions} from './queries/use-fetch-work-tags-permissions';
import {type StatPermissions} from './stat-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';

export function useWorkTagsPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['workTags'] & {loading: boolean} {
    const {institutionSetId, shouldQuery} = payload;
    const {data, loading} = useFetchWorkTagsPermissions({
        variables: {
            siteSetId: institutionSetId,
        },
        skip: !institutionSetId || !shouldQuery,
    });
    const crud = useCrudPermissionAdapter(data?.staffhours.management.workTags);
    return React.useMemo(() => ({...crud, loading}), [crud, loading]);
}
