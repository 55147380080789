import {type NonEmptyArray} from './non-empty-array';

/**
 * overlayColors
 *
 * Builds css string for overlaying colors bottom up. I.e. last argument is on top.
 */
export const overlayColors = (...args: NonEmptyArray<string>) =>
    args
        .map(color => `linear-gradient(0deg, ${color}, ${color})`)
        .reverse()
        .join(', ');
