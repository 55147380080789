import React from 'react';

import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';
import {useFetchDailyTotalsPermissions} from './queries/use-fetch-daily-totals-permissions';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export function useDailyTotalsPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['attendance']['header']['export']['dailyTotals'] & {loading: boolean} {
    const {data, loading} = useFetchDailyTotalsPermissions({
        variables: {siteSetId: payload.institutionSetId},
        skip: !payload.shouldQuery,
    });
    const crud = useCrudPermissionAdapter(data?.staffhours.management.attendance.dailyCsvExportURL);

    return React.useMemo(() => ({loading, ...crud}), [crud, loading]);
}
