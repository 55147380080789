import React from 'react';
import MuiFormHelperText, {type FormHelperTextProps as MuiFormHelperTextProps} from '@mui/material/FormHelperText';
import {type Components, type Theme} from '@mui/material/styles';

type Base = Pick<MuiFormHelperTextProps, 'children' | 'error' | 'disabled'>;

export type FormErrorTextProps = Base;
export type FormHelperTextProps = Omit<Base, 'error'>;

export const FormErrorText: React.FC<FormErrorTextProps> = props => <MuiFormHelperText {...props} />;
export const FormHelperText: React.FC<FormHelperTextProps> = props => <MuiFormHelperText {...props} error={false} />;

/**
 * MUI Theming
 */
export const FormHelperTextThemeConfiguration: Components<Theme>['MuiFormHelperText'] = {
    styleOverrides: {
        root: ({theme}) => {
            return {
                ...theme.typography.micro,
                color: theme.modernFamlyTheme.colorPalette.n300,
                margin: 0,
            };
        },
        disabled: ({theme}) => {
            return {
                color: theme.modernFamlyTheme.colorPalette.n200,
            };
        },
    },
};
