import React from 'react';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {type StatPermissions} from './stat-permissions';
import {useCustomFormsEnrollmentPermissions} from './use-custom-forms-enrollment-permissions';
import {useCustomFormsInterestPermissions} from './use-custom-forms-interest-permissions';
import {useCustomFormsRegistrationPermissions} from './use-custom-forms-registration-permissions';
import {useCustomFormsManagementPermissions} from './use-custom-forms-management-permissions';
import {useCustomFormsManagementBySiteSetIdPermissions} from './use-custom-forms-management-by-site-set-id-permissions';

export function useCustomRegistrationFormsPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['customRegistrationForms'] & {loading: boolean} {
    const {loading: loadingEnrollmentPermissions, ...enrollment} = useCustomFormsEnrollmentPermissions(payload);
    const {loading: loadingInterestPermissions, ...interest} = useCustomFormsInterestPermissions(payload);
    const {loading: loadingRegistrationPermissions, ...registration} = useCustomFormsRegistrationPermissions(payload);
    const {loading: loadingManageFormPermissions, ...manageForm} = useCustomFormsManagementPermissions(payload);
    const {loading: loadingManageFormBySiteSetIdPermissions, ...manageFormBySiteSetId} =
        useCustomFormsManagementBySiteSetIdPermissions(payload);

    return React.useMemo(
        () => ({
            loading:
                loadingEnrollmentPermissions ||
                loadingInterestPermissions ||
                loadingRegistrationPermissions ||
                loadingManageFormPermissions ||
                loadingManageFormBySiteSetIdPermissions,
            enrollment,
            interest,
            registration,
            manageForm,
            manageFormBySiteSetId,
        }),
        [
            loadingEnrollmentPermissions,
            loadingInterestPermissions,
            loadingRegistrationPermissions,
            loadingManageFormPermissions,
            loadingManageFormBySiteSetIdPermissions,
            enrollment,
            interest,
            registration,
            manageForm,
            manageFormBySiteSetId,
        ],
    );
}
