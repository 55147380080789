import {MonkeyPuzzleTheme as MonkeyPuzzleMFTheme} from 'modern-famly';

import {hexToRGBA} from 'web-app/util/hex-to-rgb';

import BaseTheme from './base';

const MonkeyPuzzleChromeColor = '#008090';
const MonkeyPuzzlePrimary = MonkeyPuzzleChromeColor;
const MonkeyPuzzleActive = hexToRGBA(MonkeyPuzzleChromeColor, 0.8);
const MonkeyPuzzleBackgroundHover = hexToRGBA(MonkeyPuzzleChromeColor, 0.04);

const MonkeyPuzzleTheme = BaseTheme.merge({
    title: 'MonkeyPuzzle',
    chromeColor: MonkeyPuzzleChromeColor,
    primary: MonkeyPuzzlePrimary,
    active: MonkeyPuzzleActive,
    backgroundHover: MonkeyPuzzleBackgroundHover,
    mf: MonkeyPuzzleMFTheme,
});

export default MonkeyPuzzleTheme;
