import {Range} from 'immutable';

export default (lower, upper) => Range(lower, upper).toArray();

/**
 * Generate a range of numbers separated by the given step.
 *
 * @param {number} lower Lower bound
 * @param {number} upper Upper bound
 * @param {number} step Range step
 * @param {boolean} includeUpper Whether to include the given upper bound. Defaults to false
 */
export const rangeWithStep = (lower, upper, step, includeUpper) => {
    const _upper = includeUpper ? upper + 1 : upper;

    return Range(lower, _upper, step).toArray();
};
