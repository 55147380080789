import {createSelector, type ParametricSelector, type Selector, type OutputParametricSelector} from 'reselect';
import {Map, List, type OrderedSet, type Record} from 'immutable';

import {type EntityState, type RecordInstance} from 'web-app/react/entities/factory/reducer-factory';
import {type APIDescription, type Entity, type EntityRelations} from 'web-app/react/entities/factory';

const HANDLING_BY_ID = () => false;
const MAKE_HANDLING_BY_ID = () => HANDLING_BY_ID;

export type EntityMap<IRecord extends {}> = Map<string, RecordInstance<IRecord>>;

const getReferencesForId = <IRecord extends {}>(
    referenceMap: Map<string, OrderedSet<string>>,
    entityMap: EntityMap<IRecord>,
    referenceId: string,
): List<any> => {
    if (!referenceMap.has(referenceId)) {
        return List();
    }

    return referenceMap
        .get(referenceId)!
        .map(entityId => entityMap.get(entityId))
        .filter(a => a)
        .toList();
};

export const createSelectors = <IRecord extends {[key: string]: any}, RootState>(
    reduxKeyPath: string,
    apiDescription: APIDescription,
    entityRelations: EntityRelations,
    groupByKeys?: string[],
): EntitySelectors<IRecord, RootState> => {
    const entityState = (state: RootState): EntityState<IRecord> =>
        reduxKeyPath.split('.').reduce((reduction: any, current) => reduction[current], state);
    const idSelector = (_: RootState, props: {id: string}) => props.id;
    const referenceIdSelector = (_: RootState, props: {referenceId: string}) => props.referenceId;
    const referenceIdsSelector = (_: RootState, props: {referenceIds: string[]}): string[] => props.referenceIds;
    const referenceMap = createSelector(entityState, entityState => entityState.referenceMap);
    const entityMap = createSelector(entityState, entityState => entityState.entityMap);
    const fetchingSet = createSelector(entityState, entityState => entityState.fetchingSet);
    const updatingSet = createSelector(entityState, entityState => entityState.updatingSet);
    const deletingSet = createSelector(entityState, entityState => entityState.deletingSet);

    const getGroupByKeysSelectors = () => {
        if (groupByKeys) {
            const makeGetByGroupedIdFunc = () => {
                return createSelector(
                    referenceMap,
                    entityMap,
                    referenceIdSelector,
                    (referenceMap, entityMap, referenceId) => getReferencesForId(referenceMap, entityMap, referenceId),
                );
            };

            const makeByMultipleGroupedIdFunc = () => {
                return createSelector(
                    referenceMap,
                    entityMap,
                    referenceIdsSelector,
                    (referenceMap, entityMap, referenceIds): Map<string, any> => {
                        return Map(
                            referenceIds.map(referenceId => {
                                return [referenceId, getReferencesForId(referenceMap, entityMap, referenceId)];
                            }),
                        );
                    },
                );
            };

            return {
                getByGroupedId: makeGetByGroupedIdFunc(),
                makeGetByGroupedId: makeGetByGroupedIdFunc,
                getByMultipleGroupedId: makeByMultipleGroupedIdFunc(),
                makeByMultipleGroupedId: makeByMultipleGroupedIdFunc,
            };
        } else {
            return {
                getByGroupedId: () => List(),
                makeGetByGroupedId: () => () => List(),
                getByMultipleGroupedId: () => Map<string, any>(),
                makeByMultipleGroupedId: () => () => Map<string, any>(),
            };
        }
    };

    const groupByKeysSelectors = getGroupByKeysSelectors();

    const makeGetByIdFunc = () => {
        return createSelector(entityMap, idSelector, (entityMap, id) => entityMap.get(id));
    };

    const getById = makeGetByIdFunc();
    const makeGetById = makeGetByIdFunc;

    // Fetching selectors
    const getFetchingSelectors = () => {
        if (apiDescription.fetch || apiDescription.fetchAll) {
            const makeFetchingByIdFunc = () => {
                return createSelector(fetchingSet, idSelector, (fetchingSet, id) => fetchingSet.includes(id));
            };

            return {
                fetchingById: makeFetchingByIdFunc(),
                makeFetchingById: makeFetchingByIdFunc,
            };
        } else {
            return {
                fetchingById: HANDLING_BY_ID,
                makeFetchingById: MAKE_HANDLING_BY_ID,
            };
        }
    };

    const fetchingSelectors = getFetchingSelectors();

    // Creating selectors
    const getCreatingSelectors = () => {
        if (apiDescription.create) {
            const makeCreatingFunc = () => {
                return createSelector(entityState, entityState => entityState.creating);
            };

            return {
                creating: makeCreatingFunc(),
                makeCreating: makeCreatingFunc,
            };
        } else {
            return {
                creating: HANDLING_BY_ID,
                makeCreating: MAKE_HANDLING_BY_ID,
            };
        }
    };

    const creatingSelectors = getCreatingSelectors();

    // Updating selectors
    const getUpdatingSelectors = () => {
        if (apiDescription.update) {
            const makeUpdating = () => {
                return createSelector(updatingSet, idSelector, (updatingSet, id) => updatingSet.includes(id));
            };

            return {
                updatingById: makeUpdating(),
                makeUpdatingById: makeUpdating,
            };
        } else {
            return {
                updatingById: HANDLING_BY_ID,
                makeUpdatingById: MAKE_HANDLING_BY_ID,
            };
        }
    };

    const updatingSelectors = getUpdatingSelectors();

    // Deleting selectors
    const getDeleteSelectors = () => {
        if (apiDescription.delete) {
            const makeDeleting = () => {
                return createSelector(deletingSet, idSelector, (deletingSet, id) => deletingSet.includes(id));
            };

            return {
                deletingById: makeDeleting(),
                makeDeletingById: makeDeleting,
            };
        } else {
            return {
                deletingById: HANDLING_BY_ID,
                makeDeletingById: MAKE_HANDLING_BY_ID,
            };
        }
    };

    const deleteSelectors = getDeleteSelectors();

    const getMakeRelationSelectorById = () => {
        if (entityRelations && entityRelations.length > 0) {
            return (entity: Entity<RootState, IRecord>, idsFromEntity: (record?: Record<IRecord>) => List<string>) =>
                () => {
                    const getById = makeGetById();

                    return createSelector(
                        entity.selectors.entityMap as typeof entityMap, // A little hack to avoid circular type reference
                        getById,
                        (items, thisEntity) =>
                            idsFromEntity(thisEntity)
                                .map(id => items.get(id))
                                .filter(a => a),
                    );
                };
        } else {
            // allows to use makeRelationSelectorById if entityRelations are empty
            // so it doesn't have to be checked against undefined every time it's used
            return () => () => () => undefined;
        }
    };

    return {
        entityMap,
        ...groupByKeysSelectors,
        ...fetchingSelectors,
        ...creatingSelectors,
        ...updatingSelectors,
        ...deleteSelectors,
        getById,
        makeGetById,
        makeRelationSelectorById: getMakeRelationSelectorById(),
    };
};

type EmptyMakeRelationSelectorById = () => () => () => undefined;
type EmptyHandlingById = () => false;
type EmptyMakeHandlingById = () => EmptyHandlingById;

type HandlingById<Selector> = Selector | EmptyHandlingById;
type MakeHandlingById<SelectorCreator> = SelectorCreator | EmptyMakeHandlingById;

interface EntitySelectors<IRecord extends {}, RootState> {
    entityMap: Selector<RootState, EntityMap<IRecord>>;
    getById: ParametricSelector<RootState, {id: string}, RecordInstance<IRecord> | undefined>;
    makeGetById: () => ParametricSelector<RootState, {id: string}, RecordInstance<IRecord> | undefined>;
    makeRelationSelectorById:
        | ((
              entity: Entity<RootState, IRecord>,
              idsFromEntity: (record?: Record<IRecord>) => List<string>,
          ) => () => OutputParametricSelector<RootState, {id: string}, List<RecordInstance<IRecord> | undefined>, any>)
        | EmptyMakeRelationSelectorById;
    deletingById: HandlingById<ParametricSelector<RootState, {id: string}, boolean>>;
    makeDeletingById: MakeHandlingById<() => ParametricSelector<RootState, {id: string}, boolean>>;
    updatingById: HandlingById<ParametricSelector<RootState, {id: string}, boolean>>;
    makeUpdatingById: MakeHandlingById<() => ParametricSelector<RootState, {id: string}, boolean>>;
    creating: HandlingById<Selector<RootState, boolean>>;
    makeCreating: MakeHandlingById<() => Selector<RootState, boolean>>;
    fetchingById: HandlingById<ParametricSelector<RootState, {id: string}, boolean>>;
    makeFetchingById: MakeHandlingById<() => ParametricSelector<RootState, {id: string}, boolean>>;
    getByGroupedId: ParametricSelector<RootState, {referenceId: string}, List<RecordInstance<IRecord>>>;
    makeGetByGroupedId: () => ParametricSelector<RootState, {referenceId: string}, List<RecordInstance<IRecord>>>;
    getByMultipleGroupedId: ParametricSelector<RootState, {referenceIds: string[]}, Map<string, List<any>>>;
    makeByMultipleGroupedId: () => ParametricSelector<RootState, {referenceIds: string[]}, Map<string, List<any>>>;
}
