import {Record} from 'immutable';

export class Name extends Record<{
    salutation: string;
    firstName: string;
    middleName: string;
    lastName: string;
    fullName: string;
}>({
    salutation: '',
    firstName: '',
    middleName: '',
    lastName: '',
    fullName: '',
}) {}

const nameFromString = fullName => {
    const names = fullName.split(' ');
    if (names.length >= 3) {
        const firstName = names[0];
        const middleName = names.slice(1, names.length - 1).join(' ');
        const lastName = names[names.length - 1];
        return {
            firstName,
            middleName,
            lastName,
            fullName,
        };
    } else if (names.length === 2) {
        return {
            firstName: names[0],
            middleName: null,
            lastName: names[1],
            fullName,
        };
    } else if (names.length === 1) {
        return {
            firstName: names[0],
            middleName: null,
            lastName: null,
            fullName,
        };
    } else {
        throw new Error(`Invalid name ${fullName}`);
    }
};

const fromAPI = data => {
    return new Name({
        ...data,
    });
};

export default {
    record: Name,
    default: new Name(),
    nameFromString,
    fromAPI,
};
