import createSelector from 'web-app/util/custom-create-selector';
import {type RootState} from 'signin-app/redux/main-reducer';
import RoutesMap from 'signin-app/routes/routes-map';
import {isPinApp} from 'signin-app/login/selectors';
import {type IEmployee} from 'signin-app/entities/staff/model';
import {type PinEmployee} from 'signin-app/pin/models';
import {type RecordInstance} from 'web-app/react/entities/factory/reducer-factory';

import {makeStaffSelector, type EmployeeIdProps, getPinEmployee} from '../selectors';

export const isCheckingOut = (state: RootState) => state.employeeCheckOut.isCheckingOut;

const entityStaffSelector = makeStaffSelector(RoutesMap.employeeCheckOut);

export const employee = createSelector<
    RootState,
    EmployeeIdProps,
    boolean,
    PinEmployee | undefined,
    RecordInstance<IEmployee>,
    PinEmployee | RecordInstance<IEmployee> | undefined
>(isPinApp, getPinEmployee, entityStaffSelector, (pinApp, pinEmployee, entityStaff) => {
    if (pinApp) {
        return pinEmployee;
    }
    return entityStaff;
});
