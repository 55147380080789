import React from 'react';

import {hasValue} from 'web-app/util/typescript';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useFetchSubtypesPermissions} from './queries/use-fetch-subtypes-permissions';
import {type StatPermissions} from './stat-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';

/**
 * Do not use this query directly!
 */
export function useSubtypePermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['subtypes'] & {loading: boolean} {
    const {institutionSetId, shouldQuery} = payload;
    const {loading, data} = useFetchSubtypesPermissions({
        variables: {
            institutionSetId,
        },
        skip: !hasValue(institutionSetId) || !shouldQuery,
    });
    const crudPermissions = useCrudPermissionAdapter(data?.staffhours.management.subtypes);
    const paidStatusCrudPermissions = useCrudPermissionAdapter(data?.staffhours.management.subtypes.paidStatus);
    return React.useMemo(
        () => ({
            loading,
            ...crudPermissions,
            paidStatus: paidStatusCrudPermissions,
        }),
        [crudPermissions, loading, paidStatusCrudPermissions],
    );
}
