import React from 'react';
import Box from '@mui/material/Box';

import {StandaloneCheckbox, type StandaloneCheckboxProps} from 'modern-famly/components/input';
import {type DataProps, useDataProps} from 'modern-famly/components/util';

import {ClassNames} from './shared';

export type ListHeaderCheckboxProps = {
    onChange: StandaloneCheckboxProps['onChange'];
    checked?: StandaloneCheckboxProps['checked'];
} & DataProps;

export const ListHeaderCheckbox = (props: ListHeaderCheckboxProps) => {
    const dataProps = useDataProps(props);

    return (
        <Box className={ClassNames.ListHeader} display="inline-flex" px={3}>
            <StandaloneCheckbox onChange={props.onChange} checked={props.checked} size="compact" {...dataProps} />
        </Box>
    );
};
