import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {shallowCompare} from 'web-app/util/shallow-diff';
import {type Dictionary, hasValue} from 'web-app/util/typescript';

type QueryParameters<T extends string> = Dictionary<T, ReturnType<typeof URLSearchParams['prototype']['get']>>;

/**
 * A hook that returns the current query params with keys matching the passed array.
 *
 * @deprecated use the improved `useStatQueryParams` instead for better performance and easier usage
 * @see {@link file://./../staffing-attendance/utils/use-stat-query-param.ts}
 * @param {Array.<string>} queryParamNames - List of queryParams names who's values to get.
 * @returns {\{queryParamNames: string | null\}} - Map of the provided keys with their
 * corresponding values. Null if empty.
 *
 * Usage:
 * ```js
 * const queryVariables = useQueryParams(['foo'])
 * console.log(queryVariables.foo) // Outputs "bar"
 * ```
 */

export function useQueryParams(queryParamNames?: undefined): {[key: string]: string};
export function useQueryParams<T extends string>(queryParamNames: T[]): QueryParameters<T>;

export function useQueryParams<T extends string>(queryParamNames?: T[]) {
    const computedQueryParams = useComputeQueryParams(queryParamNames);
    const [queryParams, setQueryParams] = useState<typeof computedQueryParams>(computedQueryParams);

    /**
     * Compare computed query parameters with state to avoid updates with
     * equal values.
     */
    useEffect(() => {
        if (shallowCompare(queryParams, computedQueryParams)) {
            setQueryParams(computedQueryParams);
        }
    }, [computedQueryParams, queryParams]);

    return queryParams;
}

const useComputeQueryParams = <T extends string>(queryParamNames?: T[]): QueryParameters<T> => {
    const initial: QueryParameters<T> = {};

    // `useLocation()` throws an error if used outside of the React router.
    try {
        const location = useLocation();
        const queryParams = new URLSearchParams(location.search);

        if (!hasValue(queryParamNames)) {
            const obj = {};
            queryParams.forEach((value, key) => {
                if (hasValue(obj[key])) {
                    obj[key] = obj[key] instanceof Array ? [...obj[key], value] : [obj[key], value];
                } else {
                    obj[key] = value;
                }
            });
            return obj;
        }

        return queryParamNames.reduce((reduction, queryParamName) => {
            reduction[queryParamName] = queryParams.get(queryParamName);
            return reduction;
        }, initial);
    } catch {
        console.error('The useQueryParams hook can only be used within the context of a react router route.');
        return hasValue(queryParamNames) ? initial : {};
    }
};
