import {useQueriesOnFirstCall} from '../../queries/use-queries-on-first-call';
import {type StatCrudPermission} from './internal/stat-crud-permission';
import {type StatPermissions} from './internal/stat-permissions';
import {useAttendanceDetailsPermissions} from './internal/use-attendance-details-permissions';
import {useContractBankHoursPermissions} from './internal/use-contract-bank-hours-permissions';
import {useContractChangeLogPermissions} from './internal/use-contract-change-log-permissions';
import {useContractWeeklyHoursPermissions} from './internal/use-contract-weekly-hours-permissions';
import {useContractWorkAvailabilityPermissions} from './internal/use-contract-work-availability-permissions';
import {useCustomFormsEnrollmentPermissions} from './internal/use-custom-forms-enrollment-permissions';
import {useCustomFormsInterestPermissions} from './internal/use-custom-forms-interest-permissions';
import {useCustomFormsManagementBySiteSetIdPermissions} from './internal/use-custom-forms-management-by-site-set-id-permissions';
import {useCustomFormsManagementPermissions} from './internal/use-custom-forms-management-permissions';
import {useCustomFormsRegistrationPermissions} from './internal/use-custom-forms-registration-permissions';
import {useDailyTotalsPermissions} from './internal/use-daily-totals-permissions';
import {useDetailedBreakdownPermissions} from './internal/use-detailed-breakdown-permissions';
import {useEditStaffSchedulePermissions} from './internal/use-edit-staff-schedule-permissions';
import {useEmployeePermissions} from './internal/use-employee-permissions';
import {useFtePermissions} from './internal/use-fte-permissions';
import {useInquiriesDownloadCsvPermissions} from './internal/use-inquiries-download-csv-permissions';
import {useKinderConnectPermissions} from './internal/use-kinder-connect-permissions';
import {useLeavePermissions} from './internal/use-leave-permission';
import {useLeaveRequestPermissions} from './internal/use-leave-request-permission';
import {useLeaveSettingsRangePermissions} from './internal/use-leave-settings-range-permissions';
import {useLeaveSettingsSubtypePaidPermissions} from './internal/use-leave-settings-subtype-paid-permissions';
import {useMilkReportPermissions} from './internal/use-milk-report-permissions';
import {useNewFormPermissions} from './internal/use-new-form-permissions';
import {useNewInquiriesPermissions} from './internal/use-new-inquiries-permissions';
import {useSchedulePermissions} from './internal/use-schedule-permission';
import {useShiftPlannerBulkDeleteShiftPermissions} from './internal/use-shift-planner-bulk-delete-shift-permissions';
import {useShiftPlannerCreateNewShiftPermissions} from './internal/use-shift-planner-create-new-shift-permissions';
import {useShiftPlannerDeleteShiftPermissions} from './internal/use-shift-planner-delete-shift-permissions';
import {useShiftPlannerHeaderCopyShiftsPermissions} from './internal/use-shift-planner-header-copy-shifts-permissions';
import {useShiftPlannerPermissions} from './internal/use-shift-planner-permissions';
import {useStaffAttendanceByEmployeePermissions} from './internal/use-staff-attendance-by-employe-permissions';
import {useStaffAttendanceFeatureTogglePermissions} from './internal/use-staff-attendance-feature-toggle-permissions';
import {useStaffAttendancePermissions} from './internal/use-staff-attendance-permissions';
import {useStaffHoursExactAttendancePermission} from './internal/use-staff-hours-exact-attendance-permission';
import {useStaffHoursExportCSVPermissions} from './internal/use-staff-hours-export-csv-permissions';
import {useStaffHoursFiltersPermissions} from './internal/use-staff-hours-filters-permissions';
import {useStaffHoursHistoricalDownloadPermissions} from './internal/use-staff-hours-historical-download-permissions';
import {useStaffHoursPermissions} from './internal/use-staff-hours-permissions';
import {useStaffProfileScheduleWidgetPermissions} from './internal/use-staff-profile-schedule-widget-permissions';
import {useStaffingCSVPermissions} from './internal/use-staffing-csv-permissions';
import {useSubtypePermissions} from './internal/use-subtype-permissions';
import {useWorkTagsPermissions} from './internal/use-work-tags-permissions';

export interface UseStatPermissionPayload {
    institutionSetId: string;
    shouldQuery?: boolean;
    employeeId?: string;
    formId?: string;
}

/**
 * Fetches, adapts and keeps all the available staffing and attendance permissions up to date and in sync.
 *
 * This is the only hook that should ever be directly accessed to know if a feature is accessible and available to a
 * logged in user, or not.
 *
 * @param payload option bag containing all necessary information to receive the available feature permissions.
 * @returns the available stat permissions. If any given permission is not returned by the backend (null or undefined),
 * the return value will have it set to 'false', so not checks for `hasValue` or something is ever needed.
 *
 * In addition, the frontend interface for permissions is a simple `CRUD` interface, which is completely decoupled
 * from the backend structure, making later adaptations easier.
 *
 * @deprecated use the stat context instead
 */
export function useStatPermissions(payload: Omit<UseStatPermissionPayload, 'shouldQuery'>): StatPermissions {
    const [createProxy, shouldQuery] = useQueriesOnFirstCall<StatPermissions>();
    const result: StatPermissions = {
        unrestricted: unrestrictedCRUD,
        subtypes: useSubtypePermissions({...payload, shouldQuery: Boolean(shouldQuery.subtypes)}),
        fte: useFtePermissions({...payload, shouldQuery: Boolean(shouldQuery.fte)}),
        leaves: useLeavePermissions({...payload, shouldQuery: Boolean(shouldQuery.leaves)}),
        leaveRequests: useLeaveRequestPermissions({...payload, shouldQuery: Boolean(shouldQuery.leaveRequests)}),
        schedules: useSchedulePermissions({...payload, shouldQuery: Boolean(shouldQuery.schedules)}),
        staffAttendance: useStaffAttendancePermissions({...payload, shouldQuery: Boolean(shouldQuery.staffAttendance)}),
        staffHours: useStaffHoursPermissions({...payload, shouldQuery: Boolean(shouldQuery.staffHours)}),
        staffAttendanceByEmployee: useStaffAttendanceByEmployeePermissions({
            ...payload,
            shouldQuery: Boolean(shouldQuery.staffAttendanceByEmployee),
        }),
        customRegistrationForms: {
            enrollment: useCustomFormsEnrollmentPermissions({
                ...payload,
                shouldQuery: Boolean(shouldQuery.customRegistrationForms?.enrollment),
            }),
            interest: useCustomFormsInterestPermissions({
                ...payload,
                shouldQuery: Boolean(shouldQuery.customRegistrationForms?.interest),
            }),
            registration: useCustomFormsRegistrationPermissions({
                ...payload,
                shouldQuery: Boolean(shouldQuery.customRegistrationForms?.registration),
            }),
            manageForm: useCustomFormsManagementPermissions({
                ...payload,
                shouldQuery: Boolean(shouldQuery.customRegistrationForms?.manageForm),
            }),
            manageFormBySiteSetId: useCustomFormsManagementBySiteSetIdPermissions({
                ...payload,
                shouldQuery: Boolean(shouldQuery.customRegistrationForms?.manageFormBySiteSetId),
            }),
        },
        shiftPlanner: useShiftPlannerPermissions({
            ...payload,
            shouldQuery: Boolean(shouldQuery.shiftPlanner),
        }),
        staffAttendanceFeatureToggle: useStaffAttendanceFeatureTogglePermissions({
            ...payload,
            shouldQuery: Boolean(shouldQuery.staffAttendanceFeatureToggle),
        }),

        institution: {
            employees: useEmployeePermissions({...payload, shouldQuery: Boolean(shouldQuery['institution.employees'])}),
        },

        // New permissions to be used with the StatGuard go here //
        // One root node per page

        roomPlanner: {
            header: {},
            content: {},
        },
        childAttendance: {
            header: {
                export: {
                    milkReport: useMilkReportPermissions({
                        ...payload,
                        shouldQuery: Boolean(shouldQuery.childAttendance?.header?.export?.milkReport),
                    }),
                    kinderConnect: useKinderConnectPermissions({
                        ...payload,
                        shouldQuery: Boolean(shouldQuery.childAttendance?.header?.export?.kinderConnect),
                    }),
                },
            },
            content: {},
        },

        staffOverview: {
            header: {},
            content: {
                cells: {
                    employee: {
                        bankHours: useContractBankHoursPermissions({
                            ...payload,
                            shouldQuery: Boolean(shouldQuery['staffOverview.content.cells.employee']?.bankHours),
                        }),
                        contractedHours: useContractWeeklyHoursPermissions({
                            ...payload,
                            shouldQuery: Boolean(shouldQuery['staffOverview.content.cells.employee']?.contractedHours),
                        }),
                    },
                    day: {
                        scheduled: useShiftPlannerPermissions({
                            ...payload,
                            shouldQuery: Boolean(shouldQuery['staffOverview.content.cells.day']?.scheduled),
                        })?.general ?? {
                            canRead: false,
                            canUpdate: false,
                            canCreate: false,
                            canDelete: false,
                        },
                        leave: useLeavePermissions({
                            ...payload,
                            shouldQuery: Boolean(shouldQuery['staffOverview.content.cells.day']?.leave),
                        }),
                    },
                    total: {
                        scheduled: useShiftPlannerPermissions({
                            ...payload,
                            shouldQuery: Boolean(shouldQuery['staffOverview.content.cells.day']?.scheduled),
                        })?.general ?? {
                            canRead: false,
                            canUpdate: false,
                            canCreate: false,
                            canDelete: false,
                        },
                    },
                },
            },
        },
        staffSchedule: {
            header: {
                export: {
                    staffingCSV: useStaffingCSVPermissions({
                        ...payload,
                        shouldQuery: Boolean(shouldQuery.staffSchedule?.header?.export?.staffingCSV),
                    }),
                },
            },
            content: {},
        },
        staffPlanner: {
            header: {
                copyShifts: useShiftPlannerHeaderCopyShiftsPermissions({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['staffPlanner.header']?.copyShifts),
                }),
                newShift: useShiftPlannerCreateNewShiftPermissions({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['staffPlanner.header']?.newShift),
                }),
                deleteShift: useShiftPlannerDeleteShiftPermissions({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['staffPlanner.header']?.deleteShift),
                }),
                bulkDeleteShift: useShiftPlannerBulkDeleteShiftPermissions({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['staffPlanner.header']?.bulkDeleteShift),
                }),
            },
            content: {
                workAvailability: useContractWorkAvailabilityPermissions({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['staffPlanner.content']?.workAvailability),
                }),
                contractedHours: useContractWeeklyHoursPermissions({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['staffPlanner.content']?.contractedHours),
                }),
                scheduledHours: useShiftPlannerPermissions({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['staffPlanner.content']?.scheduledHours),
                })?.general ?? {
                    canRead: false,
                    canUpdate: false,
                    canCreate: false,
                    canDelete: false,
                },
            },
        },
        staffHoursPage: {
            header: {
                seeChangeLog: useContractChangeLogPermissions({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['staffHoursPage.header']?.seeChangeLog),
                }),
                export: useStaffHoursExportCSVPermissions({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['staffHoursPage.header']?.export),
                }),
                filters: useStaffHoursFiltersPermissions({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['staffHoursPage.header']?.filters),
                }),
            },
            content: {
                bankHours: useContractBankHoursPermissions({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['staffHoursPage.content']?.bankHours),
                }),
            },
        },
        staffHoursSettingsPage: {
            header: {
                exportHistoricalData: useStaffHoursHistoricalDownloadPermissions({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['staffHoursSettingsPage.header']?.exportHistoricalData),
                }),
                exactAttendanceOptIn: useStaffHoursExactAttendancePermission({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['staffHoursSettingsPage.header']?.exactAttendanceOptIn),
                }),
            },
            content: {},
        },
        staffLeave: {
            header: {},
            content: {},
        },

        inquiries: {
            header: {
                downloadCsv: useInquiriesDownloadCsvPermissions({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['inquiries.header']?.downloadCsv),
                }),
                newInquiry: useNewInquiriesPermissions({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['inquiries.header']?.newInquiry),
                }),
            },
            content: {},
        },
        forms: {
            header: {
                newForm: useNewFormPermissions({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['forms.header']?.newForm),
                }),
            },
            content: {},
        },
        future: {
            header: {},
            content: {},
        },

        occupancy: {
            header: {},
            content: {},
        },
        attendance: {
            header: {
                export: {
                    dailyTotals: useDailyTotalsPermissions({
                        ...payload,
                        shouldQuery: Boolean(shouldQuery['attendance.header.export']?.dailyTotals),
                    }),
                    detailedBreakdown: useDetailedBreakdownPermissions({
                        ...payload,
                        shouldQuery: Boolean(shouldQuery['attendance.header.export']?.detailedBreakdown),
                    }),
                },
            },
            content: {},
        },
        attendanceDetails: useAttendanceDetailsPermissions({
            ...payload,
            shouldQuery: Boolean(shouldQuery.attendanceDetails),
        }),

        staffLeaveSettings: {
            leave: {
                ranges: useLeaveSettingsRangePermissions(payload),
                subtypes: {
                    paid: useLeaveSettingsSubtypePaidPermissions({
                        ...payload,
                        shouldQuery: Boolean(shouldQuery['staffLeaveSettings.leave.subtypes']?.paid),
                    }),
                },
            },
        },

        staffScheduleSettings: {
            editStaffSchedule: useEditStaffSchedulePermissions(payload),
        },

        staffProfile: {
            scheduleWidget: useStaffProfileScheduleWidgetPermissions({
                ...payload,
                shouldQuery: Boolean(shouldQuery.staffProfile?.scheduleWidget),
            }),
            contract: {
                weeklyHours: useContractWeeklyHoursPermissions({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['staffProfile.contract']?.weeklyHours),
                }),
                workAvailability: useContractWorkAvailabilityPermissions({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['staffProfile.contract']?.workAvailability),
                }),
                bankHours: useContractBankHoursPermissions({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['staffProfile.contract']?.bankHours),
                }),
                changeLog: useContractChangeLogPermissions({
                    ...payload,
                    shouldQuery: Boolean(shouldQuery['staffProfile.contract']?.changeLog),
                }),
            },
        },

        workTags: useWorkTagsPermissions({...payload, shouldQuery: Boolean(shouldQuery.workTags)}),
    };
    return createProxy(result);
}

const unrestrictedCRUD: StatCrudPermission = {
    canCreate: true,
    canRead: true,
    canUpdate: true,
    canDelete: true,
};
