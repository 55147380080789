import React from 'react';

import {LeaveType} from 'web-app/react/leave-types/leave-type';
import {hasValue} from 'web-app/util/typescript';
import {type StatPermissionLeavesFragment} from 'web-app/react/staffing-attendance/graphql/fragment/__generated__/stat-permission-leaves.fragment.api-types';
import {type StatPermissionLeaveFragment} from 'web-app/react/staffing-attendance/graphql/fragment/__generated__/stat-permission-leave.fragment.api-types';

import {type ExtendedStatCrudPermissions} from './extended-stat-crud-permissions';
import {type StatPermissions} from './stat-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';
import {type UseStatPermissionPayload} from '..//use-stat-permissions';
import {useFetchLeavePermissions} from './queries/use-fetch-leaves-permissions';

/**
 * Do not use this query directly!
 */
export function useLeavePermissions(payload: UseStatPermissionPayload): StatPermissions['leaves'] & {loading: boolean} {
    const {institutionSetId, employeeId, shouldQuery} = payload;
    const {data: leavePermissionData, loading} = useFetchLeavePermissions({
        variables: {
            institutionSetId,
            employeeId: employeeId ?? '',
        },
        skip: !hasValue(employeeId) || !hasValue(institutionSetId) || !shouldQuery,
    });
    const permissionFragment: StatPermissionLeavesFragment | undefined =
        leavePermissionData?.staffhours.management.leaves;
    const sickLeaveCrudPermissions = useCrudPermissionAdapter(permissionFragment?.sick);
    const childSickLeaveCrudPermissions = useCrudPermissionAdapter(permissionFragment?.childSick);
    const vacationLeaveCrudPermissions = useCrudPermissionAdapter(permissionFragment?.holiday);
    const absentLeaveCrudPermissions = useCrudPermissionAdapter(permissionFragment?.absence);

    const absentManagementNotes = useCrudPermissionAdapter(permissionFragment?.absence.managementNotes);
    const sickManagementNotes = useCrudPermissionAdapter(permissionFragment?.sick.managementNotes);
    const vacationManagementNotes = useCrudPermissionAdapter(permissionFragment?.holiday.managementNotes);
    const childSickManagementNotes = useCrudPermissionAdapter(permissionFragment?.childSick.managementNotes);

    const sickLeaveAdditionalPermissions = useAdditionalLeavePermissionsAdapter(permissionFragment?.sick);
    const childSickLeaveAdditionalPermissions = useAdditionalLeavePermissionsAdapter(permissionFragment?.childSick);
    const vacationLeaveAdditionalPermissions = useAdditionalLeavePermissionsAdapter(permissionFragment?.holiday);
    const absentLeaveAdditionalPermissions = useAdditionalLeavePermissionsAdapter(permissionFragment?.absence);
    return React.useMemo(() => {
        return {
            loading,
            [LeaveType.Sick]: {
                managementNotes: sickManagementNotes,
                ...sickLeaveCrudPermissions,
                ...sickLeaveAdditionalPermissions,
            },
            [LeaveType.ChildSick]: {
                managementNotes: childSickManagementNotes,
                ...childSickLeaveCrudPermissions,
                ...childSickLeaveAdditionalPermissions,
            },
            [LeaveType.Vacation]: {
                managementNotes: vacationManagementNotes,
                ...vacationLeaveCrudPermissions,
                ...vacationLeaveAdditionalPermissions,
            },
            [LeaveType.Absent]: {
                managementNotes: absentManagementNotes,
                ...absentLeaveCrudPermissions,
                ...absentLeaveAdditionalPermissions,
            },
        };
    }, [
        loading,
        sickManagementNotes,
        sickLeaveCrudPermissions,
        sickLeaveAdditionalPermissions,
        childSickManagementNotes,
        childSickLeaveCrudPermissions,
        childSickLeaveAdditionalPermissions,
        vacationManagementNotes,
        vacationLeaveCrudPermissions,
        vacationLeaveAdditionalPermissions,
        absentManagementNotes,
        absentLeaveCrudPermissions,
        absentLeaveAdditionalPermissions,
    ]);
}

function useAdditionalLeavePermissionsAdapter(
    request?: StatPermissionLeaveFragment,
): Pick<ExtendedStatCrudPermissions<StatPermissionLeaveFragment>, 'canEditSubtype'> {
    return React.useMemo(() => {
        const {canEditSubtype} = request ?? {};
        return {
            canEditSubtype: canEditSubtype ?? false,
        };
    }, [request]);
}
