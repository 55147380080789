import {type CustomRegistrationFormManagementFlagsFragment} from 'web-app/react/staffing-attendance/graphql/fragment/__generated__/custom-registration-form-management-flags.fragment.api-types';

import {type StatPermissions} from './stat-permissions';
import {useCrudPermissionAdapter} from './use-crud-permission-adapter';

export const useGetDefaultManageFormPermissions = (
    result?: CustomRegistrationFormManagementFlagsFragment,
): StatPermissions['customRegistrationForms']['manageForm'] => {
    return {
        ...useCrudPermissionAdapter(result),
        name: {
            canCreate: false,
            canRead: true,
            canUpdate: Boolean(result?.canEditName),
            canDelete: false,
        },
        publish: {
            canCreate: false,
            canRead: true,
            canUpdate: Boolean(result?.canPublish),
            canDelete: false,
        },
        unpublish: {
            canCreate: false,
            canRead: true,
            canUpdate: Boolean(result?.canUnpublish),
            canDelete: false,
        },
        responses: {
            canCreate: false,
            canRead: Boolean(result?.canDownloadResponses),
            canUpdate: false,
            canDelete: false,
        },
        successMessage: {
            canCreate: false,
            canRead: true,
            canUpdate: Boolean(result?.canEditSuccessMessage),
            canDelete: false,
        },
        toggleNotifications: {
            canCreate: false,
            canRead: true,
            canUpdate: Boolean(result?.canToggleNotifications),
            canDelete: false,
        },
        share: {
            canCreate: false,
            canRead: false,
            canUpdate: Boolean(result?.canShare),
            canDelete: false,
        },
        duplicate: {
            canCreate: Boolean(result?.canDuplicate),
            canRead: false,
            canUpdate: false,
            canDelete: false,
        },
        manageSubmissionKeys: {
            canCreate: Boolean(result?.canManageSubmissionKeys),
            canRead: false,
            canUpdate: false,
            canDelete: false,
        },
        sendEmails: {
            canCreate: Boolean(result?.canSendEmails),
            canRead: false,
            canUpdate: false,
            canDelete: false,
        },
        viewResponses: {
            canRead: Boolean(result?.canViewResponses),
            canCreate: false,
            canUpdate: false,
            canDelete: false,
        },
    };
};
