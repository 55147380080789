import React from 'react';
import {type RouteComponentProps} from 'react-router-dom';
import {Box, Text, Stack} from 'modern-famly';
import {useDispatch} from 'react-redux';

import * as PrivateRouteSelectors from 'signin-app/components/private-route/selectors';
import * as LoginSelectors from 'signin-app/login/selectors';
import * as CheckinSelectors from 'signin-app/employee/check-in/selectors';
import translate from 'signin-app/helpers/translate';
import * as GroupSelectors from 'signin-app/groups/selectors';
import * as CheckoutSelectors from 'signin-app/child/check-out/selectors';
import Header from 'signin-app/components/header';
import {PinHeader} from 'signin-app/child/pin-header';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import {
    ConfirmButton,
    ActionButton as CancelButton,
    BottomBarContainer,
    PageBodyContainer,
    ConfirmButtonTypes,
} from 'signin-app/components/action-buttons';
import {UserOverview} from 'signin-app/components/user-overview';
import {isPinApp} from 'signin-app/login/selectors';
import RoutesMap from 'signin-app/routes/routes-map';
import {HeaderPerson} from 'signin-app/components/header-person';
import {Card, CardsContainer} from 'signin-app/groups/group-item';
import {useTypedSelector} from 'signin-app/components/hooks';
import PersonSelect from 'signin-app/components/person-select';
import * as WhitelabelSelectors from 'signin-app/whitelabel/selectors';
import * as ChildSelectors from 'signin-app/child/selectors';
import {hasValue} from 'web-app/util/typescript';
import {useQueryParams} from 'web-app/react/hooks/use-query-params';
import {PageContainer} from 'signin-app/components/containers';

import {resetLeave, selectGroup, checkInEmployee} from './actions';
import * as Selectors from './selectors';
import LeaveTime from './leave-time';

type EmployeeCheckInRouteProps = RouteComponentProps<{employeeId: string}>;

export const EmployeeCheckIn: React.FC<EmployeeCheckInRouteProps> = props => {
    const accessToken = useTypedSelector(LoginSelectors.accessToken);
    const isFetching = useTypedSelector(PrivateRouteSelectors.isPrefetching);
    const employee = useTypedSelector(state => CheckinSelectors.employee(state, props));
    const selectedHours = useTypedSelector(CheckinSelectors.selectedHours);
    const selectedMinutes = useTypedSelector(CheckinSelectors.selectedMinutes);
    const canShowRooms = useTypedSelector(Selectors.canShowRooms);
    const selectedGroupId = useTypedSelector(CheckinSelectors.selectedGroupId);
    const groups = useTypedSelector(GroupSelectors.groups);
    const isCheckingIn = useTypedSelector(CheckinSelectors.isCheckingIn);
    const canCheckIn = useTypedSelector(CheckinSelectors.canCheckIn);
    const limitedData = useTypedSelector(CheckoutSelectors.limitedData);
    const employeeEstimatedCheckoutTimeEnabled = useTypedSelector(GroupSelectors.employeeEstimatedCheckoutTimeEnabled);
    const isPin = useTypedSelector(isPinApp);
    const whitelabelConfiguration = useTypedSelector(WhitelabelSelectors.whiteLabelConfiguration);
    const pinChildren = useTypedSelector(ChildSelectors.pinChildren);

    const dispatch = useDispatch();
    const {update} = useQueryParams(['update']);
    const isUpdatePage = hasValue(update);

    React.useEffect(() => {
        if (employee && !employee.checkedIn) {
            dispatch(resetLeave.action());
        }
        if (employee && (employee.groupId || employee.tempGroupId)) {
            const currentEmployeeGroupId = employee.tempGroupId ? employee.tempGroupId : employee.groupId;
            dispatch(selectGroup.action(currentEmployeeGroupId));
        }
    }, [dispatch, employee]);

    const handleEmployeeCheckIn = () => {
        if (employee) {
            const successMessage = translate(employee.checkedIn ? 'updateSignInSuccess' : 'signInSuccess', {
                personName: employee.name.fullName,
            });

            dispatch(
                checkInEmployee.action(
                    accessToken,
                    employee.id,
                    selectedHours,
                    selectedMinutes,
                    selectedGroupId,
                    successMessage,
                    employee.checkedIn,
                ),
            );
        }
    };
    const employeeName = React.useMemo(() => {
        return limitedData ? employee?.name.firstName : employee?.name.fullName;
    }, [employee, limitedData]);

    const initials = React.useMemo(() => {
        return `${employee?.name.firstName.charAt(0)}${employee?.name.lastName.charAt(0)}`;
    }, [employee]);

    const employeeInfo = React.useMemo(() => {
        if (!employee || !employeeName) {
            return null;
        }
        return <HeaderPerson image={employee?.image.small} initials={initials} name={employeeName} />;
    }, [employee, initials, employeeName]);

    // Show employee info header only if PersonSelect will not be shown
    const shouldShowEmployeeInfoOnPin = React.useMemo(() => {
        if (!isPin || (pinChildren && pinChildren.size > 0)) {
            return false;
        }
        return true;
    }, [isPin, pinChildren]);

    const cancelButtonLink = React.useMemo(() => {
        if (isUpdatePage) {
            return `/employee/${employee?.id}/checkout`;
        }

        return RoutesMap.overview;
    }, [isUpdatePage, employee?.id]);

    return (
        <PageContainer>
            {isPin ? (
                <PinHeader whitelabelConfiguration={whitelabelConfiguration} />
            ) : (
                <Header centerElement={employeeInfo} />
            )}
            {isFetching || !employee ? (
                <Spinner color="white" centered />
            ) : (
                <>
                    <PageBodyContainer mt={16}>
                        {shouldShowEmployeeInfoOnPin ? (
                            <Stack justifyContent="center" mb={14}>
                                {employeeInfo}
                            </Stack>
                        ) : null}
                        <PersonSelect activeEmployeeId={employee.id} />
                        {employeeEstimatedCheckoutTimeEnabled ? (
                            <>
                                <LeaveTime
                                    checkedIn={employee.checkedIn}
                                    selectedLeaveTime={employee.estimatedCheckoutTime}
                                />
                                {canShowRooms && !isUpdatePage && (
                                    <Box mt={12}>
                                        <Text variant="h6" color="n0" emphasized data-e2e-id="signin-room-title">
                                            {translate('whichRoomToSignInto')}
                                        </Text>
                                        <CardsContainer mt={2}>
                                            {groups.map(group => (
                                                <Card
                                                    key={group.groupId}
                                                    image={group.image.small}
                                                    displayTitle={group.title}
                                                    selected={group.groupId === selectedGroupId}
                                                    onClick={() => dispatch(selectGroup.action(group.groupId))}
                                                    dataE2eCardContainerClass={`group-title-${group.title}`}
                                                />
                                            ))}
                                        </CardsContainer>
                                    </Box>
                                )}
                            </>
                        ) : (
                            <UserOverview image={employee.image.large} name={employee.name.fullName} />
                        )}
                    </PageBodyContainer>
                    <BottomBarContainer>
                        {!isPin ? <CancelButton text={translate('cancel')} link={cancelButtonLink} /> : null}
                        <ConfirmButton
                            type={ConfirmButtonTypes.SIGN_IN}
                            disabled={!canCheckIn}
                            isLoading={isCheckingIn}
                            onClick={handleEmployeeCheckIn}
                            text={translate(employee.checkedIn ? 'updateLeavingTime' : 'signIn')}
                            dataE2eCheckinConfirmButton="employee-check-in-confirm-button"
                            isOnlyButton={isPin}
                        />
                    </BottomBarContainer>
                </>
            )}
        </PageContainer>
    );
};
