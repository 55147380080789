import {Record, List} from 'immutable';

import {makeReducer, match} from 'web-app/util/redux';
import type Image from 'web-app/react/entities/common/image';
import BehaviorsCollection from 'web-app/behaviors/behaviors-collection';

import * as Actions from './actions';

interface GroupImage {
    small: string;
    large: string;
    empty: boolean;
}

export interface Group {
    color: string;
    department: object;
    departmentId: string;
    groupId: string;
    image: GroupImage;
    info: string;
    institutionId: string;
    noCheckedInChildren: number;
    noCheckedInEmployees: number;
    noChildren: number;
    noEmployees: number;
    noSickChildren: number;
    noVacationChildren: number;
    phone: object;
    requirePickupInfo: boolean;
    title: string;
}

interface ContactPerson {
    firstName: string;
    fullName: string;
    lastName: string;
    middleName: string;
}

export interface Institution {
    address: object;
    billingContact: string;
    billingEmail: object;
    contactPerson: ContactPerson;
    country: string;
    createdTime: string;
    ean: string;
    email: string;
    famlyId: string;
    institutionId: string;
    institutionType: number;
    locale: string;
    organizationId: string;
    phone: string;
    shortName: string;
    signedAt: object;
    timezone: string;
    title: string;
    vat: string;
    image: typeof Image.default;
}

export interface OpeningHours {
    hour: number;
    minutes: number[];
}

export interface InstitutionOverview {
    employeeEstimatedCheckoutTimeEnabled: boolean;
    features: string[];
    groups: Group[];
    labels: {[x: string]: string};
    openingHours: OpeningHours[];
    institution?: Institution;
    hiddenGroupIds: List<string>;
    behaviors: BehaviorsCollection;
}

export class InstitutionOverviewState extends Record<InstitutionOverview>({
    employeeEstimatedCheckoutTimeEnabled: false,
    features: [],
    groups: [],
    institution: undefined,
    labels: {},
    openingHours: [],
    hiddenGroupIds: List(),
    behaviors: new BehaviorsCollection([]),
}) {}

export default makeReducer<InstitutionOverviewState>(
    [
        match(Actions.fetchOverviewSuccess, (state, {payload}) => {
            return state.merge(payload.institutionOverview);
        }),
        match(Actions.updateHiddenGroups, (state, {payload}) => {
            return state.merge({
                hiddenGroupIds: payload.hiddenGroupIds,
            });
        }),
    ],
    new InstitutionOverviewState(),
);
