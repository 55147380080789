import React from 'react';
import Box from '@mui/material/Box';
import {type PaperProps} from '@mui/material/Paper';
import SwipeableDrawer, {type SwipeableDrawerProps} from '@mui/material/SwipeableDrawer';
import MuiDrawer, {type DrawerProps as MuiDrawerProps} from '@mui/material/Drawer';
import {type Theme, type Components} from '@mui/material/styles';

import {useDataProps} from 'modern-famly/components/util';
import {useBreakpoints} from 'modern-famly/theming';

type InputProps = {
    /**
     * Height value for the Drawer container (Defaults to '90vh')
     */
    height?: string;
    disablePortal?: boolean;
};

/**
 * A type defining all possible borderRadius options (All default to '0px')
 */
type BorderRadiusProps = {
    borderTopLeftRadius?: string;
    borderTopRightRadius?: string;
    borderBottomLeftRadius?: string;
    borderBottomRightRadius?: string;
};

/**
 * Props shared for all the Drawers
 */
type SharedDrawerProps = InputProps & Pick<SwipeableDrawerProps, 'open' | 'onOpen' | 'onClose'>;

/**
 * Props for Drawer only
 */
type InnerDrawerProps = SharedDrawerProps & BorderRadiusProps & Pick<SwipeableDrawerProps, 'anchor'>;

const InnerDrawer: React.FC<React.PropsWithChildren<InnerDrawerProps>> = ({
    children,
    onOpen,
    onClose,
    anchor = 'bottom',
    open,
    height,
    borderBottomLeftRadius = 0,
    borderBottomRightRadius = 0,
    borderTopLeftRadius = 0,
    borderTopRightRadius = 0,
    disablePortal,
    ...rest
}) => {
    const dataProps = useDataProps(rest);

    const {isTabletPortraitAndLarger, isTabletLandscapeAndLarger} = useBreakpoints();

    /**
     * Ensures that the drawer doesn't take up full width on tablets.
     * Note that the order of the if statements matter!
     */
    const maxWidth = React.useMemo(() => {
        if (isTabletLandscapeAndLarger) {
            return '70%';
        }

        if (isTabletPortraitAndLarger) {
            return '80%';
        }

        return '100%';
    }, [isTabletPortraitAndLarger, isTabletLandscapeAndLarger]);

    const PaperProps: PaperProps = React.useMemo(
        () => ({
            sx: {
                height: height || '100vh',
                borderBottomLeftRadius,
                borderBottomRightRadius,
                borderTopLeftRadius,
                borderTopRightRadius,
                paddingBottom: 'env(safe-area-inset-bottom)',
                maxWidth,
                margin: '0 auto',
            },
        }),
        [height, borderBottomLeftRadius, borderBottomRightRadius, borderTopLeftRadius, borderTopRightRadius, maxWidth],
    );

    return (
        <SwipeableDrawer
            anchor={anchor}
            open={open}
            onClose={onClose}
            onOpen={onOpen}
            disablePortal={disablePortal}
            PaperProps={PaperProps}
            {...dataProps}
        >
            {children}
        </SwipeableDrawer>
    );
};

const Puller = () => {
    return (
        <Box
            sx={theme => {
                return {
                    width: 60,
                    height: 6,
                    backgroundColor: theme.modernFamlyTheme.colorPalette.n300,
                    borderRadius: 3,
                    position: 'absolute',
                    top: 8,
                    left: 'calc(50% - 30px)',
                };
            }}
        />
    );
};

/**
 * Props for the BottomDrawer only
 */
export type SheetProps = Omit<SharedDrawerProps, 'anchor'> & {
    /**
     * A flag indicating whether to show a 'Puller' visual or not (Defaults to true).
     * This might be useful when we want to show a Drawer on desktop but hide the puller (as it does not make sense to have it).
     */
    showPuller?: boolean;

    /**
     * Border radius for the drawer (Defaults to 16px)
     */
    borderRadius?: string;

    /**
     * Height of the drawer. Defaults to 90vh
     */
    height?: string;
    disablePortal?: boolean;
};

export const Sheet: React.FC<React.PropsWithChildren<SheetProps>> = ({
    showPuller = true,
    children,
    height = '90vh',
    borderRadius = '16px',
    disablePortal = false,
    ...rest
}) => {
    return (
        <InnerDrawer
            height={height}
            {...rest}
            anchor="bottom"
            borderTopLeftRadius={borderRadius}
            borderTopRightRadius={borderRadius}
            disablePortal={disablePortal}
        >
            {showPuller ? <Puller /> : null}
            {children}
        </InnerDrawer>
    );
};

/**
 * MUI Theming
 */
export const DrawerThemeConfiguration: Components<Theme>['MuiDrawer'] = {
    defaultProps: {
        anchor: 'bottom',
        open: false,
    },
};

export type DrawerProps = Pick<MuiDrawerProps, 'open' | 'onClose' | 'variant'>;

export const Drawer: React.FC<React.PropsWithChildren<DrawerProps>> = ({open, ...rest}) => {
    const dataProps = useDataProps(rest);

    return <MuiDrawer open={open} {...rest} {...dataProps} anchor="right" />;
};
