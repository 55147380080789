import React from 'react';
import {useDispatch} from 'react-redux';
import i18next from 'i18next';
import {Stack, Box, Button, DropdownButton, Text} from 'modern-famly';

import {logOut} from 'signin-app/login/actions';
import {useModal} from 'web-app/react/modal-context/modal-context';
import {ModalHeader, ModalTopBar, ModalBody} from 'web-app/react/components/modals/modals';
import translate from 'signin-app/helpers/translate';

export const SettingsDropdown: React.FC<{
    showWarning?: boolean;
    siteName?: string;
    settings?: {label: string; onClick: VoidFunction}[];
}> = props => {
    const dispatch = useDispatch();

    const {show, hide} = useModal({
        component: WarningModal,
    });

    const handleLogout = React.useCallback(() => dispatch(logOut.action()), [dispatch]);

    const handleClick = React.useCallback(() => {
        if (props.showWarning) {
            show({
                onModalClose: hide,
                onConfirm: () => {
                    hide();
                    handleLogout();
                },
                siteName: props.siteName ?? '',
            });
        } else {
            handleLogout();
        }
    }, [handleLogout, hide, show, props]);

    const options = React.useMemo(() => {
        const logout = {
            label: translate('adminLogout'),
            onClick: handleClick,
            ['data-e2e-id']: 'logout-button',
        };
        return props.settings ? [...props.settings, logout] : [logout];
    }, [props.settings, handleClick]);

    return (
        <DropdownButton
            variant="tertiary"
            icon="more_horiz"
            aria-label={translate('settings')}
            data-e2e-id="settings-button"
            options={options}
        />
    );
};

export const WarningModal: React.FC<{onModalClose: VoidFunction; onConfirm: VoidFunction; siteName: string}> = ({
    onModalClose,
    onConfirm,
    siteName,
}) => {
    return (
        <Box pb={6} pt={6} pr={2} pl={2} data-e2e-id="logout-warning-modal">
            <ModalTopBar onCloseModal={onModalClose}>
                <ModalHeader
                    header={translate('logoutConfirmationTitle', {
                        siteName,
                    })}
                />
                <div></div>
            </ModalTopBar>
            <ModalBody>
                <Text variant="body" mt={4}>
                    {translate('logoutConfirmationDescription', {
                        siteName,
                    })}
                </Text>
            </ModalBody>
            <Stack gap={4} pr={4} mt={4} justifyContent="flex-end">
                <Button variant="tertiary" text={i18next.t('cancel')} onClick={onModalClose} />
                <Button
                    variant="primary"
                    text={translate('logoutConfirmationButton')}
                    onClick={onConfirm}
                    data-e2e-id="confirm-logout-button"
                />
            </Stack>
        </Box>
    );
};
