import {gql} from '@apollo/client';

import {StatPermissionSubTypePaidManagement} from '../fragment/stat-permission-subtype-paid.fragment';
import {StatPermissionSubTypeManagement} from '../fragment/stat-permission-subtype.fragment';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchSubtypesPermissions = gql`
    query FetchSubtypesPermissions($institutionSetId: InstitutionSetId!) {
        staffhours {
            management {
                subtypes(siteSetId: $institutionSetId) {
                    ...StatPermissionSubTypeManagement
                }
            }
        }
    }
    ${StatPermissionSubTypeManagement}
    ${StatPermissionSubTypePaidManagement}
`;
