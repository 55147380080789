import {createBox} from 'modern-famly';
import styled from 'styled-components';

export const PageContainer = createBox({padding: '40px 40px 120px'});

export const HeaderLogoContainer = styled(createBox({}))`
    * {
        max-height: 32px;
    }
    img {
        width: auto;
    }
`;
