import {createAction} from 'signin-app/redux/helpers';
import {type PinLoginResponse} from 'signin-app/pin/models';

interface UpdatedChildProps {
    response: {
        autoCheckedOut: boolean;
        checkinLoginId: string;
        checkinTime: string;
        checkoutLoginId: string;
        checkoutTime: string;
        childCheckinId: string;
        childId: string;
        deletedAt: string | null;
        goHomeWithChildId: string;
        groupId: string;
        hours: number | null;
        institutionId: string;
        pickupRelationId: string;
        pickupTime: string;
    };
    pickupName?: string;
}

interface UpdatedEmployeeProps {
    response: {
        autoCheckedOut: boolean;
        checkinStatements: any;
        checkinTime: string | null;
        checkoutTime: string | null;
        deletedAt: string | null;
        employeeCheckinId: string;
        employeeId: string;
        estimatedCheckoutTime: string | null;
        groupId: string;
        institutionId: string;
    };
}

const domain = 'PIN';

export const {action: setPinValue} = createAction(`${domain}/SET_PIN_VALUE`, (newValue: string) => ({newValue}));

export const {action: pinLogout} = createAction(`${domain}/PIN_LOGOUT`, () => ({}));

export const {action: updateChild} = createAction(`${domain}/UPDATE_CHILD`, (updatedChildProps: UpdatedChildProps) => ({
    updatedChildProps,
}));

export const {action: updateEmployee} = createAction(
    `${domain}/UPDATE_EMPLOYEE`,
    (updatedEmployeeProps: UpdatedEmployeeProps) => ({
        updatedEmployeeProps,
    }),
);

export const {
    action: pinLogin,
    success: pinLoginSuccess,
    failed: pinLoginFailed,
} = createAction(
    `${domain}/PIN_LOGIN`,
    (pin: string) => ({pin}),
    (response: PinLoginResponse) => ({response}),
);
