import {gql} from '@apollo/client';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchInquiriesPermissions = gql`
    query FetchInquiriesPermissions($siteSetId: SiteSetId!) {
        attendance {
            management {
                orgInquiries(siteSetIds: [$siteSetId]) {
                    siteId
                    canCreate
                    canView
                    canUpdate
                    canDelete
                    canEnrollChildren
                    canMoveInquiriesOut
                    canCreateActions
                    canViewActions
                    canUpdateActions
                    canDeleteActions
                }
            }
        }
    }
`;
