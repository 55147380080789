import gql from 'graphql-tag';

export const CustomRegistrationFormDescription = gql`
    fragment CustomRegistrationFormDescription on CustomRegistrationFormDescription {
        formId
        formName
        formType
        isPublished
        isShared
        lastEditedAt
        lastEditedBy
        lastEditedByName
        numberOfSitesSharedWith
        siteIds
    }
`;
