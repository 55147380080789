import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18next from 'i18next';

// Inspired by https://github.com/JedWatson/react-select/blob/v1.2.1/src/utils/defaultMenuRenderer.js

const getMoreResultOption = () => ({value: null, disabled: true, label: i18next.t('moreResultsAvailable')});

const MoreResultsOptionRender = props => {
    const {optionClassName, optionComponent, instancePrefix, optionRenderer, optionIndex} = props;
    const Option = optionComponent;
    const option = getMoreResultOption();

    const optionClass = classNames(optionClassName, {
        'Select-option': true,
        'is-disabled': option.disabled,
    });

    return (
        <Option
            className={optionClass}
            instancePrefix={instancePrefix}
            isDisabled={option.disabled}
            isFocused={false}
            isSelected={false}
            key="moreResultsOption"
            option={option}
            optionIndex={optionIndex}
        >
            {optionRenderer(option, optionIndex, '')}
        </Option>
    );
};

MoreResultsOptionRender.propTypes = {
    optionIndex: PropTypes.number,
    instancePrefix: PropTypes.string.isRequired,
    optionClassName: PropTypes.string,
    optionComponent: PropTypes.func,
    optionRenderer: PropTypes.any,
};

const MenuRenderer = props => {
    const {
        focusedOption,
        focusOption,
        inputValue,
        instancePrefix,
        onFocus,
        onOptionRef,
        onSelect,
        optionClassName,
        optionComponent,
        optionRenderer,
        options,
        removeValue,
        selectValue,
        valueArray,
        valueKey,
        optimized,
        internalised,
        hideMoreLabel,
    } = props;

    const Option = optionComponent;

    const slicedOptions = !optimized && !internalised ? options : options.slice(0, 50);
    const renderedOptions = slicedOptions.map((option, i) => {
        const isSelected = valueArray && valueArray.some(x => x[valueKey] === option[valueKey]);
        const isFocused = option === focusedOption;
        const optionClass = classNames(optionClassName, {
            'Select-option': true,
            'is-selected': isSelected,
            'is-focused': isFocused,
            'is-disabled': option.disabled,
        });

        return (
            <Option
                className={optionClass}
                focusOption={focusOption}
                inputValue={inputValue}
                instancePrefix={instancePrefix}
                isDisabled={option.disabled}
                isFocused={isFocused}
                isSelected={isSelected}
                key={`option-${i}-${option[valueKey]}`}
                onFocus={onFocus}
                onSelect={onSelect}
                option={option}
                optionIndex={i}
                ref={ref => {
                    onOptionRef(ref, isFocused);
                }}
                removeValue={removeValue}
                selectValue={selectValue}
            >
                {optionRenderer(option, i, inputValue)}
            </Option>
        );
    });
    return (
        <React.Fragment>
            {renderedOptions}
            {options.length > 50 && !hideMoreLabel ? (
                <MoreResultsOptionRender
                    optionClassName={optionClassName}
                    optionComponent={optionComponent}
                    instancePrefix={instancePrefix}
                    optionRenderer={optionRenderer}
                    optionIndex={options.length}
                />
            ) : null}
        </React.Fragment>
    );
};

MenuRenderer.propTypes = {
    focusOption: PropTypes.func,
    focusedOption: PropTypes.object,
    inputValue: PropTypes.string,
    instancePrefix: PropTypes.string,
    onFocus: PropTypes.func,
    onOptionRef: PropTypes.func,
    onSelect: PropTypes.func,
    optionClassName: PropTypes.string,
    optionComponent: PropTypes.func,
    optionRenderer: PropTypes.func,
    options: PropTypes.array,
    removeValue: PropTypes.func,
    selectValue: PropTypes.func,
    valueArray: PropTypes.array,
    valueKey: PropTypes.string,
    optimized: PropTypes.bool,
    internalised: PropTypes.bool,
    hideMoreLabel: PropTypes.bool,
};

export default MenuRenderer;
