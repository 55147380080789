import gql from 'graphql-tag';

export const CustomRegistrationFormSection = gql`
    fragment CustomRegistrationFormSection on CustomRegistrationFormSection {
        id
        name
        description
        sectionType
        fields {
            ...CustomRegistrationFormField
        }
    }
`;
