import gql from 'graphql-tag';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchDetailedBreakdownPermissions = gql`
    query FetchDetailedBreakdownPermissions($siteSetId: SiteSetId!) {
        staffhours {
            management {
                attendance(siteSetId: $siteSetId) {
                    detailedCsvExportURL {
                        canView
                    }
                }
            }
        }
    }
`;
