import React from 'react';
import MuiChip, {type ChipProps as MuiChipProps} from '@mui/material/Chip';
import {type Components, type ComponentsPropsList, styled, type Theme} from '@mui/material/styles';

import {Icon, type IconName} from 'modern-famly/components/data-display/icon';
import {type AvatarProps} from 'modern-famly/components/data-display/avatar';
import {useDataProps} from 'modern-famly/components/util';

export type PillProps = {
    /**
     * The text of the component.
     */
    text: string;

    /**
     * The size of the component.
     *
     * @default 'regular'
     */
    size?: 'compact' | 'regular';

    /**
     * The click handler of the component.
     */
    onClick?: MuiChipProps['onClick'];

    /**
     * Callback fired when the delete icon is clicked. If set, the delete icon will be shown.
     */
    onDelete?: MuiChipProps['onDelete'];

    /**
     * If `true`, the component is disabled.
     *
     * @default false
     */
    disabled?: MuiChipProps['disabled'];

    /**
     * The icon to display.
     */
    icon?: IconName;

    /**
     * The Avatar element to display. Takes precedence over `icon` if both are given.
     */
    avatar?: MuiChipProps['avatar'];

    /**
     * If `true` the component appears selected
     */
    selected?: boolean;
};

const DeleteButton = styled('button')`
    margin: 0 !important;
    padding: 0 !important;
    background: transparent !important;
    border: none !important;
    color: inherit !important;
    height: 20px;
    width: 20px;
    font-size: unset !important;
`;

export const PillClassName = 'MFPill-root';

export const Pill = ({
    size = 'regular',
    onClick,
    text,
    onDelete,
    disabled,
    icon,
    avatar,
    selected = false,
    ...props
}: PillProps) => {
    // We clone the `avatar` element passed into the `Pill` component so we can control its `size` prop. This seems like
    // a bit of a hack, but it's what MUI does internally to add additional props to passed in elements.
    const avatarComponent = React.useMemo(() => {
        return avatar && React.isValidElement<AvatarProps>(avatar) ? React.cloneElement(avatar, {size: 20}) : undefined;
    }, [avatar]);

    const dataProps = useDataProps(props);

    return (
        <MuiChip
            className={PillClassName}
            avatar={avatarComponent}
            label={text}
            size={size}
            clickable={false}
            onClick={onClick}
            onDelete={onDelete}
            disabled={disabled}
            deleteIcon={
                <DeleteButton type="button" onClick={onDelete} data-e2e-class="delete-pill">
                    <Icon name="close" size={20} />
                </DeleteButton>
            }
            // Ensure that the `avatar` prop takes precedence over the `icon` prop
            icon={icon && !avatarComponent ? <Icon name={icon} size={18} data-e2e-class="pill-icon" /> : undefined}
            sx={
                selected
                    ? theme => ({
                          background: theme.modernFamlyTheme.colorPalette.p400,
                          color: theme.modernFamlyTheme.colorPalette.n0,
                          '&:hover': {
                              background: theme.modernFamlyTheme.colorPalette.p400,
                              color: theme.modernFamlyTheme.colorPalette.n0,
                          },
                      })
                    : undefined
            }
            {...dataProps}
        />
    );
};

/*
|------------------------------------------------------------------------------
| MUI Theming
|------------------------------------------------------------------------------
*/
declare module '@mui/material/Chip' {
    export interface ChipPropsSizeOverrides {
        medium: false;
        small: false;

        compact: true;
        regular: true;
    }
}

export const ChipThemeConfiguration: Components<Theme>['MuiChip'] = {
    defaultProps: {
        size: 'regular',
    },
    styleOverrides: {
        root: ({theme, ownerState}) => ({
            transitionDuration: '200ms',
            transitionProperty: 'color, background',
            borderRadius: '24px',
            background: theme.modernFamlyTheme.colorPalette.p100,
            color: theme.modernFamlyTheme.colorPalette.p500,
            height: ownerState.size === 'compact' ? '24px' : '36px',
            padding: theme.modernFamlyTheme.spacing(0),
            paddingRight: theme.modernFamlyTheme.spacing(getPaddingRight(ownerState)),
            paddingLeft: theme.modernFamlyTheme.spacing(getPaddingLeft(ownerState)),
            fontSize: theme.typography['body-small'].fontSize,
            lineHeight: theme.typography['body-small'].lineHeight,
            cursor: ownerState.disabled || !ownerState.onClick ? 'unset' : 'pointer',
            '&:hover': ownerState.onClick
                ? {
                      background: theme.modernFamlyTheme.colorPalette.p200,
                  }
                : undefined,
            '&.Mui-disabled': {
                opacity: '1',
                background: theme.modernFamlyTheme.colorPalette.n75,
                '& *': {
                    color: theme.modernFamlyTheme.colorPalette.n200,
                },
                '& .MuiAvatar-root': {
                    opacity: '0.3',
                },
            },
        }),
        label: ({ownerState}) => ({
            paddingLeft: Boolean(ownerState.icon) || Boolean(ownerState.avatar) ? '4px' : '0',
            paddingRight: ownerState.onDelete ? '4px' : 0,
        }),
    },
};

type OwnerState = ComponentsPropsList['MuiChip'] & Record<string, unknown>;

const getPaddingLeft = (ownerState: OwnerState) => {
    switch (true) {
        case ownerState.size === 'compact' && Boolean(ownerState.avatar):
            return 0.5;
        case ownerState.size === 'compact' && Boolean(ownerState.icon):
        case ownerState.size === 'regular' && Boolean(ownerState.avatar):
        case ownerState.size === 'regular' && Boolean(ownerState.icon):
            return 2;
        default:
            return 3;
    }
};

const getPaddingRight = (ownerState: OwnerState) => {
    switch (true) {
        case ownerState.size === 'compact' && Boolean(ownerState.onDelete):
            return 1;
        case ownerState.size === 'regular' && Boolean(ownerState.onDelete):
            return 2;
        default:
            return 3;
    }
};
