import deepEqual from 'deep-equal';
import {useState, useRef, useEffect} from 'react';
import {fromEvent} from 'rxjs';
import {share, debounceTime} from 'rxjs/operators';

import * as Breakpoints from 'web-app/styleguide/breakpoint-helpers';
import {hasValue} from 'web-app/util/typescript';

// Create a single event listener that hooks can subscribe to.
const resizeEvent = fromEvent(window, 'resize').pipe(share(), debounceTime(250));

const makeBreakpoints = () => ({
    isMobileLandscapeAndLarger: Breakpoints.isMobileLandscapeAndLarger(),
    isTabletPortraitAndLarger: Breakpoints.isTabletPortraitAndLarger(),
    isTabletLandscapeAndLarger: Breakpoints.isTabletLandscapeAndLarger(),
    isLaptopAndLarger: Breakpoints.isLaptopAndLarger(),
    customAtLeastOneLargerThanLandscape: Breakpoints.isCustomAtLeastOneLargerThanLandscape(),
});

/**
 * Access the current breakpoint state. Updates with a debounce of 250ms.
 *
 * @deprecated Use `useBreakpoints` from 'modern-famly' instead. Only use this if you _really_ need the `customAtLeastOneLargerThanLandscape` breakpoint.
 *
 * Usage
 * ```
 * const {isLaptopAndLarger} = useBreakpoints();
 * ```
 */
export const useBreakpoints = () => {
    const [breakpoints, setBreakpoints] = useState(() => makeBreakpoints());

    const resizeSubscription = useRef<null | ReturnType<typeof resizeEvent.subscribe>>(null);

    useEffect(() => {
        if (!hasValue(resizeSubscription.current)) {
            resizeSubscription.current = resizeEvent.subscribe(() => {
                // Only update state if breakpoints actually change to avoid doing no-op updates
                setBreakpoints(breakpoints => {
                    const newBreakpoints = makeBreakpoints();
                    if (deepEqual(breakpoints, newBreakpoints)) {
                        return breakpoints;
                    }
                    return newBreakpoints;
                });
            });
        }

        // We need to reference the current ref here instead of accessing it
        // directly in the clean up callback otherwise the value might have changed
        // when the callback is called.
        const subscription = resizeSubscription.current;
        return () => subscription.unsubscribe();
    }, [setBreakpoints]);

    return breakpoints;
};
