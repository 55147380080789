import {ofType} from 'redux-observable';
import {merge, of} from 'rxjs';
import {mergeMap, map, catchError} from 'rxjs/operators';

import {combineEpics} from 'web-app/util/redux-observable';
import * as GlobalEventActions from 'signin-app/global-event/actions';
import {type Action} from 'web-app/util/redux/action-types';
import {type RootState} from 'signin-app/redux/main-reducer';
import {type SignInEpic} from 'signin-app/redux/types';
import ChildApi from 'signin-app/api/child-api';

import * as Actions from './actions';

const goHomeWithChildren: SignInEpic = action$ =>
    action$.pipe(
        ofType(Actions.fetchGoHomeWithChildren.type),
        mergeMap(({payload}: ReturnType<typeof Actions.fetchGoHomeWithChildren.action>) =>
            ChildApi.goHomeWithChildren(payload.childId).pipe(
                map(groups => Actions.fetchGoHomeWithChildrenSuccess.action(groups)),
                catchError(e =>
                    merge(
                        of(Actions.fetchGoHomeWithChildrenFailed.action()),
                        of(GlobalEventActions.updateError.action(e.error)),
                    ),
                ),
            ),
        ),
    );

export default combineEpics<Action<any>, Action<any>, RootState>(goHomeWithChildren);
