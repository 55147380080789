import {createGlobalStyle} from 'styled-components';

export const CLASSNAME_PREFIX = 'SELECT_STYLE_';
const selectHeight = '26px';
export default createGlobalStyle`

    .${CLASSNAME_PREFIX}container {
        position: relative;
        width: 100%;
    }

    .${CLASSNAME_PREFIX}.placeholder {
        color: #999;
    }

    .${CLASSNAME_PREFIX}Select {
        .Select-menu-outer {
            z-index: 100;
        }
        &--expand {
            .Select-menu-outer {
                min-width: 100%;
                width: auto;
            }
        }
        &--extended {
            .Select-menu-outer {
                max-height: 525px;
            }

            .Select-menu {
                max-height: 520px;
            }
        }
        &--tall.has-value {
            .Select-placeholder {
                line-height: 45px;
            }

            .Select-input {
                height: 45px;
                line-height: 45px;
            }
        }

        .Select-control {
            height: auto;
            border-radius: 0;
            color: #000;
        }

        .Select-option {
            padding: 0;

            border-bottom: #e4e4e4 1px solid;

            &.is-focused {
                background: #f4f5f6;

                @media all and (max-width: 799px) {
                    background: #ffffff;
                }
            }
        }

        .Select-placeholder,
        &.Select--single > .Select-control .Select-value {
            line-height: ${selectHeight};
        }

        .Select-placeholder {
            color: #999;
        }

        .Select-input {
            height: ${selectHeight};
        }

        .Select-clear {
            display: block;
        }

        &--multi .Select-value {
            color: #000;
            background-color: #f4f5f6;
            border-color: #e5e6e9 #dfe0e4 #d3d4d6;
            margin: 2px;
        }

        &--multi .Select-value-icon {
            color: #a6b3cf;
            padding: 5px 7px 5px;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-right: 1px solid #e5e6e9;

            &:hover {
                color: #000;
                background-color: #dfe0e4;
            }
        }

        &--single {
            .Select-control {
                display: flex;
                align-items: center;
            }

            .Select-arrow-zone {
                margin-top: 4px;
            }

            .Select-multi-value-wrapper {
                flex: 1 1 0%;
            }

            .Select-input {
                padding-left: 5px;

                > input {
                    line-height: ${selectHeight};
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }

        &--multi .Select-value-label {
            white-space: nowrap;
            padding: 5px 7px;
        }

        &--multi.Select.has-value.Select-multi-value-wrapper {
            margin-bottom: 5px;
            padding-top: 2px;
        }

        .Select-loading-zone {
            display: none;
        }

        .Select-value-label {
            :local {
                .selectOption {
                    padding: 7px 0;
                }
            }
        }

        &--appearance {
            &--simple {
                .Select-control {
                    border: none;
                    &:hover {
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .${CLASSNAME_PREFIX}arrow {
        height: 8px;
        width: 12px;
    }

    .${CLASSNAME_PREFIX}selectOption,
    .${CLASSNAME_PREFIX}dropdownOption {
        padding: 10px 9px;
        font-size: ${props => props.theme.fontConfiguration.sizes.Caption};

        &__avatar {
            display: block;
            float: left;
            width: 30px;
            height: 30px;
            margin-right: 16px;
            pointer-events: none;
            flex-shrink: 0;
        }

        &__title {
            line-height: 1.3;
        }

        &__subtitle {
            color: #999;
            line-height: 1.2;
            padding-top: 1px;
        }
    }

    .${CLASSNAME_PREFIX}selectOption {
        &__title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__subtitle {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .${CLASSNAME_PREFIX}dropdownOption {
        display: flex;
        align-items: center;
        padding: 0;
        color: #333333;

        &__avatar {
            float: none;
            width: 22px;
            height: 22px;
            margin-right: 10px;
            box-sizing: border-box;
            flex-shrink: 0;
        }

        &__title {
            font-size: ${props => props.theme.fontConfiguration.sizes.Body};
        }

        &__subtitle {
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__content {
            flex: 1 0;
            max-width: 95%;
        }
    }

    .${CLASSNAME_PREFIX}dropdown {
        display: flex;
        align-items: center;
        position: relative;
        padding: 4px 0;

        &.${CLASSNAME_PREFIX}disabled {
            .${CLASSNAME_PREFIX}dropdownSelectedValue {
                cursor: default;
            }
        }
    }

    .${CLASSNAME_PREFIX}dropdownSelectedValue {
        display: flex;
        cursor: pointer;
        align-items: center;

        .${CLASSNAME_PREFIX}dropdownOption {
            &__content {
                flex: none;
                max-width: 100%;
            }
        }
    }

    .${CLASSNAME_PREFIX}arrowContainer {
        margin-left: 7px;
        margin-top: 4px;
    }

    .${CLASSNAME_PREFIX}dropdownItems {
        position: absolute;
        top: 100%;
        left: 0;
        background: white;
        z-index: 1000;
        border: 1px solid #ccc;
        border-top-color: #e6e6e6;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
        margin-top: -1px;
        min-width: 300px;
        max-height: 200px;
        width: 100%;
        overflow: auto;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;

        &.${CLASSNAME_PREFIX}alignRight {
            left: initial;
            right: 0;
        }

        &.${CLASSNAME_PREFIX}slim {
            min-width: 150px;
        }
    }

    .${CLASSNAME_PREFIX}dropdownValue {
        padding: 10px 16px 10px 10px;
        cursor: pointer;
        z-index: 1;

        .${CLASSNAME_PREFIX}dropdownOption__title {
            font-size: ${props => props.theme.fontConfiguration.sizes.Caption};
        }

        &:hover {
            background-color: #f4f5f6;
        }
    }

    .${CLASSNAME_PREFIX}image {
        height: 100%;
        width: 100%;
    }
`;
