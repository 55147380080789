import React from 'react';
import MuiIconButton from '@mui/material/IconButton';
import {type DateView} from '@mui/x-date-pickers';

import {Icon as ModernFamlyIcon} from 'modern-famly/components/data-display';

/**
 * Oreder of views used in date picker.
 *
 * Note that the ordering is important.
 * When clicking the date, the first view will open
 * and after selecting value the next view will open.
 */
export const dateViewsOrder: readonly DateView[] = ['year', 'month', 'day'] as const;

/**
 * The PreviousIconButton "slots" unfortunately types its props as "any". Reflecting that here.
 */
export const PreviousIconButton = (props: any) => {
    return (
        <MuiIconButton
            {...props}
            sx={theme => ({width: 36, height: 36, color: theme.modernFamlyTheme.colorPalette.n400})}
        >
            <ModernFamlyIcon name="chevron_left" size={24} />
        </MuiIconButton>
    );
};

/**
 * The NextIconButton "slots" unfortunately types its props as "any". Reflecting that here.
 */
export const NextIconButton = (props: any) => {
    return (
        <MuiIconButton
            {...props}
            sx={theme => ({width: 36, height: 36, color: theme.modernFamlyTheme.colorPalette.n400})}
        >
            <ModernFamlyIcon name="chevron_right" size={24} />
        </MuiIconButton>
    );
};

export const DIALOG_PADDING = 6;
