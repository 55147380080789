import React from 'react';

import {useInterval} from 'web-app/react/hooks/use-interval';
import * as Time from 'web-app/react/components/form-elements/time/time';
import {hasValue} from 'web-app/util/typescript';

/**
 * This hook forces a render of the encapsulating component
 * by flipping a boolean state every X ms.
 */
const useRerenderOnInterval = (ms: number) => {
    const [, setRenderFlag] = React.useState(false);
    useInterval(() => setRenderFlag(flag => !flag), ms);
};

type ConditionalProps = {time: Time.TimeWithSeconds; withSeconds: true} | {time: Time.Time; withSeconds?: false};

export const SelfUpdatingFromNow = React.memo<
    ConditionalProps & {
        children: (fromNow: string) => React.ReactNode;
    }
>(props => {
    const interval = props.withSeconds && hasValue(props.time.seconds) ? 1000 : 15000;
    useRerenderOnInterval(interval);

    return <>{props.children(Time.getFromNowString(props.time))}</>;
});
