import i18next from 'i18next';
import {from} from 'rxjs';

import {entityFactoryCreator} from 'web-app/react/entities/factory';
import {lazyDispatch} from 'signin-app/redux/helpers';
import * as GlobalEventActions from 'signin-app/global-event/actions';
import RESTClient from 'signin-app/api/clients/rest-client';
import ENV from 'signin-app/config/environment';

const onEntityCreated = (entityName: string) => {
    return GlobalEventActions.updateSuccess.action(i18next.t('entities.createSuccess', {entityName}));
};

const onEntityDeleted = (entityName: string) => {
    return GlobalEventActions.updateSuccess.action(i18next.t('entities.deleteSuccess', {entityName}));
};

const confirmDelete = (msg: string) =>
    from(
        new Promise<0 | 1>(resolve => {
            // eslint-disable-next-line no-alert
            const isConfirmed = confirm(msg);

            if (isConfirmed) {
                resolve(1);
            } else {
                resolve(0);
            }
        }),
    );

const entity = entityFactoryCreator(
    lazyDispatch,
    ENV.isDevelopment,
    onEntityCreated,
    onEntityDeleted,
    RESTClient,
    confirmDelete,
);

export default entity;
