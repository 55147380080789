import {makeCreateAction} from 'web-app/util/redux/action-helpers';
import {getStore} from 'signin-app/redux/store';
import ENV from 'signin-app/config/environment';
import {type Action} from 'web-app/util/redux/action-types';

export const lazyDispatch = (action: Action<any>): void => {
    getStore().dispatch(action);
};

export const createAction = makeCreateAction(lazyDispatch, ENV.isDevelopment);
