import {type QueryHookOptions} from '@apollo/client';

import {FetchCustomFormsEnrollmentPermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-custom-forms-enrollment-permissions.query';
import {
    type FetchCustomFormsEnrollmentPermissionsQuery,
    type FetchCustomFormsEnrollmentPermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-custom-forms-enrollment-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchCustomFormsEnrollmentPermissions(
    options: QueryHookOptions<
        FetchCustomFormsEnrollmentPermissionsQuery,
        FetchCustomFormsEnrollmentPermissionsQueryVariables
    >,
): ImmerWrappedQueryResult<
    FetchCustomFormsEnrollmentPermissionsQuery,
    FetchCustomFormsEnrollmentPermissionsQueryVariables
> {
    return useStatQuery<
        FetchCustomFormsEnrollmentPermissionsQuery,
        FetchCustomFormsEnrollmentPermissionsQueryVariables
    >(FetchCustomFormsEnrollmentPermissions, options);
}
