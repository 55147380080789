import React from 'react';
import {type WrappedFieldProps} from 'redux-form';

import {type DistributiveOmit} from 'web-app/util/typescript';
import DayPickerInput, {
    type DayPickerInputProps,
} from 'web-app/react/components/form-elements/daypicker/day-picker-input';
import ReactOnMeta from 'web-app/react/components/form-elements/containers/react-on-meta';

type Props = WrappedFieldProps & DistributiveOmit<DayPickerInputProps, 'value'>;

/**
 * @deprecated use the mf version
 */
class FormDayPickerInput extends React.Component<Props> {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    public render() {
        const {
            input,
            onChange, // eslint-disable-line no-unused-vars
            meta, // eslint-disable-line no-unused-vars
            ...rest
        } = this.props;
        return (
            <ReactOnMeta {...this.props}>
                <DayPickerInput {...rest} value={input.value} onChange={this.handleChange} />
            </ReactOnMeta>
        );
    }
    private handleChange(value?: string) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
        this.props.input.onChange(value);
    }
}

export default FormDayPickerInput;
