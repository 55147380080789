import React from 'react';
import MuiBadge, {type BadgeProps as MuiBadgeProps, badgeClasses} from '@mui/material/Badge';
import {type Components, type Theme} from '@mui/material/styles';

import {type DataProps, useDataProps} from 'modern-famly/components/util';
import {type ColorKey} from 'modern-famly/theming';
import {exhaustiveCheck} from 'modern-famly/util';

type BadgeSeverity = 'critical' | 'info' | 'success' | 'warning' | 'upsell' | 'neutral';

export type BadgeProps = Pick<MuiBadgeProps, 'variant' | 'overlap' | 'max' | 'invisible' | 'children' | 'aria-label'> &
    DataProps & {
        badgeContent?: number;
        severity?: BadgeSeverity;
    };

export const Badge = (props: BadgeProps) => {
    const dataProps = useDataProps(props);

    return (
        <MuiBadge
            {...props}
            {...dataProps}
            max={props.max ?? 999}
            slotProps={{badge: () => ({'data-e2e-class': 'badge'})}}
            sx={({modernFamlyTheme}) => ({
                [`& .${badgeClasses.badge}`]: {
                    backgroundColor:
                        modernFamlyTheme.colorPalette[getBackgroundColorForSeverity(props.severity ?? 'critical')],
                },
            })}
        />
    );
};

const getBackgroundColorForSeverity = (severity: BadgeSeverity): ColorKey => {
    switch (severity) {
        case 'critical':
            return 'r400';
        case 'info':
            return 'b400';
        case 'success':
            return 'g400';
        case 'warning':
            return 'y400';
        case 'upsell':
            return 'o400';
        case 'neutral':
            return 'n300';
        default:
            exhaustiveCheck(severity);
            return 'n300';
    }
};

export const BadgeThemeConfiguration: Components<Theme>['MuiBadge'] = {
    styleOverrides: {
        badge: ({theme}) => ({
            color: theme.modernFamlyTheme.colorPalette.n0,
            fontFamily: theme.typography.micro.fontFamily,
            fontSize: '10px',
            letterSpacing: '-2',
            lineHeight: '8px',
        }),
        dot: ({theme}) => ({
            width: '12px',
            height: '12px',
            borderRadius: theme.spacing(3),
        }),
    },
};
