import React, {type SVGAttributes} from 'react';
import styled from 'styled-components';

import {type ITheme} from 'web-app/styleguide/themes/model';

const appendUnit = (value: string | number) => (typeof value === 'string' ? value : `${value}px`);

interface StyledSVGProps {
    background?: string;
    size?: number | null;
    width?: string | number;
    height?: string | number;
    radius?: number | string;
    inline?: boolean;
}

const StyledSVG = styled('svg').attrs<any>(props => ({
    width: `${props.width !== undefined ? props.width : props.size}px`,
    height: `${props.height !== undefined ? props.height : props.size}px`,
    version: '1.1',
    xmlns: 'http://www.w3.org/2000/svg',
}))<StyledSVGProps>`
    background: ${props => (props.background ? props.background : 'initial')};
    border-radius: ${props => (props.radius ? appendUnit(props.radius) : 'initial')};
    vertical-align: initial;
    ${props => (props.inline ? 'display: inline-block;' : '')}
`;
StyledSVG.defaultProps = {
    size: 20,
};

type SVGProps = SVGAttributes<SVGElement> & {
    background?: string;
    inline?: boolean;
    size?: number | null;
};

export class SVG extends React.PureComponent<SVGProps> {
    public render() {
        return <StyledSVG {...this.props} />;
    }
}

const getFill = (appearance: string, theme: ITheme) => {
    switch (appearance) {
        case 'white':
            return theme.invertedText;
        case 'accent1':
            return theme.accent1;
        default:
            return theme.text;
    }
};

export const G = styled('g')<{appearance?: string | null; fill?: string | null; color?: string}>`
    fill: ${props => {
        if (props.fill) {
            return props.fill;
        }
        if (props.color) {
            return props.color;
        }
        if (props.appearance) {
            return getFill(props.appearance, props.theme);
        }
        return props.theme.text;
    }};
`;
