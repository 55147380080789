export const waitForFrames = (cb: () => void) => {
    if (window.requestAnimationFrame) {
        // Inspired by https://medium.com/@paularmstrong/twitter-lite-and-high-performance-react-progressive-web-apps-at-scale-d28a00e780a3
        return window.requestAnimationFrame(() => window.requestAnimationFrame(cb));
    } else {
        // Fallback for deferred rendering. Inspired by https://github.com/azu/react-defer-render/blob/master/src/ReactDeferRender.js
        return setTimeout(cb, 0);
    }
};

export const cancelWaitForFrames = id => {
    if (window.cancelAnimationFrame) {
        // Inspired by https://medium.com/@paularmstrong/twitter-lite-and-high-performance-react-progressive-web-apps-at-scale-d28a00e780a3
        return window.cancelAnimationFrame(id);
    } else {
        // Fallback for deferred rendering. Inspired by https://github.com/azu/react-defer-render/blob/master/src/ReactDeferRender.js
        return clearTimeout(id);
    }
};

export class FrameWaiter {
    private id;
    private id2;
    private cb;

    constructor(cb) {
        this.cb = cb;
    }

    public call() {
        if (window.requestAnimationFrame) {
            this.id = window.requestAnimationFrame(() => {
                this.id2 = window.requestAnimationFrame(this.cb);
            });
        } else {
            this.id = setTimeout(this.cb, 0);
        }
    }

    public stop() {
        cancelWaitForFrames(this.id);
        this.id = undefined;
        if (this.id2) {
            cancelWaitForFrames(this.id2);
            this.id2 = undefined;
        }
    }
}
