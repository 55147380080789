import React from 'react';
import {type WrappedFieldProps} from 'redux-form';
import {type Moment} from 'moment-timezone';

import WeekOfPicker, {type InputProps} from 'web-app/react/components/form-elements/week-of-picker/week-of-picker';
import ReactOnMeta from 'web-app/react/components/form-elements/containers/react-on-meta';
import {DEFAULT_DATE_VALUE_FORMAT} from 'web-app/react/components/form-elements/daypicker/constants';

type Props = WrappedFieldProps & Omit<InputProps, 'value'>;

const FormWeekOfPicker: React.FC<React.PropsWithChildren<Props>> = props => {
    const {
        input,
        meta, // eslint-disable-line no-unused-vars
        ...rest
    } = props;

    const {onChange} = input;

    const handleChange = React.useCallback(
        (value: Moment) => {
            return onChange(value.clone().isoWeekday(1).format(DEFAULT_DATE_VALUE_FORMAT));
        },
        [onChange],
    );

    return (
        <ReactOnMeta {...props}>
            <WeekOfPicker {...rest} selectedDate={input.value} onDateChange={handleChange} />
        </ReactOnMeta>
    );
};

export default FormWeekOfPicker;
