import {type OperationVariables, type QueryHookOptions, type QueryResult, useQuery} from '@apollo/client';
import {type DocumentNode} from 'graphql';
import {useMemo} from 'react';

import {REST_GRAPHQL_CLIENTNAME} from 'web-app/api/clients/constants';

/**
 * Simple wrapper for apollo's `useQuery`.
 *
 * 1) enhances the `fetchMore` to handle an edge case, where backend calls returning after a component was
 * unmounted resulting in errors making the CI tests flaky and confusing for everyone.
 * Github issue: {@link https://shorturl.at/aDFGJ}
 *
 *
 * @param query first parameter for the useQuery
 * @param options options parameter for the useQuery
 * @returns a more error prone and enhanced version of the return values
 */
export function useResilientQuery<TData = any, TVariables = OperationVariables>(
    query: DocumentNode,
    options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> {
    return useWrapQueryResult(useQuery(query, options));
}

/**
 * Simple wrapper for apollo's `useQuery`.
 *
 * 1) enhances the `fetchMore` to handle an edge case, where backend calls returning after a component was
 * unmounted resulting in errors making the CI tests flaky and confusing for everyone.
 * Github issue: {@link https://shorturl.at/aDFGJ}
 *
 * 2) includes the correct client name for queries going to the famlyfacade wrapper, which allows querying rest
 * api using graphql
 *
 * @param query first parameter for the useQuery
 * @param options options parameter for the useQuery
 * @returns a more error prone and enhanced version of the return values
 */
export function useResilientRestQuery<TData = any, TVariables = OperationVariables>(
    query: DocumentNode,
    options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> {
    return useResilientQuery(query, {...options, context: {...options?.context, clientName: REST_GRAPHQL_CLIENTNAME}});
}

/**
 * Actual wrapper for the resilient query, which adds the correct error handling.
 *
 * @param queryResult the query result to be wrapped
 * @returns the more error prone query result
 */
function useWrapQueryResult<Q, QV>(queryResult: QueryResult<Q, QV>): QueryResult<Q, QV> {
    return useMemo(
        () => ({
            ...queryResult,
            updateQuery: mapFn => {
                try {
                    queryResult.updateQuery(mapFn);
                } catch (e) {
                    if (
                        !(e?.message as string).includes(`ObservableQuery with this id doesn't exist`) &&
                        !(e?.message as string).includes(`17`)
                    ) {
                        throw e;
                    }
                }
            },
            fetchMore: async options => {
                let result;
                try {
                    result = await queryResult.fetchMore(options);
                } catch (e) {
                    if (
                        !(e?.message as string).includes(`ObservableQuery with this id doesn't exist`) &&
                        !(e?.message as string).includes(`17`)
                    ) {
                        throw e;
                    }
                }
                return result;
            },
        }),
        [queryResult],
    );
}
