import {GrandirUKTheme as GrandirUKMFTheme} from 'modern-famly';

import {hexToRGBA} from 'web-app/util/hex-to-rgb';

import BaseTheme from './base';

const GrandirUKChromeColor = '#00326C';
const GrandirUKPrimary = GrandirUKChromeColor;
const GrandirUKActive = hexToRGBA(GrandirUKChromeColor, 0.8);
const GrandirUKBackgroundHover = hexToRGBA(GrandirUKChromeColor, 0.04);

const GrandirUKTheme = BaseTheme.merge({
    title: 'Grandir UK',
    chromeColor: GrandirUKChromeColor,
    primary: GrandirUKPrimary,
    active: GrandirUKActive,
    backgroundHover: GrandirUKBackgroundHover,
    mf: GrandirUKMFTheme,
});

export default GrandirUKTheme;
