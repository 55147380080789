import React from 'react';
// We use this context both the sign-in and the main app, so we need to import
// ´useSelector´ rather than the reducer specific `useTypedSelector`.
// Otherwise, the signin app build breaks as we then start to import the main app's reducers.
// eslint-disable-next-line no-restricted-imports
import {useSelector} from 'react-redux';

import * as AccountSelectors from 'web-app/react/session/selectors';

export interface AuthState {
    isLoggedIn;
}

export const authStateContext = React.createContext<AuthState>({
    isLoggedIn: false,
});

const {Provider, Consumer} = authStateContext;

export const AuthStateConsumer = Consumer;
export const AuthStateProvider = Provider;

export const AuthStateContextWrapper = InnerComponent => props => {
    // Disabling hook linting rule - it seems ok to use a hook inside a HoC
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isLoggedIn = useSelector(state => AccountSelectors.isLoggedIn(state));

    return (
        <Provider value={{isLoggedIn}}>
            <InnerComponent {...props} />
        </Provider>
    );
};
