import React from 'react';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useGetDefaultManageFormPermissions} from './use-get-default-manage-form-permissions';
import {useFetchCustomFormsManagementPermissions} from './queries/use-fetch-custom-form-management-permissions';
import {type StatPermissions} from './stat-permissions';

export function useCustomFormsManagementPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['customRegistrationForms']['manageForm'] & {loading: boolean} {
    const {institutionSetId, shouldQuery, formId} = payload;
    const {loading, data} = useFetchCustomFormsManagementPermissions({
        variables: {
            institutionSetId,
            formId: formId as string,
        },
        skip: !shouldQuery || !institutionSetId || !formId,
    });

    const crud = useGetDefaultManageFormPermissions(data?.attendance.management.customRegistrationForm.byFormId);

    return React.useMemo(
        () => ({
            loading,
            ...crud,
        }),
        [crud, loading],
    );
}
