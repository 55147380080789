export const StoreKey = 'entityFactory';

export enum RequestType {
    Create = 'create',
    Fetch = 'fetch',
    FetchAll = 'fetchAll',
    Update = 'update',
    Delete = 'delete',
}

export enum RequestEvent {
    start = 'start',
    success = 'success',
    failed = 'failed',
    aborted = 'aborted',
}

export enum RequestMethod {
    GET = 'GET',
    POST = 'POST',
    DELETE = 'DELETE',
}

export enum InternalAction {
    save = 'INTERNAL_SAVE',
}
