import gql from 'graphql-tag';

import {StatPermissionStaffAttendanceManagement} from '../fragment/stat-permission-staffattendance.fragment';

/**
 * Do not use this query directly!
 *
 * Always use `useStatPermissions`!
 * @see [useStatPermissions](../../hooks/adaptation/use-stat-permissions.ts)
 */
export const FetchStaffAttendancePermissions = gql`
    query FetchStaffAttendancePermissions($institutionSetId: SiteSetId!) {
        staffhours {
            management {
                staffAttendance {
                    siteSet(siteSetId: $institutionSetId) {
                        ...StatPermissionStaffAttendanceManagement
                    }
                }
            }
        }
    }
    ${StatPermissionStaffAttendanceManagement}
`;
