import {Range} from 'immutable';

import {colorKeys} from 'web-app/styleguide/themes';
import NewLookTheme from 'web-app/styleguide/themes/new-look';
import colorDistance from 'web-app/util/color-distance';

const defaultColor = 'analogue1';

const filteredColorKeys = colorKeys.filter(
    colorKey =>
        NewLookTheme[colorKey] &&
        NewLookTheme[colorKey][0] === '#' &&
        colorDistance(NewLookTheme.text, NewLookTheme[colorKey]) > 50 &&
        colorDistance(NewLookTheme.invertedText, NewLookTheme[colorKey]) > 50,
);

/*
    Zero is the new default value from the backend. Due to this fact we treat it like a special case
*/
const MealTypeColorValues = Range(0, filteredColorKeys.length + 1).toArray();

export const MealTypeColorMap = MealTypeColorValues.reduce(
    (reduction, color) => ({
        ...reduction,
        [color]: color === 0 ? defaultColor : colorKeys[color - 1],
    }),
    {},
);

export const MealTypeColors = MealTypeColorValues.map(color => ({
    value: color,
    color: MealTypeColorMap[color],
}));
