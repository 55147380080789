import React, {useMemo} from 'react';
import {type RouteComponentProps} from 'react-router-dom';
import styled from 'styled-components';
import {Text, Box, Stack, Checkbox, createBox, EmptyState, createStack} from 'modern-famly';

import ChildrenEntity from 'signin-app/entities/children';
import translate from 'signin-app/helpers/translate';
import {limitedData} from 'signin-app/child/check-out/selectors';
import * as GroupsSelectors from 'signin-app/groups/selectors';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import PersonButton from 'signin-app/components/person-button';
import Header from 'signin-app/components/header';
import * as PrivateRouteSelectors from 'signin-app/components/private-route/selectors';
import {useTypedSelector} from 'signin-app/components/hooks';
import SignedInChildrenEmptyState from 'signin-app/resources/img/signed-in-children.svg';
import {PageContainer} from 'signin-app/components/containers';

import {toggleWhoPicksUp} from './actions';
import {showSignedIn} from './selectors';

const StyledBox = styled(
    createBox({
        alignSelf: 'flex-end',
    }),
)`
    div {
        color: white;
    }
`;

const StyledEmptyStateContainer = styled(
    createBox({
        margin: '0 auto',
    }),
)`
    p,
    h6 {
        color: ${props => props.theme.mf.colorPalette.n0};
    }
`;

export const PersonCardsContainer = styled(
    createStack({
        display: 'grid',
        gap: '16px',
        justifyContent: 'space-between',
    }),
)`
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    @media (max-width: 455px) {
        justify-content: center;
        grid-template-columns: repeat(2, 1fr);
    }
`;

const PickupViewer: React.FC = () => {
    const shouldShhowSignedIn = useTypedSelector(showSignedIn);
    return (
        <StyledBox color="n0">
            <Checkbox
                label={translate('showPickupTimes')}
                checked={shouldShhowSignedIn}
                onChange={toggleWhoPicksUp.dispatch}
            />
        </StyledBox>
    );
};

type GroupRouteProps = RouteComponentProps<{groupId: string}>;

export const Group: React.FC<GroupRouteProps> = props => {
    const childrenList = useTypedSelector(state =>
        ChildrenEntity.selectors.getByGroupedId(state, {referenceId: props.match.params.groupId}),
    );
    const groupName = useTypedSelector(state => GroupsSelectors.getGroupName(state, {id: props.match.params.groupId}));
    const isFetching = useTypedSelector(PrivateRouteSelectors.isPrefetching);
    const isDataLimited = useTypedSelector(state => limitedData(state));
    const shouldShowSignedIn = useTypedSelector(state => showSignedIn(state));
    const checkedInChildren = childrenList.filter(child => child.checkedIn);
    const childrenToRender = shouldShowSignedIn ? checkedInChildren : childrenList;
    const isEmptyState = useMemo(
        () => checkedInChildren.size === 0 && shouldShowSignedIn,
        [checkedInChildren, shouldShowSignedIn],
    );

    return (
        <PageContainer>
            <Header />
            {isFetching ? (
                <Spinner color="white" centered />
            ) : (
                <Stack flexDirection="column" gap={8}>
                    <Text variant="h4" color="n0" textAlign="center" emphasized>
                        {groupName}
                    </Text>
                    {!childrenList.size ? (
                        <Box textAlign="center" color="n0" mt={8}>
                            {translate('noChildren')}
                        </Box>
                    ) : (
                        <Stack flexDirection="column">
                            {isDataLimited ? null : <PickupViewer />}
                            <PersonCardsContainer>
                                {childrenToRender.map(child => {
                                    const childLink = child.checkedIn
                                        ? `/child/${child.id}/checkout`
                                        : `/child/${child.id}/checkin`;
                                    return (
                                        <PersonButton
                                            key={child.id}
                                            linkTo={childLink}
                                            person={child}
                                            hide={shouldShowSignedIn && !child.checkedIn ? true : undefined}
                                        />
                                    );
                                })}
                            </PersonCardsContainer>
                            <Stack flexWrap="wrap" gap={4}>
                                {isEmptyState ? (
                                    <StyledEmptyStateContainer>
                                        <EmptyState
                                            fullWidth
                                            imageUrl={SignedInChildrenEmptyState}
                                            heading={translate('signedinChildrenEmptyStateHeading')}
                                            body={translate('signedinChildrenEmptyStateBody')}
                                        />
                                    </StyledEmptyStateContainer>
                                ) : null}
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            )}
        </PageContainer>
    );
};
