import {Record} from 'immutable';

interface INavigationConfiguration {
    contextualSidebarBackground: string;
    contextualSidebarLinkBackground: string;
    contextualSidebarLinkColor: string;
    contextualSidebarLinkColorActive: string;
}

export class NavigationConfiguration extends Record<INavigationConfiguration>({
    contextualSidebarBackground: '',
    contextualSidebarLinkBackground: '',
    contextualSidebarLinkColor: '',
    contextualSidebarLinkColorActive: '',
}) {}
