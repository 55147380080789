import gql from 'graphql-tag';

// unfortunately, graphql does not allow creating fragments out of nowhere, so we have to go
// with the subtype management as base :/
export const StatPermissionSubTypePaidManagement = gql`
    fragment StatPermissionSubTypePaidManagement on SubTypeManagementPaidStatusType {
        canView
        canEdit
    }
`;
