import React from 'react';

import {type StatPermissions} from './stat-permissions';
import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {useFetchShiftPlannerPermissions} from './queries/use-fetch-shift-planner-permissions';

/**
 * Do not use this query directly!
 */
export function useShiftPlannerDeleteShiftPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['staffPlanner']['header']['deleteShift'] & {loading: boolean} {
    const {institutionSetId} = payload;
    const {data, loading} = useFetchShiftPlannerPermissions({
        variables: {
            siteSetId: institutionSetId,
        },
        skip: !payload.shouldQuery || !institutionSetId,
    });

    return React.useMemo(
        () => ({
            loading,
            canCreate: false,
            canRead: data?.staffhours.management.shiftplanner?.shift.draft.canDelete ?? false,
            canUpdate: false,
            canDelete: data?.staffhours.management.shiftplanner?.shift.draft.canDelete ?? false,
        }),
        [data?.staffhours.management.shiftplanner?.shift.draft.canDelete, loading],
    );
}
