import gql from 'graphql-tag';

export const StatPermissionCustomFormsManagement = gql`
    fragment StatPermissionCustomFormsManagement on CustomRegistrationFormManagementType {
        canView
        canEdit
        canDelete
        canDownloadResponses
        canDuplicate
        canEditName
        canEditSuccessMessage
        canPublish
        canUnpublish
        canShare
        canToggleNotifications
        canManageSubmissionKeys
        canSendEmails
        canViewResponses
    }
`;
