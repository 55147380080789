import {type Theme} from '../theme';
import {createBaseThemeVariation} from './base';

export const FamlyTheme: Theme = createBaseThemeVariation({
    name: 'famly',
    brandColor: 'hsla(265, 75%, 35%, 1)',
    primaryColors: {
        p50: 'hsla(265, 75%, 98%, 1)',
        p75: 'hsla(265, 75%, 97%, 1)',
        p100: 'hsla(265, 75%, 96%, 1)',
        p200: 'hsla(265, 75%, 90%, 1)',
        p300: 'hsla(265, 75%, 60%, 1)',
        p400: 'hsla(265, 75%, 35%, 1)',
        p500: 'hsla(265, 75%, 20%, 1)',
    },
});
