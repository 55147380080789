import {type QueryHookOptions} from '@apollo/client';

import {FetchLeavePermissions} from 'web-app/react/staffing-attendance/graphql/queries/fetch-leave-permissions.query';
import {
    type FetchLeavePermissionsQuery,
    type FetchLeavePermissionsQueryVariables,
} from 'web-app/react/staffing-attendance/graphql/queries/__generated__/fetch-leave-permissions.query.api-types';
import {useStatQuery} from 'web-app/react/staffing-attendance/hooks/queries/use-stat-query';
import {type ImmerWrappedQueryResult} from 'web-app/react/staffing-attendance/hooks/use-immer-query-wrapper';

export function useFetchLeavePermissions(
    options: QueryHookOptions<FetchLeavePermissionsQuery, FetchLeavePermissionsQueryVariables>,
): ImmerWrappedQueryResult<FetchLeavePermissionsQuery, FetchLeavePermissionsQueryVariables> {
    return useStatQuery<FetchLeavePermissionsQuery, FetchLeavePermissionsQueryVariables>(
        FetchLeavePermissions,
        options,
    );
}
