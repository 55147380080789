import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import {List} from 'immutable';
import styled, {css} from 'styled-components';

import Button from 'web-app/react/components/form-elements/button/button';
import Select from 'web-app/react/components/form-elements/select/select';
import {Flex} from 'web-app/react/components/layout/layout';
import {Caption} from 'web-app/react/components/text/text';
import Close from 'web-app/react/components/icons/legacy/lazy-icons/close';
import ReactOnMeta from 'web-app/react/components/form-elements/containers/react-on-meta';

const emptyBlur = () => ({});

const ClearButton = styled(Button)`
    width: 100%;
    text-align: left;
`;

const StyledFormListSelectItem = styled.li`
    display: flex;
`;

const DefaultStyledFormListSelectItem = styled(StyledFormListSelectItem)`
    margin-top: 4px;
`;

const StyledFormList = styled.ul`
    ${props =>
        props.hasCustomRenderer
            ? css`
                  && {
                      margin-top: 4px;
                  }
              `
            : ''}
    ${props =>
        props.inline
            ? css`
                  display: flex;
                  flex-wrap: wrap;
                  ${StyledFormListSelectItem} {
                      margin-right: 8px;
                      margin-bottom: 8px;
                  }
              `
            : ''}
    ${props =>
        props.disabled
            ? css`
                  pointer-events: none;
              `
            : ''}
`;

const RemoveIcon = styled(Close).attrs(() => ({
    size: 10,
}))`
    padding-right: 4px;
`;

const DefaultItemLabel = styled(Caption).attrs(() => ({
    secondary: true,
}))`
    white-space: normal;
    text-align: left;
`;

const StyledSelect = styled(Select)`
    width: 100%;
    display: block;
`;

const FormListSelect = props => {
    const {input, options, valueRenderer, valueRendererOptions, inline, disabled, hideClearAll, ...rest} = props;

    const value = input.value || '';
    const values = List(value.split(',')).filter(a => a);
    const onChange = (value, clickable) => {
        if (rest.onChange) {
            rest.onChange(value);
        }
        if (!clickable) {
            input.onChange(value);
        }
    };

    const onRemove = removeValue => {
        const newValues = values.filter(val => val !== removeValue);
        return onChange(newValues.join(','));
    };

    const displayedOptions = options.filter(o => !o.hidden);

    return (
        <ReactOnMeta {...props}>
            <StyledSelect
                {...rest}
                autoBlur={true}
                disabled={disabled}
                onBlur={emptyBlur}
                options={displayedOptions.filter(opt => !values.includes(opt.value))}
                onChange={option => {
                    let newValue;
                    if (option.value !== undefined && option.value !== null) {
                        newValue = values.push(option.value).join(',');
                    } else {
                        newValue = option.value;
                    }
                    return onChange(newValue, option.clickable);
                }}
            />
            {values.size > 0 && !disabled && !hideClearAll ? (
                <ClearButton appearance="link" onClick={() => onChange(null)}>
                    {i18next.t('clearAll')}
                </ClearButton>
            ) : null}
            {values.size > 0 ? (
                <StyledFormList hasCustomRenderer={Boolean(valueRenderer)} inline={inline} disabled={disabled}>
                    {values.map(val => {
                        const option = options.find(opt => opt.value === val);
                        if (!option) {
                            return null;
                        }
                        const remove = () => onRemove(val);
                        if (valueRenderer) {
                            return (
                                <StyledFormListSelectItem key={val}>
                                    {valueRenderer(option, remove, valueRendererOptions)}
                                </StyledFormListSelectItem>
                            );
                        }
                        return (
                            <DefaultStyledFormListSelectItem key={val}>
                                <Button appearance="link" onClick={remove}>
                                    <Flex align="center">
                                        <RemoveIcon />
                                        <DefaultItemLabel>{option.label}</DefaultItemLabel>
                                    </Flex>
                                </Button>
                            </DefaultStyledFormListSelectItem>
                        );
                    })}
                </StyledFormList>
            ) : null}
        </ReactOnMeta>
    );
};
FormListSelect.propTypes = {
    input: PropTypes.object,
    options: PropTypes.array,
    valueRenderer: PropTypes.func,
    valueRendererOptions: PropTypes.object,
    inline: PropTypes.bool,
    disabled: PropTypes.bool,
    hideClearAll: PropTypes.bool,
    onInputChange: PropTypes.func,
    isLoading: PropTypes.bool,
    placeholder: PropTypes.string,
    noResultsText: PropTypes.string,
    ignorePlatform: PropTypes.bool,
};

export default FormListSelect;
