import React from 'react';

import {type UseStatPermissionPayload} from '../use-stat-permissions';
import {type StatPermissions} from './stat-permissions';
import {useContractWeeklyHoursPermissions} from './use-contract-weekly-hours-permissions';
import {useContractWorkAvailabilityPermissions} from './use-contract-work-availability-permissions';
import {useShiftPlannerBulkDeleteShiftPermissions} from './use-shift-planner-bulk-delete-shift-permissions';
import {useShiftPlannerCreateNewShiftPermissions} from './use-shift-planner-create-new-shift-permissions';
import {useShiftPlannerDeleteShiftPermissions} from './use-shift-planner-delete-shift-permissions';
import {useShiftPlannerHeaderCopyShiftsPermissions} from './use-shift-planner-header-copy-shifts-permissions';
import {useShiftPlannerPermissions} from './use-shift-planner-permissions';

/**
 * Do not use this query directly!
 */
export function useStaffPlannerPermissions(
    payload: UseStatPermissionPayload,
): StatPermissions['staffPlanner'] & {loading: boolean} {
    const {loading: loadingCopyShifts, ...copyShifts} = useShiftPlannerHeaderCopyShiftsPermissions(payload);
    const {loading: loadingCreateShifts, ...newShift} = useShiftPlannerCreateNewShiftPermissions(payload);
    const {loading: loadingDeleteShift, ...deleteShift} = useShiftPlannerDeleteShiftPermissions(payload);
    const {loading: loadingBulkDeleteShift, ...bulkDeleteShift} = useShiftPlannerBulkDeleteShiftPermissions(payload);
    const {loading: loadingWorkAvailability, ...workAvailability} = useContractWorkAvailabilityPermissions(payload);
    const {loading: loadingContractedHours, ...contractedHours} = useContractWeeklyHoursPermissions(payload);
    const {loading: loadingScheduledHours, ...scheduledHours} = useShiftPlannerPermissions(payload);

    return React.useMemo(
        () => ({
            loading:
                loadingCopyShifts ||
                loadingCreateShifts ||
                loadingDeleteShift ||
                loadingBulkDeleteShift ||
                loadingWorkAvailability ||
                loadingContractedHours ||
                loadingScheduledHours,
            header: {
                copyShifts,
                newShift,
                deleteShift,
                bulkDeleteShift,
            },
            content: {
                workAvailability,
                contractedHours,
                scheduledHours: scheduledHours.general,
            },
        }),
        [
            bulkDeleteShift,
            contractedHours,
            copyShifts,
            deleteShift,
            loadingBulkDeleteShift,
            loadingContractedHours,
            loadingCopyShifts,
            loadingCreateShifts,
            loadingDeleteShift,
            loadingScheduledHours,
            loadingWorkAvailability,
            newShift,
            scheduledHours.general,
            workAvailability,
        ],
    );
}
